import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { SharedModule } from '@app/shared/shared.module'
import { TimeUtilitiesService } from '@app/core/_services/time-utilities.service'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserService } from '@app/core/_services/user.service'
import { firstValueFrom } from 'rxjs'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'

type JoinedTooEarlyDialogComponentData = {
    sessionTime: number
}
@Component({
    selector: 'app-joined-too-early-dialog',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './joined-too-early-dialog.component.html',
    styleUrl: './joined-too-early-dialog.component.scss',
})
export class JoinedTooEarlyDialogComponent {
    faTimes = faTimes

    public joinedTooEarly: boolean
    public sessionStartTimeStr: string

    constructor(
        private dialog: MatDialogRef<JoinedTooEarlyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: JoinedTooEarlyDialogComponentData,
        private dateFormat: UserDateFormattingService,
        private analyticsService: AnalyticsService,
        private userService: UserService,
    ) {
        this.analyticsService.logViewedModalEvent('Joined Too Early')
    }

    async ngOnInit() {
        const user = await firstValueFrom(this.userService.currentUser)
        this.sessionStartTimeStr = this.dateFormat.generateUpcomingSessionTimeStr(
            this.data.sessionTime,
            user.timeZone,
        )
    }

    onCloseButtonClick(): void {
        this.dialog.close(false)
    }
}
