<div class="std-modal-container">
    <div class="std-modal-header">
        <div class="std-modal-title">Update email address?</div>
    </div>

    <div class="std-modal-body w-full">
        <div class="f-bluegray-14-n-text"
            >{{ data.currentEmailAddress }} to {{ data.newEmailAddress }}</div
        >

        <div class="std-modal-action-container">
            <button class="f-btn f-btn--primary f-btn-250" (click)="dialog.close(true)"
                >Yes, update email address</button
            >
            <button class="f-btn f-btn--secondary f-btn-250" (click)="dialog.close(false)"
                >Cancel</button
            >
        </div>
    </div>
</div>
