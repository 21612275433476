import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'

export type FmButtonType =
    | 'solid'
    | 'solid-outline-disabled'
    | 'outline'
    | 'facebook-login'
    | 'google-login'
    | 'cancel-confirm'
    | 'cancel-keep'

export type FmButtonAnimationType = 'dots' | 'spinner'
export type FmButtonSizeType = 'small' | 'medium'

@Component({
    selector: 'fm-button',
    templateUrl: './fm-button.component.html',
    styleUrls: ['./fm-button.component.scss'],
})
export class FmButtonComponent implements OnInit {
    @Input() buttonType?: FmButtonType = 'solid'
    @Input() disabled?: boolean = false
    @Input() loading?: boolean = false
    @Input() animationType?: FmButtonAnimationType = 'dots'
    @Input() buttonSize: FmButtonSizeType = 'medium'
    @Output() clicked: EventEmitter<Event> = new EventEmitter<Event>()

    constructor() {}

    ngOnInit(): void {}

    onClickButton(event: Event) {
        this.clicked.emit(event)
    }
}
