import { Component, OnInit } from '@angular/core'
import { BookLimitCheckService, BookLimitInfo } from '@app/core/_services/book-limit-check.service'
import { MatDialogRef } from '@angular/material/dialog'
import { take } from 'rxjs/operators'
import { AnalyticsService } from '@app/core/_services/analytics.service'

@Component({
    selector: 'app-session-limit-exceeded',
    templateUrl: './session-limit-exceeded.component.html',
    styleUrls: ['./session-limit-exceeded.component.scss'],
})
export class SessionLimitExceededComponent implements OnInit {
    public modalMessage: string = ''
    public limitInfo: BookLimitInfo
    public tip: string = `Focusmate limits the number of future bookings a Focusmate can schedule based on their number of completed sessions and their timeliness score. This helps prevent fake accounts and habitual "no show" users from negatively affecting the community's productivity and ensures quality partners.`

    constructor(
        private analyticsService: AnalyticsService,
        public bookLimitCheckService: BookLimitCheckService,
        public dialog: MatDialogRef<SessionLimitExceededComponent>,
    ) {}

    ngOnInit() {
        this.bookLimitCheckService.currentLimitInfo
            .pipe(take(1))
            .subscribe((limitInfo: BookLimitInfo) => {
                this.limitInfo = limitInfo

                if (limitInfo.compSessions >= 5) {
                    if (limitInfo.attRate < 60) {
                        this.analyticsService.logViewedModalEvent(
                            'Session Limit: <= 60% Attendance',
                        )
                        this.modalMessage = `The number of upcoming sessions you are allowed to book is limited to ${this.limitInfo.bookLimit} due to your timeliness score. Raise it above 60% to increase your limit.`
                    } else if (limitInfo.attRate < 80) {
                        this.analyticsService.logViewedModalEvent(
                            'Session Limit: 60-80% Attendance',
                        )
                        this.modalMessage = `The number of upcoming sessions you are allowed to book is limited to ${this.limitInfo.bookLimit} due to your timeliness score. Raise it above 80% to increase your limit.`
                    }
                }
            })
    }
}
