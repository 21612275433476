import { Component, EventEmitter, Inject, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AnalyticsService, BookingSource } from '@app/core/_services/analytics.service'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { WelcomeChecklistHeaderComponent } from '../welcome-checklist-header/welcome-checklist-header.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { SharedModule } from '@app/shared/shared.module'

@Component({
    selector: 'fm-book-first-session-modal',
    standalone: true,
    imports: [CommonModule, SharedModule, FontAwesomeModule, WelcomeChecklistHeaderComponent],
    templateUrl: './book-first-session-modal.component.html',
    styleUrl: './book-first-session-modal.component.scss',
})
export class BookFirstSessionModalComponent {
    @Output() public bookingSuccess = new EventEmitter()

    public bookingSource = BookingSource.CreateSessionModalFromWelcomeChecklist
    faTimes = faTimes

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialogRef<BookFirstSessionModalComponent>,
        private analyticsService: AnalyticsService,
    ) {
        this.analyticsService.logViewedModalEvent('Welcome Checklist Modal: Book First Session')
    }

    onBookingSuccess(event) {
        this.bookingSuccess.emit(event)
    }

    onBookingFailure() {
        this.dialog.close()
    }

    onBookingCancel() {
        this.dialog.close()
    }

    closeModalButtonClick() {
        this.dialog.close()
    }
}
