import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { User } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import {
    RewardsReferralService,
    VIEWED_REFERRAL_PROMPT_MODAL_PROP,
} from '@app/core/_services/rewards-referral.service'
import { UserService } from '@app/core/_services/user.service'
import { environment } from '@env/environment'
import { faTimes, faGift, faExternalLink } from '@fortawesome/pro-solid-svg-icons'
import { faClone } from '@fortawesome/pro-regular-svg-icons'
import { firstValueFrom } from 'rxjs'
import { ConfettiService } from '@app/core/_services/confetti.service'

interface ReferralPromptModalComponentData {}

@Component({
    selector: 'app-referral-prompt-modal',
    templateUrl: './referral-prompt-modal.component.html',
    styleUrl: './referral-prompt-modal.component.scss',
})
export class ReferralPromptModalComponent {
    faTimes = faTimes
    faGift = faGift
    faClone = faClone
    faExternalLink = faExternalLink

    referralLink: string
    copyPromptText: string
    copyConfirmedTimeout: any

    user: User

    constructor(
        private dialog: MatDialogRef<ReferralPromptModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ReferralPromptModalComponentData,
        private userService: UserService,
        private analyticsService: AnalyticsService,
        private rewardsReferralService: RewardsReferralService,
    ) {}

    async ngOnInit() {
        const baseUrl = environment.marketingHomeUrlShort
        this.user = await firstValueFrom(this.userService.currentUser)
        this.referralLink = `${baseUrl}/?fmreferral=${this.user.rewardsReferralCode}`

        this.user.properties[VIEWED_REFERRAL_PROMPT_MODAL_PROP] = true
        this.userService.update(this.user, null).subscribe((res) => {})

        this.analyticsService.logViewedModalEvent('Rewards Referral Prompt Modal', {
            completedSessions: this.user.compSessions,
        })
    }

    confirmCopy() {
        if (this.copyConfirmedTimeout) {
            clearTimeout(this.copyConfirmedTimeout)
        }

        this.copyConfirmedTimeout = setTimeout(() => {
            this.copyConfirmedTimeout = null
        }, 3000)

        this.analyticsService.logClickedEvent(
            'Copy Rewards Referral Link',
            'Rewards Referral Prompt Modal',
        )
    }

    onCloseButtonClick() {
        if (this.copyConfirmedTimeout) {
            clearTimeout(this.copyConfirmedTimeout)
            this.copyConfirmedTimeout = null
        }
        this.dialog.close()

        this.rewardsReferralService.emphasizeReferIcon()
    }
}
