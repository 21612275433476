<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="closeModal()">
    </fa-icon>

    <div class="std-modal-header">
        <div class="std-modal-title long-title">We're finding you a partner</div>
    </div>

    <div class="std-modal-body w-full">
        <div class="std-modal-body-text text-center">
            <p
                >Your {{ sessionTimeString }} session has started. Please join the session and we'll
                continue looking for a partner.</p
            >
        </div>

        <div class="std-modal-action-container">
            <button
                class="f-btn f-btn--primary f-btn-250"
                (click)="joinButtonClick()"
                [disabled]="xhrInProgress"
                >Join Session</button
            >
            <a class="f-link fm-mt-15px" (click)="cancelButtonClick()" style="text-decoration: none"
                >Cancel session</a
            >
        </div>
    </div>

    <div class="modal-timer"
        >You have&nbsp;<app-fm-countdown
            [countdownTo]="countdownTo"
            (onCountdownFinished)="handleCountdownFinished()"></app-fm-countdown
        >&nbsp;to join.
    </div>
</div>
