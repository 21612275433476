import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { User, UserSettingsShareAvailability } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserService } from '@app/core/_services/user.service'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Subject } from 'rxjs'
import { take } from 'rxjs/operators'

interface RadioButtonInfo {
    value: UserSettingsShareAvailability
    label: string
}
@Component({
    selector: 'app-share-availability-setting',
    templateUrl: './share-availability-setting.component.html',
    styleUrls: ['./share-availability-setting.component.scss'],
})
export class ShareAvailabilitySettingComponent implements OnInit {
    public radioButtonInfo: RadioButtonInfo[] = [
        {
            value: 'everyone',
            label: 'Everyone',
        },
        {
            value: 'only saved',
            label: 'Favorites only',
        },
        {
            value: 'no one',
            label: 'No one',
        },
    ]

    public faTimes = faTimes
    public initialPreferenceSelected: UserSettingsShareAvailability
    public preferenceSelected: UserSettingsShareAvailability

    public disableSaveButton = true
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    private currentUser: User = new User()
    public dontShowThisAgain = false
    private source: string

    constructor(
        public dialog: MatDialogRef<ShareAvailabilitySettingComponent>,
        public userService: UserService,
        private analyticsService: AnalyticsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
        this.userService.currentUser.pipe(take(1)).subscribe((user) => {
            this.currentUser = user
            this.preferenceSelected = this.currentUser.settings.shareAvailability || 'no one'
            this.initialPreferenceSelected = this.preferenceSelected

            if (this.data.notificationOnSave) {
                this.source = 'Share Availability Settings: Notification On Save'
                this.preferenceSelected = 'only saved'
                this.analyticsService.logViewedModalEvent(this.source)
            } else {
                this.source = 'Share Availability Settings: Preferences'
                this.analyticsService.logViewedModalEvent(this.source)
            }
            this.checkEnableSaveButton()
        })
    }

    radioButtonChanged() {
        this.checkEnableSaveButton()
    }

    checkEnableSaveButton(): void {
        this.disableSaveButton =
            this.preferenceSelected === this.initialPreferenceSelected &&
            !this.data.notificationOnSave
    }

    public submitForm() {
        this.analyticsService.logClickedEvent('Save availability', this.source)
        this.userService
            .updateSettings({ shareAvailability: this.preferenceSelected })
            .subscribe(() => {
                this.analyticsService.logUpdatedSettingEvent(
                    'shareAvailability',
                    this.preferenceSelected,
                    this.initialPreferenceSelected,
                    this.source,
                )
                this.dialog.close()
            })
    }

    learnMoreSelected() {
        this.analyticsService.logClickedEvent('Learn more', this.source)
    }

    learnMoreAboutFavoritesSelected() {
        this.analyticsService.logClickedEvent('Learn more about Favorites', this.source)
    }

    ngOnDestroy() {
        if (this.dontShowThisAgain) {
            this.currentUser.properties['notifyOnSaveUserDisabled'] = true
            this.userService.update(this.currentUser).subscribe()
            this.analyticsService.logUpdatedUserPropertyEvent(
                'notifyOnSaveUserDisabled',
                true,
                this.source,
            )
        }

        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
