<div
    class="w-full"
    [ngClass]="{ 'badge-container': !standAlone, 'stand-alone-container': standAlone }">
    <fa-stack
        *ngIf="snoozed"
        [matTooltip]="snoozeTooltip"
        matTooltipPosition="below"
        matTooltipShowDelay="200"
        matTooltipClass="f-tooltip"
        class="badge snooze"
        [ngClass]="size">
        <fa-icon
            class="circle-outer"
            [icon]="faCircle"
            stackItemSize="1x"
            transform="{{ borderTransform }}"></fa-icon>
        <fa-icon
            class="circle"
            [icon]="faCircle"
            stackItemSize="1x"
            transform="{{ badgeTransform }}"></fa-icon>
        <fa-icon
            class="clock"
            [icon]="faClockEight"
            stackItemSize="1x"
            transform="{{ iconTransform }}"></fa-icon>
    </fa-stack>

    <fa-stack *ngIf="saved" class="badge saved" [ngClass]="size">
        <fa-icon
            class="circle-outer"
            [icon]="faCircle"
            stackItemSize="1x"
            transform="{{ borderTransform }}"></fa-icon>
        <fa-icon
            class="circle"
            [icon]="faCircle"
            stackItemSize="1x"
            transform="{{ badgeTransform }}"></fa-icon>
        <fa-icon
            class="star"
            [icon]="faStar"
            stackItemSize="1x"
            transform="{{ iconTransform }}"></fa-icon>
    </fa-stack>
</div>
