<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close(false)">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title wrap-double-line-el">
            Report {{ data.profileDisplayName }}
        </div>
    </div>

    <div class="std-modal-body w-full">
        <div class="std-modal-body-text text-left wrap-word-break">
            <p>Thank you for helping us keep Focusmate safe and productive!</p>
            <p
                >We will review your report and take action within 48 hours. Your report is
                completely confidential.
            </p>

            <p
                >Select the option that best describes how {{ data.partnerName }} violated our
                Community Guidelines.
            </p>
        </div>

        <app-fm-select
            class="w-full"
            [optionsList]="reasons"
            [currentValue]="selectedReason"
            fieldName="reasonsList"
            btnClasses="std-select w-full"
            placeholder="Please choose a reason"
            (selectionChanged)="onSelectionChanged($event)"></app-fm-select>

        <mat-form-field
            floatLabel="never"
            class="fm-textarea w-full fm-mt-10px"
            [ngClass]="{ 'required-state': commentsRequired }"
            hideRequiredMarker="true">
            <mat-label>Please describe your concern</mat-label>
            <textarea
                matInput
                maxlength="500"
                (input)="onKeypressEvent($event)"
                [(ngModel)]="comments"
                name="additionalComments"></textarea>
        </mat-form-field>

        <div class="std-modal-action-container">
            <app-loading-button
                classList="f-btn f-btn--primary f-btn-250"
                label="Report"
                loadingLabel="Reporting"
                [notValid]="disableForm"
                (clicked)="reportUserProfile()"></app-loading-button>

            <button class="f-link fm-mt-10px" (click)="dialog.close(false)">Cancel</button>
        </div>
    </div>
</div>
