import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

@Component({
    selector: 'app-availability-list-quiet-mode-info',
    templateUrl: './availability-list-quiet-mode-info.component.html',
    styleUrls: ['./availability-list-quiet-mode-info.component.scss'],
})
export class AvailabilityListQuietModeInfoComponent {
    public faTimes = faTimes

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<AvailabilityListQuietModeInfoComponent>,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Availability list: Quiet Mode Info')
    }

    learnMoreClicked() {
        this.analyticsService.logClickedEvent(
            'More about Quiet Mode',
            'Availability list: Quiet Mode Info',
        )
    }
}
