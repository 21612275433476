<div
    class="flex flex-col items-center justify-between relative box-border w-full h-full font-avenir"
    [class]="{ 'sm:min-h-[639px]': !showingVideo }">
    <!-- header -->
    <div
        class="relative w-full sm:w-[400px] px-[30px] py-[36px] sm:px-[0px] sm:py-[24px] flex items-center justify-center">
        @if (showingVideo) {
            <div
                class="absolute left-[30px] sm:left-0 cursor-pointer text-blurple"
                (click)="hideVideo()"
                ><fa-icon [icon]="faArrowLeft"></fa-icon
            ></div>
        }
        <div class="text-blurple text-center text-26-36 font-semibold"> How it works </div>
    </div>

    <!-- body -->
    <div class="px-[30px] sm:px-[100px] flex flex-col items-center justify-center gap-[12px]">
        @if (!showingVideo) {
            <div class="w-full p-[16px] flex flex-col gap-[8px] bg-blurple-5 rounded-lg">
                <div class="text-blurple text-14-19 font-semibold"
                    ><fa-icon [icon]="faCalendarDays" class="pr-[6px]"></fa-icon>Book a session</div
                >
                <div class="text-bluegrey-2 text-14-19 font-medium"
                    ><ul
                        ><li
                            >Choose between 25, 50, and 75 minute sessions; sessions are available
                            24/7</li
                        ></ul
                    ></div
                >
            </div>

            <div class="w-full p-[16px] flex flex-col gap-[8px] bg-blurple-5 rounded-lg">
                <div class="text-blurple text-14-19 font-semibold"
                    ><fa-icon [icon]="faDesktop" class="pr-[6px]"></fa-icon>Join Session</div
                >
                <div class="text-bluegrey-2 text-14-19 font-medium"
                    ><ul
                        ><li class="mb-[4px]"
                            >Greet your partner and share your goals (for example: "replying to 5
                            emails" or "booking a flight")</li
                        >
                        <li>Write your goals in the chat for added accountability</li
                        ><li>Focus on your goals and make progress</li></ul
                    ></div
                >
            </div>

            <div class="w-full p-[16px] flex flex-col gap-[8px] bg-blurple-5 rounded-lg">
                <div class="text-blurple text-14-19 font-semibold"
                    ><fa-icon [icon]="faPartyHorn" class="pr-[6px]"></fa-icon>Celebrate your
                    progress!</div
                >
                <div class="text-bluegrey-2 text-14-19 font-medium"
                    ><ul
                        ><li>Tell your partner how you did and celebrate your progress!</li></ul
                    ></div
                >
            </div>
        } @else {
            <div class="sm:h-[250px]">
                <video
                    class="rounded-lg h-inherit"
                    controls
                    (ended)="videoEnded($event)"
                    (play)="videoStarted($event)"
                    (pause)="videoPaused($event)"
                    width="100%"
                    autoplay="true"
                    height="auto">
                    <source
                        src="https://focusmate.nyc3.digitaloceanspaces.com/Focusmate.mp4"
                        type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        }
    </div>

    <!-- bottom -->
    <div
        class="w-full sm:w-[400px] pt-[35px] pb-[50px] px-[30px] sm:pb-[60px] sm:px-[0px] flex flex-col items-center justify-center gap-[15px]">
        <button class="f-btn f-btn--primary w-full" (click)="onNextStepButtonClick()"
            >Got it! <fa-icon class="pl-[6px]" [icon]="faArrowRight"></fa-icon
        ></button>
        @if (!showingVideo) {
            <div
                (click)="showVideo()"
                class="text-blurple flex flex-row items-center justify-center text-14-19 font-semibold cursor-pointer"
                ><fa-icon class="pr-[4px]" [icon]="faPlay"></fa-icon>View video</div
            >
        }
    </div>
</div>
