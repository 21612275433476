import { Injectable } from '@angular/core'
import { TimeFormat } from '../_models'
import { UserService } from './user.service'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'

export interface SessionTimeDisplayInfo {
    startTime: string
    endTime: string
    timeRange: string
}

// Snooze Constants
const SNOOZED_UNTIL = 'until '
const SNOOZED_UNTIL_I_UNSNOOZE_THEM = 'until you unsnooze them'

// People List Constants
const LAST_MET_TODAY = 'Last met today'
const LAST_MET_YESTERDAY = 'Last met yesterday'
const LAST_MET = 'Last met'

@Injectable({
    providedIn: 'root',
})
export class UserDateFormattingService {
    private timeFormat: TimeFormat = '12hr'
    private timezone: string

    constructor(private userService: UserService) {
        this.userService.currentUser.subscribe((user) => {
            this.timeFormat = user?.settings?.timeFormat || '12hr'
            this.timezone = user?.timeZone
        })
    }

    format(ts: number, format: string, tz: string = null, timeFormat: TimeFormat = null): string {
        timeFormat = timeFormat ?? this.timeFormat
        tz = tz ?? this.timezone

        // replace 'h:mmm a' and 'h:mma'
        let formatted = format.replace(/h:mm a/g, timeFormat === '12hr' ? 'h:mm a' : 'HH:mm')
        formatted = formatted.replace(/h:mma/g, timeFormat === '12hr' ? 'h:mma' : 'HH:mm')
        if (tz) {
            return moment(ts).tz(tz).format(formatted)
        } else {
            return moment(ts).format(formatted)
        }
    }

    formatNow(format: string, tz: string = null, timeFormat: TimeFormat = null): string {
        return this.format(Date.now(), format, tz, timeFormat)
    }

    formatShortHand(sessionTime: number, tz: string = null) {
        let str = this.format(sessionTime, 'h:mma', tz)
        if (this.timeFormat === '12hr') {
            str = str.replace(/:00/g, '')
        }
        return str
    }

    generateSnoozedUntilTimeLabel(dateTime: moment, now: moment): string {
        // NOTE: It is the caller's responsibility to ensure moments
        // passed to this function use the same timezone
        const isSameDay: boolean = dateTime.date() === now.date()
        const isSameYear: boolean = dateTime.year() === now.year()

        if (dateTime.diff(now, 'days') <= 1) {
            if (isSameDay) {
                return this.format(dateTime.valueOf(), 'h:mma')
            } else {
                return this.format(dateTime.valueOf(), 'h:mma [tomorrow]')
            }
        } else {
            if (isSameYear) {
                return this.format(dateTime.valueOf(), 'h:mma, MMM D')
            } else {
                return this.format(dateTime.valueOf(), 'h:mma, MMM D, YYYY')
            }
        }
    }

    generateSnoozeUntilString(expiresAt: string, timezone: string) {
        if (!expiresAt) {
            return SNOOZED_UNTIL_I_UNSNOOZE_THEM
        } else {
            let now = moment().tz(timezone)
            let expiresAtMoment = moment(expiresAt)
            return SNOOZED_UNTIL + this.generateSnoozedUntilTimeLabel(expiresAtMoment, now)
        }
    }

    generateUpcomingSessionTimeStr(sessionTime: number, timezone: string) {
        const sessionDate = moment(sessionTime).tz(timezone)
        const today = moment().tz(timezone).startOf('day')

        if (sessionDate.isSame(today, 'day')) {
            // If the session is today, only show the time
            return this.format(sessionTime, 'h:mma')
        } else {
            // Otherwise, show the month, day, and time
            return this.format(sessionTime, 'MMM D, h:mma')
        }
    }

    generateTimeSlotsForDropDownMenu(increment: number) {
        let hours = []
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += increment) {
                const slot = this.format(moment({ hour, minute }).valueOf(), 'h:mm a')
                const slotvalue = `${hour}:${minute}`
                hours.push({ value: slotvalue, name: slot })
            }
        }
        return hours
    }

    generateLastMetString(sessionTime: number | null) {
        if (!sessionTime) {
            return ''
        }
        const now: moment = moment()
        const yesterday: moment = now.clone().subtract(1, 'd')
        const sessionMoment: moment = moment(sessionTime)

        if (sessionMoment.isSame(now, 'day')) {
            return LAST_MET_TODAY
        } else if (sessionMoment.isSame(yesterday, 'day')) {
            return LAST_MET_YESTERDAY
        } else if (sessionMoment.isSame(now, 'year')) {
            return `${LAST_MET} ${sessionMoment.format('MMM D')}`
        } else {
            return `${LAST_MET} ${sessionMoment.format('MMM D YYYY')}`
        }
    }

    public getSessionTimeDisplayStrings(
        sessionTime: number,
        duration: number,
        timezone: string,
    ): SessionTimeDisplayInfo {
        const startTime = this.formatShortHand(sessionTime)
        const endTime = this.formatShortHand(sessionTime + duration)

        let startTimeNoAmPm = moment(sessionTime).tz(timezone).format('h:mm').replace(/:00/g, '')
        if (this.timeFormat === '24hr') {
            startTimeNoAmPm = moment(sessionTime).tz(timezone).format('HH:mm')
        }

        const sessionTimeDisplayInfo: SessionTimeDisplayInfo = {
            startTime,
            endTime,
            timeRange: `${startTime} - ${endTime}`,
        }

        if (this.timeFormat === '12hr') {
            if (
                moment(sessionTime).tz(timezone).format('a') ===
                moment(sessionTime + duration)
                    .tz(timezone)
                    .format('a')
            ) {
                sessionTimeDisplayInfo.timeRange = `${startTimeNoAmPm} - ${endTime}`
            }
        }

        return sessionTimeDisplayInfo
    }
}
