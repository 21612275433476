import * as Sentry from '@sentry/angular-ivy'
import { ElementRef, Injectable } from '@angular/core'
import { ScriptLoaderService } from './script-loader.service'
import { UserService } from './user.service'
import { User } from '../_models'
import confetti from 'canvas-confetti'

const UNNECESSARY_CONFETTI_USER_PROP = 'unnecessaryConfetti'

@Injectable({
    providedIn: 'root',
})
export class ConfettiService {
    private confettiPromise: Promise<void>
    private user: User

    constructor(private userService: UserService) {
        this.userService.currentUser.subscribe((user) => {
            this.user = user
        })
    }

    /**
     * Check if the user has enabled unnecessary confetti in their settings
     */
    public unnecessaryConfettiEnabled() {
        return this.user.properties && this.user.properties[UNNECESSARY_CONFETTI_USER_PROP]
    }

    /**
     * jus a lil guy
     */
    public lilPop({ x, y }: { x?: number; y?: number } = {}) {
        x = x ?? 0.5
        y = y ?? 0.5
        try {
            confetti({
                origin: { x, y },
            })
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                Sentry.captureException(e)
            }
        }
    }

    /**
     * lil guy on a mission
     */
    public lilPopAtSpot(element: ElementRef) {
        try {
            const { x, y } = this.calculateCenterOfElement(element)
            this.lilPop({ x, y })
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                Sentry.captureException(e)
            }
        }
    }

    /**
     * bigly
     */
    public chonkyPop() {
        try {
            this.confettiCannon()
            this.snowConfetti()
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                Sentry.captureException(e)
            }
        }
    }

    /**
     * dripping with rizz
     */
    public leakyConfetti(element: ElementRef) {
        try {
            const origin = this.calculateCenterOfElement(element)
            this.snowConfetti({
                duration: 2000,
                confettiOrigin: origin,
                timeBetweenConfettis: 100,
                startVelocity: 4,
            })
        } catch (e) {
            console.error(e)
            if (!(e instanceof ReferenceError)) {
                Sentry.captureException(e)
            }
        }
    }

    private confettiCannon() {
        // straight up stolen from the canvas-confetti demo website
        var count = 200
        var defaults = {
            origin: { y: 0.7 },
        }

        function fire(particleRatio, opts) {
            confetti({
                ...defaults,
                ...opts,
                particleCount: Math.floor(count * particleRatio),
                zIndex: 10000,
            })
        }

        fire(0.25, {
            spread: 26,
            startVelocity: 55,
        })
        fire(0.2, {
            spread: 60,
        })
        fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8,
        })
        fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
        })
        fire(0.1, {
            spread: 120,
            startVelocity: 45,
        })

        // confetti from the sides
        confetti({
            particleCount: 200,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            zIndex: 10000,
        })
        confetti({
            particleCount: 200,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            zIndex: 10000,
        })
    }

    private snowConfetti({
        duration = 2000,
        confettiOrigin,
        timeBetweenConfettis,
        startVelocity = 0,
    }: {
        duration?: number
        confettiOrigin?: { x: number; y: number }
        timeBetweenConfettis?: number
        startVelocity?: number
    } = {}) {
        // straight up stolen from canvas-confetti demo website
        var animationEnd = Date.now() + duration
        var skew = 1

        function randomInRange(min, max) {
            return Math.random() * (max - min) + min
        }

        let timeAtLastConfetti = Date.now()
        ;(function frame() {
            if (timeBetweenConfettis) {
                var now = Date.now()
                var timeSinceLastConfetti = now - timeAtLastConfetti
                if (timeSinceLastConfetti > timeBetweenConfettis) {
                    timeAtLastConfetti = 0
                } else {
                    requestAnimationFrame(frame)
                    return
                }
                timeAtLastConfetti = now
            }

            var timeLeft = animationEnd - Date.now()
            var ticks = Math.max(200, 500 * (timeLeft / duration))
            skew = Math.max(0.8, skew - 0.001)

            // since particles fall down one at a time, we need to
            // pick a random color out of the library defaults
            const colors = [
                '#26ccff',
                '#a25afd',
                '#ff5e7e',
                '#88ff5a',
                '#fcff42',
                '#ffa62d',
                '#ff36ff',
            ]
            var color = colors[Math.floor(Math.random() * colors.length)]

            const xOrigin = confettiOrigin?.x ?? Math.random()
            // since particles fall down, skew start toward the top
            const yOrigin = confettiOrigin?.y ?? Math.random() & (skew - 0.2)
            confetti({
                particleCount: 1,
                startVelocity: startVelocity,
                ticks: ticks,
                origin: {
                    x: xOrigin,
                    y: yOrigin,
                },
                colors: [color],
                gravity: randomInRange(0.4, 0.6),
                scalar: randomInRange(0.4, 1),
                drift: randomInRange(-0.4, 0.4),
                zIndex: 10000,
            })

            if (timeLeft > 0) {
                requestAnimationFrame(frame)
            }
        })()
    }

    private calculateCenterOfElement(element: ElementRef) {
        const nativeElement = element.nativeElement
        const rect = nativeElement.getBoundingClientRect()

        // Calculate the center of the element
        const centerX = rect.left + rect.width / 2
        const centerY = rect.top + rect.height / 2

        // Normalize these coordinates to be between 0 and 1
        const normalizedX = centerX / window.innerWidth
        const normalizedY = centerY / window.innerHeight

        return { x: normalizedX, y: normalizedY }
    }
}
