<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close(false)">
    </fa-icon>

    <div class="std-modal-header">
        <div class="font-avenir font-semibold text-26-36 text-center mb-[5px] text-blurple"
            >Whoa... You’re on Fire! 🔥</div
        >
    </div>

    <div class="std-modal-body w-full">
        <div class="std-modal-body-text text-center w-full">
            <p style="margin-top: 0"
                >You've already booked all your free sessions for the week.<br /><br />Upgrade now
                for unlimited sessions with Focusmate Plus.</p
            >
        </div>

        <div class="std-modal-action-container">
            <button class="f-btn f-btn--primary f-btn-250" (click)="upgradeSelected('Upgrade now')"
                >Upgrade now</button
            >
            <button class="f-link fm-mt-15px" (click)="dialog.close(false)"
                >No thanks, maybe later</button
            >
        </div>
    </div>
</div>
