import { Injectable } from '@angular/core'
import { AnalyticsService } from './analytics.service'
import * as Sentry from '@sentry/angular-ivy'
import { DialogService } from './dialog.service'
import { DeviceDetectionService } from './device-detection.service'

@Injectable({
    providedIn: 'root',
})
export class PlayToneService {
    private endSessionTone: HTMLAudioElement
    private alertTone: HTMLAudioElement
    private rematchPopupTone: HTMLAudioElement

    private mutedEndSessionTonePlayed: boolean = false

    constructor(
        private analyticsService: AnalyticsService,
        private dialogService: DialogService,
        private deviceDetectionService: DeviceDetectionService,
    ) {
        if (!this.endSessionTone) {
            this.endSessionTone = new Audio()
            this.endSessionTone.src = '../../../assets/sounds/session_end.mp3'
            this.endSessionTone.volume = 0.8
            this.endSessionTone.load()
        }
    }

    playAlertTone(): Promise<void> {
        if (!this.alertTone) {
            this.alertTone = new Audio()
            this.alertTone.src = '../../../assets/sounds/alert2.mp3'
            this.alertTone.load()
        }
        return this.attemptPlay(this.alertTone)
    }

    playRematchPopupTone(): Promise<void> {
        if (!this.rematchPopupTone) {
            this.rematchPopupTone = new Audio()
            this.rematchPopupTone.src = '../../../assets/sounds/alert3.mp3'
            this.rematchPopupTone.load()
        }
        return this.attemptPlay(this.rematchPopupTone)
    }

    playEndSessionTone(): Promise<void> {
        this.endSessionTone.muted = false
        return this.attemptPlay(this.endSessionTone)
    }

    playEndSessionToneMuted(): void {
        // WORKAROUND: On iOS/Safari, tones will not play unless initiated
        // by a user interaction the first time. On clicking button to join
        // session, play the tone muted so that when the session finished event
        // is triggered later, the user will hear the tone.
        if (this.endSessionTone && !this.mutedEndSessionTonePlayed) {
            this.endSessionTone.muted = true

            this.endSessionTone
                .play()
                .then(() => {})
                .catch((e) => {})
            this.mutedEndSessionTonePlayed = true
        }
    }

    // helper function to play a sound and show the alert pop-up if playing fails
    private attemptPlay(tone: HTMLAudioElement): Promise<void> {
        return tone.play().catch(async (e) => {
            const isMobile = await this.deviceDetectionService.isMobileAccurate()
            if (e.name === 'NotAllowedError' && !isMobile) {
                this.analyticsService.logPlayToneFailedNotificationEvent()
                this.dialogService.openPlayToneFailed()
            }
            console.error(e)
            Sentry.captureException(e)
            throw e
        })
    }
}
