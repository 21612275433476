import { Component } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
@Component({
    selector: 'app-video-not-available-notice',
    templateUrl: './video-not-available-notice.component.html',
    styleUrls: ['./video-not-available-notice.component.scss'],
})
export class VideoNotAvailableNoticeComponent {
    public faTimes = faTimes
    constructor(
        public dialog: MatDialogRef<VideoNotAvailableNoticeComponent>,
        private analyticsService: AnalyticsService,
    ) {}
    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Video not available')
    }
}
