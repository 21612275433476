import { Injectable } from '@angular/core'
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
    Navigation,
} from '@angular/router'
import { Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class UaBookConfirmGuard {
    private sessionTime: number

    constructor(private router: Router) {
        let nav: Navigation = this.router.getCurrentNavigation()

        if (nav.extras && nav.extras.state && nav.extras.state.data) {
            this.sessionTime = nav.extras.state.data.sessionTime
        }
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let nav: Navigation = this.router.getCurrentNavigation()

        if (nav.extras && nav.extras.state && nav.extras.state.data) {
            this.sessionTime = nav.extras.state.data.sessionTime
        }

        if (!this.sessionTime) {
            let url_index = state.url.lastIndexOf('/')
            let url = state.url.substring(0, url_index)
            this.router.navigate([url])
            return false
        } else {
            return true
        }
    }
}
