import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { ServerTimeService } from '@app/core/_services/server-time.service'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

const MODAL_NAME = 'Time Sync Information'

@Component({
    selector: 'app-time-sync-info',
    templateUrl: './time-sync-info.component.html',
    styleUrl: './time-sync-info.component.scss',
})
export class TimeSyncInfoComponent {
    faTimes = faTimes

    constructor(
        public dialog: MatDialogRef<TimeSyncInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TimeSyncInfoComponent,
        private analyticsService: AnalyticsService,
        public serverTimeService: ServerTimeService,
    ) {
        this.analyticsService.logViewedModalEvent(MODAL_NAME)
    }

    onFixButtonClick(): void {
        this.analyticsService.logClickedEvent('Help me fix my device time', MODAL_NAME)
        this.dialog.close(false)
    }
}
