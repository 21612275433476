import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { ReportService } from '@app/core/_services/report.service'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

@Component({
    selector: 'app-report-profile',
    templateUrl: './report-profile.component.html',
    styleUrls: ['./report-profile.component.scss'],
})
export class ReportProfileComponent implements OnInit {
    public selectedReason: number = 0
    faTimes = faTimes
    public disableForm: boolean = true
    public commentsRequired: boolean = false
    public comments: string = ''

    reasons = [
        { value: 1, name: 'Fake Profile' },
        { value: 2, name: 'Offensive Profile' },
        { value: 3, name: 'Misconduct/Other' },
    ]

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<ReportProfileComponent>,
        private reportService: ReportService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Report Profile: Confirm')
    }

    onSelectionChanged(selection) {
        this.selectedReason = selection
        this.updateFormState()
    }

    onKeypressEvent(e) {
        this.updateFormState()
    }

    private doesSelectionRequireComments() {
        return this.reasons.some((item) => item.value === this.selectedReason)
    }

    private validCommentsEntered() {
        return this.comments && this.comments.length > 0
    }

    updateFormState() {
        const commentsRequired = this.doesSelectionRequireComments()
        const isCommentValid = this.validCommentsEntered()

        this.disableForm = commentsRequired && !isCommentValid
        this.commentsRequired = commentsRequired && !isCommentValid
    }

    reportUserProfile() {
        let codedComment: string = encodeURIComponent(this.comments)
        this.reportService
            .sendProfileReport(
                this.data.profileUrl,
                this.data.photoUrl,
                this.selectedReason,
                codedComment,
            )
            .subscribe(
                (success) => {
                    this.analyticsService.logAmplitudeReportUser(
                        this.data.source,
                        'profile',
                        this.selectedReason,
                    )
                    this.dialog.close(true)
                },
                (error) => {
                    this.dialog.close(false)
                },
            )
    }

    ngOnDestroy() {}
}
