import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ConfettiService } from '@app/core/_services/confetti.service'
import { AnalyticsService } from '@app/core/_services/analytics.service'

@Component({
    selector: 'fm-welcome-checklist-confetti-modal',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './welcome-checklist-confetti-modal.component.html',
    styleUrl: './welcome-checklist-confetti-modal.component.scss',
})
export class WelcomeChecklistConfettiModalComponent {
    @Output() doneButtonClick: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild('doneButton', { read: ElementRef, static: true }) doneButtonRef: ElementRef

    constructor(
        private confettiService: ConfettiService,
        private analyticsService: AnalyticsService,
    ) {
        this.analyticsService.logViewedModalEvent('Welcome Checklist Modal: Acknowledge Completion')
        this.confettiService.chonkyPop()
    }

    onDoneButtonClick() {
        this.analyticsService.logClickedEvent(
            'Done',
            'Welcome Checklist Modal: Acknowledge Completion',
        )
        this.confettiService.lilPopAtSpot(this.doneButtonRef)
        this.doneButtonClick.emit()
    }
}
