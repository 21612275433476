<div class="w-full">
    <div class="w-full flex flex-row justify-between items-center" [ngClass]="toggleLabelClasses">
        <div class="flex flex-row justify-between items-center w-full">
            <div class="flex flex-row items-center justify-start">
                <div>Quiet Mode</div>
                @if (showInfoModalTrigger) {
                    <div class="cursor-pointer ml-1">
                        <fa-icon
                            (click)="openInfoModal()"
                            class="text-[#808080] text-12-16"
                            [icon]="faInfoCircle"></fa-icon>
                    </div>
                }
            </div>
            <fm-toggle-switch
                matTooltip="Enable when you don’t have a mic or can’t talk (think libraries, shared spaces, etc.). Use the text chat to communicate with your partners."
                matTooltipPosition="below"
                matTooltipShowDelay="750"
                matTooltipClass="f-tooltip"
                [matTooltipDisabled]="showDescriptionText"
                [(ngModel)]="toggleValue"
                (toggleChange)="onToggleChange($event)"></fm-toggle-switch>
        </div>
    </div>
    @if (showDidYouKnowBubble) {
        <div
            class="text-bluegrey-2 text-11-15 font-avenir font-medium mt-0.5 px-2.5 py-2 bg-lemonade rounded-lg"
            >💬 Did you know? You can choose if you want to match with people in Quiet Mode in your
            <span class="underline cursor-pointer" (click)="settingsSelected()">Settings</span>.
            <div class="mt-5 font-semibold">
                <span class="cursor-pointer" (click)="closeBubble()">Close</span> ·
                <span class="cursor-pointer"
                    ><a
                        target="_blank"
                        class="no-underline"
                        href="https://support.focusmate.com/en/articles/8060080-session-settings-my-task-quiet-mode-and-partner"
                        >Learn more <fa-icon class="" [icon]="faUpRightFromSquare"></fa-icon></a
                ></span>
            </div>
        </div>
    } @else if (showDescriptionText) {
        <div class="text-bluegrey-2 text-11-15 font-avenir font-medium mt-0.5"
            >Select Quiet Mode matching preferences in your
            <span class="underline cursor-pointer" (click)="settingsSelected()">Settings</span
            >.</div
        >
    }
</div>
