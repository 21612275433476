import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@app/core/_services/api.service'
import { environment } from '@env/environment'

@Injectable()
export class ProfileQuestionsService {
    savedFormData: FormData = null

    constructor(private apiService: ApiService) {}

    getUserProfileQuestionsAnswers(): Observable<Object> {
        this.savedFormData = null
        return this.apiService.get(environment.api_url + 'info/')
    }

    setUserProfileAnswers(answers: FormData): Observable<any> {
        this.savedFormData = answers
        return this.apiService.postform(environment.api_url + 'info/', answers)
    }

    ngOnDestroy() {}
}
