import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ConfirmTransactionComponent } from './components/confirm-transaction/confirm-transaction.component'
import { GroupActionDialogComponent } from './components/group-action-dialog/group-action-dialog.component'
import { GroupInviteComponent } from './components/group-invite/group-invite.component'
import { NoShowAcknowledgeComponent } from './components/no-show-acknowledge/no-show-acknowledge.component'
import { SessionLimitExceededComponent } from './components/session-limit-exceeded/session-limit-exceeded.component'
import { SuspendedComponent } from './components/suspended/suspended.component'
import { SessionInfoCardComponent } from './components/session-info-card/session-info-card.component'
import { MaterialModule } from '@app/material/material.module'
import { SharedModule } from '@app/shared/shared.module'
import { PlanLimitReachedComponent } from './components/plan-limit-reached/plan-limit-reached.component'
import { OneLineNotificationComponent } from './components/one-line-notification/one-line-notification.component'
import { SafariWarningComponent } from './components/safari-warning/safari-warning.component'
import { PartnerIsNewUserComponent } from './components/partner-is-new-user/partner-is-new-user.component'
import { NewPartnerDialogComponent } from './components/new-partner-dialog/new-partner-dialog.component'
import { LostPartnerBeforeSessionDialogComponent } from './components/lost-partnerbefore-session-dialog/lost-partner-before-session-dialog.component'
import { UserSegmentationSurveyComponent } from './components/user-segmentation-survey/user-segmentation-survey.component'
import { PartnerIsLateComponent } from './components/rematch/partner-is-late/partner-is-late.component'
import { FindingPartnerComponent } from './components/rematch/finding-partner/finding-partner.component'
import { FindingPartnerFailedComponent } from './components/rematch/finding-partner-failed/finding-partner-failed.component'
import { UserLatePartnerSearchingComponent } from './components/rematch/user-late-partner-searching/user-late-partner-searching.component'
import { LateSessionCanceledComponent } from './components/rematch/late-session-canceled/late-session-canceled.component'
import { FoundNewPartnerComponent } from './components/rematch/found-new-partner/found-new-partner.component'
import { ConfirmSystemStatusComponent } from './components/confirm-system-status/confirm-system-status.component'
import { SessionConflictComponent } from './components/session-conflict/session-conflict.component'
import { InviterMeetingNoLongerAvailableComponent } from './components/inviter-meeting-no-longer-available/inviter-meeting-no-longer-available.component'
import { UserActionsModule } from '@app/user-actions/user-actions.module'
import { SessionConflictWithCancelComponent } from './components/session-conflict-with-cancel/session-conflict-with-cancel.component'
import { BookErrorSessionAlreadyStartedComponent } from './components/book-error-session-already-started/book-error-session-already-started.component'
import { PlayToneFailedComponent } from './components/play-tone-failed/play-tone-failed.component'
import { FeatureReleaseModalComponent } from './components/feature-release-modal/feature-release-modal.component'
import { YearInReviewComponent } from './components/year-in-review/year-in-review.component'
import { StandardsModule } from '@app/standards-v2/standards.module'
import { SessionPreferencesInfoComponent } from './components/session-preferences-info/session-preferences-info.component'
import { VideoFallbackNoticeComponent } from './components/video-fallback-notice/video-fallback-notice.component'
import { VideoNotAvailableNoticeComponent } from './components/video-not-available-notice/video-not-available-notice.component'
import { ForceRematchComponent } from './components/rematch/force-rematch/force-rematch.component'
import { MissingPartnerModalComponent } from './components/rematch/missing-partner-modal/missing-partner-modal.component'
import { AvailabilityListQuietModeInfoComponent } from './components/availability-list-quiet-mode-info/availability-list-quiet-mode-info.component'
import { PartnerInQuietModeModalComponent } from './components/partner-in-quiet-mode-modal/partner-in-quiet-mode-modal.component'
import { DomainUpdatedNotificationComponent } from './components/domain-updated-notification/domain-updated-notification.component'
import { TimeSyncInfoComponent } from './components/time-sync-info/time-sync-info.component'
import { GoogleOauthCallbackErrorModalComponent } from './google-oauth-callback-error-modal/google-oauth-callback-error-modal.component'
import { SwUpdateNotificationComponent } from './sw-update-notification/sw-update-notification.component'
import { ReferralPromptModalComponent } from './components/referral-prompt-modal/referral-prompt-modal.component'

@NgModule({
    declarations: [
        ConfirmTransactionComponent,
        GroupActionDialogComponent,
        GroupInviteComponent,
        NoShowAcknowledgeComponent,
        SessionInfoCardComponent,
        SessionLimitExceededComponent,
        SuspendedComponent,
        PlanLimitReachedComponent,
        OneLineNotificationComponent,
        SafariWarningComponent,
        PartnerIsNewUserComponent,
        LostPartnerBeforeSessionDialogComponent,
        NewPartnerDialogComponent,
        UserSegmentationSurveyComponent,
        PartnerIsLateComponent,
        FindingPartnerComponent,
        FindingPartnerFailedComponent,
        UserLatePartnerSearchingComponent,
        LateSessionCanceledComponent,
        FoundNewPartnerComponent,
        ConfirmSystemStatusComponent,
        SessionConflictComponent,
        SessionConflictWithCancelComponent,
        InviterMeetingNoLongerAvailableComponent,
        BookErrorSessionAlreadyStartedComponent,
        PlayToneFailedComponent,
        FeatureReleaseModalComponent,
        YearInReviewComponent,
        SessionPreferencesInfoComponent,
        VideoFallbackNoticeComponent,
        VideoNotAvailableNoticeComponent,
        ForceRematchComponent,
        MissingPartnerModalComponent,
        AvailabilityListQuietModeInfoComponent,
        PartnerInQuietModeModalComponent,
        DomainUpdatedNotificationComponent,
        SwUpdateNotificationComponent,
        TimeSyncInfoComponent,
        GoogleOauthCallbackErrorModalComponent,
        ReferralPromptModalComponent,
    ],
    exports: [
        ConfirmTransactionComponent,
        GroupActionDialogComponent,
        GroupInviteComponent,
        NoShowAcknowledgeComponent,
        SessionInfoCardComponent,
        SessionLimitExceededComponent,
        SuspendedComponent,
        PlanLimitReachedComponent,
        OneLineNotificationComponent,
        SafariWarningComponent,
        SessionPreferencesInfoComponent,
        VideoFallbackNoticeComponent,
        VideoNotAvailableNoticeComponent,
        ForceRematchComponent,
        PartnerInQuietModeModalComponent,
        DomainUpdatedNotificationComponent,
        SwUpdateNotificationComponent,
    ],
    imports: [CommonModule, StandardsModule, MaterialModule, SharedModule, UserActionsModule],
})
export class SharedDialogsModule {}
