<div class="dt-container">
    <mat-calendar
        class="w-full"
        [minDate]="minDate"
        [(selected)]="selected"
        (selectedChange)="dateChanged($event)">
    </mat-calendar>
    <app-fm-select
        class="time-select dt-time-select w-full"
        [optionsList]="timeSlotValues"
        [currentValue]="timeString"
        [fieldName]="'timeSlots'"
        [forceUpdate]="false"
        [disabled]="false"
        btnClasses="std-select w-full"
        [panelClasses]="panelClasses"
        (selectionChanged)="selectedTimeSlot($event)"></app-fm-select>
    <div
        class="w-full flex-r-c-c"
        [matTooltip]="isTimeValid ? '' : 'Selected time must be in the future'"
        matTooltipPosition="below"
        matTooltipShowDelay="200"
        matTooltipClass="f-tooltip">
        <button
            class="f-link fm-mt-5px fm-mb-10px save-btn"
            [disabled]="!isTimeValid"
            (click)="dateTimeSelected()"
            >Save
            <fa-icon
                *ngIf="!isTimeValid"
                [icon]="faInfoCircle"
                [classes]="['fa-icon-size-14']"></fa-icon>
        </button>
    </div>
</div>
