<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="closeModal()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title long-title">Oops, you missed your session! ⏰</div>
    </div>
    <div class="std-modal-body w-full">
        <div class="std-modal-body-text">
            <p>Don’t worry! We get it, life gets in the way sometimes. </p>
            <p>To help you out, we've canceled this session for you and rematched your partner.</p>
            <p>Feel free to book the next available time slot. 🙌</p>
        </div>

        <div class="std-modal-action-container">
            <button class="f-btn f-btn--primary f-btn-250" (click)="closeModal()">Ok</button>
        </div>
    </div>
</div>
