import { Component, OnInit } from '@angular/core'
import { UserService } from '@app/core/_services/user.service'

@Component({
    selector: 'app-gender-inclusion-blog',
    templateUrl: './gender-inclusion-blog.component.html',
    styleUrls: ['./gender-inclusion-blog.component.scss'],
})
export class GenderInclusionBlogComponent implements OnInit {
    constructor(public userService: UserService) {}

    ngOnInit(): void {}
}
