import { Injectable } from '@angular/core'

import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'
import { DeviceDetectionService } from './device-detection.service'
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser'
import { ExperimentsService } from './experiments.service'
import { DeviceDetectorService } from 'ngx-device-detector'
import { PartnerAtTimeSlotGroupInfo } from './available-times.service'
import { environment } from '../../../environments/environment'
import { User } from '../_models/user'
import { GroupInfo } from '../_models/rest_api'
import {
    SESSION_STATE_LATE,
    SESSION_STATE_LOST_PARTNER,
    SESSION_STATE_PENDING,
    SESSION_STATE_SEARCHING,
} from './session-state.service'
import { UserDateFormattingService } from './user-date-formatting.service'

const VIDEO_ISSUES_FORM_LINK = 'https://focusmate.typeform.com/to/oB16Dg?'
const VI_FORM_EMAIL = '&email='
const VI_FORM_USER_ID = '&user_id='
const VI_FORM_FIRST_NAME = '&first_name='
const VI_FORM_PROFILE_URL = '&profile_url=' + encodeURIComponent(environment.homeUrl + '/user/')
const VI_FORM_BROWSER = '&browser='
const VI_FORM_BROWSER_VERSION = '&browser_version='
const VI_FORM_OS = '&os='
const VI_FORM_OS_VERSION = '&os_version='
const VI_FORM_DEVICE_TYPE = 'device_type='
const VI_FORM_ROOM_URL = '&meeting_room_id='
const VI_FORM_SESSIONS = '&sessions='
const VI_FORM_PLAN_NAME = '&plan_name='
const VI_FORM_USER_AGENT = '&user_agent='

export const FIFTY_MINS_IN_MSECS = 3000000
export const FIFTEEN_MINS_IN_MSECS = 900000
export const TEN_MINS_IN_MSECS = 600000
export const FIVE_MINUTES_IN_MSECS = 300000
export const TWO_MINS_IN_MSECS = 120000
export const THIRTY_SECONDS_IN_MS = 30 * 1000
export const ONE_HOUR_IN_MSECS = 3600000

export type FmDisplayMode =
    | 'standalone'
    | 'minimal-ui'
    | 'browser'
    | 'fullscreen'
    | 'picture-in-picture'
    | 'window-controls-overlay'
    | 'unknown'

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    public FIFTY_MINS: number = 3000000
    public TWENTY_MINS: number = 1200000
    public THIRTY_MINS_IN_MSECS: number = 1800000
    public FIFTEEN_MINS: number = 900000
    public TEN_MINS: number = 600000
    public ONE_HOUR_IN_MSECS: number = 3600000

    constructor(
        private deviceDetectionService: DeviceDetectionService,
        private deviceDetectorService: DeviceDetectorService,
        private sanitizer: DomSanitizer,
    ) {}

    createReportBugFormLink(user: User): SafeUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
            environment.reportBugFormLink + '?email=' + encodeURIComponent(user.email),
        )
    }

    createVideoIssuesFormLink(user: User, roomId: string): SafeResourceUrl {
        let deviceInfo = this.deviceDetectorService.getDeviceInfo()
        let formLink: SafeResourceUrl = ''
        let device_type = 'desktop'

        if (this.deviceDetectorService.isMobile()) {
            device_type = 'mobile'
        }
        if (this.deviceDetectorService.isTablet()) {
            device_type = 'tablet'
        }

        formLink = VIDEO_ISSUES_FORM_LINK
        // NOTE: device type must be frist in list (does not have a leading &)
        formLink += VI_FORM_DEVICE_TYPE + encodeURIComponent(device_type)

        if (deviceInfo.hasOwnProperty('browser') && deviceInfo.browser) {
            formLink += VI_FORM_BROWSER + encodeURIComponent(deviceInfo.browser)
        } else {
            formLink += VI_FORM_BROWSER + encodeURIComponent('Unknown')
        }

        if (deviceInfo.hasOwnProperty('browser_version') && deviceInfo.browser_version) {
            formLink += VI_FORM_BROWSER_VERSION + encodeURIComponent(deviceInfo.browser_version)
        } else {
            formLink += VI_FORM_BROWSER_VERSION + encodeURIComponent('Unknown')
        }

        if (deviceInfo.hasOwnProperty('os') && deviceInfo.os) {
            formLink += VI_FORM_OS + encodeURIComponent(deviceInfo.os)
        } else {
            formLink += VI_FORM_OS + encodeURIComponent('Unknown')
        }

        if (deviceInfo.hasOwnProperty('os_version') && deviceInfo.os_version) {
            formLink += VI_FORM_OS_VERSION + encodeURIComponent(deviceInfo.os_version)
        } else {
            formLink += VI_FORM_OS_VERSION + encodeURIComponent('Unknown')
        }
        if (deviceInfo.hasOwnProperty('userAgent') && deviceInfo.userAgent) {
            formLink += VI_FORM_USER_AGENT + encodeURIComponent(deviceInfo.userAgent)
        } else {
            formLink += VI_FORM_USER_AGENT + encodeURIComponent('')
        }

        formLink += VI_FORM_PROFILE_URL + encodeURIComponent(user.profileUrl)
        formLink += VI_FORM_ROOM_URL + encodeURIComponent(roomId)
        formLink += VI_FORM_SESSIONS + encodeURIComponent(user.compSessions.toString())
        formLink += VI_FORM_PLAN_NAME + encodeURIComponent(user.planInfo.productAccess)
        formLink += VI_FORM_EMAIL + encodeURIComponent(user.email)
        formLink += VI_FORM_USER_ID + encodeURIComponent(user.userId)
        formLink += VI_FORM_FIRST_NAME + encodeURIComponent(user.firstName)

        return formLink
    }

    createFeebackDeviceInfo() {
        let deviceInfo = this.deviceDetectorService.getDeviceInfo()
        let browser = deviceInfo.browser || 'Unknown'
        let browserVersion = deviceInfo.browser_version || 'Unknown'
        let os = deviceInfo.os || 'Unknown'
        let osVersion = deviceInfo.os_version || 'Unknown'
        let userAgent = deviceInfo.userAgent || 'Unknown'
        let device = deviceInfo.device || 'Unknown'

        let deviceType = 'desktop'

        if (this.deviceDetectorService.isMobile()) {
            deviceType = 'mobile'
        }
        if (this.deviceDetectorService.isTablet()) {
            deviceType = 'tablet'
        }

        return {
            device: device,
            deviceType,
            browser,
            browserVersion,
            os,
            osVersion,
            userAgent,
        }
    }

    getRoomIdFromUrl(url): string {
        if (url) {
            return url.substring(url.lastIndexOf('/') + 1, url.length)
        } else {
            return ''
        }
    }

    getFirstNameFromDisplayName(displayName): string {
        if (displayName) {
            var lastIndex = displayName.lastIndexOf(' ')

            if (lastIndex === -1) {
                return displayName
            }
            return displayName.substr(0, lastIndex)
        }
        return ''
    }

    createSessionUrl(roomId: string, sessionTime: number, user: User): SafeResourceUrl {
        if (roomId === '') {
            return this.createTestSessionUrl(user)
        }

        if (this.deviceDetectionService.isMobile() && roomId.length === 36) {
            // TODO: Remove when we stop using jitsi as backup
            return user.videoLinkPrefix + roomId + user.videoLinkConfig
        } else {
            return environment.homeUrl + '/session/' + (sessionTime / 1000).toString()
        }
    }

    createTestSessionUrl(user: User) {
        return environment.homeUrl + '/session/' + user.userId
    }

    getTimerDisplay(distance: number): string {
        var minutes = Math.floor(distance / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)

        return '' + minutes + ':' + (seconds < 10 ? '0' + seconds : seconds)
    }

    getTimerNumberOfSeconds(distance: number): string {
        return Math.floor((distance % (1000 * 60)) / 1000).toString()
    }

    getMemberSinceFormat(dstring: string, tzone: string): string {
        return moment(dstring).tz(tzone).format('MMM Do, YYYY')
    }

    getDisplayDate(epoch: number, timezone: string) {
        return moment(epoch).tz(timezone).format('ddd M/D')
    }

    isSessionToday(session_time: number, timezone: string) {
        let now = moment().tz(timezone)
        let s_time = moment(session_time).tz(timezone)

        return s_time.isSame(now, 'd')
    }

    isValidTimezone(timeZone: string): boolean {
        let found: boolean = false

        if (!timeZone) {
            return false
        }
        let full_list: string[] = moment.tz.names()

        if (
            timeZone.indexOf('US/') !== -1 ||
            timeZone.indexOf('Europe/') !== -1 ||
            timeZone.indexOf('Africa/') !== -1 ||
            timeZone.indexOf('America/') !== -1 ||
            timeZone.indexOf('Asia/') !== -1 ||
            timeZone.indexOf('Atlantic/') !== -1 ||
            timeZone.indexOf('Australia/') !== -1 ||
            timeZone.indexOf('Pacific/') !== -1 ||
            timeZone.indexOf('Indian/') !== -1 ||
            timeZone.indexOf('Canada/') !== -1 ||
            timeZone.indexOf('Antarctica/')
        ) {
            for (let zone of full_list) {
                if (zone === timeZone) {
                    return true
                }
            }
        }
        return found
    }

    getGuessedTimezone(): string | null {
        let guess: string = moment.tz.guess()
        if (this.isValidTimezone(guess)) {
            return guess
        } else {
            return null
        }
    }

    hasSessionTimePassed(sessionTime: number, currentTime: number): boolean {
        if (sessionTime < currentTime) {
            return true
        }
        return false
    }

    hasSessionEnded(sessionTime: number, sessionDuration: number, currentTime: number): boolean {
        if (sessionTime + sessionDuration < currentTime) {
            return true
        }
        return false
    }

    ongoingSession(sessionTime: number, sessionDuration: number, currentTime: number): boolean {
        if (
            this.hasSessionTimePassed(sessionTime, currentTime) === true &&
            this.hasSessionEnded(sessionTime, sessionDuration, currentTime) === false
        ) {
            return true
        } else {
            return false
        }
    }

    getCurrentTimeForTimezone(timezone) {
        return moment().tz(timezone)
    }

    getCurrentTimestamp(): number {
        return moment().utc().valueOf()
    }

    getCurrentUtcIsoString(): string {
        return moment().utc().toISOString()
    }

    public showVideoIcon(sessionTime: number, sessionDuration: number) {
        // Show video icon to user 10 minutes
        // before and after session and during session
        let now = moment().utc().valueOf()

        // TODO: Create greyed out icon and tooltip indicating
        // video icon inactive until 10 mins prior
        let before: number = sessionTime - this.TEN_MINS
        let after: number = sessionTime + this.TEN_MINS + sessionDuration

        if (now >= before && now <= after) {
            return true
        } else {
            return false
        }
    }

    public canCancel(sessionTime: number) {
        // Can cancel a session anytime prior to 30mins into session
        let now = moment().utc().valueOf()

        if (sessionTime + this.THIRTY_MINS_IN_MSECS < now) {
            return false
        } else {
            return true
        }
    }

    public isTenMinsToSessionStart(sessionTime: number, now: number): boolean {
        if (now > sessionTime - this.TEN_MINS) {
            return true
        } else {
            return false
        }
    }

    public isWithinFiveMinutesAfterSessionStart(sessionTime: number, now: number): boolean {
        if (now <= sessionTime + FIVE_MINUTES_IN_MSECS && now >= sessionTime) {
            return true
        } else {
            return false
        }
    }

    /**
     * Given a confirmed session, determine if it should be displayed on the calendar.
     *
     * Typically the backend will not return sessions that should not displayed, but this isn't always the case.
     * We need to filter out past session that are unmatched - with the exception of session that have
     * started and are still in a rematchable (or rematching) state.
     **/
    public shouldDisplaySession(
        sessionTime: number,
        sessionState: number,
        hasPartner: boolean,
    ): boolean {
        // Skip past unmatched sessions unless they are rematching (or rematchable) within the rematch window
        const currentTime = this.getCurrentTimestamp()
        const isSessionTimePassed = this.hasSessionTimePassed(sessionTime, currentTime)
        const isWithinRematchWindow = this.isWithinFiveMinutesAfterSessionStart(
            sessionTime,
            currentTime,
        )

        const isRematchingOrRematchable = [
            SESSION_STATE_SEARCHING,
            SESSION_STATE_LATE,
            SESSION_STATE_PENDING,
            SESSION_STATE_LOST_PARTNER,
        ].includes(sessionState)

        if (isWithinRematchWindow && isRematchingOrRematchable) {
            return true
        }

        if (isSessionTimePassed && !hasPartner) {
            return false
        }

        return true
    }

    public buildMessageLinkUrl(sid: string, email: string) {
        return (
            environment.message_partner.link +
            environment.message_partner.email +
            email +
            environment.message_partner.sid +
            sid
        )
    }

    isStringEmpty(theString: string): boolean {
        return !theString || theString === ''
    }

    calculateWidthOfString(text: string, fontSize: string) {
        let textElement = document.createElement('span')
        document.body.appendChild(textElement)
        textElement.innerHTML = text
        textElement.style.fontFamily = 'AvenirNext'
        textElement.style.fontSize = fontSize
        textElement.style.height = 'auto'
        textElement.style.width = 'auto'
        textElement.style.position = 'absolute'
        textElement.style.whiteSpace = 'no-wrap'
        textElement.style.fontStyle = 'normal'
        textElement.style.fontWeight = '600'

        let width = Math.ceil(textElement.clientWidth)
        document.body.removeChild(textElement)
        return width
    }

    getFirstCharacterFromString(stringToCheck: string) {
        if (!stringToCheck) {
            return ''
        }
        try {
            if (stringToCheck) {
                const codePoint = stringToCheck.normalize().codePointAt(0)
                return String.fromCodePoint(codePoint)
            } else {
                return ''
            }
        } catch {
            return stringToCheck[0]
        }
    }

    genereateDisplayName(firstName, lastName, hide) {
        let displayName = ''

        if (firstName) {
            displayName = firstName

            if (lastName) {
                if (hide) {
                    displayName += ' ' + this.getFirstCharacterFromString(lastName) + '.'
                } else {
                    displayName += ' ' + lastName
                }
            } else {
                displayName = ''
            }
        }
        return displayName
    }

    getPageTitleFromRoute(url) {
        const PAGE_TITLE_DASHBOARD = 'Focusmate | Dashboard'
        const PAGE_TITLE_DEFAULT = 'Focusmate - Virtual coworking for getting anything done'
        if (url === '/dashboard') {
            return PAGE_TITLE_DASHBOARD
        } else {
            return PAGE_TITLE_DEFAULT
        }
    }

    getProfilePartnerPreferencesUrl(): SafeResourceUrl {
        return environment.homeUrl + '/profile/edit-p?type=preferences'
    }

    getInviteUrl(): SafeResourceUrl {
        const INVITE_URL = '/invite'
        return environment.homeUrl + INVITE_URL
    }

    public usersShareGroup(
        currentUserGroups: GroupInfo[],
        userGroups: PartnerAtTimeSlotGroupInfo[],
    ): boolean {
        let groups = new Set<string>()

        for (let group of currentUserGroups) {
            groups.add(group.groupId)
        }
        for (let group of userGroups) {
            if (groups.has(group.groupId)) {
                return true
            }
        }
        return false
    }

    inMaintenanceWindow(url: string): boolean {
        const maintenanceStart = 1696215600000 // Oct 1st 11pm EST
        const maintenanceEnd = maintenanceStart + this.ONE_HOUR_IN_MSECS
        const now = this.getCurrentTimestamp()
        const marketingSiteUrls: Array<string> = [
            '/',
            '/about',
            '/contact',
            '/success-stories',
            '/science',
            '/how-it-works',
            '/benefits',
            '/use-cases',
            '/testimonials',
            '/faq',
            '/community',
            '/community-guidelines',
            '/pricing/checkout/success',
            '/pricing',
            '/features',
            '/terms',
            '/privacy',
        ]

        if (marketingSiteUrls.includes(url)) {
            return false
        }

        // Put banner up 30 minutes prior to start
        if (now >= maintenanceStart - this.THIRTY_MINS_IN_MSECS && now < maintenanceEnd) {
            return true
        }
        return false
    }

    isMaintenanceBannerSticky(): boolean {
        const sticky = document.getElementById('fmStickyNav')

        if (sticky) {
            return true
        }
        return false
    }

    public sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }

    public isRunningInPWAMode(): boolean {
        try {
            if (
                window.matchMedia('(display-mode: standalone)').matches ||
                window.matchMedia('(display-mode: minimal-ui)').matches
            ) {
                return true
            }
            return false
        } catch (e) {
            return false
        }
    }

    public getDisplayMode(): FmDisplayMode {
        try {
            if (window.matchMedia('(display-mode: standalone)').matches) {
                return 'standalone'
            }
            if (window.matchMedia('(display-mode: minimal-ui)').matches) {
                return 'minimal-ui'
            }
            if (window.matchMedia('(display-mode: browser)').matches) {
                return 'browser'
            }
            if (window.matchMedia('(display-mode: fullscreen)').matches) {
                return 'fullscreen'
            }
            if (window.matchMedia('(display-mode: picture-in-picture)').matches) {
                return 'picture-in-picture'
            }
            if (window.matchMedia('(display-mode: window-controls-overlay)').matches) {
                return 'window-controls-overlay'
            }
            return 'unknown'
        } catch (e) {
            return 'unknown'
        }
    }
}
