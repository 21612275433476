import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { User } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { TimeUtilitiesService } from '@app/core/_services/time-utilities.service'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'
import { UserRelationshipService } from '@app/core/_services/user-relationship.service'
import { UserService } from '@app/core/_services/user.service'
import {
    UserAction,
    USER_ACTION_BLOCKED,
    USER_ACTION_UNBLOCKED,
} from '@app/user-actions/components/user-actions-menu/user-actions-menu.component'

const SNOOZE_UNTIL_UNSNOOZED = 'until you unsnooze them'

@Component({
    selector: 'app-people-list-item',
    templateUrl: './people-list-item.component.html',
    styleUrls: ['./people-list-item.component.scss'],
})
export class PeopleListItemComponent implements OnInit {
    @Input() userId: string
    @Input() displayName: string
    @Input() profileUrl: string
    @Input() profileImage: string
    @Input() expiresAt: string
    @Input() blockedStatus: boolean
    @Input() snoozed: boolean
    @Input() saved: boolean
    @Input() mostRecentSessionTime: number | null
    @Input() sharedSessionCount: number | null
    @Input() sharedAvailability: boolean

    public snoozedUntil: string = SNOOZE_UNTIL_UNSNOOZED
    public lastTimeWorkedWith: string = ''
    public lastTimeTooltip: string = ''
    public userImage: string
    public isUserBlocked: number = 1
    private currentUser: User

    constructor(
        private route: ActivatedRoute,
        private dateFormat: UserDateFormattingService,
        public userService: UserService,
        public userRelationshipService: UserRelationshipService,
    ) {
        this.currentUser = this.route.snapshot.data['currentUser']
    }

    ngOnInit(): void {
        if (this.blockedStatus) {
            this.isUserBlocked = 2
        }
    }

    ngOnChanges() {
        this.snoozedUntil = this.dateFormat.generateSnoozeUntilString(
            this.expiresAt,
            this.currentUser.timeZone,
        )
        this.lastTimeWorkedWith = this.dateFormat.generateLastMetString(this.mostRecentSessionTime)

        if (this.mostRecentSessionTime === null) {
            this.lastTimeTooltip = ''
        } else {
            this.lastTimeTooltip = this.dateFormat.format(
                this.mostRecentSessionTime,
                'MMM D, YYYY h:mma',
            )
        }
    }

    userActionTaken(event: UserAction) {
        if (event.userAction === USER_ACTION_BLOCKED) {
            this.isUserBlocked = 2
        }
        if (event.userAction === USER_ACTION_UNBLOCKED) {
            this.isUserBlocked = 1
        }
    }
}
