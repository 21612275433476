<form #profileUrlForm="ngForm" class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title">Set your profile URL</div>
    </div>
    <div class="std-modal-body w-full">
        <div class="f-form-field w-full">
            <label for="profileUrl">Profile URL</label>
            <input
                class="w-full"
                maxlength="30"
                type="text"
                [(ngModel)]="formModel.profileUrl"
                name="profileUrl"
                pattern="[a-z0-9-]{5,30}"
                required
                appFmAutofocus
                (ngModelChange)="onKey($event)"
                [ngClass]="error ? 'error' : ''" />
        </div>
        <div id="setProfileUrlError" class="f-error fm-mt-2px fm-mb-3px text-left w-full">{{
            error
        }}</div>
        <div class="w-full f-hint fm-mt-2px text-left">
            {{ env.homeUrl }}/user/{{ formModel.profileUrl }}<br /><br />Must be 5 to 30 characters,
            and only contain letters, numbers, and hyphens.</div
        >
    </div>
    <div class="std-modal-action-container">
        <app-spinner-button
            class="action"
            [disable]="disableSaveButton || !profileUrlForm.form.valid"
            [label]="'Save profile URL'"
            [actionComplete]="updateCompleted"
            classes="f-btn f-btn--primary f-btn-250"
            (clicked)="submitForm()">
        </app-spinner-button>

        <div class="f-link fm-mt-15px" (click)="dialog.close()">Cancel</div>
    </div>
</form>
