import { Injectable } from '@angular/core'
import { HttpHeaders, HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError, timer } from 'rxjs'
import { concat, retryWhen, take, catchError, delayWhen } from 'rxjs/operators'
import { switchMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { FirebaseTokenService } from '@app/core/_services/firebase-token.service'

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(
        private http: HttpClient,
        private firebaseTokenService: FirebaseTokenService,
    ) {}

    private retryErrorValue(status: number) {
        return !(
            status === 400 ||
            status === 413 ||
            status === 401 ||
            status === 409 ||
            status === 404
        )
    }

    private formatErrors(error: any) {
        let errMsg: string
        if (error instanceof HttpErrorResponse) {
            if (error.status === 413) {
                errMsg = 'Image file too large. Max file size 2M'
                return throwError(errMsg)
            } else if (error.status === 409) {
                return throwError(error)
            } else {
                if (typeof error.error === 'string') {
                    errMsg = error.error
                } else if (typeof error.error === 'object') {
                    errMsg = error.error.error
                } else {
                    errMsg = error.message
                }
                return throwError(errMsg)
            }
        } else {
            errMsg = error.message ? error.message : error.toString()
            return throwError(errMsg)
        }
    }

    private handleRetries() {
        return retryWhen((error) => {
            return error.pipe(
                switchMap((err: any) => {
                    if (this.retryErrorValue(err.status)) {
                        return of(err)
                    }
                    return throwError(err)
                }),
                delayWhen(() => timer(4000)),
                take(3),
                concat(throwError({ message: 'Sorry, there was an error (after 3 retries)' })),
            )
        })
    }

    get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        return this.http
            .get(path, { headers: headers, params: params })
            .pipe(this.handleRetries(), catchError(this.formatErrors))
    }

    getWithCredentials(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' })

        return this.http
            .get(path, { headers: headers, params: params, withCredentials: true })
            .pipe(this.handleRetries(), catchError(this.formatErrors))
    }

    getAnonymous(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json', Anonymous: '' })

        return this.http
            .get(path, { headers: headers, params: params })
            .pipe(this.handleRetries(), catchError(this.formatErrors))
    }

    puddt(path: string, body: Object = {}): Observable<any> {
        return this.http
            .put(path, JSON.stringify(body))
            .pipe(this.handleRetries(), catchError(this.formatErrors))
    }

    post(path: string, body: Object = {}, options: any = {}): Observable<any> {
        options.headers = new HttpHeaders({ 'Content-Type': 'application/json' })

        return this.http
            .post(path, JSON.stringify(body), options)
            .pipe(this.handleRetries(), catchError(this.formatErrors))
    }

    put(path: string, body: Object = {}): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' })

        return this.http
            .put(path, JSON.stringify(body), { headers })
            .pipe(this.handleRetries(), catchError(this.formatErrors))
    }

    postsync(path: string, body: Object = {}) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        let xhr = new XMLHttpRequest()
        xhr.open('POST', path, false)
        let token = this.firebaseTokenService.getToken()
        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.setRequestHeader('Authorization', token)
        xhr.send(JSON.stringify(body))
        /*
    return this.http.post(
      `${environment.api_url}${path}`,
      JSON.stringify(body),
      { headers }
    ).pipe(
      this.handleRetries(),
      catchError(this.formatErrors)) */
    }

    postform(path: string, formData: FormData): Observable<any> {
        return this.http
            .post(path, formData)
            .pipe(this.handleRetries(), catchError(this.formatErrors))
    }

    delete(path, headers = {}): Observable<any> {
        return this.http
            .delete(path, { headers })
            .pipe(this.handleRetries(), catchError(this.formatErrors))
    }
}
