import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { UserService } from '@app/core/_services/user.service'
import { take } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class UaSignupPersonalInfoGuard {
    constructor(
        private userService: UserService,
        private router: Router,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return Observable.create((obs) => {
            this.userService.isAuthenticated.pipe(take(1)).subscribe((authenticated) => {
                if (authenticated) {
                    obs.next(true)
                } else {
                    obs.next(
                        this.router.parseUrl(
                            '/i/' +
                                next.params['hash'] +
                                '/calendar/' +
                                next.params['timeinfo'] +
                                '/signup',
                        ),
                    )
                }
            })
        })
    }
}
