<div class="flex flex-row items-center justify-center !rounded-fm-3 h-[40px] w-full">
    <div
        class="flex-1 h-full first:!rounded-l-fm-3 last:!rounded-r-fm-3 border-2 border-solid hover:z-30 -ml-[2px] first:ml-0"
        [ngClass]="{
            'border-[#7d7ec3] bg-[#f2f3fe] z-40': item.value == currentSelection,
            'border-[#DADAED] z-20': item.value != currentSelection,
            'hover:bg-[#f2f3fe]': !disabled
        }"
        *ngFor="let item of itemList">
        <button
            [matTooltip]="item.tooltip"
            matTooltipClass="f-tooltip"
            matTooltipShowDelay="750"
            matTooltipPosition="above"
            [disabled]="disabled"
            (click)="onModelChange(item.value)"
            class="flex items-center justify-center first:!rounded-l-fm-3 last:!rounded-r-fm-3 font-avenir text-blurple text-center h-full w-full enabled:hover:bg-[#f2f3fe]"
            [ngClass]="item.labelClasses">
            <div *ngIf="item.faIcon"><fa-icon [icon]="item.faIcon"></fa-icon> </div>
            <div>{{ item.label }}</div></button
        >
    </div>
</div>
