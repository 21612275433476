<div class="flex flex-col items-center relative box-border w-full h-full font-avenir">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="onCloseButtonClick()"></fa-icon>

    <!-- header -->
    <div
        class="pt-[50px] pb-[36px] sm:pt-[60px] sm:pb-[35px] px-[20px] sm:px-[100px] flex flex-col items-center justify-center">
        <div class="text-center text-26-36 font-semibold"> Hmm, that didn't work... </div>
    </div>

    <!-- body -->
    <div
        class="max-w-full px-[20px] sm:px-[100px] mb-[60px] flex flex-col items-center justify-center gap-[40px] text-14-19 font-medium text-bluegrey-1">
        <div>
            Looks like there was an issue connecting to your Google Account. <br /><br />Please make
            sure that you're granting Focusmate all the required calendar permissions by checking
            all the boxes during the sign up process. If you're still having trouble, feel free to
            <a
                class="underline"
                target="_blank"
                href="mailto:support&#64;focusmate.com?subject=Google Calendar Connection Issue"
                >contact us.</a
            >
        </div>

        <button class="f-btn f-btn--primary f-btn-250" (click)="onCloseButtonClick()">OK</button>
    </div>
</div>
