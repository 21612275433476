import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

@Component({
    selector: 'app-late-session-canceled',
    templateUrl: './late-session-canceled.component.html',
    styleUrls: ['./late-session-canceled.component.scss'],
})
export class LateSessionCanceledComponent implements OnInit {
    faTimes = faTimes
    constructor(
        public dialog: MatDialogRef<LateSessionCanceledComponent>,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Late: Session Canceled')
    }

    closeModal() {
        this.dialog.close()
    }
}
