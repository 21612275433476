import { Injectable } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { AnalyticsService } from './analytics.service'
import { PlayToneService } from './play-tone.service'
import * as Sentry from '@sentry/angular-ivy'

export type NotificationsPermissionState = NotificationPermission | 'unsupported'

@Injectable({
    providedIn: 'root',
})
export class BrowserNotificationsService {
    public notificationsPermissionState: ReplaySubject<NotificationsPermissionState> =
        new ReplaySubject(1)

    constructor(
        private playToneService: PlayToneService,
        private analyticsService: AnalyticsService,
    ) {
        this.notificationsPermissionState.next(this.getCurrentNotificationsPermissionsState())
    }

    public async requestNotificationPermissions(): Promise<NotificationsPermissionState> {
        if (!('Notification' in window)) {
            return 'unsupported'
        }
        const state = await Notification.requestPermission()
        this.notificationsPermissionState.next(state)
        return state
    }

    public getCurrentNotificationsPermissionsState(): NotificationsPermissionState {
        if (!('Notification' in window)) {
            return 'unsupported'
        } else {
            return Notification.permission
        }
    }

    public async notify(title: string, options: NotificationOptions = {}) {
        const permissionsState = this.getCurrentNotificationsPermissionsState()
        let notificationSuccess = true
        try {
            const notification = new Notification(title, {
                icon: '../assets/images/f_logo_small.png',
                badge: '../assets/images/f_logo_small.png',
                ...options,
            })

            notification.onclick = () => {
                notification.close()
                window.focus()
            }
        } catch (e) {
            notificationSuccess = false
            console.error(e)
            Sentry.captureException(e)
        }

        let soundSuccess = true
        try {
            await this.playToneService.playAlertTone()
        } catch (e) {
            soundSuccess = false
        }

        this.analyticsService.logDesktopNotificationEvent(
            permissionsState,
            notificationSuccess,
            soundSuccess,
        )
    }
}
