<div class="w-full" style="position: relative">
    <mat-select
        #fmSnoozeSelect
        [panelClass]="panelClasses"
        panelWidth=""
        [value]="currentSelection"
        class="hide-select"
        name="displayName"
        disableOptionCentering>
        <mat-option
            *ngFor="let item of optionsList"
            [value]="item.name"
            (click)="optionSelected(item.name)">
            <span>{{ item.name }}</span
            ><span class="expires-at">{{ item.expiresAt ? item.expiresAt : '' }}</span>
        </mat-option>
    </mat-select>
    <button
        *ngIf="optionsList && optionsList.length && optionsList.length > 0"
        [ngClass]="btnClasses"
        [disabled]="disabled"
        (click)="openSelectOptions()">
        <div class="select-text button-label">
            <div>{{ displayString }}</div>
            <div class="expires-at">{{ expiresAt }}</div>
        </div>
        <fa-icon class="caret" [icon]="faCaretDown" [classes]="['fa-caret-down-select']"></fa-icon>
    </button>
</div>
