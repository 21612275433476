<button
    [ngClass]="[buttonType, buttonSize]"
    [class.loading]="loading"
    [class.disabled]="disabled"
    (click)="onClickButton($event)"
    [disabled]="disabled || loading">
    <img
        *ngIf="buttonType === 'facebook-login' && !loading"
        class="social-icon"
        src="assets/images/social-media/facebook-colored.webp" />
    <span *ngIf="buttonType === 'facebook-login' && !loading">Continue with Facebook</span>

    <img
        *ngIf="buttonType === 'google-login' && !loading"
        class="social-icon"
        src="assets/images/social-media/google-colored.webp" />
    <span *ngIf="buttonType === 'google-login' && !loading">Continue with Google</span>

    <div *ngIf="loading && animationType == 'dots'" class="loading-dots-container">
        <div [ngClass]="buttonType" class="loading-dot loading-dot-1"></div>
        <div [ngClass]="buttonType" class="loading-dot loading-dot-2"></div>
        <div [ngClass]="buttonType" class="loading-dot loading-dot-3"></div>
    </div>
    <div *ngIf="loading && animationType == 'spinner'" class="sk-fading-circle">
        <div class="sk-circle1" [ngClass]="buttonType"></div>
        <div class="sk-circle2" [ngClass]="buttonType"></div>
        <div class="sk-circle3" [ngClass]="buttonType"></div>
        <div class="sk-circle4" [ngClass]="buttonType"></div>
        <div class="sk-circle5" [ngClass]="buttonType"></div>
        <div class="sk-circle6" [ngClass]="buttonType"></div>
        <div class="sk-circle7" [ngClass]="buttonType"></div>
        <div class="sk-circle8" [ngClass]="buttonType"></div>
        <div class="sk-circle9" [ngClass]="buttonType"></div>
        <div class="sk-circle10" [ngClass]="buttonType"></div>
        <div class="sk-circle11" [ngClass]="buttonType"></div>
        <div class="sk-circle12" [ngClass]="buttonType"></div>
    </div>

    <ng-content *ngIf="!loading"></ng-content>
</button>
