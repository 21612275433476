import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { UserService } from '@app/core/_services/user.service'

@Injectable({
    providedIn: 'root',
})
export class UABaseGuard {
    constructor(
        private router: Router,
        private userService: UserService,
    ) {}

    // Guard check when trying to load the calendar.
    // Only allow it if user is logged in and onboard is complete AND
    // required user information has been provided
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return Observable.create((obs) => {
            let urlTree = this.router.parseUrl(state.url)
            let savedQueryParams = urlTree.queryParams
            urlTree.queryParams = {}
            this.userService.isAuthenticated.pipe(take(1)).subscribe((authenticated) => {
                let newUrlTree: UrlTree
                if (authenticated) {
                    newUrlTree = this.router.parseUrl(urlTree.toString() + '/calendar')
                } else {
                    newUrlTree = this.router.parseUrl(urlTree.toString() + '/welcome')
                }
                newUrlTree.queryParams = savedQueryParams
                obs.next(newUrlTree)
            })
        })
    }
}
