import { Injectable } from '@angular/core'
import { User } from '../_models'

export const DISMISSED_YEAR_IN_REVIEW_MODAL = 'dismissedYearInReviewModal2023'
export const GOOGLE_CALENDAR_RELEASE_MODAL_VIEWED = 'gcalReleaseModalViewed'
export const DOMAIN_UPDATED_NOTIFICATION_VIEWED = 'domainUpdatedNotificationViewed'

type FeatureName = 'yearInReview' | 'enableServiceWorker'

@Injectable({
    providedIn: 'root',
})
export class FeatureCheckService {
    constructor() {}

    hasViewedGoogleCalendarReleaseModal(user: User): boolean {
        // Allow e2e tests to pass
        if (user.email === 'fmtestuserone@gmail.com' || user.email === 'fmtestusertwo@gmail.com') {
            return true
        }
        return user.properties[GOOGLE_CALENDAR_RELEASE_MODAL_VIEWED] ?? false
    }

    hasViewedYearInReviewModal(user: User): boolean {
        return user.properties[DISMISSED_YEAR_IN_REVIEW_MODAL] ?? false
    }

    hasViewedDomainUpdatedNotification(user: User): boolean {
        return user.properties[DOMAIN_UPDATED_NOTIFICATION_VIEWED] ?? false
    }

    public checkFeature(featureName: FeatureName, user: User): boolean {
        return user.features && user.features[featureName]
    }
}
