import {
    Component,
    OnInit,
    Input,
    HostListener,
    ViewChild,
    Signal,
    ElementRef,
} from '@angular/core'
import { AuthService } from '@app/core/_services/auth.service'
import { CalendarViewService } from '@app/core/_services/calendar-view.service'
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { firstValueFrom, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { MediaQueryService } from '@app/core/_services/media-query.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { UserService } from '@app/core/_services/user.service'
import { User } from '@app/core/_models'
import { GroupService } from '@app/core/_services/group.service'
import { SafeUrl } from '@angular/platform-browser'
import { StripeService } from '@app/core/_services/stripe.service'
import { DeviceDetectionService } from '@app/core/_services/device-detection.service'
import { trigger } from '@angular/animations'
import { MatMenuTrigger } from '@angular/material/menu'
import {
    faInfoCircle,
    faPaperPlane,
    faQuestionCircle,
    faBars,
    faGift,
} from '@fortawesome/free-solid-svg-icons'
import { MODAL_VERIFY_EMAIL } from '../email-verify/email-verify.component'
import { XHR, XHRInitial, XHRLoading } from '@app/core/_models/xhr'
import {
    faClockEight,
    faUserGroup,
    faCalendarStar,
    faCircleInfo,
} from '@fortawesome/pro-regular-svg-icons'
import { faCaretDown, faStar } from '@fortawesome/pro-solid-svg-icons'
import { RewardsReferralService } from '@app/core/_services/rewards-referral.service'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@app/shared/shared.module'
import { MaterialModule } from '@app/material/material.module'
import { HomeSharedModule } from '@app/home-shared/home-shared.module'
import { WelcomeChecklistSidebarComponent } from '@app/welcome-checklist/welcome-checklist-sidebar/welcome-checklist-sidebar.component'
import { WelcomeChecklistService } from '@app/core/_services/welcome-checklist.service'
import { environment } from '@env/environment'
import { ServerTimeService } from '@app/core/_services/server-time.service'
import { ConfettiService } from '@app/core/_services/confetti.service'
import { UtilsService } from '@app/core/_services'

@Component({
    selector: 'app-bar',
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,
        WelcomeChecklistSidebarComponent,
        MaterialModule,
        HomeSharedModule,
    ],
    templateUrl: './app-bar.component.html',
    styleUrls: ['./app-bar.component.scss'],
    animations: [trigger('noop', [])],
})
export class AppBarComponent implements OnInit {
    @ViewChild('mainMenu', { static: false }) mainMenuTrigger: MatMenuTrigger
    @ViewChild('helpMenu', { static: false }) helpMenuTrigger: MatMenuTrigger
    @ViewChild('referIcon', { static: false }) referIcon: ElementRef
    @Input() showSecondaryNav: boolean = true
    @Input('showUpgradeButton') showUpgradeButtonInput

    public communityGuidelinesUrl: SafeUrl = environment.marketingHomeUrl + '/community'
    public reportBugFormLink: SafeUrl
    public buildVersion: string = environment.applicationVersion
    public faInfoCircle = faInfoCircle
    public faPaperPlane = faPaperPlane
    public faQuestionCircle = faQuestionCircle
    public faBars = faBars
    public faCalendarStar = faCalendarStar
    public faStar = faStar
    public faClockEight = faClockEight
    public faUserGroup = faUserGroup
    public faCaretDown = faCaretDown
    public faGift = faGift
    public faCircleInfo = faCircleInfo

    public trialDaysRemaining: number | null = null
    public isWelcomeChecklistActive: Signal<boolean>
    public showUpgradeButton: boolean = false
    public isSubscriptionManuallyPaused: boolean = false
    public planName: string = ''
    public router_sub: any
    public username: string = ''
    public currentUrl: string = ''
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public tours: any
    screenSize: string = ''
    public groupAdmin: boolean = false
    public userImage: string = '../../../assets/images/blank_profile.png'
    public user: User = new User()
    public isUnsupportedBrowser: boolean = false
    public emailSubject = encodeURIComponent('Focusmate Invitation')
    public bodyContent = encodeURIComponent(`Hi! 
    
Have you heard of Focusmate? 
    
I've been using it for the last few weeks and I love it. Their service enables you to video-conference with a "virtual coworker" for an hour, to hold each other accountable and keep each other company, while each working on your own projects. 
    
It's been a huge help in focusing on key tasks and limiting distractions.
    
https://www.focusmate.com/?utm_source=invite
    `)

    public mainMenuActiveClass = ''
    public helpMenuActiveClass = ''

    public xhrCreatePortal: XHR<string> = XHRInitial
    public onPeoplePageLink = false

    constructor(
        public serverTimeService: ServerTimeService,
        public groupService: GroupService,
        public analyticsService: AnalyticsService,
        private stripeService: StripeService,
        public router: Router,
        private as: AuthService,
        private ca: CalendarViewService,
        private mql: MediaQueryService,
        public userService: UserService,
        private dialogService: DialogService,
        private deviceDetectionService: DeviceDetectionService,
        public rewardsReferralService: RewardsReferralService,
        private welcomeChecklistService: WelcomeChecklistService,
        private confettiService: ConfettiService,
        private utilsService: UtilsService,
    ) {
        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
            if (
                event instanceof NavigationStart ||
                event instanceof NavigationCancel ||
                event instanceof NavigationEnd
            ) {
                this.currentUrl = event.url
                this.onPeoplePageLink = this.isOnAnPeopleMenuPage(this.currentUrl)
            }
        })
    }

    @HostListener('window:click', ['$event'])
    onClick(event: any): void {
        if (this.mainMenuActiveClass === 'active') {
            this.mainMenuActiveClass = ''
            this.mainMenuTrigger.closeMenu()
        }
        if (this.helpMenuActiveClass === 'active') {
            this.helpMenuActiveClass = ''
            this.helpMenuTrigger.closeMenu()
        }
    }

    ngAfterViewInit() {
        this.helpMenuTrigger.menuOpened.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            setTimeout(() => {
                this.helpMenuActiveClass = 'active'
                this.analyticsService.logClickedEvent('Help', 'navbar')
            }, 100)
        })
        this.helpMenuTrigger.menuClosed.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.helpMenuActiveClass = ''
        })

        this.mainMenuTrigger.menuOpened.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            setTimeout(() => {
                this.mainMenuActiveClass = 'active'
                this.analyticsService.logClickedEvent('Account', 'navbar')
            }, 100)
        })
        this.mainMenuTrigger.menuClosed.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.mainMenuActiveClass = ''
        })

        this.rewardsReferralService.emphasizeReferIconSubject
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((emphasize) => {
                if (emphasize) {
                    this.referIcon.nativeElement.classList.add('hithere-animation')
                    this.confettiService.leakyConfetti(this.referIcon)
                }
            })
    }

    isOnAnPeopleMenuPage(currentUrl: string): boolean {
        if (currentUrl.startsWith('/people') || currentUrl === '/availability/favorites') {
            return true
        } else {
            return false
        }
    }

    launchVerify() {
        this.dialogService.openEmailVerificationDialog(MODAL_VERIFY_EMAIL)
    }

    launchSuspended() {
        this.dialogService.openSuspendedDialog()
    }

    sendEmailVerification() {
        this.userService.sentEmailVerification.next(true)
        this.userService.recheckEmailVerified().then((verified) => {
            if (!verified) {
                this.userService.sendEmailVerification().then((result) => {})
            }
        })
    }

    ngOnInit() {
        this.mql.mediaQueryCurrentMatch
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((mediaQuery) => {
                switch (mediaQuery) {
                    case 'small':
                        this.screenSize = this.mql.getMqName
                        break
                    case 'large':
                        this.screenSize = this.mql.getMqName
                        break
                    case 'xsmall':
                        this.screenSize = this.mql.getMqName
                        break
                    case 'mobile':
                        this.screenSize = this.mql.getMqName
                        break
                    default:
                        break
                }
            })

        this.screenSize = this.mql.getMqName

        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user: User) => {
            this.user = user
            this.isUnsupportedBrowser = !this.deviceDetectionService.isSupportedBrowserDaily()
            this.reportBugFormLink = this.utilsService.createReportBugFormLink(user)

            if (user.firstName == null) {
                user.firstName = ''
            }
            if (user.familyName == null) {
                user.familyName = ''
            }

            this.username = user.firstName + ' ' + user.familyName

            if (user.photo) {
                this.userImage = user.photo
            } else {
                this.userImage = '../../../assets/images/blank_profile.png'
            }

            for (let i = 0; i < user.group.length; ++i) {
                if (user.group[i].groupAdmin === true) {
                    this.groupAdmin = true
                }
            }

            const isUserOnBasicPlan = this.stripeService.isFreeTierPlan(user.planInfo.productAccess)
            this.showUpgradeButton = isUserOnBasicPlan || this.showUpgradeButtonInput
            this.isSubscriptionManuallyPaused = user.manualPauseStatus == 'pause_active'
            this.planName = user.planInfo.productAccess

            this.trialDaysRemaining = this.rewardsReferralService.getTrialDaysRemaining(user)

            this.isWelcomeChecklistActive = this.welcomeChecklistService.isWelcomeChecklistActive
        })
    }

    onImageFailedToLoad(event) {
        this.userImage = '../../../assets/images/blank_profile.png'
    }

    logout = () => {
        this.as.signOut()
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }

    logHelpCenter() {
        this.analyticsService.logClickedEvent('Help Center', 'helpMenu')
    }

    logContactUs() {
        this.analyticsService.logClickedEvent('Contact Us', 'helpMenu')
    }

    logInviteFriend() {
        this.analyticsService.logInviteFriendSelected()
        this.analyticsService.logClickedEvent('Invite a Friend', 'secondaryNav')
    }

    logLaunchTestSession(fromGA: string, fromAmp: string) {
        this.analyticsService.logLaunchTestSession(fromGA)
        this.analyticsService.logClickedEvent('Launch Test Session', fromAmp)

        if (this.isUnsupportedBrowser) {
            this.dialogService.openSafariWarning(this.user.userId)
        }
    }

    toggleSideNav() {
        this.ca.toggleSideNav()
    }

    async upgradeSelected() {
        this.stripeService.setUpgradeSource('navbar')
        if (!this.isSubscriptionManuallyPaused) {
            this.analyticsService.logClickedEvent('Upgrade', 'navbar')
            this.router.navigate(['/upgrade'])
        } else {
            this.analyticsService.logClickedEvent('Unpause', 'navbar')
            this.xhrCreatePortal = XHRLoading
            const portal = await firstValueFrom(this.stripeService.createBillingPortal())
            window.location.href = portal['portalUrl']
        }
    }

    timeSyncInfo() {
        this.dialogService.openTimeSyncInfoDialog()
    }

    stopPropagation(e) {
        e.stopPropagation()
    }
}
