<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title wrap-double-line-el">
            You've blocked {{ data.partnerName }}
        </div>
    </div>

    <div class="std-modal-body w-full text-center">
        <div class="std-modal-body-text wrap-word-break">
            <p
                >{{ data.partnerName }} won’t be able to book a session with you again. If you’re
                currently in a session together and want to leave, click “End Session”.</p
            >
            <p
                >If {{ data.partnerName }} violated the
                <a class="f-link" [href]="communityGuidelinesUrl" target="_blank"
                    >Community Guidelines</a
                >

                , please help keep our community safe by reporting them.
            </p>
        </div>

        <div class="std-modal-action-container">
            <button class="f-btn f-btn--primary f-btn-250" (click)="dialog.close()">Got it</button>
        </div>
    </div>
</div>
