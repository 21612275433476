import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'

@Component({
    selector: 'app-lost-partner-before-session-dialog',
    templateUrl: './lost-partner-before-session-dialog.component.html',
    styleUrls: ['./lost-partner-before-session-dialog.component.scss'],
})
export class LostPartnerBeforeSessionDialogComponent implements OnInit {
    constructor(
        private analyticsService: AnalyticsService,
        public dialog: MatDialogRef<LostPartnerBeforeSessionDialogComponent>,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Partner Canceled: Rematching')
    }
}
