<button type="submit" [ngClass]="classes" [disabled]="disable" (click)="buttonClicked($event)">
    <fm-icon *ngIf="fmIcon && !loading" [classes]="fmIconClass" [icon]="fmIcon"></fm-icon>
    <fa-icon
        *ngIf="faIcon && !loading"
        [style.margin-right]="label ? '5px' : '0'"
        [icon]="faIcon"
        [classes]="['']"></fa-icon>
    {{ loading ? '' : label }}
    <div *ngIf="loading" class="sk-fading-circle">
        <div class="sk-circle1" [ngClass]="colorClass"></div>
        <div class="sk-circle2" [ngClass]="colorClass"></div>
        <div class="sk-circle3" [ngClass]="colorClass"></div>
        <div class="sk-circle4" [ngClass]="colorClass"></div>
        <div class="sk-circle5" [ngClass]="colorClass"></div>
        <div class="sk-circle6" [ngClass]="colorClass"></div>
        <div class="sk-circle7" [ngClass]="colorClass"></div>
        <div class="sk-circle8" [ngClass]="colorClass"></div>
        <div class="sk-circle9" [ngClass]="colorClass"></div>
        <div class="sk-circle10" [ngClass]="colorClass"></div>
        <div class="sk-circle11" [ngClass]="colorClass"></div>
        <div class="sk-circle12" [ngClass]="colorClass"></div>
    </div>
</button>
