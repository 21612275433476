// This will be the format of the expected response from the backend
// to a api/sessions request using GET method
// datetime will be UTC representation, number of milliseconds since jan1, 1970

export type SessionPreferenceFavorites = 'noPreference' | 'preferred' | 'onlyFavorites'

export interface BookSessionPreferences {
    favorites: { value: SessionPreferenceFavorites }
    quietMode: { value: boolean }
}

export const SESSION_ACTIVITY_TYPE_ANYTHING = 100
export const SESSION_ACTIVITY_TYPE_DESK = 200
export const SESSION_ACTIVITY_TYPE_MOVING = 400
export type SessionActivityType =
    | typeof SESSION_ACTIVITY_TYPE_ANYTHING
    | typeof SESSION_ACTIVITY_TYPE_DESK
    | typeof SESSION_ACTIVITY_TYPE_MOVING

// Api Error Codes
export const API_ERROR_BOOK_SCORE_LIMIT_EXCEEDED = 0
export const API_ERROR_CANCEL_TOO_FAR_INTO_SESSION = 1
export const API_ERROR_USER_SUSPENDED = 3
export const API_ERROR_BOOK_AUTO_CANCEL_ACTIVE = 2
export const API_ERROR_BOOK_PLAN_LIMIT_EXCEEDED = 4
export const API_ERROR_BOOK_INVITER_MEEETING_NOT_AVAILABLE = 5
export const API_ERROR_BOOK_DIRECT_MEETING_NO_LONGER_AVAILABLE = 25
export const API_ERROR_BOOK_ALREADY_STARTED = 6
export const API_ERROR_BOOK_CONFLICT = 7
export const API_ERROR_SIGNUP_DEA_REJECTED = 8
export const API_ERROR_PLAN_NO_LONGER_AVAILABLE = 26
export const API_ERROR_TOO_MANY_EMAILS = 31

// Book session durations
export const SESSION_DURATION_50_MINS: number = 3000000
export const SESSION_DURATION_25_MINS: number = 1500000
export const SESSION_DURATION_75_MINS: number = 4500000

export class ConfirmedSessionsResponse {
    constructor(
        public partnerDisplayName: string = '',
        public sessionTime: number = 0,
        public meetingRoom: string = '',
        public partnerProfileUrl: string = '',
        public partnerId: string = '',
        public title: string = '',
        public partnerPhoto: string = '',
        public user_comp_sessions: number = 0,
        public group: GroupInfo[] = [],
        public status: number = 0,
        public state: number = 0,
        public duration: number = SESSION_DURATION_50_MINS,
        public snoozed: boolean = false,
        public snoozeExpiresAt: string = null,
        public saved: boolean = false,
        public sharedAvailability: boolean = false,
        public preferences: BookSessionPreferences = {
            favorites: { value: 'noPreference' },
            quietMode: { value: false },
        },
        public partnerPreferences: PartnerSessionPreferences = {
            quietMode: { value: false },
        },
        public activityType: SessionActivityType = SESSION_ACTIVITY_TYPE_ANYTHING,
    ) {}
}

export class BookSessionsResponse {
    constructor(
        public partnerDisplayName: string = '',
        public partnerProfileUrl: string = '',
        public partnerId: string = '',
        public sessionTime: number = 0,
        public meetingRoom: string = '',
        public title: string = '',
        public first_booking: boolean = false,
        public state: number = 0,
        public duration: number = SESSION_DURATION_50_MINS,
        public group: GroupInfo[] = [],
        public error: any,
        public saved: boolean = false,
        public sharedAvailability: boolean = false,
        public profileImage: string = '',
        public preferences: BookSessionPreferences = {
            favorites: { value: 'noPreference' },
            quietMode: { value: false },
        },
        public activityType: SessionActivityType = SESSION_ACTIVITY_TYPE_ANYTHING,
        public partnerPreferences: PartnerSessionPreferences = {
            quietMode: { value: false },
        },
    ) {}
}

export class PartnerInfo {
    public partnerName: string | null = null
    public partnerDisplayName: string | null = null
    public partnerPhoto: string | null = null
    public partnerId: string | null = null
    public partnerTimezone: string | null = null
    public partnerOpenedSessions: number | null = null
    public partnerTotalSessions: number | null = null
    public partnerSessions: number | null = null
    public previousPartnerId: string | null = null
    public saved: boolean = false
}

export class SessionInfo {
    public meetingRoom: string | null = null
    public hasSessionBooked: boolean = false
    public sessionTime: number | null = null
    public duration: number | null = null
    public rematchEligible: boolean = false
    public state: number | null = null
    public dailyCoToken: string | null = null
    public dailyCoTokenMuted: string | null = null
    public dailyCoTokenVideoOff: string | null = null
    public dailyCoTokenMutedVideoOff: string | null = null
    public partnerPreferences: PartnerSessionPreferences = { quietMode: { value: false } }
    public preferences: BookSessionPreferences = {
        quietMode: { value: false },
        favorites: { value: 'noPreference' },
    }
}

export class RematchCheckResponse {
    public status: number = 0
    public sessionInfo: SessionInfoResponse
}

// This is the response expected from the backend
// for a GET sessions/<session_time> query
export class SessionInfoResponse {
    public partnerInfo: PartnerInfo = new PartnerInfo()
    public sessionInfo: SessionInfo = new SessionInfo()
}

export interface ReportInfo {
    report_type: string
    profile_url?: string
    photo_url?: string
    session_time?: number
    session_id?: string
    reason_id?: number
    comment?: string
}

export class ContactFormSubmission {
    constructor(
        public from_email: string = '',
        public from_name: string = '',
        public message: string = '',
    ) {}
}

export class UpdateSessionInfo {
    constructor(
        public sessionTime: number,
        public title?: string,
        public preferences?: BookSessionPreferences,
        public activityType?: SessionActivityType,
    ) {}
}

export class Group {
    constructor(
        public groupId: string = '',
        public groupName: string = '',
        public groupLogo: string = '',
        public groupUrlCode: string = '',
        public groupDomains: string = '',
        public groupAdmin: string = '',
        public groupDripTag: string = '',
        public groupAdminProduct: string = 'Focusmate Basic',
        public groupMemberProduct: string = 'Focusmate Basic',
        public groupHasSubscription: boolean = false,
        public autoInviteWithLink: boolean = true,
    ) {}
}

export class GroupInfo {
    constructor(
        public groupId: string = '',
        public groupName: string = '',
        public groupLogo: string = '',
        public groupUrlCode: string = '',
        public groupDomains: string = '',
        public groupAdmin: boolean = false,
        public groupCreateAdmin: string = '',
        public groupMember: boolean = false,
        public groupMemberSince: string = '',
        public groupInvited: boolean = false,
        public groupInvitedAt: string = '',
        public groupInviteNotified: boolean = false,
        public autoInviteWithLink: boolean = true,
    ) {}
}
export class GroupMember {
    constructor(
        public firstName: string = '',
        public lastName: string = '',
        public photoUrl: string = '',
        public email: string = '',
        public profileUrl: string = '',
    ) {}
}
export class GroupCreate {
    constructor(
        public groupId: string = '',
        public groupName: string = '',
        public groupUrlCode: string = '',
        public groupDomains: string = '',
        public groupCreateAdmin: string = '',
        public groupDripTag: string = '',
        public groupAdminProduct: string = 'Focusmate Basic',
        public groupMemberProduct: string = 'Focusmate Basic',
        public autoInviteWithLink: boolean = true,
    ) {}
}

export class GroupFilter {
    constructor(
        public groupInfo: GroupInfo = new GroupInfo(),
        public show: boolean = true,
    ) {}
}

/* Common Models */
export interface PartnerSessionPreferences {
    quietMode: {
        value: boolean
    }
}

/* GET /v1/user-availability/list/favorites */
export interface FavoritesAvailabilityResponse {
    availability: FavoritesAvailabilityData
}
export type FavoritesAvailabilityData = {
    favoritesCount: number
    partners: { [key: string]: FavoritesAvailabilityPartner }
    availableSessions: FavoritesAvailabilitySessionInfo[]
}
export type FavoritesAvailabilityPartnerGroup = {
    groupId: string
}
export type FavoritesAvailabilityPartner = {
    firstName: string
    displayName: string
    groups: FavoritesAvailabilityPartnerGroup[]
    profilePhoto: string
    profileSlug: string
    saved: boolean
    sharedAvailability: boolean
    userId: string
    quietModeMatchAllowed: boolean
}
export type FavoritesAvailabilitySessionInfo = {
    sessionTime: number
    duration: number
    userId: string
    directOnly: boolean
    activityType: SessionActivityType
    preferences: PartnerSessionPreferences
}

/* GET /v1/available-times */
export interface TimeSlotAvailabilityResponse {
    availability: TimeSlotAvailabilityData
}
export interface TimeSlotAvailabilityData {
    partners: { [key: string]: TimeSlotAvailablePartner }
    sessions: TimeSlotSessionInfo[]
}
export interface TimeSlotAvailablePartner {
    firstName: string
    displayName: string
    groups: TimeSlotAvailablePartnerGroup[]
    profilePhoto: string
    profileSlug: string
    saved: boolean
    sharedAvailability: boolean
    quietModeMatchAllowed: boolean
}
export interface TimeSlotSessionInfo {
    sessionTime: number
    duration: number
    userId: string
    directOnly: boolean
    activityType: SessionActivityType
    preferences: PartnerSessionPreferences
}
export interface TimeSlotAvailablePartnerGroup {
    groupId: string
}

export class TimeSlotAvailabilityResponse implements TimeSlotAvailabilityResponse {
    availability: TimeSlotAvailabilityData = {
        sessions: [],
        partners: {},
    }
}

/* GET /v1/user-availability/id/<user_id> */
export interface SavedUserAvailabilityResponse {
    availability: SavedUserAvailabilityData
}
export interface SavedUserAvailabilityData {
    firstName: string
    displayName: string
    profilePhoto: string
    profileUrl: string
    quietModeMatchAllowed: boolean
    meetings: SavedUserAvailabilitySessionInfo[]
}
export interface SavedUserAvailabilitySessionInfo {
    sessionTime: number
    duration: number
    activityType: SessionActivityType
    preferences: PartnerSessionPreferences
}
