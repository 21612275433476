<div
    class="flex justify-center items-center h-10 w-full bg-lemonade text-bluegrey text-base font-semibold font-avenir -tracking-[.011em]">
    <app-loading-indicator
        *ngIf="xhrCreatePortal.state == 'loading'; else showHelp"
        [sizeClass]="'size-16'"></app-loading-indicator>
    <ng-template #showHelp>
        <span (click)="goToPortal()" class="cursor-pointer underline"
            >Please add your billing address
        </span>
        &nbsp;·&nbsp;
        <span
            class="underline"
            matTooltip="We need your address to comply with tax regulations."
            matTooltipPosition="below"
            matTooltipClass="f-tooltip"
            matTooltipShowDelay="250">
            Why?</span
        >
    </ng-template>
</div>
