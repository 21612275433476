import { Routes, RouterModule, PreloadAllModules } from '@angular/router'
import { NgModule } from '@angular/core'
import { OauthCallbackGuard } from './oauth-callback/oauth-callback.guard'

const appRoutes: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('./calendar/calendar.module').then((m) => m.CalendarModule),
    },
    {
        path: 'profile',
        loadChildren: () => import('./my-profile/my-profile.module').then((m) => m.MyProfileModule),
    },
    {
        path: 'user/:id',
        loadChildren: () =>
            import('./user-profile/user-profile.module').then((m) => m.UserProfileModule),
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    },
    {
        path: 'group-management',
        loadChildren: () => import('./group/group.module').then((m) => m.GroupModule),
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    },
    {
        path: 'launch',
        loadChildren: () => import('./launch/launch.module').then((m) => m.LaunchModule),
    },
    {
        path: 'session',
        loadChildren: () => import('./launch/launch.module').then((m) => m.LaunchModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'signup',
        loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule),
    },
    {
        path: 'oauth/authorize',
        loadChildren: () => import('./oauth/oauth.module').then((m) => m.OAuthModule),
    },
    {
        path: 'oauthcallback/:provider',
        loadComponent: () =>
            import('./oauth-callback/oauth-callback.component').then(
                (m) => m.OauthCallbackComponent,
            ),
        canActivate: [OauthCallbackGuard],
    },
    {
        path: 'sessions',
        loadChildren: () =>
            import('./my-sessions/my-sessions.module').then((m) => m.MySessionsModule),
    },
    {
        path: 'people',
        loadChildren: () => import('./people/people.module').then((m) => m.PeopleModule),
    },
    {
        path: 'upgrade',
        loadChildren: () => import('./plans/plans.module').then((m) => m.PlansModule),
    },
    {
        path: 'invite',
        loadChildren: () =>
            import('./user-referral/user-referral.module').then((m) => m.UserReferralModule),
    },
    {
        path: 'refer',
        loadChildren: () =>
            import('./rewards-referral/rewards-referral.module').then(
                (m) => m.RewardsReferralModule,
            ),
    },
    {
        path: 'i/:hash',
        loadChildren: () =>
            import('./user-availability/user-availability.module').then(
                (m) => m.UserAvailabilityModule,
            ),
    },
    {
        path: 'availability',
        loadChildren: () =>
            import('./availability-lists/availability-lists.module').then(
                (m) => m.AvailabilityListsModule,
            ),
    },
    { path: '**', redirectTo: 'dashboard' },
]

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            enableTracing: false,
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'enabled',
            paramsInheritanceStrategy: 'always',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
