import { Injectable } from '@angular/core'
import { Observable, throwError as _throw } from 'rxjs'
import { environment } from '@env/environment'

import { AnalyticsService } from '@app/core/_services/analytics.service'
import { ApiService } from '@app/core/_services/api.service'
import { map, catchError } from 'rxjs/operators'

export enum SessionStatus {
    SESSION_JOIN = 0,
    SESSION_STARTED = 1,
    SESSION_INPROGRESS = 2,
    SESSION_ENDED = 3,
    SESSION_CLOSED = 4,
    SESSION_MET_PARTNER = 5,
}

export enum TrackEvents {
    EVENT_UNKNOWN = 'Unknown',
    EVENT_SESSION_JOIN = 'videoLink',
    EVENT_SESSION_STARTED = 'sessionStarted',
    EVENT_SESSION_INPROGRESS = 'sessionInprogress',
    EVENT_SESSION_ENDED = 'sessionEnded',
    EVENT_SESSION_CLOSED = 'sessionClosed',
    EVENT_SESSION_JOIN_LOG_FAIL = 'videoLinkLogFailure',
    EVENT_SESSION_STARTED_LOG_FAIL = 'sessionStartedLogFailure',
    EVENT_SESSION_INPROGRESS_LOG_FAIL = 'sessionInprogressLogFailure',
    EVENT_SESSION_ENDED_LOG_FAIL = 'sessionEndedLogFailure',
    EVENT_SESSION_CLOSED_LOG_FAIL = 'sessionClosedLogFailure',
}

@Injectable()
export class JoinSessionTrackerService {
    constructor(
        private apiService: ApiService,
        private analyticsService: AnalyticsService,
    ) {}

    trackSessionStatus(
        meetingRoom: string,
        sessionTime: number,
        type: SessionStatus,
        source: string,
    ): Observable<any> {
        let tracker = { meetingRoom, sessionTime, eventType: type }

        return this.apiService.post(environment.api_url + 'join/', tracker).pipe(
            map((res) => {
                this.analyticsService.logTrackSessionJoin(this.mapEventToTrackEvents(type), source)
            }),
            catchError((error) => {
                this.analyticsService.logTrackSessionJoin(
                    this.mapEventToTrackEventsLogFailure(type),
                    meetingRoom,
                )

                return _throw(error)
            }),
        )
    }

    trackSessionStatusSync(sid: string, type: SessionStatus) {
        let tracker = { session_id: sid, event_type: type }
        this.apiService.postsync(environment.api_url + 'join/', tracker)
        this.analyticsService.logTrackSessionJoin(this.mapEventToTrackEvents(type), 'sync')
    }

    mapEventToTrackEvents(event: SessionStatus): TrackEvents {
        let ga_events: TrackEvents = TrackEvents.EVENT_UNKNOWN

        switch (event) {
            case SessionStatus.SESSION_JOIN:
                ga_events = TrackEvents.EVENT_SESSION_JOIN
                break
            case SessionStatus.SESSION_STARTED:
                ga_events = TrackEvents.EVENT_SESSION_STARTED
                break
            case SessionStatus.SESSION_INPROGRESS:
                ga_events = TrackEvents.EVENT_SESSION_INPROGRESS
                break
            case SessionStatus.SESSION_ENDED:
                ga_events = TrackEvents.EVENT_SESSION_ENDED
                break
            case SessionStatus.SESSION_CLOSED:
                ga_events = TrackEvents.EVENT_SESSION_CLOSED
                break
        }

        return ga_events
    }

    mapEventToTrackEventsLogFailure(event: SessionStatus): TrackEvents {
        let ga_events: TrackEvents = TrackEvents.EVENT_UNKNOWN

        switch (event) {
            case SessionStatus.SESSION_JOIN:
                ga_events = TrackEvents.EVENT_SESSION_JOIN_LOG_FAIL
                break
            case SessionStatus.SESSION_STARTED:
                ga_events = TrackEvents.EVENT_SESSION_STARTED_LOG_FAIL
                break
            case SessionStatus.SESSION_INPROGRESS:
                ga_events = TrackEvents.EVENT_SESSION_INPROGRESS_LOG_FAIL
                break
            case SessionStatus.SESSION_ENDED:
                ga_events = TrackEvents.EVENT_SESSION_ENDED_LOG_FAIL
                break
            case SessionStatus.SESSION_CLOSED:
                ga_events = TrackEvents.EVENT_SESSION_CLOSED_LOG_FAIL
                break
        }

        return ga_events
    }
}
