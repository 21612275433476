<div *ngIf="profileUrl; else doNotLinkProfile" class="profile-container" [ngClass]="size">
    <a [routerLink]="'/user/' + profileUrl" target="_blank">
        <img
            *ngIf="!useIconImage"
            (error)="onProfileImageFailedToLoad($event)"
            src="{{ userImage }}" />
        <fa-icon *ngIf="useIconImage" [icon]="profileImageIcon" [classes]="['']"></fa-icon>
    </a>
    <app-badges
        class="badges w-full"
        [size]="size"
        [snoozed]="snoozed"
        [snoozeTooltip]="snoozeTooltip"
        [saved]="saved">
    </app-badges>
</div>
<ng-template #doNotLinkProfile>
    <div class="profile-container" [ngClass]="size">
        <img
            *ngIf="!useIconImage"
            (error)="onProfileImageFailedToLoad($event)"
            src="{{ userImage }}" />
        <fa-icon *ngIf="useIconImage" [icon]="profileImageIcon" [classes]="['align-top']"></fa-icon>
        <app-badges
            class="badges w-full"
            [size]="size"
            [snoozed]="snoozed"
            [snoozeTooltip]="snoozeTooltip"
            [saved]="saved">
        </app-badges>
    </div>
</ng-template>
