import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class CookieService {
    constructor() {}

    setCookie(name: string, value: string, expirationDays: number = 365): void {
        var domainComponents = window.location.hostname.split(/\./)
        var tld = domainComponents.pop() // top-level domain (e.g. .com)
        var sld = domainComponents.pop() + '.' + tld // second-level domain (e.g. focusmate.com)

        var expireDate = new Date()
        expireDate.setTime(expireDate.getTime() + expirationDays * 24 * 60 * 60 * 1000)

        document.cookie = `${name}=${value};path=/;domain=${sld};expires=${expireDate.toUTCString()}`
    }

    getCookie(name: string): string | null {
        const nameEQ = name + '='
        const ca = document.cookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == ' ') c = c.substring(1, c.length)
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
        }
        return null
    }

    deleteCookie(name: string): void {
        this.setCookie(name, '', -1)
    }
}
