import { Injectable, inject } from '@angular/core'
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent,
    HttpErrorResponse,
} from '@angular/common/http'
import { Observable, BehaviorSubject, throwError as _throw } from 'rxjs'
import { catchError, take, finalize } from 'rxjs/operators'
import { switchMap } from 'rxjs/operators'
import { filter } from 'rxjs/operators'
import { FirebaseTokenService } from '@app/core/_services/firebase-token.service'

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
    isRefreshingToken: boolean = false
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null)

    constructor(private firebaseTokenService: FirebaseTokenService) {}

    addToken(req: HttpRequest<any>): HttpRequest<any> {
        return req.clone({
            setHeaders: {
                Authorization: this.firebaseTokenService.getToken(),
            },
        })
    }

    addAnonymous(req: HttpRequest<any>): HttpRequest<any> {
        return req.clone({
            setHeaders: {
                Authorization: '',
            },
        })
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<
        | HttpSentEvent
        | HttpHeaderResponse
        | HttpProgressEvent
        | HttpResponse<any>
        | HttpUserEvent<any>
    > {
        if (req.headers.get('Anonymous') === '') {
            return next.handle(this.addAnonymous(req)).pipe(
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        switch ((<HttpErrorResponse>error).status) {
                            case 401:
                                if (
                                    this.firebaseTokenService.checkTokenExpiredError(error) === true
                                ) {
                                    return this.handle401Error(req, next)
                                } else {
                                    return _throw(error)
                                }

                            default:
                                return _throw(error)
                        }
                    } else {
                        return _throw(error)
                    }
                }),
            )
        } else {
            return next.handle(this.addToken(req)).pipe(
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        switch ((<HttpErrorResponse>error).status) {
                            case 401:
                                if (
                                    this.firebaseTokenService.checkTokenExpiredError(error) === true
                                ) {
                                    return this.handle401Error(req, next)
                                } else {
                                    return _throw(error)
                                }

                            default:
                                return _throw(error)
                        }
                    } else {
                        return _throw(error)
                    }
                }),
            )
        }
    }

    handle400Error(error) {
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            return this.logoutUser()
        }

        return _throw(error)
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null)

            return this.firebaseTokenService.refreshToken().pipe(
                take(1),
                switchMap((newToken: string) => {
                    if (newToken) {
                        this.firebaseTokenService.setToken(newToken)
                        this.tokenSubject.next(newToken)
                        return next.handle(this.addToken(req))
                    }
                    return this.logoutUser()
                }),
                catchError((error) => {
                    this.firebaseTokenService.setToken('')
                    return this.logoutUser()
                }),
                finalize(() => {
                    this.isRefreshingToken = false
                }),
            )
        } else {
            return this.tokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((token) => {
                    this.firebaseTokenService.setToken(token)
                    return next.handle(this.addToken(req))
                }),
            )
        }
    }

    logoutUser() {
        // Route to the login page (implementation up to you)
        return _throw('')
    }
}
