import { Injectable } from '@angular/core'
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router'
import { Observable, of } from 'rxjs'
import { first } from 'rxjs/operators'
import { User } from '../_models'
import { UserService } from '../_services/user.service'

@Injectable({
    providedIn: 'root',
})
export class UserResolver {
    constructor(private userService: UserService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
        return this.userService.currentUser.pipe(first())
    }
}
