<div class="std-modal-container">
    <div class="std-modal-header">
        <div class="std-modal-title">Sorry, we couldn’t find anyone for you 😞</div>
    </div>
    <div class="std-modal-body">
        <div class="std-modal-body-text">
            <p
                >We couldn’t find a rematch for you this time, but we’re working on improvements to
                make sure no one is left behind.</p
            >
            <p>If you’ve got time, consider booking another session now.</p></div
        >
    </div>
    <div class="std-modal-action-container">
        <button class="f-btn f-btn--primary f-btn-250" (click)="goToDashboard()"
            >Back to Dashboard</button
        >
    </div>
</div>
