import { Component, Input, Output, EventEmitter, Signal } from '@angular/core'
import { Subject } from 'rxjs'
import { BookSessionsService } from '@app/core/_services/book-sessions.service'
import { BookLimitCheckService } from '@app/core/_services/book-limit-check.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { ConfirmedSessionsService } from '@app/core/_services/confirmed-sessions.service'
import { MediaQueryService } from '@app/core/_services/media-query.service'
import {
    Container,
    ConfirmedSessionsResponse,
    SESSION_DURATION_50_MINS,
    SESSION_DURATION_25_MINS,
    SESSION_DURATION_75_MINS,
    SessionPreferenceFavorites,
    SessionActivityType,
} from '@app/core/_models'
import { take, takeUntil } from 'rxjs/operators'
import { ErrorHandlerService } from '@app/core/_services/error-handler.service'
import { UserService } from '@app/core/_services/user.service'
import { User } from '@app/core/_models'

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'
import { BookingSource } from '@app/core/_services/analytics.service'
import { FIFTEEN_MINS_IN_MSECS, FeatureCheckService } from '@app/core/_services'
import { MODAL_VERIFY_EMAIL_BOOKING } from '../email-verify/email-verify.component'
import {
    faTimes,
    faCaretRight,
    faCaretDown,
    faStar,
    faCaretUp,
} from '@fortawesome/pro-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { SessionSettingsService } from '@app/core/_services/session-settings.service'
import { WelcomeChecklistService } from '@app/core/_services/welcome-checklist.service'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'
import { RewardsReferralService } from '@app/core/_services/rewards-referral.service'

const moment = _moment

const LATE_BOOKING_GRACE_PERIOD_MINUTES = 4

interface RecurringForm {
    recurType: number
    startDate: any
    timeString: string
    numSessions: number
    title: string
    duration: number
    valid: boolean
    favoritesPreference: SessionPreferenceFavorites
    onlyFavorites: boolean
    activityType: SessionActivityType
    quietMode: boolean
}

@Component({
    selector: 'fm-create-session-form',
    templateUrl: './create-session-form.component.html',
    styleUrls: ['./create-session-form.component.scss'],
})
export class CreateSessionFormComponent {
    @Input() bookingSource: BookingSource
    @Input() minimumWaitBeforeDefaultTimeslot: number = 0

    @Output() onBookingSuccess: EventEmitter<any> = new EventEmitter()
    @Output() onBookingFailure: EventEmitter<any> = new EventEmitter()
    @Output() onBookingCancel: EventEmitter<any> = new EventEmitter()

    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public session_list: number[] = new Array<number>()
    public conflict_list: number[] = new Array<number>()
    public faTimes = faTimes
    public faCaretRight = faCaretRight
    public faCaretDown = faCaretDown
    public faCaretUp = faCaretUp
    public faCalendar = faCalendar
    public faStar = faStar
    public faGlobe = null
    private formChanged: boolean = false

    public showAdvancedOptions: boolean = false

    private isWelcomeChecklistActive: Signal<boolean> =
        this.welcomeChecklistService.isWelcomeChecklistActive

    recurType = [
        { value: 1, viewValue: 'Does Not Repeat', name: 'Do not repeat' },
        { value: 3, viewValue: 'Repeat Weekly', name: ' Repeat weekly' },
        { value: 2, viewValue: 'Repeat Daily', name: ' Repeat daily' },
    ]

    recurTimes = [
        { value: 1, viewValue: '1 Time', name: '1 Time' },
        { value: 2, viewValue: '2 Times', name: '2 Times' },
        { value: 3, viewValue: '3 Times', name: '3 Times' },
        { value: 4, viewValue: '4 Times', name: '4 Times' },
        { value: 5, viewValue: '5 Times', name: '5 Times' },
        { value: 6, viewValue: '6 Times', name: '6 Times' },
        { value: 7, viewValue: '7 Times', name: '7 Times' },
        { value: 8, viewValue: '8 Times', name: '8 Times' },
        { value: 9, viewValue: '9 Times', name: '9 Times' },
        { value: 10, viewValue: '10 Times', name: '10 Times' },
    ]

    durationValues = [
        { value: SESSION_DURATION_25_MINS, name: '25 minutes' },
        { value: SESSION_DURATION_50_MINS, name: '50 minutes' },
        { value: SESSION_DURATION_75_MINS, name: '75 minutes' },
    ]

    timeSlotValues = []

    public formModel: RecurringForm = {
        recurType: 1,
        startDate: moment.tz().set({ hour: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
        numSessions: 0,
        timeString: '',
        title: '',
        valid: true,
        duration: SESSION_DURATION_25_MINS,
        favoritesPreference: this.sessionSettingsService.getSessionSettingFavoritesPreference(),
        onlyFavorites: false,
        activityType: this.sessionSettingsService.getSessionSettingActivityType(),
        quietMode: this.sessionSettingsService.quietModeEnabled(),
    }

    public minDate: Date
    public errorTime: boolean = false
    public limitRemaining: number = 0
    public confirmedSessionsArray: Container<ConfirmedSessionsResponse> =
        new Container<ConfirmedSessionsResponse>()
    public user: User
    public timeZone: string

    public startDateString: string = ''

    constructor(
        public userService: UserService,
        private dialogService: DialogService,
        private bs: BookSessionsService,
        private confirmedSessionsService: ConfirmedSessionsService,
        private mql: MediaQueryService,
        private errorHandlerService: ErrorHandlerService,
        public bookLimitCheckService: BookLimitCheckService,
        private sessionSettingsService: SessionSettingsService,
        private welcomeChecklistService: WelcomeChecklistService,
        private dateFormat: UserDateFormattingService,
        private rewardsReferralService: RewardsReferralService,
    ) {
        this.minDate = new Date()

        // create timeslot values
        for (let millis = 0; millis < 1000 * 60 * 60 * 24; millis += 1000 * 60 * 15) {
            const timeString = this.dateFormat.format(millis, 'h:mma', 'UTC')
            const timeStringAmPm = this.dateFormat.format(millis, 'h:mm a', 'UTC', '12hr')
            this.timeSlotValues.push({ value: timeStringAmPm, name: timeString })
        }
    }

    ngOnInit() {
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user: User) => {
            this.user = user
            this.timeZone = user.timeZone
            this.formModel.duration = this.sessionSettingsService.getSessionDurationSetting(user)
            this.formModel.startDate = moment
                .tz(this.timeZone)
                .set({ hour: 0, minutes: 0, seconds: 0, milliseconds: 0 })
            this.startDateString = this.dateFormat.format(this.formModel.startDate, 'YYYY-MM-DD')
        })

        this.bookLimitCheckService.currentRemaining
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((limit) => {
                this.limitRemaining = limit
            })

        this.determineFirstAvailableTimeSlot()
    }

    private hasOverlapWithSession(
        proposedStartTime: number,
        existingStartTime: number,
        existingDuration: number,
        sessionDuration: number,
    ) {
        let proposedEndTime = proposedStartTime + sessionDuration
        let existEndTime = existingStartTime + existingDuration

        if (proposedStartTime < existingStartTime && proposedEndTime <= existingStartTime) {
            return false
        } else if (proposedStartTime > existEndTime) {
            return false
        } else {
            return true
        }
    }

    private determineFirstAvailableTimeSlot() {
        this.confirmedSessionsService.confirmedSessionsSubject
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((conf_sessions: Container<ConfirmedSessionsResponse>) => {
                this.confirmedSessionsArray = conf_sessions

                if (!this.formChanged) {
                    let now = moment.tz(this.timeZone)
                    now = now.seconds(0)
                    now = now.milliseconds(0)
                    now = now.add(this.minimumWaitBeforeDefaultTimeslot, 'minutes')

                    if (now.minutes() >= 45 + LATE_BOOKING_GRACE_PERIOD_MINUTES) {
                        if (now.hours() === 23) {
                            this.formModel.startDate.add(1, 'd')
                        }
                        now = now.minutes(0)
                        now = now.add(1, 'hours')
                    } else if (now.minutes() >= 30 + LATE_BOOKING_GRACE_PERIOD_MINUTES) {
                        now = now.minutes(45)
                    } else if (now.minutes() >= 15 + LATE_BOOKING_GRACE_PERIOD_MINUTES) {
                        now = now.minutes(30)
                    } else if (now.minutes() >= LATE_BOOKING_GRACE_PERIOD_MINUTES) {
                        now = now.minutes(15)
                    } else {
                        now = now.minutes(0)
                    }

                    let now_ts = now.valueOf()

                    for (let i = 0; i < this.confirmedSessionsArray.length(); i++) {
                        while (
                            this.hasOverlapWithSession(
                                now_ts,
                                this.confirmedSessionsArray.getByIndex(i).sessionTime,
                                this.confirmedSessionsArray.getByIndex(i).duration,
                                this.formModel.duration,
                            )
                        ) {
                            now_ts =
                                this.confirmedSessionsArray.getByIndex(i).sessionTime +
                                Math.floor(
                                    this.confirmedSessionsArray.getByIndex(i).duration /
                                        FIFTEEN_MINS_IN_MSECS,
                                ) *
                                    FIFTEEN_MINS_IN_MSECS +
                                FIFTEEN_MINS_IN_MSECS
                        }
                    }

                    this.formModel.timeString = this.dateFormat.format(
                        now_ts,
                        'h:mm a',
                        this.user.timeZone,
                        '12hr',
                    )
                    this.formModel.startDate = moment(now_ts).tz(this.timeZone)
                }
            })
    }

    timeValid() {
        let parsedTimeString = []
        let hours: number
        let minutes: number
        let tempDate = this.formModel.startDate.clone()
        this.formChanged = true

        parsedTimeString = this.formModel.timeString.split(/:/)

        if (parsedTimeString[1].slice(-2) === 'pm') {
            hours = +parsedTimeString[0]
            if (hours !== 12) {
                hours += 12
            }
        } else {
            hours = +parsedTimeString[0]
            if (hours === 12) {
                hours = 0
            }
        }
        minutes = +parsedTimeString[1].slice(0, -2)
        tempDate.hours(hours)
        tempDate.minutes(minutes)

        if (tempDate >= moment().tz(this.timeZone)) {
            this.errorTime = false
        } else {
            this.errorTime = true
        }
        this.formModel.valid = !this.errorTime

        this.startDateString = this.dateFormat.format(tempDate, 'YYYY-MM-DD')
    }

    onSubmit(formData) {
        let parsedTimeString = []
        let hours: number
        let minutes: number
        let numSessionsToBook: number = 1

        parsedTimeString = this.formModel.timeString.split(/:/)

        if (parsedTimeString[1].slice(-2) === 'pm') {
            hours = +parsedTimeString[0]
            if (hours !== 12) {
                hours += 12
            }
        } else {
            hours = +parsedTimeString[0]
            if (hours === 12) {
                hours = 0
            }
        }
        minutes = +parsedTimeString[1].slice(0, -2)
        this.formModel.startDate.tz(this.timeZone).hours(hours)
        this.formModel.startDate.tz(this.timeZone).minutes(minutes)

        if (this.formModel.recurType !== 1) {
            numSessionsToBook = this.formModel.numSessions
        }

        this.bs
            .bookRecurringSessions(
                this.formModel.startDate.tz(this.timeZone).valueOf(),
                this.formModel.recurType,
                numSessionsToBook,
                this.timeZone,
                this.formModel.title,
                this.confirmedSessionsArray,
                this.formModel.duration,
                this.formModel.favoritesPreference,
                this.formModel.activityType,
                this.formModel.quietMode,
                this.bookingSource,
            )
            .pipe(take(1))
            .subscribe(
                (sessions) => {
                    let parsedRes = this.bs.parseBookResponse(sessions)
                    let message: string = ''

                    if (parsedRes['numSuccess'] > 0) {
                        message = 'Booking saved!'

                        if (parsedRes['numSuccess'] > 1) {
                            message = 'Bookings saved!'
                        }
                    }

                    this.onBookingSuccess.emit({ value: true, message })

                    if (this.user.emailVerified === false) {
                        this.dialogService
                            .openEmailVerificationDialog(MODAL_VERIFY_EMAIL_BOOKING)
                            .afterClosed()
                            .pipe(take(1))
                            .subscribe(() => {
                                this.processParsedResult(parsedRes)
                            })
                    } else {
                        this.processParsedResult(parsedRes)
                    }
                },
                (error) => {
                    this.onBookingFailure.emit(error)
                    this.errorHandlerService.handleErrorResponse(error)
                },
            )
    }

    processParsedResult(parsedRes) {
        if (parsedRes['planLimitReached'] === true) {
            this.dialogService.openPlanLimitReachedDialog()
        } else if (
            !this.isWelcomeChecklistActive() &&
            !this.user.properties.viewedSegmentationSurveyModal &&
            this.user.compSessions >= 3
        ) {
            this.dialogService.openSegmentationSurveyModal()
        } else if (
            !this.isWelcomeChecklistActive() &&
            this.rewardsReferralService.shouldSeeReferralPrompt(this.user)
        ) {
            this.dialogService.openReferralPromptModal()
        }
    }

    getTouchUi(): boolean {
        if (this.mql.getMqName === 'mobile' || this.mql.getMqName === 'xsmall') {
            return true
        } else {
            return false
        }
    }

    onCancelCreate() {
        this.onBookingCancel.emit()
    }

    selectedTimeSlot(e) {
        this.formModel.timeString = e
        this.timeValid()
    }

    selectedDuration(e) {
        this.formModel.duration = e

        if (!this.formChanged) {
            this.determineFirstAvailableTimeSlot()
        }
    }

    selectedRecurType(e) {
        if (e != 1) {
            if (this.limitRemaining <= 10) {
                this.formModel.numSessions = this.limitRemaining
            } else {
                this.formModel.numSessions = 10
            }
        } else {
            this.formModel.numSessions = 0
        }
        this.formModel.recurType = e
    }

    selectedRecurFrequency(e) {
        this.formModel.numSessions = e
    }

    public toggleThePanel() {
        this.showAdvancedOptions = !this.showAdvancedOptions
    }

    public onActivityChanged(e) {
        this.formModel.activityType = e
    }

    public onQuietModeChanged(e) {
        this.formModel.quietMode = e
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
