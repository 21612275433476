@if (serverTimeService.systemTimeOutOfSync()) {
    <div class="flex flex-row justify-center items-center w-full h-8 bg-red-warn">
        <div
            class="text-bluegrey font-medium font-avenir text-14-19 cursor-pointer flex flex-row justify-center items-center"
            (click)="timeSyncInfo()">
            <div>
                <span class="font-semibold"> WARNING:</span> Your system time may be out of sync
            </div>
            <div class="ml-1 mt-0.5">
                <fa-icon [icon]="faCircleInfo"></fa-icon>
            </div>
        </div>
    </div>
}

<div
    *ngIf="trialDaysRemaining && trialDaysRemaining > 0"
    class="w-full h-[35px] flex items-center justify-center bg-tarotea font-semibold text-[14px] text-bluegrey md:hidden font-avenir"
    >{{ trialDaysRemaining }} days left in trial</div
>

<mat-toolbar class="fm-main-toolbar">
    <a
        routerLink="/dashboard"
        class="fm-site-nav__logo fm-app-bar-logo"
        [ngClass]="
            showUpgradeButton && user.features['inviteUserAvailabilityEnabled'] ? 'hide-me' : ''
        ">
        <fm-logo-wordmark [color]="'#ffffff'"></fm-logo-wordmark>
    </a>

    <span
        class="example-fill-remaining-space"
        [ngClass]="
            showUpgradeButton && user.features['inviteUserAvailabilityEnabled'] ? 'hide-me' : ''
        "></span>

    <span
        *ngIf="trialDaysRemaining && trialDaysRemaining > 0"
        class="hidden md:flex font-semibold text-[14px] text-white px-3 font-avenir"
        >{{ trialDaysRemaining }} days left in trial</span
    >

    <app-loading-indicator
        *ngIf="xhrCreatePortal.state == 'loading'"
        style="margin-right: 5px"
        colorClass="primary"
        [sizeClass]="'size-12'"></app-loading-indicator>
    <button
        *ngIf="showUpgradeButton"
        (click)="upgradeSelected()"
        class="fm-btn fm-btn--brand-1 upgrade-btn"
        [ngClass]="user.features['inviteUserAvailabilityEnabled'] ? 'shift-me' : ''"
        >{{ isSubscriptionManuallyPaused ? 'Unpause' : 'Upgrade' }}
    </button>

    <div class="help-menu-btn-container">
        <a
            class="fm-main-help-btn"
            aria-label="Refer"
            [ngClass]="currentUrl == '/refer' ? 'active' : ''"
            routerLink="/refer">
            <span #referIcon class="text-lg cursor-pointer">🎁</span>
            <div class="help-text">Refer</div>
        </a>
    </div>

    <div class="help-menu-btn-container">
        <button
            #helpMenu="matMenuTrigger"
            [matMenuTriggerFor]="help"
            class="fm-main-help-btn"
            [ngClass]="helpMenuActiveClass"
            aria-label="Help">
            <fa-icon
                [icon]="faQuestionCircle"
                [classes]="['fa-navbar-icons']"
                style="cursor: pointer"></fa-icon>
            <div class="help-text">Help</div>
        </button>
    </div>

    <mat-menu backdropClass="main-menu-backdrop" class="fm-menu fm-main-menu" #help="matMenu">
        <a
            class="fm-faq-text fm-menu-item-text"
            target="_blank"
            href="https://support.focusmate.com/en/articles/9110188-getting-started">
            <button class="fm-menu-item-text" mat-menu-item>Getting Started</button>
        </a>
        @if (screenSize == 'mobile' || screenSize == 'xsmall') {
            @if (isUnsupportedBrowser) {
                <a
                    class="fm-faq-text fm-menu-item-text"
                    (click)="logLaunchTestSession('mainMenu', 'navBarDropdown')">
                    <button class="fm-menu-item-text" mat-menu-item>Launch Test Session</button>
                </a>
            } @else {
                <a
                    class="fm-faq-text fm-menu-item-text"
                    (click)="logLaunchTestSession('mainMenu', 'navBarDropdown')"
                    [routerLink]="'/session/' + user.userId">
                    <button class="fm-menu-item-text" mat-menu-item>Launch Test Session</button>
                </a>
            }
        }

        <a class="fm-faq-text fm-menu-item-text" [href]="communityGuidelinesUrl" target="_blank">
            <button class="fm-menu-item-text" mat-menu-item>Community Guidelines</button>
        </a>

        <a
            class="fm-faq-text fm-menu-item-text"
            href="https://support.focusmate.com"
            target="_blank"
            (click)="logHelpCenter()">
            <button class="fm-menu-item-text" mat-menu-item>Help Center</button>
        </a>
        <a class="fm-faq-text fm-menu-item-text" [href]="reportBugFormLink" target="_blank">
            <button class="fm-menu-item-text" mat-menu-item>Report a Bug</button>
        </a>
        <a
            class="fm-faq-text fm-menu-item-text"
            href="https://support.focusmate.com/en/articles/4470666-contact-us"
            target="_blank"
            (click)="logContactUs()">
            <button class="fm-menu-item-text" mat-menu-item>Contact Us</button>
        </a>
        <div
            class="border-t border-grey-4 flex flex-row justify-start items-center h-12 px-4 font-avenir"
            (click)="stopPropagation($event)">
            <div class="text-bluegrey-2 text-12-16 font-medium opacity-70"
                >Build: {{ buildVersion }}
            </div>
        </div>
    </mat-menu>

    <button
        #mainMenu="matMenuTrigger"
        [matMenuTriggerFor]="menu"
        class="main-menu-btn"
        aria-label="Account"
        [ngClass]="mainMenuActiveClass">
        <img
            *ngIf="userImage != ''"
            class="main-menu-img"
            (error)="onImageFailedToLoad($event)"
            src="{{ userImage }}" />
        <div id="main-menu-overlay"></div>
    </button>
    <mat-menu backdropClass="main-menu-backdrop" class="fm-menu fm-main-menu" #menu="matMenu">
        <a class="fm-menu-item-header" routerLink="/profile">
            <button class="fm-menu-item-header" mat-menu-item>
                <div class="menu-header overflow-hidden">
                    <img
                        *ngIf="userImage != ''"
                        class="fm-user-img"
                        (error)="onImageFailedToLoad($event)"
                        src="{{ userImage }}" />
                    <div class="flex-c-c-fs overflow-hidden">
                        <div class="fm-menu-item-text overflow-hidden w-full wrap-single-line-el">{{
                            username
                        }}</div>
                        <div class="menu-prod">{{ planName }}</div>
                    </div>
                </div>
            </button>
        </a>
        <a
            *ngIf="(userService.currentUser | async).fmAd"
            class="fm-faq-text fm-menu-item-text"
            routerLink="/group-management">
            <button class="fm-menu-item-text" mat-menu-item>Manage Groups</button>
        </a>
        <a
            *ngIf="(userService.currentUser | async).fmAd"
            class="fm-faq-text fm-menu-item-text"
            routerLink="/admin">
            <button class="fm-menu-item-text" mat-menu-item>Admin Console</button>
        </a>
        <a class="fm-faq-text fm-menu-item-text" routerLink="/profile/edit-p">
            <button class="fm-menu-item-text fm-menu-item-sep-remove" mat-menu-item
                >Settings</button
            >
        </a>
        <button class="fm-menu-item-text" mat-menu-item (click)="logout()">Sign Out</button>
    </mat-menu>
</mat-toolbar>

<div
    *ngIf="(userService.isAuthenticated | async) && !(userService.isEmailVerified | async)"
    class="fm-verify-nav-bar"
    [ngClass]="(userService.sentEmailVerification | async) ? 'sent' : ''">
    <ng-template #emailSent>
        <div style="display: flex" class="f-bluegray-14-n-text text-center">
            <div class="f-bluegray-14-n-text">
                We sent a verification email to {{ user.email }}. Please follow the instructions in
                it.&nbsp;
                <fa-icon
                    [icon]="faInfoCircle"
                    [classes]="['fa-verify-info']"
                    (click)="launchVerify()"
                    style="cursor: pointer"></fa-icon>
            </div>
        </div>
    </ng-template>
    <div
        *ngIf="!(userService.sentEmailVerification | async); else emailSent"
        style="display: flex"
        class="f-bluegray-14-n-text text-center">
        <div
            >To get a partner or upgrade your plan, you must verify your email address.
            <span class="f-link banner-link" (click)="sendEmailVerification()"
                >Resend verification email.</span
            >&nbsp;
            <fa-icon
                [icon]="faInfoCircle"
                [classes]="['fa-verify-info']"
                (click)="launchVerify()"
                style="cursor: pointer"></fa-icon>
        </div>
    </div>
</div>
<div *ngIf="(userService.currentUser | async).suspended" class="fm-suspend-nav-bar">
    <button mat-button class="fm-button-verify-big" (click)="launchSuspended()"> Suspended </button>
</div>

@if (isWelcomeChecklistActive()) {
    <div class="min-[951px]:hidden p-[12px]">
        <fm-welcome-checklist-sidebar></fm-welcome-checklist-sidebar>
    </div>
}

<div *ngIf="showSecondaryNav" class="fm-second-nav-bar">
    <button
        [style.visibility]="currentUrl != '/dashboard' ? 'hidden' : 'visible'"
        class="fm-hamburger-menu"
        aria-label="Hamburger Menu"
        (click)="toggleSideNav()">
        <fa-icon
            [icon]="faBars"
            [classes]="['fa-icon-size-20', 'fa-icon-color-brand-1']"
            style="cursor: pointer">
        </fa-icon>
    </button>

    <div class="fm-breadcrumb-nav">
        <a
            *ngIf="router.url != '/dashboard'; else disableDashboard"
            class="text-sm text-grey font-semibold font-avenir sm:pr-4 pr-2.5 no-underline hover:underline"
            [routerLink]="['/dashboard']"
            >Calendar</a
        >
        <ng-template #disableDashboard
            ><button class="text-sm text-blurple font-semibold font-avenir sm:pr-4 pr-2.5" disabled
                >Calendar</button
            ></ng-template
        >

        <a
            *ngIf="router.url != '/sessions'; else disableSessions"
            class="text-sm text-grey font-semibold font-avenir sm:pr-4 pr-2.5 no-underline hover:underline"
            [routerLink]="['/sessions']"
            >Sessions</a
        >
        <ng-template #disableSessions
            ><button class="text-sm text-blurple font-semibold font-avenir sm:pr-4 pr-2.5" disabled
                >Sessions</button
            ></ng-template
        >

        <a
            *ngIf="router.url != '/group-management' && groupAdmin; else disableGroups"
            class="text-sm text-grey font-semibold font-avenir sm:pr-4 pr-2.5 no-underline hover:underline"
            [routerLink]="['/group-management']"
            >Groups</a
        >
        <ng-template #disableGroups
            ><button
                *ngIf="groupAdmin"
                class="text-sm text-blurple font-semibold font-avenir sm:pr-4 pr-2.5"
                disabled
                >Groups</button
            >
        </ng-template>

        <button
            (click)="analyticsService.logClickedEvent('People', 'secondaryNav')"
            [matMenuTriggerFor]="people"
            class="flex flex-row justify-start items-center text-sm font-avenir hover:underline font-semibold"
            [ngClass]="{
                'text-blurple': onPeoplePageLink,
                'text-grey': !onPeoplePageLink
            }"
            xPosition="after"
            ><div>People</div
            ><div class="mt-0.5 text-12-16"
                ><fa-icon class="ml-1" [icon]="faCaretDown"></fa-icon></div
        ></button>
        <mat-menu #people="matMenu" class="fm-menu">
            <a
                (click)="
                    analyticsService.logClickedEvent(
                        'Favorites Availability',
                        'secondaryNavPeopleMenu'
                    )
                "
                class="font-semibold !text-[14px] font-avenir !text-grey antialiased no-underline"
                routerLink="/availability/favorites"
                mat-menu-item>
                <div class="inline-flex w-4 min-w-4 flex-row justify-start items-center mr-1">
                    <fa-icon [icon]="faCalendarStar" [classes]="['fa-icon-size-14']"></fa-icon>
                </div>
                Favorites Availability
            </a>
            <a
                (click)="analyticsService.logClickedEvent('Favorites', 'secondaryNavPeopleMenu')"
                class="font-semibold !text-[14px] font-avenir !text-grey antialiased"
                routerLink="/people"
                [queryParams]="{ type: 'favorites' }"
                mat-menu-item>
                <div class="inline-flex w-4 min-w-4 flex-row justify-start items-center mr-1">
                    <fa-icon [icon]="faStar" [classes]="['fa-icon-size-14']"></fa-icon>
                </div>
                Favorites
            </a>
            <a
                (click)="analyticsService.logClickedEvent('All partners', 'secondaryNavPeopleMenu')"
                class="font-semibold !text-[14px] font-avenir !text-grey antialiased"
                routerLink="/people"
                [queryParams]="{ type: 'partners' }"
                mat-menu-item>
                <div class="inline-flex w-4 min-w-4 flex-row justify-start items-center mr-1">
                    <fa-icon [icon]="faUserGroup" [classes]="['fa-icon-size-14']"></fa-icon>
                </div>
                All partners
            </a>
            <a
                (click)="analyticsService.logClickedEvent('Snoozed', 'secondaryNavPeopleMenu')"
                class="font-semibold !text-[14px] font-avenir !text-grey antialiased"
                routerLink="/people"
                [queryParams]="{ type: 'snoozed' }"
                mat-menu-item>
                <div class="inline-flex w-4 min-w-4 flex-row justify-start items-center mr-1">
                    <fa-icon [icon]="faClockEight" [classes]="['fa-icon-size-14']"></fa-icon>
                </div>
                Snoozed
            </a>
        </mat-menu>
        <div class="fm-breadcrumb-item-right">
            @if (screenSize != 'mobile' && screenSize != 'xsmall') {
                @if (isUnsupportedBrowser) {
                    <a
                        button
                        class="fm-breadcrumb-item"
                        (click)="logLaunchTestSession('secondaryNav', 'secondNavBar')">
                        Launch Test Session</a
                    >
                } @else {
                    <a
                        button
                        class="fm-breadcrumb-item"
                        (click)="logLaunchTestSession('secondaryNav', 'secondNavBar')"
                        [routerLink]="'/session/' + user.userId">
                        Launch Test Session</a
                    >
                }
            }

            <a
                button
                *ngIf="screenSize != 'mobile' && !user.features['inviteUserAvailabilityEnabled']"
                class="fm-breadcrumb-item"
                (click)="logInviteFriend()"
                href="mailto:?subject={{ emailSubject }}&body={{ bodyContent }}"
                target="_blank"
                >Invite a Friend</a
            >
        </div>
    </div>
</div>
<!-- @if (serverTimeService.systemTimeOutOfSync()) {
    <div class="flex flex-row justify-center items-center w-full h-8 bg-red-warn">
        <div
            class="text-bluegrey font-medium font-avenir text-14-19 cursor-pointer flex flex-row justify-center items-center"
            (click)="timeSyncInfo()">
            <div>
                <span class="font-semibold"> WARNING:</span> Your system time may be out of sync
            </div>
            <div class="ml-1 mt-0.5">
                <fa-icon [icon]="faCircleInfo"></fa-icon>
            </div>
        </div>
    </div>
} -->
