<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()"></fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title">Unlink?</div>
    </div>

    <div class="std-modal-body w-full">
        <div class="body-text">
            <b>{{ data.integration.name }}</b> will no longer have access to your Focusmate account
            information.
        </div>
    </div>
    <div class="std-modal-action-container">
        <app-spinner-button
            class="action"
            [label]="'Remove'"
            classes="f-btn f-btn--primary f-btn-250"
            (clicked)="confirmRemoveIntegration()">
        </app-spinner-button>

        <div class="f-link fm-mt-15px" (click)="dialog.close()">Cancel</div>
    </div>
</div>
