import { Component, Output, Signal, EventEmitter, Input, computed, signal } from '@angular/core'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { faCircleDot } from '@fortawesome/pro-duotone-svg-icons'
import { faCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { CommonModule } from '@angular/common'
import {
    WelcomeChecklistStep,
    WelcomeChecklistStepName,
    WelcomeChecklistSteps,
} from '@app/core/_models/welcome-checklist'

@Component({
    selector: 'fm-welcome-checklist-progress-indicator',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule],
    templateUrl: './welcome-checklist-progress-indicator.component.html',
    styleUrl: './welcome-checklist-progress-indicator.component.scss',
})
export class WelcomeChecklistProgressIndicatorComponent {
    faCircleCheck = faCircleCheck
    faCircleDot = faCircleDot
    faCircle = faCircle

    @Input() steps: Signal<WelcomeChecklistSteps>
    @Input() activeStep: Signal<WelcomeChecklistStep> = signal(null)

    @Output() stepClicked: EventEmitter<WelcomeChecklistStepName> =
        new EventEmitter<WelcomeChecklistStepName>()

    constructor() {}

    onStepClick(stepName: WelcomeChecklistStepName) {
        this.stepClicked.emit(stepName)
    }
}
