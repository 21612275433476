import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatDialogRef } from '@angular/material/dialog'
import { GoogleCalendarService } from '@app/core/_services/google-calendar.service'
import { SharedModule } from '@app/shared/shared.module'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { AnalyticsService } from '@app/core/_services/analytics.service'

@Component({
    selector: 'app-confirm-disconnect-google-account',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './confirm-disconnect-google-account.component.html',
    styleUrl: './confirm-disconnect-google-account.component.scss',
})
export class ConfirmDisconnectGoogleAccountComponent {
    faTimes = faTimes
    constructor(
        private dialog: MatDialogRef<ConfirmDisconnectGoogleAccountComponent>,
        private googleCalendarService: GoogleCalendarService,
        private analyticsService: AnalyticsService,
    ) {
        this.analyticsService.logViewedModalEvent('Confirm Disconnect Google Account')
    }

    onClickDisconnect(): void {
        this.analyticsService.logClickedEvent(
            'Disconnect Google Account',
            'Confirm Disconnect Google Account Modal',
        )
        this.googleCalendarService.disconnectGoogleCalendar().subscribe(() => {
            this.dialog.close(true)
        })
    }

    onCloseButtonClick(): void {
        this.dialog.close(false)
    }
}
