import { GroupInfo } from '@app/core/_models/rest_api'

export class ProfileSettings {
    constructor(
        public timeZone: string = '',
        public timeZone1: string = '',
        public firstName: string = '',
        public familyName: string = '',
        public displayName: string = '',
        public profileUrl: string = '',
        public hide: boolean = true,
        public calendarInvites: boolean = false,
        public weeklyStats: boolean = false,
        public tipReminders: boolean = false,
    ) {}
}

export class UserProfileInfo {
    public valid: boolean = false
    public timeZone: string = ''
    public createdOn: number = 0
    public attRate: number = 0
    public firstName: string = ''
    public displayName: string = ''
    public photoUrl: string = ''
    public compSessions: number = 0
    public qanda: any = []
    public bookLimit: number = 0
    public weeklyStreak: number = 0
    public monthlyStreak: number = 0
    public group: GroupInfo[] = []
    public email: string = ''
    public meetings: number[] = []
    public profileUrl: string = ''
    public status: number = 0
    public snoozeStatus: boolean = false
    public snoozeExpiresAt: string = ''
    public uid: string = ''
    public suspended: boolean = false
    public isVerified: boolean = true
    public saved: boolean = false
    public sharedAvailability = false
    public planInfo: any = {}
    public lastSessionTime: number | null = null
    public sharedSessions: number = 0
}

export interface QList {
    text?: string
    order?: number
}

export interface QAList {
    text?: string
    id?: number
    qs?: QList[]
}
