<div [ngClass]="classes">
    <div class="star-container" *ngIf="saved; else notSaved" (click)="removeFromSaved()">
        <fa-icon
            matTooltip="Remove from Favorites"
            matTooltipPosition="below"
            matTooltipShowDelay="200"
            matTooltipClass="f-tooltip"
            class="fm-color-brand-1"
            *ngIf="saved; else notSaved"
            [icon]="faStarSaved"></fa-icon>
    </div>
    <ng-template #notSaved>
        <div *ngIf="!disableIfViewingSelf; else disableStar">
            <div class="star-container" (click)="addToSaved()">
                <fa-icon
                    matTooltip="Add to Favorites"
                    matTooltipPosition="below"
                    matTooltipShowDelay="200"
                    matTooltipClass="f-tooltip"
                    [icon]="faStarNotSaved"></fa-icon>
            </div>
        </div>
        <ng-template #disableStar>
            <div class="star-disabled">
                <fa-icon
                    matTooltip="You are amazing, but you cannot add yourself to your Favorites list!"
                    matTooltipPosition="below"
                    matTooltipShowDelay="200"
                    matTooltipClass="f-tooltip"
                    [icon]="faStarNotSaved"></fa-icon>
            </div>
        </ng-template>
    </ng-template>
</div>
