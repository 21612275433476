import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

export interface FmButtonGroupItem {
    value: string | number
    faIcon: any
    label: string
    labelClasses: string
    tooltip: string
}

@Component({
    selector: 'fm-button-group',
    templateUrl: './fm-button-group.component.html',
    styleUrls: ['./fm-button-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FmButtonGroupComponent),
            multi: true,
        },
    ],
})
export class FmButtonGroupComponent implements ControlValueAccessor {
    @Input() itemList: Array<FmButtonGroupItem>
    @Input() disabled: boolean = false
    @Output() selectionChanged: EventEmitter<string> = new EventEmitter<string>()
    public currentSelection: string

    onChange: any = () => {}
    onTouch: any = () => {}

    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn
    }

    writeValue(value: any) {
        this.currentSelection = value
    }

    onModelChange(e: any) {
        this.currentSelection = e
        this.onChange(e)
        this.selectionChanged.emit(e)
    }

    ngOnInit() {}

    ngOnChanges(e) {}

    ngAfterViewInit() {}

    ngAfterContentInit() {}
}
