import { Component } from '@angular/core'
import { XHR, XHRInitial, XHRLoading } from '@app/core/_models/xhr'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { StripeService } from '@app/core/_services/stripe.service'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'

@Component({
    selector: 'fm-update-address-banner',
    templateUrl: './update-address-banner.component.html',
    styleUrls: ['./update-address-banner.component.scss'],
})
export class UpdateAddressBannerComponent {
    public xhrCreatePortal: XHR<string> = XHRInitial
    public faCircleInfo = faCircleInfo

    constructor(
        private analyticsService: AnalyticsService,
        private stripeService: StripeService,
    ) {}
    goToPortal() {
        this.xhrCreatePortal = XHRLoading
        this.analyticsService.logClickedEvent('Updated Billing Address', 'updateAddressBanner')

        this.stripeService.createBillingPortal().subscribe((portal) => {
            window.location.href = portal['portalUrl'] + '/customer/update'
        })
    }
}
