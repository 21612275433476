<div>
    <fa-icon
        [matTooltip]="tooltip"
        [matTooltipDisabled]="tooltip == null"
        matTooltipPosition="above"
        matTooltipShowDelay="200"
        matTooltipClass="f-tooltip"
        class="flex justify-center items-center h-full w-full align-middle"
        [icon]="faMicrophoneSlash"></fa-icon>
</div>
