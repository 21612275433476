import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { UserService } from '@app/core/_services/user.service'

@Injectable({
    providedIn: 'root',
})
export class ReferralGuard {
    constructor(
        private router: Router,
        private userService: UserService,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {
        return Observable.create((obs) => {
            this.userService.isAuthenticated.pipe(take(1)).subscribe((authenticated) => {
                if (authenticated) {
                    this.userService.currentUser.pipe(take(1)).subscribe((currentUser) => {
                        if (currentUser.features['inviteUserAvailabilityEnabled']) {
                            obs.next(true)
                        } else {
                            obs.next(this.router.parseUrl('dashboard'))
                        }
                    })
                } else {
                    obs.next(this.router.parseUrl('dashboard'))
                }
            })
        })
    }
}
