import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { User } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserService } from '@app/core/_services/user.service'
import { Subject } from 'rxjs'
import { take, takeUntil } from 'rxjs/operators'
import { environment } from '@env/environment'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

class ProfileForm {
    constructor(public profileUrl: string = '') {}
}

@Component({
    selector: 'app-edit-profile-url-modal',
    templateUrl: './edit-profile-url-modal.component.html',
    styleUrls: ['./edit-profile-url-modal.component.scss'],
})
export class EditProfileUrlModalComponent implements OnInit {
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public currentUser: User = new User()
    public formModel: ProfileForm = new ProfileForm()
    public disableSaveButton = true
    public env = environment
    public error = ''
    public updateCompleted: boolean = false
    faTimes = faTimes

    constructor(
        public dialog: MatDialogRef<EditProfileUrlModalComponent>,
        public userService: UserService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Update Profile URL')
        this.userService.currentUser.pipe(take(1)).subscribe((user) => {
            this.currentUser = user
            this.formModel.profileUrl = this.currentUser.profileUrl
            this.checkEnableSaveNameButton()
        })
    }

    checkEnableSaveNameButton() {
        if (!this.formModel.profileUrl) {
            this.disableSaveButton = true
        } else if (this.formModel.profileUrl !== this.currentUser.profileUrl) {
            this.disableSaveButton = false
        } else {
            this.disableSaveButton = true
        }
    }

    checkChangedFields(): string[] {
        let fieldArray: string[] = []
        if (this.formModel.profileUrl !== this.currentUser.profileUrl) {
            fieldArray.push('profileURL')
        }
        return fieldArray
    }

    onKey(e) {
        this.error = ''
        this.formModel.profileUrl = this.formModel.profileUrl.toLocaleLowerCase()
        this.checkEnableSaveNameButton()
    }

    submitForm() {
        this.error = ''
        let fieldsChanged = this.checkChangedFields()

        this.currentUser.profileUrl = this.formModel.profileUrl

        this.userService.update(this.currentUser, null).subscribe((res) => {
            if (res.error) {
                this.updateCompleted = true
                this.currentUser = res.user
                setTimeout(() => {
                    this.updateCompleted = false
                })
                this.error = res.error
            } else {
                if (fieldsChanged.length > 0) {
                    this.analyticsService.logProfileEditedEvent(fieldsChanged)
                }
                this.dialog.close()
            }
        })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
