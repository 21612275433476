<div [ngSwitch]="playButtonState">
    <a
        *ngSwitchCase="7"
        aria-label="Join session"
        [routerLink]="routerLink"
        [state]="routerState"
        (click)="joinSessionButtonClick()"
        class="fm-btn fm-btn--brand-1 w-full"
        style="position: relative; height: 40px">
        <fa-icon style="font-size: 10px" [icon]="faPlay"></fa-icon>
        <span class="text-12-16 font-avenir ml-1 font-semibold"> Join session</span>
    </a>

    <a
        *ngSwitchCase="5"
        (click)="joinSessionButtonClick()"
        class="fm-btn fm-btn--brand-1 w-full"
        style="position: relative; height: 40px">
        <fa-icon style="font-size: 10px" [icon]="faPlay"></fa-icon>
        <span class="text-12-16 font-avenir ml-1 font-semibold"> Join session</span>
    </a>
    <div
        *ngSwitchCase="8"
        [matTooltip]="tooltipContent"
        matTooltipPosition="above"
        matTooltipShowDelay="500"
        matTooltipClass="f-tooltip"
        [matTooltipDisabled]="videoIconEnabled">
        <button
            *ngIf="!videoIconEnabled; else linkBtn"
            [disabled]="!videoIconEnabled"
            class="fm-vid-btn-tile">
            <fa-icon style="font-size: 10px" [icon]="faPlay"></fa-icon>
            <span *ngIf="!hideJoinButtonTextLabel" class="ml-1 tile-btn-text">Join</span>
        </button>
        <ng-template #linkBtn>
            <a
                class="fm-vid-btn-tile"
                [routerLink]="routerLink"
                [state]="routerState"
                (click)="joinSessionButtonClick()">
                <fa-icon style="font-size: 10px" [icon]="faPlay"></fa-icon>
                <span *ngIf="!hideJoinButtonTextLabel" class="ml-1 tile-btn-text">Join</span>
            </a>
        </ng-template>
    </div>
    <div
        *ngSwitchCase="9"
        [matTooltip]="tooltipContent"
        matTooltipPosition="above"
        matTooltipShowDelay="500"
        matTooltipClass="f-tooltip"
        [matTooltipDisabled]="videoIconEnabled">
        <button
            *ngIf="!videoIconEnabled; else linkBtn"
            [disabled]="!videoIconEnabled"
            class="fm-vid-btn-tile">
            <fa-icon style="font-size: 10px" [icon]="faPlay"></fa-icon>
            <span *ngIf="!hideJoinButtonTextLabel" class="ml-1 tile-btn-text">Join</span>
        </button>
        <ng-template #linkBtn>
            <a
                class="fm-vid-btn-tile"
                [routerLink]="routerLink"
                [state]="routerState"
                (click)="joinSessionButtonClick()">
                <fa-icon style="font-size: 10px" [icon]="faPlay"></fa-icon>
                <span *ngIf="!hideJoinButtonTextLabel" class="ml-1 tile-btn-text">Join</span>
            </a>
        </ng-template>
    </div>
</div>
