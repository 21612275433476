import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-spinner-button',
    templateUrl: './spinner-button.component.html',
    styleUrls: ['./spinner-button.component.scss'],
})
export class SpinnerButtonComponent implements OnInit {
    @Input() disable: boolean
    @Input() classes: string = ''
    @Input() label: string
    @Input() actionComplete: boolean = false
    @Input() faIcon = null
    @Input() fmIcon = null
    @Input() fmIconClass: string = ''
    @Output() clicked: EventEmitter<Event> = new EventEmitter<Event>()

    public loading: boolean = false
    public colorClass: string = 'primary'

    constructor() {}

    ngOnInit(): void {
        if (this.classes.includes('f-btn--secondary')) {
            this.colorClass = 'secondary'
        }

        if (this.classes.includes('f-btn--session-cancel')) {
            this.colorClass = 'warn'
        }
    }

    ngOnChanges() {
        if (this.actionComplete === true) {
            this.actionComplete = false
            this.loading = false
            this.classes = this.classes.replace(' spinning', '')
        }
    }

    buttonClicked(e: Event) {
        if (!this.loading) {
            this.classes += ' spinning'
            this.loading = true
            this.clicked.emit(e)
        }
    }
}
