import { Injectable } from '@angular/core'

import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { ConfirmedSessionsService } from '@app/core/_services/confirmed-sessions.service'
import {
    Container,
    API_ERROR_BOOK_PLAN_LIMIT_EXCEEDED,
    API_ERROR_BOOK_INVITER_MEEETING_NOT_AVAILABLE,
    API_ERROR_BOOK_ALREADY_STARTED,
    API_ERROR_BOOK_CONFLICT,
    API_ERROR_BOOK_DIRECT_MEETING_NO_LONGER_AVAILABLE,
    BookSessionPreferences,
    SessionPreferenceFavorites,
    SessionActivityType,
    BookSessionsResponse,
} from '@app/core/_models'
import { ConfirmedSessionsResponse } from '@app/core/_models'
import { SelectedSessionService } from '@app/core/_services/selected-sessions.service'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'
import {
    AnalyticsService,
    BookingSource,
    BookingRecurType,
} from '@app/core/_services/analytics.service'
import { environment } from '@env/environment'
import { ApiService } from '@app/core/_services/api.service'
import { SESSION_STATE_INITIAL } from './session-state.service'

// Max duration to wait
// before querying result of book request
const MAX_WAIT_DURATION = 8000
const RETRY_WAIT_DURATION = 2000
const WAIT_BUFFER = 500

export interface BookSessionInfo {
    sessionTime: number
    title: string | null
    userAvailabilityCode: string | null
    duration: number
    userId: string | null
    preferences: BookSessionPreferences
    activityType: SessionActivityType
}

@Injectable()
export class BookSessionsService {
    // written to whenever one or more sessions are booked
    public sessionBookedSubject: Subject<void> = new Subject<void>()

    constructor(
        private apiService: ApiService,
        private selectedSessionsService: SelectedSessionService,
        private analyticsService: AnalyticsService,
        private confirmedSessionsService: ConfirmedSessionsService,
    ) {}

    private processBookResult(bookSessionList: BookSessionsResponse[], recurType: number) {
        let bookRecurType: BookingRecurType = BookingRecurType.OneTime

        if (recurType === 1) {
        } else if (recurType === 2) {
            bookRecurType = BookingRecurType.Daily
        } else {
            bookRecurType = BookingRecurType.Weekly
        }

        if (bookSessionList) {
            let numSuccessfullBookings = 0
            let earliestsessionTime = 0
            let earliestDuration = 50

            for (var j = 0; j < bookSessionList.length; j++) {
                if (!bookSessionList[j].error) {
                    numSuccessfullBookings++
                    if (
                        earliestsessionTime === 0 ||
                        earliestsessionTime > bookSessionList[j].sessionTime
                    ) {
                        earliestsessionTime = bookSessionList[j].sessionTime
                        earliestDuration = bookSessionList[j].duration
                    }

                    if (bookSessionList[j].state != SESSION_STATE_INITIAL) {
                        // If response to POST had state initial,
                        // then the partner data would not yet be set.
                        // Subsequent query will populate the confirmed
                        // session with the latest values so don't do it here
                        this.confirmedSessionsService.addNewlyBookedSession(
                            bookSessionList[j].sessionTime,
                            bookSessionList[j].partnerDisplayName,
                            bookSessionList[j].meetingRoom,
                            bookSessionList[j].partnerProfileUrl,
                            bookSessionList[j].partnerId,
                            bookSessionList[j].title,
                            bookSessionList[j].profileImage,
                            bookSessionList[j].group,
                            bookSessionList[j].state,
                            bookSessionList[j].duration,
                            bookSessionList[j].saved,
                            bookSessionList[j].sharedAvailability,
                            bookSessionList[j].preferences,
                            bookSessionList[j].activityType,
                            bookSessionList[j].partnerPreferences,
                        )
                    }
                }
                this.selectedSessionsService.deleteSelectedSession(bookSessionList[j].sessionTime)
            }
        }
    }

    bookRecurringSessions(
        start_time: number,
        rec_type: number,
        num_sessions: number,
        timezone: string,
        title: string,
        conf_sessions: Container<ConfirmedSessionsResponse>,
        duration,
        matchPreferenceFavorites: SessionPreferenceFavorites,
        activity: SessionActivityType,
        quietMode: boolean,
        source: BookingSource,
    ): Observable<any> {
        const preferences: BookSessionPreferences = {
            favorites: { value: matchPreferenceFavorites },
            quietMode: { value: quietMode },
        }
        let session_list: number[] = []

        session_list = this.generateRecurringSessionList(
            start_time,
            rec_type,
            num_sessions,
            timezone,
            conf_sessions,
        )

        let bookSessionInfo: BookSessionInfo[] = []

        session_list.forEach(function (sTime) {
            bookSessionInfo.push({
                sessionTime: sTime,
                title: title,
                userAvailabilityCode: '',
                duration: duration,
                userId: '',
                preferences,
                activityType: activity,
            })
        })

        if (session_list.length === 0) {
            const noSessionsToBook = new Observable((observer) => {
                observer.next(session_list)
                observer.complete()
            })
            return noSessionsToBook
        }

        return new Observable<any>((bookResponse) => {
            this.apiService
                .post(environment.api_url + 'session/', {
                    bookData: {
                        sessions: bookSessionInfo,
                    },
                })
                .subscribe(
                    (responseList: BookSessionsResponse[]) => {
                        for (let bookedSession of responseList) {
                            if (bookedSession.error) {
                                continue
                            }
                            this.analyticsService.logBookedSessionEvent(
                                source,
                                rec_type - 1,
                                bookSessionInfo.length,
                                bookedSession.sessionTime,
                                bookedSession.duration,
                                timezone,
                                bookedSession.state,
                                matchPreferenceFavorites,
                                activity,
                                quietMode,
                            )
                        }

                        let duration = this.determineQueryTimeout(responseList, session_list)

                        if (duration === 0) {
                            this.processBookResult(responseList, rec_type)
                            this.sessionBookedSubject.next()
                            bookResponse.next(responseList)
                        } else {
                            setTimeout(() => {
                                this.confirmedSessionsService
                                    .getConfirmedSessions(true)
                                    .subscribe((confirmedSessions) => {
                                        if (
                                            this.isMatchCheckStillPending(
                                                confirmedSessions,
                                                session_list,
                                            )
                                        ) {
                                            setTimeout(() => {
                                                this.confirmedSessionsService
                                                    .getConfirmedSessions()
                                                    .subscribe(() => {
                                                        this.processBookResult(
                                                            responseList,
                                                            rec_type,
                                                        )
                                                        this.sessionBookedSubject.next()
                                                        bookResponse.next(responseList)
                                                    })
                                            }, RETRY_WAIT_DURATION)
                                        } else {
                                            this.processBookResult(responseList, rec_type)
                                            this.sessionBookedSubject.next()
                                            bookResponse.next(responseList)
                                        }
                                    })
                            }, duration)
                        }
                    },
                    (error) => {
                        bookResponse.error(error)
                    },
                )
        })
    }

    private determineQueryTimeout(response: any, sessionTimeList: number[]): number {
        let duration: number = 0
        let sessionCount: number = 0

        if (response) {
            for (let session of response) {
                if (sessionTimeList.includes(session.sessionTime)) {
                    if (session.state == SESSION_STATE_INITIAL) {
                        sessionCount++
                        duration += Math.max(duration, session.queryDelay)
                    }
                }
            }
        } else {
            return 0
        }

        if (sessionCount !== 0) {
            duration += WAIT_BUFFER
        }

        return Math.min(duration, MAX_WAIT_DURATION)
    }

    private generateRecurringSessionList(
        start_time: number,
        rec_type: number,
        num_sessions: number,
        timezone: string,
        conf_sessions: Container<ConfirmedSessionsResponse>,
    ): number[] {
        let session_list: number[] = []
        let conflict_list: number[] = []
        let ts: number

        let unavailableTimeSlots = this.confirmedSessionsService.getUnavailableTimeSlots()

        if (rec_type === 1) {
            // Selected one time, add to list and return
            if (unavailableTimeSlots.indexOf(start_time) === -1) {
                session_list.push(start_time)
            }
        } else {
            let temp = moment(start_time).tz(timezone)

            let hour: number = temp.tz(timezone).hours()

            while (session_list.length + conflict_list.length < num_sessions) {
                if (rec_type === 2) {
                    // Daily
                    ts = temp.tz(timezone).valueOf()
                    if (conf_sessions.getBySessionTime(ts) !== null) {
                        // Session already exists, add to conflict
                        conflict_list.push(ts)
                    } else {
                        if (unavailableTimeSlots.indexOf(ts) === -1) {
                            session_list.push(ts)
                        } else {
                            conflict_list.push(ts)
                        }
                    }
                    //session_list.push(temp.tz(timezone).valueOf())

                    temp.tz(timezone).add(1, 'd')
                    if (temp.tz(timezone).hours() !== hour) {
                        temp.tz(timezone).hours(hour)
                    }
                } else {
                    // Weekly
                    ts = temp.tz(timezone).valueOf()
                    if (conf_sessions.getBySessionTime(ts) !== null) {
                        // Session already exists, add to conflict
                        conflict_list.push(ts)
                    } else {
                        if (unavailableTimeSlots.indexOf(ts) === -1) {
                            session_list.push(ts)
                        } else {
                            conflict_list.push(ts)
                        }
                    }
                    //session_list.push(temp.tz(timezone).valueOf())
                    temp.tz(timezone).add(7, 'd')
                    if (temp.tz(timezone).hours() !== hour) {
                        temp.tz(timezone).hours(hour)
                    }
                }
            }
        }
        return session_list
    }

    parseBookResponse(bookResponse): any {
        let numSuccess: number = 0
        let responseInfo = {
            firstBook: false,
            numSuccess: 0,
            planLimitReached: false,
        }

        for (let i: number = 0; i < bookResponse.length; ++i) {
            if (bookResponse[i].first_booking === true) {
                responseInfo['firstBook'] = true
            }

            if (bookResponse[i].hasOwnProperty('error')) {
                if (bookResponse[i]['error'].hasOwnProperty('error_code')) {
                    switch (bookResponse[i]['error']['error_code']) {
                        case API_ERROR_BOOK_PLAN_LIMIT_EXCEEDED:
                            responseInfo['planLimitReached'] = true
                            break

                        case API_ERROR_BOOK_INVITER_MEEETING_NOT_AVAILABLE:
                        case API_ERROR_BOOK_DIRECT_MEETING_NO_LONGER_AVAILABLE:
                            responseInfo['inviterNoLongerAvailable'] = true
                            break

                        case API_ERROR_BOOK_ALREADY_STARTED:
                            responseInfo['alreadyStarted'] = true
                            break

                        case API_ERROR_BOOK_CONFLICT:
                            responseInfo['sessionConflict'] = true
                            responseInfo['conflicts'] = bookResponse[i]['error']['conflicts']
                            break
                    }
                }
            } else {
                numSuccess++
            }
        }
        responseInfo['numSuccess'] = numSuccess

        return responseInfo
    }

    bookSession(
        bookSessionInfo: BookSessionInfo[],
        timeZone: string,
        source: BookingSource,
    ): Observable<any> {
        let sessionTimeList: number[] = []

        let length: number = bookSessionInfo.length

        for (var i = 0; i < length; ++i) {
            sessionTimeList.push(bookSessionInfo[i].sessionTime)
        }

        return new Observable<any>((bookResponse) => {
            this.apiService
                .post(environment.api_url + 'session/', {
                    bookData: {
                        sessions: bookSessionInfo,
                    },
                })
                .subscribe(
                    (responseList: BookSessionsResponse[]) => {
                        for (let bookedSession of responseList) {
                            if (bookedSession.hasOwnProperty('error')) {
                                continue
                            }
                            this.analyticsService.logBookedSessionEvent(
                                source,
                                0,
                                bookSessionInfo.length,
                                bookedSession.sessionTime,
                                bookedSession.duration,
                                timeZone,
                                bookedSession.state,
                                bookedSession.preferences.favorites.value,
                                bookedSession.activityType,
                                bookedSession.preferences.quietMode.value,
                            )
                        }
                        let duration = this.determineQueryTimeout(responseList, sessionTimeList)

                        if (duration === 0) {
                            this.processBookResult(responseList, 1)
                            this.sessionBookedSubject.next()
                            bookResponse.next(responseList)
                        } else {
                            setTimeout(() => {
                                this.confirmedSessionsService
                                    .getConfirmedSessions(true)
                                    .subscribe((confirmedSessions) => {
                                        if (
                                            this.isMatchCheckStillPending(
                                                confirmedSessions,
                                                sessionTimeList,
                                            )
                                        ) {
                                            setTimeout(() => {
                                                this.confirmedSessionsService
                                                    .getConfirmedSessions()
                                                    .subscribe(() => {
                                                        this.processBookResult(responseList, 1)
                                                        this.sessionBookedSubject.next()
                                                        bookResponse.next(responseList)
                                                    })
                                            }, RETRY_WAIT_DURATION)
                                        } else {
                                            this.processBookResult(responseList, 1)
                                            this.sessionBookedSubject.next()
                                            bookResponse.next(responseList)
                                        }
                                    })
                            }, duration)
                        }
                    },
                    (error) => {
                        for (var i = 0; i < bookSessionInfo.length; ++i) {
                            this.selectedSessionsService.deleteSelectedSession(
                                bookSessionInfo[i].sessionTime,
                            )
                        }
                        //throwError(error);
                        bookResponse.error(error)
                    },
                )
        })
    }

    isMatchCheckStillPending(confirmedSessions, sessionTimeList: number[]): boolean {
        if (confirmedSessions !== null) {
            for (let session of confirmedSessions) {
                if (sessionTimeList.includes(session.sessionTime)) {
                    if (session.state === SESSION_STATE_INITIAL) {
                        return true
                    }
                }
            }
        }
        return false
    }

    ngOnDestroy() {}
}
