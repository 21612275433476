import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

@Component({
    selector: 'app-finding-partner-failed',
    templateUrl: './finding-partner-failed.component.html',
    styleUrls: ['./finding-partner-failed.component.scss'],
})
export class FindingPartnerFailedComponent implements OnInit {
    faTimes = faTimes
    constructor(
        private analyticsService: AnalyticsService,
        private router: Router,
        public dialog: MatDialogRef<FindingPartnerFailedComponent>,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('New Partner: Not Found')
    }

    public goToDashboard() {
        this.router.navigate(['/dashboard'])
        this.dialog.close()
    }
}
