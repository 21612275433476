import { Directive, AfterViewInit, ElementRef } from '@angular/core'

@Directive({
    selector: '[appFmAutofocus]',
})
export class FmAutofocusDirective implements AfterViewInit {
    constructor(private el: ElementRef) {
        setTimeout(() => {
            this.el.nativeElement.focus()
        }, 100)
    }

    ngOnInit() {}

    ngAfterViewInit() {}
}
