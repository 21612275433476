<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="closeDialog()"></fa-icon>

    <div class="std-modal-header">
        <div class="std-modal-title std-modal-title--sentence wrap-word-break"
            >{{ modalTitle }}
        </div>
        <div class="subtitle">{{ modalSubtitle }}</div>
    </div>
    <div
        class="std-modal-body"
        *ngIf="xhrPartners.state == 'loading' || xhrPartners.state == 'initial'; else listLoaded">
        <app-loading-indicator></app-loading-indicator>
    </div>
    <ng-template #listLoaded>
        <div class="std-modal-body w-full">
            <ng-scrollbar
                [track]="listMode ? 'vertical' : ''"
                autoHeightDisabled="false"
                viewClass="ng-scrollbar-content-width-fix"
                class="w-full flex flex-col items-center justify-start max-w-full"
                [ngClass]="{
                    '!min-h-[125px]': directBookPartnerList.length == 1,
                    '!min-h-[250px]': directBookPartnerList.length > 1
                }">
                <div
                    class="w-full saved-and-shared-list"
                    [ngClass]="{ 'list-mode': listMode, 'hide-list-mode': !listMode }"
                    #scrollingList>
                    <app-timeslot-partner-item
                        *ngFor="let partner of directBookPartnerList; let i = index"
                        (click)="updateDirectBookPartnerListItemSelected(i)"
                        class="list-item only:mb-0 mt-[25px] first:mt-0"
                        [displayName]="partner.displayName"
                        [saved]="partner.saved"
                        [profileImage]="partner.profilePhoto"
                        [profileUrl]="partner.profileSlug"
                        [totalSessions]="partner.totalSessions"
                        [sharedSessions]="partner.sharedSessions"
                        [lastSessionTogether]="partner.lastSessionTime"
                        [timezone]="partner.timezone"
                        [selected]="directBookPartnerListItemSelected == i"
                        [currentUserTimezone]="currentUser.timeZone"
                        [activityType]="partner.activityType"
                        [quietMode]="
                            partner.preferences.quietMode.value
                        "></app-timeslot-partner-item>
                </div>
            </ng-scrollbar>

            <div
                *ngIf="directBookPartnerList.length > 2 && !listMode"
                class="f-link mb-[25px]"
                (click)="toggleListMode()"
                >+ Show all {{ directBookPartnerList.length }} people</div
            >
            <div
                *ngIf="directBookPartnerList.length > 2 && listMode"
                class="f-link mt-[25px]"
                (click)="toggleListMode()">
                <fa-icon class="chevron-left" [icon]="faChevronLeft" [classes]="['']"></fa-icon>Go
                back</div
            >

            <div *ngIf="!listMode" class="saved-user-list-container w-full">
                <app-timeslot-partner-item
                    class="list-item"
                    *ngIf="
                        !mostLikelyRandomPartner || directBookPartnerList.length > 0;
                        else showAvailableUser
                    "
                    (click)="setIsmostLikelyRandomPartnerSelected()"
                    isCommunityMember="true"
                    [selected]="ismostLikelyRandomPartnerSelected"
                    [currentUserTimezone]="currentUser.timeZone">
                </app-timeslot-partner-item>
                <ng-template #showAvailableUser>
                    <app-timeslot-partner-item
                        class="list-item"
                        (click)="setIsmostLikelyRandomPartnerSelected()"
                        [displayName]="mostLikelyRandomPartner.displayName"
                        [saved]="mostLikelyRandomPartner.saved"
                        [profileImage]="mostLikelyRandomPartner.profilePhoto"
                        [profileUrl]="mostLikelyRandomPartner.profileSlug"
                        [totalSessions]="mostLikelyRandomPartner.totalSessions"
                        [sharedSessions]="mostLikelyRandomPartner.sharedSessions"
                        [lastSessionTogether]="mostLikelyRandomPartner.lastSessionTime"
                        [timezone]="mostLikelyRandomPartner.timezone"
                        [selected]="ismostLikelyRandomPartnerSelected"
                        [currentUserTimezone]="currentUser.timeZone"
                        [quietMode]="
                            mostLikelyRandomPartner.preferences.quietMode.value
                        "></app-timeslot-partner-item>
                </ng-template>
            </div>

            <div class="std-modal-action-container w-full fm-mb-15px" style="padding-top: 45px">
                <app-spinner-button
                    class="action cancel"
                    [actionComplete]="actionComplete"
                    [faIcon]="ismostLikelyRandomPartnerSelected ? null : faLock"
                    [label]="ctaText"
                    classes="f-btn f-btn--primary  f-btn-250"
                    (clicked)="bookSession()"></app-spinner-button>
            </div>

            <div
                *ngIf="directBookPartnerListItemSelected >= 0; else directBookNotSelected"
                class="disclaimer-text wrap-double-line-el">
                This person will be your partner unless one of you cancels ·
                <span
                    ><a
                        (click)="trackLearnMoreSelected()"
                        class="disclaimer-text"
                        style="text-decoration: underline"
                        href="http://support.focusmate.com/en/articles/6385284-how-we-match-you-with-a-partner"
                        target="_blank"
                        >Learn more</a
                    ></span
                ></div
            >
            <ng-template #directBookNotSelected>
                <div
                    *ngIf="mostLikelyRandomPartner; else communitySelected"
                    class="disclaimer-text wrap-double-line-el">
                    We’ll match you with this person or another Focusmate community member ·
                    <span
                        ><a
                            (click)="trackLearnMoreSelected()"
                            class="disclaimer-text"
                            style="text-decoration: underline"
                            href="http://support.focusmate.com/en/articles/6385284-how-we-match-you-with-a-partner"
                            target="_blank"
                            >Learn more</a
                        ></span
                    ></div
                >
                <ng-template #communitySelected>
                    <div class="disclaimer-text wrap-double-line-el">
                        We’ll randomly match you with a Focusmate community member ·
                        <span
                            ><a
                                (click)="trackLearnMoreSelected()"
                                class="disclaimer-text"
                                style="text-decoration: underline"
                                href="http://support.focusmate.com/en/articles/6385284-how-we-match-you-with-a-partner"
                                target="_blank"
                                >Learn more</a
                            ></span
                        ></div
                    >
                </ng-template>
            </ng-template>
        </div>
    </ng-template>
</div>
