<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title xwrap-double-line-el flex flex-row items-center justify-center"
            >Quiet Mode
        </div>
    </div>

    <div class="std-modal-body w-full text-bluegrey text-sm font-avenir font-medium gap-6">
        <div class="w-full flex flex-col items-start justify-start gap-3">
            <div class="w-full flex flex-col items-start justify-start gap-3">
                <div class="w-full"
                    >Enable Quiet Mode on this page to book sessions without voice communication.<br /><br />
                    Your Favorite partners’ availability for Quiet Mode sessions depends on their
                    preferences.</div
                >
            </div>
        </div>
        <div class="std-modal-action-container">
            <button class="f-btn f-btn--primary f-btn-250" (click)="dialog.close()">Got it </button>
            <div class="flex flex-row justify-center items-center mt-4">
                <a
                    class="f-link"
                    style="text-decoration: none"
                    target="_blank"
                    href="https://support.focusmate.com/en/articles/8060080-session-settings-my-task-quiet-mode-and-partner"
                    (click)="learnMoreClicked()"
                    >More about Quiet Mode
                </a>
            </div>
        </div>
    </div>
</div>
