import { Injectable } from '@angular/core'

import { BehaviorSubject, Observable } from 'rxjs'
import { BookLimitCheckService } from '@app/core/_services/book-limit-check.service'
import { SessionActivityType, SessionPreferenceFavorites } from '../_models'

export interface SelectedSession {
    user?: string
    sessionTime: number
    profileUrl: string
    userAvailabilityCode: string
    sessionDuration: number
    sessionPreferenceFavorites: SessionPreferenceFavorites
    activityType: SessionActivityType
    quietMode: boolean
}

@Injectable({
    providedIn: 'root',
})
export class SelectedSessionService {
    private selectedSessions: SelectedSession[] = []
    private subject: BehaviorSubject<SelectedSession[]> = new BehaviorSubject<SelectedSession[]>(
        this.selectedSessions,
    )

    constructor(private bookLimitCheckService: BookLimitCheckService) {}

    addSelectedSession(selected: SelectedSession): void {
        var len = this.selectedSessions.length
        let inserted: boolean = false

        for (var i = 0; i < len; i++) {
            if (selected.sessionTime < this.selectedSessions[i].sessionTime) {
                this.selectedSessions.splice(i, 0, selected)
                inserted = true
                break
            } else if (selected.sessionTime === this.selectedSessions[i].sessionTime) {
                this.selectedSessions.splice(i, 1, selected)
                inserted = true
                break
            }
        }

        if (inserted === false) {
            this.selectedSessions.push(selected)
        }

        this.subject.next(this.selectedSessions)
        this.bookLimitCheckService.updateLimitsSelected(this.selectedSessions.length)
    }

    deleteSelectedSession(selectedTime: number): void {
        var index

        index = this.selectedSessions.findIndex((item) => selectedTime === item.sessionTime)

        if (index != -1) {
            this.selectedSessions.splice(index, 1)
        }

        this.subject.next(this.selectedSessions)
        this.bookLimitCheckService.updateLimitsSelected(this.selectedSessions.length)
    }

    getSelectedSessionInfoByTime(sessionTIme: number): SelectedSession | null {
        let index = this.selectedSessions.findIndex((item) => sessionTIme === item.sessionTime)
        return this.selectedSessions[index] || null
    }

    deleteAllSelectedSessions(): void {
        this.selectedSessions.splice(0, this.selectedSessions.length)
        this.subject.next(this.selectedSessions)
        this.bookLimitCheckService.updateLimitsSelected(this.selectedSessions.length)
    }

    getSelectedSessions(): Observable<SelectedSession[]> {
        return this.subject.asObservable()
    }
}
