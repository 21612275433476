<div class="bg-oatmilk sm:w-[330px] p-[20px] sm:p-[30px] h-auto animate-height" #modalContent>
    @if (!waitingForPartner) {
        <div class="flex flex-col gap-[7px] sm:gap-[15px] items-center justify-center">
            <div class="text-lg sm:text-xl font-semibold">Your partner is running late</div>
            <div class="text-sm w-[250px] mb-[10px]"
                >We can find you a new partner, or you can wait as long as you'd like. <br /><br />
                You have&nbsp;<app-fm-countdown
                    [countdownTo]="countdownTo"
                    (onCountdownFinished)="handleCountdownFinished()"></app-fm-countdown
                >&nbsp;to request a new partner.</div
            >
            <button class="f-btn f-btn--secondary f-btn-250" (click)="waitForPartner()"
                >Wait for now</button
            >
            <div class="flex flex-col items-center gap-[3px]">
                <button
                    class="relative f-btn f-btn--primary f-btn-250"
                    (click)="onRequestNewPartnerButtonClick()">
                    @if (autoRematchAtTimestamp) {
                        Requesting new partner in&nbsp;
                        <app-fm-countdown
                            [countdownTo]="autoRematchAtTimestamp"
                            (onCountdownFinished)="handleCountdownFinished()"></app-fm-countdown>
                    } @else {
                        Request new partner
                    }
                    <div
                        #autoRematchLoadingDiv
                        class="absolute bottom-0 left-0 h-[5px] w-0 bg-blurple-1 transition-width ease-linear"></div>
                </button>
                <div class="w-[250px] mt-[5px] flex flex-row items-start justify-center gap-[7px]">
                    <input
                        type="checkbox"
                        id="autoRematchCheckbox"
                        [(ngModel)]="autoRematchEnabled"
                        (change)="onClickAutoRematchCheckbox()"
                        [disabled]="autoRematchCheckboxDisabled"
                        class="custom-checkbox flex-shrink-0" />
                    <label for="autoRematchCheckbox" class="text-sm font-normal cursor-pointer"
                        >Automatically request new partner from now on</label
                    >
                </div>
            </div>
        </div>
    } @else {
        <div
            class="flex flex-col gap-[7px] sm:gap-[15px] items-center justify-center opacity-0 animate-opacity">
            <div
                class="text-lg sm:text-xl font-semibold text-center max-w-full overflow-hidden text-ellipsis whitespace-nowrap sm:whitespace-normal"
                >Waiting for {{ data.partnerName }}...</div
            >
            <div class="text-sm w-[250px]"
                >You still have&nbsp;<app-fm-countdown
                    [countdownTo]="countdownTo"
                    (onCountdownFinished)="handleCountdownFinished()"></app-fm-countdown
                >to request a new partner.</div
            >
            <button class="f-btn f-btn--primary w-[250px] max-w-full" (click)="requestNewPartner()"
                >Request new partner</button
            >
        </div>
    }
</div>
