import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { SessionPreferenceFavorites } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { TimeUtilitiesService } from '@app/core/_services/time-utilities.service'
import { UserService } from '@app/core/_services/user.service'
import { FmButtonGroupItem } from '@app/standards-v2/components/fm-button-group/fm-button-group.component'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'

type ShowOnlyFavoritesOption = 'always' | 'never' | 'conditionally'

@Component({
    selector: 'fm-session-preference-favorites',
    templateUrl: './session-preference-favorites.component.html',
    styleUrls: ['./session-preference-favorites.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SessionPreferenceFavoritesComponent),
            multi: true,
        },
    ],
})
export class SessionPreferenceFavoritesComponent implements ControlValueAccessor {
    @Input() timezone
    @Input() showOnlyFavoritesOption: ShowOnlyFavoritesOption = 'conditionally'
    @Input() toggleLabelClasses: string = ''
    @Input() checkboxLabelClasses: string = ''
    @Input() buttonClasses: string = ''
    @Input() disabled: boolean = false
    @Input() showInfoModalTrigger: boolean = false
    @Input() source: string = ''
    @Output() preferenceChanged = new EventEmitter<SessionPreferenceFavorites>()

    public toggleValue: SessionPreferenceFavorites
    public checkboxSelected: boolean
    public onlyFavoritesCheckboxVisible: boolean
    public faInfoCircle = faInfoCircle
    public buttonList: FmButtonGroupItem[] = [
        {
            value: 'preferred',
            faIcon: faStar,
            label: 'Prefer Favorites',
            labelClasses: '',
            tooltip: 'Matches you with your Favorite partners whenever they are available',
        },
        {
            value: 'noPreference',
            faIcon: null,
            label: 'Anyone',
            labelClasses: '',
            tooltip: 'Matches you with the first available person',
        },
    ]

    constructor(
        private timeUtilitiesService: TimeUtilitiesService,
        private dialogService: DialogService,
        private analyticsService: AnalyticsService,
        public userService: UserService,
    ) {}

    onChange: any = () => {}
    onTouch: any = () => {}

    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn
    }

    writeValue(value: SessionPreferenceFavorites) {
        if (value == 'onlyFavorites') {
            this.toggleValue = 'preferred'
            this.checkboxSelected = true
        } else {
            this.toggleValue = value
            this.checkboxSelected = false
        }
        this.onlyFavoritesCheckboxVisible = this.showFavoritesOnlyCheckbox()
    }

    trackClickedEvent(selection: string) {
        for (let item of this.buttonList) {
            if (item.value == selection) {
                this.analyticsService.logClickedEvent(item.label, this.source)
            }
        }
    }

    onToggleChange(e: any) {
        this.toggleValue = e
        if (this.toggleValue == 'noPreference') {
            this.checkboxSelected = false
        }
        this.preferenceChanged.emit(this.determineFavoritesPreference())
        this.onChange(this.determineFavoritesPreference())
        this.onlyFavoritesCheckboxVisible = this.showFavoritesOnlyCheckbox()
        this.trackClickedEvent(e)
    }
    onCheckboxChange(e: any) {
        this.checkboxSelected = e
        this.toggleValue = 'preferred'
        this.preferenceChanged.emit(this.determineFavoritesPreference())
        this.onChange(this.determineFavoritesPreference())
        this.analyticsService.logClickedEvent(
            `Only favorites (${this.checkboxSelected})`,
            this.source,
        )
    }

    private determineFavoritesPreference(): SessionPreferenceFavorites {
        if (this.checkboxSelected) {
            return 'onlyFavorites'
        } else {
            return this.toggleValue
        }
    }

    private showFavoritesOnlyCheckbox(): boolean {
        if (this.showOnlyFavoritesOption == 'never') {
            return false
        }
        if (this.showOnlyFavoritesOption == 'always') {
            return true
        }
        if (this.toggleValue == 'preferred') {
            return true
        }
        return false
    }

    openInfoModal() {
        this.dialogService.openSessionPreferencesInfoModal()
    }

    ngOnInit() {}

    ngOnChanges() {
        this.buttonList[1].faIcon = this.timeUtilitiesService.determineGlobeIconFromTimezone(
            this.timezone,
        )
        this.onlyFavoritesCheckboxVisible = this.showFavoritesOnlyCheckbox()
        for (let button of this.buttonList) {
            button.labelClasses = this.buttonClasses
        }
    }
}
