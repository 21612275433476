import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, throwError as _throw } from 'rxjs'
import { UserProfileInfo } from '@app/core/_models'
import { ApiService } from '@app/core/_services/api.service'
import { map, distinctUntilChanged, catchError } from 'rxjs/operators'
import { environment } from '@env/environment'

@Injectable()
export class UserProfileService {
    private user: UserProfileInfo = new UserProfileInfo()
    private userProfileSubject = new BehaviorSubject<UserProfileInfo>(this.user as UserProfileInfo)
    public userProfile = this.userProfileSubject.asObservable().pipe(distinctUntilChanged())

    constructor(private apiService: ApiService) {}

    getUserProfile(user_id: string): Observable<any> {
        return this.apiService.get(environment.api_url + 'user/' + user_id).pipe(
            map((data) => {
                this.user = new UserProfileInfo()
                Object.assign(this.user, data)
                this.user.valid = true

                this.userProfileSubject.next(this.user)
                return this.user
            }),
            catchError((error) => {
                this.user = new UserProfileInfo()
                this.user.valid = false
                this.userProfileSubject.next(this.user)
                return _throw(error)
            }),
        )
    }

    ngOnDestroy() {}
}
