import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import * as Sentry from '@sentry/angular-ivy'

export const LOCAL_STORAGE_HIDE_QUIET_MODE_MODAL = 'hidePartnerInQuietModeNotification'

@Component({
    selector: 'app-partner-in-quiet-mode-modal',
    templateUrl: './partner-in-quiet-mode-modal.component.html',
    styleUrl: './partner-in-quiet-mode-modal.component.scss',
})
export class PartnerInQuietModeModalComponent {
    faTimes = faTimes
    public autoTriggered: boolean
    public dontShowThisAgain: boolean = false

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private analyticsService: AnalyticsService,
        public dialog: MatDialogRef<PartnerInQuietModeModalComponent>,
    ) {
        this.autoTriggered = data.autoTriggered
    }

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Partner in Quiet Mode - In Session')
    }

    private storeHideModalSetting() {
        try {
            localStorage.setItem(LOCAL_STORAGE_HIDE_QUIET_MODE_MODAL, 'true')
        } catch (e) {
            console.error(e)
            Sentry.captureException(e, {
                tags: {
                    'fm.errorDomain': 'session',
                },
            })
        }
    }

    closeModal() {
        this.storeHideModalSetting()
        this.dialog.close()
    }
}
