<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="closeModal()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title wrap-word-break">Your partner is in Quiet Mode</div>
    </div>
    <div class="std-modal-body">
        <div class="std-modal-body-text wrap-word-break w-full">
            <div class="text-bluegrey text-sm leading-normal w-full text-left font-avenir">
                <div>Your partner won’t be using their mic. </div>
                <div class="mt-2"
                    >Pro tip: You can use the text chat to share your goals and updates on your
                    progress.</div
                >
            </div>
        </div>
    </div>
    <div class="std-modal-action-container">
        <button class="f-btn f-btn--primary f-btn-250" (click)="closeModal()">Got it</button>
        @if (autoTriggered) {
            <mat-checkbox
                class="notification-pref-checkbox mt-1 font-avenir text-14-19 text-bluegrey font-medium"
                disableRipple="true"
                [(ngModel)]="dontShowThisAgain"
                >Don't show this again</mat-checkbox
            >
        }
    </div>
</div>
