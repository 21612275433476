import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ReportService } from '@app/core/_services/report.service'
import { ReportInfo } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

@Component({
    selector: 'app-report-bad-behavior',
    templateUrl: './report-bad-behavior.component.html',
    styleUrls: ['./report-bad-behavior.component.scss'],
})
export class ReportBadBehaviorComponent implements OnInit {
    public selectedReason: number = 0
    faTimes = faTimes
    public disableForm: boolean = true
    public commentsRequired: boolean = false
    public comments: string = ''

    reasons = [
        { value: 11, name: 'No show' },
        { value: 4, name: 'Broke session guidelines' },
        { value: 5, name: 'Flirting or inappropriate comments' },
        { value: 6, name: 'Harassment, shaming, or threats' },
        { value: 7, name: 'Illegal activities' },
        { value: 8, name: 'Inappropriate attire' },
        { value: 9, name: 'Nudity or sexual acts' },
        { value: 10, name: 'Other' },
    ]

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<ReportBadBehaviorComponent>,
        private reportService: ReportService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Report Bad Behavior: Confirm')
    }

    onSelectionChanged(selection) {
        this.selectedReason = selection
        this.updateFormState()
    }

    onKeypressEvent(e) {
        this.updateFormState()
    }

    private doesSelectionRequireComments() {
        return this.selectedReason === 10
    }

    private validCommentsEntered() {
        return this.comments && this.comments.length > 0
    }

    updateFormState() {
        const commentsRequired = this.doesSelectionRequireComments()
        const isCommentValid = this.validCommentsEntered()

        this.disableForm = commentsRequired && !isCommentValid
        this.commentsRequired = commentsRequired && !isCommentValid
    }

    reportUser() {
        let codedComment: string = encodeURIComponent(this.comments)
        let reportInfo: ReportInfo = {
            report_type: 'session',
        }

        reportInfo.comment = codedComment
        reportInfo.session_id = this.data.sessionId
        reportInfo.session_time = this.data.sessionTime
        reportInfo.reason_id = this.selectedReason

        this.reportService.reportSession(reportInfo).subscribe(
            (success) => {
                this.analyticsService.logAmplitudeReportUser(
                    this.data.source,
                    'session',
                    this.selectedReason,
                )
                this.dialog.close(true)
            },
            (error) => {
                this.dialog.close(false)
            },
        )
    }

    ngOnDestroy() {}
}
