import { Injectable } from '@angular/core'
import { AnalyticsService } from './analytics.service'
import { ApiService } from './api.service'
import { environment } from '@env/environment'
import { Observable, ReplaySubject, map } from 'rxjs'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'
import { User } from '../_models'
import { RewardsReferralsData } from '../_models/rewards_referrals'
import { CookieService } from './cookie.service'

export const VIEWED_REFERRAL_PROMPT_MODAL_PROP = 'viewedReferralPromptModal'

const FM_REFERRAL_KEY = 'fmreferral'

@Injectable({
    providedIn: 'root',
})
export class RewardsReferralService {
    private code: string | null = null

    public emphasizeReferIconSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1)

    constructor(
        private analyticsService: AnalyticsService,
        private apiService: ApiService,
        private cookieService: CookieService,
    ) {}

    // check query params for a referral code and persist it.
    // if it's a code this browser hasn't seen before, emit an event
    public detectRewardsReferralCode(): void {
        const params = new URLSearchParams(window.location.search)
        const newCode = params.get(FM_REFERRAL_KEY)
        if (newCode) {
            let previousCode = this.getStoredRewardsReferralCode()
            if (!previousCode || previousCode !== newCode) {
                // new browser/code combo, as far as we can tell
                this.analyticsService.logRewardsReferralLead(newCode, previousCode)
            }
            this.setRewardsReferralCode(newCode)
        }
    }

    // store a given code
    private setRewardsReferralCode(code: string): void {
        this.code = code
        localStorage.setItem(FM_REFERRAL_KEY, code)
    }

    // clear the persisted code
    public clearRewardsReferralCode(): void {
        this.code = null
        this.cookieService.deleteCookie(FM_REFERRAL_KEY)
    }

    public getStoredRewardsReferralCode(): string | null {
        if (this.code) {
            return this.code
        }
        return this.cookieService.getCookie(FM_REFERRAL_KEY)
    }

    public sendReferralEmails(emails: string[]): Observable<any> {
        return this.apiService.post(environment.api_url + 'refer', { emails }).pipe(
            map((data) => {
                return data
            }),
        )
    }

    public fetchRewardsData(): Observable<RewardsReferralsData> {
        return this.apiService.get(environment.api_url + 'refer').pipe(
            map((data) => {
                return data
            }),
        )
    }

    // for a given user calculate the trial days remaining (round up). if no trial, return null
    public getTrialDaysRemaining(user: User): number | null {
        if (!user.freeTrialEnd) {
            return null
        }

        // get difference between now and the end of the trial
        const now = moment()
        const end = moment(user.freeTrialEnd)
        if (end.isBefore(now)) {
            return null
        }
        let diffInDays = end.diff(now, 'days')

        // the diff function truncates the result, so we manually round up if there are any remaining minutes
        if (end.diff(now, 'minutes') % 1440 > 0) {
            diffInDays += 1
        }

        return diffInDays
    }

    public formatRewardTimeRemaining(
        periodStart: number | undefined,
        periodEnd: number | undefined,
        userTimezone: string,
    ): string {
        if (!periodStart || !periodEnd) {
            return '0 days'
        }

        const currentDate = moment()
        let startDate = moment(periodStart).tz(userTimezone).startOf('day')
        const endDate = moment(periodEnd).tz(userTimezone).startOf('day')

        // Check if the end date is in the past
        if (endDate.isBefore(currentDate)) {
            return 'None'
        }

        // If start date is in the past, use current date as start date
        if (startDate.isBefore(currentDate)) {
            startDate = currentDate
        }

        // calculate difference in days and assume months are 31 days
        const totalDays = endDate.diff(startDate, 'days')
        const months = totalDays >= 31 ? Math.floor(totalDays / 31) : 0
        const days = totalDays - months * 31

        let result = ''

        if (months > 0) {
            result += `${months} ${months === 1 ? 'month' : 'months'}`
        }

        if (days > 0) {
            if (months > 0) {
                result += ' '
            }
            result += `${days} ${days === 1 ? 'day' : 'days'}`
        }

        return result
    }

    public shouldSeeReferralPrompt(user: User): boolean {
        // show the referral prompt after 10 sessions
        return !user.properties[VIEWED_REFERRAL_PROMPT_MODAL_PROP] && user.compSessions >= 5
    }

    public emphasizeReferIcon() {
        this.emphasizeReferIconSubject.next(true)
        this.emphasizeReferIconSubject.next(false)
    }
}
