import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { UserService } from '@app/core/_services/user.service'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { StoreRedirectOnLoginService } from '@app/core/_services/store-redirect-on-login.service'

@Injectable({
    providedIn: 'root',
})
export class ProfGuard {
    constructor(
        private router: Router,
        private userService: UserService,
        private storeRedirectOnLoginService: StoreRedirectOnLoginService,
    ) {}

    // Guard check when trying to load profile.
    // Only allow it if onboarding has completed
    // and there is a user currently logged in
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return Observable.create((obs) => {
            this.userService.isAuthenticated.pipe(take(1)).subscribe((authenticated) => {
                if (authenticated) {
                    this.userService.currentUser.pipe(take(1)).subscribe((user) => {
                        if (user.onboard || user.features.experimentVerifyBeforeMatch) {
                            obs.next(true)
                        } else {
                            this.router.navigate(['signup/profile'])
                            obs.next(false)
                        }
                    })
                } else {
                    this.storeRedirectOnLoginService.setUrlToRedirectToOnLogin(state.url)
                    this.router.navigate(['/login'])
                    obs.next(false)
                }
            })
        })
    }
}
