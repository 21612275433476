import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { ApiService } from './api.service'
import { environment } from '@env/environment'
import { catchError, map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { FavoritesAvailabilityResponse, SavedUserAvailabilityResponse } from '../_models'

export class UserAvailabilty {
    public partner: any = {
        firstName: '',
        displayName: '',
        avatar: '',
        availabilityCode: '',
        quietModeMatchAllowed: false,
    }
    public availability: any[] = []
    public valid: boolean = false
}

export class ResetAvailabilityCodeResponse {
    public availabilityCode: string = ''
}

@Injectable({
    providedIn: 'root',
})
export class UserAvailabiltyService {
    constructor(private apiService: ApiService) {}

    private userAvailabilitySubject = new BehaviorSubject<UserAvailabilty>(new UserAvailabilty())
    public userAvailability = this.userAvailabilitySubject.asObservable()

    getUserAvailability(userHash: string, anonymousUser: boolean): Observable<any> {
        if (!anonymousUser) {
            return this.apiService.get(environment.api_url + 'user-availability/' + userHash).pipe(
                map((data: UserAvailabilty) => {
                    this.userAvailabilitySubject.next(data)
                    return data
                }),
                catchError((error) => {
                    let data = new UserAvailabilty()
                    data.partner.availabilityCode = userHash
                    this.userAvailabilitySubject.next(data)
                    return throwError(error)
                }),
            )
        } else {
            return this.apiService
                .getAnonymous(environment.api_url + 'user-availability/' + userHash)
                .pipe(
                    map((data: UserAvailabilty) => {
                        this.userAvailabilitySubject.next(data)
                        return data
                    }),
                    catchError((error) => {
                        let data = new UserAvailabilty()
                        data.partner.availabilityCode = userHash
                        this.userAvailabilitySubject.next(data)
                        return throwError(error)
                    }),
                )
        }
    }

    getUserSharedAvailability(userId: string): Observable<any> {
        return this.apiService.get(environment.api_url + 'user-availability/id/' + userId).pipe(
            map((data: SavedUserAvailabilityResponse) => {
                return data
            }),
            catchError((error) => {
                return throwError(error)
            }),
        )
    }

    queryAvailableTimesWithFavorites(retrieveFromTimestamp: number, retrieveToTimestamp: number) {
        let params = new HttpParams()

        params = params.append('retrieve-from', retrieveFromTimestamp.toString())
        params = params.append('retrieve-to', retrieveToTimestamp.toString())

        return this.apiService
            .get(environment.api_url + 'user-availability/list/favorites', params)
            .pipe(
                map((response: FavoritesAvailabilityResponse) => {
                    return response
                }),
            )
    }

    resetAvailabilityLink(): Observable<any> {
        return this.apiService.post(environment.api_url + 'user-availability/code/reset').pipe(
            map((data: ResetAvailabilityCodeResponse) => {
                return data
            }),
            catchError((error) => {
                return throwError(error)
            }),
        )
    }
}
