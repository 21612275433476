<div class="w-full">
    <div class="w-full flex flex-row justify-between items-center" [ngClass]="toggleLabelClasses">
        <div class="flex flex-row justify-start items-center">
            <div>Partner</div>

            @if (showInfoModalTrigger) {
                <div class="cursor-pointer ml-1">
                    <fa-icon
                        (click)="openInfoModal()"
                        class="text-[#808080] text-12-16"
                        [icon]="faInfoCircle"></fa-icon>
                </div>
            }
        </div>
    </div>

    <fm-button-group
        class="w-full text-sm font-medium"
        [itemList]="buttonList"
        [disabled]="disabled"
        [ngModel]="toggleValue"
        [ngModelOptions]="{ standalone: true }"
        (selectionChanged)="onToggleChange($event)" />
    <div
        *ngIf="showOnlyFavoritesOption != 'never'"
        [ngClass]="onlyFavoritesCheckboxVisible ? 'visible' : 'invisible'">
        <div [ngClass]="checkboxLabelClasses">
            <fm-checkbox
                [disabled]="disabled"
                [(ngModel)]="checkboxSelected"
                (checkBoxChange)="onCheckboxChange($event)"
                [ngModelOptions]="{ standalone: true }"
                ><span>Only match me if a Favorite partner is available</span></fm-checkbox
            ></div
        >
    </div>
</div>
