<div class="diag-container">
    <mat-icon (click)="closeDialog()" class="diag-close icon-size-16">clear</mat-icon>

    <div class="diag-header">
        <ng-container [ngSwitch]="modalVersion">
            <ng-container *ngSwitchCase="1">
                <div class="diag-header-text fm-mb-30px" style="margin-top: 49px; font-weight: 600">
                    Verify your email address
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="2">
                <div
                    class="diag-header-text"
                    style="margin-top: 49px; font-weight: 600; margin-bottom: 16px">
                    Congrats on booking a session! 🎉
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="3">
                <div class="diag-header-text fm-mb-30px" style="margin-top: 49px; font-weight: 600">
                    To upgrade, first verify your email address
                </div>
            </ng-container>
        </ng-container>
    </div>

    <div class="diag-body modal-body-v1">
        <ng-container [ngSwitch]="modalVersion">
            <ng-container *ngSwitchCase="1">
                <div
                    class="diag-sub-header-text text-left"
                    style="margin-top: 30px; color: #2f3150; line-height: 19px">
                    <p
                        >To protect your account security, you must verify your email address before
                        you can get a partner or upgrade your plan.</p
                    >
                    <p>Can’t find the verification email?</p>
                    <ol>
                        <li>Try checking your spam folder</li>
                        <li>Have us resend the email to you</li>
                    </ol>
                    <p
                        >If you’re still having trouble, please email verify&#64;focusmate.com from
                        the account you’d like to verify.
                    </p>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="2">
                <div class="modal-body-title fm-mt-30px fm-mb-20px">
                    Verify your email address
                </div>

                <div
                    class="diag-sub-header-text text-left"
                    style="color: #2f3150; line-height: 19px">
                    <p
                        >To protect your account security, you must verify your email address before
                        you can get matched with a partner.</p
                    >
                    <p>Can’t find the verification email?</p>
                    <ol>
                        <li>Try checking your spam folder</li>
                        <li>Have us resend the email to you</li>
                    </ol>
                    <p
                        >If you’re still having trouble, please email verify&#64;focusmate.com from
                        the account you’d like to verify.
                    </p>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="3">
                <div
                    class="diag-sub-header-text text-left"
                    style="margin-top: 30px; color: #2f3150; line-height: 19px">
                    <p
                        >To protect your payment information, you must verify your email address
                        before upgrading your plan.</p
                    >
                    <p>Can’t find the verification email?</p>
                    <ol>
                        <li>Try checking your spam folder</li>
                        <li>Have us resend the email to you</li>
                    </ol>
                    <p
                        >If you’re still having trouble, please email verify&#64;focusmate.com from
                        the account you’d like to verify.
                    </p>
                </div>
            </ng-container>
        </ng-container>

        <div class="customHrDiag w-full" style="margin-top: 12px; margin-bottom: 30px"></div>

        <div class="diag-actions w-full" style="justify-content: space-around; margin-bottom: 49px">
            <app-spinner-button
                class="action action-left"
                [disable]="alreadyVerified"
                [label]="'Resend email'"
                [actionComplete]="sendingEmailComplete"
                classes="f-btn f-btn--secondary w-full"
                (clicked)="sendEmailVerification()">
            </app-spinner-button>
            <button class="f-btn f-btn--primary action action-right" (click)="closeDialog()"
                >Got it</button
            >
        </div>
    </div>
</div>
