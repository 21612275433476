import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

@Component({
    selector: 'fm-google-oauth-callback-error-modal',
    templateUrl: './google-oauth-callback-error-modal.component.html',
    styleUrl: './google-oauth-callback-error-modal.component.scss',
})
export class GoogleOauthCallbackErrorModalComponent {
    faTimes = faTimes

    constructor(
        private dialog: MatDialogRef<GoogleOauthCallbackErrorModalComponent>,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Google OAuth Callback Error')
    }

    onCloseButtonClick() {
        this.dialog.close()
    }
}
