import { Component, Input, OnInit } from '@angular/core'
import { faClockEight } from '@fortawesome/pro-regular-svg-icons'
import { faCircle, faStar } from '@fortawesome/pro-solid-svg-icons'

@Component({
    selector: 'app-badges',
    templateUrl: './badges.component.html',
    styleUrls: ['./badges.component.scss'],
})
export class BadgesComponent implements OnInit {
    @Input() size: string = 'small'
    @Input() snoozed: boolean
    @Input() saved: boolean
    @Input() snoozeTooltip: string
    @Input() standAlone: boolean = false

    public faStar = faStar
    public faClockEight = faClockEight
    public faCircle = faCircle
    public iconTransform: string
    public badgeTransform: string
    public borderTransform: string

    constructor() {}

    ngOnInit(): void {
        if (this.size.includes('xsmall')) {
            // Base size is 16
            this.iconTransform = 'shrink-8' // 8px
            this.badgeTransform = 'shrink-2' // 14px
            this.borderTransform = 'grow-0' // 16px
        } else if (this.size.includes('small')) {
            // Base size is 16
            this.iconTransform = 'shrink-6' // 10px
            this.badgeTransform = 'grow-0' // 16px
            this.borderTransform = 'grow-2' // 18px
        } else if (this.size.includes('medium')) {
            // Base size is 16
            this.iconTransform = 'shrink-4' // 12px
            this.badgeTransform = 'grow-4' // 20px
            this.borderTransform = 'grow-6' // 22px
        } else if (this.size.includes('large')) {
            // Base is 16px
            this.iconTransform = 'grow-6' // 22px
            this.badgeTransform = 'grow-18' // 34px
            this.borderTransform = 'grow-20' // 36px
        }
    }
}
