<ng-container [ngSwitch]="tileState">
    <ng-container *ngSwitchCase="1">
        <div
            class="fm-available-slot-container relataive"
            *ngIf="hidden == false"
            (click)="touchStart()"
            (mouseenter)="onMouseEnterEmptyTile()">
            <div
                *ngIf="waitingUser != ''"
                (click)="addSession()"
                class="flex-1 flex flex-row justify-start items-center">
                <app-profile-image
                    class="fm-profile-img"
                    [saved]="saved"
                    [profileImage]="photoUrl"
                    [size]="'xsmall'">
                </app-profile-image>

                <div class="fm-user-name overflow-hidden">
                    <div class="wrap-double-line-el">
                        @if (unmatchedPartnerQuietMode) {
                            <fm-quiet-mode-icon
                                class="inline-flex mr-0.5 mb-[1px] text-[10px] text-blurple" />
                        }
                        {{ waitingUser }}
                    </div>
                </div>
            </div>
            <div
                (click)="addSession()"
                class="select-indicator flex-row justify-start items-center absolute z-[100] h-[31px] top-[2.5px] left-0"
                [ngClass]="{
                    'w-[calc(100%_-_18px)]': directBookAvailable,
                    'w-full': !directBookAvailable
                }"
                matTooltip="{{
                    waitingUser
                        ? 'This is your most likely partner. Partners can change before the session starts—but don’t worry, we’ll automatically match you with a new one.'
                        : 'Book a session even if you don’t see anyone—we’ll find you a partner!'
                }}"
                matTooltipClass="f-tooltip">
                <div class="ml-[7px] h-full w-[31px] flex justify-center items-center">
                    <fa-duotone-icon
                        class="text-[31px]"
                        [icon]="faCircleCheck"
                        primaryColor="white"
                        secondaryColor="#7D7EC3"
                        primaryOpacity="1.0"
                        secondaryOpacity="0.70"></fa-duotone-icon>
                </div>

                <div class="fm-select-text overflow-hidden">
                    <div class="wrap-double-line-el">Select</div>
                </div>
                <div
                    *ngIf="saved"
                    class="absolute z-[99] -bottom-0 left-[22px] bg-[#7D7EC3] opacity-70 w-4 h-4 rounded-full"></div>
            </div>

            <div
                (click)="openPartnerModalStarBar()"
                *ngIf="directBookAvailable"
                class="flex justify-center items-center text-white h-7 w-4 bg-[#7579DA] ml-auto opacity-80 mr-[1px] rounded-sm"
                matTooltip="Book a locked-in session with a Favorite partner"
                matTooltipClass="f-tooltip">
                <fa-icon class="text-[8px]" [icon]="faStar"></fa-icon>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="2">
        <!-- Selected slot -->
        <div class="fm-selected-slot" *ngIf="hidden == false" (domChange)="onDomChange($event)">
            <div class="fm-selected-slot-top-actions-container">
                <div class="fm-selected-action" style="margin-right: 3px">
                    <app-spinner-button
                        class="w-full"
                        [disable]="false"
                        [label]="useButtonIcons ? '' : 'Book'"
                        [fmIcon]="useButtonIcons ? 'fm-icon-double-checkmarks' : null"
                        fmIconClass="svg-double-check-tile-book"
                        classes="f-btn-tile f-btn-tile--primary w-full"
                        (clicked)="bookSession()"></app-spinner-button>
                </div>

                <div class="fm-selected-action" style="margin-left: 3px">
                    <button
                        class="f-btn-tile f-btn-tile--secondary w-full"
                        (click)="openPartnerModalSelected()">
                        <div *ngIf="!useButtonIcons; else useIconsForMoreLabel">
                            <span>More</span>
                        </div>
                        <ng-template #useIconsForMoreLabel>
                            <fa-icon
                                [icon]="faEllipsis"
                                [classes]="['fa-icon-size-16']"></fa-icon> </ng-template
                    ></button>
                </div>
            </div>
            <div *ngIf="dstChosen == true" class="fm-tile-dst-text">Daylight</div>
            <div *ngIf="dstChosen == true" class="fm-tile-dst-text">Saving</div>
            <div class="fm-selected-slot-top-actions-container">
                <div class="fm-selected-action">
                    <button
                        class="f-btn-tile f-btn-tile--secondary w-full"
                        (click)="removeSession()"
                        aria-label="Cancel session"
                        ><span *ngIf="!useButtonIcons">Remove</span>
                        <fa-icon
                            *ngIf="useButtonIcons"
                            [icon]="faXmark"
                            [classes]="['fa-icon-size-16']"></fa-icon
                    ></button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="3">
        <!-- Booked but not matched slot -->
        <div
            class="flex flex-col justify-between items-start w-full h-full p-1.5 border-2 border-[#7d7ec3] bg-[#f2f3fe] font-avenir rounded-fm-5 relative"
            [ngClass]="{ '!p-3': !isCalendarTile }"
            (domChange)="onDomChange($event)">
            <div *ngIf="dstChosen == true" class="fm-chosen-dst-text">DST</div>
            <div class="flex flex-row justify-start items-start w-full">
                <div *ngIf="!hideProfileImage" class="h-[30px] w-[30px] mr-1">
                    <fa-stack class="h-[30px] w-[30px]">
                        <fa-icon
                            class="text-[#DADAED]"
                            [icon]="faCircle"
                            stackItemSize="1x"
                            transform="grow-14"></fa-icon>
                        <fa-icon
                            class="text-blurple"
                            [icon]="faArrowsRepeat"
                            stackItemSize="1x"
                            transform="shrink-2"></fa-icon>
                    </fa-stack>
                </div>
                <div class="flex flex-col justify-center items-start w-full overflow-hidden">
                    <div class="flex flex-row text-11-15 font-semibold text-bluegrey w-full">
                        <div class="wrap-single-line-el">{{ timeString }}</div>
                        <div *ngIf="!isCalendarTile" class="ml-auto mr-0.5">{{
                            sessionDurationLabel
                        }}</div>
                    </div>
                    <div class="partner-name user-link w-full">
                        <div class="wrap-single-line-el">Matching...</div>
                    </div>

                    <div
                        *ngIf="sessionTitle != '' && !halfHeight"
                        class="session-title wrap-single-line-el w-full"
                        >{{ sessionTitle }}</div
                    >
                </div>
                <button
                    matTooltip="Edit Session Settings"
                    matTooltipPosition="above"
                    matTooltipClass="f-tooltip"
                    matTooltipShowDelay="400"
                    (click)="editSession()"
                    aria-label="Edit Session Settings"
                    class="px-1 h-4 gap-1 flex flex-row items-center justify-center bg-blurple-3 hover:bg-[#D4D4FE] rounded cursor-pointer">
                    @if (quietMode) {
                        <fm-quiet-mode-icon class="text-[11px] text-blurple" />
                    }
                    <fm-activity-type-icon class="text-[11px]" [activityType]="activityType" />
                </button>
            </div>

            <div
                class="flex flex-row justify-between items-end w-full"
                [ngClass]="{ 'mt-2.5': !isCalendarTile }">
                <app-video-icon
                    *ngIf="alwaysShowVideoIcon"
                    [sessionUrl]="sessionUrl"
                    [sessionTime]="sessionTime"
                    [videoIconEnabled]="false"
                    [source]="source"
                    [hideJoinButtonTextLabel]="hideJoinButtonTextLabel">
                </app-video-icon>
                <div class="ml-auto flex gap-0.5">
                    <button
                        class="edit bg-blurple-3 hover:bg-[#D4D4FE]"
                        matTooltip="Edit session details"
                        matTooltipPosition="above"
                        matTooltipClass="f-tooltip"
                        matTooltipShowDelay="400"
                        (click)="editSession()"
                        aria-label="View session info">
                        <fa-icon [icon]="faPen" [classes]="['fa-pen']"></fa-icon>
                    </button>
                    <button
                        *ngIf="!hideCancel"
                        matTooltip="Cancel session"
                        matTooltipPosition="above"
                        matTooltipClass="f-tooltip"
                        matTooltipShowDelay="400"
                        appFmDebounceClick
                        class="cancel bg-blurple-3 hover:bg-[#D4D4FE]"
                        (debounceClick)="cancelSessionRequest()"
                        aria-label="Cancel session">
                        <fa-icon [icon]="faTimes" [classes]="['fa-cancel']"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="4">
        <!-- Booked and matched slot -->
        <div
            class="flex flex-col justify-between items-start w-full h-full p-1.5 border-2 border-[#7d7ec3] bg-[#f2f3fe] font-avenir rounded-fm-5 relative"
            [ngClass]="{ '!p-3': !isCalendarTile }"
            (domChange)="onDomChange($event)"
            [style]="sessionEnded ? 'opacity: .4' : ''">
            <div *ngIf="dstChosen == true" class="fm-chosen-dst-text">DST</div>
            <div class="flex flex-row justify-start items-start w-full">
                <app-profile-image
                    *ngIf="!hideProfileImage"
                    class="mr-1"
                    [saved]="saved"
                    [profileImage]="photoUrl"
                    [size]="'xsmall'">
                </app-profile-image>
                <div class="flex flex-col justify-center items-start w-full overflow-hidden">
                    <div class="flex flex-row text-11-15 font-semibold text-bluegrey w-full">
                        <fa-icon *ngIf="isLockedInSession" class="mr-0.5" [icon]="faLock">
                        </fa-icon>
                        <div class="wrap-single-line-el">{{ timeString }}</div>
                        <div *ngIf="!isCalendarTile" class="ml-auto mr-0.5">{{
                            sessionDurationLabel
                        }}</div>
                    </div>
                    <ng-template #disabledLink>
                        <div class="partner-name">
                            <div class="wrap-single-line-el">
                                @if (confirmedPartnerQuietMode) {
                                    <fm-quiet-mode-icon
                                        tooltip="{{ focusMate }} is in Quiet Mode"
                                        class="inline-flex mr-0.5 mb-[1px] text-[10px] text-blurple" />
                                }
                                {{ focusMate }}
                            </div>
                        </div>
                    </ng-template>
                    <a
                        *ngIf="userRouterLink != ''; else disabledLink"
                        [routerLink]="[userRouterLink]"
                        (click)="trackProfileView('Partner')"
                        class="partner-name user-link w-full">
                        <div class="wrap-single-line-el">
                            @if (confirmedPartnerQuietMode) {
                                <fm-quiet-mode-icon
                                    tooltip="{{ focusMate }} is in Quiet Mode"
                                    class="inline-flex mr-0.5 mb-[1px] text-[10px] text-blurple" />
                            }
                            {{ focusMate }}
                        </div>
                    </a>
                    <div
                        *ngIf="sessionTitle != '' && !halfHeight"
                        class="session-title wrap-single-line-el w-full"
                        >{{ sessionTitle }}</div
                    >
                </div>
                <button
                    matTooltip="Edit Session Settings"
                    matTooltipPosition="above"
                    matTooltipClass="f-tooltip"
                    matTooltipShowDelay="400"
                    (click)="editSession()"
                    aria-label="Edit Session Settings"
                    class="px-1 h-4 gap-1 flex flex-row items-center justify-center bg-blurple-3 hover:bg-[#D4D4FE] rounded cursor-pointer">
                    @if (quietMode) {
                        <fm-quiet-mode-icon class="text-[11px] text-blurple" />
                    }
                    <fm-activity-type-icon class="text-[11px]" [activityType]="activityType" />
                </button>
            </div>

            <div
                class="flex flex-row justify-between items-end w-full"
                [ngClass]="{ 'mt-2.5': !isCalendarTile }">
                <app-video-icon
                    *ngIf="videoIconEnabled || alwaysShowVideoIcon"
                    [sessionUrl]="sessionUrl"
                    [sessionTime]="sessionTime"
                    [videoIconEnabled]="videoIconEnabled"
                    [source]="source"
                    [hideJoinButtonTextLabel]="hideJoinButtonTextLabel">
                </app-video-icon>
                <div class="ml-auto flex gap-0.5">
                    <button
                        matTooltip="Edit session details"
                        matTooltipPosition="above"
                        matTooltipClass="f-tooltip"
                        matTooltipShowDelay="400"
                        class="edit bg-blurple-3 hover:bg-[#D4D4FE]"
                        (click)="editSession()"
                        aria-label="View session info">
                        <fa-icon [icon]="faPen" [classes]="['fa-pen']"></fa-icon>
                    </button>
                    <button
                        *ngIf="!hideCancel && !sessionEnded"
                        matTooltip="Cancel session"
                        matTooltipPosition="above"
                        matTooltipClass="f-tooltip"
                        matTooltipShowDelay="400"
                        appFmDebounceClick
                        class="cancel bg-blurple-3 hover:bg-[#D4D4FE]"
                        (debounceClick)="cancelSessionRequest()"
                        aria-label="Cancel session">
                        <fa-icon [icon]="faTimes" [classes]="['fa-cancel']"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="5">
        <!-- Booked and matched slot - cancel selected -->
        <div
            class="cancel-confirm"
            *ngIf="hidden == false"
            [ngClass]="{ 'cancel-full': !isCalendarTile }">
            <div class="cancel-text">Cancel?</div>
            <div class="cancel-no" (click)="cancelCancel()">
                <div>No</div>
            </div>
            <app-loading-button
                class="cancel-yes"
                classList="cancel-yes"
                label="Yes"
                loadingLabel=""
                [notValid]="false"
                (clicked)="cancelSession()"></app-loading-button>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="6">
        <!-- Daylight savings slot -->
        <div class="fm-empty-slot">
            <div
                matTooltip="Daylight saving time"
                matTooltipPosition="above"
                matTooltipShowDelay="200"
                matTooltipClass="f-tooltip"
                class="fm-tile-dst-text"
                >{{ dstString }}</div
            >
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="7">
        <!-- Maintenance slot -->
        <div
            matTooltip="This timeslot isn't available due to scheduled maintenance."
            matTooltipClass="f-tooltip"
            class="flex justify-center items-center w-full h-full text-[#a9a9a9]">
            <div class="text-10-12 font-medium text-center">Scheduled Maintenance</div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="8">
        <!-- Searching slot -->
        <div
            class="flex flex-col justify-between items-start w-full h-full p-1.5 border-2 border-[#7d7ec3] bg-[#f2f3fe] font-avenir rounded-fm-5 relative"
            [ngClass]="{ '!p-3': !isCalendarTile }"
            (domChange)="onDomChange($event)">
            <div *ngIf="dstChosen == true" class="fm-chosen-dst-text">DST</div>
            <div class="flex flex-row justify-start items-start w-full">
                <div *ngIf="!hideProfileImage" class="h-[30px] w-[30px] mr-1">
                    <fa-stack class="h-[30px] w-[30px]">
                        <fa-icon
                            class="text-[#DADAED]"
                            [icon]="faCircle"
                            stackItemSize="1x"
                            transform="grow-14"></fa-icon>
                        <fa-icon
                            class="text-blurple"
                            [icon]="faArrowsRepeat"
                            stackItemSize="1x"
                            transform="shrink-2"></fa-icon>
                    </fa-stack>
                </div>
                <div class="flex flex-col justify-center items-start w-full overflow-hidden">
                    <div class="flex flex-row text-11-15 font-semibold text-bluegrey w-full">
                        <div class="wrap-single-line-el">{{ timeString }}</div>
                        <div *ngIf="!isCalendarTile" class="ml-auto mr-0.5">{{
                            sessionDurationLabel
                        }}</div>
                    </div>
                    <div class="partner-name user-link w-full">
                        <div class="wrap-single-line-el">Searching</div>
                    </div>

                    <div
                        *ngIf="sessionTitle != '' && !halfHeight"
                        class="session-title wrap-single-line-el w-full"
                        >{{ sessionTitle }}</div
                    >
                </div>
                <button
                    matTooltip="Edit Session Settings"
                    matTooltipPosition="above"
                    matTooltipClass="f-tooltip"
                    matTooltipShowDelay="400"
                    (click)="editSession()"
                    aria-label="Edit Session Settings"
                    class="px-1 h-4 gap-1 flex flex-row items-center justify-center bg-blurple-3 hover:bg-[#D4D4FE] rounded cursor-pointer">
                    @if (quietMode) {
                        <fm-quiet-mode-icon class="text-[11px] text-blurple" />
                    }
                    <fm-activity-type-icon class="text-[11px]" [activityType]="activityType" />
                </button>
            </div>

            <div
                class="flex flex-row justify-between items-end w-full"
                [ngClass]="{ 'mt-2.5': !isCalendarTile }">
                <app-video-icon
                    *ngIf="videoIconEnabled || alwaysShowVideoIcon"
                    [sessionUrl]="sessionUrl"
                    [sessionTime]="sessionTime"
                    [videoIconEnabled]="videoIconEnabled"
                    [source]="source"
                    [hideJoinButtonTextLabel]="hideJoinButtonTextLabel">
                </app-video-icon>
                <div class="ml-auto flex gap-0.5">
                    <button
                        matTooltip="Edit session details"
                        matTooltipPosition="above"
                        matTooltipClass="f-tooltip"
                        matTooltipShowDelay="400"
                        class="edit bg-blurple-3 hover:bg-[#D4D4FE]"
                        (click)="editSession()"
                        aria-label="View session info">
                        <fa-icon [icon]="faPen" [classes]="['fa-pen']"></fa-icon>
                    </button>
                    <button
                        *ngIf="!hideCancel"
                        matTooltip="Cancel session"
                        matTooltipPosition="above"
                        matTooltipClass="f-tooltip"
                        matTooltipShowDelay="400"
                        appFmDebounceClick
                        class="cancel bg-blurple-3 hover:bg-[#D4D4FE]"
                        (debounceClick)="cancelSessionRequest()"
                        aria-label="Cancel session">
                        <fa-icon [icon]="faTimes" [classes]="['fa-cancel']"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="9">
        <!-- Late slot -->
        <div
            class="flex flex-col justify-between items-start w-full h-full p-1.5 border-2 border-[#7d7ec3] bg-[#f2f3fe] font-avenir rounded-fm-5 relative"
            [ngClass]="{ '!p-3': !isCalendarTile }"
            (domChange)="onDomChange($event)">
            <div *ngIf="dstChosen == true" class="fm-chosen-dst-text">DST</div>
            <div class="flex flex-row justify-start items-start w-full">
                <div *ngIf="!hideProfileImage" class="h-[30px] w-[30px] mr-1">
                    <fa-stack class="h-[30px] w-[30px]">
                        <fa-icon
                            class="text-[#DADAED]"
                            [icon]="faCircle"
                            stackItemSize="1x"
                            transform="grow-14"></fa-icon>
                        <fa-icon
                            class="text-blurple"
                            [icon]="faAlarmExclamation"
                            stackItemSize="1x"
                            transform="shrink-2"></fa-icon>
                    </fa-stack>
                </div>
                <div class="flex flex-col justify-center items-start w-full overflow-hidden">
                    <div class="flex flex-row text-11-15 font-semibold text-bluegrey w-full">
                        <div class="wrap-single-line-el">{{ timeString }}</div>
                        <div *ngIf="!isCalendarTile" class="ml-auto mr-0.5">{{
                            sessionDurationLabel
                        }}</div>
                    </div>
                    <div class="partner-name user-link w-full">
                        <div class="wrap-single-line-el">Late</div>
                    </div>

                    <div
                        *ngIf="sessionTitle != '' && !halfHeight"
                        class="session-title wrap-single-line-el w-full"
                        >{{ sessionTitle }}</div
                    >
                </div>
                <button
                    matTooltip="Edit Session Settings"
                    matTooltipPosition="above"
                    matTooltipClass="f-tooltip"
                    matTooltipShowDelay="400"
                    (click)="editSession()"
                    aria-label="Edit Session Settings"
                    class="px-1 h-4 gap-1 flex flex-row items-center justify-center bg-blurple-3 hover:bg-[#D4D4FE] rounded cursor-pointer">
                    @if (quietMode) {
                        <fm-quiet-mode-icon class="text-[11px] text-blurple" />
                    }
                    <fm-activity-type-icon class="text-[11px]" [activityType]="activityType" />
                </button>
            </div>

            <div
                class="flex flex-row justify-between items-end w-full"
                [ngClass]="{ 'mt-2.5': !isCalendarTile }">
                <app-video-icon
                    *ngIf="videoIconEnabled || alwaysShowVideoIcon"
                    [sessionUrl]="sessionUrl"
                    [sessionTime]="sessionTime"
                    [videoIconEnabled]="videoIconEnabled"
                    [source]="source"
                    [hideJoinButtonTextLabel]="hideJoinButtonTextLabel">
                </app-video-icon>
                <div class="ml-auto flex gap-0.5">
                    <button
                        matTooltip="Edit session details"
                        matTooltipPosition="above"
                        matTooltipClass="f-tooltip"
                        matTooltipShowDelay="400"
                        class="edit bg-blurple-3 hover:bg-[#D4D4FE]"
                        (click)="editSession()"
                        aria-label="View session info">
                        <fa-icon [icon]="faPen" [classes]="['fa-pen']"></fa-icon>
                    </button>
                    <button
                        *ngIf="!hideCancel"
                        matTooltip="Cancel session"
                        matTooltipPosition="above"
                        matTooltipClass="f-tooltip"
                        matTooltipShowDelay="400"
                        appFmDebounceClick
                        class="cancel bg-blurple-3 hover:bg-[#D4D4FE]"
                        (debounceClick)="cancelSessionRequest()"
                        aria-label="Cancel session">
                        <fa-icon [icon]="faTimes" [classes]="['fa-cancel']"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="10">
        <!-- Missing partner slot (session has started) -->
        <div
            class="flex flex-col justify-between items-start w-full h-full p-1.5 border-2 border-[#7d7ec3] bg-[#f2f3fe] font-avenir rounded-fm-5 relative"
            [ngClass]="{ '!p-3': !isCalendarTile }"
            (domChange)="onDomChange($event)">
            <div *ngIf="dstChosen == true" class="fm-chosen-dst-text">DST</div>
            <div class="flex flex-row justify-start items-start w-full">
                <div *ngIf="!hideProfileImage" class="h-[30px] w-[30px] mr-1">
                    <fa-stack class="h-[30px] w-[30px]">
                        <fa-icon
                            class="text-[#DADAED]"
                            [icon]="faCircle"
                            stackItemSize="1x"
                            transform="grow-14"></fa-icon>
                        <fa-icon
                            class="text-blurple"
                            [icon]="faExclamation"
                            stackItemSize="1x"
                            transform="shrink-2"></fa-icon>
                    </fa-stack>
                </div>
                <div class="flex flex-col justify-center items-start w-full overflow-hidden">
                    <div class="flex flex-row text-11-15 font-semibold text-bluegrey w-full">
                        <div class="wrap-single-line-el">{{ timeString }}</div>
                        <div *ngIf="!isCalendarTile" class="ml-auto mr-0.5">{{
                            sessionDurationLabel
                        }}</div>
                    </div>
                    <div class="partner-name user-link w-full">
                        <div class="wrap-single-line-el">Join to match</div>
                    </div>

                    <div
                        *ngIf="sessionTitle != '' && !halfHeight"
                        class="session-title wrap-single-line-el w-full"
                        >{{ sessionTitle }}</div
                    >
                </div>
                <button
                    matTooltip="Edit Session Settings"
                    matTooltipPosition="above"
                    matTooltipClass="f-tooltip"
                    matTooltipShowDelay="400"
                    (click)="editSession()"
                    aria-label="Edit Session Settings"
                    class="px-1 h-4 gap-1 flex flex-row items-center justify-center bg-blurple-3 hover:bg-[#D4D4FE] rounded cursor-pointer">
                    @if (quietMode) {
                        <fm-quiet-mode-icon class="text-[11px] text-blurple" />
                    }
                    <fm-activity-type-icon class="text-[11px]" [activityType]="activityType" />
                </button>
            </div>

            <div
                class="flex flex-row justify-between items-end w-full"
                [ngClass]="{ 'mt-2.5': !isCalendarTile }">
                <app-video-icon
                    [sessionUrl]="sessionUrl"
                    [sessionTime]="sessionTime"
                    [videoIconEnabled]="true"
                    [rematchAtStart]="true"
                    [source]="source"
                    [hideJoinButtonTextLabel]="hideJoinButtonTextLabel">
                </app-video-icon>
                <div class="ml-auto flex gap-0.5">
                    <button
                        matTooltip="Edit session details"
                        matTooltipPosition="above"
                        matTooltipClass="f-tooltip"
                        matTooltipShowDelay="400"
                        class="edit bg-blurple-3 hover:bg-[#D4D4FE]"
                        (click)="editSession()"
                        aria-label="View session info">
                        <fa-icon [icon]="faPen" [classes]="['fa-pen']"></fa-icon>
                    </button>
                    <button
                        *ngIf="!hideCancel"
                        matTooltip="Cancel session"
                        matTooltipPosition="above"
                        matTooltipClass="f-tooltip"
                        matTooltipShowDelay="400"
                        appFmDebounceClick
                        class="cancel bg-blurple-3 hover:bg-[#D4D4FE]"
                        (debounceClick)="cancelSessionRequest()"
                        aria-label="Cancel session">
                        <fa-icon [icon]="faTimes" [classes]="['fa-cancel']"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
