import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UtilsService } from '@app/core/_services/utils.service'

export const FOUND_NEW_PARTNER_ACTION_COUNTDOWN_COMPLETE = 1

@Component({
    selector: 'app-found-new-partner',
    templateUrl: './found-new-partner.component.html',
    styleUrls: ['./found-new-partner.component.scss'],
})
export class FoundNewPartnerComponent implements OnInit {
    public countdownTo: number

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private analyticsService: AnalyticsService,
        private util: UtilsService,
        public dialog: MatDialogRef<FoundNewPartnerComponent>,
    ) {}

    ngOnInit(): void {
        let now = this.util.getCurrentTimestamp()
        this.countdownTo = now + 10000
        this.analyticsService.logViewedModalEvent('New Partner: Found')

        if (!this.data.partnerPhoto) {
            this.data.partnerPhoto = '../../../assets/images/blank_profile.png'
        }
    }

    public failedImageLoad() {
        this.data.partnerPhoto = '../../../assets/images/blank_profile.png'
    }

    public handleCountdownFinished() {
        this.dialog.close(FOUND_NEW_PARTNER_ACTION_COUNTDOWN_COMPLETE)
    }
}
