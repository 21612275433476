<div class="diag-container">
    <mat-icon (click)="onCloseDialog()" class="diag-close icon-size-16">clear</mat-icon>
    <div class="diag-header">
        <div class="diag-header-text fm-mt-50px fm-mb-30px" style="color: #2f3150">
            Unsupported browser
        </div>
    </div>
    <div class="diag-body">
        <div class="diag-sub-header-text text-left fm-mt-10px fm-mb-10px" style="color: #2f3150">
            <p>
                On desktop, we strongly recommend using
                <a class="diag-link" href="https://www.google.com/chrome" target="_blank">Chrome</a
                >.
            </p>
            <p> On mobile, we support these browsers: </p>

            <ul>
                <li>Android: Chrome</li>
                <li>iOS (iPhone/iPad): Safari</li>
            </ul>

            <p> Please login to Focusmate on one of our supported browsers. </p>
        </div>

        <div class="customHrDiag w-full" style="color: #2f3150"></div>
        <div class="diag-action fm-mt-20px fm-mb-50px w-full">
            <button
                appFmDebounceClick
                (debounceClick)="confirmCopy()"
                style="min-width: 150px"
                [copy-clipboard]="sessionLink"
                class="fm-btn fm-btn--md"
                >Copy session link to clipboard</button
            >
        </div>
    </div>
</div>
