import { Component, HostListener, OnInit, ViewChild } from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { ActivatedRoute, Router } from '@angular/router'
import { User } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { PeopleService } from '@app/core/_services/people.service'
import {
    MAX_SET_TIMEOUT_VALUE,
    TimeUtilitiesService,
} from '@app/core/_services/time-utilities.service'
import { UserRelationshipService } from '@app/core/_services/user-relationship.service'
import { UserService } from '@app/core/_services/user.service'
import { FmSelectOption } from '@app/shared/components/fm-select/fm-select.component'
import { LoadingIndicatorComponent } from '@app/shared/components/loading-indicator/loading-indicator.component'
import {
    faCalendarStar,
    faCircleInfo,
    faClockEight,
    faUserGroup,
} from '@fortawesome/pro-regular-svg-icons'
import { faArrowDownArrowUp, faGhost } from '@fortawesome/pro-regular-svg-icons'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { Subject } from 'rxjs'
import { debounceTime, takeUntil, take } from 'rxjs/operators'

const DEFAULT_SORT = 'asc:name'

const SNOOZE_SORT_BY_OPTIONS: FmSelectOption[] = [
    { value: 'asc:name', name: 'First name (A-Z)', displayIcon: faArrowDownArrowUp },
    { value: 'desc:name', name: 'First name (Z-A)', displayIcon: faArrowDownArrowUp },
    { value: 'asc:expires_at', name: 'Snoozed until', displayIcon: faArrowDownArrowUp },
    {
        value: 'desc:snoozed_at',
        name: 'Date snoozed (Newest)',
        displayIcon: faArrowDownArrowUp,
    },
    { value: 'asc:snoozed_at', name: 'Date snoozed (Oldest)', displayIcon: faArrowDownArrowUp },
]

const ALL_PARTNERS_SORT_BY_OPTIONS: FmSelectOption[] = [
    { value: 'asc:name', name: 'First name (A-Z)', displayIcon: faArrowDownArrowUp },
    { value: 'desc:name', name: 'First name (Z-A)', displayIcon: faArrowDownArrowUp },
    { value: 'desc:recent', name: 'Most recent', displayIcon: faArrowDownArrowUp },
    { value: 'desc:frequency', name: 'Sessions together', displayIcon: faArrowDownArrowUp },
]

const SAVED_SORT_BY_OPTIONS: FmSelectOption[] = [
    { value: 'asc:name', name: 'First name (A-Z)', displayIcon: faArrowDownArrowUp },
    { value: 'desc:name', name: 'First name (Z-A)', displayIcon: faArrowDownArrowUp },
    { value: 'desc:recent', name: 'Most recent', displayIcon: faArrowDownArrowUp },
    { value: 'desc:frequency', name: 'Sessions together', displayIcon: faArrowDownArrowUp },
    { value: 'desc:favorited', name: 'Recently favorited', displayIcon: faArrowDownArrowUp },
]

const LIST_TYPE_OPTIONS: FmSelectOption[] = [
    { value: 'favorites', name: 'Favorites', displayIcon: faStar, optionIcon: faStar },
    { value: 'partners', name: 'All partners', displayIcon: faUserGroup, optionIcon: faUserGroup },
    { value: 'snoozed', name: 'Snoozed', displayIcon: faClockEight, optionIcon: faClockEight },
]

const DEFAULT_SORT_BY = {
    everyone: 'desc:recent',
    partners: 'desc:recent',
    snoozed: 'asc:expires_at',
    favorites: 'desc:recent',
}

@Component({
    selector: 'fm-people-list',
    templateUrl: './people-list.component.html',
    styleUrls: ['./people-list.component.scss'],
})
export class PeopleListComponent implements OnInit {
    @ViewChild('paginator', { static: false }) memberPaginator: MatPaginator

    @HostListener('window:focus', ['$event'])
    onFocus(event: any): void {
        this.updateSnoozedPeople()
        this.startExpiredAtCheckTimer()
    }

    @HostListener('window:blur', ['$event'])
    onBlur(event: any): void {
        this.clearListTimer()
    }

    public people: any[] = []
    private pages = new Subject<any>()
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public page: number = 1
    public pageSize: number = 25
    public peopleCount: number = 0
    private timerId
    public sortByOptions: FmSelectOption[] = []

    // Icons
    public faGhost = faGhost
    public faUserGroup = faUserGroup
    public faArrowDownArrowUp = faArrowDownArrowUp
    public faCircleInfo = faCircleInfo
    public faClockEight = faClockEight
    public faStar = faStar
    public faCalendarStar = faCalendarStar
    public listTypes: FmSelectOption[] = LIST_TYPE_OPTIONS

    public sortBySelection
    public listSelection: string = 'everyone'

    private loadingIndicatorDialog: MatDialogRef<LoadingIndicatorComponent>
    public isLoadingList: boolean = true

    private currentUser: User

    constructor(
        public userService: UserService,
        private route: ActivatedRoute,
        public userRelationshipService: UserRelationshipService,
        private peopleService: PeopleService,
        private router: Router,
        public timeUtilitiesService: TimeUtilitiesService,
        private dialogService: DialogService,
        private loadingIndicator: MatDialog,
        public analyticsService: AnalyticsService,
    ) {}

    showLoadingIndicator() {
        const config: MatDialogConfig = {
            hasBackdrop: true,
            backdropClass: 'fm-transparent-overlay-class',
            panelClass: ['fm-loading-indicator'],
            disableClose: true,
            width: '60px',
            height: '60px',
        }
        this.isLoadingList = true
        this.loadingIndicatorDialog = this.loadingIndicator.open(LoadingIndicatorComponent, config)
    }

    removeLoadingIndicator() {
        this.loadingIndicatorDialog.close()
        this.isLoadingList = false
    }

    setListOptionsBasedOnListSelection() {
        if (this.listSelection === 'snoozed') {
            this.sortByOptions = SNOOZE_SORT_BY_OPTIONS
        } else if (this.listSelection === 'favorites') {
            this.sortByOptions = SAVED_SORT_BY_OPTIONS
        } else {
            this.sortByOptions = ALL_PARTNERS_SORT_BY_OPTIONS
        }
    }

    setSortByBasedOnListSelection() {
        if (this.isValidListType(this.listSelection)) {
            this.sortBySelection = DEFAULT_SORT_BY[this.listSelection]
        } else {
            this.sortBySelection = DEFAULT_SORT
        }
    }

    isValidListType(listType: string) {
        return this.listTypes.some((item) => item.value === listType)
    }

    ngOnInit() {
        this.currentUser = this.route.snapshot.data['currentUser']
        this.listTypes = LIST_TYPE_OPTIONS
        this.listSelection = 'partners'

        this.initializePeopleList()
        this.getPeopleList()

        this.userRelationshipService.userRelationUpdated
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.getPeopleList()
            })

        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((queryParams) => {
            if (
                queryParams &&
                queryParams['type'] !== this.listSelection &&
                this.isValidListType(queryParams['type'])
            ) {
                this.updateListTypeSelection(queryParams['type'])
            }
        })
    }

    initializePeopleList() {
        let queryParamsType = this.route.snapshot.queryParams['type'] || 'everyone'
        queryParamsType = this.isValidListType(queryParamsType) ? queryParamsType : 'everyone'

        if (queryParamsType === 'everyone') {
            queryParamsType = 'partners'
        }
        this.listSelection = queryParamsType

        this.setListOptionsBasedOnListSelection()
        this.setSortByBasedOnListSelection()

        this.addQueryParamsToUrl()

        this.pages.pipe(takeUntil(this.ngUnsubscribe), debounceTime(200)).subscribe((e) => {
            this.page = e.event.pageIndex + 1
            this.getPeopleList()
        })
    }

    private getPeopleList() {
        this.showLoadingIndicator()

        this.peopleService
            .getPartnerList(this.listSelection, this.page, this.pageSize, this.sortBySelection)
            .subscribe({
                next: (v) => this.receivedNewList(v),
                error: (e) => this.removeLoadingIndicator(),
                complete: () => this.removeLoadingIndicator(),
            })
    }

    public receivedNewList(data) {
        this.peopleCount = data.peopleCount
        this.people = data.people
        this.updateSnoozedPeople()
        this.startExpiredAtCheckTimer()
        this.removeLoadingIndicator()
    }

    public updateSortBySelection(selection) {
        this.sortBySelection = selection
        this.page = 1
        this.analyticsService.logClickedEvent(
            `${this.listSelection}-${this.sortBySelection}`,
            'peopleList',
        )
        this.getPeopleList()
    }

    public updateListTypeSelection(selection) {
        this.listSelection = selection
        this.analyticsService.logClickedEvent(this.listSelection, 'peopleList')
        this.page = 1
        this.addQueryParamsToUrl()
        this.setListOptionsBasedOnListSelection()
        this.setSortByBasedOnListSelection()
        this.getPeopleList()
    }

    pageEventOccured(e: PageEvent) {
        this.pages.next({ event: e })
    }

    updateSnoozedPeople() {
        if (this.listSelection === 'snoozed') {
            this.removeExpiredSnoozesIfNeeded()
        } else {
            this.people.forEach((person) => {
                if (person.snoozed) {
                    let timeout = this.timeUtilitiesService.getDurationBetweenNowAndISOString(
                        person.expiresAt,
                        'seconds',
                    )
                    person.snoozed = timeout == null || timeout > 0
                    if (!person.snoozed) {
                        person.expiresAt = null
                    }
                }
            })
        }
    }

    removeExpiredSnoozesIfNeeded() {
        this.people = this.people.filter((person) => {
            let timeout = this.timeUtilitiesService.getDurationBetweenNowAndISOString(
                person.expiresAt,
                'seconds',
            )
            return timeout == null || timeout > 0
        })
    }

    startExpiredAtCheckTimer() {
        this.clearListTimer()
        let nextTimeoutString: string | null = null

        for (let i = 0; i < this.people.length; i++) {
            if (this.people[i].snoozed) {
                if (!nextTimeoutString || this.people[i].expiresAt < nextTimeoutString) {
                    nextTimeoutString = this.people[i].expiresAt
                }
            }
        }

        let nextTimeout = this.timeUtilitiesService.getDurationBetweenNowAndISOString(
            nextTimeoutString,
            'milliseconds',
        )

        if (nextTimeout !== null) {
            if (nextTimeout < 0) {
                nextTimeout = 0
            }

            if (nextTimeout < MAX_SET_TIMEOUT_VALUE) {
                this.timerId = setTimeout(() => {
                    this.updateSnoozedPeople()
                }, nextTimeout)
            }
        }
    }

    launchInfoModal() {
        this.dialogService.openPeopleListInfoModal()
    }

    clearListTimer() {
        if (this.timerId) {
            clearTimeout(this.timerId)
            this.timerId = null
        }
    }

    addQueryParamsToUrl() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { type: this.listSelection },
            queryParamsHandling: 'merge',
            skipLocationChange: false,
        })
    }

    ngOnDestroy() {
        this.clearListTimer()
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
