import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@app/core/_services/api.service'
import { environment } from '@env/environment'

@Injectable()
export class pminsService {
    constructor(private apiService: ApiService) {}

    getpmins(): Observable<any> {
        return this.apiService.get(environment.api_url + 'pmins/')
    }
}
