<div class="flex flex-col items-center relative box-border w-full h-full font-avenir">
    <div class="h-[50px] w-full">
        <fa-icon
            class="std-modal-close z-10"
            [icon]="faTimes"
            [classes]="['fa-std-close-icon']"
            (click)="closeModalButtonClick()">
        </fa-icon>
    </div>
    <fm-create-session-form
        [bookingSource]="bookingSource"
        (onBookingSuccess)="onBookingSuccess(data)"
        (onBookingFailure)="onBookingFailure()"
        (onBookingCancel)="onBookingCancel()"></fm-create-session-form>
</div>
