import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { User } from '@app/core/_models'
import { DOMAIN_UPDATED_NOTIFICATION_VIEWED } from '@app/core/_services'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserService } from '@app/core/_services/user.service'
import {} from '@fortawesome/pro-regular-svg-icons'
import {
    faArrowPointer,
    faBell,
    faBookmark,
    faTimes,
    faVideo,
} from '@fortawesome/pro-solid-svg-icons'
import { take } from 'rxjs'

@Component({
    selector: 'app-domain-updated-notification',
    templateUrl: './domain-updated-notification.component.html',
    styleUrls: ['./domain-updated-notification.component.scss'],
})
export class DomainUpdatedNotificationComponent {
    public faTimes = faTimes
    public faArrowPointer = faArrowPointer
    public faBell = faBell
    public faVideo = faVideo
    public faBookmark = faBookmark
    public currentUrl: string
    private currentUser: User = new User()

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<DomainUpdatedNotificationComponent>,
        private analyticsService: AnalyticsService,
        public userService: UserService,
    ) {}

    ngOnInit(): void {
        this.userService.currentUser.pipe(take(1)).subscribe((user) => {
            this.currentUser = user
        })
        this.analyticsService.logViewedModalEvent('Domain Updated Notification')
    }

    closeModal() {
        this.dialog.close()
    }

    needHelp() {
        this.analyticsService.logClickedEvent(
            'I need more information',
            'Domain Updated Notification',
        )
    }

    ngOnDestroy(): void {
        if (this.currentUser.userId) {
            this.currentUser.properties[DOMAIN_UPDATED_NOTIFICATION_VIEWED] = true
            this.userService.update(this.currentUser).subscribe()
        }
    }
}
