<div class="flex flex-col items-center relative box-border w-full h-full font-avenir">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="onCloseButtonClick()"></fa-icon>

    <!-- header -->
    <div
        class="pt-[50px] pb-[36px] sm:pt-[60px] sm:pb-[35px] px-[20px] sm:px-[100px] flex flex-col items-center justify-center">
        <div class="text-center text-26-36 font-semibold"> It's not quite time! </div>
    </div>

    <!-- body -->
    <div
        class="max-w-full px-[20px] sm:px-[100px] mb-[60px] flex flex-col items-center justify-center gap-[40px] text-14-19 font-medium text-bluegrey-1">
        Your session starts at {{ sessionStartTimeStr }}, and you can join up to 10 minutes early.
        Thanks for being prompt - come back soon!

        <button class="f-btn f-btn--primary f-btn-250" (click)="onCloseButtonClick()"
            >See you then!</button
        >
    </div>
</div>
