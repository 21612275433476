import { Component, OnInit, Input } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { UserService } from '@app/core/_services/user.service'

@Component({
    selector: 'app-group-logo',
    templateUrl: './group-logo.component.html',
    styleUrls: ['./group-logo.component.scss'],
})
export class GroupLogoComponent implements OnInit {
    @Input() logoUrl: string
    @Input() logoClass: string

    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public groupId: string = ''
    public groupRouterLink: string = ''

    constructor(public userService: UserService) {}

    ngOnInit() {
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            for (let group of user.group) {
                if (group.groupLogo === this.logoUrl) {
                    this.groupId = group.groupId
                    this.groupRouterLink = '/group/' + this.groupId
                }
            }
        })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
