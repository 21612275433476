import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { UserRelationshipService } from '@app/core/_services/user-relationship.service'
import { take, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { UserService } from '@app/core/_services/user.service'
import {
    USER_ACTION_BLOCKED,
    USER_ACTION_LAUNCH_SNOOZE,
    USER_ACTION_NONE,
} from '@app/user-actions/components/user-actions-menu/user-actions-menu.component'
import { SafeResourceUrl } from '@angular/platform-browser'
import { UtilsService } from '@app/core/_services'

type BlockModalContent = 'block' | 'blockInfo' | 'snoozeInfo'

@Component({
    selector: 'app-block-user',
    templateUrl: './block-user.component.html',
    styleUrls: ['./block-user.component.scss'],
})
export class BlockUserComponent implements OnInit {
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public modalContent: BlockModalContent = 'block'
    public faTimes = faTimes
    public settingsUrl: SafeResourceUrl
    public inviteLink: SafeResourceUrl = this.utilsService.getInviteUrl()

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialogRef<BlockUserComponent>,
        private userRelationshipService: UserRelationshipService,
        private analyticsService: AnalyticsService,
        private userService: UserService,
        private utilsService: UtilsService,
    ) {}

    blockUser() {
        this.userRelationshipService
            .blockUser(this.data.partnerId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (success) => {
                    this.analyticsService.logAmplitudeBlockUser(
                        this.data.source,
                        this.data.partnerSaved,
                    )
                    this.dialog.close({ userAction: USER_ACTION_BLOCKED })
                },
                (error) => {
                    this.dialog.close({ userAction: USER_ACTION_NONE })
                },
            )
    }

    closeDialog() {
        this.dialog.close({ userAction: USER_ACTION_NONE })
    }

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Block Member: Confirm')
        this.userService.currentUser.pipe(take(1)).subscribe((user) => {
            this.settingsUrl = this.utilsService.getProfilePartnerPreferencesUrl()
        })
    }

    showSnoozeInfo() {
        this.modalContent = 'snoozeInfo'
        this.analyticsService.logViewedModalEvent('Block Member: Snooze Info')
    }

    showBlockInfo() {
        this.modalContent = 'blockInfo'
        this.analyticsService.logViewedModalEvent('Block Member: Block Info')
    }

    showBlockSelection() {
        this.modalContent = 'block'
        this.analyticsService.logViewedModalEvent('Block Member: Confirm')
    }

    learnMoreClicked() {
        if (this.modalContent === 'blockInfo') {
            this.analyticsService.logClickedEvent('Learn more', 'Block Member: Block Info')
        } else if (this.modalContent === 'snoozeInfo') {
            this.analyticsService.logClickedEvent('Learn more', 'Block Member: Snooze Info')
        }
    }

    settingsClicked() {
        this.analyticsService.logClickedEvent('Settings', 'Block Member: Snooze Info')
    }

    launchSnoozeModal() {
        this.analyticsService.logClickedEvent('Snooze', 'Block Member: Confirm')
        this.dialog.close({ userAction: USER_ACTION_LAUNCH_SNOOZE })
    }
}
