import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class AutoCancelStatusService {
    private autoCancelStatusSubject = new BehaviorSubject<boolean>(false)
    public autocancelStatus = this.autoCancelStatusSubject.asObservable()
    public triggerSession: number | null = null

    constructor() {}

    public setAutoCancelStatus(enabled: boolean, trigger: number | null): void {
        this.triggerSession = trigger
        this.autoCancelStatusSubject.next(enabled)
    }
}
