<div class="partner-container w-full" [ngClass]="{ selected: selected }">
    <app-profile-image
        class="profile-image-container"
        [snoozed]="snoozed"
        [saved]="saved"
        [profileImage]="profileImage"
        [profileImageIcon]="faGlobe"
        size="medium plus">
    </app-profile-image>
    <div *ngIf="!isCommunityMember; else communityMember" class="partner-info-container">
        <div class="partner-name fm-mb-4px text-left wrap-single-line-el">
            @if (quietMode) {
                <fm-quiet-mode-icon
                    tooltip="{{ displayName }} is in Quiet Mode"
                    class="inline-flex mr-0.5 text-[12px] text-blurple" />
            }
            {{ displayName }}
        </div>
        <div class="partner-info-text text-left wrap-single-line-el">
            {{ sessionsInfo }}
        </div>
        <div *ngIf="lastMet" class="partner-info-text text-left wrap-single-line-el">{{
            lastMet
        }}</div>
        <div class="partner-info-text text-left wrap-single-line-el">{{ timezoneAndTime }}</div>
    </div>
    <ng-template #communityMember>
        <div class="partner-info-container">
            <div class="partner-name fm-mb-12px text-left wrap-single-line-el">
                Focusmate community
            </div>
            <div class="partner-info-text text-left wrap-double-line-el">
                We’ll randomly match you with a Focusmate
                <span class="text-nowrap">community member</span>
            </div>
        </div>
    </ng-template>
    <div class="flex flex-row justify-center items-center">
        <fm-activity-type-icon
            class="text-[13px] mb-[3px] mr-3.5 flex flex-row justify-center items-center"
            [activityType]="activityType"
            showTooltip="true" />

        <a
            matTooltip="View profile"
            matTooltipPosition="above"
            matTooltipShowDelay="200"
            matTooltipClass="f-tooltip"
            (click)="trackUserProfileSelected()"
            *ngIf="!isCommunityMember && profileLink"
            class="text-bluegrey text-[18px]"
            [routerLink]="profileLink"
            target="_blank">
            <fa-icon [icon]="faAddressCard" [classes]="['']"></fa-icon>
        </a>
    </div>
</div>
