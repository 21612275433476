import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { UserService } from '@app/core/_services/user.service'
import { StoreRedirectOnLoginService } from '@app/core/_services/store-redirect-on-login.service'
import { StripeService } from '../_services/stripe.service'

@Injectable({
    providedIn: 'root',
})
export class UpgradePlanGuard {
    constructor(
        private router: Router,
        private userService: UserService,
        private stripeService: StripeService,
        private storeRedirectOnLoginService: StoreRedirectOnLoginService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return Observable.create((obs) => {
            this.userService.isAuthenticated.pipe(take(1)).subscribe((authenticated) => {
                if (authenticated) {
                    this.userService.currentUser.pipe(take(1)).subscribe((user) => {
                        if (this.stripeService.isFreeTierPlan(user.planInfo.productAccess)) {
                            obs.next(true)
                        } else {
                            this.router.navigate(['/'])
                            obs.next(false)
                        }
                    })
                } else {
                    this.storeRedirectOnLoginService.setUrlToRedirectToOnLogin(state.url)
                    this.router.navigate(['/login'])
                    obs.next(false)
                }
            })
        })
    }
}
