import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

@Component({
    selector: 'app-inviter-meeting-no-longer-available',
    templateUrl: './inviter-meeting-no-longer-available.component.html',
    styleUrls: ['./inviter-meeting-no-longer-available.component.scss'],
})
export class InviterMeetingNoLongerAvailableComponent implements OnInit {
    public faTimes = faTimes

    constructor(
        private analyticsService: AnalyticsService,
        private dialog: MatDialogRef<InviterMeetingNoLongerAvailableComponent>,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('No Longer Available')
    }

    closeDialog() {
        this.dialog.close(false)
    }
}
