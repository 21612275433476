export const environment = {
    sentryEnv: 'production',
    sentryDsn: 'https://025e717765fb40baa76b9efee0df21fa@sentry.io/1506414',
    sentryTracesSampleRate: 0.001, // .1%
    sentryTracePropagationTargets: [/^https:\/\/api.focusmate\.com/],
    sentryReplaysSessionSampleRate: 0.0, // No replays for now
    sentryReplaysOnErrorSampleRate: 0.001, // .1%
    sentryShowDialog: false,
    amplitudeKey: '98a40760f895a4f469d7482d76cff6be',
    amplitudeEndPoint: 'https://analytics2.focusmate.com/2/httpapi',
    stripePubKey: 'pk_live_kXoWj58MO8DuXSzJeITR92X7',
    applicationVersion: '992.5f91309e',
    production: true,
    g_client_id: '754392301417-nfnell7fk8icqsl6g0n51vanb3ug2ssm.apps.googleusercontent.com',
    fb_client_id: '339703016486562',
    fb_ver_num: 'v2.8',
    firebase: {
        apiKey: 'AIzaSyBd7gm2iFZ8ksJRZfjKGf0j_UcLVFSQbWc',
        authDomain: 'auth.focusmate.com',
        databaseURL: 'https://focusmate-99d59.firebaseio.com',
        projectId: 'focusmate-99d59',
        storageBucket: 'focusmate-99d59.appspot.com',
        messagingSenderId: '615362219491',
    },
    marketingHomeUrl: 'https://www.focusmate.com',
    marketingHomeUrlShort: 'focusmate.com',
    homeUrl: 'https://app.focusmate.com',
    domainName: 'app.focusmate.com',
    message_partner: {
        link: 'https://focusmate.wufoo.com/forms/p1piow9k0mqrf7b/def/',
        email: 'Field4=',
        sid: '&Field5=',
    },
    videoLoc: 'https://focusmate.nyc3.digitaloceanspaces.com/Focusmate.mp4',
    api_url: 'https://api.focusmate.com/v1/',
    segmentationSurveyJune2020TypeformLink: 'https://form.focusmate.com/tell-us-more',
    reportBugFormLink: 'https://form.focusmate.com/report-bug',
    intercom_app_id: 'suqm2wd9',
    tosVersion: 'v2',
    mediaEndpoint: 'https://media.focusmate.com',
    showDomainUpdatedConfirmation: true,
}
