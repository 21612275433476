import { Injectable } from '@angular/core'
import {
    faGlobeAfrica,
    faGlobeAmericas,
    faGlobeAsia,
    faGlobeEurope,
} from '@fortawesome/pro-solid-svg-icons'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'

export const MAX_SET_TIMEOUT_VALUE = 0x7fffffff // 32bits
export type MOMENT_DURATION_AS = 'minutes' | 'seconds' | 'milliseconds'

@Injectable({
    providedIn: 'root',
})
export class TimeUtilitiesService {
    constructor() {}

    getDurationBetweenNowAndISOString(
        datatimeInISOFormat: string | null,
        durationUnit: MOMENT_DURATION_AS,
    ): number | null {
        if (!datatimeInISOFormat) {
            return null
        } else {
            let now = moment()
            let momentFromIsoString = moment(datatimeInISOFormat)
            return Math.ceil(moment.duration(momentFromIsoString.diff(now)).as(durationUnit))
        }
    }

    getStartDayOfWeek(sessionTime: number, timeZone: string) {
        let dayNum = moment(sessionTime).tz(timeZone).format('d')

        // Moment sets Sunday to 0, Monday to 1 etc..
        // We want the week to start on Monday
        if (+dayNum === 0) {
            dayNum = '6'
        } else {
            dayNum = (+dayNum - 1).toString()
        }
        return dayNum + ' - ' + moment(sessionTime).tz(timeZone).format('dddd').toLowerCase()
    }

    getSecondsSince(time: number) {
        return (moment().utc().valueOf() - time) / 1000
    }

    getMsUntilNextMinute() {
        return moment().endOf('minute').valueOf() - moment().valueOf()
    }

    isToday(sessionTime: number, timezone: string): boolean {
        const today = moment().tz(timezone).startOf('day')
        const sessionDate = moment(sessionTime).tz(timezone).startOf('day')
        return today.isSame(sessionDate)
    }

    isTomorrow(sessionTime: number, timezone: string): boolean {
        let now_plus_one_day = moment().tz(timezone).add(1, 'd')
        return moment(sessionTime).tz(timezone).date() === now_plus_one_day.date()
    }

    public determineGlobeIconFromTimezone(timezone: string): any {
        if (!timezone) {
            return faGlobeAmericas
        } else {
            let region: string = timezone.split('/')[0]

            if (region === 'US' || region === 'America' || region === 'Canada') {
                return faGlobeAmericas
            } else if (region === 'Africa') {
                return faGlobeAfrica
            } else if (region === 'Asia' || region === 'Australia' || region === 'Indian') {
                return faGlobeAsia
            } else if (region === 'Europe') {
                return faGlobeEurope
            } else {
                return faGlobeAmericas
            }
        }
    }

    public buildDurationStringFromMiliseconds(ms: number): string {
        let totalSeconds = Math.floor(ms / 1000)
        let hours = Math.floor(totalSeconds / 3600)
        let minutes = Math.floor((totalSeconds % 3600) / 60)
        let seconds = totalSeconds % 60

        if (hours > 0) {
            return `${hours} hours, ${minutes} minutes, ${seconds} seconds`
        } else if (minutes > 0) {
            return `${minutes} minutes, ${seconds} seconds`
        } else {
            return `${seconds} seconds`
        }
    }
}
