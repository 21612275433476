import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { User } from '@app/core/_models'
import { UserService } from '@app/core/_services/user.service'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { take } from 'rxjs/operators'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'
import { DialogService } from '@app/core/_services/dialog.service'
import { MatSelect } from '@angular/material/select'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'

export const SNOOZE_DURATION_ONE_HOUR = '1 hour'
export const SNOOZE_DURATION_EIGHT_HOURS = '8 hours'
export const SNOOZE_DURATION_24_HOURS = '24 hours'
export const SNOOZE_DURATION_ONE_WEEK = '1 week'
export const SNOOZE_DURATION_ONE_MONTH = '1 month'
export const SNOOZE_DURATION_CUSTOM = 'Custom'
export const SNOOZE_DURATION_INDEFINITELY = 'Until I unsnooze them'
export const SNOOZE_DURATION_INVALID = 'Select snooze duration'

export interface SnoozeDurationSelected {
    selection: string
    customValue: moment.Moment
}

const ONE_MINUTE = 60000

@Component({
    selector: 'app-fm-select-snooze-duration',
    templateUrl: './fm-select-snooze-duration.component.html',
    styleUrls: ['./fm-select-snooze-duration.component.scss'],
})
export class FmSelectSnoozeDurationComponent implements OnInit {
    @ViewChild('fmSnoozeSelect', { static: false }) fmSnoozeSelect: MatSelect
    @Input() currentSelection: string
    @Input() customValue: moment.Moment
    @Input() disabled: boolean
    @Input() btnClasses: string
    @Output() selectionChanged = new EventEmitter<SnoozeDurationSelected>()

    private currentUser: User = new User()
    private timeoutId

    optionsList = [
        { name: SNOOZE_DURATION_ONE_HOUR, expiresAt: '' },
        { name: SNOOZE_DURATION_EIGHT_HOURS, expiresAt: '' },
        { name: SNOOZE_DURATION_24_HOURS, expiresAt: '' },
        { name: SNOOZE_DURATION_ONE_WEEK, expiresAt: '' },
        { name: SNOOZE_DURATION_ONE_MONTH, expiresAt: '' },
        { name: SNOOZE_DURATION_INDEFINITELY },
        { name: SNOOZE_DURATION_CUSTOM, expiresAt: '' },
    ]

    public panelClasses: string[] = ['f-select', 'f-select-snooze-duration']

    public displayString: string = SNOOZE_DURATION_INVALID
    public expiresAt: string = ''
    faCaretDown = faCaretDown

    public startDate: moment
    public minDate: moment
    public maxDate: moment
    public datePickerDisabled: boolean = false
    public showSpinners: boolean = true
    public showSeconds = false
    public disableMinute = false
    public hideTime = false
    public enableMeridian = true
    public touchUi = false
    public stepSecond = 0
    public stepHour = 1
    public stepMinute = 30

    constructor(
        private userService: UserService,
        private dialogService: DialogService,
        private dateFormat: UserDateFormattingService,
    ) {}

    ngOnInit(): void {
        this.userService.currentUser.pipe(take(1)).subscribe((currentUser) => {
            this.currentUser = currentUser
            this.updateDurationExpireTimes()

            this.minDate = moment().tz(this.currentUser.timeZone)
            this.maxDate = moment().tz(this.currentUser.timeZone).add(1, 'y')
            this.startDate = moment
                .tz(this.currentUser.timeZone)
                .add(1, 'h')
                .set({ minutes: 0, seconds: 0, milliseconds: 0 })
        })
    }
    ngOnChanges() {
        if (this.currentUser.timeZone !== '') {
            this.updateDurationExpireTimes()
        }
    }

    private updateDurationExpireTimes() {
        let now = moment().tz(this.currentUser.timeZone)
        this.minDate = undefined
        this.minDate = moment().tz(this.currentUser.timeZone)

        for (let item of this.optionsList) {
            if (item.name === SNOOZE_DURATION_INDEFINITELY) {
                item.expiresAt = ''
            } else if (item.name === SNOOZE_DURATION_ONE_HOUR) {
                item.expiresAt = this.dateFormat.generateSnoozedUntilTimeLabel(
                    moment.tz(this.currentUser.timeZone).add(1, 'hours'),
                    now,
                )
            } else if (item.name === SNOOZE_DURATION_EIGHT_HOURS) {
                item.expiresAt = this.dateFormat.generateSnoozedUntilTimeLabel(
                    moment.tz(this.currentUser.timeZone).add(8, 'hours'),
                    now,
                )
            } else if (item.name === SNOOZE_DURATION_24_HOURS) {
                item.expiresAt = this.dateFormat.generateSnoozedUntilTimeLabel(
                    moment.tz(this.currentUser.timeZone).add(24, 'hours'),
                    now,
                )
            } else if (item.name === SNOOZE_DURATION_ONE_WEEK) {
                item.expiresAt = this.dateFormat.generateSnoozedUntilTimeLabel(
                    moment.tz(this.currentUser.timeZone).add(7, 'days'),
                    now,
                )
            } else if (item.name === SNOOZE_DURATION_ONE_MONTH) {
                item.expiresAt = this.dateFormat.generateSnoozedUntilTimeLabel(
                    moment.tz(this.currentUser.timeZone).add(1, 'months'),
                    now,
                )
            } else if (item.name === SNOOZE_DURATION_CUSTOM) {
                if (this.customValue) {
                    item.expiresAt = this.dateFormat.generateSnoozedUntilTimeLabel(
                        this.customValue,
                        now,
                    )
                } else {
                    item.expiresAt = ''
                }
            }
        }
        this.updateExpiresAtEveryMinute()
        this.setDisplayFromCurrentValue()
    }

    setDisplayFromCurrentValue(): void {
        for (let i = 0; i < this.optionsList.length; ++i) {
            if (this.optionsList[i].name === this.currentSelection) {
                this.displayString = this.optionsList[i].name
                this.expiresAt = this.optionsList[i].expiresAt
                return
            }
        }
        this.displayString = 'Select snooze duration'
        this.expiresAt = ''
    }

    openSelectOptions() {
        this.fmSnoozeSelect.open()
    }

    customDateSelected(dateTimeSelected) {
        if (moment.isMoment(dateTimeSelected)) {
            this.customValue = dateTimeSelected.clone()
            this.currentSelection = SNOOZE_DURATION_CUSTOM
            let snoozeDurationSelected: SnoozeDurationSelected = {
                selection: this.currentSelection,
                customValue: this.customValue,
            }
            this.selectionChanged.emit(snoozeDurationSelected)
            this.updateDurationExpireTimes()
            this.setDisplayFromCurrentValue()
        } else {
            this.currentSelection = SNOOZE_DURATION_INVALID
        }
    }

    private updateExpiresAtEveryMinute() {
        let now = new Date()
        let delay = ONE_MINUTE - now.getSeconds() * 1000

        this.timeoutId = setTimeout(() => {
            this.updateDurationExpireTimes()
        }, delay)
    }

    optionSelected(selectedOption) {
        if (selectedOption == SNOOZE_DURATION_CUSTOM) {
            this.dialogService
                .openDateTimePickerModal()
                .afterClosed()
                .pipe(take(1))
                .subscribe((dateTimeSelected: moment) => {
                    this.customDateSelected(dateTimeSelected)
                })
        } else {
            let snoozeDurationSelected: SnoozeDurationSelected = {
                selection: selectedOption,
                customValue: null,
            }
            this.currentSelection = selectedOption
            this.selectionChanged.emit(snoozeDurationSelected)
            this.setDisplayFromCurrentValue()
        }
    }

    ngOnDestroy() {
        if (this.timeoutId) {
            clearInterval(this.timeoutId)
        }
    }
}
