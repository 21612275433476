import { Injectable, inject } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'

import { Auth, getIdToken, user } from '@angular/fire/auth'
import { Observable } from 'rxjs'
import { of } from 'rxjs'
import { from } from 'rxjs'
import { switchMap } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class FirebaseTokenService {
    private afAuth = inject(Auth)
    token: string = ''

    constructor() {}

    getToken(): string {
        return this.token
    }

    setToken(token) {
        this.token = token
    }

    checkTokenExpiredError(response): boolean {
        if (response instanceof HttpErrorResponse) {
            if (response.hasOwnProperty('error')) {
                if (typeof response.error === 'string') {
                    let error = JSON.parse(response.error)

                    if (error.error.startsWith('Token expired')) {
                        return true
                    }
                } else if (typeof response.error === 'object') {
                    if (response.error.hasOwnProperty('error')) {
                        if (
                            response.error.error.startsWith('Token expired') ||
                            response.error.error.startsWith('Missing Token')
                        ) {
                            return true
                        }
                    }
                }
            }
        } else {
            if (
                typeof response !== 'undefined' &&
                typeof response.error !== 'undefined' &&
                typeof response.error === 'string'
            ) {
                let error = JSON.parse(response.error)

                if (error.error.startsWith('Token expired')) {
                    return true
                }
            }
        }

        return false
    }

    refreshToken(): Observable<any> {
        return user(this.afAuth).pipe(
            switchMap((currentUser) => {
                if (currentUser) {
                    return from(getIdToken(currentUser))
                } else {
                    this.token = ''
                    return of(this.token)
                }
            }),
        )
    }
}
