import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { environment } from '@env/environment'
import { Observable, Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import { ConfirmedSessionsService } from '.'
import { DialogService } from './dialog.service'

export enum BlockStatus {
    none = 0,
    blocked = 1,
    blocking = 2,
    both = 3,
}

export interface SnoozeUserRequest {
    userId: string
    expiresAt: string
}

export interface UnsnoozeUserRequest {
    userId: string
}

export interface SaveUserRequest {
    userId: string
    saveUser: boolean
}

export type UserRelationshipAction = 'snooze' | 'unsnooze' | 'block' | 'unblock' | 'save' | 'unsave'

export interface UserRelationshipActionTaken {
    action: UserRelationshipAction
    userId: string
}

@Injectable({
    providedIn: 'root',
})
export class UserRelationshipService {
    constructor(
        private confirmedSessionService: ConfirmedSessionsService,
        private apiService: ApiService,
        private dialogService: DialogService,
    ) {}

    private userRelationUpdatedSubject = new Subject<UserRelationshipActionTaken>()
    public userRelationUpdated = this.userRelationUpdatedSubject.asObservable()

    blockUser(blockUserId: string): Observable<any> {
        let blockInfo = {
            blockId: blockUserId,
        }

        return this.apiService
            .post(environment.api_url + 'relation/block', { blockInfo: blockInfo })
            .pipe(
                map((data) => {
                    this.userRelationUpdatedSubject.next({ action: 'block', userId: blockUserId })
                }),
            )
    }

    unblockUser(unblockUserId: string): Observable<any> {
        let unblockInfo = {
            unblockId: unblockUserId,
        }

        return this.apiService
            .post(environment.api_url + 'relation/unblock', {
                unblockInfo: unblockInfo,
            })
            .pipe(
                map((data) => {
                    this.userRelationUpdatedSubject.next({
                        action: 'unblock',
                        userId: unblockUserId,
                    })
                }),
            )
    }

    snoozeUser(snoozeInfo: SnoozeUserRequest): Observable<any> {
        return this.apiService.post(environment.api_url + 'relation/snooze', snoozeInfo).pipe(
            map((data) => {
                this.userRelationUpdatedSubject.next({
                    action: 'snooze',
                    userId: snoozeInfo.userId,
                })
                this.confirmedSessionService.getConfirmedSessions().subscribe()
            }),
        )
    }

    unsnoozeUser(snoozeInfo: UnsnoozeUserRequest): Observable<any> {
        return this.apiService.post(environment.api_url + 'relation/unsnooze', snoozeInfo).pipe(
            map((data) => {
                this.userRelationUpdatedSubject.next({
                    action: 'unsnooze',
                    userId: snoozeInfo.userId,
                })
                this.confirmedSessionService.getConfirmedSessions().subscribe()

                this.dialogService.openConfirmTransaction('Unsnoozed!')
            }),
        )
    }

    getSnoozeList(page: number, pageSize: number, sortBy: string) {
        let queryString = this.generateQueryString(page, pageSize, sortBy)
        return this.apiService.get(environment.api_url + 'relation/snooze' + queryString)
    }

    private generateQueryString(page: number, pageSize: number, sortBy: string): string {
        return `?page=${page}&page_size=${pageSize}&sort_by=${sortBy}`
    }

    saveUser(saveUserInfo: SaveUserRequest) {
        return this.apiService.post(environment.api_url + 'relation/save', saveUserInfo).pipe(
            map((data) => {
                this.confirmedSessionService.updateSavedStatus(
                    saveUserInfo.userId,
                    saveUserInfo.saveUser,
                )
                let action: UserRelationshipAction = 'save'

                if (!saveUserInfo.saveUser) {
                    action = 'unsave'
                }
                this.userRelationUpdatedSubject.next({ action, userId: saveUserInfo.userId })
            }),
        )
    }
}
