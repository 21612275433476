import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { User } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserService } from '@app/core/_services/user.service'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
    selector: 'app-mute-by-default-setting',
    templateUrl: './mute-by-default-setting.component.html',
    styleUrls: ['./mute-by-default-setting.component.scss'],
})
export class MuteByDefaultSettingComponent implements OnInit {
    public radioButtonInfo = [
        {
            value: false,
            label: 'Unmuted when I first join a session',
        },
        {
            value: true,
            label: 'Muted when I first join a session',
        },
    ]

    public faTimes = faTimes
    public initialPreferenceSelected: boolean
    public preferenceSelected: boolean

    public disableSaveButton = true
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    private currentUser: User = new User()

    constructor(
        public dialog: MatDialogRef<MuteByDefaultSettingComponent>,
        public userService: UserService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Default Microphone Settings')
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.currentUser = user
            this.preferenceSelected = this.currentUser.settings.muteByDefault || false
            this.initialPreferenceSelected = this.preferenceSelected
            this.checkEnableSaveButton()
        })
    }

    radioButtonChanged() {
        this.checkEnableSaveButton()
    }

    checkEnableSaveButton(): void {
        this.disableSaveButton = this.preferenceSelected === this.initialPreferenceSelected
    }

    public submitForm() {
        this.userService
            .updateSettings({ muteByDefault: this.preferenceSelected })
            .subscribe(() => {
                this.dialog.close()
            })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
