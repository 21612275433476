<mat-select
    [ngClass]="btnClasses"
    #fmSelect
    id="{{ fieldName }}"
    [panelClass]="panelClasses"
    (click)="openSelectOptions()"
    (selectionChange)="selectionMade($event)"
    [value]="currentValue"
    name="displayName"
    [placeholder]="placeholder"
    disableOptionCentering
    hideSingleSelectionIndicator
    [disabled]="disabled">
    <mat-select-trigger id="{{ fieldName }}SelectedValue">
        <fa-icon
            style="min-width: 18px; margin-right: 6px"
            *ngIf="displayIcon"
            [icon]="displayIcon"
            [classes]="['fa-icon-size-14']"></fa-icon
        ><span>{{ displayString }}</span>
    </mat-select-trigger>
    <mat-option *ngFor="let item of optionsList" [value]="item.value">
        <fa-icon
            style="min-width: 18px; margin-right: 6px"
            *ngIf="item.optionIcon"
            [icon]="item.optionIcon"
            [classes]="['fa-icon-size-14']"></fa-icon
        ><span>{{ item.name }}</span>
    </mat-option>
</mat-select>
