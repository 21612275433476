import { Injectable } from '@angular/core'
import { MatDialogConfig, MatDialog } from '@angular/material/dialog'
import { HttpErrorResponse } from '@angular/common/http'
import { ConfirmedSessionsService } from '@app/core/_services/confirmed-sessions.service'
import { BookLimitCheckService } from '@app/core/_services/book-limit-check.service'
import { SnackbarService } from '@app/core/_services/snackbar.service'
import { NoShowAcknowledgeComponent } from '@app/shared-dialogs/components/no-show-acknowledge/no-show-acknowledge.component'
import { SessionLimitExceededComponent } from '@app/shared-dialogs/components/session-limit-exceeded/session-limit-exceeded.component'
import { take } from 'rxjs/operators'
import {
    API_ERROR_BOOK_AUTO_CANCEL_ACTIVE,
    API_ERROR_BOOK_SCORE_LIMIT_EXCEEDED,
    API_ERROR_CANCEL_TOO_FAR_INTO_SESSION,
} from '../_models'

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {
    constructor(
        private bookLimitCheckService: BookLimitCheckService,
        private confirmedSessionsService: ConfirmedSessionsService,
        private snackbarService: SnackbarService,
        public dialog: MatDialog,
    ) {}

    handleErrorResponse(error: any) {
        if (error instanceof HttpErrorResponse) {
            if (error.status === 409) {
                if (error.error.hasOwnProperty('error_code')) {
                    if (error.error.error_code === API_ERROR_BOOK_SCORE_LIMIT_EXCEEDED) {
                        this.bookLimitCheckService.updateLimitInfo(
                            error.error.bookLimit,
                            error.error.attRate,
                            error.error.compSessions,
                        )
                        this.confirmedSessionsService
                            .getConfirmedSessions()
                            .pipe(take(1))
                            .subscribe((confirmed_sessions) => {
                                this.openSessionLimitDialog()
                            })
                    } else if (error.error.error_code === API_ERROR_CANCEL_TOO_FAR_INTO_SESSION) {
                        this.snackbarService.createFailureNotification(
                            error.error.error_message,
                            '',
                        )
                    } else if (error.error.error_code === API_ERROR_BOOK_AUTO_CANCEL_ACTIVE) {
                        this.openNoShowAcknowledgeDialog(error.error.acTrigger)
                    } else if (error.error.error_code === 3) {
                        this.snackbarService.createFailureNotification(
                            error.error.error_message,
                            '',
                        )
                    }
                } else if (error.error.hasOwnProperty('error')) {
                    if (error.error.error === API_ERROR_CANCEL_TOO_FAR_INTO_SESSION) {
                        this.snackbarService.createFailureNotification(error.error.message, '')
                    }
                }
            }
        }
    }

    openNoShowAcknowledgeDialog(type: number) {
        let config: MatDialogConfig = {
            data: {
                triggerSession: type,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
            disableClose: true,
        }

        this.dialog.open(NoShowAcknowledgeComponent, config)
    }

    openSessionLimitDialog() {
        let config: MatDialogConfig = {
            data: {},
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: [],
            width: '600px',
            maxWidth: '100%',
            minWidth: 300,
        }
        return this.dialog.open(SessionLimitExceededComponent, config)
    }
}
