import { Component, Signal, WritableSignal, computed, signal } from '@angular/core'
import { CommonModule } from '@angular/common'
import { XHR, XHRInitial, XHRLoading, XHRSuccess } from '@app/core/_models/xhr'
import {
    GetGoogleCalendarsResponse,
    GoogleCalendarInfo,
    GoogleCalendarService,
} from '@app/core/_services/google-calendar.service'
import { MatDialogRef } from '@angular/material/dialog'
import { SharedModule } from '@app/shared/shared.module'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { MaterialModule } from '@app/material/material.module'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { catchError, of } from 'rxjs'

@Component({
    selector: 'app-edit-google-calendar-integration-modal',
    standalone: true,
    imports: [CommonModule, SharedModule, MaterialModule],
    templateUrl: './edit-google-calendar-integration-modal.component.html',
    styleUrl: './edit-google-calendar-integration-modal.component.scss',
})
export class EditGoogleCalendarIntegrationModalComponent {
    faTimes = faTimes

    public xhrCalendars: WritableSignal<XHR<GetGoogleCalendarsResponse>> = signal(XHRInitial)
    public connectedCalendar: WritableSignal<GoogleCalendarInfo> = signal(null)
    public selectedCalendar: WritableSignal<GoogleCalendarInfo> = signal(null)
    public savingConnectedCalendar: WritableSignal<boolean> = signal(false)

    public calendars = computed(() => {
        const xhr = this.xhrCalendars()
        if (xhr.state === 'success') {
            return xhr.data.calendars
        }
        return null
    })

    public calendarListOptions = computed(() => {
        // sorted to place the primary calendar first, then alphabetical order
        const calendars = this.calendars()
        if (calendars) {
            const primaryCalendar = calendars.find((c) => c.primary)
            const otherCalendars = calendars
                .filter((c) => !c.primary)
                .sort((a, b) => a.name.localeCompare(b.name))
            return [primaryCalendar, ...otherCalendars].filter((c) => c)
        }
    })

    public disableSaveConnectedCalendarButton: Signal<boolean> = computed(() => {
        const calendars = this.xhrCalendars()
        if (
            calendars.state === 'success' &&
            this.selectedCalendar() &&
            calendars.data.connectedCalendarId !== this.selectedCalendar().id
        ) {
            return false
        }
        return true
    })

    constructor(
        private dialog: MatDialogRef<EditGoogleCalendarIntegrationModalComponent>,
        private googleCalendarService: GoogleCalendarService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Edit Google Calendar Integration')
        this.xhrCalendars.set(XHRLoading)
        this.googleCalendarService
            .getGoogleCalendarsForUser()
            .pipe(
                catchError((error) => {
                    if (error === 'refresh_error') {
                        this.dialog.close()
                        return of(null)
                    } else {
                        throw error
                    }
                }),
            )
            .subscribe((r) => {
                if (!r) {
                    return
                }
                const selectedCalendar = r.calendars.find((c) => c.id === r.connectedCalendarId)
                this.connectedCalendar.set(selectedCalendar)
                if (selectedCalendar) {
                    this.selectedCalendar.set(selectedCalendar)
                } else {
                    // select primary calendar if there is no selection
                    const primaryCalendar = r.calendars.find((c) => c.primary)
                    this.selectedCalendar.set(primaryCalendar)
                }
                this.xhrCalendars.set(XHRSuccess(r))
            })
    }

    onChangeSelectedCalendar(calendarId) {
        const selectedCalendar = this.calendars().find((c) => c.id === calendarId)
        this.selectedCalendar.set(selectedCalendar)
    }

    onSaveConnectedCalendarButtonClick() {
        this.analyticsService.logClickedEvent(
            'Save Connected Calendar',
            'Edit Google Calendar Integration Modal',
        )
        this.savingConnectedCalendar.set(true)
        this.googleCalendarService
            .setConnectedGoogleCalendar(this.selectedCalendar().id)
            .subscribe((r) => {
                this.savingConnectedCalendar.set(false)
                this.dialog.close()
            })
    }

    onCloseButtonClick() {
        this.dialog.close()
    }
}
