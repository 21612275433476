<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title">Time Format</div>
    </div>

    <div class="std-modal-body w-full">
        <div class="f-form-field w-full field-spacer">
            <mat-radio-group
                class="f-radio-group"
                [(ngModel)]="timeFormat"
                (change)="radioButtonChanged()">
                <mat-radio-button
                    disableRipple
                    class="f-radio-button !mb-[13px] first:mt-[13px]"
                    value="12hr">
                    12-hour <span class="text-grey-3 ml-[5px]">{{ timeDisplay12hr }}</span>
                </mat-radio-button>
                <mat-radio-button
                    disableRipple
                    class="f-radio-button !mb-[13px] first:mt-[13px]"
                    value="24hr">
                    24-hour <span class="text-grey-3 ml-[5px]">{{ timeDisplay24hr }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="std-modal-action-container">
            <app-spinner-button
                class="action"
                [disable]="disableSaveButton"
                [label]="'Save preference'"
                classes="f-btn f-btn--primary f-btn-250"
                (clicked)="submitForm()">
            </app-spinner-button>
        </div>
    </div>
</div>
