import { Component, OnInit } from '@angular/core'
import { Subject } from 'rxjs'

@Component({
    selector: 'app-people-container',
    templateUrl: './people.component.html',
    styleUrls: ['./people.component.scss'],
})
export class PeopleComponent implements OnInit {
    private ngUnsubscribe: Subject<any> = new Subject<any>()

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
