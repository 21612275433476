import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router'
import { UtilsService } from '@app/core/_services/utils.service'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { UserService } from '@app/core/_services/user.service'
import { LoginSignupUtilService } from '@app/core/_services/login-signup-util.service'
import { StoreRedirectOnLoginService } from '@app/core/_services/store-redirect-on-login.service'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private router: Router,
        private userService: UserService,
        private lUtils: LoginSignupUtilService,
        private storeRedirectOnLoginService: StoreRedirectOnLoginService,
    ) {}

    // Guard check when trying to load the calendar.
    // Only allow it if user is logged in and onboard is complete AND
    // required user information has been provided
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {
        return Observable.create((obs) => {
            this.userService.isAuthenticated.pipe(take(1)).subscribe((authenticated) => {
                if (authenticated) {
                    this.userService.currentUser.pipe(take(1)).subscribe((user) => {
                        if (user.onboard || this.lUtils.getComingFromSignup()) {
                            if (!this.userService.isUserAccountSetupComplete(user)) {
                                obs.next(this.router.parseUrl('profile/edit-p'))
                            } else {
                                obs.next(true)
                            }
                        } else {
                            if (!this.userService.isUserAccountSetupComplete(user)) {
                                obs.next(this.router.parseUrl('signup/profile'))
                            } else {
                                this.userService.isEmailVerified
                                    .pipe(take(1))
                                    .subscribe((verified) => {
                                        if (verified === true) {
                                            obs.next(this.router.parseUrl('signup/profile'))
                                        } else {
                                            if (user.features.experimentVerifyBeforeMatch) {
                                                obs.next(this.router.parseUrl('signup/profile'))
                                            } else {
                                                obs.next(this.router.parseUrl('signup/verify'))
                                            }
                                        }
                                    })
                            }
                        }
                    })
                } else {
                    this.storeRedirectOnLoginService.setUrlToRedirectToOnLogin(state.url)
                    obs.next(this.router.parseUrl('login'))
                }
            })
        })
    }
}
