<div
    class="std-modal-container shared-availability-container"
    [ngClass]="data.notificationOnSave ? 'saved-user-notify' : 'settings'">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div
            class="std-modal-title wrap-word-break"
            [ngClass]="{ 'std-modal-title--sentence': data.notificationOnSave }"
            >{{
                data.notificationOnSave ? '🌟 Make the most of your Favorites! 🌟' : 'Availability'
            }}</div
        >
    </div>

    <div class="std-modal-body w-full shared-availability-body">
        <div class="f-form-field w-full field-spacer">
            <label
                *ngIf="data.notificationOnSave; else fromSettingsPage"
                style="margin-bottom: 13px"
                >Let’s set up your preferences so people you enjoy working with can easily book with
                you. You can change this anytime.<br /><br />
                Who can see sessions on your ‘See availability’ page and book locked-in sessions
                with you?</label
            >

            <ng-template #fromSettingsPage>
                <label style="margin-bottom: 13px"
                    >Who can see sessions on my ‘See availability’ page and book locked-in sessions
                    with me:</label
                >
            </ng-template>

            <mat-radio-group
                class="f-radio-group"
                [(ngModel)]="preferenceSelected"
                (change)="radioButtonChanged()">
                <mat-radio-button
                    disableRipple
                    class="f-radio-button"
                    [value]="radioButtonInfo[0].value">
                    {{ radioButtonInfo[0].label }}
                </mat-radio-button>

                <div class="setting-info" *ngIf="preferenceSelected == radioButtonInfo[0].value"
                    >All Focusmate members can book locked-in sessions with you. Excludes people you
                    have snoozed or blocked, and those that don’t match your gender preference, if
                    any.</div
                >

                <mat-radio-button
                    disableRipple
                    class="f-radio-button"
                    [value]="radioButtonInfo[1].value">
                    {{ radioButtonInfo[1].label }}
                </mat-radio-button>

                <div class="setting-info" *ngIf="preferenceSelected == radioButtonInfo[1].value"
                    >Only people you have Favorited can book locked-in sessions with you and be
                    preferentially matched with you.</div
                >

                <mat-radio-button
                    disableRipple
                    class="f-radio-button"
                    [value]="radioButtonInfo[2].value">
                    {{ radioButtonInfo[2].label }}
                </mat-radio-button>
                <div class="setting-info" *ngIf="preferenceSelected == radioButtonInfo[2].value"
                    >No one can book locked-in sessions with you.</div
                >
            </mat-radio-group>
        </div>

        <div class="std-modal-action-container shared-availability-action-container">
            <app-spinner-button
                class="action"
                [disable]="disableSaveButton"
                [label]="'Save availability'"
                classes="f-btn f-btn--primary f-btn-250"
                (clicked)="submitForm()">
            </app-spinner-button>

            <a
                *ngIf="!data.notificationOnSave"
                class="f-link fm-mt-15px"
                (click)="learnMoreSelected()"
                style="text-decoration: none"
                href="http://support.focusmate.com/en/articles/6377602-availability-setting-and-see-availability-page"
                target="_blank"
                >Learn more</a
            >
            <a
                *ngIf="data.notificationOnSave"
                class="f-link fm-mt-15px"
                (click)="learnMoreAboutFavoritesSelected()"
                style="text-decoration: none"
                href="http://support.focusmate.com/en/articles/6365663-about-favorites"
                target="_blank"
                >Learn more about Favorites</a
            >
        </div>

        <mat-checkbox
            *ngIf="data.notificationOnSave"
            class="notification-pref-checkbox dont-show-text fm-mt-15px"
            disableRipple="true"
            [(ngModel)]="dontShowThisAgain"
            >Don't show this again</mat-checkbox
        >
    </div>
</div>
