import { Injectable } from '@angular/core'
import { GoogleAnalyticsEventsService } from '@app/core/_services/google-analytics-events.service'
import { AmplitudeAnalyticsEventsService } from '@app/core/_services/amplitude-analytics-events.service'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'
import {
    User,
    GroupInfo,
    SessionPreferenceFavorites,
    SessionActivityType,
    SESSION_ACTIVITY_TYPE_DESK,
    SESSION_ACTIVITY_TYPE_MOVING,
    SESSION_ACTIVITY_TYPE_ANYTHING,
} from '../_models'
import { UtilsService } from './utils.service'
import { environment } from '@env/environment'
import * as Sentry from '@sentry/angular-ivy'
import { ExperimentsService } from './experiments.service'
import { TimeUtilitiesService } from './time-utilities.service'
import { SESSION_STATE_DIRECT_BOOKER, SESSION_STATE_LOCKED_INVITEE } from './session-state.service'
import { WelcomeChecklistStepName } from '../_models/welcome-checklist'

const PathToName = {
    '/dashboard': 'Calendar',
    '/sessions': 'My Sessions',
    '/profile': 'My Profile',
    '/profile/edit-i': 'Edit Image',
    '/profile/edit-q': 'Edit Profile',
    '/profile/edit-p': 'Edit Settings',
    '/account/delete': 'Delete Account',
    '/signup': 'Sign Up',
    '/signup/method': 'Sign Up Method',
    '/signup/verify': 'Sign Up Verify',
    '/signup/profile': 'Sign Up Profile',
    '/group-management': 'Group Management',
    '/upgrade': 'Upgrade',
    '/upgrade/checkout/success': 'Checkout Success',
    '/oauth/authorize': 'Login with Focusmate',
    '/people': 'People List',
}

export enum SessionTileSource {
    CalendarTile = 0,
    UpcomingSessions,
    SessionInfoModal,
    MySessionPage,
    PartnerIsLateModal,
    FindingParnerModal,
    AvailabilityConflictModal,
    SeeAvailabilityList,
    ConsolidatedFavoritesList,
    ForceRematchModal,
    MissingPartnerModal,
}

export const VideoLinkToString = [
    { GA: 'sessionTile', Amplitude: 'calendarTile' },
    { GA: 'sessionTile', Amplitude: 'upcomingSessions' },
    { GA: 'sessionInfo', Amplitude: 'sessionInfoModal' },
    { GA: 'sessionTile', Amplitude: 'mySessionsPage' },
]

export enum BookingSource {
    CalendarTile = 0,
    CalendarFooter,
    SessionInfoModal,
    CreateSessionModal,
    CreateSessionModalAtOnboarding,
    CreateSessionModalFromWelcomeChecklist,
    CreateSessionModalFromYearInReview,
    UserAvailabilityCalendar,
    TimeSlotPartnerModal,
    IndividualAvailabilityList,
    ConsolidatedFavoritesList,
}

export const BookingSourceToString = [
    'calendarTile',
    'calendarFooter',
    'sessionInfoModal',
    'createSessionModal',
    'createSessionModalAtOnboarding',
    'createSessionModalFromWelcomeChecklist',
    'createSessionModalFromYearInReview',
    'userCalendar',
    'timeSlotPartnerModal',
    'individualAvailabilityList',
    'consolidatedFavoritesList',
]

export const SessionTileSourceToString = [
    { GA: 'cancelFromTile', Amplitude: 'calendarTile' },
    { GA: 'cancelFromTile', Amplitude: 'upcomingSessions' },
    { GA: 'cancelFromInfo', Amplitude: 'sessionInfoModal' },
    { GA: 'cancelFromTile', Amplitude: 'mySessionsPage' },
    { GA: 'lateToSessionModal', Amplitude: 'lateToSessionModal' },
    { GA: 'newPartnerSearchingModal', Amplitude: 'newPartnerSearchingModal' },
    { GA: 'seeAvailabilityConflict', Amplitude: 'seeAvailabilityConflict' },
    { GA: 'seeAvailabilityList', Amplitude: 'seeAvailabilityList' },
    { GA: 'consolidatedFavoritesList', Amplitude: 'consolidatedFavoritesList' },
    { GA: 'forceRematchModal', Amplitude: 'forceRematchModal' },
    { GA: 'missingPartnerModal', Amplitude: 'missingPartnerModal' },
]

export enum BookingRecurType {
    OneTime = 0,
    Daily,
    Weekly,
}

export const BookingRecurTypeToString = ['none', 'daily', 'weekly']
export type BookedSessionPartner = 'pending' | 'confirmed' | 'directBooker'

// Must map directly with complaintType in
// ReportProfileFormComponent
const ReportProfileReasonToString = ['Invalid', 'fake', 'offensive', 'misconduct']

const ReportSessionReasonToString = [
    'Invalid',
    'No Show',
    'Late',
    'Misconduct/Other',
    'broke-session-protocol',
    'flirting-or-inappropriate-comments',
    'harassment-shaming-or-threats',
    'illegal-activities',
    'inappropriate-attire',
    'nudity-or-sexual-acts',
    'other',
]

export const RECEIVED_ERROR_ACTION_SOCIAL_LOGIN_POPUP = 'Social login - Popup'
export const RECEIVED_ERROR_ACTION_SOCIAL_LOGIN_REDIRECT = 'Social login - Redirect'
export const RECEIVED_ERROR_ACTION_EMAIL_VERIFICATION = 'Email Verification'
export const RECEIVED_ERROR_ACTION_SIGN_UP = 'Sign Up'
export const RECEIVED_ERROR_ACTION_IMAGE_UPLOAD = 'Image Upload'
export const RECEIEVED_ERROR_MESSAGE_FILE_TOO_LARGE = 'File size too large'
export const RECEIEVED_ERROR_MESSAGE_FILE_EXT_NOT_SUPPORTED = 'Extension not supported'
export const RECEIVED_ERROR_ACTION_TOS_AGREEMENT = 'ToS Agreement'
export const RECEIVED_ERROR_ACTION_TZ_GUESS = 'Guess timezone'

const ActivityTypeToAmplitudeString = {
    [SESSION_ACTIVITY_TYPE_DESK]: 'Desk',
    [SESSION_ACTIVITY_TYPE_ANYTHING]: 'Anything',
    [SESSION_ACTIVITY_TYPE_MOVING]: 'Moving',
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private path: string = ''
    private page: string = ''
    private userId: string = ''

    constructor(
        private amplitudeAnalyticsEventsService: AmplitudeAnalyticsEventsService,
        private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
        private utilsService: UtilsService,
        private timeUtilitiesService: TimeUtilitiesService,
        private expirementService: ExperimentsService,
    ) {}

    private mapPathToPagename(path: string): any {
        let path_no_query = path.split('?', 1)[0]

        if (PathToName.hasOwnProperty(path_no_query)) {
            return {
                page: PathToName[path_no_query],
                sendPageView: true,
            }
        } else {
            if (path.startsWith('/signup/')) {
                return { page: 'Landing: Group', sendPageView: true }
            }
            if (path.startsWith('/launch') || path.startsWith('/session')) {
                let roomId = this.utilsService.getRoomIdFromUrl(path)

                if (roomId.indexOf('ts-') !== -1) {
                    return { page: 'Test Session', sendPageView: true }
                } else if (this.userId === roomId) {
                    // URL will update once the test room is created
                    return { page: 'Test Session', sendPageView: false }
                } else {
                    return { page: 'Active Session', sendPageView: true }
                }
            }

            return { page: 'Unknown', sendPageView: true }
        }
    }

    trackPageView(path, routeData) {
        this.path = path
        let viewInfo = this.mapPathToPagename(path)
        let properties = {}

        if (routeData && routeData.pageName) {
            this.page = routeData.pageName
        } else {
            this.page = viewInfo.page
        }

        if (viewInfo.sendPageView) {
            try {
                this.amplitudeAnalyticsEventsService.emitEvent(
                    'Viewed Page',
                    properties,
                    this.page,
                    this.path,
                )
            } catch (e) {
                if (!(e instanceof ReferenceError)) {
                    throw e
                }
                Sentry.captureException(e)
            }

            try {
                this.googleAnalyticsEventsService.trackPageView(this.page, this.path)
            } catch (e) {
                if (!(e instanceof ReferenceError)) {
                    throw e
                }
                Sentry.captureException(e)
            }
        }
    }

    setUserId(userId: string) {
        this.userId = userId
        this.googleAnalyticsEventsService.setUserId(userId)
        this.amplitudeAnalyticsEventsService.setUserId(userId)
        Sentry.configureScope((scope) => {
            scope.setUser({ id: userId })
            scope.setTag('applicationVersion', environment.applicationVersion)
        })
    }

    logDailyCoErrorEvent(errorMsg: string, roomId: string) {
        let properties: any = {
            type: errorMsg,
            roomId,
        }

        let sentryProperties: any = {
            errorMsg,
            roomId,
        }
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Received Session Error',
            properties,
            this.page,
            this.path,
        )

        try {
            Sentry.withScope((scope) => {
                scope.setExtras({ property: sentryProperties })
                Sentry.captureMessage('Received Session Error')
            })
        } catch (e) {
            // Do nothing
        }
    }

    logReceivedError(action: string, message: string, data: any = null) {
        const properties = {
            action,
            message,
            data,
        }
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Received Error',
            properties,
            this.page,
            this.path,
        )
    }

    logProfileEditedEvent(fieldsArray: string[]) {
        let properties = {
            fields: fieldsArray,
        }
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Edited Profile',
            properties,
            this.page,
            this.path,
        )
    }

    logUpdatedSettingEvent(
        field: string,
        value: string | boolean | number,
        previousValue: string | boolean | number,
        source: string,
    ): void {
        const properties = {
            field,
            value,
            previousValue,
            source,
        }
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Updated Setting',
            properties,
            this.page,
            this.path,
        )
    }

    logUpdatedUserPropertyEvent(
        field: string,
        value: string | boolean | number,
        source: string,
    ): void {
        const properties = {
            field,
            value,
            source,
        }
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Updated User Property',
            properties,
            this.page,
            this.path,
        )
    }

    logDailySessionInfoEvent(properties: any) {
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Received Session Info',
            properties,
            this.page,
            this.path,
        )
    }

    logNewUserSettings() {
        this.googleAnalyticsEventsService.emitEvent('UserSettings', 'updatedSettings', 'newUser', 1)
    }

    logNewUserPhotoUploaded(photo: any) {
        this.googleAnalyticsEventsService.emitEvent(
            'UserSettings',
            photo == null ? 'noPhotoUploaded' : 'photoUploaded',
            'newUser',
            1,
        )
    }

    logUserSettingsPhotoUpload() {
        this.googleAnalyticsEventsService.emitEvent(
            'UserSettings',
            'photoUploaded',
            'existingUser',
            1,
        )
    }

    logUserSettingsPhotoUploadFailure(error: string) {
        this.googleAnalyticsEventsService.emitEvent(
            'UserSettings',
            'photoUploadFailure',
            'existingUser - ' + error,
            1,
        )
    }

    logUserSettingsPersonal() {
        this.googleAnalyticsEventsService.emitEvent(
            'UserSettings',
            'updatedSettings',
            'existingUser',
            1,
        )
    }

    logUserSettingsPersonalError(error: string) {
        this.googleAnalyticsEventsService.emitEvent(
            'UserSettings',
            'updatedSettings',
            'existingUser - ' + error,
            1,
        )
    }

    logEmailVerified() {
        this.googleAnalyticsEventsService.emitEvent(
            'SignUp and Login',
            'emailVerified',
            'success',
            1,
        )
    }

    logEmailVerifyFailure(code: string) {
        this.googleAnalyticsEventsService.emitEvent(
            'SignUp and Login',
            'emailVerifyFailure',
            code,
            1,
        )
    }

    logEmailVerifySent(success: boolean, newUser: boolean = true) {
        this.googleAnalyticsEventsService.emitEvent(
            'SignUp and Login',
            success ? 'verifyEmailSent' : 'verifyEmailSentFailed',
            newUser ? 'newUser' : 'existingUser',
            1,
        )
    }

    logAccountDelete(success: boolean) {
        this.googleAnalyticsEventsService.emitEvent(
            'Account',
            'deleteAccount',
            success ? 'success' : 'failure',
            1,
        )
    }

    logAccountCancelDelete() {
        this.googleAnalyticsEventsService.emitEvent('Account', 'cancelDelete', '', 1)
    }

    logAmplitudeReportUser(source: string, type: string, reason: number = 0) {
        let properties = {
            source,
            type,
        }

        if (type === 'profile') {
            properties['reason'] = ReportProfileReasonToString[reason]
        } else {
            properties['reason'] = ReportSessionReasonToString[reason]
        }
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Reported User',
            properties,
            this.page,
            this.path,
        )
    }

    logAmplitudeBlockUser(source: string, saved: boolean) {
        let properties = {
            source,
            saved,
        }

        this.amplitudeAnalyticsEventsService.emitEvent(
            'Blocked User',
            properties,
            this.page,
            this.path,
        )
    }

    logUnsnoozedUser(source: string) {
        let properties = {
            source,
        }
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Unsnoozed User',
            properties,
            this.page,
            this.path,
        )
    }

    logSnoozedUser(selection: string, durationInMinutes, saved: boolean, source: string) {
        let properties = {
            selection,
            durationInMinutes,
            saved,
            source,
        }
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Snoozed User',
            properties,
            this.page,
            this.path,
        )
    }

    logOnboardingComplete() {
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Completed Onboarding',
            {},
            this.page,
            this.path,
        )
    }

    logClickedEvent(text: string, source: string, properties = {}) {
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Clicked',
            {
                text,
                source,
                ...properties,
            },
            this.page,
            this.path,
        )
    }

    logAutoRematchEvent() {
        this.amplitudeAnalyticsEventsService.emitEvent('Auto Rematch', {}, this.page, this.path)
    }

    logAutoRematchCanceledEvent() {
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Auto Rematch Canceled',
            {},
            this.page,
            this.path,
        )
    }

    logViewedModalEvent(modal: string, properties = {}) {
        properties['modal'] = modal
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Viewed Modal',
            properties,
            this.page,
            this.path,
        )
    }

    logViewedBannerEvent(banner: string, properties = {}) {
        properties['banner'] = banner
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Viewed Banner',
            properties,
            this.page,
            this.path,
        )
    }

    logViewedModalWithCountEvent(modal: string, count: number) {
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Viewed Modal',
            {
                modal,
                count,
            },
            this.page,
            this.path,
        )
    }

    logSessionTitleEdit() {
        this.googleAnalyticsEventsService.emitEvent('Session', 'titleEdit', '', 1)
    }

    logTrackSessionJoin(event: string, source: string) {
        this.googleAnalyticsEventsService.emitEvent('Session', event, source)
    }

    logDailyMeetingLoadingEvent(properties: any) {
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Loading Daily Meeting',
            properties,
            this.page,
            this.path,
        )
    }

    logDailyMeetingLoadedEvent(properties: any) {
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Loaded Daily Meeting',
            properties,
            this.page,
            this.path,
        )
    }

    logAmplitudeFinishedSession(
        sessionTime: number,
        sessionId: string,
        timeZone: string,
        user: User,
    ) {
        let properties = {
            startTimeOfDay: moment(sessionTime).tz(timeZone).format('HH:mm'),
            startDayOfWeek: this.timeUtilitiesService.getStartDayOfWeek(sessionTime, timeZone),
        }

        this.amplitudeAnalyticsEventsService.emitEvent(
            'Finished Session',
            properties,
            this.page,
            this.path,
        )
    }

    logGAUserSignIn(newUser: boolean, email: string) {
        this.googleAnalyticsEventsService.emitEvent(
            'SignUp and Login',
            newUser ? 'newUserCreated' : 'existingUserSignIn',
            '',
            1,
        )
    }

    setUserProperty(user: User) {
        let properties = {}
        let groups = []

        user.group.forEach((group: GroupInfo) => {
            if (group.groupMember === true) {
                groups.push(group.groupId)
            }
        })

        properties['groups'] = groups

        this.amplitudeAnalyticsEventsService.setUserProperties(properties)
    }

    logSignInMethod(from: string) {
        this.googleAnalyticsEventsService.emitEvent('SignInModal', from, '', 1)
    }

    logSignUpMethod(from: string) {
        this.googleAnalyticsEventsService.emitEvent('SignUpModal', from, '', 1)
    }

    logAddToCalendar(type: string) {
        this.googleAnalyticsEventsService.emitEvent('addToCalendar', type, 'fromInfo', 1)
    }

    logInviteFriendSelected() {
        this.googleAnalyticsEventsService.emitEvent(
            'inviteFriendSelected',
            'inviteFriendSelected',
            '',
            1,
        )
    }

    logCrowdfundingSelected(from: string, version: string) {
        this.googleAnalyticsEventsService.emitEvent('crowdFunding', from, version)
    }

    logPrepModalEvents(cat, event, label) {
        this.googleAnalyticsEventsService.emitEvent(cat, event, label, 0)
    }

    logMessageLinkSelected() {
        this.googleAnalyticsEventsService.emitEvent('Session', 'messagingLink', 'messagingLink')
    }

    logIntroVideoView(action: string = 'siteIntroVideo', label: string = 'introVideo') {
        this.googleAnalyticsEventsService.emitEvent('VideoModalCreated', action, label, 1)
    }

    logDailyCoLoadFailedEvent(evt: any, roomId: string) {
        let properties: any = {
            type: evt.errorMsg || '',
            roomId: roomId,
        }
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Received Load Attempt Failed',
            properties,
            this.page,
            this.path,
        )
    }

    logLaunchTestSession(from: string) {
        this.googleAnalyticsEventsService.emitEvent('Session', 'testSessionLaunched', from, 1)
    }

    logHowItWorksVideo(action: string, label: string) {
        this.googleAnalyticsEventsService.emitEvent('HowItWorksVideo', action, label, 1)
    }

    logSesssionFeedback(properties: object) {
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Submitted Feedback',
            properties,
            this.page,
            this.path,
        )
    }

    logDesktopNotificationEvent(
        permissionsState: string,
        notificationSuccess: boolean,
        soundSuccess: boolean,
    ) {
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Desktop Notification',
            {
                notificationSuccess,
                soundSuccess,
                permissionsState,
            },
            this.page,
            this.path,
        )
    }

    logPlayToneFailedNotificationEvent() {
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Play Tone Failed Notification',
            {},
            this.page,
            this.path,
        )
    }

    logRewardsReferralLead(code: string, previousCode: string = null) {
        this.amplitudeAnalyticsEventsService.emitEvent(
            'New Internal Rewards Referral Lead',
            {
                code,
                previousCode,
            },
            this.page,
            this.path,
        )
    }

    logBookedSessionEvent(
        source: BookingSource,
        recurringType: BookingRecurType,
        count: number,
        sessionTime: number,
        sessionDurationInMillisecons: number,
        timeZone: string,
        state: number,
        favoritesPreference: SessionPreferenceFavorites,
        activityType: SessionActivityType,
        quietMode: boolean,
    ) {
        const now = moment().utc().valueOf()
        const minutesUntilStartTime = Math.round((sessionTime - now) / 1000 / 60)
        const secondsUntilStartTime = Math.round((sessionTime - now) / 1000)

        let partner: BookedSessionPartner = 'pending'

        if (state === SESSION_STATE_LOCKED_INVITEE) {
            partner = 'confirmed'
        }
        if (state === SESSION_STATE_DIRECT_BOOKER) {
            partner = 'directBooker'
        }

        this.amplitudeAnalyticsEventsService.emitEvent(
            'Booked Session',
            {
                partner,
                source: BookingSourceToString[source],
                recurringType: BookingRecurTypeToString[recurringType],
                count: count,
                minutesUntilStartTime: minutesUntilStartTime, // Keep for now to maintain older charts
                secondsUntilStartTime: secondsUntilStartTime,
                startTimeOfDay: moment(sessionTime).tz(timeZone).format('HH:mm'),
                startDayOfWeek: this.timeUtilitiesService.getStartDayOfWeek(sessionTime, timeZone),
                sessionLengthInMinutes: Math.round(sessionDurationInMillisecons / 60 / 1000),
                favoritesPreference,
                activityType: ActivityTypeToAmplitudeString[activityType] || activityType,
                quietMode: quietMode,
            },
            this.page,
            this.path,
        )
    }

    logWelcomeChecklistStepCompleted(stepName: WelcomeChecklistStepName) {
        this.amplitudeAnalyticsEventsService.emitEvent(
            'Welcome Checklist Step Completed',
            {
                step: stepName,
            },
            this.page,
            this.path,
        )
    }
}
