import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { faTimes, faClockEight, faUserGroup } from '@fortawesome/pro-regular-svg-icons'
import { faStar } from '@fortawesome/pro-solid-svg-icons'

@Component({
    selector: 'app-people-list-info',
    templateUrl: './people-list-info.component.html',
    styleUrls: ['./people-list-info.component.scss'],
})
export class PeopleListInfoComponent implements OnInit {
    public faTimes = faTimes
    public faClockEight = faClockEight
    public faUserGroup = faUserGroup
    public faStar = faStar
    public currentUrl: string

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<PeopleListInfoComponent>,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('People list: Info')
    }

    learnMoreClicked() {
        this.analyticsService.logClickedEvent('Learn more', 'People list: Info')
    }
}
