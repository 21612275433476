import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'app-confirm-system-status',
    templateUrl: './confirm-system-status.component.html',
    styleUrls: ['./confirm-system-status.component.scss'],
})
export class ConfirmSystemStatusComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<ConfirmSystemStatusComponent>,
    ) {}

    ngOnInit(): void {}

    public closeDialog(confirmed: boolean) {
        if (confirmed) {
            this.dialog.close(true)
        } else {
            this.dialog.close(false)
        }
    }
}
