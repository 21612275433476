<div class="std-modal-container">
    <ng-container [ngSwitch]="modalContent">
        <ng-container *ngSwitchCase="'block'">
            <fa-icon
                class="std-modal-close"
                [icon]="faTimes"
                [classes]="['fa-std-close-icon']"
                (click)="closeDialog()">
            </fa-icon>
            <div class="std-modal-header fm-mt-18px" style="padding-bottom: 3px">
                <div class="std-modal-title wrap-double-line-el pb-[30px]"
                    >Block {{ data.partnerName }}?</div
                >
            </div>

            <div class="std-modal-body w-full text-left">
                <div class="font-avenir font-medium pb-[30px]">
                    <p class="wrap-double-line-el text-14-19 pb-[10px] text-bluegrey"
                        >Are you sure you want to block {{ data.partnerName }}?</p
                    >
                    <p class="text-14-19 text-bluegrey"
                        >Unless they made you feel uncomfortable or unsafe, we recommend using
                        snooze for more control and flexibility over who you match with.
                    </p>
                </div>
                <div class="info-link self-start" (click)="showSnoozeInfo()"
                    >What happens when I snooze someone?</div
                >
                <div class="info-link mt-[8px] self-start" (click)="showBlockInfo()"
                    >What happens when I block someone?</div
                >
            </div>
            <div class="w-full std-modal-action-container-row">
                <div class="std-action">
                    <button
                        class="f-btn f-btn--secondary xf-btn-250 w-full"
                        (click)="launchSnoozeModal()"
                        >Snooze instead</button
                    >
                </div>
                <div class="std-action">
                    <app-spinner-button
                        class="w-full"
                        [label]="'Block'"
                        classes="f-btn f-btn--primary xf-btn-250 w-full"
                        (clicked)="blockUser()">
                    </app-spinner-button>
                </div>
            </div>

            <div class="f-link fm-mt-19px text-center" (click)="closeDialog()">Cancel</div>
        </ng-container>

        <ng-container *ngSwitchCase="'snoozeInfo'">
            <fa-icon
                class="std-modal-close"
                [icon]="faTimes"
                [classes]="['fa-std-close-icon']"
                (click)="closeDialog()">
            </fa-icon>
            <div class="std-modal-header"> </div>

            <div class="std-modal-body w-full">
                <div class="info-subtitle">What happens when I snooze someone?</div>

                <div class="info-content-container text-left f-bluegray-14-n-text fm-mt-34px">
                    <ul>
                        <li>You won’t be matched with them during the snooze period</li>
                        <li
                            >Any upcoming sessions with them during the snooze period will be
                            rematched</li
                        >
                        <li
                            >If they have access to your Availability page, they won't see sessions
                            that are during the snooze period</li
                        >
                        <li>They won’t be notified</li>
                        <li
                            >You can unsnooze them or edit the snooze in
                            <a
                                class="f-link"
                                [href]="settingsUrl"
                                target="_blank"
                                (click)="settingsClicked()"
                                >Settings</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div class="std-modal-action-container" style="padding-top: 30px">
                <div class="f-btn f-btn--primary f-btn-250" (click)="showBlockSelection()"
                    >Back</div
                >
                <a
                    class="f-link fm-mt-19px"
                    style="text-decoration: none"
                    target="_blank"
                    href="https://support.focusmate.com/en/articles/5950429-how-to-snooze"
                    (click)="learnMoreClicked()"
                    >Learn more</a
                >
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'blockInfo'">
            <fa-icon
                class="std-modal-close"
                [icon]="faTimes"
                [classes]="['fa-std-close-icon']"
                (click)="closeDialog()">
            </fa-icon>
            <div class="std-modal-header"> </div>

            <div class="std-modal-body w-full">
                <div class="info-subtitle">What happens when I block someone?</div>

                <div class="info-content-container text-left f-bluegray-14-n-text fm-mt-34px">
                    <ul>
                        <li>You won’t be matched with them</li>
                        <li>They won’t see your name or profile anywhere on Focusmate</li>
                        <li>They won’t be able to message you</li>
                        <li>They won’t be notified</li>
                        <li
                            >We’ll get you a new partner for any upcoming sessions with them. But if
                            you’re currently in a session together, it will not end
                            automatically.</li
                        >
                    </ul>
                </div>
            </div>
            <div class="std-modal-action-container" style="padding-top: 30px">
                <div class="f-btn f-btn--primary f-btn-250" (click)="showBlockSelection()"
                    >Back</div
                >
                <a
                    class="f-link fm-mt-19px"
                    style="text-decoration: none"
                    target="_blank"
                    href="https://support.focusmate.com/en/articles/4044457-how-to-block-a-user-on-focusmate"
                    (click)="learnMoreClicked()"
                    >Learn more</a
                >
            </div>
        </ng-container>
    </ng-container>
</div>
