import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

export const LOGIN_ERROR_FACEBOOK_EMAIL_PERMISSIONS_NEEDED =
    'To login with Facebook, please grant us permission to access your email'
export const LOGIN_ERROR_DEA_REJECTED =
    'For safety reasons, disposable email addresses are not allowed'

@Injectable({
    providedIn: 'root',
})
export class StoreRedirectOnLoginService {
    private redirectToPath: string = ''

    signInUpError: string = ''
    private signInUpErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
        this.signInUpError,
    )

    constructor() {}

    setUrlToRedirectToOnLogin(path: string) {
        this.redirectToPath = path
    }

    getUrlToRedirectToOnLogin() {
        let temp = this.redirectToPath
        this.redirectToPath = ''
        return temp
    }

    resetUrlToRedirectToOnLogin(url: string) {
        if (url !== '/login') {
            this.redirectToPath = ''
        }
    }

    storeSignInUpError(err: string) {
        this.signInUpError = err
        this.signInUpErrorSubject.next(this.signInUpError)
    }

    clearSignInUpError() {
        this.signInUpError = ''
    }

    getSignInUpError(): Observable<string> {
        return this.signInUpErrorSubject.asObservable()
    }
}
