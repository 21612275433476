import { Component, OnInit, Input, Self, ElementRef, Signal } from '@angular/core'
import { Subject } from 'rxjs'
import {
    UtilsService,
    SelectedSessionService,
    CancelSessionService,
    BookSessionInfo,
} from '@app/core/_services'
import { DialogService } from '@app/core/_services/dialog.service'
import { BookSessionsService } from '@app/core/_services'
import {
    GroupInfo,
    User,
    SESSION_DURATION_25_MINS,
    SESSION_DURATION_50_MINS,
    SESSION_DURATION_75_MINS,
    SessionPreferenceFavorites,
    BookSessionPreferences,
    SESSION_ACTIVITY_TYPE_ANYTHING,
    SessionActivityType,
} from '@app/core/_models'
import { BookLimitCheckService } from '@app/core/_services/book-limit-check.service'
import { ErrorHandlerService } from '@app/core/_services/error-handler.service'
import {
    BookingSource,
    SessionTileSource,
    AnalyticsService,
    SessionTileSourceToString,
} from '@app/core/_services/analytics.service'
import { SessionInfoCardComponent } from '@app/shared-dialogs/components/session-info-card/session-info-card.component'
import { UserService } from '@app/core/_services/user.service'
import { take, takeUntil } from 'rxjs/operators'
import {
    faUserFriends,
    faPen,
    faTimes,
    faClock,
    faLock,
    faEllipsis,
    faCheck,
    faXmark,
    faCircle,
    faStar,
    faExclamation,
} from '@fortawesome/pro-solid-svg-icons'
import {
    faAlarmExclamation,
    faArrowsRepeat,
    faClock as faRegularClock,
} from '@fortawesome/pro-regular-svg-icons'
import { MODAL_VERIFY_EMAIL_BOOKING } from '../email-verify/email-verify.component'
import { faAddressCard, faCircleCheck } from '@fortawesome/pro-duotone-svg-icons'
import {
    SessionStateService,
    SESSION_STATE_LATE,
    SESSION_STATE_SEARCHING,
} from '@app/core/_services/session-state.service'
import { SessionSettingsService } from '@app/core/_services/session-settings.service'
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip'
import { ConfettiService } from '@app/core/_services/confetti.service'
import { WelcomeChecklistService } from '@app/core/_services/welcome-checklist.service'
import {
    SessionTimeDisplayInfo,
    UserDateFormattingService,
} from '@app/core/_services/user-date-formatting.service'
import { RewardsReferralService } from '@app/core/_services/rewards-referral.service'

const SESSION_DURATION_50_MINS_LABEL: string = '50m'
const SESSION_DURATION_25_MINS_LABEL: string = '25m'
const SESSION_DURATION_75_MINS_LABEL: string = '75m'

enum TileState {
    Empty = 1,
    Selected = 2,
    Pending = 3,
    Confirmed = 4,
    CancelRequest = 5,
    DST = 6,
    MAINT = 7,
    Searching = 8,
    Late = 9,
    MissingPartner = 10,
}

export const CustomTooltipOptions: MatTooltipDefaultOptions = {
    showDelay: 500,
    hideDelay: 0,
    touchendHideDelay: 0,
    disableTooltipInteractivity: true,
    position: 'above',
}

@Component({
    selector: 'app-session-tile',
    templateUrl: './session-tile.component.html',
    styleUrls: ['./session-tile.component.scss'],
    providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltipOptions }],
})
export class SessionTileComponent implements OnInit {
    @Input() sessionTime: number
    @Input() timeZone: string
    @Input() waitingUser: string
    @Input() confirmed: boolean
    @Input() selected: boolean
    @Input() focusMate: string
    @Input() hidden: boolean
    @Input() sessionUrl: string
    @Input() userEmail: string
    @Input() userName: string
    @Input() photoUrl: string
    @Input() currentTime: number
    @Input() userProfileUrl: string
    @Input() waitingProfileUrl: string
    @Input() dst: boolean
    @Input() dstChosen: boolean
    @Input() sessionTitle: string
    @Input() alwaysShowVideoIcon: boolean
    @Input() group: GroupInfo[]
    @Input() source: SessionTileSource
    @Input() isTimeSlotAvailable: boolean
    @Input() status: number
    @Input() state: number
    @Input() sessionDuration: number
    @Input() saved: boolean
    @Input() directBookAvailable: boolean = false
    @Input() activityType: SessionActivityType = SESSION_ACTIVITY_TYPE_ANYTHING
    @Input() quietMode: boolean = true
    @Input() confirmedPartnerQuietMode: boolean = false
    @Input() unmatchedPartnerQuietMode: boolean = false

    // Icons
    faUserFriends = faUserFriends
    faPen = faPen
    faTimes = faTimes
    faClock = faClock
    faLock = faLock
    faStar = faStar
    isLockedInSession: boolean = false
    faRegularClock = faRegularClock
    faAddressCard = faAddressCard
    faEllipsis = faEllipsis
    faCheck = faCheck
    faXmark = faXmark
    faCircle = faCircle
    faCircleCheck = faCircleCheck
    faAlarmExclamation = faAlarmExclamation
    faArrowsRepeat = faArrowsRepeat
    faExclamation = faExclamation

    // observables
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    private user: User = new User()

    dstString: string = ''
    removeString: string = ''
    cancelHover: boolean = false
    focusmateHover: boolean = false
    tileState = TileState.Empty
    emptyHover: boolean = false
    profileHover: boolean = false

    /* Time Based Config */
    sessionOngoing = false
    sessionEnded = false
    sessionStarted = false

    /* Report Icon */
    sessionId: string = ''
    timeString: string = ''
    hideJoinButtonTextLabel: boolean = false
    hideProfileImage: boolean = false
    hideMatching: boolean = false
    hideCancel: boolean = false
    isCalendarTile: boolean = false
    useButtonIcons: boolean = false

    /* Message Icon */
    messageLinkUrl: string = ''

    /* Icons on tile left side */
    videoIconEnabled: boolean = false
    // Always show message icon for now

    private isAwaitingCancelConfirm: boolean = false
    private cancelInProgress: boolean = false

    userRouterLink: string = ''

    public tileWidth: number = 198
    public halfHeight: boolean
    public sessionDurationLabel: string
    private sessionPreferenceFavorites: SessionPreferenceFavorites
    private sessionActivityType: SessionActivityType
    private sessionQuietMode: boolean
    public sessionTimeDisplayInfo: SessionTimeDisplayInfo = {
        startTime: '',
        endTime: '',
        timeRange: '',
    }

    private isWelcomeChecklistActive: Signal<boolean> =
        this.welcomeChecklistService.isWelcomeChecklistActive

    constructor(
        private ss: SelectedSessionService,
        private cs: CancelSessionService,
        private bs: BookSessionsService,
        private util: UtilsService,
        private dialogService: DialogService,
        private bookLimitCheckService: BookLimitCheckService,
        private errorHandlerService: ErrorHandlerService,
        public userService: UserService,
        private analyticsService: AnalyticsService,
        private sessionStateService: SessionStateService,
        private sessionSettingsService: SessionSettingsService,
        private dateFormat: UserDateFormattingService,
        private utilsService: UtilsService,
        private confettiService: ConfettiService,
        @Self() private element: ElementRef,
        private welcomeChecklistService: WelcomeChecklistService,
        private rewardsReferralService: RewardsReferralService,
    ) {}

    editSession() {
        this.dialogService.openSessionInfoCard(
            this.sessionTime,
            this.sessionDuration,
            this.sessionId,
            this.state,
            SessionInfoCardComponent,
        )
    }

    onDomChange(e) {
        this.tileWidth = e

        if (!this.isCalendarTile) {
            this.timeString = this.sessionTimeDisplayInfo.startTime
            this.hideMatching = false
            this.hideCancel = false
        } else {
            // For empty/select states, update button label (icon or text)
            // based on width of the tile.
            this.useButtonIcons = this.tileWidth < 110

            // If width is greater than 160px, show end of session time
            // also. Otherwise just show start time
            if (this.tileWidth >= 160) {
                this.timeString = this.sessionTimeDisplayInfo.timeRange
            } else {
                this.timeString = this.sessionTimeDisplayInfo.startTime
            }

            // Below 120px width we will remove the image/icon from top right corner
            // and remove the text label from the join button
            this.hideProfileImage = this.tileWidth < 124
            this.hideJoinButtonTextLabel = this.tileWidth < 124

            if (this.tileWidth < 80) {
                this.hideMatching = true
            } else {
                this.hideMatching = false
            }
            this.hideCancel = this.tileWidth < 95
        }
    }

    ngOnInit() {
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user: User) => {
            this.user = user
            this.updateTile()

            if (this.tileState === TileState.Selected) {
                // Use Case: If a user selects a session and navigates away from the dashboard
                // and back, we need to maintain the activityType and favoritesPreference that was
                // set at the time the session was selected
                const selectedInfo = this.ss.getSelectedSessionInfoByTime(this.sessionTime)
                this.sessionActivityType =
                    selectedInfo?.activityType ??
                    this.sessionSettingsService.getSessionSettingActivityType()
                this.sessionPreferenceFavorites =
                    selectedInfo?.sessionPreferenceFavorites ??
                    this.sessionSettingsService.getSessionSettingFavoritesPreference()
                this.sessionQuietMode =
                    selectedInfo?.quietMode ?? this.sessionSettingsService.quietModeEnabled()
            }
        })
    }

    setIsCalendarTile() {
        if (this.source === SessionTileSource.CalendarTile) {
            this.isCalendarTile = true
            this.halfHeight = this.sessionDuration === SESSION_DURATION_25_MINS
        } else {
            this.isCalendarTile = false
            this.halfHeight = false
        }
    }

    private getSessionDurationLabel(sessionDuration: number) {
        if (sessionDuration === SESSION_DURATION_50_MINS) {
            return SESSION_DURATION_50_MINS_LABEL
        } else if (sessionDuration === SESSION_DURATION_75_MINS) {
            return SESSION_DURATION_75_MINS_LABEL
        } else {
            return SESSION_DURATION_25_MINS_LABEL
        }
    }

    updateTile() {
        this.setIsCalendarTile()
        this.sessionDurationLabel = this.getSessionDurationLabel(this.sessionDuration)

        if (this.timeZone === '' || this.timeZone === null) {
            return
        }

        if (this.status === 4) {
            // Deleted account
            this.userRouterLink = ''
        } else if (this.state === SESSION_STATE_SEARCHING || this.state === SESSION_STATE_LATE) {
            // Seaching state
            this.userRouterLink = ''
        } else if (this.userProfileUrl !== '') {
            // Tile has a match, set url accordingly
            this.userRouterLink = '/user/' + this.userProfileUrl
        } else {
            // Tile has no match but does have a waiting user
            if (this.waitingProfileUrl !== '') {
                this.userRouterLink = '/user/' + this.waitingProfileUrl
            } else {
                this.userRouterLink = ''
            }
        }

        this.sessionOngoing = false
        this.sessionEnded = false
        this.sessionStarted = false
        this.cancelInProgress = false

        if (this.util.ongoingSession(this.sessionTime, this.sessionDuration, this.currentTime)) {
            this.sessionOngoing = true
        }
        if (this.util.hasSessionEnded(this.sessionTime, this.sessionDuration, this.currentTime)) {
            this.sessionEnded = true
        }
        if (this.util.hasSessionTimePassed(this.sessionTime, this.currentTime)) {
            this.sessionStarted = true
        }

        this.sessionTimeDisplayInfo = this.dateFormat.getSessionTimeDisplayStrings(
            this.sessionTime,
            this.sessionDuration,
            this.timeZone,
        )

        if (
            typeof this.sessionUrl == 'undefined' ||
            this.sessionUrl == null ||
            this.sessionUrl === ''
        ) {
        } else {
            this.sessionId = this.utilsService.getRoomIdFromUrl(this.sessionUrl)
        }

        this.messageLinkUrl = this.util.buildMessageLinkUrl(this.sessionId, this.userEmail)

        if (this.dst) {
            this.setDSTString()
        }
        this.determineState()
        this.determineNumIcons()
        this.updateLayoutBasedOnWidth()
        this.isLockedInSession = this.sessionStateService.isLockedInState(this.state)

        // For matched sessions we want the video icon
        // to be shown 10 mins prior to session start
        // Since we update confirmed sessions every 2 mins, check to
        // see if we need to start a timer to show video icon
        if (
            this.tileState === TileState.Confirmed &&
            this.sessionTime - this.util.TEN_MINS > this.currentTime &&
            this.sessionTime - this.util.TEN_MINS - this.currentTime < 120000
        ) {
            setTimeout(
                () => {
                    this.videoIconEnabled = true
                },
                this.sessionTime - this.util.TEN_MINS - this.currentTime,
            )
        }
    }

    setDSTString() {
        this.dstString = 'DST'
    }

    private updateLayoutBasedOnWidth() {
        if (this.element) {
            this.onDomChange(this.element.nativeElement.clientWidth)
        }
    }

    ngOnChanges(e) {
        if (e.sessionTime && !e.sessionTime.firstChange) {
            // Reset tileState as this is a new timeslot
            this.tileState = TileState.Empty
        }
        this.updateTile()
    }

    setTileState(tileState: TileState) {
        this.tileState = tileState
    }

    determineState() {
        if (!this.isTimeSlotAvailable && !this.confirmed) {
            this.setTileState(TileState.MAINT)
        } else if (this.dst && !this.dstChosen) {
            this.setTileState(TileState.DST)
        } else {
            if (this.confirmed) {
                if (this.confettiService.unnecessaryConfettiEnabled()) {
                    // if the previous state was selected and it's now confirmed,
                    // that means we've just booked this slot. confetti time
                    if (this.tileState === TileState.Selected) {
                        this.popConfettiAtCenter()
                    }
                }

                if (this.isAwaitingCancelConfirm) {
                    this.setTileState(TileState.CancelRequest)
                } else if (this.state === SESSION_STATE_SEARCHING) {
                    this.tileState = TileState.Searching
                } else if (this.state === SESSION_STATE_LATE) {
                    this.tileState = TileState.Late
                } else if (this.focusMate === '' && this.sessionStarted) {
                    this.setTileState(TileState.MissingPartner)
                } else if (this.focusMate != '') {
                    this.setTileState(TileState.Confirmed)
                } else {
                    this.setTileState(TileState.Pending)
                }
            } else if (this.selected) {
                this.setTileState(TileState.Selected)
            } else if (
                this.tileState === TileState.Confirmed ||
                this.tileState === TileState.Pending ||
                this.tileState === TileState.CancelRequest
            ) {
                // Session was cancelled
                this.setTileState(TileState.Empty)
            } else {
                this.setTileState(TileState.Empty)
            }
        }
    }

    determineNumIcons() {
        this.videoIconEnabled = this.util.showVideoIcon(this.sessionTime, this.sessionDuration)
    }

    addSession() {
        if (this.bookLimitCheckService.bookLimitReached()) {
            this.errorHandlerService.openSessionLimitDialog()
        } else {
            this.setTileState(TileState.Selected)
            this.sessionPreferenceFavorites =
                this.sessionSettingsService.getSessionSettingFavoritesPreference()
            this.sessionActivityType = this.sessionSettingsService.getSessionSettingActivityType()
            this.sessionQuietMode = this.sessionSettingsService.quietModeEnabled()
            this.ss.addSelectedSession({
                sessionTime: this.sessionTime,
                user: this.waitingUser,
                profileUrl: this.waitingProfileUrl,
                userAvailabilityCode: '',
                sessionDuration: this.sessionDuration,
                sessionPreferenceFavorites: this.sessionPreferenceFavorites,
                activityType: this.sessionActivityType,
                quietMode: this.sessionQuietMode,
            })
        }
    }

    removeSession() {
        this.setTileState(TileState.Empty)
        this.ss.deleteSelectedSession(this.sessionTime)
    }

    cancelSession() {
        if (!this.cancelInProgress) {
            this.cancelInProgress = true
            this.cs.cancelSession(this.sessionTime, this.source).subscribe(
                (res) => {
                    this.cancelInProgress = false
                    this.isAwaitingCancelConfirm = false
                },
                (error) => {
                    this.isAwaitingCancelConfirm = false
                    this.cancelInProgress = false
                    this.errorHandlerService.handleErrorResponse(error)
                    this.cancelCancel()
                },
            )
        }
        this.onMouseLeaveDisplayCancel()
    }

    bookSession() {
        const preferences: BookSessionPreferences = {
            favorites: { value: this.sessionPreferenceFavorites },
            quietMode: { value: this.sessionQuietMode },
        }

        let bookSessionInfo: BookSessionInfo = {
            sessionTime: this.sessionTime,
            userAvailabilityCode: '',
            duration: this.sessionDuration,
            userId: '',
            title: '',
            preferences,
            activityType: this.sessionActivityType,
        }

        this.bs
            .bookSession([bookSessionInfo], this.timeZone, BookingSource.CalendarTile)
            .pipe(take(1))
            .subscribe(
                (res) => {
                    let parsedRes = this.bs.parseBookResponse(res)

                    if (parsedRes['numSuccess'] !== 0) {
                        this.dialogService.openConfirmTransaction(
                            parsedRes['numSuccess'] === 1
                                ? 'Session booked!'
                                : parsedRes['numSuccess'] + ' sessions booked!',
                        )
                    }

                    if (this.user.emailVerified === false) {
                        this.dialogService
                            .openEmailVerificationDialog(MODAL_VERIFY_EMAIL_BOOKING)
                            .afterClosed()
                            .pipe(take(1))
                            .subscribe(() => {
                                this.processParsedResult(parsedRes)
                            })
                    } else {
                        this.processParsedResult(parsedRes)
                    }
                },
                (error) => {
                    this.errorHandlerService.handleErrorResponse(error)
                },
            )
    }

    private popConfettiAtCenter() {
        this.confettiService.lilPopAtSpot(this.element)
    }

    processParsedResult(parsedRes) {
        if (parsedRes['planLimitReached'] === true) {
            this.dialogService.openPlanLimitReachedDialog()
        } else if (
            !this.isWelcomeChecklistActive() &&
            !this.user.properties.viewedSegmentationSurveyModal &&
            this.user.compSessions >= 3
        ) {
            this.dialogService.openSegmentationSurveyModal()
        } else if (
            !this.isWelcomeChecklistActive() &&
            this.rewardsReferralService.shouldSeeReferralPrompt(this.user)
        ) {
            this.dialogService.openReferralPromptModal()
        }
    }

    cancelSessionRequest() {
        if (this.tileState === TileState.Pending) {
            this.cancelSession()
        } else {
            this.isAwaitingCancelConfirm = true
            this.setTileState(TileState.CancelRequest)
        }
    }

    cancelCancel() {
        this.isAwaitingCancelConfirm = false
        this.cancelHover = false
        this.determineState()
    }

    public trackProfileView(text: string) {
        this.analyticsService.logClickedEvent(
            text,
            SessionTileSourceToString[this.source]['Amplitude'],
        )
    }

    public openPartnerModalStarBar() {
        let source = SessionTileSourceToString[this.source]['Amplitude']
        source += ': Available'

        this.analyticsService.logClickedEvent('Star bar', source)
        this.dialogService.openTimeslotPartnerModal(
            this.sessionTime,
            this.sessionDuration,
            this.timeZone,
        )
    }

    public openPartnerModalSelected() {
        let source = SessionTileSourceToString[this.source]['Amplitude']
        source += ': Selected'

        this.analyticsService.logClickedEvent('More', source)
        this.dialogService.openTimeslotPartnerModal(
            this.sessionTime,
            this.sessionDuration,
            this.timeZone,
        )
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }

    onMouseEnterEmptyTile() {
        this.updateLayoutBasedOnWidth()
    }

    onMouseLeaveUserProfile() {
        this.profileHover = false
    }

    onMouseEnterUserProfile() {
        this.profileHover = true
    }

    onMouseEnterDisplayRemove() {
        this.removeString = 'Remove?'
    }

    onMouseLeaveDisplayRemove() {
        this.removeString = ''
    }

    onMouseLeaveDisplayCancel() {
        this.cancelHover = false
    }

    touchStart() {
        // On mobile devices we do not have the hover state.
        // On clicking a time slot we will change the state of the tile
        // to show the select/more buttons similar to hover state on mobile
        const canHover = window.matchMedia('(hover: hover) and (pointer: fine)').matches

        if (!canHover) {
            this.addSession()
        }
    }
}
