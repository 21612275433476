import { Injectable } from '@angular/core'
import { UtilsService } from './utils.service'

// NOTE: Below const definition must map directly to
// backend definition of session state
export const SESSION_STATE_SEARCHING = 1
export const SESSION_STATE_LATE = 2
export const SESSION_STATE_CANCELED = 3
export const SESSION_STATE_SEARCH_FAILED = 4
export const SESSION_STATE_LATE_NO_SHOW = 5
export const SESSION_STATE_SEARCH_CANCELED = 6
export const SESSION_STATE_LATE_CANCELED = 7
export const SESSION_STATE_PENDING = 8
export const SESSION_STATE_MATCHED = 9
export const SESSION_STATE_MATCHED_GROUP = 10
export const SESSION_STATE_MATCHED_REMATCHED = 11
export const SESSION_STATE_LOST_PARTNER = 12
export const SESSION_STATE_LOCKED_INVITEE = 13
export const SESSION_STATE_LOCKED_INVITER = 14
export const SESSION_STATE_UNVERIFIED = 15
export const SESSION_STATE_INITIAL = 16
export const SESSION_STATE_DIRECT_BOOKER = 17
export const SESSION_STATE_DIRECT_BOOKEE = 18

const LOCKED_SESSION_STATES = [
    SESSION_STATE_LOCKED_INVITEE,
    SESSION_STATE_LOCKED_INVITER,
    SESSION_STATE_DIRECT_BOOKER,
    SESSION_STATE_DIRECT_BOOKEE,
]

@Injectable({
    providedIn: 'root',
})
export class SessionStateService {
    constructor(private utilsService: UtilsService) {}

    public isLockedInState(state: number): boolean {
        return LOCKED_SESSION_STATES.includes(state)
    }

    public isPendingPartnerState(state: number): boolean {
        if (
            state === SESSION_STATE_PENDING ||
            state === SESSION_STATE_INITIAL ||
            state === SESSION_STATE_UNVERIFIED ||
            state === SESSION_STATE_LOST_PARTNER
        ) {
            return true
        } else {
            return false
        }
    }

    public requiresForceRematch(state: number, startTime: number): boolean {
        // a session in the pending or lost partner state that started between 0 and 5 minutes ago
        // require an immediate decision from the user on whether to rematch or cancel.
        const currentTime = this.utilsService.getCurrentTimestamp()
        const isWithinRematchWindow = this.utilsService.isWithinFiveMinutesAfterSessionStart(
            startTime,
            currentTime,
        )

        const isPendingOrLostPartner = [SESSION_STATE_PENDING, SESSION_STATE_LOST_PARTNER].includes(
            state,
        )

        if (isWithinRematchWindow && isPendingOrLostPartner) {
            return true
        }

        return false
    }
}
