import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { User, UserSettingsShareAvailability } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { UserRelationshipService } from '@app/core/_services/user-relationship.service'
import { UserService } from '@app/core/_services/user.service'
import { faStar as faStarLight } from '@fortawesome/pro-regular-svg-icons'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { Subject, take, takeUntil } from 'rxjs'
import {
    UserAction,
    USER_ACTION_REMOVE_SAVED,
    USER_ACTION_SAVED,
} from '../user-actions-menu/user-actions-menu.component'

@Component({
    selector: 'app-toggle-save',
    templateUrl: './toggle-save.component.html',
    styleUrls: ['./toggle-save.component.scss'],
})
export class ToggleSaveComponent implements OnInit {
    @Input() saved: boolean = false
    @Input() targetUserId: string
    @Input() classes: string[]
    @Input() source: string
    @Output() onUserAction = new EventEmitter<UserAction>()

    faStarSaved = faStar
    faStarNotSaved = faStarLight

    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public disableIfViewingSelf: boolean

    private notifyOnSaveUserDisabled: boolean = false
    private shareAvailability: UserSettingsShareAvailability

    constructor(
        private dialogService: DialogService,
        private userRelationshipService: UserRelationshipService,
        private userService: UserService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user: User) => {
            this.disableIfViewingSelf = user.userId === this.targetUserId
            this.notifyOnSaveUserDisabled = user.properties['notifyOnSaveUserDisabled'] || false
            this.shareAvailability = user.settings.shareAvailability || 'no one'
        })
    }

    public addToSaved() {
        this.userRelationshipService
            .saveUser({ userId: this.targetUserId, saveUser: true })
            .subscribe(() => {
                this.analyticsService.logClickedEvent('Add user to Favorites', this.source)
                this.onUserAction.emit({ userAction: USER_ACTION_SAVED })
                this.dialogService.openConfirmTransaction('Added to Favorites!')

                if (!this.notifyOnSaveUserDisabled && this.shareAvailability === 'no one') {
                    this.dialogService.openEditShareAvailabilitySettingModal(true)
                }
            })
    }

    removeFromSaved() {
        this.userRelationshipService
            .saveUser({ userId: this.targetUserId, saveUser: false })
            .subscribe(() => {
                this.analyticsService.logClickedEvent('Remove user from Favorites', this.source)
                this.onUserAction.emit({ userAction: USER_ACTION_REMOVE_SAVED })
                this.dialogService.openConfirmTransaction('Removed from Favorites')
            })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
