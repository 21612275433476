<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()"></fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title">What's your timezone?</div>
    </div>

    <div class="std-modal-body w-full">
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" class="w-full">
            <div class="w-full">
                <fm-timezone-select
                    formControlName="timezone"
                    class="w-[420px] max-w-full"
                    (selectChange)="timezoneChanged($event)"></fm-timezone-select>
                <div class="f-hint fm-mt-2px">Time: {{ currentTime }}</div>
                <div class="error-text fm-mt-4px" *ngIf="timezoneSelectionError"
                    >Please select your time zone</div
                >
            </div>
        </form>
    </div>

    <div class="std-modal-action-container">
        <app-spinner-button
            class="action"
            [disable]="disableSaveButton"
            [label]="'Save timezone'"
            classes="f-btn f-btn--primary f-btn-250"
            (clicked)="onSubmit()">
        </app-spinner-button>

        <div class="f-link fm-mt-15px" (click)="dialog.close()">Cancel</div>
    </div>
</div>
