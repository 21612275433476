import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class InSessionModalService {
    public showPartnerIsNewModal$ = new Subject<boolean>()
    public openFeedbackForm$ = new Subject<boolean>()
    public openPartnerInQuietModeModal$ = new Subject<boolean>()

    constructor() {}

    openPartnerIsNewModal() {
        this.showPartnerIsNewModal$.next(true)
    }

    openFeedbackForm() {
        this.openFeedbackForm$.next(true)
    }

    openPartnerInQueitModeModal() {
        this.openPartnerInQuietModeModal$.next(true)
    }
}
