<div class="dropdown">
    <button
        type="button"
        class="select-trigger"
        (click)="open($event, dropdown, origin, false)"
        #origin>
        <ng-template [ngIf]="!isOpen" [ngIfElse]="searchTpl">
            <div *ngIf="label" class="wrap-single-line-el">{{ label }}</div>
        </ng-template>

        <ng-template #searchTpl>
            <input
                appFmAutofocus
                [formControl]="searchControl"
                placeholder="Search..."
                (click)="$event.stopPropagation()" />
        </ng-template>
        <div class="tz-placeholder" [ngClass]="{ 'float-label': label || isOpen }">Time zone</div>
        <div class="select-trigger-chevron" (click)="open($event, dropdown, origin, true)"
            ><fa-icon [icon]="faChevronDown"></fa-icon
        ></div>
    </button>

    <ng-template #dropdown>
        <div class="select-menu z-[1001] w-max absolute top-0 left-0">
            <cdk-virtual-scroll-viewport
                itemSize="56"
                class="select-menu-inner"
                [style.height.px]="visibleOptions * 56">
                <div *ngIf="!options.length" class="no-results-item"
                    >Couldn't find that time zone</div
                >

                <div
                    *cdkVirtualFor="let option of options"
                    class="select-item"
                    [class.active]="isActive(option)"
                    (click)="select(option)">
                    {{ option.label }}
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </ng-template>
</div>
