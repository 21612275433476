import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatDialogRef } from '@angular/material/dialog'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { WelcomeChecklistHeaderComponent } from '../welcome-checklist-header/welcome-checklist-header.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserService } from '@app/core/_services/user.service'
import { UtilsService } from '@app/core/_services'
import { DeviceDetectionService } from '@app/core/_services/device-detection.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { User } from '@app/core/_models'

@Component({
    selector: 'fm-complete-first-session-modal',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, WelcomeChecklistHeaderComponent],
    templateUrl: './complete-first-session-modal.component.html',
    styleUrl: './complete-first-session-modal.component.scss',
})
export class CompleteFirstSessionModalComponent {
    faArrowRight = faArrowRight

    public testSessionUrl: string

    constructor(
        private dialog: MatDialogRef<CompleteFirstSessionModalComponent>,
        private analyticsService: AnalyticsService,
        private userService: UserService,
        private utilsService: UtilsService,
    ) {
        this.analyticsService.logViewedModalEvent('Welcome Checklist Modal: Complete First Session')

        this.userService.currentUser.subscribe((user) => {
            this.testSessionUrl = this.utilsService.createTestSessionUrl(user)
        })
    }

    testSessionLinkClick() {
        this.analyticsService.logLaunchTestSession('welcomeChecklist')
        this.analyticsService.logClickedEvent(
            'test session',
            'Welcome Checklist Modal: Complete First Session',
        )
    }

    nextStepButtonClick() {
        this.analyticsService.logClickedEvent(
            'Next',
            'Welcome Checklist Modal: Complete First Session',
        )
        this.dialog.close()
    }
}
