<div class="std-modal-container">
    <ng-container [ngSwitch]="modalContent">
        <ng-container *ngSwitchCase="'snooze'">
            <fa-icon
                class="std-modal-close"
                [icon]="faTimes"
                [classes]="['fa-std-close-icon']"
                (click)="closeSnoozeModal()"></fa-icon>
            <div class="std-modal-header">
                <div class="std-modal-title wrap-double-line-el"
                    >Snooze {{ data.displayName }}
                </div>
            </div>

            <div class="std-modal-body w-full">
                <form class="w-full flex-c-fs-c">
                    <div class="f-form-field w-full" style="align-items: center">
                        <app-fm-select-snooze-duration
                            class="w-full"
                            style="max-width: 335px; overflow: hidden"
                            [currentSelection]="selectedDuration"
                            [customValue]="customValue"
                            [disabled]="false"
                            btnClasses="std-select w-full"
                            (selectionChanged)="updateSelectedDuration($event)">
                        </app-fm-select-snooze-duration>
                    </div>

                    <div class="f-form-field w-full fm-mt-25px" style="align-items: center">
                        <div class="info-link" (click)="showSnoozeInfo()"
                            >What happens when I snooze someone?</div
                        >
                    </div>
                </form>
            </div>
            <div class="std-modal-action-container">
                <div
                    class="w-full flex-r-c-c"
                    [matTooltip]="isCustomTimeValid ? '' : 'Selected time must be in the future'"
                    matTooltipPosition="below"
                    matTooltipShowDelay="200"
                    matTooltipClass="f-tooltip">
                    <app-spinner-button
                        class="action"
                        [disable]="disableSaveButton || !isCustomTimeValid"
                        [label]="'Snooze'"
                        classes="f-btn f-btn--primary f-btn-250"
                        (clicked)="submitForm()">
                    </app-spinner-button>
                </div>
                <div class="f-link fm-mt-19px" (click)="closeSnoozeModal()">Cancel</div>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'snoozeInfo'">
            <fa-icon
                class="std-modal-close"
                [icon]="faTimes"
                [classes]="['fa-std-close-icon']"
                (click)="closeSnoozeModal()"></fa-icon>
            <div class="std-modal-header" style="padding-bottom: 13px"> </div>

            <div class="std-modal-body w-full">
                <div class="info-subtitle">What happens when I snooze someone?</div>
                <div class="info-content-container text-left f-bluegray-14-n-text fm-mt-34px">
                    <ul>
                        <li>You won’t be matched with them during the snooze period</li>
                        <li
                            >Any upcoming sessions with them during the snooze period will be
                            rematched</li
                        >
                        <li
                            >If they have access to your Availability page, they won't see sessions
                            that are during the snooze period</li
                        >
                        <li>They won’t be notified</li>
                        <li
                            >You can unsnooze them or edit the snooze in
                            <a
                                class="f-link"
                                [href]="settingsUrl"
                                target="_blank"
                                (click)="settingsClicked()"
                                >Settings</a
                            ></li
                        >
                    </ul>
                </div>
            </div>
            <div class="std-modal-action-container" style="padding-top: 30px">
                <div class="f-btn f-btn--primary f-btn-250" (click)="showSnoozeSelection()"
                    >Back</div
                >
                <a
                    class="f-link fm-mt-19px"
                    style="text-decoration: none"
                    target="_blank"
                    href="https://support.focusmate.com/en/articles/5950429-how-to-snooze"
                    (click)="learnMoreClicked()"
                    >Learn more</a
                >
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'editSnooze'">
            <fa-icon
                class="std-modal-close"
                [icon]="faTimes"
                [classes]="['fa-std-close-icon']"
                (click)="closeSnoozeModal()"></fa-icon>
            <div class="std-modal-header" style="padding-bottom: 50px">
                <div class="std-modal-title wrap-double-line-el">Edit Snooze</div>
            </div>

            <div class="std-modal-body w-full fm-mt-30px fm-mb-30px">
                <form class="w-full flex-c-fs-c">
                    <div class="f-bluegray-14-n-text fm-mb-10px"
                        >{{ data.displayName }} is snoozed {{ snoozedUntil }}</div
                    >

                    <div class="f-form-field w-full" style="align-items: center">
                        <app-fm-select-snooze-duration
                            class="w-full"
                            style="max-width: 335px; overflow: hidden"
                            [currentSelection]="selectedDuration"
                            [customValue]="customValue"
                            [disabled]="false"
                            btnClasses="std-select w-full"
                            (selectionChanged)="updateSelectedDuration($event)">
                        </app-fm-select-snooze-duration>
                    </div>
                </form>
            </div>
            <div class="std-modal-action-container">
                <div
                    class="w-full flex-r-c-c"
                    [matTooltip]="isCustomTimeValid ? '' : 'Selected time must be in the future'"
                    matTooltipPosition="below"
                    matTooltipShowDelay="200"
                    matTooltipClass="f-tooltip">
                    <app-spinner-button
                        class="action"
                        [disable]="disableSaveButton || !isCustomTimeValid"
                        [label]="'Snooze'"
                        classes="f-btn f-btn--primary f-btn-250"
                        (clicked)="submitForm()">
                    </app-spinner-button>
                </div>
                <div class="f-link fm-mt-19px" (click)="closeSnoozeModal()">Cancel</div>
            </div>
        </ng-container>
    </ng-container>
</div>
