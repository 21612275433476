import { Component, EventEmitter, Inject, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faClose } from '@fortawesome/pro-solid-svg-icons'
import { WelcomeChecklistService } from '@app/core/_services/welcome-checklist.service'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UtilsService } from '@app/core/_services'
import { UserService } from '@app/core/_services/user.service'

@Component({
    selector: 'fm-complete-unfinished-steps-modal',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule],
    templateUrl: './complete-unfinished-steps-modal.component.html',
    styleUrl: './complete-unfinished-steps-modal.component.scss',
})
export class CompleteUnfinishedStepsModalComponent {
    @Output() nextStepButtonClick: EventEmitter<any> = new EventEmitter<any>()

    faClose = faClose
    faArrowRight = faArrowRight

    isHowItWorksStepComplete: boolean
    isReviewCommunityGuidelinesStepComplete: boolean

    testSessionUrl: string

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialogRef<CompleteUnfinishedStepsModalComponent>,
        private welcomeChecklistService: WelcomeChecklistService,
        private analyticsService: AnalyticsService,
        private userService: UserService,
        private utilsService: UtilsService,
    ) {
        this.isHowItWorksStepComplete = this.welcomeChecklistService.isStepComplete('howItWorks')
        this.isReviewCommunityGuidelinesStepComplete = this.welcomeChecklistService.isStepComplete(
            'reviewCommunityGuidelines',
        )

        this.analyticsService.logViewedModalEvent(
            'Welcome Checklist Modal: Complete Unfinished Steps',
        )

        this.userService.currentUser.subscribe((user) => {
            this.testSessionUrl = this.utilsService.createTestSessionUrl(user)
        })
    }

    onCloseIconClick() {
        this.dialog.close()
    }

    testSessionLinkClick() {
        this.analyticsService.logLaunchTestSession('welcomeChecklist')
        this.analyticsService.logClickedEvent(
            'test session',
            'Welcome Checklist Modal: Complete Unfinished Steps',
        )
    }

    onNextStepButtonClick() {
        this.analyticsService.logClickedEvent(
            'Next',
            'Welcome Checklist Modal: Complete Unfinished Steps',
        )
        this.nextStepButtonClick.emit()
    }
}
