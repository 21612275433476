<div class="w-full">
    <div class="w-full flex flex-row justify-between items-center" [ngClass]="toggleLabelClasses">
        <div class="flex flex-row justify-start items-center">
            <div class="">My Task</div>
            @if (showInfoModalTrigger) {
                <div class="cursor-pointer ml-1">
                    <fa-icon
                        (click)="openInfoModal()"
                        class="text-[#808080] text-12-16"
                        [icon]="faInfoCircle"></fa-icon>
                </div>
            }
        </div>
    </div>
    <fm-button-group
        class="w-full text-sm font-medium"
        [itemList]="buttonList"
        [disabled]="disabled"
        [ngModel]="initialValue"
        [ngModelOptions]="{ standalone: true }"
        (selectionChanged)="onToggleChange($event)" />
    <div class="text-bluegrey-2 text-11-15 font-avenir font-medium mt-1.5"
        >We’ll match you with the same task type whenever possible.</div
    >
</div>
