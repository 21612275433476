import { Component } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { InSessionModalService } from '@app/launch/services/in-session-modal.service'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

@Component({
    selector: 'app-video-initialization-failed-notice',
    templateUrl: './video-initialization-failed-notice.component.html',
    styleUrls: ['./video-initialization-failed-notice.component.scss'],
})
export class VideoInitializationFailedNoticeComponent {
    public faTimes = faTimes
    constructor(
        public dialog: MatDialogRef<VideoInitializationFailedNoticeComponent>,
        private analyticsService: AnalyticsService,
        private inSessionModalService: InSessionModalService,
    ) {}
    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Video Initialization Failed')
    }

    refresh() {
        this.analyticsService.logClickedEvent('Refresh', 'Video Initialization Failed')
        location.reload()
    }

    reportHere() {
        this.analyticsService.logClickedEvent('report it here', 'Video Initialization Failed')
        this.inSessionModalService.openFeedbackForm()
        this.dialog.close()
    }
}
