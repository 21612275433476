import { Injectable } from '@angular/core'
import { ApiService } from '@app/core/_services/api.service'
import {
    BookSessionPreferences,
    SessionActivityType,
    SessionPreferenceFavorites,
    UpdateSessionInfo,
} from '@app/core/_models'
import { environment } from '@env/environment'
import { map } from 'rxjs/operators'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { ConfirmedSessionsService } from '@app/core/_services/confirmed-sessions.service'

@Injectable({
    providedIn: 'root',
})
export class EditSessionService {
    constructor(
        private analyticsService: AnalyticsService,
        private confirmedSessionsService: ConfirmedSessionsService,
        private apiService: ApiService,
    ) {}

    editSessionTitle(sessionTime: number, title: string) {
        let updateSessionInfo: UpdateSessionInfo = new UpdateSessionInfo(
            (sessionTime = sessionTime),
            (title = title),
        )
        const data = { data: updateSessionInfo }

        return this.apiService.put(environment.api_url + 'session/', data).pipe(
            map((responseData) => {
                this.analyticsService.logSessionTitleEdit()
                this.confirmedSessionsService.updateTitle(sessionTime, title)
                return responseData
            }),
        )
    }

    editSessionPreferences(
        sessionTime: number,
        favoritesPreference: SessionPreferenceFavorites,
        activityType: SessionActivityType,
        quietMode: boolean,
    ) {
        let preferences: BookSessionPreferences = {
            favorites: { value: favoritesPreference },
            quietMode: { value: quietMode },
        }
        let updateSessionInfo: UpdateSessionInfo = new UpdateSessionInfo(
            (sessionTime = sessionTime),
        )
        updateSessionInfo.preferences = preferences
        updateSessionInfo.activityType = activityType
        const data = { data: updateSessionInfo }

        return this.apiService.put(environment.api_url + 'session/', data).pipe(
            map((responseData) => {
                this.confirmedSessionsService.updatePreferences(sessionTime, preferences)
                return responseData
            }),
        )
    }
}
