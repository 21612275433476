import { Injectable } from '@angular/core'
import * as Sentry from '@sentry/angular-ivy'
import { environment } from '@env/environment'

declare let gtag: Function

export enum GACategories {
    CAT_SESSION = 'Session',
}

export enum GAEvents {
    EVENT_UNKNOWN = 'Unknown',
    EVENT_VERIFY_EMAIL_SENT = 'verifyEmailSent',
    EVENT_VERIFY_EMAIL_SENT_FAILED = 'verifyEmailSentFailed',
    EVENT_SESSION_JOIN = 'videoLink',
    EVENT_SESSION_STARTED = 'sessionStarted',
    EVENT_SESSION_INPROGRESS = 'sessionInprogress',
    EVENT_SESSION_ENDED = 'sessionEnded',
    EVENT_SESSION_CLOSED = 'sessionClosed',
    EVENT_SESSION_JOIN_LOG_FAIL = 'videoLinkLogFailure',
    EVENT_SESSION_STARTED_LOG_FAIL = 'sessionStartedLogFailure',
    EVENT_SESSION_INPROGRESS_LOG_FAIL = 'sessionInprogressLogFailure',
    EVENT_SESSION_ENDED_LOG_FAIL = 'sessionEndedLogFailure',
    EVENT_SESSION_CLOSED_LOG_FAIL = 'sessionClosedLogFailure',
}

export enum GALabel {
    LABEL_VERIFY_SENT_NEW_USER = 'newUser',
    LABEL_VERIFY_SENT_EXISTING_USER = 'existingUser',
}

// confess: still learning if these are useful
/*
 * Quack like a GAEvent
 */
export interface GAEventInterface {
    eventCategory: string
    eventAction: string
    eventLabel: string
    eventValue: number
}

// Interfaces can't set defaults ... not sure if the interface is useful ever
export class GAEvent implements GAEventInterface {
    // Confess: no idea what the best practices are for formatting long arg lists
    constructor(
        public eventCategory,
        public eventAction,
        public eventLabel = null,
        public eventValue = null,
    ) {}
}

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsEventsService {
    public emitEvent(
        eventCategory: string,
        eventAction: string,
        eventLabel: string = null,
        eventValue: number = 0,
    ) {
        try {
            gtag('event', eventAction, {
                send_to: 'focusmate',
                event_category: eventCategory,
                event_label: eventLabel,
                value: eventValue,
            })
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e
            }
            Sentry.captureException(e)
        }
    }

    public setUserId(userId: string) {
        try {
            gtag('set', 'user_id', userId)
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e
            }
            Sentry.captureException(e)
        }
    }

    public trackPageView(pageTitle: string, pagePath: string) {
        try {
            gtag('event', 'page_view', {
                send_to: 'focusmate',
                page_title: pageTitle,
                page_path: pagePath,
            })
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e
            }
            Sentry.captureException(e)
        }
    }
}
