import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'app-update-email-address',
    templateUrl: './update-email-address.component.html',
    styleUrls: ['./update-email-address.component.scss'],
})
export class UpdateEmailAddressComponent implements OnInit {
    constructor(
        public dialog: MatDialogRef<UpdateEmailAddressComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {}
}
