<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title xwrap-double-line-el text-center"
            >Welcome to our new address!
        </div>
    </div>

    <div class="std-modal-body w-full text-bluegrey text-sm font-avenir font-medium gap-6">
        <div class="w-full flex flex-col items-start justify-start gap-3">
            <div
                >Focusmate has moved to app.focusmate.com for enhanced performance. <br /><br />Now
                that you’re here, here’s what to do:</div
            >
            <div class="w-full flex flex-col justify-start items-start">
                <div class="!mb-2 flex flex-row">
                    <div class="w-5 flex flex-row justify-start items-start">
                        <fa-icon class="text-blurple" [icon]="faBookmark"></fa-icon>
                    </div>
                    <div>
                        <span class="text-blurple">Bookmark the new address</span> for quick
                        access.</div
                    >
                </div>
                <div class="!mb-2 flex flex-row">
                    <div class="w-5 flex flex-row justify-start items-start">
                        <fa-icon class="text-blurple" [icon]="faBell"></fa-icon>
                    </div>
                    <div>
                        <span class="text-blurple">Allow browser notifications</span> when
                        prompted.</div
                    >
                </div>
                <div class="flex flex-row">
                    <div class="w-5 flex flex-row justify-start items-start">
                        <fa-icon class="text-blurple" [icon]="faVideo"></fa-icon
                    ></div>
                    <div>
                        <span class="text-blurple">Grant camera and mic permissions</span> when
                        prompted.</div
                    >
                </div>
            </div>
            <div> Happy focusing! </div>
        </div>
    </div>
    <div class="std-modal-action-container">
        <button class="f-btn f-btn--primary f-btn-250 !px-0" (click)="closeModal()">Got it!</button>
        <div class="flex flex-row justify-center items-center mt-4">
            <a
                class="f-link"
                style="text-decoration: none"
                target="_blank"
                href="http://support.focusmate.com/en/articles/9028876-focusmate-s-updated-web-address-march-2024"
                (click)="needHelp()">
                I need more information</a
            ></div
        >
    </div>
</div>
