import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'

@Component({
    selector: 'app-partner-is-new-user',
    templateUrl: './partner-is-new-user.component.html',
    styleUrls: ['./partner-is-new-user.component.scss'],
})
export class PartnerIsNewUserComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private analyticsService: AnalyticsService,
        public dialog: MatDialogRef<PartnerIsNewUserComponent>,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Partner Is New')
    }
}
