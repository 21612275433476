<fm-update-address-banner
    *ngIf="(userService.currentUser | async).planInfo.addressUpdateNeeded"
    class="w-full z-40"
    [ngClass]="{
        'fixed top-0': isBannerSticky,
        relative: !isBannerSticky
    }" />
<fm-maintenance-banner
    *ngIf="showMaintenanceBanner"
    class="w-full z-50"
    [ngClass]="{
        'fixed top-0': isBannerSticky,
        relative: !isBannerSticky
    }" />

<router-outlet></router-outlet>
