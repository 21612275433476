<div class="fm-ev-container">
    <div>
        <mat-icon type="button" class="fm-clear-icon" (click)="dialog.close()">clear</mat-icon>
    </div>
    <div class="fm-ev-title fm-mb-30px text-center">Account Suspended</div>
    <div class="fm-ev-text fm-mb-20px">
        Your account has been suspended due to violation of the
        <a class="diag-link" [href]="communityGuidelinesUrl" target="_blank">community guidelines</a
        >. If you feel the suspension is in error or would like to have your account restored please
        <a
            class="diag-link"
            target="_blank"
            href="mailto:support&#64;focusmate.com?subject=[RESTORE ACCOUNT]&body={{ bodyContent }}"
            >contact us.</a
        >.
    </div>
</div>
