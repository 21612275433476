import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { ApiService } from '@app/core/_services/api.service'
import { environment } from '@env/environment'
import { first } from 'rxjs/operators'

const FM_AUTHORIZE_URL = environment.api_url + 'oauth/authorize'
const THIRD_PARTY_AUTH_URL = environment.api_url + 'oauth/3p'

@Injectable({ providedIn: 'root' })
export class OAuthService {
    constructor(private apiService: ApiService) {}

    validateConsentRequest(queryParams: Params) {
        const params = new HttpParams({ fromObject: queryParams })
        return this.apiService.get(FM_AUTHORIZE_URL, params).pipe(first())
    }

    validateThirdPartyCallback(provider: string, queryParams: Params) {
        const fullUrl = `${THIRD_PARTY_AUTH_URL}/${provider}/token`
        // convert queryParams to HttpParams
        const httpParams = new HttpParams({ fromObject: queryParams })
        return this.apiService.get(fullUrl, httpParams).pipe(first())
    }

    handleConsent(queryParams: Params, allow: boolean) {
        const params = new HttpParams({ fromObject: queryParams })
        return this.apiService.post(FM_AUTHORIZE_URL, { allow }, { params }).pipe(first())
    }

    getGoogleOauthUrl() {
        return this.apiService.get(`${THIRD_PARTY_AUTH_URL}/google`).pipe(first())
    }
}
