<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title wrap-double-line-el">Snooze {{ data.displayName }}</div>
    </div>

    <div class="std-modal-body w-full">
        <div class="flex-c-c-c w-full f-bluegray-14-n-text">
            <p
                >You have snoozed {{ data.displayName }}
                <span class="fm-color-brand-1">{{ data.expiresAt }}</span></p
            >
        </div>
    </div>
    <div class="std-modal-action-container">
        <button class="f-btn f-btn--primary f-btn-250" (click)="dialog.close()">Close </button>

        <div
            *ngIf="currentUrl != '/people'"
            class="f-link fm-mt-15px"
            routerLink="/people"
            [queryParams]="{ type: 'snoozed' }"
            (click)="manageSnoozesSelected()"
            >Manage snoozes</div
        >
    </div>
</div>
