<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div *ngIf="initialPreferenceSelected != 2; else invalidSelection" class="std-modal-title"
            >Gender Preference</div
        >
        <ng-template #invalidSelection>
            <div class="std-modal-title">We’ve made some changes to gender preferences</div>
            <div class="std-modal-body-text text-left w-full">
                <p
                    >You previously indicated you prefer to pair with
                    <span class="fm-color-brand-1 text-nowrap"
                        >[Women & non-binary people only]</span
                    >
                    on Focusmate.
                </p>
                <p
                    >We've refined your choices to better promote safety and inclusion within our
                    community.</p
                >

                <p style="margin-bottom: 0">Please select an updated preference below:</p>
            </div>
        </ng-template>
    </div>

    <div class="std-modal-body w-full">
        <div class="f-form-field w-full field-spacer">
            <label style="margin-bottom: 7px">Match me with:</label>
            <mat-radio-group
                class="f-radio-group"
                [(ngModel)]="preferenceSelected"
                (change)="radioButtonChanged()">
                <mat-radio-button
                    disableRipple
                    class="f-radio-button"
                    *ngFor="let item of radioButtonInfo"
                    [value]="item.value">
                    {{ item.label }}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="f-hint w-full"
            >*Those with no gender set will not be included in your matches.</div
        >

        <div
            *ngIf="initialPreferenceSelected != 2; else invalidSelectionNote"
            class="f-hint fm-mt-20px w-full"
            >Your gender determines your matching options.
            <span class="f-link-effect fm-color-brand-1" (click)="updateGenders()"
                >({{ genderList }})</span
            >
        </div>
        <ng-template #invalidSelectionNote>
            <div class="f-hint fm-mt-20px w-full"
                >Your gender
                <span class="f-link-effect fm-color-brand-1" (click)="updateGenders()"
                    >({{ genderList }})</span
                >
                determines your matching options. You can change your preferences in your account
                settings at any time.</div
            >
        </ng-template>

        <div class="std-modal-action-container">
            <app-spinner-button
                class="action"
                [disable]="disableSaveButton"
                [label]="'Save gender preference'"
                classes="f-btn f-btn--primary f-btn-250"
                (clicked)="submitForm()">
            </app-spinner-button>

            <div class="f-link fm-mt-15px" (click)="dialog.close()">{{
                initialPreferenceSelected == 2 ? 'Remind me later' : 'Cancel'
            }}</div>
            <app-gender-inclusion-blog class="w-full fm-mt-15px"></app-gender-inclusion-blog>
        </div>
    </div>
</div>
