import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { PlayToneService } from '@app/core/_services/play-tone.service'
import { UtilsService } from '@app/core/_services/utils.service'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

export const MISSING_PARTNER_ACTION_CANCEL = 'cancel'
export const MISSING_PARTNER_ACTION_SEARCH_FOR_PARTNER = 'search'
export type MissingPartnerAction =
    | typeof MISSING_PARTNER_ACTION_CANCEL
    | typeof MISSING_PARTNER_ACTION_SEARCH_FOR_PARTNER

@Component({
    selector: 'app-missing-partner-modal',
    templateUrl: './missing-partner-modal.component.html',
    styleUrls: ['./missing-partner-modal.component.scss'],
})
export class MissingPartnerModalComponent {
    public showMore: boolean = false
    public countdownTo: number
    faTimes = faTimes

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private analyticsService: AnalyticsService,
        private util: UtilsService,
        public dialog: MatDialogRef<MissingPartnerModalComponent, MissingPartnerAction>,
        public playToneService: PlayToneService,
    ) {}

    ngOnInit(): void {
        let now = this.util.getCurrentTimestamp()

        if (now >= this.data.countdownTo) {
            this.handleCountdownFinished()
        } else {
            this.countdownTo = this.data.countdownTo
        }
        this.analyticsService.logViewedModalEvent('Missing Partner')
        this.playToneService.playRematchPopupTone()
    }

    public handleCountdownFinished() {
        this.dialog.close(MISSING_PARTNER_ACTION_CANCEL)
    }

    public cancelSessionButtonClick() {
        this.analyticsService.logClickedEvent('Wait for Partner', 'missingPartnerModal')
        this.dialog.close(MISSING_PARTNER_ACTION_CANCEL)
    }

    public searchForPartnerButtonClick() {
        this.analyticsService.logClickedEvent('Request rematch', 'missingPartnerModal')
        this.dialog.close(MISSING_PARTNER_ACTION_SEARCH_FOR_PARTNER)
    }
}
