import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Subject } from 'rxjs'
import { DialogService } from '@app/core/_services/dialog.service'
import { take, takeUntil } from 'rxjs/operators'
import { UserRelationshipService } from '@app/core/_services/user-relationship.service'
import { UserService } from '@app/core/_services/user.service'
import { User } from '@app/core/_models'
import {
    faCalendar,
    faClockEight,
    faExclamationTriangle,
    faFileSlash,
    faMinusOctagon,
    faPencil,
    faStar,
    faTimes,
    faUser,
    faAddressCard,
} from '@fortawesome/pro-regular-svg-icons'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'

export const USER_ACTION_BLOCKED = 'blocked'
export const USER_ACTION_UNBLOCKED = 'unblocked'
export const USER_ACTION_SNOOZED = 'snoozed'
export const USER_ACTION_UNSNOOZED = 'unsnoozed'
export const USER_ACTION_NONE = 'none'
export const USER_ACTION_LAUNCH_SNOOZE = 'launchSnooze'
export const USER_ACTION_SAVED = 'saved'
export const USER_ACTION_REMOVE_SAVED = 'removeSaved'

export interface UserAction {
    userAction: string
    snoozeExpiresAt?: string
}

@Component({
    selector: 'app-user-actions-menu',
    templateUrl: './user-actions-menu.component.html',
    styleUrls: ['./user-actions-menu.component.scss'],
})
export class UserActionsMenuComponent implements OnInit {
    @Input() classes: string = 'svg-user-action-menu-brand-1'
    @Input() showReportSession: boolean = false
    @Input() showReportProfile: boolean = false
    @Input() showBlock: boolean = true
    @Input() showUnblock: boolean = true
    @Input() showEditSnooze: boolean = true
    @Input() showSnooze: boolean = true
    @Input() showUnsnooze: boolean = true
    @Input() showSeeAvailability: boolean = false
    @Input() showViewProfile: boolean = true

    @Input() sessionTime: number | null = null
    @Input() sessionId: string | null = null
    @Input() timeZone: string | null = null
    @Input() targetUserDisplayName: string | null = null
    @Input() targetUserId: string | null = null
    @Input() targetUserProfileUrl: string | null = null
    @Input() targetUserPhotoUrl: string | null = null
    @Input() status: number | null = null
    @Input() source: string | null = null
    @Input() snoozeStatus: boolean = false
    @Input() snoozeExpiresAt: string | null = null
    @Input() saved: boolean = false
    @Input() sharedAvailability: boolean = false
    @Output() onUserAction = new EventEmitter<UserAction>()

    private ngUnsubscribe: Subject<any> = new Subject<any>()
    private currentUser: User = new User()
    public menuItemClasses: string[]

    public faMinusOctagon = faMinusOctagon
    public faExclamationTriangle = faExclamationTriangle
    public faTimes = faTimes
    public faPencil = faPencil
    public faUser = faUser
    public faClockEight = faClockEight
    public faStar = faStar
    public faFileSlash = faFileSlash
    public faCalendar = faCalendar
    public faAddressCard = faAddressCard

    public showSeeAvailabilityOption: boolean = false
    public sectionOnePresent: boolean = false
    public sectionTwoPresent: boolean = false
    public sectionThreePresent: boolean = false

    constructor(
        private dialogService: DialogService,
        private userRelationshipService: UserRelationshipService,
        public userService: UserService,
        private analyticsService: AnalyticsService,
        private dateFormat: UserDateFormattingService,
    ) {}

    ngOnInit() {
        this.userService.currentUser.pipe(take(1)).subscribe((user: User) => {
            this.currentUser = user
        })
        this.updateMenu()
    }

    ngOnChanges() {
        this.updateMenu()
    }

    public updateMenu() {
        if (this.targetUserId == this.currentUser.userId) {
            this.menuItemClasses = ['svg-user-action-menu-item-disabled']
        } else {
            this.menuItemClasses = ['svg-user-action-menu-item']
        }

        this.showSeeAvailabilityOption = this.showSeeAvailability && this.saved
        this.sectionOnePresent = this.showSeeAvailabilityOption || this.showViewProfile
        this.sectionTwoPresent = this.showSnooze || this.showUnsnooze || this.showEditSnooze
        this.sectionThreePresent =
            this.showBlock || this.showUnblock || this.showReportProfile || this.showReportSession
    }

    reportPartner() {
        this.dialogService
            .openReportBadBehavior(
                this.sessionId,
                this.sessionTime,
                this.targetUserDisplayName,
                this.source,
            )
            .afterClosed()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((reported) => {
                if (reported === true) {
                    this.dialogService.openReportBadBehaviorConfirmation(this.targetUserDisplayName)
                }
            })
    }

    public openBlockUserDialog() {
        this.dialogService
            .openBlockUserDialog(
                this.targetUserId,
                this.targetUserDisplayName,
                this.saved,
                this.source,
            )
            .afterClosed()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((userAction: UserAction) => {
                if (userAction.userAction === USER_ACTION_BLOCKED) {
                    this.onUserAction.emit(userAction)
                    this.dialogService
                        .openBlockUserConfirmation(this.targetUserDisplayName)
                        .afterClosed()
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe(() => {})
                } else if (userAction.userAction === USER_ACTION_LAUNCH_SNOOZE) {
                    this.snooze(false)
                }
            })
    }

    public unblockUser() {
        this.userRelationshipService.unblockUser(this.targetUserId).subscribe(() => {
            this.onUserAction.emit({ userAction: USER_ACTION_UNBLOCKED })
            this.dialogService.openConfirmTransaction('Unblocked ' + this.targetUserDisplayName)
        })
    }

    reportProfileViolation() {
        this.dialogService
            .openReportProfile(
                this.targetUserProfileUrl,
                this.targetUserPhotoUrl,
                this.targetUserDisplayName,
                this.source,
            )
            .afterClosed()
            .pipe(take(1))
            .subscribe((reported) => {
                if (reported) {
                    this.dialogService.openConfirmTransaction('Report submitted!')
                }
            })
    }

    logSeeAvailability() {
        this.analyticsService.logClickedEvent('See availability', 'CSM - ' + this.source)
    }

    snooze(editSnooze: boolean) {
        this.dialogService
            .openSnoozeUserModal(
                this.targetUserId,
                this.targetUserDisplayName,
                editSnooze,
                this.snoozeExpiresAt,
                this.saved,
                this.source,
            )
            .afterClosed()
            .pipe(take(1))
            .subscribe((userAction: UserAction) => {
                this.onUserAction.emit(userAction)
            })
    }

    unsnooze() {
        this.dialogService
            .openUnsnoozeUserModal(this.targetUserId, this.targetUserDisplayName, this.source)
            .afterClosed()
            .pipe(take(1))
            .subscribe((userAction: UserAction) => {
                this.onUserAction.emit(userAction)
            })
    }

    addToSaved() {
        this.userRelationshipService
            .saveUser({ userId: this.targetUserId, saveUser: true })
            .subscribe(() => {
                this.onUserAction.emit({ userAction: USER_ACTION_SAVED })
                this.dialogService.openConfirmTransaction('Added to saved!')
            })
    }

    removeFromSaved() {
        this.userRelationshipService
            .saveUser({ userId: this.targetUserId, saveUser: false })
            .subscribe(() => {
                this.onUserAction.emit({ userAction: USER_ACTION_REMOVE_SAVED })
                this.dialogService.openConfirmTransaction('Removed from saved')
            })
    }

    viewProfile() {
        this.analyticsService.logClickedEvent('View profile', 'CSM - ' + this.source)
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
