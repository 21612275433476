import { CommonModule } from '@angular/common'
import { Component, Signal } from '@angular/core'
import { SharedModule } from '@app/shared/shared.module'
import { WelcomeChecklistProgressIndicatorComponent } from '../welcome-checklist-progress-indicator/welcome-checklist-progress-indicator.component'
import { faCircleChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { WelcomeChecklistStep, WelcomeChecklistSteps } from '@app/core/_models/welcome-checklist'
import { WelcomeChecklistService } from '@app/core/_services/welcome-checklist.service'
import { AnalyticsService } from '@app/core/_services/analytics.service'

@Component({
    standalone: true,
    imports: [CommonModule, SharedModule, WelcomeChecklistProgressIndicatorComponent],
    selector: 'fm-welcome-checklist-sidebar',
    templateUrl: './welcome-checklist-sidebar.component.html',
    styleUrl: './welcome-checklist-sidebar.component.scss',
})
export class WelcomeChecklistSidebarComponent {
    faCircleChevronDown = faCircleChevronDown

    public steps: Signal<WelcomeChecklistSteps> = this.welcomeChecklistService.welcomeChecklistSteps
    public activeStep: Signal<WelcomeChecklistStep> = this.welcomeChecklistService.activeStep

    constructor(
        private welcomeChecklistService: WelcomeChecklistService,
        private analyticsService: AnalyticsService,
    ) {}

    checklistClick() {
        this.analyticsService.logClickedEvent('Welcome Checklist', 'Welcome Checklist Sidebar')
        this.welcomeChecklistService.openWelcomeChecklistModal()
    }
}
