import { Injectable, WritableSignal, signal } from '@angular/core'
import { GroupInfo, GroupFilter } from '@app/core/_models'
import { BehaviorSubject, Subject } from 'rxjs'
import { UserService } from '@app/core/_services/user.service'
import { takeUntil } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class GroupFilterService {
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    private storagePrefix: string = ''
    groupFiltersSelected: WritableSignal<GroupFilter[]> = signal([])

    constructor(private userService: UserService) {
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.storagePrefix = user.userId + '_'
        })
    }

    initGroupFilters(groups: GroupInfo[]): void {
        let groupFilters: GroupFilter[] = []

        for (let group of groups) {
            if (group.groupMember === true) {
                let selected: boolean = true
                if (localStorage.getItem(this.storagePrefix + group.groupId) === 'false') {
                    selected = false
                }
                groupFilters.push(new GroupFilter(group, selected))
            }
        }

        let all_selected: boolean = true
        if (localStorage.getItem(this.storagePrefix + '_all_fm_members') === 'false') {
            all_selected = false
        }
        groupFilters.push(
            new GroupFilter(
                new GroupInfo(
                    '_all_fm_members',
                    'All Focusmate Members',
                    '../../assets/images/f_logo.png',
                    '',
                    '',
                    false,
                    '',
                    true,
                ),
                all_selected,
            ),
        )

        this.groupFiltersSelected.set(groupFilters)
    }

    updateFilter(group_id: string, show: boolean) {
        localStorage.setItem(this.storagePrefix + group_id, show.toString())
        const groupFilters = this.groupFiltersSelected()

        let newGroupFilters = [...groupFilters]

        for (let filter of newGroupFilters) {
            if (filter.groupInfo.groupId === group_id) {
                filter.show = show
            }
        }
        this.groupFiltersSelected.set(newGroupFilters)
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
