import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LogoWordmarkComponent } from './components/logo-wordmark/logo-wordmark.component'
import { SiteFooterComponent } from './components/site-footer/site-footer.component'
import { RouterModule } from '@angular/router'
import { FmNavbarComponent } from './components/fm-navbar/fm-navbar.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

@NgModule({
    declarations: [LogoWordmarkComponent, SiteFooterComponent, FmNavbarComponent],
    exports: [LogoWordmarkComponent, SiteFooterComponent, FmNavbarComponent],
    imports: [RouterModule, CommonModule, FontAwesomeModule],
})
export class HomeSharedModule {}
