<div class="std-modal-container">
    <div class="std-modal-header">
        <div class="std-modal-title">Update Email {{ data.error ? 'Failed' : 'Success' }}</div>
    </div>

    <div class="std-modal-body w-full">
        <div *ngIf="data.error; else success">{{ data.error }}</div>
        <ng-template #success>
            <div class="status"
                ><div
                    class="status-label"
                    [ngClass]="data.updateStatus.drip.updated ? 'success' : 'failed'"
                    >Drip status:</div
                >{{ data.updateStatus.drip.updated ? 'Success' : 'Failed' }}</div
            >
            <div class="status"
                ><div
                    class="status-label"
                    [ngClass]="data.updateStatus.stripe.updated ? 'success' : 'failed'"
                    >Stripe status:</div
                >{{ data.updateStatus.stripe.updated ? 'Success' : 'Failed' }}</div
            >
            <div class="status"
                ><div
                    class="status-label"
                    [ngClass]="data.updateStatus.firebase.updated ? 'success' : 'failed'"
                    >Firebase status:</div
                >{{ data.updateStatus.firebase.updated ? 'Success' : 'Failed' }}</div
            >
            <div class="status"
                ><div
                    class="status-label"
                    [ngClass]="data.updateStatus.intercom.updated ? 'success' : 'failed'"
                    >Intercom status:</div
                >{{ data.updateStatus.intercom.updated ? 'Success' : 'Failed' }}</div
            >
        </ng-template>

        <div class="std-modal-action-container">
            <button class="f-btn f-btn--primary f-btn-250" (click)="dialog.close()">Ok</button>
        </div>
    </div>
</div>
