import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PeopleRoutingModule } from './people-routing.module'
import { PeopleListComponent } from './components/people-list/people-list.component'
import { PeopleListItemComponent } from './components/people-list-item/people-list-item.component'
import { SharedModule } from '@app/shared/shared.module'
import { PeopleComponent } from './people.component'
import { PeopleListInfoComponent } from './components/people-list-info/people-list-info.component'
import { UserActionsModule } from '@app/user-actions/user-actions.module'
import { StandardsModule } from '@app/standards-v2/standards.module'
import { MaterialModule } from '@app/material/material.module'
import { NgScrollbarModule } from 'ngx-scrollbar'
import { AppBarComponent } from '@app/shared/components/app-bar/app-bar.component'

@NgModule({
    declarations: [
        PeopleComponent,
        PeopleListComponent,
        PeopleListItemComponent,
        PeopleListInfoComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        MaterialModule,
        UserActionsModule,
        PeopleRoutingModule,
        StandardsModule,
        NgScrollbarModule,
        AppBarComponent,
    ],
})
export class PeopleModule {}
