import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core'

@Directive({ selector: '[copy-clipboard]' })
export class CopyClipboardDirective {
    @Input('copy-clipboard')
    public payload: string

    @Output('copied')
    public copied: EventEmitter<string> = new EventEmitter<string>()
    private textArea: any

    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent): void {
        if (!this.payload) return

        this.textArea = document.createElement('textArea')
        this.textArea.readOnly = false
        this.textArea.contentEditable = true
        this.textArea.value = this.payload
        this.textArea.style.position = 'absolute'
        this.textArea.style.top = 0
        this.textArea.style.zIndex = -1
        document.body.appendChild(this.textArea)
        this.textArea.focus()
        let range, selection

        range = document.createRange()
        range.selectNodeContents(this.textArea)
        selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
        this.textArea.setSelectionRange(0, 999999)

        document.execCommand('copy')
        document.body.removeChild(this.textArea)
        this.copied.emit(this.payload)
    }

    // Remove for now - not compatible with iOS
    // @HostListener('click', ['$event'])
    // public onClickOld(event: MouseEvent): void {
    // 	event.preventDefault();
    // 	if (!this.payload) return;

    // 	let listener = (e: ClipboardEvent) => {
    // 		let clipboard = e.clipboardData || window['clipboardData'];
    // 		clipboard.setData('text', this.payload.toString());
    // 		e.preventDefault();

    // 		this.copied.emit(this.payload);
    // 	};

    // 	document.addEventListener('copy', listener, false);
    // 	document.execCommand('copy');
    // 	document.removeEventListener('copy', listener, false);
    // }
}
