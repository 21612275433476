import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CommonModule } from '@angular/common'

import { faArrowRight, faClose } from '@fortawesome/free-solid-svg-icons'
import { UserService } from '@app/core/_services/user.service'
import { firstValueFrom } from 'rxjs'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { AnalyticsService } from '@app/core/_services/analytics.service'

@Component({
    selector: 'fm-welcome-to-focusmate-modal',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule],
    templateUrl: './welcome-to-focusmate-modal.component.html',
    styleUrl: './welcome-to-focusmate-modal.component.scss',
})
export class WelcomeToFocusmateModalComponent implements OnInit {
    @Output() nextStepButtonClick: EventEmitter<null> = new EventEmitter<null>()

    faArrowRight = faArrowRight
    faClose = faClose

    userName: string

    constructor(
        private userService: UserService,
        private analyticsService: AnalyticsService,
    ) {}

    async ngOnInit() {
        const user = await firstValueFrom(this.userService.currentUser)
        this.userName = user.firstName
        this.analyticsService.logViewedModalEvent('Welcome Checklist Modal: Welcome')
    }

    onNextStepButtonClick() {
        this.analyticsService.logClickedEvent('Next', 'Welcome Checklist Modal: Welcome')
        this.nextStepButtonClick.emit()
    }
}
