<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="closeDialog()"></fa-icon>

    <div class="std-modal-header">
        <div class="std-modal-title std-modal-title--sentence wrap-word-break"
            >Heads up...you have a scheduling conflict</div
        >
    </div>

    <div class="std-modal-body">
        <div class="std-modal-body-text text-center">
            You can still book this session, but we’ll have to cancel the conflicting
            {{ data.conflicts.length > 1 ? 'sessions:' : 'session:' }}</div
        >

        <div class="session-info-container fm-mt-25px" *ngFor="let conflictItem of conflictList">
            <div class="flex-r-c-c w-full fm-mb-14px">
                <div class="date-label">
                    {{ conflictItem.dateLabel }}
                </div>
            </div>
            <div
                class="flex-r-c-c w-full"
                [style.justify-content]="
                    conflictItem.timeLabels.length == 1 ? 'center' : 'space-around'
                ">
                <div *ngFor="let session of conflictItem.timeLabels" class="booked-session">
                    <span>{{ session }}</span>
                </div>
            </div>
        </div>

        <div class="std-modal-action-container" style="width: 260px">
            <button class="f-btn f-btn--primary f-btn-250x w-full" (click)="cancelAndBook()"
                >Book anyway</button
            >
            <button class="f-link fm-mt-10px" (click)="closeDialog()">Go back</button>
        </div>
    </div>
</div>
