<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div
            class="text-[22px] leading-normal font-avenir text-center font-semibold wrap-double-line-el"
            >Refresh to start video</div
        >
    </div>

    <div class="std-modal-body w-full">
        <div class="text-bluegrey text-sm leading-normal w-full text-left font-avenir">
            <div
                >Something went wrong with the video feed, but pressing 'Refresh' should get it
                going.
            </div>
            <div class="!mt-3 text-bluegrey text-sm leading-normal w-full text-left font-avenir"
                >If refreshing doesn't fix it, report it
                <a
                    class="text-bluegrey text-sm leading-normal w-full text-left font-avenir cursor-pointer"
                    (click)="reportHere()"
                    >here</a
                ></div
            >
        </div>
    </div>
    <div class="std-modal-action-container">
        <button class="f-btn f-btn--primary f-btn-250" (click)="refresh()">Refresh</button>
    </div>
</div>
