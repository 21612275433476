import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Router } from '@angular/router'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { LocalStorageAccessService } from '@app/core/_services/local-storage-access.service'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons'

@Component({
    selector: 'fm-session-preference-quiet-mode',
    templateUrl: './session-preference-quiet-mode.component.html',
    styleUrl: './session-preference-quiet-mode.component.scss',
})
export class SessionPreferenceQuietModeComponent {
    @Input() initialValue: boolean = false
    @Input() toggleLabelClasses: string = ''
    @Input() disabled: boolean = false
    @Input() showInfoModalTrigger: boolean = false
    @Input() showDescriptionText: boolean = false
    @Input() source: string
    @Output() quietModeChanged = new EventEmitter<boolean>()

    public faInfoCircle = faInfoCircle
    public faUpRightFromSquare = faUpRightFromSquare
    public toggleValue: boolean
    public showDidYouKnowBubble: boolean

    constructor(
        private analyticsService: AnalyticsService,
        private router: Router,
        private dialogService: DialogService,
        private localStorageAccessService: LocalStorageAccessService,
    ) {}

    ngOnInit() {
        this.showDidYouKnowBubble =
            this.showDescriptionText && !this.localStorageAccessService.getItem('qmInfoDismissed')
        this.toggleValue = this.initialValue
    }

    onToggleChange(e: any) {
        this.quietModeChanged.emit(e)
    }

    openInfoModal() {
        this.dialogService.openSessionPreferencesInfoModal()
    }

    settingsSelected() {
        this.analyticsService.logClickedEvent('Settings', 'matchingPreferencesSidebar')
        this.router.navigate(['profile/edit-p'], { queryParams: { type: 'preferences' } })
    }

    closeBubble() {
        this.localStorageAccessService.setItem('qmInfoDismissed', 'true')
        this.showDidYouKnowBubble = false
    }

    ngOnChanges() {}
}
