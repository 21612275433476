<div class="flex flex-row gap-[4px]">
    @for (step of steps(); track step.name) {
        @if (!step.visible) {
        } @else if (!step.complete) {
            @if (activeStep()?.name !== step.name) {
                <fa-icon [icon]="faCircle" class="text-grey-3" (click)="onStepClick(step.name)" />
            } @else {
                <fa-duotone-icon
                    [icon]="faCircleDot"
                    class="cursor-pointer [--fa-primary-color:white] [--fa-secondary-color:#4648aa] [--fa-primary-opacity:1.0] [--fa-secondary-opacity:1.0] hover:[--fa-secondary-color:#5557d0]"
                    (click)="onStepClick(step.name)" />
            }
        } @else {
            <fa-icon
                [icon]="faCircleCheck"
                class="text-blurple hover:text-blurple-light cursor-pointer"
                (click)="onStepClick(step.name)" />
        }
    } @empty {
        <fa-icon [icon]="faCircle" class="text-grey-3" />
        <fa-icon [icon]="faCircle" class="text-grey-3" />
        <fa-icon [icon]="faCircle" class="text-grey-3" />
        <fa-icon [icon]="faCircle" class="text-grey-3" />
    }
</div>
