<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()"></fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title">Generate API Key</div>
    </div>

    <div class="std-modal-body w-full">
        <div
            class="api-key-blurple-info-container"
            [class.hand-cursor]="newKey && !keyCopiedToClipboard"
            [copy-clipboard]="newKey && !keyCopiedToClipboard ? newKey : null"
            (click)="newKey && !keyCopiedToClipboard ? onClickKeyArea() : null">
            <img class="blurple-key-image" src="assets/images/blurplekey.svg" />

            <div class="api-key-info-text">
                <!-- new key was just created -->
                <div *ngIf="newKey; else noNewKey">
                    {{ newKey }}
                </div>

                <ng-template #noNewKey>
                    <!-- loading key info -->
                    <div
                        *ngIf="
                            xhrHasActiveApiKey.state === 'initial' ||
                            xhrHasActiveApiKey.state === 'loading'
                        ">
                        Loading...
                    </div>

                    <!-- no active API key -->
                    <div *ngIf="xhrHasActiveApiKey.state === 'success' && !xhrHasActiveApiKey.data">
                        This account does not have an active API key yet. Generate one below.
                    </div>

                    <!-- pre-existing active API key -->
                    <div *ngIf="xhrHasActiveApiKey.state === 'success' && xhrHasActiveApiKey.data">
                        This account already has an active API key. Generating a new one will
                        invalidate it.
                    </div>
                </ng-template>
            </div>
        </div>

        <div class="copy-to-clipboard-text" *ngIf="newKey && !keyCopiedToClipboard"
            >(click to copy)</div
        >
        <div class="copy-to-clipboard-text" *ngIf="newKey && keyCopiedToClipboard">(copied!)</div>

        <div *ngIf="newKey" class="body-text">
            Here is your API key. <b>This is the only time it will be visible!</b> Please copy and
            store it securely. If it is lost, you will need to generate a new one. See our
            <a class="font-inherit" href="https://apidocs.focusmate.com">API documentation</a> for
            more details.
        </div>

        <div *ngIf="!newKey" class="body-text">
            API keys can be used to programmatically access this account's data. See our
            <a href="https://apidocs.focusmate.com">API documentation</a> for more details.
        </div>
    </div>

    <div *ngIf="!newKey" class="std-modal-action-container">
        <app-spinner-button
            class="action"
            [label]="'Generate Key'"
            classes="f-btn f-btn--primary f-btn-250"
            (clicked)="confirmGenerateApiKey()">
        </app-spinner-button>
    </div>
</div>
