import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ReportInfo } from '@app/core/_models'
import { ApiService } from '@app/core/_services/api.service'
import { environment } from '@env/environment'

@Injectable()
export class ReportService {
    constructor(private apiService: ApiService) {}

    sendProfileReport(profile, photo, reason_id, comment): Observable<any> {
        return this.apiService.post(environment.api_url + 'report/', {
            report_type: 'profile',
            profile_url: profile,
            photo_url: photo,
            reason_id: reason_id,
            comment: comment,
        })
    }

    reportSession(reportInfo: ReportInfo): Observable<any> {
        return this.apiService.post(environment.api_url + 'report/', reportInfo)
    }

    reportVideoIssue(feedbackInfo: any) {
        return this.apiService.post(environment.api_url + 'report/video-feedback', { feedbackInfo })
    }

    ngOnDestroy() {}
}
