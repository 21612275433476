import { Component, OnInit, Input } from '@angular/core'

@Component({
    selector: 'fm-logo-wordmark',
    template: `
        <span class="wordmark" [style.color]="color" [style.fontSize]="fontSize">
            <span class="focus">Focus</span><span class="mate">mate</span>
        </span>
    `,
    styleUrls: ['logo-wordmark.component.scss'],
})
export class LogoWordmarkComponent implements OnInit {
    @Input() color: string
    @Input() fontSize: string

    constructor() {}

    ngOnInit() {}
}
