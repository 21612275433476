import { Component, Input } from '@angular/core'
import {
    SESSION_ACTIVITY_TYPE_ANYTHING,
    SESSION_ACTIVITY_TYPE_DESK,
    SESSION_ACTIVITY_TYPE_MOVING,
} from '@app/core/_models'
import { faLampDesk, faPersonWalking, faShuffle } from '@fortawesome/pro-solid-svg-icons'

const movingTooltip = 'Moving mode: For workouts, chores, and active tasks'
const anythingTooltip = "Anything mode: When they're juggling various tasks or still deciding"
const deskTooltip = 'Desk mode: For writing, emails, coding, and more'

@Component({
    selector: 'fm-activity-type-icon',
    templateUrl: './activity-type-icon.component.html',
    styleUrls: ['./activity-type-icon.component.scss'],
})
export class ActivityTypeIconComponent {
    @Input() activityType: number
    @Input() backgroundClasses: string = ''
    @Input() showTooltip: boolean = false

    public displayIcon
    public tooltip: string

    ngOnInit() {
        this.setIcon()
    }

    ngOnChanges() {
        this.setIcon()
    }

    setIcon() {
        switch (this.activityType) {
            case SESSION_ACTIVITY_TYPE_ANYTHING:
                this.displayIcon = faShuffle
                if (this.showTooltip) {
                    this.tooltip = anythingTooltip
                }
                break

            case SESSION_ACTIVITY_TYPE_DESK:
                this.displayIcon = faLampDesk
                if (this.showTooltip) {
                    this.tooltip = deskTooltip
                }
                break

            case SESSION_ACTIVITY_TYPE_MOVING:
                this.displayIcon = faPersonWalking
                if (this.showTooltip) {
                    this.tooltip = movingTooltip
                }
                break
        }
    }
}
