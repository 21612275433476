<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title">Quiet Mode Preference</div>
    </div>

    <div class="std-modal-body w-full">
        <div class="f-form-field w-full field-spacer">
            <div class="text-bluegrey-1 font-avenir leading-normal text-[14px] font-medium">
                <div>
                    Use Quiet Mode when you don’t have a mic or can’t talk (think libraries and
                    shared spaces).
                    <br /><br />Choose your Quiet Mode matching preference:
                </div>
            </div>
            <mat-radio-group
                class="f-radio-group"
                [(ngModel)]="quietModeAllowed"
                (change)="radioButtonChanged()">
                <mat-radio-button
                    disableRipple
                    class="f-radio-button !mb-[13px] first:mt-[13px]"
                    *ngFor="let item of radioButtonInfo"
                    [value]="item.value">
                    {{ item.label }}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="std-modal-action-container">
            <app-spinner-button
                class="action"
                [disable]="disableSaveButton"
                [label]="'Save preference'"
                classes="f-btn f-btn--primary f-btn-250"
                (clicked)="submitForm()">
            </app-spinner-button>

            <a
                class="f-link mt-[15px]"
                style="text-decoration: none"
                target="_blank"
                href="https://support.focusmate.com/en/articles/8060080-session-settings-my-task-quiet-mode-and-partner"
                (click)="learnMoreClicked()"
                >More about Quiet Mode
            </a>
        </div>
    </div>
</div>
