import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { ApiService } from './api.service'

@Injectable({
    providedIn: 'root',
})
export class PeopleService {
    constructor(private apiService: ApiService) {}

    getPartnerList(listType: string, page: number, pageSize: number, sortBy: string) {
        let queryString = this.generateQueryString(listType, page, pageSize, sortBy)
        return this.apiService.get(environment.api_url + 'people/' + queryString)
    }

    private generateQueryString(
        listType: string,
        page: number,
        pageSize: number,
        sortBy: string,
    ): string {
        return `?page=${page}&page_size=${pageSize}&sort_by=${sortBy}&type=${listType}`
    }
}
