<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title wrap-double-line-el">About the People page</div>
    </div>

    <div class="std-modal-body w-full">
        <div class="flex-c-c-c w-full f-bluegray-14-n-text">
            <ul>
                <li class="fm-mb-10px"
                    ><span class="fm-color-brand-1">
                        <fa-icon
                            [icon]="faUserGroup"
                            [classes]="['fa-icon-size-14']"
                            (click)="dialog.close()">
                        </fa-icon>
                        All partners
                    </span>
                    shows your past session partners, including those you have Favorited or Snoozed.
                    People you have blocked are not included.</li
                >
                <li class="fm-mb-10px"
                    ><span class="fm-color-brand-1">
                        <fa-icon
                            [icon]="faStar"
                            [classes]="['fa-icon-size-14']"
                            (click)="dialog.close()">
                        </fa-icon>
                        Favorites
                    </span>
                    includes everyone you have Favorited.</li
                >
                <li
                    ><span class="fm-color-brand-1">
                        <fa-icon
                            [icon]="faClockEight"
                            [classes]="['fa-icon-size-14']"
                            (click)="dialog.close()">
                        </fa-icon>
                        Snoozed
                    </span>
                    includes people you have Snoozed.</li
                >
            </ul>
        </div>
    </div>
    <div class="std-modal-action-container">
        <button class="f-btn f-btn--primary f-btn-250" (click)="dialog.close()">Got it </button>

        <a
            class="f-link fm-mt-19px"
            style="text-decoration: none"
            target="_blank"
            href="http://support.focusmate.com/en/articles/6054574-the-people-page"
            (click)="learnMoreClicked()"
            >Learn more</a
        >
    </div>
</div>
