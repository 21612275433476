<div
    class="w-full p-[24px] sm:py-[12px] flex flex-row items-center gap-[4px] justify-start bg-white rounded-xl">
    <div class="w-full text-left text-blurple font-semibold text-[16px] font-avenir leading-normal"
        >👋 WELCOME CHECKLIST</div
    >

    <div class="flex flex-row items-center justify-center gap-[20px]">
        <fm-welcome-checklist-progress-indicator
            [steps]="steps"
            [activeStep]="activeStep"
            (stepClicked)="onStepClick($event)"
            class="text-[16px]"></fm-welcome-checklist-progress-indicator>
        <fa-icon
            class="sm:hidden text-[20px] text-blurple cursor-pointer"
            (click)="onCloseIconClick()"
            [icon]="faCircleChevronUp"></fa-icon>
        <fa-icon
            class="hidden sm:block text-[20px] text-grey-3 cursor-pointer"
            (click)="onCloseIconClick()"
            [icon]="faClose"></fa-icon>
    </div>
</div>
