<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title">Gender Preference</div>
    </div>

    <div class="std-modal-body w-full">
        <div class="f-form-field w-full field-spacer">
            <label style="margin-bottom: 7px">Match me with:</label>
            <mat-radio-group
                class="f-radio-group"
                [(ngModel)]="preferenceSelected"
                (change)="radioButtonChanged()">
                <mat-radio-button
                    disableRipple
                    class="f-radio-button"
                    *ngFor="let item of radioButtonInfo"
                    [value]="item.value">
                    {{ item.label }}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="f-hint fm-mt-20px w-full"
            >Your gender determines your matching options.
            <span class="f-link-effect fm-color-brand-1" (click)="updateGenders()"
                >({{ genderList }})</span
            ></div
        >

        <div class="std-modal-action-container">
            <app-spinner-button
                class="action"
                [disable]="disableSaveButton"
                [label]="'Save gender preference'"
                classes="f-btn f-btn--primary f-btn-250"
                (clicked)="submitForm()">
            </app-spinner-button>

            <div class="f-link fm-mt-15px" (click)="dialog.close()">Cancel</div>
            <app-gender-inclusion-blog class="w-full fm-mt-15px"></app-gender-inclusion-blog>
        </div>
    </div>
</div>
