import { Component, OnInit } from '@angular/core'
import { TermsService } from '@app/core/_services/terms.service'
import { MatDialogRef } from '@angular/material/dialog'
import {
    AnalyticsService,
    RECEIVED_ERROR_ACTION_TOS_AGREEMENT,
} from '@app/core/_services/analytics.service'
import { environment } from '@env/environment'
import { Subject, takeUntil } from 'rxjs'
import { UserService } from '@app/core/_services/user.service'

const MODAL_NAME = 'ToS Agreement: ' + environment.tosVersion || 'None'

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public env = environment

    constructor(
        private userService: UserService,
        private termsService: TermsService,
        private analyticsService: AnalyticsService,
        public dialogRef: MatDialogRef<TermsComponent>,
    ) {}

    ngOnInit() {
        this.analyticsService.logViewedModalEvent(MODAL_NAME)
        this.userService.isAuthenticated.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: (isAuthenticated) => this.closeOnLogout(isAuthenticated),
        })
    }

    private closeOnLogout(isAuthenticated: boolean): void {
        if (!isAuthenticated) {
            this.dialogRef.close()
        }
    }

    accept() {
        this.analyticsService.logClickedEvent('Accept', MODAL_NAME)
        this.termsService.sendTermsAgreement(environment.tosVersion || 'None').subscribe({
            next: (v) => this.handleAcceptTermsSuccess(v),
            error: (e) => this.handleAcceptTermsFailed(e),
        })
    }

    handleAcceptTermsSuccess(v) {
        this.userService.repopulate()
        this.dialogRef.close()
    }

    handleAcceptTermsFailed(e) {
        this.analyticsService.logReceivedError(RECEIVED_ERROR_ACTION_TOS_AGREEMENT, MODAL_NAME)
        this.dialogRef.close()
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
