<div class="diag-container">
    <mat-icon (click)="closeDialog()" class="diag-close icon-size-16">clear</mat-icon>
    <div class="diag-header">
        <div class="diag-header-text fm-mb-30px" style="margin-top: 49px; font-weight: 600">
            Oh no! There’s a scheduling conflict
        </div>
    </div>

    <div class="diag-body modal-body-v1">
        <div
            class="diag-sub-header-text text-left"
            style="margin-top: 30px; color: #2f3150; line-height: 19px">
            <p>{{
                data.conflicts.length > 1
                    ? 'You already have sessions booked:'
                    : 'You already have a session booked:'
            }}</p>
            <ul>
                <li *ngFor="let session of data.conflicts">{{ session }}</li>
            </ul>

            <p
                >You can either book a different time, or cancel your conflicting
                {{ data.conflicts.length > 1 ? 'sessions' : 'session' }} to book this time slot.</p
            >
        </div>

        <div class="customHrDiag w-full" style="margin-top: 12px; margin-bottom: 30px"></div>

        <div class="diag-action w-full" style="justify-content: space-around; margin-bottom: 49px">
            <button class="f-btn f-btn--primary action-btn" (click)="closeDialog()">Got it</button>
        </div>
    </div>
</div>
