import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '@env/environment'

import { ApiService } from '@app/core/_services/api.service'
import { map } from 'rxjs/operators'

enum OpCode {
    ACK_NO_SHOW = 0,
}

interface AccountManagementOperation {
    op_code: OpCode
}

@Injectable()
export class AccountManagementService {
    constructor(private apiService: ApiService) {}

    acknowledgeNoshow(): Observable<any> {
        let body: AccountManagementOperation = {
            op_code: OpCode.ACK_NO_SHOW,
        }

        return this.apiService.post(environment.api_url + 'account/', body).pipe(
            map((data) => {
                return data
            }),
        )
    }
}
