<div class="std-modal-container">
    <div class="std-modal-header">
        <div class="std-modal-title wrap-word-break">
            Meet your new partner, {{ data.partnerName }}!
        </div>
    </div>
    <div class="std-modal-body">
        <mat-card class="partner-card">
            <mat-card-header class="partner-card-header">
                <img
                    class="partner-img-container !rounded-full"
                    mat-card-avatar
                    mat-card-sm-image
                    (error)="failedImageLoad()"
                    [src]="data.partnerPhoto"
                    #image />
                <mat-card-title class="partner-name">{{ data.partnerDisplayName }} </mat-card-title>
                <mat-card-subtitle class="partner-sessions"
                    >Sessions: {{ data.completedSessions }}
                </mat-card-subtitle>
            </mat-card-header>
        </mat-card>
        <div class="modal-timer fm-pt-10px"
            >Starting in&nbsp;<app-fm-countdown
                [countdownTo]="countdownTo"
                countdownFormat="2"
                (onCountdownFinished)="handleCountdownFinished()"></app-fm-countdown
            >...
        </div>
    </div>
</div>
