import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { Observable, ReplaySubject, first } from 'rxjs'
import { ApiService } from './api.service'
import { ApplicationIntegration } from '../_models/integrations'

const INTEGRATIONS_URL = environment.api_url + 'oauth/integrations'

export interface GetIntegrationsResponse {
    integrations: ApplicationIntegration[]
    googleAccountEmail?: string
    gcalConnected?: boolean
}

@Injectable({
    providedIn: 'root',
})
export class UserIntegrationsService {
    constructor(private apiService: ApiService) {}

    getIntegrations(): Observable<GetIntegrationsResponse> {
        return this.apiService.get(INTEGRATIONS_URL).pipe(first())
    }

    unlinkIntegration(id: string): Observable<any> {
        const fullUrl = `${INTEGRATIONS_URL}/${id}`
        return this.apiService.delete(fullUrl, {}).pipe(first())
    }
}
