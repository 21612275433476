<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title wrap-double-line-el">
            You've reported {{ data.partnerName }}
        </div>
    </div>

    <div class="std-modal-body w-full">
        <div class="std-modal-body-text text-left wrap-word-break">
            <p
                >Thank you. You took on an extra burden by making this report, and we know that can
                be a hard choice to make. You’re helping us create a safer community for you and
                everyone else.</p
            >

            <p
                >We will review your report and take action within 48 hours. (And a copy has been
                sent to you.) We will email you if we need more information.</p
            >

            <p
                >If you’re currently in a session together and want to leave, click “End Session”.
                You can also block
                {{ data.partnerName }}
                from the Help dropdown.</p
            >
        </div>

        <div class="std-modal-action-container">
            <button class="f-btn f-btn--primary f-btn-250" (click)="dialog.close()">Got it</button>
        </div>
    </div>
</div>
