interface SessionType {
    sessionTime: number
}

export class Container<T extends SessionType> {
    private elements: Array<T>

    constructor() {
        this.elements = new Array<T>()
    }

    isEmpty(): boolean {
        if (this.elements.length === 0) {
            return true
        } else {
            return false
        }
    }

    clear(): void {
        let length: number = this.elements.length

        for (var i = 0; i < length; i++) {
            this.elements.pop()
        }
    }

    insert(item: T): boolean {
        var len: number = this.elements.length
        let inserted: boolean = false

        for (var i = 0; i < len; i++) {
            if (item.sessionTime < this.elements[i].sessionTime) {
                this.elements.splice(i, 0, item)
                inserted = true
                break
            } else if (item.sessionTime === this.elements[i].sessionTime) {
                this.elements.splice(i, 1, item)
                inserted = true
                break
            }
        }

        if (inserted === false) {
            this.elements.push(item)
        }

        return true
    }

    remove(item: T): boolean {
        var len: number = this.elements.length
        let removed: boolean = false

        for (var i = 0; i < len; i++) {
            if (item.sessionTime < this.elements[i].sessionTime) {
                break
            } else if (item.sessionTime === this.elements[i].sessionTime) {
                this.elements.splice(i, 1)
                removed = true
                break
            }
        }
        return removed
    }

    has(session_time: number) {
        var len: number = this.elements.length
        let found: boolean = false

        for (var i = 0; i < len; i++) {
            if (session_time < this.elements[i].sessionTime) {
                break
            } else if (session_time === this.elements[i].sessionTime) {
                found = true
                break
            }
        }
        return found
    }

    getBySessionTime(session_time: number): T {
        var len: number = this.elements.length

        for (var i = 0; i < len; i++) {
            if (session_time < this.elements[i].sessionTime) {
                break
            } else if (session_time === this.elements[i].sessionTime) {
                return this.elements[i]
            }
        }
        return null
    }

    length() {
        return this.elements.length
    }

    getByIndex(index: number) {
        if (this.elements.length <= index) {
            return null
        } else {
            return this.elements[index]
        }
    }

    getArray() {
        return this.elements
    }
}
