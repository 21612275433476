import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { User } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserService } from '@app/core/_services/user.service'
import { Subject, timer } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms'
import { TimeUtilitiesService } from '@app/core/_services/time-utilities.service'

@Component({
    selector: 'app-edit-timezone-modal',
    templateUrl: './edit-timezone-modal.component.html',
    styleUrls: ['./edit-timezone-modal.component.scss'],
})
export class EditTimezoneModalComponent implements OnInit {
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public currentUser: User
    public disableSaveButton = true
    public currentTime: string = ''
    faTimes = faTimes
    public showCurrentTime: boolean = false
    private timeoutId: any

    public initialSubmissionFailed: boolean = false

    public timezoneSelectionError: boolean = false
    public profileForm: UntypedFormGroup

    constructor(
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialogRef<EditTimezoneModalComponent>,
        public userService: UserService,
        private analyticsService: AnalyticsService,
        private dateFormat: UserDateFormattingService,
        private timeUtils: TimeUtilitiesService,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Update Timezone')
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.currentUser = user

            this.profileForm = this.formBuilder.group({
                timezone: new UntypedFormControl(user.timeZone, [Validators.required]),
            })

            this.profileForm.controls.timezone.valueChanges
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((value) => {
                    if (this.initialSubmissionFailed) {
                        this.verifyTimezoneSelection()
                    }
                })

            // update the displayed time every minute
            this.updateCurrentTime(this.currentUser.timeZone)
            const msToNextMinute = this.timeUtils.getMsUntilNextMinute()
            timer(msToNextMinute, 1000 * 60)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                    const tz = this.profileForm.controls.timezone.value
                    this.updateCurrentTime(tz)
                })
        })
    }

    checkEnableSaveNameButton(tz: string) {
        if (!tz) {
            this.disableSaveButton = true
        } else if (tz !== this.currentUser.timeZone) {
            this.disableSaveButton = false
        } else {
            this.disableSaveButton = true
        }
    }

    timezoneChanged(tz: string) {
        this.updateCurrentTime(tz)
        this.checkEnableSaveNameButton(tz)
    }

    onSubmit() {
        if (this.profileForm.valid) {
            this.currentUser.timeZone = this.profileForm.controls.timezone.value
            this.userService.update(this.currentUser, null).subscribe((res) => {
                this.analyticsService.logProfileEditedEvent(['timezone'])
                this.dialog.close()
            })
        } else {
            this.initialSubmissionFailed = true
            this.verifyTimezoneSelection()
        }
    }

    private verifyTimezoneSelection() {
        if (this.profileForm.controls.timezone.errors?.required) {
            this.timezoneSelectionError = true
        } else {
            this.timezoneSelectionError = false
        }
    }

    private updateCurrentTime(tz: string) {
        this.currentTime = this.dateFormat.formatNow('h:mm a', tz)
    }

    ngOnDestroy() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
