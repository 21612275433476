<div class="flex flex-col items-center relative box-border w-full h-full font-avenir">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="onCloseButtonClick()"></fa-icon>

    <!-- header -->
    <div
        class="pt-[50px] pb-[36px] sm:pt-[60px] sm:pb-[35px] px-[20px] sm:px-[100px] flex flex-col items-center justify-center gap-[20px]">
        <fa-icon [icon]="faGift" class="fa-2xl text-blurple"></fa-icon>
        <div class="text-center text-26-36 font-semibold">Help us grow Focusmate!</div>
    </div>

    <!-- body -->
    <div
        class="max-w-full px-[20px] sm:px-[100px] mb-[60px] flex flex-col items-center justify-center gap-[20px] text-14-19 font-medium text-bluegrey-1">
        <div>
            Share your referral link with a friend to give them a
            <span class="font-semibold">free month</span> of Focusmate Plus and earn a
            <span class="font-semibold">free month</span> when they upgrade!
        </div>

        <div class="w-full">
            <div class="font-medium"
                >Your referral link ({{ copyConfirmedTimeout ? 'copied!' : 'click to copy' }}):</div
            >
            <div class="w-full flex flex-col sm:flex-row gap-[8px] cursor-pointer">
                <div
                    (click)="confirmCopy()"
                    [copy-clipboard]="referralLink"
                    class="relative flex flex-col flex-grow justify-center h-[50px] bg-[#ececf5] p-[10px] text-blurple rounded-fm-5"
                    >{{ referralLink }}
                    <fa-icon
                        [icon]="faClone"
                        class="fa-lg text-blurple absolute right-[10px] mt-auto"></fa-icon>
                </div>
            </div>
        </div>

        <button class="f-btn f-btn--primary f-btn-250 mt-[20px]" (click)="onCloseButtonClick()"
            >Got it!</button
        >

        <div>
            <a
                class="text-blurple no-underline"
                target="_blank"
                href="https://support.focusmate.com/en/articles/8309216-referrals"
                >Learn more<fa-icon
                    [icon]="faExternalLink"
                    class="pl-[4px] text-blurple"></fa-icon></a
        ></div>
    </div>
</div>
