import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import {
    GENDER_MATCH_MEN_ONLY,
    GENDER_MATCH_NO_PREFERENCE,
    GENDER_MATCH_STRING_MEN_ONLY,
    GENDER_MATCH_STRING_NO_PREFERENCE,
    User,
} from '@app/core/_models'
import { UtilsService } from '@app/core/_services/utils.service'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { GenderService } from '@app/core/_services/gender.service'
import { UserService } from '@app/core/_services/user.service'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

interface RadioButtonInfo {
    value: number
    label: string
}

@Component({
    selector: 'app-gender-preference-man-modal',
    templateUrl: './gender-preference-man-modal.component.html',
    styleUrls: ['./gender-preference-man-modal.component.scss'],
})
export class GenderPreferenceManModalComponent implements OnInit {
    public radioButtonInfo: RadioButtonInfo[] = [
        {
            value: GENDER_MATCH_NO_PREFERENCE,
            label: GENDER_MATCH_STRING_NO_PREFERENCE,
        },
        {
            value: GENDER_MATCH_MEN_ONLY,
            label: GENDER_MATCH_STRING_MEN_ONLY,
        },
    ]

    public faTimes = faTimes
    public initialPreferenceSelected: number = 0
    public preferenceSelected: number = 0

    public disableSaveButton = true
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    private currentUser: User = new User()
    public genderList: string = ''

    constructor(
        public dialog: MatDialogRef<GenderPreferenceManModalComponent>,
        public userService: UserService,
        private genderService: GenderService,
        private utilsService: UtilsService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Gender Preference: Cis Man')
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.currentUser = user
            this.preferenceSelected = this.currentUser.genderPreference
            this.initialPreferenceSelected = this.preferenceSelected
            this.genderList = this.genderService.buildGenderList(
                user.genderSelection,
                user.gendersAdditional,
            )

            this.checkEnableSaveButton()
        })
    }

    radioButtonChanged() {
        this.checkEnableSaveButton()
    }

    checkEnableSaveButton(): void {
        this.disableSaveButton = this.preferenceSelected === this.initialPreferenceSelected
    }

    public submitForm() {
        this.currentUser.genderPreference = this.preferenceSelected
        this.userService.update(this.currentUser, null).subscribe(() => {
            this.dialog.close()
        })
    }

    updateGenders() {
        this.dialog.close(true)
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
