<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="closeSnoozeModal()">
    </fa-icon>
    <div class="std-modal-header" style="padding-bottom: 50px">
        <div class="std-modal-title wrap-double-line-el">Unnooze {{ data.displayName }}</div>
    </div>

    <div class="std-modal-body w-full">
        <div class="w-full flex-c-fs-c">
            <div class="f-bluegray-14-n-text fm-mb-10px"
                >Are you sure you want to unsnooze {{ data.displayName }}?</div
            >
        </div>
    </div>
    <div class="std-modal-action-container">
        <app-spinner-button
            class="action"
            [label]="'Unsnooze'"
            classes="f-btn f-btn--primary f-btn-250"
            (clicked)="unsnooze()">
        </app-spinner-button>

        <div class="f-link fm-mt-19px" (click)="closeSnoozeModal()">Cancel</div>
    </div>
</div>
