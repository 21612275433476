<div class="list-container">
    <div class="title-container flex-r-c-c fm-mb-15px">
        <div class="list-title text-center">People</div>
        <fa-icon
            class="type-info-icon"
            [icon]="faCircleInfo"
            [classes]="['fa-icon-size-14', 'fa-icon-color-grey']"
            (click)="launchInfoModal()"></fa-icon>
    </div>
    <div
        [ngClass]="{
            'md:flex-row md:justify-between': listSelection == 'favorites',
            'xsm:flex-row xsm:justify-between': listSelection != 'favorites'
        }"
        class="flex flex-col items-center justify-start w-full !px-4 !mb-2.5 gap-2">
        <app-fm-select
            [ngClass]="{
                'md:w-36 md:min-w-36': listSelection == 'favorites',
                'xsm:w-36 xsm:min-w-36': listSelection != 'favorites'
            }"
            class="w-full order-1"
            [optionsList]="listTypes"
            [currentValue]="listSelection"
            [fieldName]="'listType'"
            btnClasses="std-select-sm w-full"
            (selectionChanged)="updateListTypeSelection($event)">
        </app-fm-select>

        <button
            (click)="analyticsService.logClickedEvent('Favorites availability', 'peopleList')"
            routerLink="/availability/favorites"
            *ngIf="listSelection == 'favorites'"
            class="flex flex-row items-center justify-center h-[34px] border-2 border-[##DADAED] rounded-fm-5 bg-white hover:bg-[#F2F3FE] text-sm font-avenir text-blurple font-medium !px-3 md:w-auto w-full md:order-2 order-last hover:border-[#B4B4E1]"
            ><fa-icon class="!mr-1.5 !mb-0.5" [icon]="faCalendarStar"></fa-icon>Favorites
            availability</button
        >

        <app-fm-select
            [ngClass]="{
                'md:w-56 md:min-w-56': listSelection == 'favorites',
                'xsm:w-56 xsm:min-w-56': listSelection != 'favorites'
            }"
            class="w-full order-3"
            [optionsList]="sortByOptions"
            [currentValue]="sortBySelection"
            [fieldName]="'sortByOptions'"
            btnClasses="std-select-sm w-full"
            (selectionChanged)="updateSortBySelection($event)"></app-fm-select>
    </div>
    <!-- <perfect-scrollbar> -->
    <ng-scrollbar class="w-full flex flex-col items-center justify-start">
        <div *ngIf="people.length > 0; else emptyList" class="list-body">
            <div *ngFor="let person of people">
                <app-people-list-item
                    [userId]="person.userId"
                    [displayName]="person.displayName"
                    [profileUrl]="person.profileUrl"
                    [profileImage]="person.profilePhoto"
                    [snoozed]="person.snoozed"
                    [expiresAt]="person.expiresAt"
                    [blockedStatus]="person.blocked"
                    [saved]="person.saved"
                    [mostRecentSessionTime]="person.mostRecentSession"
                    [sharedSessionCount]="person.sharedSessionCount"
                    [sharedAvailability]="person.sharedAvailability">
                </app-people-list-item>
            </div>
        </div>
        <ng-template #emptyList>
            <div *ngIf="!isLoadingList" class="empty-list list-body">
                <fa-icon [icon]="faGhost" [classes]="['fa-icon-size-14']"></fa-icon>
                Nothing to show here...
            </div>
        </ng-template>
    </ng-scrollbar>
    <!-- </perfect-scrollbar> -->

    <mat-paginator
        class="snooze-paginator"
        *ngIf="people.length > 0"
        #paginator
        [length]="peopleCount"
        [pageSize]="pageSize"
        [pageIndex]="page - 1"
        hidePageSize="true"
        (page)="pageEventOccured($event)">
    </mat-paginator>
</div>
