<div class="relative box-border w-full h-full flex flex-col items-center">
    <!-- header -->
    <div
        class="max-w-[100%] py-[36px] sm:pt-[50px] sm:pb-[35px] px-[30px] flex items-center justify-center">
        <div
            class="text-blurple text-center font-avenir text-26-36 font-semibold max-w-[430px] line-clamp-3">
            Welcome, {{ userName }}! 👋
        </div>
    </div>

    <!-- body -->
    <div
        class="px-[30px] sm:p-0 sm:w-[400px] flex flex-col items-center justify-center gap-[12px] sm:gap-[24px]">
        <img src="assets/images/welcome-checklist/welcome-image.webp" />
        <div class="w-full text-center font-avenir text-14-19 font-medium text-bluegrey-2">
            Focusmate is a global community of friendly, supportive people who can help you get
            anything done.
        </div>
        <div class="w-full text-center font-avenir text-14-19 font-medium text-bluegrey-2">
            Let's get you set up for maximum focus in 4 quick steps!
        </div>
    </div>

    <!-- bottom -->
    <div
        class="w-full sm:w-[400px] pt-[35px] pb-[50px] px-[30px] sm:px-0 flex items-center justify-center">
        <button class="f-btn f-btn--primary w-full" (click)="onNextStepButtonClick()"
            >Start Welcome Checklist <fa-icon class="pl-[6px]" [icon]="faArrowRight"></fa-icon
        ></button>
    </div>
</div>
