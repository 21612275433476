import { ApiService } from '@app/core/_services/api.service'
import { Injectable } from '@angular/core'
import { ContactFormSubmission } from '@app/core/_models'
import { environment } from '@env/environment'

@Injectable()
export class ContactService {
    constructor(private apiService: ApiService) {}

    submitContact(submission: ContactFormSubmission) {
        return this.apiService.post(environment.api_url + 'contact/', submission).toPromise()
    }
}
