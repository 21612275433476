<button
    appFmDebounceClick
    type="submit"
    [ngClass]="classes"
    [disabled]="disabled || notValid"
    (debounceClick)="buttonPressed()">
    <div *ngIf="icon == ''">{{ !disabled ? label : loadingLabel }}</div>
    <div *ngIf="icon != ''" class="fm-btn-with-icon-label">
        <div class="fm-btn-with-icon-label-left">{{ !disabled ? label : loadingLabel }}</div>
        <mat-icon *ngIf="!disabled">{{ icon }}</mat-icon>
    </div>

    <div
        *ngIf="disabled"
        [style.margin-left]="loadingLabel == '' ? '' : '20px'"
        [ngClass]="!invert ? 'btn-spinner' : 'btn-spinner-invert'"></div>
</button>
