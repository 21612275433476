<div class="relative box-border w-full h-full font-avenir">
    <fa-icon
        class="hidden sm:block text-[20px] text-grey-3 cursor-pointer absolute top-[20px] right-[28px]"
        (click)="onCloseIconClick()"
        [icon]="faClose"></fa-icon>

    <!-- header -->
    <div class="flex items-center justify-center py-[36px] sm:pb-[24px] sm:pt-[50px] px-[30px]">
        <div class="text-blurple text-center text-26-36 font-semibold">
            Get ready for your first session!
        </div>
    </div>

    <!-- body -->
    <div
        class="px-[30px] sm:px-[100px] flex flex-col gap-[19px] text-14-19 text-bluegrey-2 text-left font-medium">
        <div>
            Sometimes booking can be the hardest part. Now let's get ready for your session:
        </div>
        <ul class="text-left m-0">
            <li
                >Test your audio/video in a
                <a (click)="testSessionLinkClick()" [href]="testSessionUrl" target="_blank">
                    test session</a
                ></li
            >
            <li>
                @if (!isHowItWorksStepComplete && !isReviewCommunityGuidelinesStepComplete) {
                    Review two quick steps to ensure a successful session
                } @else if (!isHowItWorksStepComplete && isReviewCommunityGuidelinesStepComplete) {
                    Review how it works
                } @else if (isHowItWorksStepComplete && !isReviewCommunityGuidelinesStepComplete) {
                    Review Community Guidelines
                }
            </li>
            <li>Join the session up to 10 minutes before the starting time</li>
        </ul>
        <div> Finish the session to complete your Welcome Checklist! </div>
    </div>

    <!-- bottom -->
    <div
        class="w-full pt-[35px] pb-[50px] px-[30px] sm:pb-[60px] sm:px-[100px] flex flex-col items-center justify-center">
        <button class="f-btn f-btn--primary w-full" (click)="onNextStepButtonClick()">
            @if (!isHowItWorksStepComplete && !isReviewCommunityGuidelinesStepComplete) {
                Review steps
            } @else if (!isHowItWorksStepComplete && isReviewCommunityGuidelinesStepComplete) {
                Review how it works
            } @else if (isHowItWorksStepComplete && !isReviewCommunityGuidelinesStepComplete) {
                Review Community Guidelines
            }
            <fa-icon class="pl-[6px]" [icon]="faArrowRight"></fa-icon>
        </button>
    </div>
</div>
