<div class="fm-fading-circle" [ngClass]="sizeClass">
    <div class="fm-circle1" [ngClass]="colorClass"></div>
    <div class="fm-circle2" [ngClass]="colorClass"></div>
    <div class="fm-circle3" [ngClass]="colorClass"></div>
    <div class="fm-circle4" [ngClass]="colorClass"></div>
    <div class="fm-circle5" [ngClass]="colorClass"></div>
    <div class="fm-circle6" [ngClass]="colorClass"></div>
    <div class="fm-circle7" [ngClass]="colorClass"></div>
    <div class="fm-circle8" [ngClass]="colorClass"></div>
    <div class="fm-circle9" [ngClass]="colorClass"></div>
    <div class="fm-circle10" [ngClass]="colorClass"></div>
    <div class="fm-circle11" [ngClass]="colorClass"></div>
    <div class="fm-circle12" [ngClass]="colorClass"></div>
</div>
