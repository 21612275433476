import { Component, HostListener, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import {
    MAX_SET_TIMEOUT_VALUE,
    TimeUtilitiesService,
} from '@app/core/_services/time-utilities.service'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'

@Component({
    selector: 'app-fm-datetime-picker',
    templateUrl: './fm-datetime-picker.component.html',
    styleUrls: ['./fm-datetime-picker.component.scss'],
})
export class FmDatetimePickerComponent implements OnInit {
    @HostListener('window:focus', ['$event'])
    onFocus(event: any): void {
        this.clearTimer()
        this.monitorSelectedTimeForValidity()
    }
    @HostListener('window:blur', ['$event'])
    onBlur(event: any): void {
        this.clearTimer()
    }
    public selected: moment = moment()
    public timeString: string | null
    public timeSlotValues = this.dateFormat.generateTimeSlotsForDropDownMenu(15)
    public minDate: moment = moment()
    public isTimeValid: boolean = true
    public faInfoCircle = faInfoCircle
    private timerId: any
    public panelClasses = ['f-select', 'f-datetime-time-select']

    constructor(
        private dateFormat: UserDateFormattingService,
        public dialog: MatDialogRef<FmDatetimePickerComponent>,
    ) {}

    ngOnInit(): void {
        this.minDate = moment()
        this.selected = moment()
        this.timeString = this.determineFirstAvailableTimeSlot()
        this.monitorSelectedTimeForValidity()
    }

    private determineFirstAvailableTimeSlot(): string {
        let now = moment()
        now = now.seconds(0)
        now = now.milliseconds(0)
        let minute: number = now.minutes()
        let hour: number = now.hours()

        if (now.minutes() >= 45) {
            if (now.hours() === 23) {
                this.selected.add(1, 'd')
                hour = 0
            }
            minute = 0
        } else if (now.minutes() >= 30) {
            minute = 45
        } else if (now.minutes() >= 15) {
            minute = 30
        } else {
            minute = 15
        }
        return `${hour}:${minute}`
    }

    selectedTimeSlot(e) {
        this.timeString = e
        this.monitorSelectedTimeForValidity()
    }

    combineDateTime(): moment {
        let tempDate = this.selected.clone()
        const time = this.timeString.split(':')
        tempDate.hours(time[0])
        tempDate.minutes(time[1])
        tempDate.seconds(0)
        tempDate.milliseconds(0)
        return tempDate
    }

    private monitorSelectedTimeForValidity() {
        const tempDate = this.combineDateTime()
        const now = moment()
        const diffInMilliSeconds = Math.floor(
            moment.duration(tempDate.diff(now)).as('milliseconds'),
        )

        if (diffInMilliSeconds > 0) {
            this.isTimeValid = true
            this.clearTimer()

            if (diffInMilliSeconds < MAX_SET_TIMEOUT_VALUE) {
                this.timerId = setTimeout(() => {
                    this.monitorSelectedTimeForValidity()
                }, diffInMilliSeconds)
            }
        } else {
            this.isTimeValid = false
        }
    }

    clearTimer() {
        if (this.timerId) {
            clearTimeout(this.timerId)
            this.timerId = null
        }
    }

    dateTimeSelected() {
        this.dialog.close(this.combineDateTime())
    }

    dateChanged(e) {
        this.monitorSelectedTimeForValidity()
    }

    ngOnDestroy() {
        this.clearTimer()
    }
}
