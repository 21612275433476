<div class="person-container">
    <div class="person-wrapper">
        <app-profile-image
            class="profile-image-container"
            [snoozed]="snoozed"
            [saved]="saved"
            [profileImage]="profileImage"
            [profileUrl]="profileUrl"
            size="medium">
        </app-profile-image>
        <div class="person-info-wrapper">
            <a
                class="person-name wrap-double-line-el"
                style="cursor: pointer; text-decoration: none"
                [routerLink]="'/user/' + profileUrl"
                target="_blank"
                >{{ displayName }}</a
            >
            <div *ngIf="snoozed" class="until">Snoozed {{ snoozedUntil }}</div>
            <div
                *ngIf="lastTimeWorkedWith && !snoozed"
                class="until"
                [matTooltip]="lastTimeTooltip"
                matTooltipPosition="below"
                matTooltipShowDelay="100"
                matTooltipClass="f-tooltip">
                {{ sharedSessionCount }}
                {{ sharedSessionCount > 1 ? 'sessions' : 'session' }} together ·
                {{ lastTimeWorkedWith }}
            </div>
            <div *ngIf="!lastTimeWorkedWith && !snoozed" class="until"> Haven’t met yet </div>
        </div>

        <div class="action-container">
            <app-toggle-save
                class="fm-mb-2px"
                [classes]="['icon-size-18']"
                [saved]="saved"
                [targetUserId]="userId"
                source="peopleList">
            </app-toggle-save>
            <app-user-actions-menu
                classes="svg-user-action-menu-grey"
                [showReportProfile]="true"
                [showUnsnooze]="snoozed"
                [showSnooze]="!snoozed"
                [showBlock]="true"
                [showUnblock]="true"
                [timeZone]="(userService.currentUser | async).timeZone"
                [targetUserDisplayName]="displayName"
                [targetUserId]="userId"
                [targetUserProfileUrl]="profileUrl"
                [targetUserPhotoUrl]="profileImage"
                [status]="isUserBlocked"
                [source]="'peopleList'"
                [snoozeStatus]="snoozed"
                [snoozeExpiresAt]="expiresAt"
                [saved]="saved"
                [sharedAvailability]="sharedAvailability"
                [showSeeAvailability]="true"
                (onUserAction)="userActionTaken($event)">
            </app-user-actions-menu>
        </div>
    </div>
</div>
