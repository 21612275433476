<div class="w-full h-full font-sans text-blurple-light relative">
    <fa-icon
        class="std-modal-close text-[15px] text-blurple-light z-[100]"
        [icon]="faTimes"
        (click)="dialog.close()">
    </fa-icon>
    @switch (currentStep) {
        @case ('noStatsAvailable') {
            <div
                class="w-full h-full flex flex-col justify-end items-center gap-4 bg-[linear-gradient(180deg,_#D3DEF3_28.48%,_#E5ECE7_85.35%)]">
                <div
                    class="absolute bg-hourglass-pattern w-full h-full flex flex-col justify-start items-center">
                    <img src="../assets/images/year-in-review/modal-clock.png" />
                </div>

                <div class="flex flex-col gap-4 justify-end items-center text-center z-50 p-4">
                    <div class="text-[28px] leading-[85.2%] text-blurple-light font-black"
                        >YEAR IN FOCUS <span class="text-white"> 2023</span></div
                    >
                    <div
                        class="text-[18px] leading-[21.6px] text-blurple-light font-normal max-w-[315px]">
                        You didn’t have enough sessions in 2023 to get your Year in Focus.<br /><br />Complete
                        some sessions and we’ll send you your Year in Focus 2024!
                    </div>
                </div>
                <div
                    class="px-20 min-[600px]:pt-10 pt-[60px] min-[600px]:pb-[60px] pb-[30px] flex flex-col justify-start items-center z-50 gap-[15px]">
                    <button (click)="openBookSessionModal()" class="share-button"
                        >Book session</button
                    >
                </div>
            </div>
        }
        @case ('initial') {
            <div
                class="w-full h-full flex flex-col justify-end items-center gap-4 bg-[linear-gradient(180deg,_#E0D6EC_28.48%,_#D3DEF3_85.35%)]">
                <div
                    class="absolute bg-confetti-pattern w-full h-full flex flex-col justify-start items-center">
                    <img
                        class="h-[278px] w-[239px]"
                        src="../assets/images/year-in-review/modal-pendant-lamp.png" />
                </div>
                <div class="font-black text-[54px] leading-[82.5%] text-center z-50 p-4">
                    YEAR<br />IN<br />FOCUS<br /><span class="text-white">2023</span>
                </div>

                <div
                    class="px-20 min-[600px]:pt-5 pt-10 min-[600px]:pb-[60px] pb-[30px] flex flex-col justify-start items-center z-50 gap-[15px]">
                    <button (click)="nextSelected()" class="share-button"
                        ><fa-icon class="mr-1" [icon]="faArrowRight"></fa-icon>Start</button
                    >
                    <div class="min-h-[19px]"></div>
                </div>
            </div>
        }
        @case ('sessionStats') {
            <div
                class="w-full h-full flex flex-col justify-end items-center gap-4 bg-[linear-gradient(180deg,_#D3DEF3_28.48%,_#E5ECE7_85.35%)]">
                <div
                    class="absolute bg-hourglass-pattern w-full h-full flex flex-col justify-start items-center">
                    <img src="../assets/images/year-in-review/modal-clock.png" />
                </div>

                <div class="flex flex-col gap-4 justify-end items-center text-center z-50 p-4">
                    <div class="text-xl leading-5 text-blurple-light font-normal"
                        >you focused for</div
                    >
                    <div class="text-[28px] leading-7 text-blurple-light font-semibold"
                        >{{ totalMinutes.toLocaleString('en-US') }} minutes</div
                    >
                    <div class="text-xl leading-5 text-blurple-light font-normal">in</div>
                    <div class="text-[28px] leading-7 text-blurple-light font-semibold">
                        {{ totalSessions.toLocaleString('en-US') }}
                        {{ totalSessions > 1 ? 'sessions' : 'session' }}
                    </div>
                </div>
                <div
                    class="px-20 min-[600px]:pt-5 pt-10 min-[600px]:pb-[60px] pb-[30px] flex flex-col justify-start items-center z-50 gap-[15px]">
                    <button (click)="nextSelected()" class="share-button"
                        ><fa-icon class="mr-1" [icon]="faArrowRight"></fa-icon>Next</button
                    >
                    <div
                        class="text-14-19 cursor-pointer font-semibold"
                        (click)="previousSelected()"
                        ><fa-icon class="mr-1" [icon]="faArrowLeft"> </fa-icon>Previous</div
                    >
                </div>
            </div>
        }
        @case ('partnerStats') {
            <div
                class="w-full h-full flex flex-col justify-end items-center gap-4 bg-[linear-gradient(180deg,_#D3DEF3_28.48%,_#E5ECE7_85.35%)]">
                <div
                    class="absolute bg-location-pattern w-full h-full flex flex-col justify-start items-center">
                    <img src="../assets/images/year-in-review/modal-globe.png" />
                </div>

                <div class="flex flex-col gap-4 justify-end items-center text-center z-50 p-4">
                    <div class="text-xl leading-5 text-blurple-light font-normal">with</div>
                    <div class="text-[28px] leading-7 text-blurple-light font-semibold"
                        >{{ totalPartners.toLocaleString('en-US') }}
                        {{ totalPartners > 1 ? 'partners' : 'partner' }}</div
                    >
                    <div class="text-xl leading-5 text-blurple-light font-normal"
                        >who joined from</div
                    >
                    <div class="text-[28px] leading-7 text-blurple-light font-semibold"
                        >{{ totalCountries.toLocaleString('en-US') }}
                        {{ totalCountries > 1 ? 'countries' : 'country' }}</div
                    >
                </div>
                <div
                    class="px-20 min-[600px]:pt-5 pt-10 min-[600px]:pb-[60px] pb-[30px] flex flex-col justify-start items-center z-50 gap-[15px]">
                    <button (click)="nextSelected()" class="share-button"
                        ><fa-icon class="mr-1" [icon]="faArrowRight"></fa-icon>Next</button
                    >
                    <div
                        class="text-14-19 cursor-pointer font-semibold"
                        (click)="previousSelected()"
                        ><fa-icon class="mr-1" [icon]="faArrowLeft"> </fa-icon>Previous</div
                    >
                </div>
            </div>
        }
        @case ('userCategory') {
            <div
                class="w-full h-full flex flex-col justify-end items-center gap-4"
                [ngClass]="usageCategoryContentAndStyles.get(sessionTimeCategory).modalGradiant">
                <div
                    class="absolute w-full h-full flex flex-col justify-start items-center"
                    [ngClass]="usageCategoryContentAndStyles.get(sessionTimeCategory).modalPattern">
                </div>
                <div class="flex flex-col gap-4 justify-end items-center text-center z-50 px-10">
                    <img
                        [src]="
                            usageCategoryContentAndStyles.get(sessionTimeCategory).imageLocation
                        " />
                    <div
                        class="text-[18px] leading-[18px] text-blurple-light font-normal max-w-[350px]"
                        >{{
                            usageCategoryContentAndStyles.get(sessionTimeCategory).description
                        }}</div
                    >
                    <div class="text-[28px] leading-7 text-blurple-light font-semibold">{{
                        usageCategoryContentAndStyles.get(sessionTimeCategory).title
                    }}</div>
                </div>
                <div
                    class="px-20 min-[600px]:pt-5 pt-10 min-[600px]:pb-[60px] pb-[30px] flex flex-col justify-start items-center z-50 gap-[15px]">
                    <button (click)="nextSelected()" class="share-button"
                        ><fa-icon class="mr-1" [icon]="faArrowRight"></fa-icon>Summary</button
                    >
                    <div
                        class="text-14-19 cursor-pointer font-semibold"
                        (click)="previousSelected()"
                        ><fa-icon class="mr-1" [icon]="faArrowLeft"> </fa-icon>Previous</div
                    >
                </div>
            </div>
        }

        @case ('summary') {
            <div
                class="w-full h-full flex flex-col justify-end items-center gap-4"
                [ngClass]="usageCategoryContentAndStyles.get(sessionTimeCategory).summaryGradiant">
                <div
                    class="absolute w-full h-full flex flex-col justify-start items-center"
                    [ngClass]="
                        usageCategoryContentAndStyles.get(sessionTimeCategory).summaryPattern
                    ">
                </div>

                <div
                    class="flex flex-col justify-end items-center gap-2 w-[330px] max-w-[100%] z-50">
                    <!-- Title Pill -->
                    <div
                        class="bg-white w-full rounded-xl text-center text-[16px] leading-[85.2%] font-black px-4 py-2">
                        YEAR IN FOCUS 2023
                    </div>
                    <!-- Total focus stats -->
                    <div
                        class="flex flex-col bg-white w-full rounded-xl text-center text-[16px] leading-[85.2%] px-2 py-1.5 gap-0.5">
                        <div class="text-[12px] leading-[12px] text-blurple-light font-normal"
                            >you focused for</div
                        >
                        <div class="text-[18px] leading-[20px] text-blurple-light font-semibold"
                            >{{ totalMinutes.toLocaleString('en-US') }} minutes</div
                        >
                        <div class="text-[12px] leading-[12px] text-blurple-light font-normal"
                            >in {{ totalSessions.toLocaleString('en-US') }}
                            {{ totalSessions > 1 ? 'sessions' : 'session' }}</div
                        >
                    </div>

                    <!-- Partners and Countries -->
                    <div class="flex flex-row gap-2 justify-between items-center w-full">
                        <div
                            class="flex flex-col bg-white w-full rounded-xl text-center px-2 py-1.5 gap-0.5">
                            <div class="text-[12px] leading-[12px] text-blurple-light font-normal"
                                >with</div
                            >
                            <div
                                class="text-[18px] leading-[20px] text-blurple-light font-semibold"
                                >{{ totalPartners.toLocaleString('en-US') }}</div
                            >
                            <div
                                class="text-[12px] leading-[12px] text-blurple-light font-normal"
                                >{{ totalPartners > 1 ? 'partners' : 'partner' }}</div
                            >
                        </div>

                        <div
                            class="flex flex-col bg-white w-full rounded-xl text-center px-2 py-1.5 gap-0.5">
                            <div class="text-[12px] leading-[12px] text-blurple-light font-normal"
                                >who joined from</div
                            >
                            <div
                                class="text-[18px] leading-[20px] text-blurple-light font-semibold"
                                >{{ totalCountries.toLocaleString('en-US') }}</div
                            >
                            <div
                                class="text-[12px] leading-[12px] text-blurple-light font-normal"
                                >{{ totalCountries > 1 ? 'countries' : 'country' }}</div
                            >
                        </div>
                    </div>

                    <!-- Image -->
                    <div class="w-full h-[219px] rounded-lg overflow-hidden">
                        <img
                            class="w-full h-full object-cover"
                            [src]="
                                usageCategoryContentAndStyles.get(sessionTimeCategory)
                                    .summaryImagLocation
                            " />
                    </div>

                    <!-- Focusmate logo -->
                    <div
                        class="flex flex-row bg-white w-full rounded-xl justify-between items-center px-4 py-0.5">
                        <div class="text-blurple-light font-avenir leading-normal"
                            ><span class="font-bold text-[13.397px]">Focus</span
                            ><span class="font-normal text-[13.933px]">mate</span></div
                        >
                        <div class="text-[12px] font-normal">focusmate.com</div>
                    </div>
                </div>
                <div
                    class="px-20 min-[600px]:pt-5 pt-10 min-[600px]:pb-[60px] pb-[30px] flex flex-col justify-start items-center z-50 gap-[15px]">
                    @if (shareableImageBlob !== null) {
                        <button (click)="onClickShareButton(1)" class="share-button"
                            ><fa-icon class="mr-1" [icon]="faShareNodes"></fa-icon> Share</button
                        >
                    }
                    <div
                        class="text-14-19 cursor-pointer font-semibold"
                        (click)="previousSelected()"
                        ><fa-icon class="mr-1" [icon]="faArrowLeft"> </fa-icon>Previous</div
                    >
                </div>
            </div>
        }
        @case ('download') {
            <div
                class="w-full h-full flex flex-col justify-end items-center gap-4"
                [ngClass]="usageCategoryContentAndStyles.get(sessionTimeCategory).summaryGradiant">
                <div
                    class="absolute w-full h-full flex flex-col justify-start items-center"
                    [ngClass]="
                        usageCategoryContentAndStyles.get(sessionTimeCategory).summaryPattern
                    ">
                </div>

                <div
                    class="flex flex-col justify-end items-center gap-2 w-[330px] max-w-[100%] z-50 download-effect">
                    <!-- Title Pill -->
                    <div
                        class="bg-white w-full rounded-xl text-center text-[16px] leading-[85.2%] font-black px-4 py-2">
                        YEAR IN FOCUS 2023
                    </div>
                    <!-- Total focus stats -->
                    <div
                        class="flex flex-col bg-white w-full rounded-xl text-center text-[16px] leading-[85.2%] px-2 py-1.5 gap-0.5">
                        <div class="text-[12px] leading-[12px] text-blurple-light font-normal"
                            >I focused for</div
                        >
                        <div class="text-[18px] leading-[20px] text-blurple-light font-semibold"
                            >{{ totalMinutes.toLocaleString('en-US') }} minutes</div
                        >
                        <div class="text-[12px] leading-[12px] text-blurple-light font-normal"
                            >in {{ totalSessions.toLocaleString('en-US') }}
                            {{ totalSessions > 1 ? 'sessions' : 'session' }}</div
                        >
                    </div>

                    <!-- Partners and Countries -->
                    <div class="flex flex-row gap-2 justify-between items-center w-full">
                        <div
                            class="flex flex-col bg-white w-full rounded-xl text-center px-2 py-1.5 gap-0.5">
                            <div class="text-[12px] leading-[12px] text-blurple-light font-normal"
                                >with</div
                            >
                            <div
                                class="text-[18px] leading-[20px] text-blurple-light font-semibold"
                                >{{ totalPartners.toLocaleString('en-US') }}</div
                            >
                            <div
                                class="text-[12px] leading-[12px] text-blurple-light font-normal"
                                >{{ totalPartners > 1 ? 'partners' : 'partner' }}</div
                            >
                        </div>

                        <div
                            class="flex flex-col bg-white w-full rounded-xl text-center px-2 py-1.5 gap-0.5">
                            <div class="text-[12px] leading-[12px] text-blurple-light font-normal"
                                >who joined from</div
                            >
                            <div
                                class="text-[18px] leading-[20px] text-blurple-light font-semibold"
                                >{{ totalCountries.toLocaleString('en-US') }}</div
                            >
                            <div
                                class="text-[12px] leading-[12px] text-blurple-light font-normal"
                                >{{ totalCountries > 1 ? 'countries' : 'country' }}</div
                            >
                        </div>
                    </div>

                    <!-- Image -->
                    <div class="w-full h-[195px] rounded-lg overflow-hidden">
                        <img
                            class="w-full h-full object-cover"
                            [src]="
                                usageCategoryContentAndStyles.get(sessionTimeCategory)
                                    .downloadImageLocation
                            " />
                    </div>

                    <!-- Focusmate logo -->
                    <div
                        class="flex flex-row bg-white w-full rounded-xl justify-between items-center px-4 py-0.5">
                        <div class="text-blurple-light font-avenir leading-normal"
                            ><span class="font-bold text-[13.397px]">Focus</span
                            ><span class="font-normal text-[13.933px]">mate</span></div
                        >
                        <div class="text-[12px] font-normal">focusmate.com</div>
                    </div>
                </div>
                <div
                    class="px-20 min-[600px]:pt-5 pt-10 min-[600px]:pb-[60px] pb-[30px] flex flex-col justify-start items-center z-50 gap-[15px]">
                    <button (click)="onClickDownloadButton()" class="share-button"
                        ><fa-icon class="mr-1" [icon]="faDownload"></fa-icon> Download</button
                    >

                    <div
                        class="text-14-19 cursor-pointer font-semibold"
                        (click)="previousSelected()"
                        ><fa-icon class="mr-1" [icon]="faArrowLeft"> </fa-icon>Previous</div
                    >
                </div>
            </div>
        }
    }
</div>
