<div class="fm-d-container">
    <mat-icon (click)="closeDialog()" class="dialog-close icon-size-16">clear</mat-icon>

    <!-- HEADER -->
    <div class="fm-d-header" style="min-height: 103px">
        <div *ngIf="editEnable == false" class="session-title" (click)="toggleEdit()">
            {{ title ? title : 'Focusmate Session' }}

            <div class="fm-icon-btn">
                <mat-icon style="opacity: 0.6" class="size-12">edit</mat-icon>
            </div>
        </div>

        <mat-form-field *ngIf="editEnable == true" class="session-title-edit w-full">
            <input
                class="title-input"
                maxlength="100"
                (keydown.enter)="saveEdit()"
                (keydown.esc)="cancelEdit($event)"
                matInput
                type="text"
                [(ngModel)]="title"
                name="title"
                required
                appFmAutofocus />
            <div class="fm-icon-btn save-title" matSuffix aria-label="Save" (click)="saveEdit()">
                <mat-icon class="size-12">keyboard_return</mat-icon>
            </div>
        </mat-form-field>
        <div class="private-title">
            <mat-icon style="opacity: 0.5" class="size-12">lock</mat-icon>
            The session's title is only visible to you.
        </div>
    </div>

    <div class="fm-d-body w-full">
        <div class="flex flex-row items-center justify-between !my-4 w-full">
            <div class="time-info-item">
                <div class="time-label !mb-2">DATE</div>
                <div class="time-value">{{ displayDate }}</div>
            </div>
            <div class="time-info-item">
                <div class="time-label !mb-2">TIME</div>
                <div class="time-value">{{ displayTime }}</div>
            </div>
            <div class="time-info-item">
                <div class="time-label !mb-2">LENGTH</div>
                <div class="time-value">{{ displayLength }}</div>
            </div>
        </div>

        <!-- Freqency Settings -->
        <div class="labels w-full !mb-2">
            <div class="label">FREQUENCY</div>
        </div>
        <div
            *ngIf="!weeklySelected && !dailySelected && !bookRecurComplete"
            class="labels w-full justify-between h-12">
            <button
                (click)="dailySelected = !dailySelected"
                class="fm-btn fm-btn--brand-1-light rpt-btn">
                Repeat daily
            </button>
            <button
                (click)="weeklySelected = !weeklySelected"
                class="fm-btn fm-btn--brand-1-light rpt-btn">
                Repeat weekly
            </button>
        </div>
        <div *ngIf="dailySelected" class="flex-r-bw-c w-full h-12">
            <div class="rpt-label">Repeat Daily</div>
            <mat-select
                class="selectpicker fm-selectpicker"
                [(ngModel)]="numSessions"
                name="numSessions"
                required>
                <mat-option
                    [disabled]="limitRemaining < time.value"
                    *ngFor="let time of recurTimes"
                    [value]="time.value">
                    {{ time.viewValue }}
                </mat-option>
            </mat-select>
            <app-loading-button
                classList="fm-btn"
                label="Save"
                loadingLabel=""
                [notValid]="false"
                (clicked)="bookRecurringSessions(2)"></app-loading-button>
            <div class="fm-icon-btn" (click)="dailySelected = false">
                <mat-icon class="size-14">clear</mat-icon>
            </div>
        </div>
        <div *ngIf="weeklySelected" class="flex-r-bw-c w-full h-12">
            <div class="rpt-label">Repeat Weekly</div>
            <mat-select
                class="selectpicker fm-selectpicker"
                [(ngModel)]="numSessions"
                name="numSessions"
                required>
                <mat-option
                    [disabled]="limitRemaining < time.value"
                    *ngFor="let time of recurTimes"
                    [value]="time.value">
                    {{ time.viewValue }}
                </mat-option>
            </mat-select>
            <app-loading-button
                classList="fm-btn"
                label="Save"
                loadingLabel=""
                [notValid]="false"
                (clicked)="bookRecurringSessions(3)"></app-loading-button>
            <div class="fm-icon-btn" (click)="weeklySelected = false">
                <mat-icon class="size-14">clear</mat-icon>
            </div>
        </div>
        <div *ngIf="bookRecurComplete" class="flex-r-bw-c w-full h-12">
            <div class="recur-success">
                <div class="recur-text">{{ recurSuccess }} ✔️</div>
                <div class="fm-icon-btn" (click)="bookRecurComplete = false">
                    <mat-icon class="size-14">clear</mat-icon>
                </div>
            </div>
        </div>

        <div class="w-full bg-white rounded-xl p-3 mb-3">
            <div class="w-full flex flex-row items-center justify-between">
                <div class="text-blurple font-semibold text-12-16 font-avenir leading-5 uppercase"
                    ><span class="cursor-pointer" (click)="expandSettings()">
                        Session Settings</span
                    >
                    <fa-icon
                        (click)="openInfoModal()"
                        class="text-[#808080] text-12-16 cursor-pointer ml-1"
                        [icon]="faInfoCircle"></fa-icon></div
                ><div class="cursor-pointer text-14-19" (click)="expandSettings()">
                    @if (showSettings) {
                        <fa-icon
                            class="text=[12px] text-blurple"
                            [icon]="faChevronCircleUp"></fa-icon>
                    } @else {
                        <fa-icon
                            class="text=[12px] text-blurple"
                            [icon]="faChevronCircleDown"></fa-icon>
                    }
                </div>
            </div>
            @if (showSettings) {
                <div class="w-full flex flex-col items-start justify-start gap-1 mt-1.5">
                    <!-- Activity Preference -->
                    <fm-session-preference-activity
                        class="w-full mb-1 mt-1.5"
                        [initialValue]="selectedSessionActivityType"
                        toggleLabelClasses="text-bluegrey text-[14px] leading-none font-medium mb-1"
                        buttonClasses="text-13-18 font-semibold flex-row gap-1"
                        [disabled]="updatingPreferences || hasSessionStarted"
                        (activityChanged)="onActivityChanged($event)" />

                    <fm-session-preference-quiet-mode
                        class="w-full mb-21"
                        [initialValue]="selectedQuietMode"
                        toggleLabelClasses="text-bluegrey text-[14px] leading-none font-medium mb-1"
                        source="sessionInfoModal"
                        (quietModeChanged)="onQuietModeChanged($event)" />

                    <!-- Favorites Preferences -->
                    <fm-session-preference-favorites
                        class="w-full mb-1"
                        [timezone]="user.timeZone"
                        toggleLabelClasses="text-bluegrey text-[14px] leading-none font-medium mb-1"
                        checkboxLabelClasses="text-bluegrey text-12-16 font-medium !mt-2"
                        buttonClasses="text-13-18 font-semibold flex-row gap-1"
                        [disabled]="updatingPreferences || hasSessionStarted"
                        source="sessionInfoModal"
                        [ngModel]="selectedFavoritesPreference"
                        (preferenceChanged)="onPreferenceChanged($event)" />
                    <fm-button
                        [matTooltip]="updateSessionTooltip"
                        matTooltipPosition="above"
                        matTooltipShowDelay="200"
                        matTooltipClass="f-tooltip"
                        buttonType="solid-outline-disabled"
                        [disabled]="disableUpdatePreferencesButton || hasSessionStarted"
                        [loading]="updatingPreferences"
                        buttonSize="small"
                        (clicked)="updatePreferences()"
                        class="!w-[213px] !font-avenir mx-auto"
                        >Update session preferences</fm-button
                    >
                    <div class="!my-1 min-h-4 h-4 w-full">
                        <div
                            *ngIf="showUpdateError"
                            class="text-12-16 text-center text-red font-normal">
                            <fa-icon class="!mr-1" [icon]="faInfoCircle"></fa-icon>Preferences
                            cannot be changed once session has started</div
                        ></div
                    >
                </div>
            }
        </div>

        <!-- Calendar / Cancel-->
        <div class="labels w-full">
            <app-add-to-calendar
                class="cal-link"
                [sessionTime]="sessionInfo.sessionTime"
                [sessionDurationMs]="sessionInfo.duration"
                [timeZone]="user.timeZone"
                [videoLink]="sessionInfo.meetingRoom"
                [email]="user.email"
                [colorClass]="'pendingIcon'"
                [name]="user.displayName"
                [focusMate]="sessionInfo.partnerDisplayName"
                [useIcon]="false">
            </app-add-to-calendar>
            <div
                appFmDebounceClick
                *ngIf="canCancel"
                (debounceClick)="cancelSession()"
                class="cancel-link">
                Cancel session
            </div>
        </div>

        <div
            class="sep w-full !my-6"
            *ngIf="sessionInfo.state != 1 && sessionInfo.state != 2"></div>
        <!-- Partner Info -->
        <div class="partner-info w-full" *ngIf="sessionInfo.state != 1 && sessionInfo.state != 2">
            <app-profile-image
                class="img-container"
                [snoozed]="sessionInfo.snoozed"
                [saved]="sessionInfo.saved"
                [profileImage]="sessionInfo.partnerPhoto"
                [snoozeTooltip]="snoozeTooltip"
                size="medium">
            </app-profile-image>

            <div class="partner-stats overflow-hidden w-full">
                <div class="label w-full">{{
                    lockedInPartner ? 'CONFIRMED PARTNER' : 'PARTNER'
                }}</div>

                <ng-template #disabledLink>
                    <div *ngIf="sessionInfo.partnerDisplayName != ''" class="partner-name-disabled">
                        {{ sessionInfo.partnerDisplayName }}
                    </div>
                </ng-template>
                <div
                    *ngIf="
                        sessionInfo.partnerDisplayName != '' && sessionInfo.status != 4;
                        else disabledLink
                    "
                    (click)="trackProfileView()"
                    [routerLink]="['/user/' + sessionInfo.partnerProfileUrl]"
                    class="partner-name w-full">
                    <fa-icon
                        style="margin-top: 3px"
                        *ngIf="lockedInPartner"
                        [icon]="faLock"
                        [classes]="['fa-locked']">
                    </fa-icon>
                    <span class="wrap-single-line-el">{{ sessionInfo.partnerDisplayName }}</span>
                </div>
                <div *ngIf="sessionInfo.partnerDisplayName == ''" class="partner-name-disabled"
                    >Not yet matched</div
                >
                <div *ngIf="sessionInfo.partnerDisplayName != ''" class="partner-sessions">
                    {{ sessionInfo.user_comp_sessions }} sessions completed
                </div>
                <div class="partner-sessions">
                    <div
                        *ngFor="let group of sessionInfo.group; let i = index"
                        style="display: inline">
                        {{ group.groupName
                        }}<span *ngIf="i < sessionInfo.group.length - 1">,&nbsp;</span>
                    </div>
                </div>
                <div
                    *ngIf="userPartOfGroup == true && sessionInfo.partnerDisplayName == ''"
                    class="partner-sessions">
                    You will always be matched with a member of your group(s), when available.
                </div>
            </div>
            <div class="actions-container">
                <a
                    class="message"
                    *ngIf="sessionInfo.partnerDisplayName != '' && sessionInfo.status == 0"
                    href="{{ messageUrl }}"
                    target="_blank">
                    <mat-icon
                        class="size-18 mt-1.5"
                        matTooltip="Message Partner"
                        matTooltipPosition="above"
                        matTooltipShowDelay="500"
                        matTooltipClass="f-tooltip">
                        message
                    </mat-icon>
                </a>
                <app-toggle-save
                    *ngIf="sessionInfo.partnerDisplayName != '' && sessionInfo.status != 4"
                    [classes]="['icon-size-18']"
                    [saved]="sessionInfo.saved"
                    [targetUserId]="sessionInfo.partnerId"
                    source="sessionInfoModal">
                </app-toggle-save>
                <app-user-actions-menu
                    classes="svg-user-action-menu-grey"
                    class="actions-menu"
                    *ngIf="sessionInfo.partnerDisplayName != '' && sessionInfo.status != 4"
                    [showReportSession]="true"
                    [sessionTime]="data.sessionTime"
                    [sessionId]="data.sessionId"
                    [timeZone]="user.timeZone"
                    [targetUserDisplayName]="sessionInfo.partnerDisplayName"
                    [targetUserId]="sessionInfo.partnerId"
                    [targetUserProfileUrl]="sessionInfo.partnerProfileUrl"
                    [targetUserPhotoUrl]="sessionInfo.partnerPhoto"
                    [status]="sessionInfo.status"
                    [source]="'sessionInfoModal'"
                    [snoozeStatus]="sessionInfo.snoozed"
                    [snoozeExpiresAt]="sessionInfo.snoozeExpiresAt"
                    [saved]="sessionInfo.saved">
                </app-user-actions-menu>
            </div>
        </div>

        <div *ngIf="showVideoButton" class="sep w-full !my-6"></div>
        <app-video-icon
            class="w-full"
            *ngIf="showVideoButton"
            [sessionUrl]="sessionInfo.meetingRoom"
            [sessionTime]="sessionInfo.sessionTime"
            [videoIconEnabled]="true"
            [source]="VideoLinkSource.SessionInfoModal"
            (joiningSession)="closeDialog()"></app-video-icon>
    </div>
</div>
