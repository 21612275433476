import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { UserService } from '@app/core/_services/user.service'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { SafeUrl } from '@angular/platform-browser'
import { environment } from '@env/environment'

@Component({
    selector: 'app-suspended',
    templateUrl: './suspended.component.html',
    styleUrls: ['./suspended.component.scss'],
})
export class SuspendedComponent implements OnInit {
    public bodyContent: string = ''
    private ngUnsubscribe: Subject<any> = new Subject<any>()

    public communityGuidelinesUrl: SafeUrl = environment.marketingHomeUrl + '/community'

    constructor(
        private analyticsService: AnalyticsService,
        public dialog: MatDialogRef<SuspendedComponent>,
        private userService: UserService,
    ) {}

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Account Suspended')

        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.bodyContent = '%0d%0a%0d%0a%0d%0a%0d%0a'
            this.bodyContent += '==== Do not remove/edit ===='
            this.bodyContent += '%0d%0a'
            this.bodyContent += 'Profile: ' + user.profileUrl
            this.bodyContent += '%0d%0a'
            this.bodyContent += 'Email: ' + user.email
        })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
