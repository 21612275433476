<div class="fm-ev-container">
    <div>
        <mat-icon type="button" class="fm-clear-icon" (click)="dialog.close()">clear</mat-icon>
    </div>

    <div class="fm-ev-title fm-mb-30px text-center">Booking Limit Reached</div>
    <div class="fm-ev-text fm-mb-20px text-center">
        <p>{{ modalMessage }}</p>
        <div
            class="fm-help-text text-center"
            matTooltip="{{ tip }}"
            matTooltipPosition="above"
            matTooltipShowDelay="100"
            matTooltipClass="f-tooltip">
            Why do we have a limit?
        </div>
    </div>
</div>
