import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'app-one-line-notification',
    templateUrl: './one-line-notification.component.html',
    styleUrls: ['./one-line-notification.component.scss'],
})
export class OneLineNotificationComponent implements OnInit {
    public message: string = ''

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
        this.message = this.data.message
    }
}
