import { Component, Inject, OnInit } from '@angular/core'
import { UserService } from '@app/core/_services/user.service'
import { AnalyticsService } from '@app/core/_services/analytics.service'

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export const MODAL_VERIFY_EMAIL = 'Verify Email Address'
export const MODAL_VERIFY_EMAIL_BOOKING = 'Verify Email Address: On Booking'
export const MODAL_VERIFY_EMAIL_UPGRADE = 'Verify Email Address: On Upgrade Attempt'

@Component({
    selector: 'app-email-verify',
    templateUrl: './email-verify.component.html',
    styleUrls: ['./email-verify.component.scss'],
})
export class EmailVerifyComponent implements OnInit {
    public alreadyVerified: boolean = false
    public sendingEmailComplete: boolean = false
    public modalVersion: number

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private userService: UserService,
        private analyticsService: AnalyticsService,
        public dialog: MatDialogRef<EmailVerifyComponent>,
    ) {
        if (data.modalName === MODAL_VERIFY_EMAIL) {
            this.modalVersion = 1
        } else if (data.modalName === MODAL_VERIFY_EMAIL_BOOKING) {
            this.modalVersion = 2
        } else {
            this.modalVersion = 3
        }
    }

    ngOnInit() {
        this.analyticsService.logViewedModalEvent(this.data.modalName)
        this.userService.recheckEmailVerified().then((verified) => {
            if (verified) {
                this.dialog.close({})
            }
        })
    }

    closeDialog() {
        this.dialog.close({})
    }

    sendEmailVerification() {
        this.userService.sendEmailVerification().then((result) => {
            if (result === true) {
                this.dialog.close({})
            } else {
                this.dialog.close({})
                this.sendingEmailComplete = true
                setTimeout(() => {
                    this.sendingEmailComplete = false
                })
            }
        })
    }
}
