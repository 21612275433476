<div
    class="sm:min-h-[639px] flex flex-col items-center justify-between relative box-border w-full h-full font-avenir">
    <!-- header -->
    <div
        class="py-[36px] sm:py-[24px] px-[30px] sm:px-[100px] flex flex-col gap-[5px] items-center justify-center"
        [class]="headerClass">
        <div class="text-blurple text-center text-26-36 font-semibold">
            Review Community Guidelines
        </div>
        @if (state.source === 'Launch Container' || outOfOrder) {
            <div class="text-bluegrey-2 text-center text-14-19 font-medium">
                Please review these guidelines for a successful session!</div
            >
        }
    </div>

    <!-- body -->
    <div class="px-[30px] sm:px-[100px] flex flex-col items-center justify-center gap-[12px]">
        <div class="w-full p-[16px] flex flex-col gap-[8px] bg-blurple-5 rounded-lg">
            <div class="text-[#328449] text-14-19 font-semibold">Okay</div>
            <div class="text-bluegrey-2 text-14-19 font-medium"
                ><ul
                    ><li
                        >Chat at the start to share your plan and at the end to share how it
                        went</li
                    >
                    <li
                        >Stay on camera; inform your partner through the chat if you need a brief
                        break</li
                    >
                    <li>Dress as if you're in a shared workspace</li>
                    <li>Engage with kindness and respect</li></ul
                ></div
            >
        </div>

        <div class="w-full p-[16px] flex flex-col gap-[8px] bg-blurple-5 rounded-lg">
            <div class="text-[#D83704] text-14-19 font-semibold">Not okay</div>
            <div class="text-bluegrey-2 text-14-19 font-medium"
                ><ul
                    ><li>Harmful, sexual, or hateful actions or comments</li
                    ><li>Flirting or prying personal details</li>
                    <li>Promoting or selling any products or services</li>
                    <li>Recording or screenshotting your partner without their consent</li></ul
                ></div
            >
        </div>
    </div>

    <!-- bottom -->
    <div
        class="w-full sm:w-[400px] pt-[35px] pb-[50px] px-[30px] sm:pb-[60px] sm:px-[0px] flex flex-col items-center justify-center gap-[15px]">
        <button class="f-btn f-btn--primary w-full" (click)="onNextStepButtonClick()">
            @if (state.complete) {
                Got it!
            } @else if (state.source === 'Launch Container') {
                Agree and join session
            } @else if (state.source === 'Welcome Checklist Modal' && !outOfOrder) {
                Agree and move to booking session
            } @else {
                Agree and continue
            }
            <fa-icon class="pl-[6px]" [icon]="faArrowRight"></fa-icon
        ></button>
        <a
            [href]="communityGuidelinesUrl"
            target="_blank"
            class="flex flex-row items-center justify-center text-14-19 text-blurple font-semibold no-underline cursor-pointer"
            >View full guidelines<fa-icon class="pl-[4px]" [icon]="faExternalLink"></fa-icon
        ></a>
    </div>
</div>
