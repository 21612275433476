import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

@Component({
    selector: 'app-snooze-user-confirm',
    templateUrl: './snooze-user-confirm.component.html',
    styleUrls: ['./snooze-user-confirm.component.scss'],
})
export class SnoozeUserConfirmComponent implements OnInit {
    public faTimes = faTimes
    public currentUrl: string

    constructor(
        public dialog: MatDialogRef<SnoozeUserConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private analyticsService: AnalyticsService,
        public router: Router,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Snooze User: Confirmed')
        this.currentUrl = this.router.url
    }

    manageSnoozesSelected() {
        this.analyticsService.logClickedEvent('Manage snoozes', 'Snooze User: Confirmed')
        this.dialog.close()
    }
}
