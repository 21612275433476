import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

@Component({
    selector: 'app-book-error-session-already-started',
    templateUrl: './book-error-session-already-started.component.html',
    styleUrls: ['./book-error-session-already-started.component.scss'],
})
export class BookErrorSessionAlreadyStartedComponent implements OnInit {
    public faTimes = faTimes

    constructor(
        private analyticsService: AnalyticsService,
        private dialog: MatDialogRef<BookErrorSessionAlreadyStartedComponent>,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('BookError: Session Already Started')
    }

    closeDialog() {
        this.dialog.close(false)
    }
}
