import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent implements OnInit {
    @Input() colorClass: string = 'secondary'
    @Input() sizeClass: string = 'size-40'

    constructor() {}

    ngOnInit(): void {}
}
