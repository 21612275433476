import { Injectable } from '@angular/core'
import { User } from '../_models'
import { UserService } from './user.service'
import { AnalyticsService } from './analytics.service'
import { firstValueFrom } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class LoginSignupUtilService {
    private comingFromSignUp: boolean = false

    constructor(
        private userService: UserService,
        private analyticsService: AnalyticsService,
    ) {}

    setComingFromSignUp(launch: boolean) {
        this.comingFromSignUp = launch
    }

    getComingFromSignup(): boolean {
        return this.comingFromSignUp
    }

    async markOnboardingComplete(user: User) {
        user.onboard = true
        await firstValueFrom(this.userService.update(user))
        this.analyticsService.logOnboardingComplete()
    }
}
