import { Injectable } from '@angular/core'
import { ApiService } from '@app/core/_services/api.service'
import { BehaviorSubject, Observable, throwError as _throw } from 'rxjs'
import { environment } from '@env/environment'
import { map, catchError } from 'rxjs/operators'
import { GroupInfo } from '@app/core/_models/rest_api'
import { HttpParams } from '@angular/common/http'
import { UserService } from './user.service'

export class GroupInfoPage {
    constructor(
        public groupId: string = '',
        public groupName: string = '',
        public groupLogo: string = '',
        public groupMember: boolean = false,
        public groupAdmin: boolean = false,
        public groupMemberSince: string = '',
        public groupMembers: number = 0,
    ) {}
}

@Injectable({
    providedIn: 'root',
})
export class GroupService {
    private groupSignUpInfo: GroupInfo = new GroupInfo()
    private groupSignUpSubject = new BehaviorSubject<GroupInfo>(new GroupInfo() as GroupInfo)
    public groupSignUp = this.groupSignUpSubject.asObservable()

    constructor(
        private apiService: ApiService,
        private userService: UserService,
    ) {}

    getGroupSignUpPageInfo(group_code: string): Observable<any> {
        return this.apiService.get(environment.api_url + 'group/code/' + group_code).pipe(
            map((data) => {
                if (data.hasOwnProperty('groupLogo')) {
                    this.groupSignUpInfo = data
                } else {
                    this.groupSignUpInfo = new GroupInfo()
                }
                this.groupSignUpSubject.next(this.groupSignUpInfo)
                return this.groupSignUpInfo
            }),
            catchError((error) => {
                return _throw(error)
            }),
        )
    }

    getGroupInfoById(groupId: string): Observable<GroupInfoPage> {
        return this.apiService.get(environment.api_url + 'group/id/' + groupId).pipe(
            map((data) => {
                return data
            }),
            catchError((error) => {
                return _throw(error)
            }),
        )
    }

    addMembers(groupId: string, emaiList: string[]): Observable<any> {
        let members = []

        for (let email of emaiList) {
            members.push({
                email: email,
            })
        }

        let add_members: any = {
            groupId: groupId,
            invites: members,
        }

        return this.apiService.post(environment.api_url + 'group/invite', add_members).pipe(
            map((data) => {
                return data
            }),
            catchError((error) => {
                return _throw(error)
            }),
        )
    }

    removeMembers(groupId: string, emaiList: string[]): Observable<any> {
        let members = []

        for (let email of emaiList) {
            members.push({
                email: email,
            })
        }

        let rem_members: any = {
            groupId: groupId,
            disinvites: members,
        }

        return this.apiService.post(environment.api_url + 'group/remove', rem_members).pipe(
            map((data) => {
                return data
            }),
            catchError((error) => {
                return _throw(error)
            }),
        )
    }

    getGroupMembers(groupId: string, page: number, pageSize: number): Observable<any> {
        let params = new HttpParams()
            .set('page', (page + 1).toString())
            .set('pageSize', pageSize.toString())
        return this.apiService
            .get(environment.api_url + 'group/members/joined/' + groupId, params)
            .pipe(
                map((data) => {
                    if (data.hasOwnProperty('members')) {
                        return data
                    } else {
                        return []
                    }
                }),
                catchError((error) => {
                    return _throw(error)
                }),
            )
    }

    verifyGroupMemberPlans(groupId: string): Observable<any> {
        return this.apiService.get(environment.api_url + 'admin/verify-members/' + groupId).pipe(
            map((data) => {
                if (data.hasOwnProperty('notOnCorrectPlan')) {
                    return data
                } else {
                    return []
                }
            }),
            catchError((error) => {
                return _throw(error)
            }),
        )
    }

    updateGroupMemberPlans(groupId: string): Observable<any> {
        return this.apiService
            .get(environment.api_url + 'admin/update-member-plans/' + groupId)
            .pipe(
                map((data) => {}),
                catchError((error) => {
                    return _throw(error)
                }),
            )
    }

    getGroupPendingInvites(groupId: string, page: number, pageSize: number): Observable<any> {
        let params = new HttpParams()
            .set('page', (page + 1).toString())
            .set('pageSize', pageSize.toString())

        return this.apiService
            .get(environment.api_url + 'group/members/pending/' + groupId, params)
            .pipe(
                map((data) => {
                    if (data.hasOwnProperty('members')) {
                        return data
                    } else {
                        return []
                    }
                }),
                catchError((error) => {
                    return _throw(error)
                }),
            )
    }

    acceptInvite(groupId: string): Observable<any> {
        let accept_ivite: any = {
            groupId: groupId,
        }

        return this.apiService.post(environment.api_url + 'group/accept', accept_ivite).pipe(
            map((data) => {
                if (data.hasOwnProperty('group')) {
                    this.userService.setCurrentUserInfo(data.user)
                    return data.group
                }
                return []
            }),
            catchError((error) => {
                return _throw(error)
            }),
        )
    }

    leaveGroup(groupId: string): Observable<any> {
        let leave: any = {
            groupId: groupId,
        }

        return this.apiService.post(environment.api_url + 'group/decline', leave).pipe(
            map((data) => {
                if (data.hasOwnProperty('group')) {
                    this.userService.setCurrentUserInfo(data.user)
                    return data.group
                }
                return []
            }),
            catchError((error) => {
                return _throw(error)
            }),
        )
    }

    acknowledgeInviteNotification(groupId: string): Observable<any> {
        let ack: any = {
            groupId: groupId,
        }

        return this.apiService.post(environment.api_url + 'group/notify', ack).pipe(
            map((data) => {
                if (data.hasOwnProperty('group')) {
                    this.userService.setCurrentUserInfo(data.user)
                    return data.group
                }
                return []
            }),
            catchError((error) => {
                return _throw(error)
            }),
        )
    }
}
