import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { PlayToneService } from '@app/core/_services/play-tone.service'

const SUPPORT_ARTICLE_URL =
    'https://support.focusmate.com/en/articles/6210459-i-m-not-getting-sounds-or-notifications-what-do-i-do'

@Component({
    selector: 'app-play-tone-failed',
    templateUrl: './play-tone-failed.component.html',
    styleUrls: ['./play-tone-failed.component.scss'],
})
export class PlayToneFailedComponent implements OnInit {
    public supportArticleUrl = SUPPORT_ARTICLE_URL

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialogRef<PlayToneFailedComponent>,
        private playToneService: PlayToneService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {}

    learnMoreClicked(): void {
        this.playToneService.playEndSessionToneMuted()
        this.analyticsService.logClickedEvent('Learn More', 'Play Tone Failed Popup')
    }

    closeDialog(): void {
        this.playToneService.playEndSessionToneMuted()
        this.analyticsService.logClickedEvent('Dismiss', 'Play Tone Failed Popup')
        this.dialog.close()
    }
}
