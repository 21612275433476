import { Component, EventEmitter, Input, Output } from '@angular/core'
import {
    SESSION_ACTIVITY_TYPE_ANYTHING,
    SESSION_ACTIVITY_TYPE_DESK,
    SESSION_ACTIVITY_TYPE_MOVING,
} from '@app/core/_models'
import { FmButtonGroupItem } from '@app/standards-v2/components/fm-button-group/fm-button-group.component'
import { faLampDesk, faPersonWalking, faShuffle } from '@fortawesome/pro-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { DialogService } from '@app/core/_services/dialog.service'
import { TimeUtilitiesService } from '@app/core/_services/time-utilities.service'

@Component({
    selector: 'fm-session-preference-activity',
    templateUrl: './session-preference-activity.component.html',
    styleUrls: ['./session-preference-activity.component.scss'],
})
export class SessionPreferenceActivityComponent {
    @Input() initialValue: number = SESSION_ACTIVITY_TYPE_ANYTHING
    @Input() toggleLabelClasses: string = ''
    @Input() buttonClasses: string = ''
    @Input() disabled: boolean = false
    @Input() showInfoModalTrigger: boolean = false
    @Output() activityChanged = new EventEmitter<number>()

    public faInfoCircle = faInfoCircle

    public buttonList: FmButtonGroupItem[] = [
        {
            value: SESSION_ACTIVITY_TYPE_DESK,
            faIcon: faLampDesk,
            label: 'Desk',
            labelClasses: '',
            tooltip: 'For writing, emails, coding, and more',
        },
        {
            value: SESSION_ACTIVITY_TYPE_MOVING,
            faIcon: faPersonWalking,
            label: 'Moving',
            labelClasses: '',
            tooltip: 'For workouts, chores, and active tasks',
        },
        {
            value: SESSION_ACTIVITY_TYPE_ANYTHING,
            faIcon: faShuffle,
            label: 'Anything',
            labelClasses: '',
            tooltip: "When you're juggling various tasks or still deciding",
        },
    ]

    constructor(private dialogService: DialogService) {}

    onToggleChange(e: any) {
        this.activityChanged.emit(e)
    }

    openInfoModal() {
        this.dialogService.openSessionPreferencesInfoModal()
    }

    ngOnChanges() {
        for (let button of this.buttonList) {
            button.labelClasses = this.buttonClasses
        }
    }
}
