import { Injectable } from '@angular/core'
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker'
import { filter, first } from 'rxjs'
import { DialogService } from './dialog.service'
import * as Sentry from '@sentry/angular-ivy'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class SoftwareUpdateService {
    constructor(
        private swUpdate: SwUpdate,
        private dialogService: DialogService,
        private userService: UserService,
    ) {
        this.registerUpateEvents()
    }

    public registerUpateEvents() {
        try {
            if (this.swUpdate.isEnabled) {
                this.swUpdate.versionUpdates
                    .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
                    .subscribe((evt) => {
                        const appData = evt.latestVersion.appData
                        if (appData && appData['promptUser']) {
                            this.userService.isAuthenticated
                                .pipe(first())
                                .subscribe((authenticated) => {
                                    if (authenticated) {
                                        this.dialogService.openSWUpdateNotification()
                                    }
                                })
                        }
                    })

                this.swUpdate.unrecoverable.subscribe((event) => {
                    Sentry.withScope((scope) => {
                        scope.setExtras({ reason: event.reason, type: event.type })
                        Sentry.captureMessage('Service Worker Unrecoverable State')
                    })
                })
            }
        } catch (err) {
            Sentry.captureException(err, {
                tags: {
                    'fm.errorDomain': 'serviceWorker',
                },
            })
        }
    }

    public checkForUpdate(): void {
        try {
            if (this.swUpdate.isEnabled) {
                this.swUpdate.checkForUpdate().then(() => {})
            }
        } catch (err) {
            Sentry.captureException(err, {
                tags: {
                    'fm.errorDomain': 'serviceWorker',
                },
            })
        }
    }
}
