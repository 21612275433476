import { Injectable } from '@angular/core'

type FmLocalStorageItem = 'qmInfoDismissed'

@Injectable({
    providedIn: 'root',
})
export class LocalStorageAccessService {
    constructor() {}

    setItem(token: FmLocalStorageItem, value: string): void {
        try {
            localStorage.setItem(token, value)
        } catch (e) {}
    }

    getItem(token: FmLocalStorageItem): string {
        try {
            return localStorage.getItem(token)
        } catch (e) {}
    }
}
