import { Component, inject } from '@angular/core'
import { Subject } from 'rxjs'
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router'
import { AuthService } from '@app/core/_services/auth.service'
import { UserService } from '@app/core/_services/user.service'
import { takeUntil, take, distinctUntilChanged } from 'rxjs/operators'
import { Meta, Title } from '@angular/platform-browser'
import { environment } from '@env/environment'
import { StoreRedirectOnLoginService } from './core/_services/store-redirect-on-login.service'
import { AnalyticsService } from './core/_services/analytics.service'
import {
    ConfirmedSessionsResponse,
    Container,
    GENDER_MATCH_WOMEN_AND_NON_BINARY,
    User,
} from './core/_models'
import { DialogService } from './core/_services/dialog.service'
import { ConfirmedSessionsService, UtilsService } from './core/_services'
import { BrowserNotificationsService } from './core/_services/browser-notifications.service'
import { DeviceDetectionService } from './core/_services/device-detection.service'
import { TermsService } from './core/_services/terms.service'
import { ErrorHandlerService } from './core/_services/error-handler.service'
import { AutoCancelStatusService } from './core/_services/auto-cancel-status.service'
import { Auth, authState, User as FirebaseUser } from '@angular/fire/auth'
import { SoftwareUpdateService } from './core/_services/software-update.service'
import { ServerTimeService } from './core/_services/server-time.service'
import { RewardsReferralService } from './core/_services/rewards-referral.service'

declare let fbq: Function

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    private auth: Auth = inject(Auth)
    authState$ = authState(this.auth)
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    private ngUserUnsubscribe: Subject<any> = new Subject<any>()
    private ngWebWorkerUnsubscribe: Subject<any> = new Subject<any>()
    currentUrl: string = ''

    public showMaintenanceBanner: boolean = false
    public isBannerSticky: boolean = false
    public termsCreated: boolean = false
    private webWorker: Worker

    constructor(
        private analyticsService: AnalyticsService,
        private serverTimeService: ServerTimeService,
        public userService: UserService,
        public router: Router,
        private authService: AuthService,
        private metaService: Meta,
        private activatedRoute: ActivatedRoute,
        private storeRedirectOnLoginService: StoreRedirectOnLoginService,
        private dialogService: DialogService,
        private utilsService: UtilsService,
        private titleService: Title,
        private confirmedSessionsService: ConfirmedSessionsService,
        private browserNotificationsService: BrowserNotificationsService,
        private deviceDetectionService: DeviceDetectionService,
        private errorHandlerService: ErrorHandlerService,
        private autoCancelStatusService: AutoCancelStatusService,
        private softwareUpdateService: SoftwareUpdateService,
        private rewardsReferralService: RewardsReferralService,
    ) {}

    getChild(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild)
        } else {
            return activatedRoute
        }
    }

    verifyGenderPreferenceSetting(user: User) {
        if (user.genderPreference === GENDER_MATCH_WOMEN_AND_NON_BINARY) {
            this.dialogService.openEditGenderPreferenceWomanModal()
        }
    }

    updateBannerState() {
        this.showMaintenanceBanner = this.utilsService.inMaintenanceWindow(this.currentUrl)
        this.userService.currentUser.pipe(take(1)).subscribe((user) => {
            if (user.planInfo.addressUpdateNeeded || this.showMaintenanceBanner) {
                this.isBannerSticky = this.utilsService.isMaintenanceBannerSticky()
            }
        })
    }

    private showUpdatedTermsModalIfNeeded() {
        this.userService.currentUser.pipe(take(1)).subscribe((user) => {
            // If user is joining a session, do not present the terms in order
            // avoid a potentially awkward experience with their partner
            if (user.onboard && !this.currentUrl.startsWith('/session/')) {
                // This check ensures that that if the user has not accepted the
                // latest version of the ToS/Privacy Policy AND
                // this version of the application has the acknowledgment modal for
                // the latest version, then present it. This ensures that those on older
                // versions of the application do not show the older version of the acknowledgment
                // modal
                if (
                    user.terms.accepted === false &&
                    user.terms.latestVersion === environment.tosVersion
                ) {
                    this.dialogService.openUpdatedToSAgreementModal()
                }
            }
        })
    }

    ngOnInit() {
        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.titleService.setTitle(this.utilsService.getPageTitleFromRoute(event.url))
                try {
                    fbq('track', 'PageView')
                } catch (e) {
                    if (!(e instanceof ReferenceError)) {
                        throw e
                    }
                }

                try {
                    if ((<any>window).Intercom) {
                        ;(<any>window).Intercom('update')
                    }
                } catch (e) {
                    if (!(e instanceof ReferenceError)) {
                        throw e
                    }
                }
                let rt = this.getChild(this.activatedRoute)

                rt.data.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
                    if (environment.sentryEnv !== 'production') {
                        this.metaService.updateTag({ name: 'robots', content: 'nofollow,noindex' })
                    } else {
                        if (data.robots) {
                            this.metaService.updateTag({ name: 'robots', content: data.robots })
                        } else {
                            this.metaService.updateTag({ name: 'robots', content: 'follow,index' })
                        }
                    }
                    this.analyticsService.trackPageView(event.urlAfterRedirects, data)
                })

                this.currentUrl = event.urlAfterRedirects
                setTimeout(() => {
                    this.updateBannerState()
                })

                this.softwareUpdateService.checkForUpdate()
                this.showUpdatedTermsModalIfNeeded()
            }

            if (event instanceof NavigationStart) {
                this.userService.setSignUpLoginViaSocialInviteeFlow(event.url)
                this.storeRedirectOnLoginService.resetUrlToRedirectToOnLogin(event.url)
                this.currentUrl = event.url
            }
        })

        this.authService.getFirebaseUser().then((currentUser) => {
            // TODO: Refactor how we move to dashboard
            // on initial page load if user already logged in
            if (currentUser && this.currentUrl === '/') {
                this.router.navigate(['/dashboard'])
            }
        })

        this.authState$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((user: FirebaseUser | null) => {
                //handle auth state changes here. Note, that user will be null if there is no currently logged in user.
                this.userService.populate(user)
            })

        this.autoCancelStatusService.autocancelStatus
            .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
            .subscribe((enabled: boolean) => {
                if (enabled) {
                    this.errorHandlerService.openNoShowAcknowledgeDialog(
                        this.autoCancelStatusService.triggerSession,
                    )
                }
            })

        // on log in / log out, set up or destroy the web worker as needed
        this.userService.isAuthenticated
            .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
            .subscribe((isAuthenticated) => {
                if (isAuthenticated) {
                    // clear referral code on any sign in/sign up
                    this.rewardsReferralService.clearRewardsReferralCode()

                    this.userService.currentUser.pipe(take(1)).subscribe((user) => {
                        this.verifyGenderPreferenceSetting(user)
                        this.serverTimeService.checkDeviceTime()

                        // set up or destroy the web worker as needed
                        this.setupSessionReminders()
                    })
                } else {
                    this.destroyWebWorker()
                }
            })
    }

    async setupSessionReminders() {
        // TODO: push notifications aaaaaaah
        if (await this.deviceDetectionService.isMobileAccurate()) {
            return
        }

        if (typeof Worker === 'undefined') {
            return // web workers are not supported in this environment.
        }

        // request permissions
        await this.browserNotificationsService.requestNotificationPermissions()

        // listen for any changes that will affect whether or not the web worker should be running
        this.browserNotificationsService.notificationsPermissionState
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.toggleWebWorkerIfNeeded())
        this.userService.currentUser
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.toggleWebWorkerIfNeeded())
    }

    // if all permissions and settings are met, turn the web worker on. if not, turn it off
    toggleWebWorkerIfNeeded() {
        const permissionsState =
            this.browserNotificationsService.getCurrentNotificationsPermissionsState()
        this.userService.currentUser.pipe(take(1)).subscribe((user) => {
            if (permissionsState === 'granted' && user.settings.sessionRemindersEnabled) {
                this.initializeWebWorker()
            } else {
                this.destroyWebWorker()
            }
        })
    }

    initializeWebWorker() {
        // avoid creating a duplicate
        this.destroyWebWorker()

        this.webWorker = new Worker(new URL('./app.worker', import.meta.url))

        // message the worker when the user's confirmed sessions change
        this.confirmedSessionsService.confirmedSessionsSubject
            .pipe(takeUntil(this.ngWebWorkerUnsubscribe))
            .subscribe((confirmedSessions: Container<ConfirmedSessionsResponse>) => {
                this.webWorker.postMessage({
                    type: 'sessionsDataUpdated',
                    data: confirmedSessions,
                })
            })

        // message the worker when the notification offset changes
        this.userService.currentUser
            .pipe(takeUntil(this.ngWebWorkerUnsubscribe))
            .subscribe((user: User) => {
                this.webWorker.postMessage({
                    type: 'userSettingsChanged',
                    data: {
                        notificationOffset: user.settings.sessionReminderNotificationOffset,
                        timeFormat: user.settings.timeFormat,
                    },
                })
            })

        // respond to the web worker's messages
        this.webWorker.onmessage = ({ data }) => {
            if (data.action) {
                if (data.action == 'updateUpcomingSessions') {
                    this.confirmedSessionsService.getConfirmedSessions().subscribe()
                } else if (data.action === 'notify') {
                    this.browserNotificationsService.notify(data.title, data.options)
                } else if (data.action === 'setLocalStorage') {
                    localStorage.setItem(data.key, data.value)
                } else if (data.action === 'getLocalStorage') {
                    this.webWorker.postMessage({
                        type: 'getLocalStorageResponse',
                        data: localStorage.getItem(data.key),
                    })
                }
            }
        }
    }

    destroyWebWorker() {
        if (this.webWorker) {
            this.webWorker.terminate()
            this.webWorker = null
        }
        this.ngWebWorkerUnsubscribe.next(null)
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
        this.ngUserUnsubscribe.next(null)
        this.ngUserUnsubscribe.complete()
        this.ngWebWorkerUnsubscribe.next(null)
        this.ngWebWorkerUnsubscribe.complete()
    }
}
