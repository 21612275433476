<div class="h-full sm:h-auto flex flex-col justify-start items-center bg-transparent">
    <div class="!h-fit sm:min-h-[639px] flex flex-col items-center justify-between bg-white">
        <!-- header -->
        <div class="py-[36px] sm:py-[24px] sm:px-[100px] flex items-center justify-center">
            <div class="text-blurple text-center text-26-36 font-semibold"> Book a session </div>
        </div>

        <!-- body -->
        <div class="std-modal-body h-full w-full px-[30px] sm:px-[100px]">
            <form #createSessionForm="ngForm" class="flex-c-fs-c w-full">
                <div class="time-info-container w-full">
                    <div class="time-info-item date">
                        <div class="time-label">Date</div>
                        <div
                            tabindex="0"
                            [attr.aria-label]="'Open calendar, ' + startDateString"
                            role="button"
                            class="date-container"
                            (click)="startDatePicker.open()">
                            <input
                                matInput
                                class="date-text pointer-events-none"
                                [disabled]="true"
                                [matDatepicker]="startDatePicker"
                                [value]="formModel.startDate"
                                [(ngModel)]="formModel.startDate"
                                [min]="minDate"
                                name="startDate"
                                (dateChange)="timeValid()" />
                            <mat-datepicker
                                [touchUi]="getTouchUi()"
                                [disabled]="false"
                                #startDatePicker></mat-datepicker>
                            <fa-icon
                                class="fm-ml-5px"
                                [icon]="faCalendar"
                                [classes]="['fa-calendar-create-session']">
                            </fa-icon>
                        </div>
                    </div>
                    <div class="time-info-item time-value time">
                        <div class="time-label">Time</div>

                        <app-fm-select
                            class="time-select"
                            [optionsList]="timeSlotValues"
                            [currentValue]="formModel.timeString"
                            [fieldName]="'timeSlots'"
                            [forceUpdate]="false"
                            [disabled]="false"
                            btnClasses="std-select w-full"
                            (selectionChanged)="selectedTimeSlot($event)">
                        </app-fm-select>
                    </div>
                    <div class="time-info-item duration">
                        <div class="time-label">Length</div>
                        <app-fm-select
                            class="duration-select"
                            [optionsList]="durationValues"
                            [currentValue]="formModel.duration"
                            [fieldName]="'durationValues'"
                            [forceUpdate]="false"
                            [disabled]="false"
                            btnClasses="std-select w-full"
                            (selectionChanged)="selectedDuration($event)">
                        </app-fm-select>
                    </div>
                </div>

                <!-- Activity Preference -->
                <fm-session-preference-activity
                    class="w-full !mb-2"
                    [initialValue]="this.formModel.activityType"
                    toggleLabelClasses="text-bluegrey text-sm font-medium !mb-0.5"
                    buttonClasses="text-sm font-medium flex-row gap-1"
                    showInfoModalTrigger="true"
                    (activityChanged)="onActivityChanged($event)" />

                <fm-session-preference-quiet-mode
                    class="w-full !mb-2"
                    [initialValue]="this.formModel.quietMode"
                    toggleLabelClasses="text-bluegrey text-sm font-medium !mb-0.5"
                    source="createSessionModal"
                    showInfoModalTrigger="true"
                    (quietModeChanged)="onQuietModeChanged($event)" />

                <!-- Favorites Preferences -->
                <fm-session-preference-favorites
                    class="w-full !mb-[15px]"
                    [timezone]="user.timeZone"
                    toggleLabelClasses="text-bluegrey text-sm font-medium !mb-0.5"
                    checkboxLabelClasses="text-bluegrey text-sm font-medium !mb-0.5 !mt-3"
                    buttonClasses="text-sm font-medium flex-row gap-1"
                    source="createSessionModal"
                    showInfoModalTrigger="true"
                    [(ngModel)]="formModel.favoritesPreference"
                    [ngModelOptions]="{ standalone: true }" />

                <div
                    *ngIf="!showAdvancedOptions"
                    class="cursor-pointer text-blurple text-sm font-avenir font-semibold !py-3"
                    (click)="toggleThePanel()">
                    <fa-icon class="!mr-1" [icon]="faCaretDown"> </fa-icon>
                    Show advanced options</div
                >

                <div *ngIf="showAdvancedOptions" class="recurring-container w-full">
                    <div class="recur-item recur-type">
                        <div class="recur-label">Repeat</div>
                        <app-fm-select
                            class="recur-select"
                            [optionsList]="recurType"
                            [currentValue]="formModel.recurType"
                            [fieldName]="'recurType'"
                            [forceUpdate]="false"
                            [disabled]="false"
                            btnClasses="std-select w-full"
                            (selectionChanged)="selectedRecurType($event)">
                        </app-fm-select>
                    </div>

                    <div class="recur-item recur-freq">
                        <div class="recur-label">End after</div>
                        <app-fm-select
                            class="recur-select"
                            [optionsList]="recurTimes"
                            [currentValue]="formModel.numSessions"
                            [fieldName]="'numSessions'"
                            [forceUpdate]="false"
                            [disabled]="formModel.recurType == 1"
                            btnClasses="std-select w-full"
                            (selectionChanged)="selectedRecurFrequency($event)">
                        </app-fm-select>
                    </div>
                </div>
                <div *ngIf="showAdvancedOptions" class="f-form-field w-full fm-mb-16px">
                    <div class="time-label"
                        >What do you want to accomplish? (only visible to you)</div
                    >
                    <input
                        maxlength="100"
                        type="text"
                        [(ngModel)]="formModel.title"
                        name="title"
                        class="w-full"
                        placeholder="Optional - e.g. Plan my day" />
                </div>

                <div
                    *ngIf="showAdvancedOptions"
                    class="cursor-pointer text-blurple text-sm font-avenir font-semibold !py-3"
                    (click)="toggleThePanel()">
                    <fa-icon class="!mr-1" [icon]="faCaretUp"> </fa-icon>
                    Hide advanced options</div
                >
            </form>
        </div>

        <!-- bottom -->
        <div class="flex flex-col justify-start items-center !mt-5 pb-[50px] sm:pb-[60px]">
            <app-spinner-button
                [disable]="errorTime"
                [label]="'Book'"
                classes="f-btn f-btn--primary f-btn-250"
                (clicked)="onSubmit(createSessionForm)">
            </app-spinner-button>

            <div class="f-link fm-mt-15px text-center" (click)="onCancelCreate()">Cancel</div>
        </div>
    </div>
</div>
