import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'app-sw-update-notification',
    templateUrl: './sw-update-notification.component.html',
    styleUrl: './sw-update-notification.component.scss',
})
export class SwUpdateNotificationComponent {
    constructor(private dialog: MatDialogRef<SwUpdateNotificationComponent>) {}

    reload() {
        document.location.reload()
    }

    close() {
        this.dialog.close()
    }
}
