import { Component, EventEmitter, Inject, Input, Output, Signal } from '@angular/core'
import { CommonModule } from '@angular/common'
import { faArrowRight, faExternalLink } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { WelcomeChecklistSteps } from '@app/core/_models/welcome-checklist'
import { environment } from '@env/environment'
import { SafeUrl } from '@angular/platform-browser'

export type ReviewCommunityGuidelinesModalSource =
    | 'Welcome Checklist Modal'
    | 'Launch Container'
    | 'In-Session Help'

export type ReviewCommunityGuidelinesModalState =
    | { complete: true; source: ReviewCommunityGuidelinesModalSource }
    | { complete: false; source: 'Launch Container' }
    | { complete: false; source: 'Welcome Checklist Modal'; steps: Signal<WelcomeChecklistSteps> }

export interface ReviewCommunityGuidelinesModalData {
    state: ReviewCommunityGuidelinesModalState
    onAgree: () => void
}

@Component({
    selector: 'fm-review-community-guidelines-modal',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule],
    templateUrl: './review-community-guidelines-modal.component.html',
    styleUrl: './review-community-guidelines-modal.component.scss',
})
export class ReviewCommunityGuidelinesModalComponent {
    @Input() state: ReviewCommunityGuidelinesModalState

    @Output() nextStepButtonClick: EventEmitter<any> = new EventEmitter<any>()
    private onAgree: () => void

    faArrowRight = faArrowRight
    faExternalLink = faExternalLink

    outOfOrder: boolean = false
    headerClass: string = ''

    public communityGuidelinesUrl: SafeUrl = environment.marketingHomeUrl + '/community'

    constructor(
        private analyticsService: AnalyticsService,
        public dialog: MatDialogRef<ReviewCommunityGuidelinesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit() {
        if (this.data?.state) {
            this.state = this.data.state
        }

        if (this.data?.onAgree) {
            this.onAgree = this.data.onAgree
        }

        this.analyticsService.logViewedModalEvent(
            `${this.state.source}: Review Community Guidelines`,
        )

        if (this.state.source !== 'Welcome Checklist Modal') {
            this.headerClass = 'sm:pt-[50px]'
        }

        // if any of the steps ahead of this one are complete, set outOfOrder to true
        if (this.state.complete === false && this.state.source === 'Welcome Checklist Modal') {
            const thisStepIndex = this.state
                .steps()
                .findIndex((s) => s.name === 'reviewCommunityGuidelines')
            const nextSteps = this.state.steps().slice(thisStepIndex + 1)
            const nextStepsComplete = nextSteps.some((s) => s.complete === true)
            if (nextStepsComplete) {
                this.outOfOrder = true
            }
        }
    }

    onNextStepButtonClick() {
        this.analyticsService.logClickedEvent(
            'Next',
            `${this.state.source}: Review Community Guidelines`,
        )
        this.nextStepButtonClick.emit()

        if (this.onAgree) {
            this.onAgree()
        }

        if (this.state.source !== 'Welcome Checklist Modal') {
            this.dialog.close()
        }
    }
}
