import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

export class BookLimitInfo {
    constructor(
        public attRate: number = 0,
        public bookLimit: number = 0,
        public compSessions: number = 0,
    ) {}
}

const UNLIMITED_SESSIONS_ALLOWED: number = 1000

@Injectable({
    providedIn: 'root',
})
export class BookLimitCheckService {
    private currentRemainingSubject = new BehaviorSubject<number>(0)
    public currentRemaining = this.currentRemainingSubject.asObservable()

    private currentLimitInfoSubject = new BehaviorSubject<BookLimitInfo>(new BookLimitInfo())
    public currentLimitInfo = this.currentLimitInfoSubject.asObservable()

    private numConfirmed: number = 0
    private numSelected: number = 0
    private numRemaining: number = 0
    private limitInfo: BookLimitInfo = new BookLimitInfo()

    constructor() {}

    public updateLimitsConfirmed(numSessionsConfirmed: number) {
        this.numConfirmed = numSessionsConfirmed
        this.emitLimitRemaing(this.numConfirmed + this.numSelected)
    }

    public updateLimitsSelected(numSelected: number) {
        this.numSelected = numSelected
        this.emitLimitRemaing(this.numConfirmed + this.numSelected)
    }

    public updateLimitInfo(limit: number, attRate: number, compSessions: number) {
        this.limitInfo.bookLimit = limit
        this.limitInfo.attRate = attRate
        this.limitInfo.compSessions = compSessions
        this.emitLimitRemaing(this.numConfirmed + this.numSelected)
        this.currentLimitInfoSubject.next(this.limitInfo)
    }

    private emitLimitRemaing(total: number) {
        if (this.limitInfo.bookLimit == null) {
            this.numRemaining = UNLIMITED_SESSIONS_ALLOWED
            this.currentRemainingSubject.next(this.numRemaining)
        } else {
            if (total >= this.limitInfo.bookLimit) {
                this.numRemaining = 0
                this.currentRemainingSubject.next(0)
            } else {
                this.numRemaining = this.limitInfo.bookLimit - total
                this.currentRemainingSubject.next(this.numRemaining)
            }
        }
    }

    public bookLimitReached(): boolean {
        return this.numRemaining === 0
    }
}
