import { BrowserModule } from '@angular/platform-browser'
import { NoopAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

// used to create fake backend
import { AuthGuard, ProfGuard } from '@app/core/_guards'

import { ApiService } from '@app/core/_services/api.service'
import { UserService } from '@app/core/_services/user.service'

import { DateSelectedService } from '@app/core/_services/date-selected.service'
import { BookSessionsService } from '@app/core/_services/book-sessions.service'
import { SelectedSessionService } from '@app/core/_services/selected-sessions.service'

import { ConfirmedSessionsService } from '@app/core/_services/confirmed-sessions.service'
import { CancelSessionService } from '@app/core/_services/cancel-session.service'
import { UtilsService } from '@app/core/_services/utils.service'
import { ReportService } from '@app/core/_services/report.service'
import { SnackbarService } from '@app/core/_services/snackbar.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { MediaQueryService } from '@app/core/_services'
import { FirebaseTokenService } from '@app/core/_services/firebase-token.service'

import { TermsService } from '@app/core/_services/terms.service'
import { pminsService } from '@app/core/_services/pmins.service'
import { CalendarViewService, AuthService } from '@app/core/_services'
import { AuthInterceptor } from '@app/core/_services/auth-interceptor.service'
import { DeleteAccountService } from '@app/core/_services/delete-account.service'
import { UserProfileService } from '@app/core/_services/user-profile.service'
import { ContactService } from '@app/core/_services/contact.service'
import { JoinSessionTrackerService } from '@app/core/_services/join-session-tracker.service'
import { ProfileQuestionsService } from '@app/core/_services/profile-questions.service'
import { BookLimitCheckService } from '@app/core/_services/book-limit-check.service'
import { AccountManagementService } from '@app/core/_services/account-management.service'

import { LoginSignupUtilService } from './_services/login-signup-util.service'
import { UpgradePlanGuard } from './_guards/upgrade-plan.guard'
import { PaymentConfirmGuard } from './_guards/payment-confirm.guard'
import { MatDialogModule } from '@angular/material/dialog'
import { MatSnackBarModule } from '@angular/material/snack-bar'

@NgModule({
    imports: [
        BrowserModule,
        RouterModule,
        MatDialogModule,
        MatSnackBarModule,
        NoopAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
    ],
    providers: [
        AuthGuard,
        ProfGuard,
        UpgradePlanGuard,
        PaymentConfirmGuard,
        DateSelectedService,
        SelectedSessionService,
        BookSessionsService,
        ConfirmedSessionsService,
        CancelSessionService,
        UtilsService,
        CalendarViewService,
        MediaQueryService,
        UserProfileService,
        AuthService,
        FirebaseTokenService,
        ProfileQuestionsService,
        ReportService,
        TermsService,
        ContactService,
        pminsService,
        JoinSessionTrackerService,
        SnackbarService,
        DialogService,
        UserService,
        ApiService,
        DeleteAccountService,
        BookLimitCheckService,
        AccountManagementService,
        LoginSignupUtilService,

        [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
    ],
})
export class CoreModule {}
