<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close(false)">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title">Gender Preference</div>
    </div>

    <div class="std-modal-body w-full">
        <div *ngIf="genderList != ''; else genderNotSet" class="std-modal-body-text text-left"
            >To set a gender preference, you’ll need to share more about your gender. (Right now
            it’s set to {{ genderList }}.)<br /><br />Your gender determines your matching
            options.</div
        >
        <ng-template #genderNotSet>
            <div class="std-modal-body-text text-left">
                To set a gender preference, you’ll need to set your gender.<br /><br />Your gender
                determines your matching options.
            </div>
        </ng-template>
        <div class="std-modal-action-container">
            <button class="f-btn f-btn--primary f-btn-250" (click)="updateGenders()"
                >Set gender(s)</button
            >
            <div class="f-link fm-mt-15px" (click)="dialog.close(false)">Cancel</div>
            <app-gender-inclusion-blog class="w-full fm-mt-15px"></app-gender-inclusion-blog>
        </div>
    </div>
</div>
