import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { environment } from '@env/environment'
import { map } from 'rxjs/operators'
import { UserService } from './user.service'

const GROUP_PLAN_4_PER_USER = 'meteredFourPerUser'

export const GROUP_SUBSCRIPTION_SOURCE_AMDIN_MANAGEMENT = 'fmAdminGroupManagementConsole'

export const INDIVIDUAL_PLAN_INTRO = 'intro'
export const INDIVIDUAL_PLAN_PLUS = 'plus'
export const INDIVIDUAL_PLAN_PLUS_ANNUAL = 'plus_annual'

const BASIC_PLAN_PRODUCT_ACCESS = 'Focusmate Basic'
const FREE_PLAN_PRODUCT_ACCESS = 'Focusmate Free'

@Injectable({
    providedIn: 'root',
})
export class StripeService {
    private source: string = 'unknown'

    constructor(
        private apiService: ApiService,
        private userService: UserService,
    ) {}

    getSessionId(planName: string, source: string) {
        if (source) {
            this.source = source
        }
        return this.apiService.post(environment.api_url + 'stripe/session', {
            checkoutInfo: {
                source: this.source,
                planName,
            },
        })
    }

    getSessionIdForGroupPlan(meteredOwner: string, groupId: string) {
        return this.apiService.post(environment.api_url + 'stripe/session/metered', {
            checkoutInfo: {
                source: GROUP_SUBSCRIPTION_SOURCE_AMDIN_MANAGEMENT,
                planName: GROUP_PLAN_4_PER_USER,
                meteredOwner: meteredOwner,
                meteredExtGroupId: groupId,
            },
        })
    }

    getCustomerPortal(groupId: string) {
        return this.apiService.post(environment.api_url + 'stripe/customer-portal', {
            checkoutInfo: {
                meteredExtGroupId: groupId,
            },
        })
    }

    cancelSubscription() {
        return this.apiService.post(environment.api_url + 'stripe/cancel').pipe(
            map((data) => {
                if (data.hasOwnProperty('user')) {
                    this.userService.setCurrentUserInfo(data.user)
                }
            }),
        )
    }

    createBillingPortal() {
        return this.apiService.post(environment.api_url + 'stripe/create-billing-portal')
    }

    setUpgradeSource(source: string) {
        this.source = source
    }

    isFreeTierPlan(productAccess: string): boolean {
        if (
            productAccess === BASIC_PLAN_PRODUCT_ACCESS ||
            productAccess === FREE_PLAN_PRODUCT_ACCESS
        ) {
            return true
        } else {
            return false
        }
    }
}
