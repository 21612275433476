<label class="checkbox-container">
    <input
        class="checkbox-input"
        type="checkbox"
        [disabled]="disabled"
        [ngModel]="checked"
        (ngModelChange)="onModelChange($event)" />
    <div class="checkbox-border" [ngClass]="{ 'checkbox-border--checked': checked }">
        <fa-icon *ngIf="checked" class="check" [icon]="faCheck"></fa-icon>
    </div>
    <ng-content> </ng-content>
</label>
