import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { environment } from '@env/environment'
import { Observable, catchError, first, throwError } from 'rxjs'
import { SnackbarService } from './snackbar.service'
import { HttpErrorResponse } from '@angular/common/http'

const GCAL_URL = environment.api_url + 'gcal'
const THIRD_PARTY_AUTH_URL = environment.api_url + 'oauth/3p'

export type GoogleCalendarInfo = {
    id: string
    name: string
    backgroundColor: string
    primary: boolean
}

export type GetGoogleCalendarsResponse = {
    connectedCalendarId: string
    calendars: GoogleCalendarInfo[]
}

@Injectable({
    providedIn: 'root',
})
export class GoogleCalendarService {
    constructor(
        private apiService: ApiService,
        private snackbarService: SnackbarService,
    ) {}

    public getGoogleCalendarsForUser(): Observable<GetGoogleCalendarsResponse> {
        return this.apiService.get(`${GCAL_URL}/calendars`).pipe(
            first(),
            catchError((error) => {
                if (error === 'refresh_error') {
                    this.snackbarService.createFailureNotification(
                        'Error with Google connection. Please sign in again.',
                        '',
                        5000,
                    )
                }

                // Propagate the error if it's not handled here.
                return throwError(() => error)
            }),
        )
    }

    public setConnectedGoogleCalendar(calendarId: string) {
        return this.apiService.post(`${GCAL_URL}/calendars`, { calendarId }).pipe(first())
    }

    public disconnectGoogleCalendar() {
        return this.apiService.delete(`${THIRD_PARTY_AUTH_URL}/google`).pipe(first())
    }
}
