<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title xwrap-double-line-el flex flex-row items-center justify-center"
            >About Session Settings
        </div>
    </div>

    <div class="std-modal-body w-full text-bluegrey text-sm font-avenir font-medium gap-6">
        <div class="w-full flex flex-col items-start justify-start gap-3">
            <div
                ><span class="font-semibold">My task:</span> Share your activity, and we'll try to
                match you with someone doing the same.</div
            >
            <div class="w-full flex flex-col justify-center items-center">
                <div class="flex flex-col justify-start items-start px-6">
                    <div class="!mb-2 flex flex-row justify-start items-start">
                        <div class="w-5 flex flex-row justify-start items-start mt-1">
                            <fm-activity-type-icon
                                class="text-12-16 mr-1.5"
                                [activityType]="activityDesk" />
                        </div>
                        <div>
                            <span class="text-blurple">Desk:</span> Perfect for writing, emails,
                            coding, and more</div
                        >
                    </div>
                    <div class="!mb-2 flex flex-row">
                        <div class="w-5 flex flex-row justify-start items-start mt-1">
                            <fm-activity-type-icon
                                class="text-12-16 mr-1.5"
                                [activityType]="activityMoving" />
                        </div>
                        <div>
                            <span class="text-blurple">Moving:</span> Ideal for workouts, chores,
                            and active tasks</div
                        >
                    </div>
                    <div class="flex flex-row">
                        <div class="w-5 flex flex-row justify-start items-start mt-1">
                            <fm-activity-type-icon
                                class="text-12-16 mr-1.5"
                                [activityType]="activityAnything"
                        /></div>
                        <div>
                            <span class="text-blurple">Anything:</span> When you're juggling various
                            tasks or still deciding</div
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full flex flex-col items-start justify-start gap-3">
            <div class="w-full"
                ><span class="font-semibold">Quiet Mode</span> is great for situations where you
                don’t have a mic or can’t talk (think libraries and shared spaces). Keep the camera
                on <fa-icon class="" [icon]="faVideo"></fa-icon> and use the text chat to
                communicate with your partners.
            </div>
            <div class="f-wull"
                >Your partners may not always be in Quiet Mode, so make sure you can hear them if
                they want to share their updates verbally.</div
            >
            <div class="w-full"
                >See
                <span class="underline cursor-pointer" (click)="settingsSelected()">Settings</span>
                for Quiet Mode matching preferences
            </div>
        </div>

        <div class="w-full flex flex-col items-start justify-start gap-3">
            <div><span class="font-semibold">Partner</span> controls who you’re matched with.</div>
            <div class="w-full flex flex-col justify-center items-center">
                <div class="flex flex-col justify-start items-start px-6">
                    <div class="!mb-2 flex flex-row">
                        <fa-icon class="text-12-16 mr-1.5 mt-0.5 text-blurple" [icon]="faStar">
                        </fa-icon>
                        <div>
                            <span class="text-blurple">Prefer Favorites:</span> Matches you with
                            your Favorite partners whenever they are available</div
                        >
                    </div>
                    <div class="flex flex-row">
                        <fa-icon
                            *ngIf="faUsersGlobe"
                            class="text-12-16 mr-1.5 mt-0.5 text-blurple"
                            [icon]="faUsersGlobe">
                        </fa-icon>
                        <div>
                            <span class="text-blurple">Anyone:</span> Gives you the classic
                            Focusmate experience, matching you with the first available person</div
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="std-modal-action-container">
        <button class="f-btn f-btn--primary f-btn-250" (click)="dialog.close()">Got it </button>
        <div class="flex flex-row justify-center items-center mt-4">
            <a
                class="f-link"
                style="text-decoration: none"
                target="_blank"
                href="https://support.focusmate.com/en/articles/8060080-session-settings-my-task-quiet-mode-and-partner"
                (click)="learnMoreClicked()"
                >Learn more
            </a>
            <span>&nbsp;·&nbsp;</span>
            <a
                class="f-link"
                style="text-decoration: none"
                target="_blank"
                href="https://forms.gle/cQbndUj7uo9wJEwE6"
                (click)="giveFeedbackClicked()">
                Give feedback</a
            ></div
        >
    </div>
</div>
