import { Component, Inject, Signal, WritableSignal, computed, effect, signal } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { WelcomeChecklistHeaderComponent } from '../welcome-checklist-header/welcome-checklist-header.component'
import { WelcomeToFocusmateModalComponent } from '../welcome-to-focusmate-modal/welcome-to-focusmate-modal.component'
import { LearnHowItWorksModalComponent } from '../learn-how-it-works-modal/learn-how-it-works-modal.component'
import { CompleteFirstSessionModalComponent } from '../complete-first-session-modal/complete-first-session-modal.component'
import { ReviewCommunityGuidelinesModalComponent } from '../review-community-guidelines-modal/review-community-guidelines-modal.component'
import { BookFirstSessionModalComponent } from '../book-first-session-modal/book-first-session-modal.component'
import { CompleteUnfinishedStepsModalComponent } from '../complete-unfinished-steps-modal/complete-unfinished-steps-modal.component'
import { WelcomeChecklistConfettiModalComponent } from '../welcome-checklist-confetti-modal/welcome-checklist-confetti-modal.component'
import {
    WelcomeChecklistStep,
    WelcomeChecklistStepName,
    WelcomeChecklistSteps,
} from '@app/core/_models/welcome-checklist'
import { AnalyticsService } from '@app/core/_services/analytics.service'

const STEP_NAME_TO_MODAL_NAME: {
    [key in WelcomeChecklistStepName]: string
} = {
    welcome: 'Welcome',
    howItWorks: 'How It Works',
    reviewCommunityGuidelines: 'Review Community Guidelines',
    bookFirstSession: 'Book First Session',
    completeFirstSession: 'Complete First Session',
    acknowledgeCompletion: 'Acknowledge Completion',
}

@Component({
    selector: 'fm-welcome-checklist-modal',
    standalone: true,
    imports: [
        CommonModule,
        WelcomeChecklistHeaderComponent,
        WelcomeToFocusmateModalComponent,
        LearnHowItWorksModalComponent,
        CompleteFirstSessionModalComponent,
        ReviewCommunityGuidelinesModalComponent,
        BookFirstSessionModalComponent,
        CompleteUnfinishedStepsModalComponent,
        WelcomeChecklistConfettiModalComponent,
    ],
    templateUrl: './welcome-checklist-modal.component.html',
    styleUrl: './welcome-checklist-modal.component.scss',
})
export class WelcomeChecklistModalComponent {
    public source = 'Welcome Checklist Modal'

    public steps: Signal<WelcomeChecklistSteps> = null
    private completeStep: Function = null
    public activeStep: Signal<WelcomeChecklistStep> = null

    public openStep: WelcomeChecklistStep = null
    public isOpenStepComplete: Signal<boolean> = null
    public showHeader: boolean = true

    public showCompleteUnfinishedStepsModal: boolean = false
    public didShowCompleteUnfinishedStepsModal: boolean = false

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialogRef<WelcomeChecklistModalComponent>,
        private analyticsService: AnalyticsService,
    ) {
        this.steps = data.steps
        this.activeStep = data.activeStep
        this.completeStep = data.completeStep

        // side effects on changes to the active step
        effect(() => {
            // if the active step ever changes, display it
            if (!this.activeStep()) {
                this.closeModal()
            } else if (
                this.activeStep().name === 'completeFirstSession' &&
                this.didShowCompleteUnfinishedStepsModal
            ) {
                // the complete unfinished steps modal is very similar to the
                // complete first session modal. do not show both in sequence
                this.closeModal()
            } else {
                this.showStep(this.activeStep())
            }
        })

        if (data.comingFromInternalTrigger) {
            // this modal was opened not by a user click, but by a trigger from within the app
            // (maybe on page load, or we detected a booked session, or a completed session).
            // check if the steps have been completed out of order and show a special modal if so.
            const completeSteps = this.steps()
                .filter((step) => step.complete)
                .map((step) => step.name)
            const incompleteSteps = this.steps()
                .filter((step) => !step.complete)
                .map((step) => step.name)
            this.showCompleteUnfinishedStepsModal =
                completeSteps.includes('bookFirstSession') &&
                (incompleteSteps.includes('reviewCommunityGuidelines') ||
                    incompleteSteps.includes('howItWorks'))
        }

        this.showStep(this.activeStep())
    }

    public completeUnfinishedStepsModalAcknowledged() {
        this.didShowCompleteUnfinishedStepsModal = true
        this.showCompleteUnfinishedStepsModal = false
    }

    public onStepCompleted(stepName: WelcomeChecklistStepName) {
        // check if the step has been completed yet
        const step = this.steps().find((step) => step.name === stepName)
        if (!step.complete) {
            // if not, complete it. the active step will change and the modal will update
            this.completeStep(stepName)
        } else {
            // if it is, manually move to the next step if there is one
            const openStepIndex =
                this.steps().findIndex((step) => step.name === this.openStep.name) + 1
            const nextStep = this.steps()[openStepIndex]
            if (nextStep) {
                this.showStep(nextStep)
            } else {
                // if there is no next step, close the modal
                this.closeModal()
            }
        }
    }

    public onHeaderStepClick(stepName: WelcomeChecklistStepName) {
        // log the clicked event
        const modalName = STEP_NAME_TO_MODAL_NAME[stepName]
        this.analyticsService.logClickedEvent(`Navigation Icon: ${modalName}`, this.source)

        // only allow moving to a previous
        const activeStepIndex = this.steps().findIndex(
            (step) => step.name === this.activeStep().name,
        )
        const clickedIndex = this.steps().findIndex((step) => step.name === stepName)
        if (clickedIndex > activeStepIndex) {
            return
        }

        // show the step
        this.showStep(this.steps()[clickedIndex])
    }

    private showStep(step: WelcomeChecklistStep) {
        this.openStep = step
    }

    public onCloseIconClick() {
        if (this.activeStep().name === 'acknowledgeCompletion') {
            this.onStepCompleted('acknowledgeCompletion')
        }

        this.closeModal()
    }

    public closeModal() {
        this.dialog.close()
    }
}
