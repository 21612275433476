import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { Router } from '@angular/router'
import { StripeService } from '@app/core/_services/stripe.service'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

@Component({
    selector: 'app-plan-limit-reached',
    templateUrl: './plan-limit-reached.component.html',
    styleUrls: ['./plan-limit-reached.component.scss'],
})
export class PlanLimitReachedComponent implements OnInit {
    public faTimes = faTimes

    constructor(
        public dialog: MatDialogRef<PlanLimitReachedComponent>,
        private analyticsService: AnalyticsService,
        private router: Router,
        private stripeService: StripeService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Session Limit: Basic')
    }

    closeDialog() {
        this.dialog.close({})
    }

    upgradeSelected(buttonText: string) {
        this.stripeService.setUpgradeSource('sessionLimitModal')
        this.analyticsService.logClickedEvent(buttonText, 'sessionLimitModal')
        this.router.navigate(['/upgrade'])
        this.dialog.close({})
    }
}
