import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@app/shared/shared.module'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { AnalyticsService, BookingSource } from '@app/core/_services/analytics.service'
import { Subject, filter, takeUntil } from 'rxjs'
import { NavigationStart, Router } from '@angular/router'

@Component({
    selector: 'fm-create-session-modal',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './create-session-modal.component.html',
    styleUrl: './create-session-modal.component.scss',
})
export class CreateSessionModalComponent {
    private ngUnsubscribe: Subject<any> = new Subject<any>()

    faTimes = faTimes

    bookingSource: BookingSource

    constructor(
        public dialog: MatDialogRef<CreateSessionModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private analyticsService: AnalyticsService,
        private router: Router,
    ) {
        this.analyticsService.logViewedModalEvent('Create Session')
        this.bookingSource = data.bookingSource

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(() => {
                this.dialog.close()
            })
    }

    onBookingCancel() {
        this.dialog.close({})
    }

    onBookingSuccess(ret: any) {
        this.dialog.close(ret)
    }

    onBookingFailure() {
        this.dialog.close({})
    }

    closeModalButtonClick() {
        this.dialog.close({})
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
