import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatDialogRef } from '@angular/material/dialog'
import { GoogleCalendarService } from '@app/core/_services/google-calendar.service'
import { SharedModule } from '@app/shared/shared.module'
import { faTimes, faExternalLink } from '@fortawesome/pro-solid-svg-icons'
import { OAuthService } from '@app/core/_services/oauth.service'
import { AnalyticsService } from '@app/core/_services/analytics.service'

@Component({
    selector: 'app-confirm-connect-google-account',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './confirm-connect-google-account.component.html',
    styleUrl: './confirm-connect-google-account.component.scss',
})
export class ConfirmConnectGoogleAccountComponent {
    faTimes = faTimes
    faExternalLink = faExternalLink

    public googleOauthUrl: string
    private shouldRedirect: boolean = false

    constructor(
        private dialog: MatDialogRef<ConfirmConnectGoogleAccountComponent>,
        private googleCalendarService: GoogleCalendarService,
        private oauthService: OAuthService,
        private analyticsService: AnalyticsService,
    ) {
        this.analyticsService.logViewedModalEvent('Confirm Connect Google Account')

        // call the backend to get the URI to redirect to
        this.oauthService.getGoogleOauthUrl().subscribe((r) => {
            if (r.url) {
                this.googleOauthUrl = r.url
            }
            if (this.shouldRedirect) {
                window.location.href = this.googleOauthUrl
            }
        })
    }

    onClickConnect(): void {
        this.analyticsService.logClickedEvent(
            'Connect Google Account',
            'Confirm Connect Google Account Modal',
        )
        this.shouldRedirect = true
        if (this.googleOauthUrl) {
            window.location.href = this.googleOauthUrl
        }
    }

    onCloseButtonClick(): void {
        this.dialog.close(false)
    }
}
