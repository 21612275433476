import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import {
    UnsnoozeUserRequest,
    UserRelationshipService,
} from '@app/core/_services/user-relationship.service'
import {
    USER_ACTION_NONE,
    USER_ACTION_UNSNOOZED,
} from '@app/user-actions/components/user-actions-menu/user-actions-menu.component'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

@Component({
    selector: 'app-unsnooze-user',
    templateUrl: './unsnooze-user.component.html',
    styleUrls: ['./unsnooze-user.component.scss'],
})
export class UnsnoozeUserComponent implements OnInit {
    public faTimes = faTimes

    constructor(
        public dialog: MatDialogRef<UnsnoozeUserComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private userRelationshipService: UserRelationshipService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {}

    unsnooze() {
        let unsnoozeInfo: UnsnoozeUserRequest = {
            userId: this.data.userId,
        }
        this.userRelationshipService.unsnoozeUser(unsnoozeInfo).subscribe((data) => {
            this.analyticsService.logUnsnoozedUser(this.data.source)
            this.dialog.close({ userAction: USER_ACTION_UNSNOOZED })
        })
    }

    public closeSnoozeModal() {
        this.dialog.close({ userAction: USER_ACTION_NONE })
    }
}
