import { Injectable } from '@angular/core'
import { User } from '../_models'

export const EXPERIMENT_VARIANT_CONTROL = 'control'
export const EXPERIMENT_VARIANT_EXPERIMENTAL = 'experimental'

@Injectable({
    providedIn: 'root',
})
export class ExperimentsService {
    constructor() {}

    private randomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    public isControlGroup(user: User): boolean {
        return parseInt(user.userId.slice(-1), 16) % 2 === 0
    }

    public getExperimentVariant(user) {
        if (this.isControlGroup(user)) {
            return EXPERIMENT_VARIANT_CONTROL
        } else {
            return EXPERIMENT_VARIANT_EXPERIMENTAL
        }
    }

    public isFeatureAvailable(featureId: string, user: User): boolean {
        if (user.properties.hasOwnProperty(featureId)) {
            return user.properties[featureId]
        } else {
            return false
        }
    }
}
