import { Component, Input, OnInit } from '@angular/core'

const BLANK_PROFILE_IMAGE = '../../../assets/images/blank_profile.png'

@Component({
    selector: 'app-profile-image',
    templateUrl: './profile-image.component.html',
    styleUrls: ['./profile-image.component.scss'],
})
export class ProfileImageComponent implements OnInit {
    @Input() profileImage: string
    @Input() profileImageIcon = null
    @Input() profileUrl: string
    @Input() snoozed: boolean = false
    @Input() snoozeTooltip: string

    @Input() saved: boolean = false
    @Input() size: string = 'small'

    public userImage: string
    public useIconImage: boolean = false

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges() {
        this.userImage = this.profileImage || BLANK_PROFILE_IMAGE

        if (this.userImage === BLANK_PROFILE_IMAGE && this.profileImageIcon) {
            this.useIconImage = true
        }
    }

    public onProfileImageFailedToLoad(event) {
        this.userImage = BLANK_PROFILE_IMAGE
    }
}
