import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'

@Component({
    selector: 'app-session-conflict',
    templateUrl: './session-conflict.component.html',
    styleUrls: ['./session-conflict.component.scss'],
})
export class SessionConflictComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private analyticsService: AnalyticsService,
        private dialog: MatDialogRef<SessionConflictComponent>,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Scheduling Conflict')
    }

    closeDialog() {
        this.dialog.close(false)
    }
}
