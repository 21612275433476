<div class="diag-container">
    <mat-icon (click)="closeDialog(false)" class="diag-close icon-size-16">clear</mat-icon>
    <div class="diag-header">
        <div class="diag-header-text fm-mt-50px fm-mb-30px" style="color: #2f3150">
            Confirm outage date/time range
        </div>
    </div>
    <div class="diag-body">
        <div class="diag-sub-header-text text-left fm-mt-10px fm-mb-10px" style="color: #2f3150">
            <p>Start time: {{ data.startTime }}</p>
            <p>End time: {{ data.endTime }}</p>
        </div>

        <div class="customHrDiag w-full" style="color: #2f3150"></div>
        <div class="diag-actions fm-mt-30px fm-mb-50px w-full" style="justify-content: center">
            <button
                style="margin-right: 16px; background: #fff !important"
                class="fm-btn fm-btn--sm fm-btn--outline-brand-1 action-btn"
                (click)="closeDialog(false)"
                >Cancel</button
            >
            <button
                style="margin-left: 16px"
                class="fm-btn fm-btn--sm action-btn"
                (click)="closeDialog(true)"
                >Set Status</button
            >
        </div>
    </div>
</div>
