<div
    class="flex flex-row items-center justify-between p-[12px] w-full bg-lemonade rounded-xl cursor-pointer shadow-fm-small-lower min-[951px]:shadow-none"
    (click)="checklistClick()">
    <div
        class="w-full text-left text-blurple font-semibold text-[16px] min-[951px]:text-[12px] font-avenir leading-5"
        >👋 WELCOME CHECKLIST</div
    >

    <div class="flex flex-row items-center justify-center gap-[20px]">
        <fm-welcome-checklist-progress-indicator
            class="text-[16px] min-[951px]:text-[13px]"
            [steps]="steps"
            [activeStep]="activeStep"></fm-welcome-checklist-progress-indicator>
        <fa-icon
            class="min-[951px]:hidden text-[20px] text-blurple"
            [icon]="faCircleChevronDown"></fa-icon>
    </div>
</div>
