import { Component, Inject } from '@angular/core'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { LateSessionCanceledComponent } from '../late-session-canceled/late-session-canceled.component'
import { AnalyticsService, SessionTileSource } from '@app/core/_services/analytics.service'
import { Router } from '@angular/router'
import { PlayToneService } from '@app/core/_services/play-tone.service'
import { DeviceDetectionService } from '@app/core/_services/device-detection.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { firstValueFrom } from 'rxjs'
import { CancelSessionService, FIVE_MINUTES_IN_MSECS } from '@app/core/_services'
import { ErrorHandlerService } from '@app/core/_services/error-handler.service'
import { UserService } from '@app/core/_services/user.service'
import * as Sentry from '@sentry/angular-ivy'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'

@Component({
    selector: 'app-force-rematch',
    templateUrl: './force-rematch.component.html',
    styleUrls: ['./force-rematch.component.scss'],
})
export class ForceRematchComponent {
    faTimes = faTimes

    private sessionTime: number
    public countdownTo: number
    public sessionTimeString: string
    public duration: number

    public xhrInProgress: boolean = false

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<LateSessionCanceledComponent>,
        private analyticsService: AnalyticsService,
        private router: Router,
        private playToneService: PlayToneService,
        private deviceDetectionService: DeviceDetectionService,
        private dialogService: DialogService,
        private cancelSessionService: CancelSessionService,
        private errorHandlerService: ErrorHandlerService,
        private userService: UserService,
        private dateFormat: UserDateFormattingService,
    ) {}

    async ngOnInit() {
        this.analyticsService.logViewedModalEvent('Force Rematch')

        this.sessionTime = this.data.sessionTime
        this.countdownTo = this.sessionTime + FIVE_MINUTES_IN_MSECS
        this.duration = this.data.duration / 1000 / 60
        this.sessionTimeString = this.dateFormat.formatShortHand(this.sessionTime)
        this.playToneService.playRematchPopupTone()
    }

    async joinButtonClick() {
        this.analyticsService.logClickedEvent('Join', 'Force Rematch Modal')

        // now that the session is in the seaching state, join it
        this.playToneService.playEndSessionToneMuted()

        if (!this.deviceDetectionService.isSupportedBrowserDaily()) {
            this.dialogService.openSafariWarning((this.sessionTime / 1000).toString())
        }

        const routerLink = `/session/${this.sessionTime / 1000}`
        const routerState = { data: { rematchAtStart: true } }
        this.router.navigate([routerLink], { state: routerState })

        this.closeModal()
    }

    handleCountdownFinished() {
        this.closeModal()
    }

    async cancelButtonClick() {
        if (this.xhrInProgress) {
            return
        }
        this.xhrInProgress = true

        this.analyticsService.logClickedEvent('Cancel', 'Force Rematch Modal')

        try {
            await firstValueFrom(
                this.cancelSessionService.cancelSession(
                    this.sessionTime,
                    SessionTileSource.ForceRematchModal,
                ),
            )
        } catch (error) {
            Sentry.captureException(error)
            this.errorHandlerService.handleErrorResponse(error)
        } finally {
            this.xhrInProgress = false
        }
        this.closeModal()
    }

    closeModal() {
        this.dialog.close()
    }
}
