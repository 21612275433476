import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { ApiService } from '@app/core/_services/api.service'
import { environment } from '@env/environment'
import { first } from 'rxjs/operators'

const API_KEY_URL = environment.api_url + 'api-key'

@Injectable({ providedIn: 'root' })
export class ApiKeyService {
    constructor(private apiService: ApiService) {}

    hasActiveApiKey() {
        return this.apiService.get(API_KEY_URL).pipe(first())
    }

    generateApiKey() {
        return this.apiService.post(API_KEY_URL).pipe(first())
    }
}
