import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpHeaders } from '@angular/common/http'
import { ConfirmedSessionsService } from '@app/core/_services/confirmed-sessions.service'
import {
    AnalyticsService,
    SessionTileSource,
    SessionTileSourceToString,
} from '@app/core/_services/analytics.service'
import { ApiService } from '@app/core/_services/api.service'
import { map } from 'rxjs/operators'
import { environment } from '@env/environment'

@Injectable()
export class CancelSessionService {
    constructor(
        private apiService: ApiService,
        private cos: ConfirmedSessionsService,
        private analyticsService: AnalyticsService,
    ) {}

    cancelSession(sessionTime: number, source: SessionTileSource): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json')

        return this.apiService
            .delete(
                environment.api_url +
                    'session/' +
                    sessionTime.toString() +
                    '?source=' +
                    SessionTileSourceToString[source]['Amplitude'],
                headers,
            )
            .pipe(
                map(
                    (data) => {
                        this.cos.removeSession(sessionTime)
                        this.analyticsService.logClickedEvent(
                            'Cancel Session',
                            SessionTileSourceToString[source]['Amplitude'],
                        )
                    },
                    (error) => {},
                ),
            )
    }

    ngOnDestroy() {}
}
