<div class="std-modal-container">
    <mat-icon (click)="closeDialog()" class="diag-close icon-size-16">clear</mat-icon>

    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="closeDialog()"></fa-icon>

    <div class="std-modal-header">
        <div class="std-modal-title std-modal-title--sentence wrap-word-break">
            Uh oh...that session is no longer available!
        </div>
    </div>

    <div class="std-modal-body">
        <div class="std-modal-body-text text-center">
            It could be because someone else booked it before you or the session was cancelled.</div
        >
        <div class="std-modal-action-container" style="width: 260px">
            <button class="f-btn f-btn--primary f-btn-250x w-full" (click)="closeDialog()"
                >Got it</button
            >
        </div>
    </div>
</div>
