<div class="relative box-border w-full h-full font-avenir">
    <!-- header -->
    <div class="flex items-center justify-center py-[36px] px-[30px]">
        <div class="text-blurple text-center text-26-36 font-semibold">
            First session done! 🎉
        </div>
    </div>

    <!-- body -->
    <div
        class="px-[30px] sm:px-[100px] flex flex-col items-center justify-center gap-[12px] text-14-19 text-bluegrey-2 text-center font-medium">
        Congrats! Onward to even greater focus.
    </div>

    <!-- bottom -->
    <div
        #doneButton
        class="w-full pt-[35px] pb-[50px] px-[30px] sm:pb-[60px] sm:px-[100px] flex flex-col items-center justify-center">
        <button class="f-btn f-btn--primary w-full" (click)="onDoneButtonClick()">Got it </button>
    </div>
</div>
