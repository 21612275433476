import { Injectable } from '@angular/core'
import DailyIframe from '@daily-co/daily-js'

// https://web.wurfl.io/#wurfl-js
interface WURFL {
    // Concatenation of brand name, model name and marketing name
    complete_device_name: string

    form_factor:
        | 'Desktop'
        | 'Tablet'
        | 'Smartphone'
        | 'Feature Phone'
        | 'Smart-TV'
        | 'Robot'
        | 'Other non-Mobile'
        | 'Other Mobile'

    // Returns true if the device is wireless (e.g. Wi-Fi, 3G, LTE). Specifically, a
    // tablet or mobile phone is considered wireless. Otherwise, a desktop or laptop are false.
    is_mobile: boolean
}

declare const WURFL: any
@Injectable({
    providedIn: 'root',
})
export class DeviceDetectionService {
    private userAgent: string = ''
    private wurfl: Promise<WURFL>

    constructor() {
        this.wurfl = new Promise((resolve, reject) => {
            const wurflScript = document.createElement('script')
            wurflScript.setAttribute('type', 'text/javascript')
            wurflScript.setAttribute('src', '//wurfl.io/wurfl.js')
            wurflScript.onload = () => resolve(WURFL)
            wurflScript.onerror = () => reject()
            document.getElementsByTagName('head')[0].appendChild(wurflScript)
        })

        if (window && window.navigator && window.navigator.userAgent) {
            this.userAgent = window.navigator.userAgent
        }
    }

    isMobile() {
        if (typeof WURFL !== 'undefined') {
            return WURFL.is_mobile
        }

        if (this.userAgent.match(/Android/i) || this.userAgent.match(/iP(ad|hone|od)/i)) {
            return true
        }
        return false
    }

    async isMobileAccurate(): Promise<boolean> {
        try {
            return (await this.wurfl).is_mobile
        } catch (e) {
            return this.isMobile()
        }
    }

    isMobileiOSNonSafari() {
        if (this.isMobileiOS()) {
            if (this.isSafari()) {
                return false
            } else {
                return true
            }
        }
        return false
    }

    isMobileiOS(): boolean {
        if (typeof WURFL !== 'undefined') {
            return !!WURFL.complete_device_name.match(/iP(ad|hone|od)/i)
        }
        return !!this.userAgent.match(/iP(ad|hone|od)/i)
    }

    isSafari() {
        if (/^((?!chrome|android|CriOS).)*safari/i.test(this.userAgent)) {
            return true
        }
        return false
    }

    isSupportedBrowserDaily(): boolean {
        const support: any = DailyIframe.supportedBrowser()

        if (support && support.hasOwnProperty('supported')) {
            return support['supported']
        } else {
            return true
        }
    }
}
