import {
    ChangeDetectorRef,
    Component,
    EmbeddedViewRef,
    EventEmitter,
    forwardRef,
    NgZone,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms'
import { debounceTime, filter, fromEvent, Subject, takeUntil } from 'rxjs'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { computePosition, autoUpdate, flip } from '@floating-ui/dom'

type TzOptionInfo = {
    value: string
    label: string
}

@Component({
    selector: 'fm-timezone-select',
    templateUrl: './fm-timezone-select.component.html',
    styleUrls: ['./fm-timezone-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FmTimezoneSelectComponent),
            multi: true,
        },
    ],
})
export class FmTimezoneSelectComponent implements ControlValueAccessor, OnInit {
    @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport
    @Output() selectChange = new EventEmitter()
    @Output() closed = new EventEmitter()

    private ngUnsubscribe: Subject<any> = new Subject<any>()

    visibleOptions = 4
    searchControl = new UntypedFormControl()
    faChevronDown = faChevronDown

    private view: EmbeddedViewRef<any>
    private originalOptions: Array<TzOptionInfo> = []
    public options: Array<TzOptionInfo> = []
    public model: TzOptionInfo

    private dropdownElement: HTMLElement = null
    private cleanupAutoUpdate: () => void = null

    constructor(
        private vcr: ViewContainerRef,
        private zone: NgZone,
        private cdr: ChangeDetectorRef,
    ) {
        this.originalOptions = moment.tz
            .names()
            .filter((tz) => this.filterTimezones(tz))
            .map((timezone) => ({
                value: timezone,
                label: this.createTimezoneLabel(timezone),
            }))

        const sortByZone = (a, b) => {
            let [ahh, amm] = a.label.split('GMT')[1].split(')')[0].split(':')
            let [bhh, bmm] = b.label.split('GMT')[1].split(')')[0].split(':')
            return +ahh * 60 + amm - (+bhh * 60 + bmm)
        }

        this.options = this.originalOptions.sort(sortByZone)
    }

    filterTimezones(timezone: string | null) {
        if (!timezone) {
            return false
        } else {
            if (timezone == 'US/Pacific-New') {
                return false
            } else {
                let checkPrefix = timezone.split('/')
                if (
                    checkPrefix[0] === 'US' ||
                    checkPrefix[0] === 'Africa' ||
                    checkPrefix[0] === 'America' ||
                    checkPrefix[0] === 'Asia' ||
                    checkPrefix[0] === 'Atlantic' ||
                    checkPrefix[0] === 'Australia' ||
                    checkPrefix[0] === 'Canada' ||
                    checkPrefix[0] === 'Europe' ||
                    checkPrefix[0] === 'Indian' ||
                    checkPrefix[0] === 'Pacific' ||
                    checkPrefix[0] === 'Antarctica'
                ) {
                    return true
                }
                return false
            }
        }
    }

    createTimezoneLabel(timezone: string): string {
        let offset = `(GMT${moment.tz(timezone).format('Z')})`
        let parts = timezone.split('/')
        let name: string

        const firstPart = parts[0]
        const lastPart = parts[parts.length - 1]
        if (firstPart !== lastPart) {
            name = `${firstPart} ${lastPart}`
        } else {
            name = `${firstPart}`
        }

        name = name.replaceAll('_', ' ')

        return `${offset} ${name}`
    }

    get isOpen() {
        return this.cleanupAutoUpdate !== null
    }

    ngOnInit() {
        this.searchControl.valueChanges
            .pipe(debounceTime(300), takeUntil(this.ngUnsubscribe))
            .subscribe((term) => this.search(term))
    }

    get label() {
        return this.model ? this.model.label : null
    }

    open(event: Event, dropdownTpl: TemplateRef<any>, origin: HTMLElement, toggle: boolean) {
        event.stopPropagation()

        if (!this.isOpen) {
            this.view = this.vcr.createEmbeddedView(dropdownTpl)
            const dropdown = this.view.rootNodes[0]

            document.body.appendChild(dropdown)
            dropdown.style.width = `${origin.offsetWidth}px`
            this.dropdownElement = dropdown

            this.zone.runOutsideAngular(() => {
                this.cleanupAutoUpdate = autoUpdate(origin, dropdown, () => {
                    computePosition(origin, dropdown, { middleware: [flip()] }).then(({ x, y }) => {
                        Object.assign(dropdown.style, {
                            left: `${x}px`,
                            top: `${y}px`,
                        })
                    })
                })

                setTimeout(() => {
                    // Due to the origin element changing on click
                    // the computation of the initial position of the dropdown
                    // is incorrect. trigger an update after the element change
                    // to re-calculate the correct position
                    this.cdkVirtualScrollViewport.scrollToIndex(this.getSelectedindex())
                })
            })

            this.handleClickOutside()
        } else if (toggle) {
            this.close()
            this.cdr.detectChanges()
        }
    }

    private getSelectedindex() {
        if (!this.model || !this.model.value) {
            return 0
        } else {
            return this.options.findIndex(
                (currentOption) => currentOption.value === this.model.value,
            )
        }
    }

    close() {
        this.closed.emit()
        this.view.destroy()
        this.searchControl.patchValue('')
        this.view = null
        this.cleanupAutoUpdate()
        this.cleanupAutoUpdate = null
        this.dropdownElement = null
    }

    select(option) {
        this.model = option
        this.selectChange.emit(option.value)
        this.onChange(option.value)
        this.close()
    }

    isActive(option) {
        if (!this.model) {
            return false
        }
        return option.value === this.model.value
    }

    search(value: string) {
        this.options = this.originalOptions.filter((option) =>
            option.label.toLowerCase().includes(value.toLowerCase()),
        )
        requestAnimationFrame(() => (this.visibleOptions = this.options.length || 1))
    }

    private handleClickOutside() {
        fromEvent(document, 'click')
            .pipe(
                filter(({ target }) => {
                    return this.dropdownElement.contains(target as HTMLElement) === false
                }),
                takeUntil(this.closed),
            )
            .subscribe(() => {
                this.close()
                this.cdr.detectChanges()
            })
    }

    ngOnDestroy() {
        if (this.cleanupAutoUpdate) {
            this.cleanupAutoUpdate()
        }
    }

    onChange: any = () => {}
    onTouch: any = () => {}

    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn
    }

    writeValue(value: any) {
        if (!value) {
            this.model = null
        } else {
            this.model = this.options.find((currentOption) => currentOption.value === value)
        }
    }
}
