import { Component, EventEmitter, Inject, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'

import {
    faCalendarDays,
    faDesktop,
    faArrowRight,
    faPlay,
    faArrowLeft,
    faPartyHorn,
} from '@fortawesome/pro-solid-svg-icons'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { WelcomeChecklistHeaderComponent } from '../welcome-checklist-header/welcome-checklist-header.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

@Component({
    selector: 'fm-learn-how-it-works-modal',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, WelcomeChecklistHeaderComponent],
    templateUrl: './learn-how-it-works-modal.component.html',
    styleUrl: './learn-how-it-works-modal.component.scss',
})
export class LearnHowItWorksModalComponent {
    @Output() nextStepButtonClick: EventEmitter<any> = new EventEmitter<any>()

    faCalendarDays = faCalendarDays
    faDesktop = faDesktop
    faArrowRight = faArrowRight
    faArrowLeft = faArrowLeft
    faPlay = faPlay
    faPartyHorn = faPartyHorn

    showingVideo = false

    constructor(private analyticsService: AnalyticsService) {
        this.analyticsService.logViewedModalEvent('Welcome Checklist Modal: How It Works')
    }

    showVideo() {
        this.showingVideo = true
    }

    hideVideo() {
        this.showingVideo = false
    }

    videoEnded(e) {
        this.analyticsService.logHowItWorksVideo('videoEnded', 'howItWorksWelcomeChecklistModal')
        this.analyticsService.logClickedEvent('endVideo', 'howItWorksWelcomeChecklistModal')
    }

    videoStarted(e) {
        this.analyticsService.logHowItWorksVideo('videoStarted', 'howItWorksWelcomeChecklistModal')
        this.analyticsService.logClickedEvent('playVideo', 'howItWorksWelcomeChecklistModal')
    }

    videoPaused(e) {
        this.analyticsService.logHowItWorksVideo('videoPaused', 'howItWorksWelcomeChecklistModal')
        this.analyticsService.logClickedEvent('pauseVideo', 'howItWorksWelcomeChecklistModal')
    }

    onNextStepButtonClick() {
        this.analyticsService.logClickedEvent('Next', 'Welcome Checklist Modal: How It Works')
        this.nextStepButtonClick.emit()
    }
}
