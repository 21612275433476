import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

@Component({
    selector: 'app-report-bad-behavior-confirm',
    templateUrl: './report-bad-behavior-confirm.component.html',
    styleUrls: ['./report-bad-behavior-confirm.component.scss'],
})
export class ReportBadBehaviorConfirmComponent implements OnInit {
    faTimes = faTimes

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<ReportBadBehaviorConfirmComponent>,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Report Bad Behavior: Complete')
    }
}
