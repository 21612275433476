<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div
            class="text-[22px] leading-normal font-avenir text-center font-semibold wrap-double-line-el"
            >We’re temporarily using a different
            <span class="text-nowrap"> video experience</span></div
        >
    </div>

    <div class="std-modal-body w-full">
        <div class="text-bluegrey text-sm leading-normal w-full text-left font-avenir">
            <div
                >Things may look slightly different but you can expect a productive Focusmate
                session as always!</div
            >
            <div class="!mt-3 font-semibold">Please allow mic and camera access when asked</div>
        </div>
    </div>
    <div class="std-modal-action-container">
        <button class="f-btn f-btn--primary f-btn-250" (click)="dialog.close()">Okay</button>
    </div>
</div>
