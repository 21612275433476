import { Component, HostListener, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { User } from '@app/core/_models'
import { DISMISSED_YEAR_IN_REVIEW_MODAL } from '@app/core/_services'
import { AnalyticsService, BookingSource } from '@app/core/_services/analytics.service'
import { ApiService } from '@app/core/_services/api.service'
import { DeviceDetectionService } from '@app/core/_services/device-detection.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { UserService } from '@app/core/_services/user.service'
import { environment } from '@env/environment'
import {
    faArrowDownToLine,
    faArrowLeft,
    faArrowRight,
    faShareNodes,
    faTimes,
} from '@fortawesome/pro-solid-svg-icons'
import { firstValueFrom } from 'rxjs'

const MODAL_NAME = 'Year in Review 2023'
const MODAL_NAME_SESSION_STATS = 'Year in Review 2023 - Sessions'
const MODAL_NAME_PARTNER_STATS = 'Year in Review 2023 - Partners'
const MODAL_NAME_USAGE_CATEGORY_VOLE = 'Year in Review 2023 - Category Vole'
const MODAL_NAME_USAGE_CATEGORY_PLATYPUS = 'Year in Review 2023 - Category Platypus'
const MODAL_NAME_USAGE_CATEGORY_ALPACA = 'Year in Review 2023 - Category Alpaca'
const MODAL_NAME_SUMMARY_VOLE = 'Year in Review 2023 - Summary (Vole)'
const MODAL_NAME_SUMMARY_PLATYPUS = 'Year in Review 2023 - Summary (Platypus)'
const MODAL_NAME_SUMMARY_ALPACA = 'Year in Review 2023 - Summary (Alpaca)'
const MODAL_NAME_DOWNLOAD_VOLE = 'Year in Review 2023 - Download (Vole)'
const MODAL_NAME_DOWNLOAD_PLATYPUS = 'Year in Review 2023 - Download (Platypus)'
const MODAL_NAME_DOWNLOAD_ALPACA = 'Year in Review 2023 - Download (Alpaca)'
const MODAL_NAME_NO_STAT = 'Year in Review 2023 - No data'

type YearInReviewStep =
    | 'initial'
    | 'sessionStats'
    | 'partnerStats'
    | 'userCategory'
    | 'summary'
    | 'noStatsAvailable'
    | 'download'

type UserCategoryStyles = {
    imageLocation: string
    modalGradiant: string
    modalPattern: string
    description: string
    title: string
    summaryImagLocation: string
    summaryGradiant: string
    summaryPattern: string
    downloadImageLocation: string
}

@Component({
    selector: 'app-year-in-review',
    templateUrl: './year-in-review.component.html',
    styleUrls: ['./year-in-review.component.scss'],
})
export class YearInReviewComponent implements OnInit {
    faTimes = faTimes
    faArrowRight = faArrowRight
    faArrowLeft = faArrowLeft
    faShareNodes = faShareNodes
    faDownload = faArrowDownToLine
    public currentStep: YearInReviewStep
    private currentUser: User = new User()

    shareModeDownload: boolean = false
    shareableImageSrc: string
    shareableImageBlob: Blob | null

    // Stats
    public totalMinutes: number
    public totalSessions: number
    public totalPartners: number
    public totalCountries: number
    public sessionTimeCategory: string
    private usageModalName: string
    private summaryModalName: string
    private downloadModalName: string

    public usageCategoryContentAndStyles = new Map<string, UserCategoryStyles>([
        [
            'am',
            {
                imageLocation: '../assets/images/year-in-review/alpaca.webp',
                description: 'You tend to be more focused in the AM, and that makes you an',
                modalGradiant: 'bg-[linear-gradient(180deg,_#E0D6EC_28.48%,_#DEE8FA_85.35%)]',
                modalPattern: 'bg-sun-pattern',
                title: 'AM Alpaca!',
                summaryImagLocation: '../assets/images/year-in-review/alpaca-summary.png',
                summaryGradiant: 'bg-[linear-gradient(180deg,_#E0D6EC_28.48%,_#DEE8FA_85.35%)]',
                summaryPattern: 'bg-confetti-alpaca-pattern',
                downloadImageLocation: '../assets/images/year-in-review/alpaca-download.png',
            },
        ],
        [
            'pm',
            {
                imageLocation: '../assets/images/year-in-review/platypus.webp',
                description: 'You tend to be more focused in the PM, and that makes you a',
                modalGradiant: 'bg-[linear-gradient(180deg,_#E0D6EC_28.48%,_#E5ECE7_85.35%)]',
                modalPattern: 'bg-moon-pattern',
                title: 'PM Platypus!',
                summaryImagLocation: '../assets/images/year-in-review/platypus-summary.png',
                summaryGradiant: 'bg-[linear-gradient(180deg,_#E0D6EC_28.48%,_#E5ECE7_85.35%)]',
                summaryPattern: 'bg-confetti-pattern',
                downloadImageLocation: '../assets/images/year-in-review/platypus-download.png',
            },
        ],
        [
            'ampm',
            {
                imageLocation: '../assets/images/year-in-review/vole.webp',
                description:
                    'You’re focused early in the day as well as later, and that makes you a',
                modalGradiant: 'bg-[linear-gradient(180deg,_#E0D6EC_28.48%,_#F8E2E2_85.35%)]',
                modalPattern: 'bg-clock-pattern',
                title: 'Versatile Vole!',
                summaryImagLocation: '../assets/images/year-in-review/vole-summary.png',
                summaryGradiant: 'bg-[linear-gradient(180deg,_#E0D6EC_28.48%,_#F8E2E2_85.35%)]',
                summaryPattern: 'bg-confetti-vole-pattern',
                downloadImageLocation: '../assets/images/year-in-review/vole-download.png',
            },
        ],
    ])

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key === 'ArrowLeft') {
            this.previousSelected()
        }
        if (event.key === 'ArrowRight') {
            this.nextSelected()
        }
        if (event.key === 'Escape') {
            this.dialog.close()
        }
    }

    constructor(
        private analyticsService: AnalyticsService,
        public userService: UserService,
        public dialog: MatDialogRef<YearInReviewComponent>,
        public apiService: ApiService,
        private dialogService: DialogService,
        private deviceDetectionService: DeviceDetectionService,
    ) {}

    async ngOnInit() {
        this.currentUser = await firstValueFrom(this.userService.currentUser)

        if (!this.currentUser.yearlyReview) {
            this.currentStep = 'noStatsAvailable'
            this.analyticsService.logViewedModalEvent(MODAL_NAME_NO_STAT)
            return
        }

        this.analyticsService.logViewedModalEvent(MODAL_NAME)

        this.currentStep = 'initial'
        this.totalMinutes = this.currentUser.yearlyReview.totalMinutes
        this.totalSessions = this.currentUser.yearlyReview.totalSessions
        this.totalPartners = this.currentUser.yearlyReview.totalPartners
        this.totalCountries = this.currentUser.yearlyReview.totalPartnerCountries
        this.sessionTimeCategory = this.currentUser.yearlyReview.usageCategory
        this.setModalNamesBasedOnUsageAnimal(this.sessionTimeCategory)

        this.shareableImageSrc = `${environment.mediaEndpoint}/2023/year-summary/${this.currentUser.yearlyReview?.imageUrlSlug}.webp`
        try {
            const resp = await fetch(this.shareableImageSrc)
            if (!resp.ok) {
                throw `${resp.status} ${resp.statusText}`
            }
            this.shareableImageBlob = await resp.blob()
            this.shareModeDownload = this.determineShareMode()
        } catch (e) {
            this.shareableImageBlob = null
            this.analyticsService.logReceivedError('Fetch Year in Focus Image', e.toString())
        }
    }

    private setModalNamesBasedOnUsageAnimal(sessionTimeCategory: string): void {
        if (sessionTimeCategory == 'am') {
            this.usageModalName = MODAL_NAME_USAGE_CATEGORY_ALPACA
            this.summaryModalName = MODAL_NAME_SUMMARY_ALPACA
            this.downloadModalName = MODAL_NAME_DOWNLOAD_ALPACA
        } else if (sessionTimeCategory == 'pm') {
            this.usageModalName = MODAL_NAME_USAGE_CATEGORY_PLATYPUS
            this.summaryModalName = MODAL_NAME_SUMMARY_PLATYPUS
            this.downloadModalName = MODAL_NAME_DOWNLOAD_PLATYPUS
        } else {
            this.usageModalName = MODAL_NAME_USAGE_CATEGORY_VOLE
            this.summaryModalName = MODAL_NAME_SUMMARY_VOLE
            this.downloadModalName = MODAL_NAME_DOWNLOAD_VOLE
        }
    }

    private determineShareMode(): boolean {
        const data = {
            title: 'My 2023 in Focus',
            text: "Here's my 2023 in Focus with Focusmate",
            files: [
                new File([this.shareableImageBlob], 'My2023InFocus.webp', {
                    type: this.shareableImageBlob.type,
                }),
            ],
        }

        if (this.deviceDetectionService.isMobileiOS() || this.deviceDetectionService.isSafari()) {
            return true
        }

        try {
            if (navigator.canShare(data)) {
            } else {
                return true
            }
        } catch (e) {
            return true
        }
        return false
    }

    async onClickShareButton(shareType: number) {
        if (this.shareModeDownload) {
            this.analyticsService.logClickedEvent('Share -> Download', MODAL_NAME)
            this.currentStep = 'download'
            this.analyticsService.logViewedModalEvent(this.downloadModalName)
        } else {
            this.analyticsService.logClickedEvent('Share', MODAL_NAME)
            const url = environment.homeUrl + '/year-in-focus'
            if (navigator.share) {
                // this is most likely a mobile device. open the native share feature
                const data = {
                    title: 'My 2023 in Focus',
                    text: "Here's my 2023 in Focus with Focusmate",
                    url: url,
                    files: [
                        new File([this.shareableImageBlob], 'My2023InFocus.webp', {
                            type: this.shareableImageBlob.type,
                        }),
                    ],
                }

                navigator.share(data)
            } else {
                // native share feature not available. switch to simple download option
                this.shareModeDownload = true
                this.analyticsService.logClickedEvent('Share -> Download', MODAL_NAME)
                this.currentStep = 'download'
                this.analyticsService.logViewedModalEvent(this.downloadModalName)
            }
        }
    }

    async onClickDownloadButton() {
        this.analyticsService.logClickedEvent('Download', this.downloadModalName)

        const blob = this.shareableImageBlob
        const link = document.createElement('a')
        link.download = 'My2023InFocus.webp'
        link.href = window.URL.createObjectURL(blob)
        link.click()
    }

    nextSelected() {
        if (this.currentStep == 'initial') {
            this.currentStep = 'sessionStats'
            this.analyticsService.logViewedModalEvent(MODAL_NAME_SESSION_STATS)
        } else if (this.currentStep == 'sessionStats') {
            this.currentStep = 'partnerStats'
            this.analyticsService.logViewedModalEvent(MODAL_NAME_PARTNER_STATS)
        } else if (this.currentStep == 'partnerStats') {
            this.currentStep = 'userCategory'
            this.analyticsService.logViewedModalEvent(this.usageModalName)
        } else if (this.currentStep == 'userCategory') {
            this.currentStep = 'summary'
            this.analyticsService.logViewedModalEvent(this.summaryModalName)
        }
    }

    previousSelected() {
        if (this.currentStep == 'sessionStats') {
            this.currentStep = 'initial'
            this.analyticsService.logViewedModalEvent(MODAL_NAME)
        } else if (this.currentStep == 'partnerStats') {
            this.currentStep = 'sessionStats'
            this.analyticsService.logViewedModalEvent(MODAL_NAME_SESSION_STATS)
        } else if (this.currentStep == 'userCategory') {
            this.currentStep = 'partnerStats'
            this.analyticsService.logViewedModalEvent(MODAL_NAME_PARTNER_STATS)
        } else if (this.currentStep == 'summary') {
            this.currentStep = 'userCategory'
            this.analyticsService.logViewedModalEvent(this.usageModalName)
        } else if (this.currentStep == 'download') {
            this.currentStep = 'summary'
            this.analyticsService.logViewedModalEvent(this.summaryModalName)
        }
    }

    openBookSessionModal() {
        this.analyticsService.logClickedEvent('Book session', MODAL_NAME_NO_STAT)
        this.dialog.close()
        this.dialogService.openCreateSessionModal(BookingSource.CreateSessionModalFromYearInReview)
    }

    ngOnDestroy(): void {
        if (this.currentUser.userId) {
            this.currentUser.properties[DISMISSED_YEAR_IN_REVIEW_MODAL] = true
            this.userService.update(this.currentUser).subscribe()
        }
    }
}
