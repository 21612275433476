import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core'
import { MatSelect } from '@angular/material/select'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

export type FmSelectOption<T = string> = {
    value: T
    name: string
    displayIcon?: any
    optionIcon?: any
}

@Component({
    selector: 'app-fm-select',
    templateUrl: './fm-select.component.html',
    styleUrls: ['./fm-select.component.scss'],
})
export class FmSelectComponent implements OnInit {
    @ViewChildren('fmSelect') selectElements: QueryList<MatSelect>
    @Input() optionsList: any[] = []
    @Input() currentValue: any
    @Input() fieldName: string
    @Input() forceUpdate: number
    @Input() disabled: boolean
    @Input() btnClasses: string
    @Input() panelClasses: string[] = ['f-select']
    @Input() placeholder: string = ''
    @Output() selectionChanged = new EventEmitter<any>()

    public displayString: string = ''
    faCaretDown = faCaretDown
    faClock = faClock
    public displayIcon: any = null

    constructor() {}

    ngOnInit(): void {
        this.getDisplayFromCurrentValue()
    }
    ngOnChanges() {
        this.getDisplayFromCurrentValue()
    }

    getDisplayFromCurrentValue() {
        for (let i = 0; i < this.optionsList.length; ++i) {
            if (this.optionsList[i].value === this.currentValue) {
                this.displayString = this.optionsList[i].name
                this.displayIcon = this.optionsList[i].displayIcon || null
                return
            }
        }
        this.displayString = this.placeholder
    }

    openSelectOptions() {
        for (let select of this.selectElements.toArray()) {
            if (select.id === this.fieldName) {
                select.open()
                break
            }
        }
    }

    // Keep materical design 48px for now.
    // panelOpenChange(state) {
    //     if(state) {
    //         for(let select of this.selectElements.toArray()) {
    //             if(select.id === this.fieldName) {
    //                 let pos = this.determineScrollPosition(this.currentValue);
    //                 select.panel.nativeElement.scrollTop = pos;
    //                 break;
    //             }
    //         }
    //     }
    // }
    // determineScrollPosition(value:any) {
    //     let position = 0;

    //     for(let i=0;i<this.optionsList.length;++i) {
    //         if(value === this.optionsList[i].value) {
    //             position = i;
    //             break;
    //         }
    //     }
    //     return position * (SELECT_LIST_OPTION_HEIGHT);
    // }

    selectionMade(event) {
        this.selectionChanged.emit(event.value)
    }
}
