import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'

@Component({
    selector: 'app-confirm-transaction',
    templateUrl: './confirm-transaction.component.html',
    styleUrls: ['./confirm-transaction.component.scss'],
})
export class ConfirmTransactionComponent implements OnInit {
    public faCheckCircle = faCheckCircle

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialogRef<ConfirmTransactionComponent>,
    ) {}

    ngOnInit() {
        setTimeout(() => {
            this.dialog.close({})
        }, this.data.delay)
    }
}
