import { Component, OnInit, Inject, Signal } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { EditSessionService } from '@app/core/_services/edit-session.service'
import { ConfirmedSessionsService } from '@app/core/_services/confirmed-sessions.service'
import {
    ConfirmedSessionsResponse,
    SESSION_ACTIVITY_TYPE_ANYTHING,
    SESSION_DURATION_25_MINS,
    SESSION_DURATION_75_MINS,
    SessionActivityType,
    SessionPreferenceFavorites,
} from '@app/core/_models/rest_api'
import { UtilsService } from '@app/core/_services/utils.service'
import { ErrorHandlerService } from '@app/core/_services/error-handler.service'
import { Container } from '@app/core/_models'
import { UserService } from '@app/core/_services/user.service'
import { CancelSessionService } from '@app/core/_services/cancel-session.service'
import { BookLimitCheckService } from '@app/core/_services/book-limit-check.service'
import { BookSessionsService, FeatureCheckService } from '@app/core/_services'
import { Subject } from 'rxjs'
import { filter, take, takeUntil } from 'rxjs/operators'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'
import { User } from '@app/core/_models'
import {
    BookingSource,
    SessionTileSource,
    AnalyticsService,
} from '@app/core/_services/analytics.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { faChevronCircleDown, faChevronCircleUp, faLock } from '@fortawesome/pro-solid-svg-icons'
import { MODAL_VERIFY_EMAIL_BOOKING } from '@app/shared/components/email-verify/email-verify.component'
import { TimeUtilitiesService } from '@app/core/_services/time-utilities.service'
import {
    SessionStateService,
    SESSION_STATE_INITIAL,
    SESSION_STATE_PENDING,
} from '@app/core/_services/session-state.service'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { WelcomeChecklistService } from '@app/core/_services/welcome-checklist.service'
import { NavigationStart, Router } from '@angular/router'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'
import { RewardsReferralService } from '@app/core/_services/rewards-referral.service'

const SESSION_DURATION_25_MINS_LABEL = '25m'
const SESSION_DURATION_50_MINS_LABEL = '50m'
const SESSION_DURATION_75_MINS_LABEL = '75m'

const UPDATE_PREFERENCES_ERROR_TEXT = 'Preferences cannot be changed once session has started.'

@Component({
    selector: 'app-session-info-card',
    templateUrl: './session-info-card.component.html',
    styleUrls: ['./session-info-card.component.scss'],
})
export class SessionInfoCardComponent implements OnInit {
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public confirmedSessionsArray: Container<ConfirmedSessionsResponse> =
        new Container<ConfirmedSessionsResponse>()
    public sessionInfo: ConfirmedSessionsResponse = new ConfirmedSessionsResponse()
    public messageUrl: string = ''
    public displayTime: string = ''
    public displayDate: string = ''
    public displayLength: string = ''
    public user: User = new User()
    public editEnable: boolean = false
    public title: string = ''

    public weeklySelected: boolean = false
    public dailySelected: boolean = false
    public bookRecurComplete: boolean = false
    public recurSuccess: string = ''

    public limitRemaining: number = 0
    public numSessions: number = 1
    public canCancel: boolean = true
    public showVideoButton: boolean = false
    public userPartOfGroup: boolean = false
    public VideoLinkSource = SessionTileSource

    private cancelInProgress: boolean = false
    public lockedInPartner: boolean = false
    faLock = faLock
    faInfoCircle = faInfoCircle
    faChevronCircleDown = faChevronCircleDown
    faChevronCircleUp = faChevronCircleUp

    public snoozeTooltip: string = ''
    public disableUpdatePreferencesButton: boolean = true
    public updatingPreferences: boolean = false
    public updateSessionTooltip: string = ''
    public hasSessionStarted: boolean = false
    public showUpdateError: boolean = false
    public showSettings: boolean = false
    private isWelcomeChecklistActive: Signal<boolean> =
        this.welcomeChecklistService.isWelcomeChecklistActive

    /* Prefences */
    public selectedFavoritesPreference: SessionPreferenceFavorites
    public selectedSessionActivityType: SessionActivityType
    public selectedQuietMode: boolean

    recurTimes = [
        { value: 1, viewValue: '1 Time' },
        { value: 2, viewValue: '2 Times' },
        { value: 3, viewValue: '3 Times' },
        { value: 4, viewValue: '4 Times' },
        { value: 5, viewValue: '5 Times' },
        { value: 6, viewValue: '6 Times' },
        { value: 7, viewValue: '7 Times' },
        { value: 8, viewValue: '8 Times' },
        { value: 9, viewValue: '9 Times' },
        { value: 10, viewValue: '10 Times' },
    ]

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public bookLimitCheckService: BookLimitCheckService,
        private editSessionService: EditSessionService,
        private utilsService: UtilsService,
        public userService: UserService,
        private cancelSessionService: CancelSessionService,
        private confirmedSessionsService: ConfirmedSessionsService,
        private bookSessionService: BookSessionsService,
        private errorHandlerService: ErrorHandlerService,
        public dialog: MatDialogRef<SessionInfoCardComponent>,
        private dialogService: DialogService,
        private analyticsService: AnalyticsService,
        private sessionStateService: SessionStateService,
        private dateFormat: UserDateFormattingService,
        private welcomeChecklistService: WelcomeChecklistService,
        private rewardsReferralService: RewardsReferralService,
        private router: Router,
    ) {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(() => {
                // If user navigates from current page, close the modal
                this.dialog.close()
            })
    }

    expandSettings() {
        this.showSettings = !this.showSettings
    }

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Session Info')
        this.canCancel = this.utilsService.canCancel(this.data.sessionTime)
        this.showVideoButton = this.utilsService.showVideoIcon(
            this.data.sessionTime,
            this.data.sessionDuration,
        )

        this.bookLimitCheckService.currentRemaining
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((limit) => {
                this.limitRemaining = limit
                if (this.limitRemaining <= 10) {
                    this.numSessions = limit
                } else {
                    this.numSessions = 10
                }
            })

        this.confirmedSessionsService.confirmedSessionsSubject
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((confirmSessions) => {
                this.confirmedSessionsArray = confirmSessions

                let temp = confirmSessions.getBySessionTime(this.data.sessionTime)
                this.canCancel = this.utilsService.canCancel(this.data.sessionTime)

                if (temp !== null) {
                    this.lockedInPartner = this.sessionStateService.isLockedInState(temp.state)
                    this.sessionInfo = confirmSessions.getBySessionTime(this.data.sessionTime)

                    if (
                        temp.state !== SESSION_STATE_PENDING &&
                        temp.state !== SESSION_STATE_INITIAL
                    ) {
                        this.showVideoButton = this.utilsService.showVideoIcon(
                            this.data.sessionTime,
                            this.data.sessionDuration,
                        )
                    } else {
                        this.showVideoButton = false
                    }
                } else {
                    this.sessionInfo = new ConfirmedSessionsResponse()
                }

                if (!this.editEnable) {
                    this.title = this.sessionInfo.title
                }

                if (this.selectedFavoritesPreference === undefined) {
                    this.selectedFavoritesPreference = this.sessionInfo.preferences.favorites.value
                }
                if (this.selectedSessionActivityType === undefined) {
                    this.selectedSessionActivityType = this.sessionInfo.activityType
                }
                if (this.selectedQuietMode === undefined) {
                    this.selectedQuietMode = this.sessionInfo.preferences.quietMode.value
                }
                this.displayLength = this.getDurationLabel(this.sessionInfo.duration)

                this.userService.currentUser.pipe(take(1)).subscribe((user) => {
                    this.user = user
                    if (
                        this.utilsService.hasSessionTimePassed(
                            this.sessionInfo.sessionTime,
                            this.utilsService.getCurrentTimestamp(),
                        )
                    ) {
                        this.updateSessionTooltip = UPDATE_PREFERENCES_ERROR_TEXT
                        this.hasSessionStarted = true
                    }

                    if (this.user.userId === '') {
                        this.dialog.close()
                    } else {
                        if (this.sessionInfo.snoozed === true) {
                            this.snoozeTooltip =
                                'Snoozed ' +
                                this.dateFormat.generateSnoozeUntilString(
                                    this.sessionInfo.snoozeExpiresAt,
                                    this.user.timeZone,
                                )
                        }

                        this.messageUrl = this.utilsService.buildMessageLinkUrl(
                            this.data.sessionId,
                            user.email,
                        )
                        this.displayTime = this.dateFormat.format(this.data.sessionTime, 'h:mma')
                        this.displayDate = this.dateFormat.format(
                            this.data.sessiontime,
                            'MMMM D, YYYY',
                        )

                        for (let group of this.user.group) {
                            this.userPartOfGroup = false
                            if (group.groupMember === true) {
                                this.userPartOfGroup = true
                                break
                            }
                        }
                    }
                })
            })
    }

    getDurationLabel(sessionDuration: number) {
        if (sessionDuration === SESSION_DURATION_25_MINS) {
            return SESSION_DURATION_25_MINS_LABEL
        } else if (sessionDuration === SESSION_DURATION_75_MINS) {
            return SESSION_DURATION_75_MINS_LABEL
        } else {
            return SESSION_DURATION_50_MINS_LABEL
        }
    }

    cancelEdit(e: Event) {
        this.title = this.sessionInfo.title
        this.editEnable = false
    }

    closeDialog() {
        this.dialog.close({})
    }

    saveEdit() {
        this.editSessionService.editSessionTitle(this.data.sessionTime, this.title).subscribe(
            (data) => {
                this.editEnable = false
            },
            (error) => {
                this.title = this.sessionInfo.title
                this.editEnable = false
            },
        )
    }

    toggleEdit() {
        this.editEnable = !this.editEnable
    }

    cancelSession() {
        if (!this.cancelInProgress) {
            this.cancelInProgress = true
            this.cancelSessionService
                .cancelSession(this.data.sessionTime, SessionTileSource.SessionInfoModal)
                .subscribe(
                    (res) => {
                        this.dialog.close({})
                    },
                    (error) => {
                        this.dialog.close({})
                    },
                )
        }
    }

    bookRecurringSessions(recType: number) {
        // Increment by one since first time slot is the existing
        // slot that is going to be repeated
        this.bookSessionService
            .bookRecurringSessions(
                this.data.sessionTime,
                recType,
                this.numSessions + 1,
                this.user.timeZone,
                this.sessionInfo.title,
                this.confirmedSessionsArray,
                this.sessionInfo.duration,
                this.selectedFavoritesPreference,
                this.selectedSessionActivityType,
                this.selectedQuietMode,
                BookingSource.SessionInfoModal,
            )
            .pipe(take(1))
            .subscribe(
                (sessions) => {
                    let parsedRes = this.bookSessionService.parseBookResponse(sessions)

                    if (parsedRes['numSuccess'] === 1) {
                        this.recurSuccess = 'Successfully scheduled 1 session!'
                        this.bookRecurComplete = true
                    } else if (parsedRes['numSuccess'] > 1) {
                        this.recurSuccess =
                            'Successfully scheduled ' + sessions.length + ' sessions!'
                        this.bookRecurComplete = true
                    }
                    this.weeklySelected = false
                    this.dailySelected = false

                    if (parsedRes['numSuccess'] !== 0) {
                        this.dialogService.openConfirmTransaction(
                            parsedRes['numSuccess'] === 1
                                ? 'Session booked!'
                                : parsedRes['numSuccess'] + ' sessions booked!',
                        )
                    }

                    if (this.user.emailVerified === false) {
                        this.dialogService
                            .openEmailVerificationDialog(MODAL_VERIFY_EMAIL_BOOKING)
                            .afterClosed()
                            .pipe(take(1))
                            .subscribe(() => {
                                this.processParsedResult(parsedRes)
                            })
                    } else {
                        this.processParsedResult(parsedRes)
                    }
                },
                (error) => {
                    this.errorHandlerService.handleErrorResponse(error)
                    this.bookRecurComplete = false
                    this.weeklySelected = false
                    this.dailySelected = false
                },
            )
    }

    processParsedResult(parsedRes) {
        if (parsedRes['planLimitReached'] === true) {
            this.dialogService.openPlanLimitReachedDialog()
        } else if (
            !this.isWelcomeChecklistActive() &&
            !this.user.properties.viewedSegmentationSurveyModal &&
            this.user.compSessions >= 3
        ) {
            this.dialogService.openSegmentationSurveyModal()
        } else if (
            !this.isWelcomeChecklistActive() &&
            this.rewardsReferralService.shouldSeeReferralPrompt(this.user)
        ) {
            this.dialogService.openReferralPromptModal()
        }
    }

    public trackProfileView() {
        this.analyticsService.logClickedEvent('Partner', 'sessionInfoModal')
        this.dialog.close({})
    }

    public onPreferenceChanged(e) {
        this.selectedFavoritesPreference = e
        this.disableUpdatePreferencesButton = this.shouldUpdatePreferencesButtonBeEnabled()
    }

    public onActivityChanged(e) {
        this.selectedSessionActivityType = e
        this.disableUpdatePreferencesButton = this.shouldUpdatePreferencesButtonBeEnabled()
    }

    public onQuietModeChanged(e) {
        this.selectedQuietMode = e
        this.disableUpdatePreferencesButton = this.shouldUpdatePreferencesButtonBeEnabled()
    }

    private shouldUpdatePreferencesButtonBeEnabled() {
        if (
            this.selectedFavoritesPreference != this.sessionInfo.preferences.favorites.value ||
            this.selectedQuietMode != this.sessionInfo.preferences.quietMode.value ||
            this.selectedSessionActivityType != this.sessionInfo.activityType
        ) {
            return false
        } else {
            return true
        }
    }

    public updatePreferences() {
        /* Once preference is updated allow for a small delay to allow the worker process
		on the backend to break up the match, if necessary, and find a new match
		 */
        this.updatingPreferences = true
        this.editSessionService
            .editSessionPreferences(
                this.data.sessionTime,
                this.selectedFavoritesPreference,
                this.selectedSessionActivityType,
                this.selectedQuietMode,
            )
            .subscribe(
                () => {
                    setTimeout(() => {
                        this.confirmedSessionsService.getConfirmedSessions().subscribe(() => {
                            this.updatingPreferences = false
                            this.disableUpdatePreferencesButton = true
                        })
                    }, 3000)
                },
                (error) => {
                    this.selectedFavoritesPreference = this.sessionInfo.preferences.favorites.value
                    this.selectedQuietMode = this.sessionInfo.preferences.quietMode.value
                    this.selectedSessionActivityType = this.sessionInfo.activityType
                    this.updatingPreferences = false
                    this.disableUpdatePreferencesButton = true

                    if (
                        this.utilsService.hasSessionTimePassed(
                            this.sessionInfo.sessionTime,
                            this.utilsService.getCurrentTimestamp(),
                        )
                    ) {
                        this.updateSessionTooltip = UPDATE_PREFERENCES_ERROR_TEXT
                        this.hasSessionStarted = true
                        this.showUpdatePreferencesError()
                    }
                },
            )
    }

    openInfoModal() {
        this.dialogService.openSessionPreferencesInfoModal()
    }

    private showUpdatePreferencesError() {
        this.showUpdateError = true
        setTimeout(() => {
            this.showUpdateError = false
        }, 4000)
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
