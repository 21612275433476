import { Component, HostListener, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { User } from '@app/core/_models'
import {
    SnoozeUserRequest,
    UserRelationshipService,
} from '@app/core/_services/user-relationship.service'
import { UserService } from '@app/core/_services/user.service'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import {
    faSlidersH,
    faTimesCircle,
    faSyncAlt,
    faBellSlash,
    faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { take } from 'rxjs/operators'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'
import { DialogService } from '@app/core/_services/dialog.service'
import { faUserFriends } from '@fortawesome/pro-light-svg-icons'
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import {
    SnoozeDurationSelected,
    SNOOZE_DURATION_24_HOURS,
    SNOOZE_DURATION_CUSTOM,
    SNOOZE_DURATION_INDEFINITELY,
    SNOOZE_DURATION_INVALID,
    SNOOZE_DURATION_ONE_HOUR,
    SNOOZE_DURATION_ONE_MONTH,
    SNOOZE_DURATION_ONE_WEEK,
    SNOOZE_DURATION_EIGHT_HOURS,
} from '@app/user-actions/components/snooze/fm-select-snooze-duration/fm-select-snooze-duration.component'
import {
    MAX_SET_TIMEOUT_VALUE,
    TimeUtilitiesService,
} from '@app/core/_services/time-utilities.service'
import {
    USER_ACTION_NONE,
    USER_ACTION_SNOOZED,
} from '@app/user-actions/components/user-actions-menu/user-actions-menu.component'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { SafeResourceUrl } from '@angular/platform-browser'
import { UtilsService } from '@app/core/_services'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'

type SnoozeModalContent = 'snooze' | 'snoozeInfo' | 'editSnooze'

const SNOOZE_UNTIL_UNSNOOZED = 'until you unsnooze them'

@Component({
    selector: 'app-snooze-user',
    templateUrl: './snooze-user.component.html',
    styleUrls: ['./snooze-user.component.scss'],
})
export class SnoozeUserComponent implements OnInit {
    @HostListener('window:focus', ['$event'])
    onFocus(event: any): void {
        this.monitorSelectedTimeForValidity()
    }
    @HostListener('window:blur', ['$event'])
    onBlur(event: any): void {
        this.clearTimer()
    }
    public disableSaveButton: boolean = false
    public faTimes = faTimes
    public faSlidersH = faSlidersH
    public faUserFriends = faUserFriends
    public faEyeSlash = faEyeSlash
    public faTimesCircle = faTimesCircle
    public faSyncAlt = faSyncAlt
    public faBellSlash = faBellSlash
    private currentUser: User = new User()
    private timerId: any
    public isCustomTimeValid: boolean = true
    public faInfoCircle = faInfoCircle

    public selectedDuration = SNOOZE_DURATION_INDEFINITELY
    public modalContent: SnoozeModalContent = 'snooze'
    public customValue: any
    public snoozedUntil: string = SNOOZE_UNTIL_UNSNOOZED
    public settingsUrl: SafeResourceUrl
    public inviteLink: SafeResourceUrl = this.utilsService.getInviteUrl()

    constructor(
        public dialog: MatDialogRef<SnoozeUserComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private userReletionshipService: UserRelationshipService,
        private userService: UserService,
        private dialogService: DialogService,
        private timeUtilitiesService: TimeUtilitiesService,
        private analyticsService: AnalyticsService,
        private utilsService: UtilsService,
        private dateFormat: UserDateFormattingService,
    ) {}

    ngOnInit(): void {
        this.userService.currentUser.pipe(take(1)).subscribe((currentUser) => {
            this.currentUser = currentUser
            this.settingsUrl = this.utilsService.getProfilePartnerPreferencesUrl()

            if (this.data.editSnooze) {
                this.modalContent = 'editSnooze'
                this.snoozedUntil = this.dateFormat.generateSnoozeUntilString(
                    this.data.snoozeExpiresAt,
                    this.currentUser.timeZone,
                )
                this.analyticsService.logViewedModalEvent('Snooze User: Edit')
            } else {
                this.analyticsService.logViewedModalEvent('Snooze User')
            }
        })
    }

    private getExpiresAtFromSelection(selection: string, now: moment.Moment) {
        now = now.set({ second: 0, millisecond: 0 })
        if (selection === SNOOZE_DURATION_INDEFINITELY) {
            return null
        } else if (selection === SNOOZE_DURATION_ONE_HOUR) {
            return now.clone().add(1, 'h').toISOString()
        } else if (selection === SNOOZE_DURATION_EIGHT_HOURS) {
            return now.clone().add(8, 'h').toISOString()
        } else if (selection === SNOOZE_DURATION_24_HOURS) {
            return now.clone().add(24, 'h').toISOString()
        } else if (selection === SNOOZE_DURATION_ONE_WEEK) {
            return now.clone().add(7, 'd').toISOString()
        } else if (selection === SNOOZE_DURATION_ONE_MONTH) {
            return now.clone().add(1, 'months').toISOString()
        } else if (selection === SNOOZE_DURATION_CUSTOM) {
            this.customValue = this.customValue.set({ second: 0, millisecond: 0 })
            return this.customValue.toISOString()
        }
    }

    submitForm() {
        let now = moment().tz(this.currentUser.timeZone)
        let expiresAt: string = this.getExpiresAtFromSelection(this.selectedDuration, now)
        let snoozedUntil: string = this.dateFormat.generateSnoozeUntilString(
            expiresAt,
            this.currentUser.timeZone,
        )
        let durationInMinutes = this.timeUtilitiesService.getDurationBetweenNowAndISOString(
            expiresAt,
            'minutes',
        )

        let snoozeInfo: SnoozeUserRequest = {
            userId: this.data.userId,
            expiresAt: expiresAt,
        }

        this.userReletionshipService.snoozeUser(snoozeInfo).subscribe((data) => {
            this.analyticsService.logSnoozedUser(
                this.selectedDuration,
                durationInMinutes,
                this.data.saved,
                this.data.source,
            )

            this.dialogService.openConfirmSnoozeUserModal(
                this.data.userId,
                this.data.displayName,
                snoozedUntil,
            )
            this.dialog.close({ userAction: USER_ACTION_SNOOZED, snoozeExpiresAt: expiresAt })
        })
    }

    public closeSnoozeModal() {
        this.dialog.close({ userAction: USER_ACTION_NONE })
    }

    updateSelectedDuration(snoozeDurationSelected: SnoozeDurationSelected) {
        this.selectedDuration = snoozeDurationSelected.selection
        this.disableSaveButton = this.selectedDuration == SNOOZE_DURATION_INVALID

        if (this.selectedDuration === SNOOZE_DURATION_CUSTOM) {
            this.customValue = snoozeDurationSelected.customValue.clone()
        }
        this.monitorSelectedTimeForValidity()
    }

    monitorSelectedTimeForValidity() {
        this.clearTimer()
        if (this.selectedDuration === SNOOZE_DURATION_CUSTOM) {
            let now = moment()
            let diffInMilliSeconds = Math.floor(
                moment.duration(this.customValue.diff(now)).as('milliseconds'),
            )

            if (diffInMilliSeconds > 0) {
                this.isCustomTimeValid = true
                if (diffInMilliSeconds < MAX_SET_TIMEOUT_VALUE) {
                    this.timerId = setTimeout(() => {
                        this.monitorSelectedTimeForValidity()
                    }, diffInMilliSeconds)
                }
            } else {
                this.isCustomTimeValid = false
            }
        }
    }

    clearTimer() {
        if (this.timerId) {
            clearTimeout(this.timerId)
            this.timerId = null
        }
    }

    showSnoozeSelection() {
        this.modalContent = 'snooze'
        this.analyticsService.logViewedModalEvent('Snooze User')
    }

    showSnoozeInfo() {
        this.modalContent = 'snoozeInfo'
        this.analyticsService.logViewedModalEvent('Snooze User: Info')
    }

    learnMoreClicked() {
        this.analyticsService.logClickedEvent('Learn more', 'Snooze User: Info')
    }

    settingsClicked() {
        this.analyticsService.logClickedEvent('Settings', 'Snooze User: Info')
    }
}
