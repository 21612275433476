<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()"></fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title"> Please check your device time </div>
    </div>
    <div class="std-modal-body">
        <div class="std-modal-body-text flex flex-col gap-3">
            <div
                >We've detected that your device time might be off by
                <span class="text-nowrap">({{ serverTimeService.systemTimeDifference() }}).</span>
                This can cause a number of issues, including:
            </div>
            <ul class="std-modal-body-text">
                <li
                    ><span class="font-semibold">No-shows:</span> if you join the session at the
                    wrong time, it can look like your partner was a no-show</li
                >
                <li
                    ><span class="font-semibold">Not being rematched:</span> if you try to request a
                    new partner at the wrong time, we won’t be able to find you a match</li
                >
                <li><span class="font-semibold">Issues logging in</span></li>
            </ul>

            <div
                >To resolve this, please click the button below for help fixing your device time.
                Once you’ve done that, just refresh this page to continue using Focusmate!</div
            >
        </div>
    </div>

    <div class="std-modal-action-container">
        <a
            class="f-btn f-btn--primary f-btn-250"
            (click)="onFixButtonClick()"
            href="http://support.focusmate.com/en/articles/9437218-how-to-reset-your-device-time"
            target="_blank"
            >Help me fix my device time</a
        >
    </div>
</div>
