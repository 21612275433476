import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Router } from '@angular/router'
import {
    SESSION_ACTIVITY_TYPE_ANYTHING,
    SESSION_ACTIVITY_TYPE_DESK,
    SESSION_ACTIVITY_TYPE_MOVING,
    SessionActivityType,
} from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { TimeUtilitiesService } from '@app/core/_services/time-utilities.service'
import { UserService } from '@app/core/_services/user.service'
import { faStar, faTimes, faVideo } from '@fortawesome/pro-solid-svg-icons'
import { take } from 'rxjs'

@Component({
    selector: 'app-session-preferences-info',
    templateUrl: './session-preferences-info.component.html',
    styleUrls: ['./session-preferences-info.component.scss'],
})
export class SessionPreferencesInfoComponent {
    public faUsersGlobe
    public faStar = faStar
    public faTimes = faTimes
    public faVideo = faVideo
    public currentUrl: string
    public activityDesk: SessionActivityType = SESSION_ACTIVITY_TYPE_DESK
    public activityAnything: SessionActivityType = SESSION_ACTIVITY_TYPE_ANYTHING
    public activityMoving: SessionActivityType = SESSION_ACTIVITY_TYPE_MOVING

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<SessionPreferencesInfoComponent>,
        private analyticsService: AnalyticsService,
        public userService: UserService,
        private timeUtilitiesService: TimeUtilitiesService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Session Preferences: Info')
        this.userService.currentUser.pipe(take(1)).subscribe((user) => {
            this.faUsersGlobe = this.timeUtilitiesService.determineGlobeIconFromTimezone(
                user.timeZone,
            )
        })
    }

    learnMoreClicked() {
        this.analyticsService.logClickedEvent('Learn more', 'Session Preferences: Info')
    }

    giveFeedbackClicked() {
        this.analyticsService.logClickedEvent('Give feedback', 'Session Preferences: Info')
    }

    settingsSelected() {
        this.analyticsService.logClickedEvent('Settings', 'Session Preferences: Info')
        this.router.navigate(['profile/edit-p'], { queryParams: { type: 'preferences' } })
        this.dialog.close()
    }
}
