import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'app-confirm-update-email-address',
    templateUrl: './confirm-update-email-address.component.html',
    styleUrls: ['./confirm-update-email-address.component.scss'],
})
export class ConfirmUpdateEmailAddressComponent implements OnInit {
    constructor(
        public dialog: MatDialogRef<ConfirmUpdateEmailAddressComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {}
}
