import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { UserAvailabiltyService } from '@app/core/_services/user-availabilty.service'
import { UserService } from '@app/core/_services/user.service'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

const MODAL_NAME = 'Confirm Invite Link Reset'

@Component({
    selector: 'app-confirm-reset-modal',
    templateUrl: './confirm-reset-modal.component.html',
    styleUrls: ['./confirm-reset-modal.component.scss'],
})
export class ConfirmResetModalComponent implements OnInit {
    faTimes = faTimes
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialogRef<ConfirmResetModalComponent>,
        private analyticsService: AnalyticsService,
        private userAvailabilityService: UserAvailabiltyService,
        private dialogService: DialogService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent(MODAL_NAME)
    }

    closeDialog() {
        this.dialog.close()
    }

    resetLink() {
        this.userAvailabilityService.resetAvailabilityLink().subscribe({
            next: (v) => this.handleResetResponse(v),
            error: (e) => this.handleResetError(e),
        })
    }

    handleResetResponse(v) {
        if (this.data.availabilityCode === v.availabilityCode) {
        } else {
            this.userService.repopulate()
            this.dialogService.openConfirmTransaction('Invite link reset')
        }
        this.dialog.close(v)
    }

    handleResetError(e) {
        this.dialog.close({ availabilityCode: this.data.availabilityCode })
    }
}
