<!-- <div class="flex flex-col items-center justify-start font-avenir h-full">
    <div class="text-left text-white font-avenir text-sm leading-4 font-semibold overflow-hidden">
        A new version of the application is ready to install. Reload the page to get the latest
        features and improvements.
    </div>
    <fm-button class="mt-3" (clicked)="reload()">Reload now</fm-button>
</div> -->
<h2 mat-dialog-title class="!text-blurple !font-semibold !font-avenir">Update Available</h2>
<mat-dialog-content class="!text-bluegrey !font-avenir">
    A new version of the application is ready to install. Reload the page to get the latest features
    and improvements.
</mat-dialog-content>
<mat-dialog-actions align="center">
    <a class="!w-[100px] f-link text-[16px] no-underline" (click)="close()">Later</a>
    <fm-button buttonSize="small" class="!w-[100px]" (clicked)="reload()">Reload now</fm-button>
</mat-dialog-actions>
