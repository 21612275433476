import { Component, OnInit } from '@angular/core'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { MatDialogRef } from '@angular/material/dialog'
import { UserService } from '@app/core/_services/user.service'
import { User } from '@app/core/_models'
import { take } from 'rxjs/operators'
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser'
import { environment } from '@env/environment'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

@Component({
    selector: 'app-offer-user-survey',
    templateUrl: './user-segmentation-survey.component.html',
    styleUrls: ['./user-segmentation-survey.component.scss'],
})
export class UserSegmentationSurveyComponent implements OnInit {
    public faTimes = faTimes

    private user: User = new User()
    public surveyLink: SafeResourceUrl = ''

    constructor(
        private userService: UserService,
        private sanitizer: DomSanitizer,
        public dialog: MatDialogRef<UserSegmentationSurveyComponent>,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.userService.currentUser.pipe(take(1)).subscribe((user) => {
            this.user = user
            this.surveyLink = this.sanitizer.bypassSecurityTrustResourceUrl(
                environment.segmentationSurveyJune2020TypeformLink +
                    '?user_id=' +
                    user.userId +
                    '&email=' +
                    encodeURIComponent(user.email),
            )

            this.updateUserProperty()
        })
        this.analyticsService.logViewedModalEvent('Segmentation Survey')
    }

    private updateUserProperty() {
        this.user.properties['viewedSegmentationSurveyModal'] = true
        this.userService.update(this.user, null).subscribe((res) => {})
    }

    closeDialog() {
        this.dialog.close()
    }

    trackSurveyVisited() {
        this.analyticsService.logClickedEvent('Go to the questions', 'Segmentation Survey')
        this.dialog.close()
    }
}
