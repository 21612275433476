<ng-container [ngSwitch]="icon">
    <ng-container *ngSwitchCase="'fm-icon-vertical-3-dot-menu'">
        <svg
            [ngClass]="classes"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.0354" cy="2.83186" r="2.83186" fill="#000000" />
            <circle cx="12.0354" cy="11.9646" r="2.83186" fill="#000000" />
            <circle cx="12.0354" cy="21.1682" r="2.83186" fill="#000000" />
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'fm-icon-snooze'">
        <svg
            [ngClass]="classes"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200">
            <path
                d="M400.13,200.22a100,100,0,1,1-100,100A100,100,0,0,1,400.13,200.22Zm0,180.64a80.65,80.65,0,1,0-80.64-80.64A80.36,80.36,0,0,0,400.13,380.86Zm-24.6-41.93a4.7,4.7,0,0,1-6.85-1.21L361,327.64a4.7,4.7,0,0,1,1.21-6.86L388.84,301V243.77a5.2,5.2,0,0,1,4.84-4.84h12.9a4.91,4.91,0,0,1,4.84,4.84V310.3a4.38,4.38,0,0,1-1.61,3.63Z"
                transform="translate(-300.13 -200.22)" />
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'fm-icon-edit-snooze'">
        <svg
            [ngClass]="classes"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200">
            <defs>
                <style>
                    .cls-1 {
                        fill-rule: evenodd;
                    }
                </style>
            </defs>
            <path
                class="cls-1"
                d="M500.13,300.17a100,100,0,1,0-100,100h.38a18.76,18.76,0,0,1-.3-5.5l.05-.59,2.34-13.3c-.82,0-1.64,0-2.47,0a80.65,80.65,0,1,1,80.65-80.65v.31a23.83,23.83,0,0,1,3.79-.31A22.43,22.43,0,0,1,500,306.3C500.07,304.28,500.13,302.23,500.13,300.17Zm-131.45,37.5a4.7,4.7,0,0,0,6.85,1.21l34.28-25a4.37,4.37,0,0,0,1.61-3.63V243.72a4.91,4.91,0,0,0-4.84-4.84h-12.9a5.2,5.2,0,0,0-4.84,4.84V301l-26.61,19.76a4.69,4.69,0,0,0-1.21,6.85Zm98.65-9.32-48.54,48.54a5.5,5.5,0,0,0-1.51,2.84L414.44,396a3.6,3.6,0,0,0,4.18,4.18l16.24-2.84a6.51,6.51,0,0,0,3-1.51l48.37-48.54Zm30.46,7.36a8.09,8.09,0,0,0,0-11.38l-7.53-7.53a8.16,8.16,0,0,0-5.7-2.34,7.77,7.77,0,0,0-5.69,2.34l-7.69,7.7,18.91,18.91Z"
                transform="translate(-300.13 -200.17)" />
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'fm-icon-unsnooze'">
        <svg
            [ngClass]="classes"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200">
            <defs>
                <style>
                    .cls-1 {
                        fill-rule: evenodd;
                    }
                </style>
            </defs>
            <path
                class="cls-1"
                d="M400.13,199.52a100.11,100.11,0,0,1,98.45,117.66,57.21,57.21,0,0,0-17.95-12.68q.15-2.47.15-5a80.65,80.65,0,1,0-80.65,80.65q2.5,0,5-.15a57.21,57.21,0,0,0,12.68,18,100,100,0,1,1-17.66-198.45Zm-24.6,138.71a4.7,4.7,0,0,1-6.85-1.21L361,326.94a4.69,4.69,0,0,1,1.21-6.85l26.61-19.76V243.07a5.2,5.2,0,0,1,4.84-4.84h12.9a4.91,4.91,0,0,1,4.84,4.84V309.6a4.37,4.37,0,0,1-1.61,3.63Zm60.15-18.56a42.91,42.91,0,1,1-15.4,15.4A42.45,42.45,0,0,1,435.68,319.67Zm35,50.55a4,4,0,0,0,0-5.69L463,356.66l7.87-7.86a4,4,0,1,0-5.69-5.7L457.27,351l-7.87-8a4,4,0,1,0-5.69,5.69l7.87,8-7.87,7.87a4,4,0,1,0,5.69,5.69l7.87-7.86,7.7,7.86A4,4,0,0,0,470.67,370.22Z"
                transform="translate(-300.13 -199.52)" />
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'fm-icon-unblock'">
        <svg
            [ngClass]="classes"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200">
            <defs>
                <style>
                    .cls-1 {
                        fill-rule: evenodd;
                    }
                </style>
            </defs>
            <path
                class="cls-1"
                d="M494.71,259l-9.53-9.61L471,263.57l9.15,9.15v55l-52.5,52.5h-55l-9.15-9.15L349.35,385.2l9.11,9.18q6.26,5.83,13.34,5.84h55.83A18.12,18.12,0,0,0,441,394.8l53.34-52.92q5.83-6.24,5.83-13.75V272.3A18.12,18.12,0,0,0,494.71,259Zm-47.88-19.55-22.5,22.5-7.27,7.28-21,21H359.71a10,10,0,0,0-10,10q.84,9.16,10,10H376l-6.64,6.63-1.64,1.64-28.43,28.43-19.2-19.2v-55l52.5-52.5h55Zm-8.35,36.65,49.29-49.3q5.89-7.07-.29-13.85-7.08-5.89-13.85-.29l-12.5,12.49L441.8,205.63a20.54,20.54,0,0,0-13.75-5.41H372.21a18.12,18.12,0,0,0-13.33,5.41L305.55,259a18.57,18.57,0,0,0-5.42,12.91v55.84a18.12,18.12,0,0,0,5.42,13.33L325.3,361l-12.46,12.46a9.95,9.95,0,0,0,0,14.14q7.07,5.9,14.14,0l54.93-54.93,1.64-1.63,47.65-47.66Zm-14.15,34.15h15.38q9.17-.84,9.59-10c-.43-4.65-2.37-7.69-5.85-9.13Z"
                transform="translate(-300.13 -200.22)" />
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'fm-icon-double-checkmarks'">
        <svg
            [ngClass]="classes"
            width="296"
            height="148"
            viewBox="0 0 296 148"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M207.2 14.8C207.2 18.8083 205.813 22.3542 203.038 25.4375L162.863 65.6124L141.674 44.4234L181.763 4.1625C184.846 1.3875 188.392 0 192.4 0C196.408 0 199.8 1.3875 202.575 4.1625C205.658 6.9375 207.2 10.4833 207.2 14.8ZM74.0613 112.326L95.1052 133.37L84.6375 143.838C81.5542 146.613 78.0083 148 74 148C69.9917 148 66.4458 146.613 63.3625 143.838L4.1625 84.6375C1.3875 81.5542 0 78.0083 0 74C0 69.6833 1.54167 66.1375 4.625 63.3625C7.4 60.5875 10.7917 59.2 14.8 59.2C18.8083 59.2 22.3542 60.5875 25.4375 63.3625L58.842 97.0851L58.8312 97.0959L61.1013 99.366L74 112.388L74.0613 112.326ZM291.837 25.4375C294.612 22.3542 296 18.8083 296 14.8C296 10.4833 294.458 6.9375 291.375 4.1625C288.6 1.3875 285.208 0 281.2 0C277.192 0 273.646 1.3875 270.562 4.1625L162.8 112.388L114.237 63.3625C111.154 60.5875 107.608 59.2 103.6 59.2C99.5915 59.2 96.1999 60.5875 93.4249 63.3625C90.3415 66.1375 88.7999 69.6833 88.7999 74C88.7999 78.0083 90.1874 81.5542 92.9624 84.6375L152.162 143.838C155.246 146.613 158.792 148 162.8 148C166.808 148 170.354 146.613 173.437 143.838L291.837 25.4375Z"
                fill="white" />
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'fm-icon-blank-profile'">
        <svg
            [ngClass]="classes"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="80" height="80" rx="40" fill="#D3D3D3" />
            <mask
                id="mask0_3232_10285"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="80"
                height="80">
                <rect width="80" height="80" rx="40" fill="#E3E4F2" />
            </mask>
            <g mask="url(#mask0_3232_10285)">
                <path
                    d="M39.9372 47.8075C43.0931 47.8075 46.1782 46.8717 48.8023 45.1183C51.4263 43.365 53.4716 40.8729 54.6793 37.9571C55.887 35.0414 56.203 31.8331 55.5873 28.7378C54.9716 25.6425 53.4519 22.7993 51.2203 20.5677C48.9887 18.3361 46.1455 16.8163 43.0502 16.2007C39.9549 15.585 36.7465 15.901 33.8308 17.1087C30.9151 18.3164 28.423 20.3616 26.6697 22.9857C24.9163 25.6098 23.9805 28.6948 23.9805 31.8508C23.9853 36.0813 25.668 40.1371 28.6594 43.1285C31.6509 46.1199 35.7067 47.8026 39.9372 47.8075V47.8075Z"
                    fill="#A9A9A9" />
                <path
                    d="M11.3224 82.9233H71.1251C71.1245 76.7554 69.2951 70.7262 65.8681 65.598C62.4411 60.4698 57.5705 56.4729 51.8721 54.1126C46.1737 51.7524 39.9034 51.1348 33.854 52.3379C27.8047 53.541 22.2478 56.5108 17.8862 60.8719C14.9809 63.7608 12.6775 67.1973 11.1093 70.9824C9.5411 74.7676 8.73923 78.8262 8.75011 82.9233H11.3224Z"
                    fill="#A9A9A9" />
            </g>
        </svg>
    </ng-container>
</ng-container>
