import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { environment } from '@env/environment'
import { UserService } from '@app/core/_services/user.service'
import { take } from 'rxjs/operators'

@Component({
    selector: 'app-safari-warning',
    templateUrl: './safari-warning.component.html',
    styleUrls: ['./safari-warning.component.scss'],
})
export class SafariWarningComponent implements OnInit {
    public sessionLink: string = ''

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private analyticsService: AnalyticsService,
        private userService: UserService,
        public dialog: MatDialogRef<SafariWarningComponent>,
    ) {}

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Unsupported Browser')
        this.userService.currentUser.pipe(take(1)).subscribe((user) => {
            this.sessionLink = environment.homeUrl + '/session/' + this.data.roomId
        })
    }

    public onCloseDialog() {
        this.dialog.close({})
    }

    confirmCopy() {}
}
