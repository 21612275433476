<div class="fm-d-container">
    <mat-icon (click)="closeDialog()" class="dialog-close icon-size-16">clear</mat-icon>
    <div class="fm-d-header">
        <div class="logos">
            <app-group-logo [logoUrl]="userPhoto" [logoClass]="'logo-signup-view'">
            </app-group-logo>

            <img style="margin-top: 50px" src="../../../../assets/images/group_arrow.svg" />

            <app-group-logo [logoUrl]="groupInfo.groupLogo" [logoClass]="'logo-signup-view'">
            </app-group-logo>
        </div>
        <div class="invite-text"
            >You've been invited to join the {{ groupInfo.groupName }} group on Focusmate!</div
        >
        <div class="invite-subtext"
            >You will be matched with a partner from your group when available.</div
        >
        <!-- <button (click)="acceptInvite()" class='fm-btn fm-btn--xl w-full'>Accept invitation</button> -->
        <app-loading-button
            class="w-full"
            classList="fm-btn fm-btn--xl w-full"
            label="Accept invitation"
            loadingLabel="Accepting invitation"
            [notValid]="false"
            (clicked)="acceptInvite()"></app-loading-button>
    </div>
</div>
