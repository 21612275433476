import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
    selector: 'fm-toggle-switch',
    templateUrl: './fm-toggle-switch.component.html',
    styleUrls: ['./fm-toggle-switch.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FmToggleSwitchComponent),
            multi: true,
        },
    ],
})
export class FmToggleSwitchComponent implements OnInit {
    @Input() disabled: boolean = false
    @Output() toggleChange = new EventEmitter<boolean>()

    onChange: any = () => {}
    onTouch: any = () => {}

    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn
    }

    ngOnInit() {}

    toggled: boolean = false
    writeValue(toggled: boolean) {
        this.toggled = toggled
    }

    toggleSwitch() {
        if (this.disabled) {
            return
        }
        this.toggled = !this.toggled
        this.onChange(this.toggled)
        this.toggleChange.emit(this.toggled)
    }
}
