<div class="flex flex-col items-center relative box-border w-full h-full font-avenir">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="onCloseButtonClick()"></fa-icon>

    <!-- header -->
    <div
        class="pt-[50px] pb-[36px] sm:pt-[60px] sm:pb-[35px] px-[20px] sm:px-[100px] flex flex-col gap-[5px] items-center justify-center">
        <div class="text-center text-26-36 font-semibold"> Google Calendar Integration </div>
    </div>

    <!-- body -->
    <div
        class="max-w-full px-[20px] sm:px-[100px] flex flex-col items-center justify-center gap-[12px] text-14-19 font-medium text-bluegrey-1">
        Which calendar should we add your sessions to?

        <div class="w-full">
            @if (xhrCalendars().state === 'loading' || xhrCalendars().state === 'initial') {
                <div class="flex flex-col items-center justify-center h-[40px] text-blurple"
                    >Fetching your calendars...</div
                >
            } @else if (xhrCalendars().state === 'error') {
                <div class="flex flex-col items-center justify-center h-[40px] text-red-1"
                    >There was an error loading your Google Calendars. Please try again later.</div
                >
            } @else if (xhrCalendars().state === 'success') {
                <mat-select
                    #gcalSelect
                    style="display: flex"
                    class="std-lg-select"
                    btnClasses="std-lg-select w-full"
                    panelClass="f-select"
                    panelWidth=""
                    placeholder="Select a calendar"
                    [value]="selectedCalendar()?.id"
                    name="gcalSelectList"
                    [disabled]="savingConnectedCalendar()"
                    disableOptionCentering>
                    <mat-select-trigger>
                        <div class="flex flex-row items-center gap-[10px]">
                            <div
                                [style.backgroundColor]="selectedCalendar()?.backgroundColor"
                                class="w-[10px] h-[10px] rounded-full shrink-0"></div>
                            <span
                                class="whitespace-nowrap overflow-hidden overflow-ellipsis pr-[10px]">
                                @if (selectedCalendar() && selectedCalendar().primary) {
                                    Primary Calendar ({{ selectedCalendar()?.name }})
                                } @else if (selectedCalendar() && selectedCalendar().name) {
                                    {{ selectedCalendar()?.name }}
                                }
                            </span>
                        </div>
                    </mat-select-trigger>
                    @for (cal of calendarListOptions(); track cal.id) {
                        <mat-option [value]="cal.id" (click)="onChangeSelectedCalendar(cal.id)">
                            <div
                                class="flex flex-row items-center gap-[10px] max-w-[70vw] sm:max-w-[50vw]">
                                <div
                                    [style.backgroundColor]="cal.backgroundColor"
                                    class="w-[10px] h-[10px] rounded-full shrink-0"></div>
                                <span class="whitespace-nowrap overflow-hidden overflow-ellipsis">
                                    @if (cal.primary) {
                                        Primary Calendar ({{ cal.name }})
                                    } @else {
                                        {{ cal.name }}
                                    }
                                </span>
                            </div>
                        </mat-option>
                    }
                </mat-select>
            }
        </div>
    </div>

    <div
        class="w-full sm:w-[400px] pt-[40px] pb-[50px] px-[30px] sm:pb-[60px] sm:px-[0px] flex flex-col items-center justify-center gap-[15px]">
        <app-spinner-button
            class="action"
            label="Save"
            [disable]="disableSaveConnectedCalendarButton()"
            classes="f-btn f-btn--primary f-btn-250"
            (clicked)="onSaveConnectedCalendarButtonClick()">
        </app-spinner-button>

        <div class="mt-[30px] text-bluegrey-2 text-12-16 font-medium">
            If you need to cancel or reschedule a session, please use the Focusmate dashboard.
            Canceling or rescheduling Google Calendar events will not affect your Focusmate
            sessions.
        </div>
    </div>
</div>
