import { Component, Output, EventEmitter, Input, Signal, computed, signal } from '@angular/core'
import { WelcomeChecklistProgressIndicatorComponent } from '../welcome-checklist-progress-indicator/welcome-checklist-progress-indicator.component'
import { faCircleChevronDown, faCircleChevronUp, faClose } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { CommonModule } from '@angular/common'
import {
    WelcomeChecklistStepName,
    WelcomeChecklistSteps,
} from '@app/core/_models/welcome-checklist'

@Component({
    selector: 'fm-welcome-checklist-header',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, WelcomeChecklistProgressIndicatorComponent],
    templateUrl: './welcome-checklist-header.component.html',
    styleUrl: './welcome-checklist-header.component.scss',
})
export class WelcomeChecklistHeaderComponent {
    @Output() closeIconClicked: EventEmitter<void> = new EventEmitter()
    @Output() stepClicked: EventEmitter<WelcomeChecklistStepName> = new EventEmitter()

    @Input() steps: Signal<WelcomeChecklistSteps> = null
    @Input() activeStep: Signal<WelcomeChecklistStepName> = signal(null)

    faCircleChevronDown = faCircleChevronDown
    faCircleChevronUp = faCircleChevronUp
    faClose = faClose

    constructor() {}

    onStepClick(stepName: WelcomeChecklistStepName) {
        this.stepClicked.emit(stepName)
    }

    onCloseIconClick() {
        this.closeIconClicked.emit()
    }
}
