<div class="std-modal-container">
    <div class="std-modal-header">
        <div class="std-modal-title long-title">You don't have a partner yet</div>
    </div>

    <div class="std-modal-body w-full">
        <div class="std-modal-body-text text-center">
            <p
                >If you previously had a partner, they might have canceled their session. The good
                news is that we can still find you a partner!</p
            >
        </div>

        <div class="std-modal-action-container">
            <button class="f-btn f-btn--primary f-btn-250" (click)="searchForPartnerButtonClick()"
                >Request partner</button
            >
            <a
                class="f-link fm-mt-15px"
                (click)="cancelSessionButtonClick()"
                style="text-decoration: none"
                >Cancel session</a
            >
        </div>

        <div class="modal-timer"
            >You have&nbsp;<app-fm-countdown
                [countdownTo]="countdownTo"
                (onCountdownFinished)="handleCountdownFinished()"></app-fm-countdown
            >&nbsp;to request a new partner.
        </div>
    </div>
</div>
