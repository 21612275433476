import { Injectable, inject } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { Observable, from } from 'rxjs'
import { Auth, user } from '@angular/fire/auth'
@Injectable({
    providedIn: 'root',
})
export class LoggedResolverService {
    private afAuth = inject(Auth)
    constructor() {}
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<any> | Observable<never> {
        return from(user(this.afAuth))
    }
}
