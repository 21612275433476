<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()"></fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title">What is your name?</div>
        <div class="std-modal-subtitle">Let other users know what to call you.</div>
    </div>

    <div class="std-modal-body w-full">
        <form #nameForm="ngForm" class="w-full flex-c-fs-c">
            <div class="f-form-field w-full field-spacer">
                <label for="firstName">First Name</label>
                <input
                    class="w-full"
                    maxlength="40"
                    type="text"
                    [(ngModel)]="formModel.firstName"
                    name="firstName"
                    required
                    appFmAutofocus
                    (ngModelChange)="onKey($event)" />
            </div>

            <div class="f-form-field w-full fm-mb-25px">
                <label for="familyName">Last Name</label>
                <input
                    class="w-full"
                    maxlength="40"
                    type="text"
                    [(ngModel)]="formModel.lastName"
                    name="lastName"
                    required
                    (ngModelChange)="onKey($event)" />
            </div>

            <div class="f-sep sep-w fm-mb-26px"></div>

            <div class="f-form-field-row w-full">
                <label class="display-as-label" for="displayName">Display as</label>
                <app-fm-select
                    style="margin-left: 5px; overflow: hidden"
                    [optionsList]="hideOptions"
                    [currentValue]="formModel.hideLastName"
                    [fieldName]="'displayNameOptions'"
                    [forceUpdate]="updateOptions"
                    [disabled]="disableDisplayOptions"
                    btnClasses="std-inline-select f-btn-100"
                    (selectionChanged)="updateHideLastNameSelection($event)"></app-fm-select>
            </div>
        </form>
    </div>
    <div class="std-modal-action-container">
        <app-spinner-button
            class="action"
            [disable]="disableSaveButton"
            [label]="'Save name'"
            classes="f-btn f-btn--primary f-btn-250"
            (clicked)="submitForm()">
        </app-spinner-button>

        <div class="f-link fm-mt-15px" (click)="dialog.close()">Cancel</div>
    </div>
</div>
