import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { VideoDialogComponent } from '@app/shared/components/video-dialog/video-dialog.component'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { VideoIconComponent } from '@app/shared/components/video-icon/video-icon.component'
import { MessageIconComponent } from '@app/shared/components/message-icon/message-icon.component'
import { SessionTileComponent } from '@app/shared/components/session-tile/session-tile.component'
import { SessionCardComponent } from '@app/shared/components/session-card/session-card.component'
import { AddToCalendarComponent } from '@app/shared/components/add-to-calendar/add-to-calendar.component'
import { VideoLightboxComponent } from '@app/shared/components/video-lightbox/video-lightbox.component'
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component'
import { CreateSessionComponent } from '@app/shared/components/create-session/create-session.component'
import { CreateSessionFormComponent } from '@app/shared/components/create-session/create-session-form.component'
import { TermsComponent } from '@app/shared/components/terms/terms.component'
import { EmailVerifyComponent } from '@app/shared/components/email-verify/email-verify.component'
import { CopyClipboardDirective } from '@app/shared/directives/fm-copy-clipboard'
import { FmAutofocusDirective } from '@app/shared/directives/fm-autofocus.directive'
import { FmDebounceClickDirective } from '@app/shared/directives/fm-debounce-click.directive'
import { NotYouSignOutComponent } from '@app/shared/components/not-you-sign-out/not-you-sign-out.component'

import { FmResizeObserverDirective } from './directives/fm-resize-observer.directive'
import { FmTimerComponent } from './components/fm-timer/fm-timer.component'
import { GroupLogoComponent } from './components/group-logo/group-logo.component'

import { HomeSharedModule } from '@app/home-shared/home-shared.module'
import { LoadingButtonComponent } from '@app/shared/components/loading-button/loading-button.component'
import { MaterialModule } from '@app/material/material.module'
import { FmCountdownComponent } from './components/fm-countdown/fm-countdown.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { SpinnerButtonComponent } from './components/spinner-button/spinner-button.component'
import { GroupTimezoneSelectorComponent } from './components/group-timezone-selector/group-timezone-selector.component'
import { FmSelectComponent } from './components/fm-select/fm-select.component'
import { FToggleComponent } from './components/f-toggle/f-toggle.component'
import { FeatureBannerComponent } from './components/feature-banner/feature-banner.component'
import { FmIconComponent } from './components/fm-icon/fm-icon.component'
import { FmDatetimePickerComponent } from './components/fm-datetime-picker/fm-datetime-picker.component'
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component'
import { BadgesComponent } from './components/badges/badges.component'
import { ProfileImageComponent } from './components/profile-image/profile-image.component'
import { FmCheckboxComponent } from '../standards-v2/components/fm-checkbox/fm-checkbox.component'
import { ModuleContainerComponent } from '../module-container/module-container.component'
import { UpdateAddressBannerComponent } from './components/update-address-banner/update-address-banner.component'
import { StandardsModule } from '@app/standards-v2/standards.module'
import { SessionPreferenceFavoritesComponent } from './components/session-preference-favorites/session-preference-favorites.component'
import { MaintenanceBannerComponent } from './components/maintenance-banner/maintenance-banner.component'
import { ActivityTypeIconComponent } from './components/activity-type-icon/activity-type-icon.component'
import { SessionPreferenceActivityComponent } from './components/session-preference-activity/session-preference-activity.component'
import { SessionPreferenceQuietModeComponent } from './components/session-preference-quiet-mode/session-preference-quiet-mode.component'
import { QuietModeIconComponent } from './components/quiet-mode-icon/quiet-mode-icon.component'

@NgModule({
    imports: [
        StandardsModule,
        CommonModule,
        FormsModule,
        HomeSharedModule,
        FontAwesomeModule,
        RouterModule,
        MaterialModule,
    ],
    exports: [
        FormsModule,
        VideoDialogComponent,
        VideoLightboxComponent,
        SpinnerComponent,
        TermsComponent,
        CreateSessionFormComponent,
        SessionTileComponent,
        SessionCardComponent,
        AddToCalendarComponent,
        VideoIconComponent,
        MessageIconComponent,
        CreateSessionComponent,
        EmailVerifyComponent,
        RouterModule,
        FmAutofocusDirective,
        FmDebounceClickDirective,
        NotYouSignOutComponent,
        GroupLogoComponent,
        CopyClipboardDirective,
        FmTimerComponent,
        LoadingButtonComponent,
        FmCountdownComponent,
        FontAwesomeModule,
        SpinnerButtonComponent,
        GroupTimezoneSelectorComponent,
        FmSelectComponent,
        FToggleComponent,
        FmResizeObserverDirective,
        FeatureBannerComponent,
        FmIconComponent,
        BadgesComponent,
        ProfileImageComponent,
        FmDatetimePickerComponent,
        LoadingIndicatorComponent,
        FmCheckboxComponent,
        UpdateAddressBannerComponent,
        SessionPreferenceFavoritesComponent,
        MaintenanceBannerComponent,
        ActivityTypeIconComponent,
        SessionPreferenceActivityComponent,
        SessionPreferenceQuietModeComponent,
        QuietModeIconComponent,
    ],
    declarations: [
        VideoDialogComponent,
        VideoLightboxComponent,
        SpinnerComponent,
        TermsComponent,
        CreateSessionFormComponent,
        SessionTileComponent,
        SessionCardComponent,
        AddToCalendarComponent,
        VideoIconComponent,
        MessageIconComponent,
        CreateSessionComponent,
        EmailVerifyComponent,
        FmAutofocusDirective,
        FmDebounceClickDirective,
        NotYouSignOutComponent,
        FmResizeObserverDirective,
        FmTimerComponent,
        GroupLogoComponent,
        CopyClipboardDirective,
        LoadingButtonComponent,
        FmCountdownComponent,
        SpinnerButtonComponent,
        GroupTimezoneSelectorComponent,
        FmSelectComponent,
        FToggleComponent,
        FeatureBannerComponent,
        FmIconComponent,
        BadgesComponent,
        ProfileImageComponent,
        FmDatetimePickerComponent,
        LoadingIndicatorComponent,
        FmCheckboxComponent,
        UpdateAddressBannerComponent,
        SessionPreferenceFavoritesComponent,
        MaintenanceBannerComponent,
        ActivityTypeIconComponent,
        SessionPreferenceActivityComponent,
        SessionPreferenceQuietModeComponent,
        QuietModeIconComponent,
    ],
})
export class SharedModule {}
