import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core'
import { UtilsService } from '@app/core/_services/utils.service'

export const COUNTDOWN_FORMAT_DEFAULT = 1 // Days:Hours:Minutes:Seconds
export const COUNTDOWN_FORMAT_SECONDS_ONLY = 2

@Component({
    selector: 'app-fm-countdown',
    templateUrl: './fm-countdown.component.html',
    styleUrls: ['./fm-countdown.component.scss'],
})
export class FmCountdownComponent implements OnInit {
    @Input() countdownTo: number
    @Input() countdownFormat: number = COUNTDOWN_FORMAT_DEFAULT
    @Output() onCountdownFinished: EventEmitter<any> = new EventEmitter<boolean>()

    public countdownDisplay: string = ''
    private countdownRunning: boolean = false
    private timeoutId: any

    constructor(private utilsService: UtilsService) {}

    ngOnInit() {
        this.initializeSessionStatusTimer()
    }

    initializeSessionStatusTimer() {
        if (this.countdownTo <= this.utilsService.getCurrentTimestamp()) {
            this.onCountdownFinished.emit(true)
        } else {
            if (this.countdownRunning !== true) {
                this.startCountdown()
            }
        }
    }

    private startCountdown() {
        let currentTime = this.utilsService.getCurrentTimestamp()
        let distance = this.countdownTo - currentTime

        if (this.countdownFormat == COUNTDOWN_FORMAT_SECONDS_ONLY) {
            this.countdownDisplay = this.utilsService.getTimerNumberOfSeconds(distance)
        } else {
            this.countdownDisplay = this.utilsService.getTimerDisplay(distance)
        }

        if (distance < 0) {
            this.clearCountdownTimer()
            this.onCountdownFinished.emit(true)
        } else {
            this.timeoutId = setTimeout(() => {
                this.startCountdown()
            }, 1000)
        }
    }

    private clearCountdownTimer() {
        if (this.countdownRunning && this.timeoutId) {
            clearTimeout(this.timeoutId)
            this.countdownRunning = false
        }
    }

    ngOnDestroy() {
        this.clearCountdownTimer()
    }
}
