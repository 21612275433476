import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuard } from '@app/core/_guards'
import { PeopleComponent } from './people.component'
import { PeopleListComponent } from '@app/people/components/people-list/people-list.component'
import { UserResolver } from '@app/core/_resolvers/user.resolver'

const routes: Routes = [
    {
        path: '',
        component: PeopleComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                resolve: { currentUser: UserResolver },
                component: PeopleListComponent,
                data: { robots: 'noindex, nofollow' },
            },
        ],
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PeopleRoutingModule {}
