import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { User } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { PlayToneService } from '@app/core/_services/play-tone.service'
import { UserService } from '@app/core/_services/user.service'
import { UtilsService } from '@app/core/_services/utils.service'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { firstValueFrom } from 'rxjs'

export const PARTNER_IS_LATE_ACTION_WAIT = 1
export const PARTNER_IS_LATE_ACTION_REQUEST_NEW_PARTNER = 2

@Component({
    selector: 'app-partner-is-late',
    templateUrl: './partner-is-late.component.html',
    styleUrls: ['./partner-is-late.component.scss'],
})
export class PartnerIsLateComponent implements OnInit {
    public showMore: boolean = false
    public countdownTo: number
    faTimes = faTimes

    public waitingForPartner: boolean = false

    @ViewChild('modalContent') modalWrapperRef: ElementRef
    @ViewChild('autoRematchLoadingDiv') autoRematchLoadingDiv: ElementRef

    public user: User
    public autoRematchCheckboxDisabled: boolean = true
    public autoRematchEnabled: boolean = false
    public autoRematchAtTimestamp: number = null

    private autoRematchTimeout: any = null

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private analyticsService: AnalyticsService,
        private util: UtilsService,
        public dialog: MatDialogRef<PartnerIsLateComponent>,
        public playToneService: PlayToneService,
        public userService: UserService,
    ) {}

    async ngOnInit() {
        let now = this.util.getCurrentTimestamp()

        if (now >= this.data.countdownTo) {
            this.handleCountdownFinished()
        } else {
            this.countdownTo = this.data.countdownTo
        }
        this.analyticsService.logViewedModalEvent('Partner Is Late')
        this.playToneService.playRematchPopupTone()

        this.user = await firstValueFrom(this.userService.currentUser)
        this.autoRematchEnabled = this.user.settings.autoRematchEnabled
        this.autoRematchCheckboxDisabled = false

        if (this.autoRematchEnabled) {
            this.kickOffAutoRematch()
        }
    }

    public handleCountdownFinished() {
        this.dialog.close(PARTNER_IS_LATE_ACTION_WAIT)
    }

    closeModal() {
        this.analyticsService.logClickedEvent('Close', 'partnerIsLateModal')
        this.dialog.close(PARTNER_IS_LATE_ACTION_WAIT)
    }

    waitForPartner() {
        // cancel auto rematch if it's running
        this.cancelAutoRematch()

        // we have to do some CSS/HTML magic to animate the modal height
        const wrapperEl = this.modalWrapperRef.nativeElement
        const previousHeight = getComputedStyle(wrapperEl).height
        wrapperEl.style.height = previousHeight // needs to be set explicitly

        this.waitingForPartner = true
        requestAnimationFrame(() => {
            wrapperEl.style.height = 'auto'
            const newPixelHeight = getComputedStyle(wrapperEl).height
            wrapperEl.style.height = previousHeight

            requestAnimationFrame(() => {
                wrapperEl.style.height = newPixelHeight
                wrapperEl.firstChild.style.opacity = 1
            })
        })

        this.analyticsService.logClickedEvent('Wait for Partner', 'partnerIsLateModal')
        this.waitingForPartner = true
    }

    public learnMore() {
        this.analyticsService.logClickedEvent('Learn more', 'partnerIsLateModal')
        this.showMore = true
    }

    public onRequestNewPartnerButtonClick() {
        this.analyticsService.logClickedEvent('Request new partner', 'partnerIsLateModal')
        this.requestNewPartner()
    }

    public requestNewPartner() {
        this.dialog.close(PARTNER_IS_LATE_ACTION_REQUEST_NEW_PARTNER)
    }

    public onClickAutoRematchCheckbox() {
        if (!this.autoRematchEnabled) {
            this.cancelAutoRematch()
        }
        this.autoRematchCheckboxDisabled = true
        this.analyticsService.logClickedEvent('Toggle Auto Rematch', 'partnerIsLateModal', {
            value: this.autoRematchEnabled,
        })
        this.analyticsService.logUpdatedSettingEvent(
            'autoRematchEnabled',
            this.autoRematchEnabled,
            !this.autoRematchEnabled,
            'Partner Is Late Modal',
        )
        this.userService
            .updateSettings({ autoRematchEnabled: this.autoRematchEnabled })
            .subscribe(() => {
                this.autoRematchCheckboxDisabled = false
            })
    }

    public kickOffAutoRematch() {
        this.autoRematchAtTimestamp = this.util.getCurrentTimestamp() + 10000

        // start the loading bar animation
        const loadingBar = this.autoRematchLoadingDiv.nativeElement
        loadingBar.style.transitionDuration = '10s'
        requestAnimationFrame(() => {
            loadingBar.style.width = '100%'
        })

        // start a timer for 10 seconds
        this.autoRematchTimeout = setTimeout(() => {
            this.analyticsService.logAutoRematchEvent()
            this.requestNewPartner()
        }, 10000)
    }

    public cancelAutoRematch() {
        this.analyticsService.logAutoRematchCanceledEvent()

        this.autoRematchAtTimestamp = null

        // stop the loading bar animation
        const loadingBar = this.autoRematchLoadingDiv.nativeElement
        if (loadingBar) {
            loadingBar.style.transitionDuration = '0s'
            requestAnimationFrame(() => {
                loadingBar.style.width = '0px'
            })
        }

        // cancel the timer
        if (this.autoRematchTimeout) {
            clearTimeout(this.autoRematchTimeout)
            this.autoRematchTimeout = null
        }
    }

    public ngOnDestroy() {
        if (this.autoRematchTimeout) {
            clearTimeout(this.autoRematchTimeout)
            this.autoRematchTimeout = null
        }
    }
}
