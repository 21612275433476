import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { SessionQueryInfo } from '@app/core/_models/session_query_info'

@Injectable()
export class DateSelectedService {
    private dateSelected: SessionQueryInfo
    public dateSelected$: Subject<SessionQueryInfo> = new Subject<SessionQueryInfo>()

    setDateSelected(dateSelected: SessionQueryInfo): void {
        this.dateSelected = dateSelected
        this.dateSelected$.next(this.dateSelected)
    }
}
