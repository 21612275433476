import { Injectable } from '@angular/core'
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'

@Injectable()
export class SnackbarService {
    constructor(public snackBar: MatSnackBar) {}

    createFailureNotification(message: string, action: string, duration = 2000) {
        let config: MatSnackBarConfig = {
            duration,
            panelClass: ['fm-success-alert'],
        }
        this.snackBar.open(message, action, config)
    }

    ngOnDestroy() {}
}
