<div class="play-tone-failed-container">
    <!--<mat-icon (click)="closeDialog()" class="close-icon icon-size-16">clear</mat-icon> -->

    <div class="text">Your browser blocked Focusmate from playing a sound.</div>
    <div class="buttons-container"
        ><a
            class="button"
            target="_blank"
            href="{{ supportArticleUrl }}"
            (click)="learnMoreClicked()"
            >Learn More</a
        >
        <button class="button" (click)="closeDialog()">Dismiss</button>
    </div>
</div>
