import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { User } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserService } from '@app/core/_services/user.service'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Subject, takeUntil } from 'rxjs'

interface RadioButtonInfo {
    value: boolean
    label: string
}

export const MATCH_QUIET_MODE_ANYTIME =
    'I’m okay being matched with someone in Quiet Mode even if I’m not in Quiet Mode'
export const MATCH_QUIET_MODE_WHEN_IN_QUIET_MODE_ONLY =
    'Don’t match me with someone in Quiet Mode unless I’m also in Quiet Mode'

@Component({
    selector: 'app-edit-quiet-mode-allowed-modal',
    templateUrl: './edit-quiet-mode-allowed-modal.component.html',
    styleUrl: './edit-quiet-mode-allowed-modal.component.scss',
})
export class EditQuietModeAllowedModalComponent {
    public radioButtonInfo: RadioButtonInfo[] = [
        {
            value: true,
            label: MATCH_QUIET_MODE_ANYTIME,
        },
        {
            value: false,
            label: MATCH_QUIET_MODE_WHEN_IN_QUIET_MODE_ONLY,
        },
    ]

    public faTimes = faTimes
    public initialPreferenceSelected: boolean
    public quietModeAllowed: boolean

    public disableSaveButton = true
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    private currentUser: User = new User()

    constructor(
        public dialog: MatDialogRef<EditQuietModeAllowedModalComponent>,
        public userService: UserService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Quiet Mode Preference')
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.currentUser = user
            this.quietModeAllowed = this.currentUser.settings.quietModeMatchAllowed ?? true
            this.initialPreferenceSelected = this.quietModeAllowed

            this.checkEnableSaveButton()
        })
    }

    radioButtonChanged() {
        this.checkEnableSaveButton()
    }

    checkEnableSaveButton(): void {
        this.disableSaveButton = this.quietModeAllowed === this.initialPreferenceSelected
    }

    public submitForm() {
        this.userService
            .updateSettings({ quietModeMatchAllowed: this.quietModeAllowed })
            .subscribe(() => {
                this.dialog.close()
            })
    }

    learnMoreClicked() {
        this.analyticsService.logClickedEvent('More about Quiet Mode', 'Quiet Mode Preference')
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
