import { Injectable, WritableSignal, signal } from '@angular/core'
import { Observable, catchError, firstValueFrom, map, of } from 'rxjs'
import { ApiService } from './api.service'
import { environment } from '@env/environment'
import { AnalyticsService } from './analytics.service'
import { TimeUtilitiesService } from './time-utilities.service'

const MAX_RETRIES = 5
const TIME_OUT_OF_SYNC_BANNER_NAME = 'Device Time Out of Sync'

interface ServerTimeData {
    serverTime: number
}

@Injectable({
    providedIn: 'root',
})
export class ServerTimeService {
    constructor(
        private apiService: ApiService,
        private analyticsService: AnalyticsService,
        private timeUtilitiesService: TimeUtilitiesService,
    ) {}
    public systemTimeOutOfSync: WritableSignal<boolean> = signal(false)
    public systemTimeDifference: WritableSignal<string | null> = signal(null)
    private retryAttempts: number = 0

    getServerTime(): Observable<any> {
        return this.apiService.getAnonymous(environment.api_url + 'server-time').pipe(
            map((data: ServerTimeData) => {
                return data.serverTime
            }),
            catchError((error) => {
                return of(null)
            }),
        )
    }

    public async checkDeviceTime() {
        const beforeTime = Date.now()
        const allowableDifference = 30000 // 30 seconds
        const allowedResponseDelay = 5000 // 5 seconds
        const serverTime = await firstValueFrom(this.getServerTime())
        const afterTime = Date.now()

        if (afterTime - beforeTime > allowedResponseDelay || serverTime == null) {
            this.retryCheckDeviceTime()
            return
        }

        if (serverTime) {
            this.retryAttempts = 0
            const timeDifference = serverTime - afterTime

            if (Math.abs(timeDifference) >= allowableDifference) {
                this.systemTimeOutOfSync.set(true)
                this.systemTimeDifference.set(
                    this.timeUtilitiesService.buildDurationStringFromMiliseconds(timeDifference),
                )
                this.analyticsService.logViewedBannerEvent(TIME_OUT_OF_SYNC_BANNER_NAME, {
                    timeDifference,
                })
            }
        }
    }

    private retryCheckDeviceTime() {
        setTimeout(() => {
            if (this.retryAttempts < MAX_RETRIES) {
                this.checkDeviceTime()
                this.retryAttempts += 1
            }
        }, 10000)
    }
}
