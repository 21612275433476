import { Component, OnInit, Inject } from '@angular/core'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { UtilsService } from '@app/core/_services/utils.service'

export const USER_IS_LATE_ACTION_CANCEL = 1
export const USER_IS_LATE_ACTION_REQUEST_NEW_PARTNER = 2
export const USER_IS_LATE_ACTION_COUNTDOWN_EXPIRED = 3

@Component({
    selector: 'app-user-late-partner-searching',
    templateUrl: './user-late-partner-searching.component.html',
    styleUrls: ['./user-late-partner-searching.component.scss'],
})
export class UserLatePartnerSearchingComponent implements OnInit {
    public countdownTo: number

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private util: UtilsService,
        private analyticsService: AnalyticsService,
        public dialog: MatDialogRef<UserLatePartnerSearchingComponent>,
    ) {}

    ngOnInit(): void {
        let now = this.util.getCurrentTimestamp()

        if (now >= this.data.countdownTo) {
            this.handleCountdownFinished()
        } else {
            this.countdownTo = this.data.countdownTo
        }

        this.analyticsService.logViewedModalEvent('Late: Change Your Partner')
    }

    public handleCountdownFinished() {
        this.dialog.close(USER_IS_LATE_ACTION_COUNTDOWN_EXPIRED)
    }

    public requestNewPartner() {
        this.analyticsService.logClickedEvent('Request new partner', 'lateToSessionModal')
        this.dialog.close(USER_IS_LATE_ACTION_REQUEST_NEW_PARTNER)
    }

    public cancelSession() {
        this.analyticsService.logClickedEvent('Cancel session', 'lateToSessionModal')
        this.dialog.close(USER_IS_LATE_ACTION_CANCEL)
    }
}
