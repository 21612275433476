import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { environment } from '@env/environment'
import { ApiService } from '@app/core/_services/api.service'
import { map } from 'rxjs/operators'

@Injectable()
export class DeleteAccountService {
    constructor(private apiService: ApiService) {}

    deleteAccount(): Observable<any> {
        return this.apiService.delete(environment.api_url + 'account/', {}).pipe(
            map((data) => {
                return data
            }),
        )
    }

    ngOnDestroy() {}
}
