import { Component, OnInit, ViewChild } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { User } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserService } from '@app/core/_services/user.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { UtilsService } from '@app/core/_services/utils.service'
import { MatSelect } from '@angular/material/select'

class NameForm {
    constructor(
        public firstName: string = '',
        public lastName: string = '',
        public hideLastName: boolean = true,
    ) {}
}

interface IHideOptions {
    value: boolean
    name: string
}

@Component({
    selector: 'app-edit-name-modal',
    templateUrl: './edit-name-modal.component.html',
    styleUrls: ['./edit-name-modal.component.scss'],
})
export class EditNameModalComponent implements OnInit {
    @ViewChild('displayNameSelect', { static: false }) displayNameSelect: MatSelect
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public currentUser: User
    public formModel: NameForm = new NameForm()
    faTimes = faTimes

    public hideOptions: IHideOptions[] = [
        { value: true, name: '' },
        { value: false, name: '' },
    ]
    public updateOptions: number = 0
    public disableSaveButton = true
    public disableDisplayOptions = false

    constructor(
        public dialog: MatDialogRef<EditNameModalComponent>,
        public userService: UserService,
        private analyticsService: AnalyticsService,
        private utilsService: UtilsService,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Update Name')
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.currentUser = user

            this.formModel.firstName = user.firstName
            this.formModel.lastName = user.familyName
            this.formModel.hideLastName = user.hide

            this.hideOptions[0].name = this.utilsService.genereateDisplayName(
                this.formModel.firstName,
                this.formModel.lastName,
                this.hideOptions[0].value,
            )
            this.hideOptions[1].name = this.utilsService.genereateDisplayName(
                this.formModel.firstName,
                this.formModel.lastName,
                this.hideOptions[1].value,
            )
            this.checkEnableSaveNameButton()
        })
    }

    checkEnableSaveNameButton() {
        if (!this.formModel.firstName || !this.formModel.lastName) {
            this.disableSaveButton = true
            this.disableDisplayOptions = true
        } else if (
            this.formModel.firstName !== this.currentUser.firstName ||
            this.formModel.lastName !== this.currentUser.familyName ||
            this.formModel.hideLastName !== this.currentUser.hide
        ) {
            this.disableSaveButton = false
            this.disableDisplayOptions = false
        } else {
            this.disableSaveButton = true
            this.disableDisplayOptions = false
        }
    }

    checkChangedFields(): string[] {
        let fieldArray: string[] = []
        if (this.formModel.firstName !== this.currentUser.firstName) {
            fieldArray.push('firstName')
        }
        if (this.formModel.lastName !== this.currentUser.familyName) {
            fieldArray.push('lastName')
        }
        if (this.formModel.hideLastName !== this.currentUser.hide) {
            fieldArray.push('displayName')
        }
        return fieldArray
    }

    onKey(e) {
        this.checkEnableSaveNameButton()

        this.hideOptions[0].name = this.utilsService.genereateDisplayName(
            this.formModel.firstName,
            this.formModel.lastName,
            this.hideOptions[0].value,
        )
        this.hideOptions[1].name = this.utilsService.genereateDisplayName(
            this.formModel.firstName,
            this.formModel.lastName,
            this.hideOptions[1].value,
        )
        this.updateOptions++
    }

    updateHideLastNameSelection(e) {
        this.formModel.hideLastName = e
        this.checkEnableSaveNameButton()
    }

    openSelectOptions() {
        this.displayNameSelect.open()
    }

    submitForm() {
        let fieldsChanged = this.checkChangedFields()

        this.currentUser.firstName = this.formModel.firstName
        this.currentUser.familyName = this.formModel.lastName
        this.currentUser.hide = this.formModel.hideLastName
        this.currentUser.displayName = this.utilsService.genereateDisplayName(
            this.formModel.firstName,
            this.formModel.lastName,
            this.formModel.hideLastName,
        )

        this.userService.update(this.currentUser, null).subscribe((user) => {
            if (fieldsChanged.length > 0) {
                this.analyticsService.logProfileEditedEvent(fieldsChanged)
            }
            this.dialog.close()
        })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
