import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core'
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
@Component({
    selector: 'fm-checkbox',
    templateUrl: './fm-checkbox.component.html',
    styleUrls: ['./fm-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FmCheckboxComponent),
            multi: true,
        },
    ],
})
export class FmCheckboxComponent implements ControlValueAccessor, OnInit {
    @Input() disabled: boolean = false
    @Output() checkBoxChange = new EventEmitter<boolean>()
    faCheck = faCheck
    onChange: any = () => {}
    onTouch: any = () => {}

    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn
    }

    constructor() {}

    ngOnInit() {}
    checked: boolean = false
    writeValue(checked: boolean) {
        this.checked = checked
    }

    onModelChange(e: boolean) {
        this.checked = e
        this.onChange(e)
        this.checkBoxChange.emit(e)
    }
}
