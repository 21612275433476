import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { AccountManagementService } from '@app/core/_services/account-management.service'
import { UserService } from '@app/core/_services/user.service'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'

@Component({
    selector: 'app-no-show-acknowledge',
    templateUrl: './no-show-acknowledge.component.html',
    styleUrls: ['./no-show-acknowledge.component.scss'],
})
export class NoShowAcknowledgeComponent implements OnInit {
    private ngUnsubscribe: Subject<any> = new Subject<any>()

    public displayTime: string = ''
    public reactivated: boolean = false

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private userService: UserService,
        private accountManagementService: AccountManagementService,
        public dialog: MatDialogRef<NoShowAcknowledgeComponent>,
        private analyticsService: AnalyticsService,
        private dateFormat: UserDateFormattingService,
    ) {}

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Reactivate')
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.displayTime = this.dateFormat.format(
                this.data.triggerSession,
                'dddd, MMMM Do [at] h:mm a',
            )
        })
    }

    confirmNoShow() {
        this.accountManagementService.acknowledgeNoshow().subscribe((result) => {
            this.userService.repopulate()
            this.reactivated = true
            setTimeout(() => {
                this.dialog.close({})
            }, 2000)
        })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
