import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject, Observable } from 'rxjs'

@Injectable()
export class CalendarViewService {
    constructor() {}

    private goToday: boolean = false
    private today_sub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.goToday)

    private goNext: boolean = false
    private next_sub: Subject<boolean> = new Subject<boolean>()

    private goPrev: boolean = false
    private prev_sub: Subject<boolean> = new Subject<boolean>()

    private weekView: boolean = true
    private week_sub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.weekView)

    private toggleSidenav: boolean = false
    private toggle_sidenav_sub: Subject<boolean> = new Subject<boolean>()

    private hideFooter: boolean = false
    private hide_footer_sub: Subject<boolean> = new Subject<boolean>()

    toggleSideNav() {
        this.toggle_sidenav_sub.next(true)
    }

    listenToggleSideNav() {
        return this.toggle_sidenav_sub.asObservable()
    }

    setWeekView(week: boolean) {
        this.weekView = week
        this.week_sub.next(this.weekView)
    }

    listenWeekView(): Observable<boolean> {
        return this.week_sub.asObservable()
    }

    goToToday(): void {
        this.today_sub.next(this.goToday)
    }

    listenGoToToday(): Observable<boolean> {
        return this.today_sub.asObservable()
    }

    goToNextDayorWeek(): void {
        this.goNext = true
        this.next_sub.next(this.goNext)
    }

    listenGoToNextDayorWeek(): Observable<boolean> {
        return this.next_sub.asObservable()
    }

    goToPrevDayorWeek(): void {
        this.goPrev = true
        this.prev_sub.next(this.goPrev)
    }

    listenGoToPrevDayorWeek(): Observable<boolean> {
        return this.prev_sub.asObservable()
    }

    hideFooterButtons(hide: boolean) {
        this.hideFooter = hide
        this.hide_footer_sub.next(this.hideFooter)
    }

    listenHideFooterButtons(): Observable<boolean> {
        return this.hide_footer_sub.asObservable()
    }
}
