<div [ngClass]="backgroundClasses">
    <fa-icon
        [matTooltip]="tooltip"
        matTooltipPosition="above"
        matTooltipShowDelay="200"
        matTooltipClass="f-tooltip"
        *ngIf="displayIcon"
        class="flex justify-center items-center h-full w-full text-blurple align-middle"
        [icon]="displayIcon"></fa-icon>
</div>
