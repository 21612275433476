import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { XHR, XHRInitial, XHRLoading, XHRSuccess } from '@app/core/_models/xhr'
import {
    AvailableTimesService,
    PartnerAtTimeSlot,
    PartnersAtTimeSlotAvailabilityResponse,
} from '@app/core/_services/available-times.service'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'
import { faTimes, faLock, faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { UserService } from '@app/core/_services/user.service'
import { forkJoin, Subject, take, takeUntil } from 'rxjs'
import { BookSessionPreferences, SESSION_ACTIVITY_TYPE_ANYTHING, User } from '@app/core/_models'
import {
    BookSessionInfo,
    BookSessionsService,
    CancelSessionService,
    UtilsService,
} from '@app/core/_services'
import {
    AnalyticsService,
    BookingSource,
    SessionTileSource,
} from '@app/core/_services/analytics.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { SessionSettingsService } from '@app/core/_services/session-settings.service'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'

const INVALID_LIST_ITEM_SELECTED = -1

const MODAL_NAME = 'Timeslot Partners'

@Component({
    selector: 'app-timeslot-partners',
    templateUrl: './timeslot-partners.component.html',
    styleUrls: ['./timeslot-partners.component.scss'],
})
export class TimeslotPartnersComponent implements OnInit {
    @ViewChild('scrollingList', { read: ElementRef, static: false }) scrollingList: ElementRef
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public currentUser: User = new User()
    public faTimes = faTimes
    public faLock = faLock
    public faChevronLeft = faChevronLeft
    public xhrPartners: XHR<PartnersAtTimeSlotAvailabilityResponse> = XHRInitial
    public listMode: boolean = false
    public actionComplete: boolean = false
    public modalTitle: string
    public modalSubtitle: string
    public ctaText: string

    public directBookPartnerList: PartnerAtTimeSlot[] = []
    public directBookPartnerListItemSelected: number = INVALID_LIST_ITEM_SELECTED
    public mostLikelyRandomPartner: PartnerAtTimeSlot
    public ismostLikelyRandomPartnerSelected: boolean = false

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialogRef<TimeslotPartnersComponent>,
        private availableTimesService: AvailableTimesService,
        private userService: UserService,
        private bookSessionService: BookSessionsService,
        private dialogService: DialogService,
        private cancelSessionService: CancelSessionService,
        private utilsService: UtilsService,
        private analyticsService: AnalyticsService,
        private sessionSettingsService: SessionSettingsService,
        private dateFormat: UserDateFormattingService,
    ) {}

    ngOnInit(): void {
        this.userService.currentUser.pipe(take(1)).subscribe((currentUser) => {
            this.currentUser = currentUser

            const sessionTimeStr = this.dateFormat.format(this.data.sessionTime, 'h:mma')
            this.modalTitle = `Session at ${sessionTimeStr}`
            this.modalSubtitle = `${moment(this.data.sessionTime).format('dddd, MMM Do')} · ${
                this.data.duration / 1000 / 60
            } minutes`
            this.ctaText = `Book for ${sessionTimeStr}`
            this.populatePartnerList(true)
        })

        this.userService.isAuthenticated.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: (isAuthenticated) => this.closeOnLogout(isAuthenticated),
        })
    }

    private closeOnLogout(isAuthenticated: boolean): void {
        if (!isAuthenticated) {
            this.dialog.close()
        }
    }

    private populatePartnerList(sendViewedModalEvent: boolean = false) {
        this.directBookPartnerList.length = 0
        this.mostLikelyRandomPartner = null
        this.xhrPartners = XHRLoading
        this.availableTimesService
            .queryAvailablePartnersAtTimeslot(this.data.sessionTime, this.data.duration)
            .subscribe((response) => {
                this.xhrPartners = XHRSuccess(response)
                this.parsePartnerList(this.xhrPartners.data.availability.partners)
                if (sendViewedModalEvent) {
                    this.analyticsService.logViewedModalWithCountEvent(
                        MODAL_NAME,
                        this.directBookPartnerList.length,
                    )
                }
            })
    }

    private filterSessionBasedOnQuietModeSettings(partner: PartnerAtTimeSlot): boolean {
        /* Do not include sessions in this list if the following conditions are met:
		1. Potential partner has quietModeMatchAllowed setting set to False
		2. Current user has selected quietMode
		3. Potential partner's session is not set to Quiet Mode
		*/
        if (
            !partner.quietModeMatchAllowed &&
            !partner.preferences.quietMode.value &&
            this.sessionSettingsService.quietModeEnabled()
        ) {
            return true
        }
        return false
    }

    parsePartnerList(partners: PartnerAtTimeSlot[]) {
        let groupMatchFound = false

        for (let partner of partners) {
            if (this.filterSessionBasedOnQuietModeSettings(partner)) {
                continue
            }

            if (partner.saved && partner.sharedAvailability) {
                this.directBookPartnerList.push(partner)
            } else if (!this.mostLikelyRandomPartner) {
                this.mostLikelyRandomPartner = partner
                groupMatchFound = this.utilsService.usersShareGroup(
                    this.currentUser.group,
                    partner.groups,
                )
            } else if (!groupMatchFound) {
                if (this.utilsService.usersShareGroup(this.currentUser.group, partner.groups)) {
                    this.mostLikelyRandomPartner = partner
                    groupMatchFound = true
                }
            }
        }

        // Reverse the list so that the most recently booked
        // users are shown first, as per requirements
        this.directBookPartnerList.reverse()

        this.determineInitialSelection()
    }

    private resetSelection() {
        this.directBookPartnerListItemSelected = INVALID_LIST_ITEM_SELECTED
        this.ismostLikelyRandomPartnerSelected = false
    }

    private determineInitialSelection() {
        this.resetSelection()
        if (this.directBookPartnerList.length === 0) {
            this.ismostLikelyRandomPartnerSelected = true
        } else {
            this.directBookPartnerListItemSelected = 0
        }
    }

    public updateDirectBookPartnerListItemSelected(index: number) {
        this.resetSelection()
        this.directBookPartnerListItemSelected = index
    }

    public setIsmostLikelyRandomPartnerSelected() {
        this.resetSelection()
        this.ismostLikelyRandomPartnerSelected = true
    }

    private moveSelectedUserToTopOfList() {
        if (this.directBookPartnerListItemSelected !== INVALID_LIST_ITEM_SELECTED) {
            let partner = this.directBookPartnerList[this.directBookPartnerListItemSelected]
            this.directBookPartnerList.splice(this.directBookPartnerListItemSelected, 1)
            this.directBookPartnerList.splice(0, 0, partner)
            this.directBookPartnerListItemSelected = 0
        }
    }

    toggleListMode() {
        if (this.listMode) {
            // User is leaving list mode
            // If they have selected someone in the list, mvove them
            // to the top of list and reset scroll position to start
            this.scrollingList.nativeElement.scrollTop = 0
            this.moveSelectedUserToTopOfList()
        }

        this.listMode = !this.listMode
    }

    bookSession() {
        let userId = null
        if (this.directBookPartnerListItemSelected >= 0) {
            userId = this.directBookPartnerList[this.directBookPartnerListItemSelected].userId
        }
        const preferences: BookSessionPreferences = {
            favorites: {
                value: this.sessionSettingsService.getSessionSettingFavoritesPreference(),
            },
            quietMode: {
                value: this.sessionSettingsService.quietModeEnabled(),
            },
        }

        let bookSessionInfo: BookSessionInfo = {
            duration: this.data.duration,
            sessionTime: this.data.sessionTime,
            userAvailabilityCode: null,
            title: null,
            userId: userId,
            preferences,
            activityType: this.sessionSettingsService.getSessionSettingActivityType(),
        }

        this.bookSessionService
            .bookSession(
                [bookSessionInfo],
                this.currentUser.timeZone,
                BookingSource.TimeSlotPartnerModal,
            )
            .subscribe({
                next: (v) => this.receivedBookSessionResult(v),
                error: (e) => this.receivedBookSessionError(e),
                complete: () => this.receivedBookSessionComplete(),
            })
    }

    public receivedBookSessionResult(result) {
        let sessionTime = this.data.sessionTime
        let duration = this.data.duration
        let parsedRes = this.bookSessionService.parseBookResponse(result)

        let userId = null
        if (this.directBookPartnerListItemSelected >= 0) {
            userId = this.directBookPartnerList[this.directBookPartnerListItemSelected].userId
        }

        if (parsedRes['numSuccess'] !== 0) {
            this.dialogService.openConfirmTransaction('Session booked!')
            this.dialog.close()
        }

        if (parsedRes['planLimitReached'] === true) {
            this.dialogService.openPlanLimitReachedDialog()
        } else if (parsedRes['sessionConflict'] === true) {
            let conflicts: string[] = []
            for (let sessionTime of parsedRes['conflicts']) {
                conflicts.push(this.dateFormat.format(sessionTime, 'dddd, MMMM Do [at] h:mm a'))
            }
            this.dialogService
                .openSessionConflictWithCancel(parsedRes['conflicts'], this.currentUser.timeZone)
                .afterClosed()
                .pipe(take(1))
                .subscribe((actionResult) => {
                    if (actionResult.action === 'cancelAndBook') {
                        const preferences: BookSessionPreferences = {
                            favorites: {
                                value: this.sessionSettingsService.getSessionSettingFavoritesPreference(),
                            },
                            quietMode: {
                                value: this.sessionSettingsService.quietModeEnabled(),
                            },
                        }

                        let bookSessionInfo: BookSessionInfo = {
                            sessionTime: sessionTime,
                            userAvailabilityCode: '',
                            duration: duration,
                            userId: userId,
                            title: '',
                            preferences,
                            activityType: SESSION_ACTIVITY_TYPE_ANYTHING,
                        }
                        this.bookAndCancel(bookSessionInfo, parsedRes['conflicts'])
                    }
                })
        } else if (parsedRes['inviterNoLongerAvailable'] === true) {
            this.dialogService.openInviterMeetingNoLongerAvailable()
            this.populatePartnerList()
        } else if (parsedRes['alreadyStarted'] === true) {
            this.dialogService.openBookFailedSessionAlreadyStarted()
            this.dialog.close()
            return
        }
        this.resetSpinnerButton()
    }

    private resetSpinnerButton() {
        this.actionComplete = false
        setTimeout(() => {
            this.actionComplete = true
        }, 1000)
    }

    public receivedBookSessionError(e) {
        this.resetSpinnerButton()
    }

    public receivedBookSessionComplete() {
        this.resetSpinnerButton()
    }

    private bookAndCancel(bookInfo: BookSessionInfo, cancelList: number[]) {
        let cancelRequests = []
        for (let sessionTime of cancelList) {
            cancelRequests.push(
                this.cancelSessionService.cancelSession(
                    sessionTime,
                    SessionTileSource.AvailabilityConflictModal,
                ),
            )
        }

        forkJoin(cancelRequests).subscribe({
            next: (v) => this.bookSession(),
            error: (e) => this.bookSession(),
        })
    }

    trackLearnMoreSelected() {
        this.analyticsService.logClickedEvent('Learn more', MODAL_NAME)
    }

    closeDialog() {
        this.dialog.close()
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
