import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ApplicationIntegration } from '@app/core/_models/integrations'
import { UserIntegrationsService } from '@app/core/_services/user-integrations.service'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface ConfirmRemoveIntegrationComponentData {
    integration: ApplicationIntegration
}

@Component({
    selector: 'app-confirm-remove-integration',
    templateUrl: './confirm-remove-integration.component.html',
    styleUrls: ['./confirm-remove-integration.component.scss'],
})
export class ConfirmRemoveIntegrationComponent implements OnInit {
    public faTimes = faTimes

    constructor(
        public dialog: MatDialogRef<ConfirmRemoveIntegrationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmRemoveIntegrationComponentData,
        public userIntegrationsService: UserIntegrationsService,
    ) {}

    ngOnInit(): void {}

    confirmRemoveIntegration() {
        this.userIntegrationsService.unlinkIntegration(this.data.integration.id).subscribe((r) => {
            this.dialog.close(true)
        })
    }
}
