import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { GroupInfo } from '@app/core/_models'
import { GroupService } from '@app/core/_services/group.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AnalyticsService } from '@app/core/_services/analytics.service'

@Component({
    selector: 'app-group-invite',
    templateUrl: './group-invite.component.html',
    styleUrls: ['./group-invite.component.scss'],
})
export class GroupInviteComponent implements OnInit {
    private ngUnsubscribe: Subject<any> = new Subject<any>()

    public groupInfo: GroupInfo = new GroupInfo()
    public userPhoto: string = this.data.userPhoto

    constructor(
        private groupService: GroupService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialogRef<GroupInviteComponent>,
        private analyticsService: AnalyticsService,
    ) {
        this.groupInfo = data.group
        if (this.data.userPhoto === '') {
            this.userPhoto = '../../../assets/images/blank_profile.png'
        }
    }

    closeDialog() {
        this.groupService
            .acknowledgeInviteNotification(this.groupInfo.groupId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (data) => {
                    this.dialog.close({ value: false })
                },
                (error) => {
                    this.dialog.close({ value: false })
                },
            )
    }

    acceptInvite() {
        this.groupService
            .acceptInvite(this.groupInfo.groupId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (data) => {
                    this.dialog.close({ value: true, message: 'Invitation accepted!' })
                },
                (error) => {
                    this.dialog.close({ value: false })
                },
            )
    }

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Group Invitation: Confirm Accept')
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
