import { Component } from '@angular/core'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'

@Component({
    selector: 'fm-maintenance-banner',
    templateUrl: './maintenance-banner.component.html',
    styleUrls: ['./maintenance-banner.component.scss'],
})
export class MaintenanceBannerComponent {
    public maintenanceStartTime: string = ''

    constructor(private dateFormat: UserDateFormattingService) {}

    ngOnInit() {
        this.maintenanceStartTime = this.dateFormat.format(1696215600000, 'h:mma z')
    }
}
