import { Component, OnInit, Input } from '@angular/core'
import { Subject } from 'rxjs'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { environment } from '@env/environment'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'

@Component({
    selector: 'app-add-to-calendar',
    templateUrl: './add-to-calendar.component.html',
    styleUrls: ['./add-to-calendar.component.scss'],
})
export class AddToCalendarComponent implements OnInit {
    @Input() sessionTime: number
    @Input() sessionDurationMs: number
    @Input() timeZone: string
    @Input() videoLink: string
    @Input() email: string
    @Input() colorClass: string
    @Input() name: string
    @Input() focusMate: string
    @Input() useIcon: boolean

    private ngUnsubscribe: Subject<any> = new Subject<any>()

    public classString: string = 'whiteIcon'
    public googleUrl: string = ''
    public icalUrl: string = ''
    public outlookUrl: string = ''
    public outlookOnlineUrl: string = ''
    public title = 'Hold for Focusmate'
    public description = ''
    public atcUrl = 'https://addtocalendar.com/atc/'
    public tzone = ''

    constructor(
        private analyticsService: AnalyticsService,
        private dateFormat: UserDateFormattingService,
    ) {}

    ngOnInit() {
        let tzone: string = ''

        let urls: any = {
            google: '',
            ical: '',
            outlook: '',
            outlookonline: '',
        }

        if (typeof name === 'undefined') {
            return urls
        }

        if (this.timeZone === 'US/Eastern') {
            tzone = 'America/New_York'
        } else if (this.timeZone === 'US/Alaska') {
            tzone = 'America/Anchorage'
        } else if (this.timeZone === 'US/Central') {
            tzone = 'America/Chicago'
        } else if (this.timeZone === 'US/Pacific') {
            tzone = 'America/Los_Angeles'
        } else if (this.timeZone === 'US/Pacific-New') {
            tzone = 'America/Los_Angeles'
        } else if (this.timeZone === 'US/Aleutian') {
            tzone = 'America/Adak'
        } else if (this.timeZone === 'US/Arizona') {
            tzone = 'America/Phoenix'
        } else if (this.timeZone === 'US/East-Indiana') {
            tzone = 'America/Indiana/Indianapolis'
        } else if (this.timeZone === 'US/Hawaii') {
            tzone = 'Pacific/Honolulu'
        } else if (this.timeZone === 'US/Indiana-Starke') {
            tzone = 'America/Chicago'
        } else if (this.timeZone === 'US/Michigan') {
            tzone = 'America/Detriot'
        } else if (this.timeZone === 'US/Mountain') {
            tzone = 'America/Boise'
        } else if (this.timeZone === 'US/Samoa') {
            tzone = 'Pacific/Midway'
        } else if (this.timeZone === 'Asia/Calcutta') {
            tzone = 'Asia/Kolkata'
        } else {
            tzone = this.timeZone
        }

        // hardcorde for now to point to calendar
        const vlink: string = environment.homeUrl
        const communityGuidelinesUrl: string = environment.marketingHomeUrl + '/community'

        const startDate = this.dateFormat.format(this.sessionTime, 'YYYY-MM-DD h:mma')
        const end: number = this.sessionTime + this.sessionDurationMs
        const endDate = this.dateFormat.format(end, 'YYYY-MM-DD h:mma')
        const utz = -new Date().getTimezoneOffset().toString()
        const atc_version = '1.5'
        const url_parameters = ['utz=' + utz, 'uln=' + navigator.language, 'vjs=' + atc_version]
        const title = 'Focusmate Session'
        const sessionDurationInMinutes = (this.sessionDurationMs / 1000 / 60).toString()
        const startTimeStr = this.dateFormat.format(
            this.sessionTime,
            'YYYY-MM-DD h:mma z',
            this.timeZone,
        )

        let description = `Your Focusmate Session has been scheduled for ${startTimeStr} for ${sessionDurationInMinutes} minutes.

    Have your plans changed? Please visit your calendar to cancel or make changes to your session. ${vlink}
    
    Remember, it's important to be the partner you'd love to have. Everything you do sets a culture for our community! 
    
    Top tips:
    - Always show up on time, or a little early
    - Greet your partner and share your goals
    - Keep your camera on at all times
    - Write down each of your tasks in the chat 
    - If you need to step away for a minute, say so
    - Check in at the end
    
    You can read the full Code of Conduct here: ${communityGuidelinesUrl}
    
    Want to learn more? Check out our new guide, Getting Started with Focusmate: https://support.focusmate.com/en/articles/9110188-getting-started
    
    Have a question? Email us at hi&#64;focusmate.com.
    
    Sincerely,
    Your Focusmate Team`
        /*   }*/
        url_parameters.push('e[0][date_start]=' + encodeURIComponent(startDate))
        url_parameters.push('e[0][date_end]=' + encodeURIComponent(endDate))
        url_parameters.push('e[0][timezone]=' + encodeURIComponent(tzone))
        url_parameters.push('e[0][title]=' + encodeURIComponent(title))
        url_parameters.push('e[0][description]=' + encodeURIComponent(description))
        url_parameters.push('e[0][location]=' + encodeURIComponent(vlink))
        url_parameters.push('e[0][organizer]=' + encodeURIComponent(name))
        url_parameters.push('e[0][organizer_email]=' + encodeURIComponent(this.email))
        url_parameters.push('e[0][privacy]=' + encodeURIComponent('public'))

        let atcUrl: string = 'https://addtocalendar.com/atc/'

        this.googleUrl = atcUrl + 'google?' + url_parameters.join('&') + 'target=_blank'
        this.icalUrl = atcUrl + 'ical?' + url_parameters.join('&') + 'target=_blank'
        this.outlookUrl = atcUrl + 'outlook?' + url_parameters.join('&') + 'target=_blank'
        this.outlookOnlineUrl =
            atcUrl + 'outlookonline?' + url_parameters.join('&') + 'target=_blank'
    }

    logATC(type: string) {
        this.analyticsService.logAddToCalendar(type)
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
