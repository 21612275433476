import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SnoozeUserComponent } from './components/snooze/snooze-user/snooze-user.component'
import { SnoozeUserConfirmComponent } from './components/snooze/snooze-user-confirm/snooze-user-confirm.component'
import { FmSelectSnoozeDurationComponent } from './components/snooze/fm-select-snooze-duration/fm-select-snooze-duration.component'
import { MaterialModule } from '@app/material/material.module'
import { SharedModule } from '@app/shared/shared.module'
import { UnsnoozeUserComponent } from './components/snooze/unsnooze-user/unsnooze-user.component'
import { BlockUserConfirmationComponent } from './components/block/block-user-conformation/block-user-conformation.component'
import { BlockUserComponent } from './components/block/block-user/block-user.component'
import { ReportBadBehaviorComponent } from './components/report/report-bad-behavior/report-bad-behavior.component'
import { ReportBadBehaviorConfirmComponent } from './components/report/report-bad-behavior-confirm/report-bad-behavior-confirm.component'
import { ReportProfileComponent } from './components/report/report-profile/report-profile.component'
import { UserActionsMenuComponent } from './components/user-actions-menu/user-actions-menu.component'
import { ToggleSaveComponent } from './components/toggle-save/toggle-save.component'

@NgModule({
    declarations: [
        SnoozeUserComponent,
        SnoozeUserConfirmComponent,
        FmSelectSnoozeDurationComponent,
        UnsnoozeUserComponent,
        BlockUserConfirmationComponent,
        BlockUserComponent,
        ReportBadBehaviorComponent,
        ReportBadBehaviorConfirmComponent,
        ReportProfileComponent,
        UserActionsMenuComponent,
        ToggleSaveComponent,
    ],
    exports: [
        SnoozeUserComponent,
        SnoozeUserConfirmComponent,
        FmSelectSnoozeDurationComponent,
        BlockUserConfirmationComponent,
        BlockUserComponent,
        ReportBadBehaviorComponent,
        ReportBadBehaviorConfirmComponent,
        ReportProfileComponent,
        UserActionsMenuComponent,
        ToggleSaveComponent,
    ],
    imports: [CommonModule, SharedModule, MaterialModule],
    // providers: [
    //     {
    //         provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    //         useValue: {
    //             useUtc: true,
    //         },
    //     },
    // ],
})
export class UserActionsModule {}
