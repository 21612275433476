<div class="diag-container">
    <mat-icon (click)="dialog.close()" class="diag-close icon-size-16">clear</mat-icon>
    <div class="diag-header">
        <div class="diag-header-text fm-mt-50px fm-mb-30px wrap-word-break" style="color: #2f3150">
            Your partner {{ data.partnerName }} is new!
        </div>
    </div>
    <div class="diag-body">
        <div class="modal-content-text fm-mx-35px fm-mt-25px fm-mb-15px" style="color: #2f3150">
            <p
                >You get to be one of their first partners. Please show them how amazing our
                community is!</p
            >
            <ul style="font-size: 16px">
                <li>Be friendly and welcome them to Focusmate</li>
                <li>Briefly review the session structure</li>
                <li>Offer to answer any questions</li>
            </ul>

            <p class="fm-mt-15px"
                >Thank you for taking the time to welcome a new member to our community.</p
            >
        </div>

        <div class="customHrDiag w-full" style="color: #2f3150"></div>
        <div class="diag-action fm-mt-25px fm-mb-50px w-full">
            <button style="min-width: 150px" class="fm-btn fm-btn--md" (click)="dialog.close()"
                >Got it</button
            >
        </div>
    </div>
</div>
