<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close(false)">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title wrap-double-line-el"> Report {{ data.partnerName }} </div>
    </div>

    <div
        class="std-modal-body std-modal-body-text w-full gap-3 text-14-19 text-bluegrey font-avenir text-left">
        <div class="wrap-word-break">
            We’re sorry that you had a bad experience. Please tell us what went wrong and we’ll take
            action within 48 hours. Your report is completely confidential.
        </div>

        <app-fm-select
            class="w-full"
            [optionsList]="reasons"
            [currentValue]="selectedReason"
            fieldName="reasonsList"
            btnClasses="std-select w-full"
            placeholder="What did they do?"
            (selectionChanged)="onSelectionChanged($event)"></app-fm-select>

        @if (selectedReason != 11) {
            <mat-form-field
                floatLabel="never"
                class="fm-textarea w-full"
                [ngClass]="{ 'required-state': commentsRequired }"
                hideRequiredMarker="true">
                <mat-label>Please describe what happened.</mat-label>
                <textarea
                    matInput
                    maxlength="500"
                    (input)="onKeypressEvent($event)"
                    [(ngModel)]="comments"
                    name="additionalComments"></textarea>
            </mat-form-field>
        } @else {
            <div class="w-full"
                >We automatically track no-shows, so there's no need to report this. Here’s what you
                can do now:</div
            >
            <div class="w-full"
                ><ul>
                    <li
                        ><strong>Leave the session:</strong> Cancel it, and book the next available
                        time slot, or</li
                    >
                    <li
                        ><strong>Rematch:</strong> If you’re within 5 minutes of the session start
                        time and another person is available, we’ll give you the option to
                        rematch.</li
                    >
                </ul></div
            >
            <div class="w-full">We hope your next session goes smoothly!</div>
        }

        <div class="std-modal-action-container" [ngClass]="{ '!pt-[8px]': selectedReason == 11 }">
            @if (selectedReason != 11) {
                <app-loading-button
                    classList="f-btn f-btn--primary f-btn-250"
                    label="Report"
                    loadingLabel="Reporting"
                    [notValid]="disableForm"
                    (clicked)="reportUser()"></app-loading-button>
                <button class="f-link fm-mt-10px" (click)="dialog.close(false)">Cancel</button>
            } @else {
                <button class="f-btn f-btn--primary f-btn-250" (click)="dialog.close(false)"
                    >Got it</button
                >
            }
        </div>
    </div>
</div>
