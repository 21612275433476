import { GroupInfo } from '@app/core/_models/rest_api'
import { WelcomeChecklistStepName } from './welcome-checklist'

export const GENDER_STRING_EMPTY = 'Not set yet'
export const GENDER_STRING_MAN = 'Man'
export const GENDER_STRING_WOMAN = 'Woman'
export const GENDER_STRING_CISGENDER = 'Cisgender'
export const GENDER_STRING_NON_BINARY = 'Non-binary'
export const GENDER_STRING_TRANSGENDER = 'Transgender'
export const GENDER_STRING_PREFER_NOT_TO_SAY = 'Prefer not to say'

// NOTE: Must remain in sync with GenderSelection enum
// on backend
export const GENDER_MASK_MAN: number = 0x01
export const GENDER_MASK_WOMAN: number = 0x02
export const GENDER_MASK_NON_BINARY: number = 0x04
export const GENDER_MASK_TRANSGENDER: number = 0x08
export const GENDER_MASK_CISGENDER: number = 0x10
export const GENDER_MASK_PREFER_NOT_SAY: number = 0x20

// NOTE: Must remain in sync with GenderPreference enum
// on backend
export const GENDER_MATCH_NO_PREFERENCE: number = 0
export const GENDER_MATCH_MEN_ONLY: number = 1
export const GENDER_MATCH_WOMEN_AND_NON_BINARY: number = 2
export const GENDER_MATCH_EXCLUDE_CIS_MEN: number = 3
export const GENDER_MATCH_EXCLUDE_CIS_WOMEN: number = 4
export const GENDER_MATCH_EXCLUDE_MEN: number = 5
export const GENDER_MATCH_EXCLUDE_WOMEN: number = 6

export const GENDER_MATCH_STRING_NO_PREFERENCE: string = 'Everyone'
export const GENDER_MATCH_STRING_MEN_ONLY: string = 'Men only'
export const GENDER_MATCH_STRING_WOMEN_AND_NON_BINARY: string = 'Women & non-binary people only'
export const GENDER_MATCH_STRING_EXCLUDE_CIS_MEN: string = 'Cisgender men'
export const GENDER_MATCH_STRING_EXCLUDE_CIS_WOMEN: string = 'Cisgender women'
export const GENDER_MATCH_STRING_EXCLUDE_MEN: string = 'Men'
export const GENDER_MATCH_STRING_EXCLUDE_WOMEN: string = 'Women'
export const GENDER_MATCH_STRING_EVERYONE_EXCEPT_CIS_MEN: string = 'Everyone except cisgender men'
export const GENDER_MATCH_STRING_EVERYONE_EXCEPT_MEN: string = 'Everyone except men'

export class PlanInfo {
    constructor(
        public canCancel: boolean = false,
        public checkPrice: boolean = false,
        public productAccess: string = 'Focusmate Free',
        public addressUpdateNeeded: boolean = false,
    ) {}
}

export type UserSettingsShareAvailability = 'everyone' | 'only saved' | 'no one'
export type TimeFormat = '12hr' | '24hr'

export class UserSettings {
    shareAvailability?: UserSettingsShareAvailability
    quietModeMatchAllowed?: boolean
    sessionRemindersEnabled?: boolean
    sessionReminderNotificationOffset?: number
    muteByDefault?: boolean
    timeFormat?: TimeFormat
    autoRematchEnabled?: boolean
}

export type ManualPauseStatus = 'no_pause' | 'pause_requested' | 'pause_active'

export interface TermsOfServiceInfo {
    accepted: boolean
    latestVersion: string
}

export interface YearlyReview {
    totalMinutes: number
    totalPartners: number
    totalPartnerCountries: number
    totalSessions: number
    imageUrlSlug: string
    usageCategory: string
}

export type WelcomeChecklistState = {
    [key in WelcomeChecklistStepName]: number
}

export class User {
    constructor(
        public timeZone: string = '',
        public createdOn: number = 0,
        public firstName: string = '',
        public familyName: string = '',
        public displayName: string = '',
        public email: string = '',
        public newUser: boolean = false,
        public onboard: boolean = false,
        public photo: string = '',
        public profileUrl: string = '',
        public hide: boolean = true,
        public terms: TermsOfServiceInfo = {
            accepted: true,
            latestVersion: '',
        },
        public userId: string = '',
        public attRate: number = 0,
        public bookLimit: number = 0,
        public compSessions: number = 0,
        public openedSessions: number = 0,
        public noShowAckNeeded: boolean = false,
        public acTrigger: number = 0,
        public weeklyStreak: number = 0,
        public monthlyStreak: number = 0,
        public calendarInvites: boolean = false,
        public weeklyStats: boolean = false,
        public tipReminders: boolean = false,
        public videoLinkPrefix: string = '',
        public videoLinkConfig: string = '',
        public group: GroupInfo[] = [],
        public fmAd: boolean = false,
        public fmSupport: boolean = false,
        public suspended: boolean = false,
        public planInfo: PlanInfo = new PlanInfo(),
        public properties: any = {},
        public features: any = {},
        public emailVerified: boolean = false,
        public availabilityCode: string = '',
        public genderSelection: number = 0,
        public genderPreference: number = 0,
        public gendersAdditional: string = '',
        public settings: UserSettings = {},
        public manualPauseStatus: ManualPauseStatus = 'no_pause',
        public yearlyReview?: YearlyReview,
        public rewardsReferralCode?: string,
        public freeTrialEnd?: number,
        public hasCompletedWelcomeChecklist?: boolean,
        public welcomeChecklistState?: WelcomeChecklistState,
    ) {}
}
