import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import {
    GENDER_MASK_CISGENDER,
    GENDER_MASK_MAN,
    GENDER_MASK_NON_BINARY,
    GENDER_MASK_PREFER_NOT_SAY,
    GENDER_MASK_TRANSGENDER,
    GENDER_MASK_WOMAN,
    GENDER_STRING_CISGENDER,
    GENDER_STRING_MAN,
    GENDER_STRING_NON_BINARY,
    GENDER_STRING_PREFER_NOT_TO_SAY,
    GENDER_STRING_TRANSGENDER,
    GENDER_STRING_WOMAN,
    User,
} from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserService } from '@app/core/_services/user.service'
import { Subject } from 'rxjs'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { takeUntil } from 'rxjs/operators'
import { faLock, faPlus } from '@fortawesome/free-solid-svg-icons'
import { UtilsService } from '@app/core/_services/utils.service'

@Component({
    selector: 'app-edit-gender-modal',
    templateUrl: './edit-gender-modal.component.html',
    styleUrls: ['./edit-gender-modal.component.scss'],
})
export class EditGenderModalComponent implements OnInit {
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public currentUser: User
    faTimes = faTimes
    public genderSelection: number = 1
    public testValue = true
    faLock = faLock
    faPlus = faPlus

    public disableSaveButton = true
    public additionalGendersSelected: boolean = false
    public additionalGenders: string = ''

    public manSelected: boolean = false
    public womanSelected: boolean = false
    public nonBinarySelected: boolean = false
    public transgenderSelected: boolean = false
    public cisgenderSelected: boolean = false
    public addSelected: boolean = false
    public preferNotToSaySelected: boolean = false

    public manToggleValue = GENDER_MASK_MAN
    public womanTogglevalue = GENDER_MASK_WOMAN
    public nonBinaryTogglevalue = GENDER_MASK_NON_BINARY
    public transgenderTogglevalue = GENDER_MASK_TRANSGENDER
    public cisgenderTogglevalue = GENDER_MASK_CISGENDER
    public preferNotToSayTogglevalue = GENDER_MASK_PREFER_NOT_SAY

    constructor(
        public dialog: MatDialogRef<EditGenderModalComponent>,
        public userService: UserService,
        private utilsService: UtilsService,
        private analyticsService: AnalyticsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
        if (this.data.viaPreferenceModal) {
            this.analyticsService.logViewedModalEvent('Update Gender(s): Via Gender Preference')
        } else {
            this.analyticsService.logViewedModalEvent('Update Gender(s)')
        }

        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.currentUser = user
            this.genderSelection = this.currentUser.genderSelection
            if (!this.currentUser.gendersAdditional) {
                this.currentUser.gendersAdditional = ''
            }
            this.additionalGenders = this.currentUser.gendersAdditional

            if (this.additionalGenders !== '') {
                this.additionalGendersSelected = true
            }
            this.setToggles()
            this.checkEnableSaveButton()
        })
    }

    private additionlGendersChanged() {
        if (!!this.currentUser.gendersAdditional && !this.additionalGendersSelected) {
            // Use Case: User has additional genders saved and has come to settings
            // page and deselected Add + toggle. This will result in saving an empty string
            return true
        }
        if (
            this.additionalGenders !== this.currentUser.gendersAdditional &&
            this.additionalGendersSelected
        ) {
            return true
        }
        return false
    }

    private genderSelectionChanged() {
        return this.genderSelection !== this.currentUser.genderSelection
    }

    private isFormEmpty() {
        if (this.genderSelection === 0) {
            if (
                !this.additionalGendersSelected ||
                this.utilsService.isStringEmpty(this.additionalGenders)
            ) {
                return true
            }
        }
        return false
    }

    private checkEnableSaveButton() {
        this.disableSaveButton =
            (!this.additionlGendersChanged() && !this.genderSelectionChanged()) ||
            this.isFormEmpty()
    }

    private setToggles() {
        this.manSelected = Boolean(this.genderSelection & GENDER_MASK_MAN)
        this.womanSelected = Boolean(this.genderSelection & GENDER_MASK_WOMAN)
        this.nonBinarySelected = Boolean(this.genderSelection & GENDER_MASK_NON_BINARY)
        this.transgenderSelected = Boolean(this.genderSelection & GENDER_MASK_TRANSGENDER)
        this.cisgenderSelected = Boolean(this.genderSelection & GENDER_MASK_CISGENDER)
        this.preferNotToSaySelected = Boolean(this.genderSelection & GENDER_MASK_PREFER_NOT_SAY)
        this.checkEnableSaveButton()
    }

    updateFlags(bit: number): void {
        this.genderSelection = this.genderSelection ^ bit

        if (bit === GENDER_MASK_TRANSGENDER && this.genderSelection & GENDER_MASK_CISGENDER) {
            // Cannot select transgdner and cisgender. Turn off cisgender
            this.genderSelection = this.genderSelection ^ GENDER_MASK_CISGENDER
        }
        if (bit === GENDER_MASK_CISGENDER && this.genderSelection & GENDER_MASK_TRANSGENDER) {
            // Cannot select transgdner and cisgender. Turn off cisgender
            this.genderSelection = this.genderSelection ^ GENDER_MASK_TRANSGENDER
        }
        if (bit === GENDER_MASK_PREFER_NOT_SAY) {
            this.genderSelection = GENDER_MASK_PREFER_NOT_SAY
            this.additionalGendersSelected = false
        } else {
            this.turnOffGender(GENDER_MASK_PREFER_NOT_SAY)
        }
        this.setToggles()
    }

    private toggleGender(genderMask: number, curerntSelections: number): number {
        return curerntSelections ^ curerntSelections
    }
    private turnOffGender(genderMask: number): void {
        this.genderSelection = (genderMask ^ 0xff) & this.genderSelection
        this.setToggles()
    }
    private turnOnGender(genderMask: number, curerntSelections: number): number {
        return genderMask | curerntSelections
    }

    toggleUpdate(e) {
        this.additionalGendersSelected = !this.additionalGendersSelected
        this.turnOffGender(GENDER_MASK_PREFER_NOT_SAY)
        this.checkEnableSaveButton()
    }

    checkChangedFields(): string[] {
        let fieldArray: string[] = ['genders']
        return fieldArray
    }

    onKey(e) {
        this.checkEnableSaveButton()
    }

    submitForm() {
        let fieldsChanged = this.checkChangedFields()

        this.currentUser.genderSelection = this.genderSelection
        let currentGenderPreference = this.currentUser.genderPreference

        if (this.additionalGendersSelected === false) {
            this.currentUser.gendersAdditional = ''
        } else {
            this.currentUser.gendersAdditional = this.additionalGenders
        }

        this.userService.update(this.currentUser, null).subscribe((res) => {
            if (fieldsChanged.length > 0) {
                this.analyticsService.logProfileEditedEvent(fieldsChanged)
            }
            let user: User = res.user
            this.dialog.close({
                preferenceReset: user.genderPreference !== currentGenderPreference,
                genderUpdated: true,
            })
        })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
