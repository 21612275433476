import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
    selector: 'app-loading-button',
    templateUrl: './loading-button.component.html',
    styleUrls: ['./loading-button.component.scss'],
})
export class LoadingButtonComponent implements OnInit {
    @Input() classList: string
    @Input() label: string
    @Input() loadingLabel: string
    @Input() notValid: boolean
    @Input() invert: boolean = false
    @Input() actionComplete: boolean = false
    @Input() icon: string = ''

    @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>()

    public disabled: boolean = false
    public classes: string = ''

    constructor() {}

    ngOnInit() {
        this.classes = this.classList
    }

    buttonPressed() {
        this.disabled = true
        this.classes = this.classList + ' load-btn'
        this.clicked.emit(true)
    }

    ngOnChanges() {
        if (this.actionComplete === true) {
            this.classes = this.classList
            this.disabled = false
            this.actionComplete = false
        }
    }
}
