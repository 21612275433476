import { ScrollingModule } from '@angular/cdk/scrolling'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FmTextInputComponent } from '@app/standards-v2/components/fm-text-input/fm-text-input.component'
import { FmButtonComponent } from './components/fm-button/fm-button.component'
import { FmLoadingIndicatorComponent } from './components/fm-loading-indicator/fm-loading-indicator.component'
import { FmTimezoneSelectComponent } from './components/fm-timezone-select/fm-timezone-select.component'
import { FmButtonGroupComponent } from './components/fm-button-group/fm-button-group.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { MaterialModule } from '@app/material/material.module'
import { FmMultiEmailInputComponent } from './components/fm-multi-email-input/fm-multi-email-input.component'
import { FmToggleSwitchComponent } from './components/toggle-switch/fm-toggle-switch.component'

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        ScrollingModule,
    ],
    exports: [
        FmTextInputComponent,
        FmButtonComponent,
        FmLoadingIndicatorComponent,
        FmTimezoneSelectComponent,
        FmButtonGroupComponent,
        FmMultiEmailInputComponent,
        FmToggleSwitchComponent,
    ],
    providers: [],
    declarations: [
        FmTextInputComponent,
        FmButtonComponent,
        FmLoadingIndicatorComponent,
        FmTimezoneSelectComponent,
        FmButtonGroupComponent,
        FmMultiEmailInputComponent,
        FmToggleSwitchComponent,
    ],
})
export class StandardsModule {}
