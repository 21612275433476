<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div
            class="text-[22px] leading-normal font-avenir text-center font-semibold wrap-double-line-el"
            >We’re experiencing issues with video</div
        >
    </div>

    <div class="std-modal-body w-full">
        <div class="text-bluegrey text-sm leading-normal w-full text-left font-avenir">
            <div>We’re sorry for the disruption and are working to fix this.</div>
            <div class="!mt-3">Your attendance scores will not be affected.</div>
        </div>
    </div>
    <div class="std-modal-action-container">
        <button class="f-btn f-btn--primary f-btn-250" (click)="dialog.close()">Okay</button>
    </div>
</div>
