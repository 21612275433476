import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { TimeFormat, User } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { TimeUtilitiesService } from '@app/core/_services/time-utilities.service'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'
import { UserService } from '@app/core/_services/user.service'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { Subject, takeUntil, timer } from 'rxjs'

@Component({
    selector: 'app-edit-time-format-modal',
    templateUrl: './edit-time-format-modal.component.html',
    styleUrl: './edit-time-format-modal.component.scss',
})
export class EditTimeFormatModalComponent {
    public faTimes = faTimes

    public timeFormat: TimeFormat
    public disableSaveButton: boolean = true

    private ngUnsubscribe: Subject<any> = new Subject<any>()
    private currentUser: User = new User()

    public timeDisplay12hr: string = ''
    public timeDisplay24hr: string = ''

    constructor(
        public dialog: MatDialogRef<EditTimeFormatModalComponent>,
        public userService: UserService,
        private analyticsService: AnalyticsService,
        private dateFormat: UserDateFormattingService,
        private timeUtils: TimeUtilitiesService,
    ) {}

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Edit Time Format')
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.currentUser = user
            this.timeFormat = this.currentUser.settings.timeFormat
        })

        this.updateTimeDisplay()

        const msToNextMinute = this.timeUtils.getMsUntilNextMinute()
        timer(msToNextMinute, 1000 * 60)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.updateTimeDisplay()
            })
    }

    updateTimeDisplay() {
        this.timeDisplay12hr = this.dateFormat.formatNow('h:mma', null, '12hr')
        this.timeDisplay24hr = this.dateFormat.formatNow('HH:mm', null, '24hr')
    }

    radioButtonChanged() {
        this.updateTimeDisplay()
        this.disableSaveButton = this.timeFormat === this.currentUser.settings.timeFormat
    }

    submitForm() {
        this.analyticsService.logClickedEvent('Save Preference', 'Edit Time Format Modal')
        this.userService.updateSettings({ timeFormat: this.timeFormat }).subscribe(() => {
            this.dialog.close()
        })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
