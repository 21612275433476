import { Injectable, Signal, WritableSignal, signal } from '@angular/core'
import {
    SESSION_ACTIVITY_TYPE_ANYTHING,
    SESSION_ACTIVITY_TYPE_DESK,
    SESSION_ACTIVITY_TYPE_MOVING,
    SESSION_DURATION_25_MINS,
    SESSION_DURATION_50_MINS,
    SESSION_DURATION_75_MINS,
    SessionActivityType,
    SessionPreferenceFavorites,
    User,
} from '../_models'
import * as Sentry from '@sentry/angular-ivy'

const LOCAL_STORAGE_SESSION_DURATION = 'sessionDurationSetting'
const LOCAL_STORAGE_SESSION_PREFERENCE_FAVORITES = 'sessionPreferenceFavorites'
const LOCAL_STORAGE_SESSION_PREFERENCE_ACTIVITY_TYPE = 'sessionPreferenceActivityType'
const LOCAL_STORAGE_SESSION_PREFERENCE_QUIET_MODE = 'sessionPreferenceQuietMode'

@Injectable({
    providedIn: 'root',
})
export class SessionSettingsService {
    private sessionDurationSetting: number
    private sessionPreferenceFavorites: SessionPreferenceFavorites
    private sessionActivityType: SessionActivityType

    public quietModeEnabled: WritableSignal<boolean> = signal(
        this.initializeSessionSettingQuietMode(),
    )

    constructor() {}

    public getSessionDurationSetting(user: User): number {
        if (!this.sessionDurationSetting) {
            let localSetting = localStorage.getItem(LOCAL_STORAGE_SESSION_DURATION)
            if (localSetting == '25m') {
                this.sessionDurationSetting = SESSION_DURATION_25_MINS
            } else if (localSetting == '50m') {
                this.sessionDurationSetting = SESSION_DURATION_50_MINS
            } else if (localSetting == '75m') {
                this.sessionDurationSetting = SESSION_DURATION_75_MINS
            } else {
                if (user.features['initialDefault25m'] === true) {
                    this.sessionDurationSetting = SESSION_DURATION_25_MINS
                } else {
                    this.sessionDurationSetting = SESSION_DURATION_50_MINS
                }
                this.setSessionDurationSetting(this.sessionDurationSetting)
            }
        }
        return this.sessionDurationSetting
    }

    public setSessionDurationSetting(sessionDuration: number) {
        this.sessionDurationSetting = sessionDuration

        try {
            if (sessionDuration === SESSION_DURATION_50_MINS) {
                localStorage.setItem(LOCAL_STORAGE_SESSION_DURATION, '50m')
            } else if (sessionDuration === SESSION_DURATION_25_MINS) {
                localStorage.setItem(LOCAL_STORAGE_SESSION_DURATION, '25m')
            } else if (sessionDuration === SESSION_DURATION_75_MINS) {
                localStorage.setItem(LOCAL_STORAGE_SESSION_DURATION, '75m')
            }
        } catch (e) {
            console.error(e)
            Sentry.captureException(e, {
                tags: {
                    'fm.errorDomain': 'sessionSettings',
                },
            })
        }
    }

    private isSessionPreferenceFavorites(value: any): boolean {
        return ['noPreference', 'preferred', 'onlyFavorites'].includes(value)
    }

    public getSessionSettingFavoritesPreference(): SessionPreferenceFavorites {
        if (!this.sessionPreferenceFavorites) {
            let localSetting
            try {
                localSetting = localStorage.getItem(LOCAL_STORAGE_SESSION_PREFERENCE_FAVORITES)
            } catch (e) {
                localSetting = null
            }
            if (localSetting && this.isSessionPreferenceFavorites(localSetting)) {
                this.sessionPreferenceFavorites = localSetting as SessionPreferenceFavorites
            } else {
                this.setSessionSettingFavoritesPreference('noPreference')
            }
        }
        return this.sessionPreferenceFavorites
    }

    public setSessionSettingFavoritesPreference(preference: SessionPreferenceFavorites) {
        this.sessionPreferenceFavorites = preference
        try {
            localStorage.setItem(LOCAL_STORAGE_SESSION_PREFERENCE_FAVORITES, preference)
        } catch (e) {
            console.error(e)
            Sentry.captureException(e, {
                tags: {
                    'fm.errorDomain': 'sessionSettings',
                },
            })
        }
    }

    private isSessionActivityType(value: string): boolean {
        return [
            SESSION_ACTIVITY_TYPE_ANYTHING,
            SESSION_ACTIVITY_TYPE_DESK,
            SESSION_ACTIVITY_TYPE_MOVING,
        ].includes(+value)
    }

    public getSessionSettingActivityType(): SessionActivityType {
        if (!this.sessionActivityType) {
            let localSetting
            try {
                localSetting = localStorage.getItem(LOCAL_STORAGE_SESSION_PREFERENCE_ACTIVITY_TYPE)
            } catch (e) {
                localSetting = null
            }
            if (localSetting && this.isSessionActivityType(localSetting)) {
                this.sessionActivityType = +localSetting as SessionActivityType
            } else {
                this.setSessionSettingActivityType(SESSION_ACTIVITY_TYPE_ANYTHING)
            }
        }
        return this.sessionActivityType
    }

    public setSessionSettingActivityType(activityType: SessionActivityType) {
        this.sessionActivityType = activityType
        try {
            localStorage.setItem(
                LOCAL_STORAGE_SESSION_PREFERENCE_ACTIVITY_TYPE,
                activityType.toString(),
            )
        } catch (e) {
            console.error(e)
            Sentry.captureException(e, {
                tags: {
                    'fm.errorDomain': 'sessionSettings',
                },
            })
        }
    }

    private initializeSessionSettingQuietMode(): boolean {
        let localSetting
        try {
            localSetting = localStorage.getItem(LOCAL_STORAGE_SESSION_PREFERENCE_QUIET_MODE)
        } catch (e) {
            localSetting = null
        }
        if (localSetting && this.isSessionPreferenceQuietMode(localSetting)) {
            return localSetting === 't'
        } else {
            return false
        }
    }

    private isSessionPreferenceQuietMode(quietMode: string): boolean {
        if (quietMode === 't' || quietMode === 'f') {
            return true
        }
        return false
    }

    public setSessionSettingQuietMode(quietMode: boolean) {
        this.quietModeEnabled.set(quietMode)
        try {
            localStorage.setItem(LOCAL_STORAGE_SESSION_PREFERENCE_QUIET_MODE, quietMode ? 't' : 'f')
        } catch (e) {
            console.error(e)
            Sentry.captureException(e, {
                tags: {
                    'fm.errorDomain': 'sessionSettings',
                },
            })
        }
    }
}
