import { Injectable, inject } from '@angular/core'
import { Router, UrlTree } from '@angular/router'
import { Observable, BehaviorSubject, ReplaySubject, Subject } from 'rxjs'
import { map, take, switchMap } from 'rxjs/operators'

import { API_ERROR_SIGNUP_DEA_REJECTED, User } from '@app/core/_models'
import { ApiService } from '@app/core/_services/api.service'
import { FirebaseTokenService } from '@app/core/_services/firebase-token.service'
import {
    AnalyticsService,
    RECEIVED_ERROR_ACTION_SIGN_UP,
} from '@app/core/_services/analytics.service'
import { BookLimitCheckService } from '@app/core/_services/book-limit-check.service'
import { environment } from '@env/environment'
import { HttpParams } from '@angular/common/http'
import { SelectedSessionService } from './selected-sessions.service'
import { ConfirmedSessionsService } from './confirmed-sessions.service'
import {
    LOGIN_ERROR_DEA_REJECTED,
    StoreRedirectOnLoginService,
} from './store-redirect-on-login.service'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'
import { UserSettings } from '@app/core/_models'
import { UtilsService } from './utils.service'
import { AutoCancelStatusService } from './auto-cancel-status.service'
import { RewardsReferralService } from './rewards-referral.service'
import { Auth, User as FirebaseUser, sendEmailVerification } from '@angular/fire/auth'
import { CookieService } from './cookie.service'
import * as Sentry from '@sentry/angular-ivy'

declare let profitwell

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private auth: Auth = inject(Auth)
    private currentUserSubject = new BehaviorSubject<User>(new User() as User)
    public currentUser = this.currentUserSubject.asObservable()

    private isAuthenticatedSubject = new ReplaySubject<boolean>(1)
    public isAuthenticated = this.isAuthenticatedSubject.asObservable()

    public isSwToBeEnabled$ = new Subject()

    private isEmailVerifiedSubject = new ReplaySubject<boolean>(1)
    public isEmailVerified = this.isEmailVerifiedSubject.asObservable()

    public sentEmailVerification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    private groupCode: string = ''
    private initialUrl: string = ''
    private firebaseUser: FirebaseUser = null
    private inviteeSignUpReferer: string = ''

    constructor(
        private router: Router,
        private apiService: ApiService,
        private firebaseTokenService: FirebaseTokenService,
        public analyticsService: AnalyticsService,
        private bookLimitCheckService: BookLimitCheckService,
        private selectedSessionService: SelectedSessionService,
        private confirmedSessionsService: ConfirmedSessionsService,
        private storeRedirectOnLoginService: StoreRedirectOnLoginService,
        private utilsService: UtilsService,
        private autoCancelStatusService: AutoCancelStatusService,
        private rewardsReferralService: RewardsReferralService,
        private cookieService: CookieService,
    ) {}

    setGroupCode(code: string) {
        this.groupCode = code
    }

    setSignUpLoginViaSocialInviteeFlow(path: string) {
        if (this.initialUrl === '') {
            this.initialUrl = path
            if (
                this.initialUrl.indexOf('/calendar') !== -1 &&
                this.initialUrl.indexOf('/signup') !== -1
            ) {
                this.setInviteSignUpReferer(this.initialUrl.split('/')[2])
            } else {
                let urlTree: UrlTree = this.router.parseUrl(this.initialUrl)
                if (urlTree.queryParams.hasOwnProperty('ref')) {
                    this.setInviteSignUpReferer(urlTree.queryParams['ref'])
                } else {
                    this.clearInviteSignUpReferer()
                }
            }
        }
    }

    setInviteSignUpReferer(referer) {
        this.inviteeSignUpReferer = referer
    }

    clearInviteSignUpReferer() {
        this.inviteeSignUpReferer = ''
    }

    populate(firebaseUser: FirebaseUser, facebookPermissionsGranted: boolean = false) {
        this.firebaseUser = firebaseUser

        if (firebaseUser && firebaseUser.email) {
            this.sentEmailVerification.next(false)

            firebaseUser.getIdToken(false).then((token) => {
                this.firebaseTokenService.setToken(token)
                let params = new HttpParams()

                if (this.inviteeSignUpReferer) {
                    params = params.append('ref', this.inviteeSignUpReferer)
                }

                const code = this.rewardsReferralService.getStoredRewardsReferralCode()
                if (code) {
                    params = params.append('rewardsReferral', code)
                }

                this.apiService
                    .getWithCredentials(environment.api_url + 'profiles/' + this.groupCode, params)
                    .subscribe(
                        (data) => {
                            if (
                                data.hasOwnProperty('error') &&
                                data.error === API_ERROR_SIGNUP_DEA_REJECTED
                            ) {
                                setTimeout(() => {
                                    this.analyticsService.logReceivedError(
                                        RECEIVED_ERROR_ACTION_SIGN_UP,
                                        LOGIN_ERROR_DEA_REJECTED,
                                    )
                                })
                                this.purgeAuth(true, false)
                                this.storeRedirectOnLoginService.storeSignInUpError(
                                    LOGIN_ERROR_DEA_REJECTED,
                                )
                            } else {
                                this.groupCode = ''
                                let currentUser: User = new User()
                                currentUser = Object.assign(data)
                                this.setVerified(
                                    firebaseUser.emailVerified || currentUser.emailVerified,
                                )

                                let updateUserNeeded: boolean = false
                                if (
                                    currentUser.emailVerified === false &&
                                    firebaseUser.emailVerified === true
                                ) {
                                    currentUser.emailVerified = firebaseUser.emailVerified
                                    this.update(currentUser, null).subscribe()
                                }

                                this.setCurrentUserInfo(currentUser)
                                this.analyticsService.logGAUserSignIn(data.newUser, data.email)

                                // confess: Moving on to the next step after user signs up
                                // as we do below does not feel right, eventually should find
                                // better way to do this

                                // If current url is either /login or /signup/method
                                // this means the user is logining in signing up and we should
                                // navigate to the calendar. If it's a new user, the calendar
                                // guard will do the necessary checks to determine if we should isntead
                                // go to the signup profile page
                                // In the case that the user didnt initially grant email permissions
                                // when signing up with Facebook, we would be getting here from /signup
                                // In this case we want to go to dashboard
                                if (
                                    facebookPermissionsGranted ||
                                    this.router.url.indexOf('/login') !== -1 ||
                                    this.router.url.indexOf('/signup/method') !== -1
                                ) {
                                    let redirect =
                                        this.storeRedirectOnLoginService.getUrlToRedirectToOnLogin()

                                    if (redirect) {
                                        this.router.navigate([redirect])
                                    } else {
                                        this.router.navigate(['/dashboard'])
                                    }
                                }

                                if (typeof profitwell !== 'undefined') {
                                    try {
                                        profitwell('user_email', currentUser.email)
                                    } catch (e) {
                                        console.error(e)
                                        Sentry.captureException(e, {
                                            tags: {
                                                'fm.errorDomain': 'profitwell',
                                            },
                                        })
                                    }
                                }
                            }
                        },
                        (err) => {
                            this.groupCode = ''
                            this.purgeAuth(true)
                        },
                    )
            })
        } else {
            // Remove any potential remnants of previous auth states
            this.purgeAuth(false)
        }
    }

    repopulate() {
        this.apiService
            .getWithCredentials(environment.api_url + 'profiles/' + this.groupCode)
            .subscribe(
                (data) => {
                    this.groupCode = ''
                    this.setCurrentUserInfo(data)
                },
                (err) => {
                    this.groupCode = ''
                    this.purgeAuth(true)
                },
            )
    }

    repopulateAndGoToDashboard() {
        this.apiService
            .getWithCredentials(environment.api_url + 'profiles/' + this.groupCode)
            .subscribe(
                (data) => {
                    this.groupCode = ''
                    this.setCurrentUserInfo(data)
                    this.router.navigate(['/dashboard'])
                },
                (err) => {
                    this.groupCode = ''
                    this.purgeAuth(true)
                },
            )
    }

    // TODO: Remove for now, will be used when we decide to auto
    // accept inviations when user signs in from group sign up page
    buildParams(): HttpParams {
        let groupId: string = localStorage.getItem('_groupInvite')

        return new HttpParams().set('groupId', groupId)
    }

    setVerified(verified: boolean) {
        this.isEmailVerifiedSubject.next(verified)
    }

    recheckEmailVerified() {
        if (this.firebaseUser) {
            return this.firebaseUser
                .reload()
                .then(() => {
                    if (this.firebaseUser.emailVerified) {
                        this.repopulate()
                    }
                    this.setVerified(this.firebaseUser.emailVerified)
                    return this.firebaseUser.emailVerified
                })
                .catch((error) => {
                    return this.firebaseUser.emailVerified
                })
        } else {
            return null
        }
    }

    sendEmailVerification() {
        if (this.firebaseUser) {
            return sendEmailVerification(this.firebaseUser)
                .then(() => {
                    this.sentEmailVerification.next(true)
                    return true
                })
                .catch((error) => {
                    return error
                })
        } else {
            return null
        }
    }

    setLoggedFlagInCookie(loggedIn: boolean) {
        const focusmateLoggedIn = loggedIn ? '1' : '0'
        this.cookieService.setCookie('focusmateLoggedIn', focusmateLoggedIn)
    }

    setCurrentUserInfo(user: User) {
        this.analyticsService.setUserId(user.userId)
        this.analyticsService.setUserProperty(user)

        try {
            if ((<any>window).Intercom) {
                ;(<any>window).Intercom('boot', {
                    role: 'user',
                    app_id: environment.intercom_app_id,
                    user_id: user.userId,
                    email: user.email,
                })
            }
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e
            }
        }

        moment.tz.setDefault(user.timeZone)
        // Set current user data into observable
        this.currentUserSubject.next(user)
        // Set isAuthenticated to true
        this.isAuthenticatedSubject.next(true)
        this.bookLimitCheckService.updateLimitInfo(user.bookLimit, user.compSessions, user.attRate)
        this.autoCancelStatusService.setAutoCancelStatus(user.noShowAckNeeded, user.acTrigger)
        this.setLoggedFlagInCookie(true)
    }

    // TODO: Refactor to remove circular dep between
    // UserService and AuthSerivce.
    purgeAuth(logout: boolean, updateUserId: boolean = true) {
        this.clearInviteSignUpReferer()
        this.selectedSessionService.deleteAllSelectedSessions()
        this.confirmedSessionsService.clearConfirmedSessions()
        // If User has moved from valid to invalid it possible
        // they were signed out from a different browser tab.
        // Transition to landing page here to avoid issues on pages
        // that require a valid user object
        this.currentUser.pipe(take(1)).subscribe((user) => {
            if (user.userId !== '') {
                this.router.navigate(['/'])
            }
        })

        // Set current user to an empty object
        this.currentUserSubject.next(new User() as User)
        // Set auth status to false
        this.isAuthenticatedSubject.next(false)
        this.setVerified(false)

        try {
            if ((<any>window).Intercom) {
                ;(<any>window).Intercom('shutdown')
            }
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e
            }
        }

        if (logout) {
            this.auth.signOut().then(() => {
                if (updateUserId) {
                    this.analyticsService.setUserId(null)
                }
            })
        }
        this.setLoggedFlagInCookie(false)
    }

    // HACK: Remove this when verify experiment is over
    public emitUpdatedUserInfo(user: User) {
        this.currentUserSubject.next(user)
    }

    // Update the user on the server (email, pass, etc)
    update(user: User, photo: any = null): Observable<any> {
        let fdata = this.prepareSave(user, photo)

        return this.apiService.postform(environment.api_url + 'profiles/', fdata).pipe(
            map((data: any) => {
                this.setCurrentUserInfo(data.user)
                return data
            }),
        )
    }

    updateSettings(settings: UserSettings) {
        return this.apiService.post(environment.api_url + 'profiles/settings', { settings }).pipe(
            map((data: any) => {
                this.setCurrentUserInfo(data.user)
                return data
            }),
        )
    }

    updateUserAndSettings(user: User, photo: any = null): Observable<any> {
        const fdata = this.prepareSave(user, photo)
        const settings = user.settings

        // call both /profiles and /profiles/settings sequentially, and only resolve after the second
        return this.apiService.postform(environment.api_url + 'profiles/', fdata).pipe(
            switchMap(() => {
                return this.apiService
                    .post(environment.api_url + 'profiles/settings', { settings })
                    .pipe(
                        map((data: any) => {
                            this.setCurrentUserInfo(data.user)
                            return data
                        }),
                    )
            }),
        )
    }

    private prepareSave(user: User, photo: any): FormData {
        let input = new FormData()

        if (photo != null) {
            input.append('avatar', photo)
        }
        input.append('timeZone', user.timeZone ?? '')
        input.append('firstName', user.firstName ?? '')
        input.append('familyName', user.familyName ?? '')
        input.append('displayName', user.displayName ?? '')
        input.append('profileUrl', user.profileUrl ?? '')
        input.append('hide', user.hide ? '1' : '0')
        input.append('onboard', user.onboard ? '1' : '0')
        input.append('tipReminders', user.tipReminders ? '1' : '0')
        input.append('calendarInvites', user.calendarInvites ? '1' : '0')
        input.append('weeklyStats', user.weeklyStats ? '1' : '0')
        input.append('emailVerified', user.emailVerified ? '1' : '0')
        input.append(
            'genderSelection',
            user.genderSelection ? user.genderSelection.toString() : '0',
        )
        input.append('gendersAdditional', user.gendersAdditional ? user.gendersAdditional : '')
        input.append(
            'genderPreference',
            user.genderPreference ? user.genderPreference.toString() : '0',
        )
        input.append('properties', JSON.stringify(user.properties))
        return input
    }

    isExistingUserWithRequiredInfo(user: User) {
        if (user.newUser || !user.timeZone || !user.firstName || !user.familyName) {
            return false
        } else {
            return true
        }
    }

    isUserAccountSetupComplete(user: User): boolean {
        if (
            !user.firstName ||
            !user.familyName ||
            !this.utilsService.isValidTimezone(user.timeZone)
        ) {
            return false
        } else {
            return true
        }
    }
}
