import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UtilsService } from '@app/core/_services/utils.service'

export const FINDING_PARTNER_ACTION_CANCEL = 1
export const FINDING_PARTNER_ACTION_TIMEOUT = 2
export const FINDING_PARTNER_ACTION_CLOSE = 3

@Component({
    selector: 'app-finding-partner',
    templateUrl: './finding-partner.component.html',
    styleUrls: ['./finding-partner.component.scss'],
})
export class FindingPartnerComponent implements OnInit {
    public countdownTo: number

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private util: UtilsService,
        private analyticsService: AnalyticsService,
        public dialog: MatDialogRef<FindingPartnerComponent>,
    ) {}

    ngOnInit(): void {
        let now = this.util.getCurrentTimestamp()

        this.analyticsService.logViewedModalEvent('New Partner: Searching')

        if (now >= this.data.countdownTo) {
            this.handleCountdownFinished()
        } else {
            this.countdownTo = this.data.countdownTo
        }
    }

    public handleCountdownFinished() {
        this.dialog.close(FINDING_PARTNER_ACTION_TIMEOUT)
    }

    public cancelSearch() {
        this.analyticsService.logClickedEvent(
            'Cancel search and return to Dashboard',
            'newPartnerSearchingModal',
        )
        this.dialog.close(FINDING_PARTNER_ACTION_CANCEL)
    }
}
