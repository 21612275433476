<div class="std-modal-container">
    <div class="std-modal-header">
        <div class="std-modal-title"> We need to change your partner </div>
    </div>
    <div class="std-modal-body w-full">
        <div class="std-modal-body-text w-full">
            <p>When you join more than one minute late, we’ll often need to change your partner.</p>
            <p>Should we try to find you a new partner?</p>
        </div>
    </div>

    <div class="std-modal-actions-and-timer-container">
        <div class="std-modal-actions">
            <button
                style="margin-right: 10px"
                class="f-btn f-btn--secondary f-btn-250"
                (click)="cancelSession()"
                >Cancel session</button
            >

            <button
                style="margin-left: 10px"
                class="f-btn f-btn--primary f-btn-250"
                (click)="requestNewPartner()"
                >Request new partner</button
            >
        </div>
        <div class="modal-timer"
            >You have&nbsp;<app-fm-countdown
                [countdownTo]="countdownTo"
                (onCountdownFinished)="handleCountdownFinished()"></app-fm-countdown
            >&nbsp;to request a new partner.</div
        >
    </div>
</div>
