// wrapper interface to indicate the different states an AJAX request can be in

interface XHRInitial {
    state: 'initial'
}

interface XHRLoading {
    state: 'loading'
}

interface XHRError {
    state: 'error'
    error: string
}

interface XHRSuccess<T> {
    state: 'success'
    data: T
}

export type XHR<T> = XHRInitial | XHRLoading | XHRError | XHRSuccess<T>

// helpers
export const XHRInitial: XHRInitial = { state: 'initial' as const }
export const XHRLoading: XHRLoading = { state: 'loading' as const }
export const XHRError = (error) => ({ state: 'error' as const, error })
export const XHRSuccess = (data) => ({ state: 'success' as const, data })
