<!-- <div *ngIf="!reactivated" class="fm-ev-container">

    <div class="fm-ev-title fm-mb-30px text-center">Upcoming sessions automatically canceled</div>
    <div class="fm-ev-text fm-mb-20px text-center">

        <p>It seems that you didn't join your session at {{displayTime}}, or were more than 10 minutes late.</p>

        <p>To protect your account and your timeliness score, we are continuously canceling your upcoming sessions 3
            hours before they would start.</p>

        <p>Once you reactivate your account, we'll stop automatically canceling your upcoming sessions.</p>

    </div>
    <button type="button" class="fm-btn fm-btn--md fm-btn--solid" (click)="confirmNoShow()">Reactivate</button>
</div>
<div *ngIf="reactivated" class="fm-ev-container">

    <div class="fm-ev-title fm-mb-30px text-center">Account Reactivated!</div>

</div> -->

<div class="std-modal-container">
    <div class="std-modal-header">
        <div *ngIf="reactivated" class="std-modal-title">Reactivated!</div>
        <div *ngIf="!reactivated" class="std-modal-title">Everything OK?</div>
    </div>

    <div *ngIf="!reactivated" class="std-modal-body w-full">
        <div class="std-modal-body-text text-left w-full">
            <p
                >Our helpful bots detected that you may have missed your most recent session on
                {{ displayTime }}. We hope you’re OK!</p
            >

            <p
                >We already let your partner know that you couldn’t make it, and we'll safeguard
                your account by canceling each upcoming session, one at a time, 30 minutes before it
                starts.</p
            >

            <p
                >When you’re ready, just reactivate your account below (and we won’t cancel any more
                sessions).</p
            >
        </div>

        <div class="std-modal-action-container">
            <app-spinner-button
                class="action"
                [disable]="reactivated"
                [label]="'Reactivate my account – I’m OK!'"
                classes="f-btn f-btn--primary f-btn-250"
                (clicked)="confirmNoShow()">
            </app-spinner-button>
        </div>
    </div>
</div>
