<div class="std-modal-container fm-terms-card-container">
    <div class="std-modal-header">
        <div class="std-modal-title">
            We have updated <span class="text-nowrap"> our Terms</span>
        </div>
    </div>

    <div class="std-modal-body std-modal-body-text">
        <div class="fm-mb-25px">
            In line with our mission to help everyone do their best work, we are adding some
            information to our Terms of Service and Privacy Policy to better serve our community.
        </div>
        <div class="fm-mb-25px terms-summary-list">
            <ul>
                <li
                    ><span style="font-weight: 600">Terms of Service:</span> We are making our Terms
                    of Service clearer and easier to understand with respect to our products,
                    offerings, and pricing schedules.</li
                >
                <li
                    ><span style="font-weight: 600">Privacy Policy:</span> We are updating our
                    Privacy Policy in response to the requirements of the General Data Protection
                    Regulation (GDPR) and the newly effective U.S. state privacy laws.</li
                >
                <li
                    ><span style="font-weight: 600">Data Processing Addendum:</span> We have added
                    information regarding our data processing security measures and procedures.</li
                >
            </ul>
        </div>
        <div class="policy-links">
            Please take a look at our updated
            <a class="link" href="{{ env.marketingHomeUrl }}/terms/" target="_blank"
                >Terms of Service</a
            >,
            <a class="link" href="{{ env.marketingHomeUrl }}/privacy/" target="_blank"
                >Privacy Policy</a
            >,
            <a class="link" href="{{ env.marketingHomeUrl }}/dpa/" target="_blank"
                >Data Processing Addendum</a
            >
            and
            <a class="link" href="{{ env.marketingHomeUrl }}/sub-processor-list/" target="_blank"
                >Sub-processor List</a
            >.
        </div>

        <div class="std-modal-action-container">
            <button class="std-action f-btn f-btn--primary f-btn-250" (click)="accept()"
                >Agree and continue</button
            >
        </div>
        <div class="disclaimer-text fm-mt-15px">
            By continuing to use and access our services, you accept and agree to the updated Terms
            of Service, Privacy Policy
        </div>
    </div>
</div>
