import { Injectable, Signal, WritableSignal, computed, signal } from '@angular/core'
import { ApiService } from './api.service'
import { Subject, filter, take, takeUntil } from 'rxjs'
import { DialogService } from './dialog.service'
import { UserService } from './user.service'
import { environment } from '@env/environment'
import { FIVE_MINUTES_IN_MSECS, UtilsService } from './utils.service'
import {
    WelcomeChecklistStep,
    WelcomeChecklistStepName,
    WelcomeChecklistSteps,
} from '../_models/welcome-checklist'
import { ConfirmedSessionsResponse, Container, User, WelcomeChecklistState } from '../_models'
import { ConfettiService } from './confetti.service'
import { ConfirmedSessionsService } from './confirmed-sessions.service'
import { AnalyticsService } from './analytics.service'
import { BookSessionsService } from './book-sessions.service'
import { NavigationEnd, Router } from '@angular/router'
import { FeatureCheckService } from './feature-check.service'

export const defaultChecklistSteps: WelcomeChecklistSteps = [
    { name: 'welcome', complete: false, visible: false },
    { name: 'howItWorks', complete: false, visible: true },
    { name: 'reviewCommunityGuidelines', complete: false, visible: true },
    { name: 'bookFirstSession', complete: false, visible: true },
    { name: 'completeFirstSession', complete: false, visible: true },
    { name: 'acknowledgeCompletion', complete: false, visible: false },
]

@Injectable({
    providedIn: 'root',
})
export class WelcomeChecklistService {
    private ngUnsubscribe: Subject<any> = new Subject<any>()

    // exported signals
    public welcomeChecklistSteps: WritableSignal<WelcomeChecklistSteps> = signal(null)
    public activeStep: Signal<WelcomeChecklistStep> = computed(() => {
        if (!this.welcomeChecklistSteps()) {
            return null
        }
        return this.welcomeChecklistSteps().find((step) => !step.complete)
    })
    public isWelcomeChecklistActive: WritableSignal<boolean> = signal(false)

    private isWelcomeChecklistModalOpen: boolean = false
    private shouldOpenModalOnNextAllowedUrl: boolean = false
    private modalAllowedUrls = ['/dashboard']
    private currentUrl: string = ''

    private confirmedSessionsIntervalId: any
    private confirmedSessionsInitialTimerId: any

    private user: User

    constructor(
        private apiService: ApiService,
        private dialogService: DialogService,
        private userService: UserService,
        private util: UtilsService,
        private confettiService: ConfettiService,
        private confirmedSessionsService: ConfirmedSessionsService,
        private bookSessionService: BookSessionsService,
        private analyticsService: AnalyticsService,
        private router: Router,
    ) {
        this.currentUrl = this.router.url

        this.userService.currentUser.subscribe((user) => {
            this.user = user

            // we have new user info. check if they should see the welcome checklist
            const userShouldSeeChecklist =
                user.hasCompletedWelcomeChecklist !== undefined &&
                user.hasCompletedWelcomeChecklist === false

            if (userShouldSeeChecklist && this.isWelcomeChecklistActive() === false) {
                this.activateChecklist()
            } else if (!userShouldSeeChecklist && this.isWelcomeChecklistActive() === true) {
                this.deactivateChecklist()
            }
        })
    }

    /**
     * The current user should see the checklist. Set up listeners and necessary state
     */
    private activateChecklist(): void {
        this.isWelcomeChecklistActive.set(true)

        // format steps to be used locally
        const steps = this.createStepsFromBackendState(this.user.welcomeChecklistState)
        this.welcomeChecklistSteps.set(steps)

        // set up a timer to request an update to confirmed sessions at every possible session end time
        // to make sure we respond immediately to a completed session. right now, session can end every 5 minutes
        const now = this.util.getCurrentTimestamp()
        const nextCheckTime = Math.ceil(now / FIVE_MINUTES_IN_MSECS) * FIVE_MINUTES_IN_MSECS
        const timeUntilnextCheckTime = nextCheckTime - now + 3000 // add 3 seconds to give the backend extra time to process end-of-session
        this.confirmedSessionsInitialTimerId = setTimeout(() => {
            this.confirmedSessionsService.getConfirmedSessions().subscribe()
            this.confirmedSessionsIntervalId = setInterval(() => {
                this.confirmedSessionsService.getConfirmedSessions().subscribe()
            }, FIVE_MINUTES_IN_MSECS)
        }, timeUntilnextCheckTime)

        // listen for url changes to detect when to show the modal
        this.currentUrl = this.router.url
        this.router.events
            .pipe(
                takeUntil(this.ngUnsubscribe),
                filter((event) => event instanceof NavigationEnd),
            )
            .subscribe((event: NavigationEnd) => {
                this.currentUrl = event.url
                if (
                    this.shouldOpenModalOnNextAllowedUrl &&
                    this.modalAllowedUrls.includes(this.currentUrl)
                ) {
                    this.openWelcomeChecklistModal(true)
                    this.shouldOpenModalOnNextAllowedUrl = false
                }
            })

        // listen for book session events
        this.bookSessionService.sessionBookedSubject
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                const wasStepAlreadyCompleted = this.isStepComplete('bookFirstSession')
                this.completeStep('welcome')
                this.completeStep('bookFirstSession')

                if (
                    !wasStepAlreadyCompleted ||
                    !this.isStepComplete('howItWorks') ||
                    !this.isStepComplete('reviewCommunityGuidelines')
                ) {
                    this.openWelcomeChecklistModalOnNextAllowedUrl(true)
                }
            })

        // also listen for confirmed sessions to detect a booked session - mostly
        // redundant, but it doesn't hurt
        this.confirmedSessionsService.confirmedSessionsSubject
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((confirmedSessions: Container<ConfirmedSessionsResponse>) => {
                if (confirmedSessions.length() > 0 && !this.isStepComplete('bookFirstSession')) {
                    this.completeStep('welcome')
                    this.completeStep('bookFirstSession')
                    this.openWelcomeChecklistModalOnNextAllowedUrl(true)
                }
            })

        // listen for updates to the completed session count
        this.confirmedSessionsService.completedSessionCountSubject
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((count) => {
                if (count > 0 && !this.isStepComplete('completeFirstSession')) {
                    this.completeStep('welcome')
                    this.completeStep('bookFirstSession')
                    this.completeStep('completeFirstSession')
                    this.openWelcomeChecklistModalOnNextAllowedUrl(true)
                }
            })
    }

    /**
     * The current user should not see the checklist. Clean up listeners and state
     */
    private deactivateChecklist(): void {
        this.isWelcomeChecklistActive.set(false)
        this.welcomeChecklistSteps.set(null)
        this.clearConfirmedSessionsTimers()
        this.ngUnsubscribe.next(null)
    }

    /**
     * Check if the user needs to review the community guidelines.
     */
    public doesUserNeedToReviewCommunityGuidelines(): boolean {
        return this.isWelcomeChecklistActive() && !this.isStepComplete('reviewCommunityGuidelines')
    }

    /**
     * Check if a step is completed.
     */
    public isStepComplete(stepName: WelcomeChecklistStepName): boolean {
        if (!this.welcomeChecklistSteps()) {
            return true
        }
        const step = this.welcomeChecklistSteps().find((step) => step.name === stepName)
        return step.complete
    }

    /**
     * Check if all steps are completed.
     */
    public areAllStepsComplete(): boolean {
        if (!this.welcomeChecklistSteps()) {
            return true
        }
        return this.welcomeChecklistSteps().every((step) => step.complete)
    }

    /**
     * Mark a step as completed in the backend and update step state.
     */
    public completeStep(stepName: WelcomeChecklistStepName): void {
        // if the step is already complete, do nothing
        if (this.isStepComplete(stepName)) {
            return
        }

        // since steps can be completed quickly, and to make it feel snappier, let's optimistically update.
        this.completeStepLocally(stepName)

        // collect step data to send to the backend
        const checklistSteps = {}
        const now = this.util.getCurrentUtcIsoString()
        checklistSteps[stepName] = now

        // mark checklist as completed if all steps are complete
        const hasCompletedWelcomeChecklist = this.areAllStepsComplete()
        if (hasCompletedWelcomeChecklist) {
            this.deactivateChecklist()
        }

        const requestData = { checklistSteps, hasCompletedWelcomeChecklist }

        this.apiService
            .post(environment.api_url + 'welcome-checklist', requestData)
            .subscribe((res) => {
                // if we've completed every step, repopulate the user object
                if (hasCompletedWelcomeChecklist) {
                    this.userService.repopulate()
                }
            })

        // analytics
        this.analyticsService.logWelcomeChecklistStepCompleted(stepName)
    }

    /**
     * Initial trigger for the welcome checklist when the page loads
     */
    public async openWelcomeChecklistAtPageLoad(): Promise<void> {
        if (!this.isWelcomeChecklistActive()) {
            return
        }

        const activeStep = this.welcomeChecklistSteps().find((step) => !step.complete)

        // if the next step is bookFirstSession or completeFirstSession, do nothing
        if (activeStep.name === 'bookFirstSession' || activeStep.name === 'completeFirstSession') {
            return
        }

        this.openWelcomeChecklistModal(true)
    }

    /**
     * Open the modal, but only if we're on a page that allows it. Otherwise,
     * set a flag to open it later
     */
    private async openWelcomeChecklistModalOnNextAllowedUrl(
        comingFromInternalTrigger: boolean = false,
    ): Promise<void> {
        if (this.modalAllowedUrls.includes(this.currentUrl)) {
            this.openWelcomeChecklistModal(comingFromInternalTrigger)
        } else {
            this.shouldOpenModalOnNextAllowedUrl = true
        }
    }

    /**
     * Open the modal for the first incomplete step.
     */
    public async openWelcomeChecklistModal(
        comingFromInternalTrigger: boolean = false,
    ): Promise<void> {
        // prevent multiple modals from opening
        if (this.isWelcomeChecklistModalOpen) {
            return
        }
        this.isWelcomeChecklistModalOpen = true

        this.dialogService.openWelcomeChecklistModal(
            {
                comingFromInternalTrigger,
                steps: this.welcomeChecklistSteps,
                activeStep: this.activeStep,
                completeStep: (s) => {
                    this.completeStep(s)
                },
            },
            () => {
                this.isWelcomeChecklistModalOpen = false
            },
        )
    }

    /**
     * Mark a step as completed in this service, but don't update the backend. Used for optimistic UI updates.
     */
    private completeStepLocally(stepName: WelcomeChecklistStepName): void {
        // make a deep copy to force a change detection in the signal
        const newSteps = JSON.parse(JSON.stringify(this.welcomeChecklistSteps()))
        const step = newSteps.find((step) => step.name === stepName)
        step.complete = true
        this.welcomeChecklistSteps.set(newSteps)
    }

    /**
     * Given the backend's welcome checklist state, format it into an ordered
     * list of steps to be used locally
     */
    private createStepsFromBackendState(
        backendState: WelcomeChecklistState,
    ): WelcomeChecklistSteps {
        // the backend only stores steps that have been completed
        const steps = defaultChecklistSteps.map((step) => {
            const foundStep = backendState[step.name]
            if (foundStep) {
                step.complete = true
            }
            return step
        })
        return steps
    }

    /**
     * Clear the timers that check for confirmed sessions
     */
    private clearConfirmedSessionsTimers(): void {
        if (this.confirmedSessionsIntervalId) {
            clearInterval(this.confirmedSessionsIntervalId)
            this.confirmedSessionsIntervalId = null
        }

        if (this.confirmedSessionsInitialTimerId) {
            clearTimeout(this.confirmedSessionsInitialTimerId)
            this.confirmedSessionsInitialTimerId = null
        }
    }

    public ngOnDestroy(): void {
        // this is probably not needed in a long-lived service, but can't hurt
        this.clearConfirmedSessionsTimers()
        this.ngUnsubscribe.next(null)
    }
}
