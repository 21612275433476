import { Injectable, NgZone } from '@angular/core'
import { Subject } from 'rxjs'

declare var window: any

@Injectable()
export class MediaQueryService {
    mqueries = {
        mobile: '(max-width: 400px)',
        xsmall: '(min-width: 401px) and (max-width: 550px)',
        small: '(min-width: 551px) and (max-width: 950px)',
        large: '(min-width: 951px)',
    }

    private currentQuerySubject = new Subject<string>()
    public mediaQueryCurrentMatch = this.currentQuerySubject.asObservable()
    private currentValue = this.getMqName

    constructor(private zone: NgZone) {
        for (let queryName in this.mqueries) {
            switch (queryName) {
                case 'mobile':
                    window.matchMedia(this.mqueries[queryName]).addListener((mqlParam) => {
                        this.zone.run(() => {
                            if (mqlParam.matches) {
                                this.emitMediaQueryChange('mobile')
                            }
                        })
                    })

                    break

                case 'xsmall':
                    window.matchMedia(this.mqueries[queryName]).addListener((mqlParam) => {
                        this.zone.run(() => {
                            if (mqlParam.matches) {
                                this.emitMediaQueryChange('xsmall')
                            }
                        })
                    })
                    break

                case 'small':
                    window.matchMedia(this.mqueries[queryName]).addListener((mqlParam) => {
                        this.zone.run(() => {
                            if (mqlParam.matches) {
                                this.emitMediaQueryChange('small')
                            }
                        })
                    })
                    break

                case 'large':
                    window.matchMedia(this.mqueries[queryName]).addListener((mqlParam) => {
                        this.zone.run(() => {
                            if (mqlParam.matches) {
                                this.emitMediaQueryChange('large')
                            }
                        })
                    })
                    break

                default:
                    break
            }
        }
    }

    private emitMediaQueryChange(size) {
        this.currentValue = size
        this.currentQuerySubject.next(this.currentValue)
    }

    check(mqName: string): boolean {
        if (!this.mqueries[mqName]) {
            console.warn(`No media query registered for "${mqName}"!`)
        }
        return window.matchMedia(this.mqueries[mqName]).matches
    }

    get getMqName(): string {
        for (const key in this.mqueries) {
            if (window.matchMedia(this.mqueries[key]).matches) {
                return key
            }
        }
    }
}
