<ng-container *ngIf="userService.currentUser | async as user">
    <a
        *ngIf="userService.currentUser | async as user"
        class="report-btn"
        [matMenuTriggerFor]="report"
        style="cursor: pointer">
        <fm-icon [classes]="classes" icon="fm-icon-vertical-3-dot-menu"></fm-icon>
    </a>

    <mat-menu class="fm-menu" #report="matMenu">
        <a
            *ngIf="showSeeAvailabilityOption && sharedAvailability; else disabledOrNotPresent"
            class="fm-menu-item-text fm-menu-item-text-grey"
            (click)="logSeeAvailability()"
            [routerLink]="'/user/' + targetUserProfileUrl + '/availability'"
            target="_blank"
            mat-menu-item>
            <div class="menu-icon-container">
                <fa-icon [icon]="faCalendar" [classes]="['fa-icon-size-14']"></fa-icon>
            </div>
            See availability
        </a>
        <ng-template #disabledOrNotPresent>
            <div
                *ngIf="showSeeAvailabilityOption && !sharedAvailability"
                matTooltip="They're currently not sharing their availability."
                matTooltipPosition="below"
                matTooltipShowDelay="200"
                matTooltipClass="f-tooltip">
                <button
                    class="fm-menu-item-text fm-menu-item-text-grey"
                    [disabled]="!sharedAvailability"
                    mat-menu-item>
                    <div class="menu-icon-container">
                        <fa-icon [icon]="faCalendar" [classes]="['fa-icon-size-14']"></fa-icon>
                    </div>
                    See availability
                </button>
            </div>
        </ng-template>

        <a
            *ngIf="showViewProfile"
            class="fm-menu-item-text fm-menu-item-text-grey"
            (click)="viewProfile()"
            [routerLink]="'/user/' + targetUserProfileUrl"
            target="_blank"
            mat-menu-item>
            <div class="menu-icon-container">
                <fa-icon [icon]="faAddressCard" [classes]="['fa-icon-size-14']"></fa-icon>
            </div>
            View profile
        </a>

        <div *ngIf="sectionOnePresent && sectionTwoPresent" class="sep"></div>
        <button
            *ngIf="!snoozeStatus && showSnooze"
            [disabled]="user.userId == targetUserId"
            class="fm-menu-item-text fm-menu-item-text-grey"
            (click)="snooze(false)"
            mat-menu-item>
            <div class="menu-icon-container">
                <fa-icon [icon]="faClockEight" [classes]="['fa-icon-size-14']"></fa-icon>
            </div>
            Snooze
        </button>
        <button
            *ngIf="snoozeStatus && showUnsnooze"
            [disabled]="user.userId == targetUserId"
            class="fm-menu-item-text fm-menu-item-text-grey"
            (click)="unsnooze()"
            mat-menu-item>
            <div class="menu-icon-container">
                <fa-icon [icon]="faTimes" [classes]="['fa-icon-size-14']"></fa-icon>
            </div>
            Unsnooze
        </button>
        <button
            *ngIf="snoozeStatus && showEditSnooze"
            [disabled]="user.userId == targetUserId"
            class="fm-menu-item-text fm-menu-item-text-grey"
            (click)="snooze(true)"
            mat-menu-item>
            <div class="menu-icon-container">
                <fa-icon [icon]="faPencil" [classes]="['fa-icon-size-14']"></fa-icon>
            </div>
            Edit snooze
        </button>
        <div
            *ngIf="(sectionOnePresent || sectionTwoPresent) && sectionThreePresent"
            class="sep"></div>
        <button
            *ngIf="(status == 0 || status == 1) && showBlock"
            [disabled]="user.userId == targetUserId"
            class="fm-menu-item-text fm-menu-item-text-grey"
            (click)="openBlockUserDialog()"
            mat-menu-item>
            <div class="menu-icon-container">
                <fa-icon [icon]="faMinusOctagon" [classes]="['fa-icon-size-14']"></fa-icon>
            </div>
            Block
        </button>
        <button
            *ngIf="(status == 2 || status == 3) && showUnblock"
            [disabled]="user.userId == targetUserId"
            class="fm-menu-item-text fm-menu-item-text-grey"
            (click)="unblockUser()"
            mat-menu-item>
            <div class="menu-icon-container">
                <fm-icon [classes]="menuItemClasses" icon="fm-icon-unblock"></fm-icon
            ></div>
            Unblock
        </button>
        <button
            *ngIf="showReportSession"
            [disabled]="user.userId == targetUserId"
            class="fm-menu-item-text fm-menu-item-text-grey"
            (click)="reportPartner()"
            mat-menu-item>
            <div class="menu-icon-container">
                <fa-icon [icon]="faExclamationTriangle" [classes]="['fa-icon-size-14']"></fa-icon>
            </div>
            Report
        </button>
        <button
            *ngIf="showReportProfile"
            [disabled]="user.userId == targetUserId"
            class="fm-menu-item-text fm-menu-item-text-grey"
            (click)="reportProfileViolation()"
            mat-menu-item>
            <div class="menu-icon-container">
                <fa-icon [icon]="faExclamationTriangle" [classes]="['fa-icon-size-14']"></fa-icon>
            </div>
            Report
        </button>
    </mat-menu>
</ng-container>
