<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close(false)">
    </fa-icon>

    <div class="std-modal-header">
        <div class="font-avenir font-semibold text-26-36 text-center mb-[5px] text-blurple"
            >Help us improve your Focusmate experience 🚀</div
        >
    </div>

    <div class="std-modal-body w-full">
        <div class="std-modal-body-text text-center w-full">
            <p>Would you please take 1 minute to tell us about yourself?</p>
            <p>
                Your answers will help make Focusmate better for you, and will be kept 100%
                confidential.</p
            >
        </div>

        <div class="std-modal-action-container">
            <a
                [href]="surveyLink"
                target="_blank"
                class="f-btn f-btn--primary f-btn-250"
                (click)="trackSurveyVisited()"
                >Go to the questions</a
            >
            <button class="f-link fm-mt-15px" (click)="dialog.close(false)">Maybe later</button>
        </div>
    </div>
</div>
