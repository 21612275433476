import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { XHR, XHRInitial, XHRLoading, XHRSuccess } from '@app/core/_models/xhr'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { ApiKeyService } from '@app/core/_services/api-key.service'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

@Component({
    selector: 'app-api-key-modal',
    templateUrl: './api-key-modal.component.html',
    styleUrls: ['./api-key-modal.component.scss'],
})
export class ApiKeyModalComponent {
    public faTimes = faTimes

    public keyCopiedToClipboard: boolean = false
    public xhrHasActiveApiKey: XHR<boolean> = XHRInitial
    public newKey?: string

    constructor(
        private analyticsService: AnalyticsService,
        public dialog: MatDialogRef<ApiKeyModalComponent>,
        public apiKeyService: ApiKeyService,
    ) {
        this.analyticsService.logViewedModalEvent('API Key Modal')

        // query whether or not the user has an active API key
        this.xhrHasActiveApiKey = XHRLoading
        this.apiKeyService.hasActiveApiKey().subscribe((response) => {
            this.xhrHasActiveApiKey = XHRSuccess(response.hasActiveApiKey)
        })
    }

    confirmGenerateApiKey() {
        this.analyticsService.logClickedEvent('Confirm Generate API Key', 'apiKeyModal')
        this.apiKeyService.generateApiKey().subscribe((response) => {
            this.newKey = response.apiKey
        })
    }

    onClickKeyArea() {
        if (this.newKey && !this.keyCopiedToClipboard) {
            this.keyCopiedToClipboard = true
        }
    }
}
