import { BrowserModule } from '@angular/platform-browser'
import { NoopAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgModule, Injectable, ErrorHandler } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'

import { environment } from '@env/environment'
import { AppRoutingModule } from '@app/app.routing'
import { AppComponent } from '@app/app.component'
import { CoreModule } from './core/core.module'
import { MaterialModule } from './material/material.module'
import { SharedModule } from './shared/shared.module'
import { SharedDialogsModule } from './shared-dialogs/shared-dialogs.module'
import { LoggedResolverService } from './core/_services/logged-resolver.service'
import { MomentDateModule } from '@angular/material-moment-adapter'
import { UserActionsModule } from './user-actions/user-actions.module'
import { PeopleModule } from './people/people.module'
import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { getAuth, provideAuth } from '@angular/fire/auth'
import { ServiceWorkerModule } from '@angular/service-worker'
import * as Sentry from '@sentry/angular-ivy'
import { Router } from '@angular/router'

@NgModule({
    declarations: [AppComponent],
    exports: [FormsModule],
    imports: [
        BrowserModule,
        NoopAnimationsModule,
        FormsModule,
        MaterialModule,
        CoreModule,
        SharedDialogsModule,
        SharedModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        MomentDateModule,
        UserActionsModule,
        PeopleModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        LoggedResolverService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: environment.sentryShowDialog,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(trace: Sentry.TraceService) {}
}
