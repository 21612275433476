import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import {
    GENDER_MASK_PREFER_NOT_SAY,
    GENDER_MATCH_NO_PREFERENCE,
    GENDER_MATCH_STRING_NO_PREFERENCE,
    GENDER_MATCH_STRING_WOMEN_AND_NON_BINARY,
    GENDER_MATCH_WOMEN_AND_NON_BINARY,
    User,
} from '@app/core/_models'
import { UtilsService } from '@app/core/_services/utils.service'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { GenderService } from '@app/core/_services/gender.service'
import { UserService } from '@app/core/_services/user.service'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
    selector: 'app-gender-preference-update-gender-modal',
    templateUrl: './gender-preference-update-gender-modal.component.html',
    styleUrls: ['./gender-preference-update-gender-modal.component.scss'],
})
export class GenderPreferenceUpdateGenderModalComponent implements OnInit {
    public faTimes = faTimes
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    private currentUser: User = new User()
    public genderList: string = ''

    constructor(
        public dialog: MatDialogRef<GenderPreferenceUpdateGenderModalComponent>,
        public userService: UserService,
        private genderService: GenderService,
        private utilsService: UtilsService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.currentUser = user

            if (
                !user.genderSelection ||
                user.genderSelection === GENDER_MASK_PREFER_NOT_SAY ||
                user.genderSelection === 0
            ) {
                this.analyticsService.logViewedModalEvent('Gender Preference: Gender not set')
                this.genderList = ''
            } else {
                this.analyticsService.logViewedModalEvent('Gender Preference: Cisgender Only')
                this.genderList = this.genderService.buildGenderList(
                    user.genderSelection,
                    user.gendersAdditional,
                )
            }
        })
    }

    updateGenders() {
        this.dialog.close(true)
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
