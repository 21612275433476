import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@app/core/_services/api.service'
import { environment } from '@env/environment'

@Injectable()
export class TermsService {
    constructor(private apiService: ApiService) {}

    sendTermsAgreement(tosPpVersion): Observable<any> {
        return this.apiService.post(environment.api_url + 'accept_tos_pp', { tosPpVersion }).pipe()
    }

    ngOnDestroy() {}
}
