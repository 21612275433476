import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-f-toggle',
    templateUrl: './f-toggle.component.html',
    styleUrls: ['./f-toggle.component.scss'],
})
export class FToggleComponent implements OnInit {
    @Input() selected: boolean
    @Input() value: number
    @Input() disabled: boolean
    @Input() classes: string = ''
    @Output() toggleChange = new EventEmitter<number>()

    public extraClasses: string = ''

    constructor() {}

    ngOnInit(): void {
        this.updateClasses()
    }

    ngOnChanges(): void {
        this.updateClasses()
    }

    updateClasses() {
        let onOff = 'off'
        if (this.selected) {
            onOff = 'on'
        }
        if (this.classes) {
            this.extraClasses = onOff + ' ' + this.classes
        } else {
            this.extraClasses = onOff
        }
    }

    toggled() {
        this.toggleChange.emit(this.value)
    }
}
