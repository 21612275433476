import { Injectable } from '@angular/core'
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router'
import { UserService } from '@app/core/_services/user.service'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class OauthCallbackGuard {
    constructor(
        private router: Router,
        private userService: UserService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return new Observable((obs) => {
            if (!route.params.provider) {
                console.error('Missing provider')
                this.router.navigate(['/login'])
                obs.next(false)
            }

            this.userService.isAuthenticated.pipe(first()).subscribe((authenticated) => {
                if (!authenticated) {
                    this.router.navigate(['/login'])
                    obs.next(false)
                } else {
                    obs.next(true)
                }
            })
        })
    }
}
