import { Component, Input, OnInit } from '@angular/core'
import { SafeResourceUrl } from '@angular/platform-browser'
import { SESSION_ACTIVITY_TYPE_ANYTHING, SessionActivityType } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { TimeUtilitiesService } from '@app/core/_services/time-utilities.service'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'
import { UserService } from '@app/core/_services/user.service'
import { faAddressCard } from '@fortawesome/pro-regular-svg-icons'
import {
    faGlobeAfrica,
    faGlobeAmericas,
    faGlobeAsia,
    faGlobeEurope,
} from '@fortawesome/pro-solid-svg-icons'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'

const NEW_USER = 'New!'
const ONE_MINUTE = 60000

@Component({
    selector: 'app-timeslot-partner-item',
    templateUrl: './timeslot-partner-item.component.html',
    styleUrls: ['./timeslot-partner-item.component.scss'],
})
export class TimeslotPartnerItemComponent implements OnInit {
    @Input() displayName: string | null = null
    @Input() saved: boolean = false
    @Input() profileImage: string | null = null
    @Input() profileUrl: string | null = null
    @Input() totalSessions: number = 0
    @Input() sharedSessions: number = 0
    @Input() lastSessionTogether: number | null = null
    @Input() timezone: string | null = null
    @Input() isCommunityMember: boolean = false
    @Input() snoozed: boolean = false
    @Input() snoozeTooltip: string | null = null
    @Input() selected: boolean = false
    @Input() currentUserTimezone: string = ''
    @Input() activityType: SessionActivityType | null = null
    @Input() quietMode: boolean = false

    public faAddressCard = faAddressCard
    public faGlobe
    public sessionsInfo: string
    public lastMet: string
    public timezoneAndTime: string
    public profileLink: SafeResourceUrl
    private timeoutId: any

    constructor(
        private analyticsService: AnalyticsService,
        private timeUtilitiesService: TimeUtilitiesService,
        public userService: UserService,
        private dateFormat: UserDateFormattingService,
    ) {}

    ngOnInit(): void {
        this.buildSessionInfo()
        this.buildLastMetInfo()
        this.buildProfileLink()
        this.buildTimeAndZoneInfo()
        this.determineGlobeIconToUser()
    }

    buildSessionInfo() {
        if (!this.totalSessions) {
            this.sessionsInfo = NEW_USER
        } else if (this.totalSessions === 1) {
            this.sessionsInfo = '1 session'
        } else {
            this.sessionsInfo = `${this.totalSessions} sessions`
        }
        if (this.sharedSessions && this.sharedSessions > 0) {
            this.sessionsInfo = `${this.sessionsInfo} · ${this.sharedSessions} together`
        }
    }

    buildLastMetInfo() {
        if (this.lastSessionTogether) {
            this.lastMet = this.dateFormat.generateLastMetString(this.lastSessionTogether)
        }
    }

    buildProfileLink() {
        if (this.profileUrl) {
            this.profileLink = `/user/${this.profileUrl}`
        }
    }

    buildTimeAndZoneInfo() {
        if (this.timezone) {
            this.timezoneAndTime = `${this.timezone} · ${this.dateFormat.formatNow('h:mma')}`
        }
        this.trackTime()
    }

    private trackTime() {
        let now = new Date()
        let delay = ONE_MINUTE - now.getSeconds() * 1000

        this.timeoutId = setTimeout(() => {
            if (this.timezone) {
                this.buildTimeAndZoneInfo()
            }
        }, delay)
    }

    private determineGlobeIconToUser() {
        // Show the cummonity globe if now user to show
        if (this.isCommunityMember) {
            this.profileImage = null
            this.faGlobe = this.timeUtilitiesService.determineGlobeIconFromTimezone(
                this.currentUserTimezone,
            )
        }
    }

    trackUserProfileSelected() {
        this.analyticsService.logClickedEvent('User', 'Timeslot Partners: List Item')
    }

    ngOnDestroy() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }
}
