import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserDateFormattingService } from '@app/core/_services/user-date-formatting.service'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min'

interface SessionConflictList {
    dateLabel: string
    timeLabels: string[]
}

@Component({
    selector: 'app-session-conflict-with-cancel',
    templateUrl: './session-conflict-with-cancel.component.html',
    styleUrls: ['./session-conflict-with-cancel.component.scss'],
})
export class SessionConflictWithCancelComponent implements OnInit {
    public conflictList: SessionConflictList[] = []
    faTimes = faTimes

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private analyticsService: AnalyticsService,
        private dialog: MatDialogRef<SessionConflictWithCancelComponent>,
        private dateFormat: UserDateFormattingService,
    ) {}

    ngOnInit(): void {
        let conflicts: number[] = this.data.conflicts
        conflicts.sort()
        for (let sessionTime of this.data.conflicts) {
            const dateLabel = this.dateFormat.format(sessionTime, 'dddd, MMMM D')
            const timeLabel = this.dateFormat.format(sessionTime, 'h:mma')

            if (
                this.conflictList.length === 0 ||
                this.conflictList[this.conflictList.length - 1].dateLabel !== dateLabel
            ) {
                this.conflictList.push({
                    dateLabel: dateLabel,
                    timeLabels: [timeLabel],
                })
            } else {
                this.conflictList[this.conflictList.length - 1].timeLabels.push(timeLabel)
            }
        }
        this.analyticsService.logViewedModalEvent('Scheduling Conflict')
    }

    closeDialog() {
        this.dialog.close({ action: 'noAction' })
    }

    cancelAndBook() {
        this.dialog.close({ action: 'cancelAndBook' })
    }
}
