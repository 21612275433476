import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject, Observable, lastValueFrom, ReplaySubject } from 'rxjs'
import { ApiService } from '@app/core/_services/api.service'
import { map } from 'rxjs/operators'

import {
    BookSessionPreferences,
    ConfirmedSessionsResponse,
    Container,
    GroupInfo,
    PartnerSessionPreferences,
    SessionActivityType,
    SessionInfoResponse,
} from '@app/core/_models'
import { BookLimitCheckService } from '@app/core/_services/book-limit-check.service'
import { FIFTEEN_MINS_IN_MSECS, UtilsService } from '@app/core/_services/utils.service'
import { SelectedSessionService } from '@app/core/_services/selected-sessions.service'
import { environment } from '@env/environment'
import { HttpParams } from '@angular/common/http'
import { AutoCancelStatusService } from './auto-cancel-status.service'
import {
    SESSION_STATE_INITIAL,
    SESSION_STATE_LATE_NO_SHOW,
    SessionStateService,
} from './session-state.service'

@Injectable({
    providedIn: 'root',
})
export class ConfirmedSessionsService {
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    private confirmedSessions: Container<ConfirmedSessionsResponse> =
        new Container<ConfirmedSessionsResponse>()
    private previousSessionsTimes = new Array<number>()

    public completedSessionCountSubject: ReplaySubject<number> = new ReplaySubject<number>(1)
    public confirmedSessionsSubject = new BehaviorSubject<Container<ConfirmedSessionsResponse>>(
        this.confirmedSessions as Container<ConfirmedSessionsResponse>,
    )

    public lateSessionCanceled = new Subject<boolean>()
    public forceRematchNeeded = new Subject<ConfirmedSessionsResponse>()

    // keep track of the session time we last emitted a force rematch needed event for
    private forceRematchNeededLastEmitted: number = null

    constructor(
        private apiService: ApiService,
        private bookLimitCheckService: BookLimitCheckService,
        private utilsService: UtilsService,
        private selectedSessionService: SelectedSessionService,
        private autoCancelStatusService: AutoCancelStatusService,
        private sessionStateService: SessionStateService,
    ) {}

    getSessionInfoByTime(
        sessionTime: number,
        meetingRoom: string,
    ): Observable<SessionInfoResponse> {
        let params = new HttpParams()

        if (meetingRoom) {
            params = params.append('room', meetingRoom)
        }

        return this.apiService
            .get(environment.api_url + 'session/' + sessionTime.toString() + '/time', params)
            .pipe(
                map((response) => {
                    let sessionInfo = new SessionInfoResponse()

                    if (response && response.hasOwnProperty('partnerInfo')) {
                        sessionInfo.partnerInfo = response['partnerInfo']
                    }
                    if (response && response.hasOwnProperty('sessionInfo')) {
                        sessionInfo.sessionInfo = response['sessionInfo']
                    }
                    return sessionInfo
                }),
            )
    }

    getConfirmedSessions(suppressOnStateInit: boolean = false) {
        return this.apiService.get(environment.api_url + 'session/').pipe(
            map((response) => {
                this.completedSessionCountSubject.next(response.compSessions)

                let backupList = []
                let suppressUpdates: boolean = false
                this.confirmedSessions.clear()

                if (response !== null) {
                    let receivedConfirmedSessions: ConfirmedSessionsResponse[] = response.sessions
                    let length: number = receivedConfirmedSessions.length

                    if (suppressOnStateInit) {
                        for (let session of receivedConfirmedSessions) {
                            if (session.state === SESSION_STATE_INITIAL) {
                                suppressUpdates = true
                            }
                        }
                    }

                    for (var i = 0; i < length; i++) {
                        const session = receivedConfirmedSessions[i]
                        if (session.state === SESSION_STATE_LATE_NO_SHOW) {
                            // TODO: Replace this implementation with something more complete
                            // when we decide to cover all use cases for late/canceled sessions
                            // Only session_times that are not late/canceled are included in previousSEssionsTimes
                            // This check will tell us if we need to display to the user that their session was canceled
                            let currentTime = this.utilsService.getCurrentTimestamp()
                            if (
                                this.previousSessionsTimes.indexOf(session.sessionTime) !== -1 &&
                                !this.utilsService.hasSessionEnded(
                                    session.sessionTime,
                                    session.duration,
                                    currentTime,
                                ) &&
                                this.utilsService.hasSessionTimePassed(
                                    session.sessionTime,
                                    currentTime,
                                )
                            ) {
                                this.previousSessionsTimes.splice(
                                    this.previousSessionsTimes.indexOf(session.sessionTime),
                                )
                                this.lateSessionCanceled.next(true)
                            }
                        } else {
                            // "force rematch" means the session has started but there is no partner
                            // (state is either pending_partner or lost_partner).
                            if (
                                this.sessionStateService.requiresForceRematch(
                                    session.state,
                                    session.sessionTime,
                                )
                            ) {
                                if (this.forceRematchNeededLastEmitted !== session.sessionTime) {
                                    // only emit here if we haven't already emitted for this timeslot
                                    this.forceRematchNeededLastEmitted = session.sessionTime
                                    this.forceRematchNeeded.next(session)
                                }
                            }

                            backupList.push(session.sessionTime)
                            this.confirmedSessions.insert(session)

                            if (!suppressUpdates) {
                                this.selectedSessionService.deleteSelectedSession(
                                    session.sessionTime,
                                )
                            }
                        }
                    }
                }
                this.previousSessionsTimes.length = 0
                this.previousSessionsTimes.push.apply(this.previousSessionsTimes, backupList)

                if (!suppressUpdates) {
                    this.confirmedSessionsSubject.next(this.confirmedSessions)
                    this.bookLimitCheckService.updateLimitInfo(
                        response.bookLimit,
                        response.attRate,
                        response.compSessions,
                    )
                    this.bookLimitCheckService.updateLimitsConfirmed(this.numUpcomingSessions())
                    this.autoCancelStatusService.setAutoCancelStatus(
                        response.autoCancel.enabled,
                        response.autoCancel.trigger,
                    )
                }

                return response.sessions
            }),
        )
    }

    updateTitle(sessionTime: number, title: string) {
        let newSession = this.confirmedSessions.getBySessionTime(sessionTime)
        newSession.title = title
        this.confirmedSessions.insert(newSession)
        this.confirmedSessionsSubject.next(this.confirmedSessions)
    }

    updatePreferences(sessionTime: number, preferences: any) {
        let newSession = this.confirmedSessions.getBySessionTime(sessionTime)
        newSession.preferences = preferences
        this.confirmedSessions.insert(newSession)
        this.confirmedSessionsSubject.next(this.confirmedSessions)
    }

    updateSavedStatus(userId: string, saved: boolean) {
        for (let i = 0; i < this.confirmedSessions.length(); ++i) {
            let session = this.confirmedSessions.getByIndex(i)

            if (session.partnerId === userId) {
                session.saved = saved
            }
        }
        this.confirmedSessionsSubject.next(this.confirmedSessions)
    }

    addNewlyBookedSession(
        sessionTime: number,
        partnerDisplayName: string,
        meetingRoom: string,
        partnerProfileUrl: string,
        partnerId: string,
        title: string,
        partnerPhoto: string,
        group: GroupInfo[],
        state: number,
        duration: number,
        saved: boolean,
        sharedAvailability: boolean,
        preferences: BookSessionPreferences,
        activityType: SessionActivityType,
        partnerPreferences: PartnerSessionPreferences,
    ) {
        let temp: ConfirmedSessionsResponse = new ConfirmedSessionsResponse()
        temp.partnerDisplayName = partnerDisplayName
        temp.sessionTime = sessionTime
        temp.meetingRoom = meetingRoom
        temp.partnerProfileUrl = partnerProfileUrl
        temp.partnerId = partnerId
        temp.title = title
        temp.partnerPhoto = partnerPhoto
        temp.group = group
        temp.state = state
        temp.duration = duration
        temp.saved = saved
        temp.sharedAvailability = sharedAvailability
        temp.preferences = preferences
        temp.activityType = activityType
        temp.partnerPreferences = partnerPreferences

        this.confirmedSessions.insert(temp)
        this.selectedSessionService.deleteSelectedSession(temp.sessionTime)
        this.confirmedSessionsSubject.next(this.confirmedSessions)
        this.bookLimitCheckService.updateLimitsConfirmed(this.numUpcomingSessions())

        if (this.sessionStateService.requiresForceRematch(state, sessionTime)) {
            // we don't check if we've already emitted for this timeslot because we want to emit
            // for all new session bookings, even if they re-book for the same slot
            this.forceRematchNeededLastEmitted = sessionTime
            this.forceRematchNeeded.next(temp)
        }
    }

    removeSession(session_time: number) {
        this.confirmedSessions.remove(new ConfirmedSessionsResponse('', session_time, '', ''))
        this.confirmedSessionsSubject.next(this.confirmedSessions)
        this.bookLimitCheckService.updateLimitsConfirmed(this.numUpcomingSessions())
    }

    clearConfirmedSessions(): void {
        this.previousSessionsTimes.length = 0
        this.confirmedSessions.clear()
        this.confirmedSessionsSubject.next(this.confirmedSessions)
        this.completedSessionCountSubject.next(0)
        this.bookLimitCheckService.updateLimitsConfirmed(this.numUpcomingSessions())
    }

    private numUpcomingSessions(): number {
        let num: number = 0
        let now = this.utilsService.getCurrentTimestamp()

        for (var i = 0; i < this.confirmedSessions.length(); i++) {
            if (
                !this.utilsService.hasSessionTimePassed(
                    this.confirmedSessions.getByIndex(i).sessionTime,
                    now,
                )
            ) {
                num++
            }
        }
        return num
    }

    getUnavailableTimeSlots() {
        // HACK: Eventually we will update
        // API to retrieve blocked slots from server
        return [
            1696215600000,
            1696215600000 + FIFTEEN_MINS_IN_MSECS,
            1696215600000 + FIFTEEN_MINS_IN_MSECS + FIFTEEN_MINS_IN_MSECS,
            1696215600000 + FIFTEEN_MINS_IN_MSECS + FIFTEEN_MINS_IN_MSECS + FIFTEEN_MINS_IN_MSECS,
        ]
        // return [1572055200000, 1572056100000, 1572057000000, 1572057900000, 1572058800000, 1572059700000, 1572060600000, 1572061500000]
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
