<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="closeDialog()"></fa-icon>

    <div class="std-modal-header">
        <div class="std-modal-title wrap-word-break">Reset invite link?</div>
    </div>

    <div class="std-modal-body">
        <div class="std-modal-body-text">
            <p>Anyone you shared your current invite link with will lose access to it.</p>
            <p>Once changed, you cannot go back to your old invite link.</p></div
        >

        <div class="std-modal-action-container" style="width: 260px">
            <app-spinner-button
                [label]="'Reset invite link'"
                classes="f-btn f-btn--warn f-btn-250"
                (clicked)="resetLink()">
            </app-spinner-button>
            <button class="f-link fm-mt-15px" (click)="closeDialog()">Cancel</button>
        </div>
    </div>
</div>
