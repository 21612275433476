@if (showCompleteUnfinishedStepsModal) {
    <fm-complete-unfinished-steps-modal
        (nextStepButtonClick)="
            completeUnfinishedStepsModalAcknowledged()
        "></fm-complete-unfinished-steps-modal>
} @else {
    <fm-welcome-checklist-header
        (closeIconClicked)="onCloseIconClick()"
        (stepClicked)="onHeaderStepClick($event)"
        [steps]="steps"
        [activeStep]="activeStep"
        class="shadow-fm-small-lower z-10"></fm-welcome-checklist-header>

    @switch (openStep.name) {
        @case ('welcome') {
            <fm-welcome-to-focusmate-modal
                (nextStepButtonClick)="onStepCompleted('welcome')"></fm-welcome-to-focusmate-modal>
        }

        @case ('howItWorks') {
            <fm-learn-how-it-works-modal
                (nextStepButtonClick)="onStepCompleted('howItWorks')"></fm-learn-how-it-works-modal>
        }

        @case ('reviewCommunityGuidelines') {
            <fm-review-community-guidelines-modal
                [state]="{ complete: openStep.complete, source, steps }"
                (nextStepButtonClick)="
                    onStepCompleted('reviewCommunityGuidelines')
                "></fm-review-community-guidelines-modal>
        }

        @case ('bookFirstSession') {
            <fm-book-first-session-modal
                (bookingSuccess)="
                    onStepCompleted('bookFirstSession')
                "></fm-book-first-session-modal>
        }

        @case ('completeFirstSession') {
            <fm-complete-first-session-modal></fm-complete-first-session-modal>
        }

        @case ('acknowledgeCompletion') {
            <fm-welcome-checklist-confetti-modal
                (doneButtonClick)="
                    onStepCompleted('acknowledgeCompletion')
                "></fm-welcome-checklist-confetti-modal>
        }
    }
}
