<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title">Auto Rematch</div>
    </div>

    <div class="std-modal-body w-full">
        <div class="f-form-field w-full field-spacer">
            <mat-radio-group
                class="f-radio-group"
                [(ngModel)]="preferenceSelected"
                (change)="radioButtonChanged()">
                <mat-radio-button disableRipple class="f-radio-button" [value]="true">
                    Auto rematch if my partner is late
                </mat-radio-button>
                @if (preferenceSelected) {
                    <div class="f-hint w-full mb-[15px]">
                        Automatically request a new partner if my original partner is late after I
                        join a Focusmate session.
                    </div>
                }

                <mat-radio-button disableRipple class="f-radio-button" [value]="false">
                    Do not auto rematch if my partner is late
                </mat-radio-button>
                @if (!preferenceSelected) {
                    <div class="f-hint w-full mb-[15px]">
                        Do not automatically request a new partner if my original partner is late
                        after I join a Focusmate session.
                    </div>
                }
            </mat-radio-group>
        </div>

        <div class="std-modal-action-container">
            <app-spinner-button
                class="action"
                [disable]="disableSaveButton"
                [label]="'Save preference'"
                classes="f-btn f-btn--primary f-btn-250"
                (clicked)="submitForm()">
            </app-spinner-button>
        </div>
    </div>
</div>
