<button
    *ngIf="useIcon"
    mat-icon-button
    disableRipple="true"
    [matMenuTriggerFor]="menu"
    class="fm-atc-menu-btn"
    matTooltip="Add to Calendar"
    matTooltipPosition="above"
    matTooltipShowDelay="500"
    matTooltipClass="f-tooltip">
    <div class="fm-atc-menu-icon">
        <mat-icon [ngClass]="colorClass">today</mat-icon>
    </div>
</button>

<div [matMenuTriggerFor]="menu">Add to calendar</div>

<mat-menu [overlapTrigger]="false" class="fm-main-menu" #menu="matMenu">
    <a class="fm-cal-link" (click)="logATC('Google')" href="{{ googleUrl }}" target="_blank"
        ><button mat-menu-item>Google</button></a
    >
    <a class="fm-cal-link" (click)="logATC('Ical')" href="{{ icalUrl }}" target="_blank"
        ><button mat-menu-item>iCalendar</button></a
    >
    <a class="fm-cal-link" (click)="logATC('Outlook')" href="{{ outlookUrl }}" target="_blank"
        ><button mat-menu-item>Outlook</button></a
    >
    <a
        class="fm-cal-link"
        (click)="logATC('OutlookOnline')"
        href="{{ outlookOnlineUrl }}"
        target="_blank"
        ><button mat-menu-item>Outlook Online</button></a
    >
</mat-menu>
