<div class="diag-container">
    <mat-icon (click)="dialog.close()" class="diag-close icon-size-16">clear</mat-icon>
    <div class="diag-header">
        <div class="diag-header-text fm-mt-50px fm-mb-30px" style="color: #2f3150">
            We needed to change your partner
        </div>
    </div>
    <div class="diag-body">
        <div class="diag-sub-header-text text-left fm-mt-10px fm-mb-10px" style="color: #2f3150">
            <p
                >Your original partner couldn't make it. The good news is we've got a new partner
                ready for you! When you’re ready, we’ll move you to your new session.</p
            >
        </div>

        <div class="customHrDiag w-full" style="color: #2f3150"></div>
        <div class="diag-action fm-mt-20px fm-mb-50px w-full">
            <button style="min-width: 150px" class="fm-btn fm-btn--md" (click)="dialog.close()"
                >Join session</button
            >
        </div>
    </div>
</div>
