<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close(false)">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title">Gender Preference</div>
    </div>

    <div class="std-modal-body w-full">
        <div class="std-modal-body-text text-left w-full"
            >Your gender preference was turned off because you updated your gender.<br /><br />You’ll
            match with everyone.</div
        >

        <ng-template #validGenderSelected>
            <div class="f-sep w-full fm-mt-25px fm-mb-25px"></div>
            <div class="std-modal-body-text text-left w-full">Want to turn it back on?</div>

            <div class="std-modal-action-container-row">
                <div class="std-action"
                    ><button class="f-btn f-btn--secondary w-full" (click)="dialog.close(false)"
                        >Leave off</button
                    ></div
                >
                <div class="std-action"
                    ><button class="f-btn f-btn--primary w-full" (click)="dialog.close(true)"
                        >Turn on</button
                    ></div
                >
            </div>
        </ng-template>

        <div *ngIf="!canUpdatePreferences; else validGenderSelected" class="w-full">
            <div class="std-modal-action-container">
                <button class="f-btn f-btn--primary f-btn-250" (click)="dialog.close()"
                    >Got it</button
                >
            </div>
        </div>
        <app-gender-inclusion-blog class="w-full fm-mt-15px"></app-gender-inclusion-blog>
    </div>
</div>
