<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title">Microphone</div>
    </div>

    <div class="std-modal-body w-full">
        <div class="f-form-field w-full field-spacer">
            <mat-radio-group
                class="f-radio-group"
                [(ngModel)]="preferenceSelected"
                (change)="radioButtonChanged()">
                <mat-radio-button
                    disableRipple
                    class="f-radio-button"
                    *ngFor="let item of radioButtonInfo"
                    [value]="item.value">
                    {{ item.label }}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="f-hint fm-mt-20px w-full"
            >You will be {{ this.preferenceSelected ? 'muted' : 'unmuted' }} the first time you join
            each session. You can still mute and unmute during the session as normal.
        </div>

        <div class="std-modal-action-container">
            <app-spinner-button
                class="action"
                [disable]="disableSaveButton"
                [label]="'Save preference'"
                classes="f-btn f-btn--primary f-btn-250"
                (clicked)="submitForm()">
            </app-spinner-button>
        </div>
    </div>
</div>
