import { Injectable } from '@angular/core'
import { ComponentType } from '@angular/cdk/portal'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { EmailVerifyComponent } from '@app/shared/components/email-verify/email-verify.component'
import { SuspendedComponent } from '@app/shared-dialogs/components/suspended/suspended.component'
import { AnalyticsService, BookingSource } from '@app/core/_services/analytics.service'
import { ConfirmTransactionComponent } from '@app/shared-dialogs/components/confirm-transaction/confirm-transaction.component'
import { ReportBadBehaviorComponent } from '@app/user-actions/components/report/report-bad-behavior/report-bad-behavior.component'
import { take } from 'rxjs/operators'
import { PlanLimitReachedComponent } from '@app/shared-dialogs/components/plan-limit-reached/plan-limit-reached.component'
import { GroupInviteComponent } from '@app/shared-dialogs/components/group-invite/group-invite.component'
import { OneLineNotificationComponent } from '@app/shared-dialogs/components/one-line-notification/one-line-notification.component'
import { SafariWarningComponent } from '@app/shared-dialogs/components/safari-warning/safari-warning.component'
import { PartnerIsNewUserComponent } from '@app/shared-dialogs/components/partner-is-new-user/partner-is-new-user.component'
import { NewPartnerDialogComponent } from '@app/shared-dialogs/components/new-partner-dialog/new-partner-dialog.component'
import { LostPartnerBeforeSessionDialogComponent } from '@app/shared-dialogs/components/lost-partnerbefore-session-dialog/lost-partner-before-session-dialog.component'
import { UserSegmentationSurveyComponent } from '@app/shared-dialogs/components/user-segmentation-survey/user-segmentation-survey.component'
import { PartnerIsLateComponent } from '@app/shared-dialogs/components/rematch/partner-is-late/partner-is-late.component'
import { FindingPartnerComponent } from '@app/shared-dialogs/components/rematch/finding-partner/finding-partner.component'
import { FindingPartnerFailedComponent } from '@app/shared-dialogs/components/rematch/finding-partner-failed/finding-partner-failed.component'
import { UserLatePartnerSearchingComponent } from '@app/shared-dialogs/components/rematch/user-late-partner-searching/user-late-partner-searching.component'
import { LateSessionCanceledComponent } from '@app/shared-dialogs/components/rematch/late-session-canceled/late-session-canceled.component'
import { FoundNewPartnerComponent } from '@app/shared-dialogs/components/rematch/found-new-partner/found-new-partner.component'
import { ConfirmSystemStatusComponent } from '@app/shared-dialogs/components/confirm-system-status/confirm-system-status.component'
import { InviterMeetingNoLongerAvailableComponent } from '@app/shared-dialogs/components/inviter-meeting-no-longer-available/inviter-meeting-no-longer-available.component'
import { SessionConflictComponent } from '@app/shared-dialogs/components/session-conflict/session-conflict.component'
import { EditNameModalComponent } from '@app/my-profile/components/edit-name-modal/edit-name-modal.component'
import { EditProfileUrlModalComponent } from '@app/my-profile/components/edit-profile-url-modal/edit-profile-url-modal.component'
import { EditTimezoneModalComponent } from '@app/my-profile/components/edit-timezone-modal/edit-timezone-modal.component'
import { EditGenderModalComponent } from '@app/my-profile/components/edit-gender-modal/edit-gender-modal.component'
import { GenderPreferenceManModalComponent } from '@app/my-profile/components/gender-preference-man-modal/gender-preference-man-modal.component'
import { GenderPreferenceWomanModalComponent } from '@app/my-profile/components/gender-preference-woman-modal/gender-preference-woman-modal.component'
import { GenderPreferenceNoncisModalComponent } from '@app/my-profile/components/gender-preference-noncis-modal/gender-preference-noncis-modal.component'
import { GenderPreferenceUpdateGenderModalComponent } from '@app/my-profile/components/gender-preference-update-gender-modal/gender-preference-update-gender-modal.component'
import { ResetGenderPreferenceModalComponent } from '@app/my-profile/components/reset-gender-preference-modal/reset-gender-preference-modal.component'
import { UpdateEmailAddressComponent } from '@app/admin/components/dialogs/update-email-address/update-email-address.component'
import { ConfirmUpdateEmailAddressComponent } from '@app/admin/components/dialogs/confirm-update-email-address/confirm-update-email-address.component'
import { ConfirmRemoveIntegrationComponent } from '@app/my-profile/components/confirm-remove-integration/confirm-remove-integration.component'
import { ApplicationIntegration } from '../_models/integrations'
import { FmDatetimePickerComponent } from '@app/shared/components/fm-datetime-picker/fm-datetime-picker.component'
import { ApiKeyModalComponent } from '@app/my-profile/components/api-key-modal/api-key-modal.component'
import { ReportBadBehaviorConfirmComponent } from '@app/user-actions/components/report/report-bad-behavior-confirm/report-bad-behavior-confirm.component'
import { ReportProfileComponent } from '@app/user-actions/components/report/report-profile/report-profile.component'
import { SnoozeUserConfirmComponent } from '@app/user-actions/components/snooze/snooze-user-confirm/snooze-user-confirm.component'
import { UnsnoozeUserComponent } from '@app/user-actions/components/snooze/unsnooze-user/unsnooze-user.component'
import { SnoozeUserComponent } from '@app/user-actions/components/snooze/snooze-user/snooze-user.component'
import { BlockUserComponent } from '@app/user-actions/components/block/block-user/block-user.component'
import { BlockUserConfirmationComponent } from '@app/user-actions/components/block/block-user-conformation/block-user-conformation.component'
import { PeopleListInfoComponent } from '@app/people/components/people-list-info/people-list-info.component'
import { ShareAvailabilitySettingComponent } from '@app/my-profile/components/share-availability-setting/share-availability-setting.component'
import { SessionConflictWithCancelComponent } from '@app/shared-dialogs/components/session-conflict-with-cancel/session-conflict-with-cancel.component'
import { MuteByDefaultSettingComponent } from '@app/my-profile/components/mute-by-default-setting/mute-by-default-setting.component'
import { TimeslotPartnersComponent } from '@app/calendar/components/timeslot-partners/timeslot-partners.component'
import { BookErrorSessionAlreadyStartedComponent } from '@app/shared-dialogs/components/book-error-session-already-started/book-error-session-already-started.component'
import { PlayToneFailedComponent } from '@app/shared-dialogs/components/play-tone-failed/play-tone-failed.component'
import { ConfirmResetModalComponent } from '@app/user-referral/components/confirm-reset-modal/confirm-reset-modal.component'
import { TermsComponent } from '@app/shared/components/terms/terms.component'
import { YearInReviewComponent } from '@app/shared-dialogs/components/year-in-review/year-in-review.component'
import { SessionPreferencesInfoComponent } from '@app/shared-dialogs/components/session-preferences-info/session-preferences-info.component'
import { ForceRematchComponent } from '@app/shared-dialogs/components/rematch/force-rematch/force-rematch.component'

import { VideoFallbackNoticeComponent } from '@app/shared-dialogs/components/video-fallback-notice/video-fallback-notice.component'
import { VideoNotAvailableNoticeComponent } from '@app/shared-dialogs/components/video-not-available-notice/video-not-available-notice.component'
import { VideoInitializationFailedNoticeComponent } from '@app/launch/components/video-initialization-failed-notice/video-initialization-failed-notice.component'
import { MissingPartnerModalComponent } from '@app/shared-dialogs/components/rematch/missing-partner-modal/missing-partner-modal.component'
import { CreateSessionModalComponent } from '@app/shared/components/create-session/create-session-modal/create-session-modal.component'
import { WelcomeChecklistModalComponent } from '@app/welcome-checklist/welcome-checklist-modal/welcome-checklist-modal.component'
import {
    ReviewCommunityGuidelinesModalComponent,
    ReviewCommunityGuidelinesModalData,
} from '@app/welcome-checklist/review-community-guidelines-modal/review-community-guidelines-modal.component'
import { EditQuietModeAllowedModalComponent } from '@app/my-profile/components/edit-quiet-mode-allowed-modal/edit-quiet-mode-allowed-modal.component'
import { AvailabilityListQuietModeInfoComponent } from '@app/shared-dialogs/components/availability-list-quiet-mode-info/availability-list-quiet-mode-info.component'
import { PartnerInQuietModeModalComponent } from '@app/shared-dialogs/components/partner-in-quiet-mode-modal/partner-in-quiet-mode-modal.component'
import { DomainUpdatedNotificationComponent } from '@app/shared-dialogs/components/domain-updated-notification/domain-updated-notification.component'
import { EditGoogleCalendarIntegrationModalComponent } from '@app/my-profile/components/edit-google-calendar-integration-modal/edit-google-calendar-integration-modal.component'
import { ConfirmDisconnectGoogleAccountComponent } from '@app/my-profile/components/confirm-disconnect-google-account/confirm-disconnect-google-account.component'
import { ConfirmConnectGoogleAccountComponent } from '@app/my-profile/components/confirm-connect-google-account/confirm-connect-google-account.component'
import { JoinedTooEarlyDialogComponent } from '@app/shared-dialogs/components/joined-too-early-dialog/joined-too-early-dialog.component'
import { JoinedTooLateDialogComponent } from '@app/shared-dialogs/components/joined-too-late-dialog/joined-too-late-dialog.component'
import { SwUpdateNotificationComponent } from '@app/shared-dialogs/sw-update-notification/sw-update-notification.component'
import { EditTimeFormatModalComponent } from '@app/my-profile/components/edit-time-format-modal/edit-time-format-modal.component'
import { TimeSyncInfoComponent } from '@app/shared-dialogs/components/time-sync-info/time-sync-info.component'
import { GoogleOauthCallbackErrorModalComponent } from '@app/shared-dialogs/google-oauth-callback-error-modal/google-oauth-callback-error-modal.component'
import { ReferralPromptModalComponent } from '@app/shared-dialogs/components/referral-prompt-modal/referral-prompt-modal.component'
import { EditAutoRematchEnabledModalComponent } from '@app/my-profile/components/edit-auto-rematch-enabled-modal/edit-auto-rematch-enabled-modal.component'

let dialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
    autoFocus: false,
    panelClass: ['std-modal', 'std-modal-relative'],
    maxWidth: '100%',
    disableClose: true,
}

let dialogConfigTransparent: MatDialogConfig = {
    closeOnNavigation: true,
    autoFocus: false,
    panelClass: ['std-modal', 'std-modal-transparent'],
    maxWidth: '100%',
    disableClose: true,
}

let dialogConfigWide: MatDialogConfig = {
    closeOnNavigation: true,
    autoFocus: false,
    panelClass: ['std-modal', 'std-modal-wide'],
    maxWidth: '100%',
    disableClose: true,
}

let dialogConfigTall: MatDialogConfig = {
    closeOnNavigation: true,
    autoFocus: false,
    panelClass: ['std-modal', 'std-modal-tall'],
    maxWidth: '100%',
    disableClose: true,
}

class ModalServiceItem {
    constructor(
        public componentRef: ComponentType<any>,
        public config: MatDialogConfig = dialogConfig,
        public data: any = {},
        public immediateDisplay: boolean = false,
        public confirmation: boolean = false,
        public afterClosed?: (res: any) => void,
    ) {}
}

@Injectable()
export class DialogService {
    private modalQueue: Array<ModalServiceItem> = []
    private immediateModalQueue: Array<ModalServiceItem> = []
    private processingQ: boolean = false

    private confirmTransactionDialog: MatDialogRef<ConfirmTransactionComponent>
    private playToneFailedDialog: MatDialogRef<PlayToneFailedComponent>

    constructor(
        private analyticsService: AnalyticsService,
        public dialog: MatDialog,
    ) {}

    createModal(modalServiceItem: ModalServiceItem) {
        if (modalServiceItem.immediateDisplay === true) {
            this.immediateModalQueue.push(modalServiceItem)
        } else {
            this.modalQueue.push(modalServiceItem)
        }

        if (!this.processingQ) {
            this.processingQ = true
            this.processModalQ()
        }
    }

    processModalQ() {
        if (this.immediateModalQueue.length > 0) {
            let item = this.immediateModalQueue[0]

            let config: MatDialogConfig = item.config

            config['data'] = item.data

            this.dialog
                .open(item.componentRef, config)
                .afterClosed()
                .pipe(take(1))
                .subscribe((res) => {
                    if (item.afterClosed) {
                        item.afterClosed(res)
                    }
                    this.immediateModalQueue.shift()
                    this.processModalQ()
                })
        } else if (this.modalQueue.length > 0) {
            let item = this.modalQueue[0]

            let config: MatDialogConfig = item.config

            config['data'] = item.data
            this.dialog
                .open(item.componentRef, config)
                .afterClosed()
                .pipe(take(1))
                .subscribe((res) => {
                    let confirm = false
                    let message = ''

                    if (typeof res === 'object' && res !== null) {
                        if (res.hasOwnProperty('value')) {
                            confirm = res['value']
                        }
                        if (res.hasOwnProperty('message')) {
                            message = res['message']
                        }
                    }

                    if (confirm === true && item.confirmation === true && message !== '') {
                        this.showQueuedConfirmTransaction(message)
                            .afterClosed()
                            .pipe(take(1))
                            .subscribe(() => {
                                if (item.afterClosed) {
                                    item.afterClosed(res)
                                }
                                this.modalQueue.shift()
                                this.processModalQ()
                            })
                    } else {
                        if (item.afterClosed) {
                            item.afterClosed(res)
                        }
                        this.modalQueue.shift()
                        this.processModalQ()
                    }
                })
        } else {
            this.processingQ = false
        }
    }

    openCreateSessionModal(bookingSource: BookingSource) {
        const modalServiceItem: ModalServiceItem = new ModalServiceItem(
            CreateSessionModalComponent,
            dialogConfig,
        )

        modalServiceItem.data = { bookingSource }
        modalServiceItem.confirmation = true

        this.createModal(modalServiceItem)
    }

    openFeatureLaunchModal(componentRef: ComponentType<any>) {
        const modalServiceItem: ModalServiceItem = new ModalServiceItem(
            componentRef,
            dialogConfigTall,
        )
        modalServiceItem.data = {}
        modalServiceItem.confirmation = false
        this.createModal(modalServiceItem)
    }

    openYearInReviewModal() {
        const modalServiceItem: ModalServiceItem = new ModalServiceItem(YearInReviewComponent, {
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal', 'yearly-review-panel'],
            disableClose: true,
        })
        modalServiceItem.confirmation = false
        this.createModal(modalServiceItem)
    }

    openVideoExperimentNotification(componentRef: ComponentType<any>) {
        let modalServiceItem: ModalServiceItem = new ModalServiceItem(componentRef)

        modalServiceItem.config = {
            data: {},
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['diag-panel'],
            width: '500px',
            maxWidth: '100%',
            minWidth: 300,
            disableClose: true,
        }

        this.createModal(modalServiceItem)
    }

    openSafariWarning(roomId: string) {
        let modalServiceItem: ModalServiceItem = new ModalServiceItem(SafariWarningComponent)

        modalServiceItem.config = {
            data: {},
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['diag-panel'],
            width: '500px',
            maxWidth: '100%',
            minWidth: 300,
            disableClose: true,
        }

        modalServiceItem.data = {
            roomId,
        }

        this.createModal(modalServiceItem)
    }

    openGroupInviteNotification(group, userPhoto) {
        let modalServiceItem: ModalServiceItem = new ModalServiceItem(GroupInviteComponent)

        modalServiceItem.config = {
            data: {},
            hasBackdrop: true,
            panelClass: ['fm-dialog', 'fm-centered-dialog'],
            backdropClass: 'fm-overlay-class',
            disableClose: true,
            width: '100%',
            maxWidth: '430px',
        }

        modalServiceItem.data = {
            group,
            userPhoto,
        }

        modalServiceItem.confirmation = true

        this.createModal(modalServiceItem)
    }

    openPlanLimitReachedDialog() {
        let modalServiceItem: ModalServiceItem = new ModalServiceItem(PlanLimitReachedComponent)

        modalServiceItem.config = {
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        modalServiceItem.data = {}

        this.createModal(modalServiceItem)
    }

    openEmailVerificationDialog(modalName: string) {
        let config: MatDialogConfig = {
            data: {
                modalName,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['modal-panel'],
            width: '572px',
            maxWidth: '100%',
            minWidth: 300,
            disableClose: true,
        }
        return this.dialog.open(EmailVerifyComponent, config)
    }

    openSuspendedDialog() {
        let config: MatDialogConfig = {
            data: {},
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: [],
            width: '600px',
            maxWidth: '100%',
            minWidth: 300,
            disableClose: true,
        }
        return this.dialog.open(SuspendedComponent, config)
    }

    openSessionInfoCard(
        sessionTime,
        sessionDuration,
        sessionId,
        state,
        componentRef: ComponentType<any>,
    ) {
        let config: MatDialogConfig = {
            data: {
                sessionTime: sessionTime,
                sessionDuration: sessionDuration,
                sessionId: sessionId,
                state,
            },
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['fm-dialog'],
            width: '430px',
            maxWidth: '100%',
            minWidth: 300,
            disableClose: true,
        }

        let dialogRef = this.dialog.open(componentRef, config)

        dialogRef
            .backdropClick()
            .pipe(take(1))
            .subscribe(() => {
                dialogRef.close()
            })

        return dialogRef
    }

    showQueuedConfirmTransaction(
        message: string,
        delay: number = 3000,
    ): MatDialogRef<ConfirmTransactionComponent> {
        let config: MatDialogConfig = {
            data: {
                message: message,
                delay: delay,
            },
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['fm-confirm-dialog'],
            maxWidth: '100%',
            disableClose: false,
            direction: 'ltr',
            hasBackdrop: false,
            position: { bottom: '100px', right: '20px' },
        }

        return this.dialog.open(ConfirmTransactionComponent, config)
    }

    openConfirmTransaction(message: string, delay: number = 3000): void {
        let config: MatDialogConfig = {
            data: {
                message: message,
                delay: delay,
            },
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['fm-confirm-dialog'],
            maxWidth: '100%',
            disableClose: false,
            direction: 'ltr',
            hasBackdrop: false,
            position: { bottom: '100px', right: '20px' },
        }

        if (this.confirmTransactionDialog) {
            this.confirmTransactionDialog.close()
        }
        this.confirmTransactionDialog = this.dialog.open(ConfirmTransactionComponent, config)
    }

    openSWUpdateNotification(): MatDialogRef<SwUpdateNotificationComponent> {
        let config: MatDialogConfig = {
            data: {},
            closeOnNavigation: false,
            autoFocus: false,
            panelClass: ['sw-update-dialog'],
            maxWidth: '300px',
            disableClose: true,
            direction: 'ltr',
            hasBackdrop: false,
            position: { bottom: '60px', right: '20px' },
        }

        return this.dialog.open(SwUpdateNotificationComponent, config)
    }

    openPlayToneFailed(): void {
        let config: MatDialogConfig = {
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['fm-play-tone-failed-dialog'],
            maxWidth: '100%',
            disableClose: false,
            direction: 'ltr',
            hasBackdrop: false,
            position: { top: '20px' },
        }

        if (this.playToneFailedDialog) {
            this.playToneFailedDialog.close()
        }
        this.playToneFailedDialog = this.dialog.open(PlayToneFailedComponent, config)
    }

    openReportBadBehavior(
        sessionId: string,
        sessionTime: number,
        partnerName: string,
        source: string,
    ) {
        let config: MatDialogConfig = {
            data: {
                sessionId,
                sessionTime,
                partnerName,
                source,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            disableClose: true,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(ReportBadBehaviorComponent, config)
    }

    openReportProfile(
        profileUrl: string,
        photoUrl: string,
        profileDisplayName: string,
        source: string,
    ) {
        let config: MatDialogConfig = {
            data: {
                profileUrl,
                photoUrl,
                profileDisplayName,
                source: source,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            disableClose: true,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(ReportProfileComponent, config)
    }

    openBlockUserDialog(
        partnerId: string,
        partnerName: string,
        partnerSaved: boolean,
        source: string,
    ) {
        let config: MatDialogConfig = {
            data: {
                partnerId,
                partnerName,
                partnerSaved,
                source,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            disableClose: true,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(BlockUserComponent, config)
    }

    openBlockUserConfirmation(partnerName: string) {
        let config: MatDialogConfig = {
            data: {
                partnerName,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            disableClose: true,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(BlockUserConfirmationComponent, config)
    }

    openReportBadBehaviorConfirmation(partnerName: string) {
        let config: MatDialogConfig = {
            data: {
                partnerName,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            disableClose: true,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(ReportBadBehaviorConfirmComponent, config)
    }

    openPartnerIsNewNotification(partnerName: string) {
        let config: MatDialogConfig = {
            data: {
                partnerName,
            },
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['diag-panel'],
            width: '500px',
            maxWidth: '100%',
            minWidth: 300,
            disableClose: true,
        }

        return this.dialog.open(PartnerIsNewUserComponent, config)
    }

    openPartnerInQuietModeNotification(autoTriggered: boolean) {
        let config: MatDialogConfig = {
            data: { autoTriggered },
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['diag-panel'],
            width: '600px',
            maxWidth: '100%',
            minWidth: 300,
            disableClose: true,
        }

        return this.dialog.open(PartnerInQuietModeModalComponent, config)
    }

    openJoinedSessionWithoutPartnerBeforeStartDialog() {
        let config: MatDialogConfig = {
            data: {},
            closeOnNavigation: false,
            autoFocus: false,
            panelClass: ['diag-panel'],
            width: '500px',
            maxWidth: '100%',
            minWidth: 300,
            disableClose: true,
        }

        return this.dialog.open(LostPartnerBeforeSessionDialogComponent, config)
    }

    openNewPartnerNotification(partnerName: string) {
        let config: MatDialogConfig = {
            data: {
                partnerName,
            },
            closeOnNavigation: false,
            autoFocus: false,
            panelClass: ['diag-panel'],
            width: '500px',
            maxWidth: '100%',
            minWidth: 300,
            disableClose: true,
        }

        return this.dialog.open(NewPartnerDialogComponent, config)
    }

    openPartnerIsLateNotification(partnerName: string, countdownTo: number) {
        const isMd = window.matchMedia('(min-width: 600px)').matches
        let position: any = {
            top: '130px',
        }

        if (isMd) {
            position.top = '160px'
            position.left = '40px'
        }

        let config: MatDialogConfig = {
            data: {
                partnerName,
                countdownTo,
            },
            closeOnNavigation: false,
            autoFocus: false,
            panelClass: ['partner-is-late-modal'],
            position: position,
            disableClose: true,
            hasBackdrop: false,
        }

        return this.dialog.open(PartnerIsLateComponent, config)
    }

    openFindingNewPartnerNotification(countdownTo: number) {
        let config: MatDialogConfig = {
            data: {
                countdownTo,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
            maxWidth: '100%',
            disableClose: true,
        }

        return this.dialog.open(FindingPartnerComponent, config)
    }

    openFindNewPartnerFailedNotification() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
            maxWidth: '100%',
            disableClose: true,
        }

        return this.dialog.open(FindingPartnerFailedComponent, config)
    }

    openUserLatePartnerSearchingNotification(countdownTo: number) {
        let config: MatDialogConfig = {
            data: {
                countdownTo,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
            maxWidth: '100%',
            disableClose: true,
        }

        return this.dialog.open(UserLatePartnerSearchingComponent, config)
    }

    openFoundNewPartnerNotification(
        partnerName: string,
        partnerDisplayName: string,
        partnerPhoto: string,
        completedSessions: number,
    ) {
        let config: MatDialogConfig = {
            data: {
                partnerName,
                partnerDisplayName,
                partnerPhoto,
                completedSessions,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
            maxWidth: '100%',
            disableClose: true,
        }

        return this.dialog.open(FoundNewPartnerComponent, config)
    }

    openLateSessionCanceledNotification() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
            maxWidth: '100%',
            disableClose: true,
        }

        return this.dialog.open(LateSessionCanceledComponent, config)
    }

    openMissingPartnerModal(countdownTo: number) {
        let config: MatDialogConfig = {
            data: {
                countdownTo,
            },
            closeOnNavigation: false,
            autoFocus: false,
            panelClass: ['std-modal'],
            maxWidth: '100%',
            disableClose: true,
        }

        return this.dialog.open(MissingPartnerModalComponent, config)
    }

    openForceRematchModal(sessionTime: number, duration: number) {
        let config: MatDialogConfig = {
            data: { sessionTime, duration },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
            maxWidth: '100%',
            disableClose: true,
        }

        return this.dialog.open(ForceRematchComponent, config)
    }

    openSegmentationSurveyModal() {
        let modalServiceItem: ModalServiceItem = new ModalServiceItem(
            UserSegmentationSurveyComponent,
        )

        modalServiceItem.config = {
            hasBackdrop: true,
            closeOnNavigation: false,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        modalServiceItem.data = {}

        this.createModal(modalServiceItem)
    }

    openReferralPromptModal() {
        let modalServiceItem: ModalServiceItem = new ModalServiceItem(ReferralPromptModalComponent)

        modalServiceItem.config = {
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
            disableClose: true,
        }

        modalServiceItem.data = {}

        this.createModal(modalServiceItem)
    }

    openOneLineNotificationModal(
        message: string,
        disableClose: boolean,
        panelClasses: Array<string> = [],
    ) {
        panelClasses.push('diag-panel')

        let config: MatDialogConfig = {
            data: {
                message,
            },
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: panelClasses,
            width: '500px',
            maxWidth: '100%',
            minWidth: 300,
            disableClose: disableClose,
        }

        return this.dialog.open(OneLineNotificationComponent, config)
    }

    openConfirmSystemStatusDialog(startTime: string, endTime: string) {
        let config: MatDialogConfig = {
            data: {
                startTime,
                endTime,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['modal-panel'],
            width: '572px',
            maxWidth: '100%',
            minWidth: 300,
            disableClose: true,
        }

        return this.dialog.open(ConfirmSystemStatusComponent, config)
    }

    openInviterMeetingNoLongerAvailable() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
            maxWidth: '100%',
            disableClose: true,
        }

        return this.dialog.open(InviterMeetingNoLongerAvailableComponent, config)
    }

    openBookFailedSessionAlreadyStarted() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
            maxWidth: '100%',
            disableClose: true,
        }

        return this.dialog.open(BookErrorSessionAlreadyStartedComponent, config)
    }

    openSessionConflict(conflicts: string[]) {
        let config: MatDialogConfig = {
            data: {
                conflicts,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['modal-panel'],
            width: '572px',
            maxWidth: '100%',
            minWidth: 300,
            disableClose: true,
        }

        return this.dialog.open(SessionConflictComponent, config)
    }

    openSessionConflictWithCancel(conflicts: number[], timezone: string) {
        let config: MatDialogConfig = {
            data: {
                conflicts,
                timezone,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
            maxWidth: '100%',
            disableClose: true,
        }

        return this.dialog.open(SessionConflictWithCancelComponent, config)
    }

    openEditNameModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            backdropClass: ['sdt-modal-backdrop'],
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(EditNameModalComponent, config)
    }

    openEditProfileUrlModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            backdropClass: ['sdt-modal-backdrop'],
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(EditProfileUrlModalComponent, config)
    }

    openEditTimezoneModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            backdropClass: ['sdt-modal-backdrop'],
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(EditTimezoneModalComponent, config)
    }

    openEditGenderModal(viaPreferenceModal: boolean = false) {
        let config: MatDialogConfig = {
            data: {
                viaPreferenceModal,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(EditGenderModalComponent, config)
    }

    openEditGenderPreferenceManModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(GenderPreferenceManModalComponent, config)
    }

    openEditGenderPreferenceUpdateGenderSelectionModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(GenderPreferenceUpdateGenderModalComponent, config)
    }

    openEditGenderPreferenceWomanModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(GenderPreferenceWomanModalComponent, config)
    }

    openEditGenderPreferenceNonCisModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(GenderPreferenceNoncisModalComponent, config)
    }

    openResetGenderPreferenceWarning() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(ResetGenderPreferenceModalComponent, config)
    }

    openEditShareAvailabilitySettingModal(notificationOnSave: boolean = false) {
        let config: MatDialogConfig = {
            data: { notificationOnSave },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
            maxHeight: '100vh',
        }

        return this.dialog.open(ShareAvailabilitySettingComponent, config)
    }

    openEditSQuietModeAllowedSettingModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
            maxHeight: '100vh',
        }

        return this.dialog.open(EditQuietModeAllowedModalComponent, config)
    }

    openTimeslotPartnerModal(sessionTime: number, duration: number, timezone: string) {
        let config: MatDialogConfig = {
            data: { sessionTime, duration, timezone },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal', 'partner-modal'],
            maxHeight: '100vh',
        }

        return this.dialog.open(TimeslotPartnersComponent, config)
    }

    openEditMuteByDefaultSettingModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(MuteByDefaultSettingComponent, config)
    }

    openEditTimeFormatModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(EditTimeFormatModalComponent, config)
    }

    openEditAutoRematchEnabledModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(EditAutoRematchEnabledModalComponent, config)
    }

    openUpdateEmailAddressResult(updateStatus: any, error: string) {
        let config: MatDialogConfig = {
            data: {
                updateStatus,
                error,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(UpdateEmailAddressComponent, config)
    }

    openConfirmUpdateEmailAddress(currentEmailAddress: string, newEmailAddress: string) {
        let config: MatDialogConfig = {
            data: {
                currentEmailAddress,
                newEmailAddress,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(ConfirmUpdateEmailAddressComponent, config)
    }

    openSnoozeUserModal(
        userId: string,
        displayName: string,
        editSnooze: boolean,
        snoozeExpiresAt: string,
        saved: boolean,
        source: string,
    ) {
        let config: MatDialogConfig = {
            data: {
                displayName,
                userId,
                editSnooze,
                snoozeExpiresAt,
                saved,
                source,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            disableClose: true,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(SnoozeUserComponent, config)
    }

    openUnsnoozeUserModal(userId: string, displayName: string, source: string) {
        let config: MatDialogConfig = {
            data: {
                displayName,
                userId,
                source,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            disableClose: true,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(UnsnoozeUserComponent, config)
    }

    openConfirmSnoozeUserModal(userId: string, displayName: string, expiresAt: string) {
        let config: MatDialogConfig = {
            data: {
                displayName,
                userId,
                expiresAt,
            },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(SnoozeUserConfirmComponent, config)
    }

    openPeopleListInfoModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(PeopleListInfoComponent, config)
    }

    openSessionPreferencesInfoModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(SessionPreferencesInfoComponent, config)
    }

    openDomainUpdatedNotificationModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(DomainUpdatedNotificationComponent, config)
    }

    openQuietModeInfoModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(AvailabilityListQuietModeInfoComponent, config)
    }

    openVideoFallbackNotice() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(VideoFallbackNoticeComponent, config)
    }

    openVideoFailedNotice() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(VideoNotAvailableNoticeComponent, config)
    }

    openVideoInitializationFailedNotice() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(VideoInitializationFailedNoticeComponent, config)
    }

    openConfirmRemoveIntegrationModal(integration: ApplicationIntegration) {
        let config: MatDialogConfig = {
            data: { integration },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(ConfirmRemoveIntegrationComponent, config)
    }

    openDateTimePickerModal() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['dt-modal'],
        }

        return this.dialog.open(FmDatetimePickerComponent, config)
    }

    openApiKeyModalComponent() {
        let config: MatDialogConfig = {
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(ApiKeyModalComponent, config)
    }

    openConfirmInivteLinkResetModal(availabilityCode: string) {
        let config: MatDialogConfig = {
            data: { availabilityCode },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(ConfirmResetModalComponent, config)
    }

    openUpdatedToSAgreementModal() {
        let config: MatDialogConfig = {
            hasBackdrop: true,
            closeOnNavigation: false,
            autoFocus: false,
            panelClass: ['std-modal'],
            maxWidth: '100%',
            disableClose: true,
            maxHeight: '100vh',
        }

        return this.dialog.open(TermsComponent, config)
    }

    openWelcomeChecklistModal(data = {}, afterClosed: () => void = () => {}): void {
        const config: MatDialogConfig = {
            ...dialogConfig,
            data,
        }

        const modalServiceItem: ModalServiceItem = new ModalServiceItem(
            WelcomeChecklistModalComponent,
            config,
        )

        modalServiceItem.data = data
        modalServiceItem.afterClosed = afterClosed

        this.createModal(modalServiceItem)
    }

    openCommmunityGuidelinesModal(data: ReviewCommunityGuidelinesModalData): void {
        const config: MatDialogConfig = {
            ...dialogConfig,
            data,
        }

        const modalServiceItem: ModalServiceItem = new ModalServiceItem(
            ReviewCommunityGuidelinesModalComponent,
            config,
        )

        modalServiceItem.data = data

        this.createModal(modalServiceItem)
    }

    openConnectGoogleCalendarIntegrationModal() {
        let config: MatDialogConfig = {
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(ConfirmConnectGoogleAccountComponent, config)
    }

    openEditGoogleCalendarIntegrationModal() {
        let config: MatDialogConfig = {
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(EditGoogleCalendarIntegrationModalComponent, config)
    }

    openDisconnectGoogleCalendarIntegrationModal() {
        let config: MatDialogConfig = {
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(ConfirmDisconnectGoogleAccountComponent, config)
    }

    openJoinedTooEarlyDialog(sessionTime: number) {
        let config: MatDialogConfig = {
            data: { sessionTime },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(JoinedTooEarlyDialogComponent, config)
    }

    openJoinedTooLateDialog(sessionTime: number) {
        let config: MatDialogConfig = {
            data: { sessionTime },
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(JoinedTooLateDialogComponent, config)
    }

    openTimeSyncInfoDialog() {
        let config: MatDialogConfig = {
            data: {},
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(TimeSyncInfoComponent, config)
    }

    openGoogleOauthCallbackErrorModal() {
        let config: MatDialogConfig = {
            hasBackdrop: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: ['std-modal'],
        }

        return this.dialog.open(GoogleOauthCallbackErrorModalComponent, config)
    }
}
