import { Directive, Output, EventEmitter, ElementRef, OnDestroy, HostListener } from '@angular/core'

@Directive({
    selector: '[domChange]',
})
export class FmResizeObserverDirective implements OnDestroy {
    @Output()
    public domChange = new EventEmitter()

    constructor(private elementRef: ElementRef) {
        this.domChange.emit(this.elementRef.nativeElement.offsetWidth)
    }

    @HostListener('window:resize', ['$event']) onResize(event) {
        this.domChange.emit(this.elementRef.nativeElement.offsetWidth)
    }

    ngOnInit() {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.domChange.emit(this.elementRef.nativeElement.offsetWidth)
        })
    }

    ngOnDestroy(): void {}
}
