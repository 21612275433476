import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { User } from '@app/core/_models'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { UserService } from '@app/core/_services/user.service'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { Subject, takeUntil } from 'rxjs'

@Component({
    selector: 'app-edit-auto-rematch-enabled-modal',
    templateUrl: './edit-auto-rematch-enabled-modal.component.html',
    styleUrl: './edit-auto-rematch-enabled-modal.component.scss',
})
export class EditAutoRematchEnabledModalComponent {
    public faTimes = faTimes
    public initialPreferenceSelected: boolean
    public preferenceSelected: boolean

    private ngUnsubscribe: Subject<any> = new Subject<any>()
    private currentUser: User = new User()

    disableSaveButton: boolean = true

    constructor(
        public dialog: MatDialogRef<EditAutoRematchEnabledModalComponent>,
        public userService: UserService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit() {
        this.analyticsService.logViewedModalEvent('Edit Auto Rematch Enabled')
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.currentUser = user
            this.initialPreferenceSelected = this.currentUser.settings.autoRematchEnabled
            this.preferenceSelected = this.initialPreferenceSelected
        })
    }

    radioButtonChanged() {
        this.disableSaveButton = this.initialPreferenceSelected === this.preferenceSelected
    }

    submitForm() {
        this.analyticsService.logUpdatedSettingEvent(
            'autoRematchEnabled',
            this.preferenceSelected,
            this.initialPreferenceSelected,
            'Edit Auto Rematch Enabled Modal',
        )

        this.userService
            .updateSettings({ autoRematchEnabled: this.preferenceSelected })
            .subscribe(() => {
                this.dialog.close()
            })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
