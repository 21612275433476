import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import * as Sentry from '@sentry/angular-ivy'
import * as amplitude from '@amplitude/analytics-browser'
import { UtilsService } from './utils.service'

@Injectable({
    providedIn: 'root',
})
export class AmplitudeAnalyticsEventsService {
    constructor(private utilsService: UtilsService) {
        try {
            amplitude.init(environment.amplitudeKey, {
                appVersion: environment.applicationVersion,
                serverUrl: environment.amplitudeEndPoint,
                defaultTracking: {
                    attribution: true,
                    sessions: false,
                    formInteractions: false,
                    fileDownloads: false,
                    pageViews: false,
                },
                sessionTimeout: 3600000,
            })
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e
            }
            Sentry.captureException(e)
        }
    }

    public emitEvent(action: string, properties: any, page, path) {
        try {
            properties['page'] = page
            properties['path'] = path
            properties['applicationVersion'] = environment.applicationVersion
            properties['displayMode'] = this.utilsService.getDisplayMode()

            amplitude.track(action, properties)
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e
            }
            Sentry.captureException(e)
        }
    }

    public setUserId(userId: string) {
        try {
            amplitude.setUserId(userId)

            if (userId == null) {
                amplitude.reset()
            }
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e
            }
            Sentry.captureException(e)
        }
    }

    public setUserProperties(properties: any): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const identify = new amplitude.Identify()
                Object.keys(properties).forEach((key) => identify.set(key, properties[key]))
                amplitude.identify(identify)
            } catch (e) {
                if (!(e instanceof ReferenceError)) throw e
                Sentry.captureException(e)
                resolve(null)
            }
        })
    }

    public clearUserProperties(properties: string[]) {
        try {
            let identify = new amplitude.Identify()

            properties.forEach((element) => {
                identify.unset(element)
            })

            amplitude.identify(identify)
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e
            }
            Sentry.captureException(e)
        }
    }
}
