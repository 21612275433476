<div class="std-modal-container">
    <fa-icon
        class="std-modal-close"
        [icon]="faTimes"
        [classes]="['fa-std-close-icon']"
        (click)="dialog.close()">
    </fa-icon>
    <div class="std-modal-header">
        <div class="std-modal-title">I am...</div>
        <div class="std-modal-subtitle"
            >This helps find partners for users with<br />a gender preference.</div
        >
    </div>

    <div class="std-modal-body w-full">
        <div class="f-form-field w-full field-spacer">
            <label style="margin-bottom: 7px">Choose one or more genders</label>
            <div class="toggle-line w-full fm-mb-10px">
                <app-f-toggle
                    [value]="womanTogglevalue"
                    [selected]="womanSelected"
                    (toggleChange)="updateFlags($event)"
                    >Woman</app-f-toggle
                >
                <app-f-toggle
                    [value]="manToggleValue"
                    [selected]="manSelected"
                    (toggleChange)="updateFlags($event)"
                    class="fm-ml-7px fm-mr-7px"
                    >Man</app-f-toggle
                >
                <app-f-toggle
                    matTooltip="Term used by some people who experience their gender as falling outside the categories of man and woman"
                    matTooltipPosition="above"
                    matTooltipShowDelay="500"
                    matTooltipClass="f-tooltip"
                    [value]="nonBinaryTogglevalue"
                    [selected]="nonBinarySelected"
                    (toggleChange)="updateFlags($event)">
                    Non-binary</app-f-toggle
                >
            </div>
            <div class="toggle-line w-full fm-mb-10px">
                <app-f-toggle
                    matTooltip="Umbrella term for people whose gender differs from what is typically associated with the sex they were assigned at birth"
                    matTooltipPosition="below"
                    matTooltipShowDelay="500"
                    matTooltipClass="f-tooltip"
                    [value]="transgenderTogglevalue"
                    [selected]="transgenderSelected"
                    (toggleChange)="updateFlags($event)"
                    >Transgender</app-f-toggle
                >
                <app-f-toggle
                    matTooltip="Term for a person whose gender aligns with what is typically associated with the sex assigned to them at birth"
                    matTooltipPosition="below"
                    matTooltipShowDelay="500"
                    matTooltipClass="f-tooltip"
                    [value]="cisgenderTogglevalue"
                    [selected]="cisgenderSelected"
                    (toggleChange)="updateFlags($event)"
                    class="fm-ml-7px fm-mr-7px"
                    >Cisgender</app-f-toggle
                >
                <app-f-toggle
                    [selected]="additionalGendersSelected"
                    style="white-space: nowrap"
                    (toggleChange)="toggleUpdate($event)">
                    <fa-icon [icon]="faPlus" [classes]="['']"></fa-icon> Add
                </app-f-toggle>
            </div>
            <div *ngIf="!data.viaPreferenceModal" class="toggle-line w-full">
                <app-f-toggle
                    [value]="preferNotToSayTogglevalue"
                    [selected]="preferNotToSaySelected"
                    (toggleChange)="updateFlags($event)"
                    >Prefer not to say</app-f-toggle
                >
            </div>
        </div>

        <div class="f-sep sep-w fm-mb-25px fm-mt-25px"></div>

        <div *ngIf="additionalGendersSelected" class="f-form-field w-full fm-mb-15px">
            <label>Additional gender(s)</label>
            <input
                class="w-full"
                maxlength="100"
                type="text"
                [(ngModel)]="additionalGenders"
                name="additionalGenders"
                (ngModelChange)="onKey($event)" />
        </div>

        <div class="disclaimer">
            <fa-icon [icon]="faLock" [classes]="['']"></fa-icon> Gender is not shown on your
            profile.
        </div>
        <div *ngIf="!additionalGendersSelected" class="filler"></div>

        <div class="std-modal-action-container">
            <app-spinner-button
                class="action"
                [disable]="disableSaveButton"
                [label]="'Save gender(s)'"
                classes="f-btn f-btn--primary f-btn-250"
                (clicked)="submitForm()">
            </app-spinner-button>

            <div class="f-link fm-mt-15px" (click)="dialog.close()">Cancel</div>
        </div>
        <app-gender-inclusion-blog class="w-full fm-mt-15px"></app-gender-inclusion-blog>
    </div>
</div>
