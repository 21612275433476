import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'fm-icon',
    templateUrl: './fm-icon.component.html',
    styleUrls: ['./fm-icon.component.scss'],
})
export class FmIconComponent implements OnInit {
    @Input() icon: string
    @Input() classes: string[]

    constructor() {}

    ngOnInit(): void {}
}
