import { Injectable } from '@angular/core'
import {
    GENDER_MASK_CISGENDER,
    GENDER_MASK_MAN,
    GENDER_MASK_NON_BINARY,
    GENDER_MASK_PREFER_NOT_SAY,
    GENDER_MASK_TRANSGENDER,
    GENDER_MASK_WOMAN,
    GENDER_MATCH_EXCLUDE_CIS_MEN,
    GENDER_MATCH_EXCLUDE_CIS_WOMEN,
    GENDER_MATCH_EXCLUDE_MEN,
    GENDER_MATCH_EXCLUDE_WOMEN,
    GENDER_MATCH_MEN_ONLY,
    GENDER_MATCH_NO_PREFERENCE,
    GENDER_MATCH_STRING_EXCLUDE_CIS_MEN,
    GENDER_MATCH_STRING_EXCLUDE_CIS_WOMEN,
    GENDER_MATCH_STRING_EXCLUDE_MEN,
    GENDER_MATCH_STRING_EXCLUDE_WOMEN,
    GENDER_MATCH_STRING_MEN_ONLY,
    GENDER_MATCH_STRING_NO_PREFERENCE,
    GENDER_MATCH_STRING_WOMEN_AND_NON_BINARY,
    GENDER_MATCH_WOMEN_AND_NON_BINARY,
    GENDER_STRING_CISGENDER,
    GENDER_STRING_EMPTY,
    GENDER_STRING_MAN,
    GENDER_STRING_NON_BINARY,
    GENDER_STRING_PREFER_NOT_TO_SAY,
    GENDER_STRING_TRANSGENDER,
    GENDER_STRING_WOMAN,
} from '../_models'
import { UtilsService } from './utils.service'

@Injectable({
    providedIn: 'root',
})
export class GenderService {
    constructor(private utilsService: UtilsService) {}

    buildGenderList(genderSelection: number, additionalGenders: string): string {
        let genderList: string = ''

        if (
            (genderSelection === null || genderSelection === 0) &&
            this.utilsService.isStringEmpty(additionalGenders)
        ) {
            genderList = GENDER_STRING_EMPTY
        } else if (genderSelection === GENDER_MASK_PREFER_NOT_SAY) {
            genderList = GENDER_STRING_PREFER_NOT_TO_SAY
        } else {
            if (genderSelection & GENDER_MASK_WOMAN) {
                genderList = this.appendGenderToList(genderList, GENDER_STRING_WOMAN)
            }
            if (genderSelection & GENDER_MASK_MAN) {
                genderList = this.appendGenderToList(genderList, GENDER_STRING_MAN)
            }
            if (genderSelection & GENDER_MASK_NON_BINARY) {
                genderList = this.appendGenderToList(genderList, GENDER_STRING_NON_BINARY)
            }
            if (genderSelection & GENDER_MASK_TRANSGENDER) {
                genderList = this.appendGenderToList(genderList, GENDER_STRING_TRANSGENDER)
            }
            if (genderSelection & GENDER_MASK_CISGENDER) {
                genderList = this.appendGenderToList(genderList, GENDER_STRING_CISGENDER)
            }
        }

        if (additionalGenders && additionalGenders !== '') {
            genderList = this.appendGenderToList(genderList, additionalGenders)
        }
        return genderList
    }

    private appendGenderToList(genderList, genderStrng): string {
        if (genderList != '') {
            genderList += ', ' + genderStrng
        } else {
            genderList = genderStrng
        }
        return genderList
    }

    getPreferenceStringFromPreferenceSelection(preferenceSelection: number): string {
        if (preferenceSelection === GENDER_MATCH_NO_PREFERENCE) {
            return 'Match me with ' + GENDER_MATCH_STRING_NO_PREFERENCE.toLowerCase()
        }
        if (preferenceSelection === GENDER_MATCH_MEN_ONLY) {
            return GENDER_MATCH_STRING_MEN_ONLY
        }
        if (preferenceSelection === GENDER_MATCH_WOMEN_AND_NON_BINARY) {
            return GENDER_MATCH_STRING_WOMEN_AND_NON_BINARY
        }
        if (preferenceSelection === GENDER_MATCH_EXCLUDE_CIS_MEN) {
            return 'Not ' + GENDER_MATCH_STRING_EXCLUDE_CIS_MEN.toLowerCase()
        }
        if (preferenceSelection === GENDER_MATCH_EXCLUDE_CIS_WOMEN) {
            return 'Not ' + GENDER_MATCH_STRING_EXCLUDE_CIS_WOMEN.toLowerCase()
        }
        if (preferenceSelection === GENDER_MATCH_EXCLUDE_MEN) {
            return 'Not ' + GENDER_MATCH_STRING_EXCLUDE_MEN.toLowerCase()
        }
        if (preferenceSelection === GENDER_MATCH_EXCLUDE_WOMEN) {
            return 'Not ' + GENDER_MATCH_STRING_EXCLUDE_WOMEN.toLowerCase()
        }
    }
}
