<div class="std-modal-container">
    <div class="std-modal-header">
        <div class="std-modal-title"> Finding you a partner… </div>
    </div>
    <div class="std-modal-body">
        <div class="std-modal-body-text">
            <p
                >Please wait here while we find you a new partner. Your session will start
                automatically.</p
            >
            <p
                >Maximum wait time:&nbsp;<app-fm-countdown
                    [countdownTo]="countdownTo"
                    (onCountdownFinished)="handleCountdownFinished()"></app-fm-countdown>
            </p>
        </div>
    </div>

    <div class="std-modal-action-container">
        <a (click)="cancelSearch()" class="cancel">Cancel search and return to Dashboard</a>
    </div>
</div>
