import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import {
    AnalyticsService,
    SessionTileSource,
    VideoLinkToString,
} from '@app/core/_services/analytics.service'
import { UtilsService } from '@app/core/_services/utils.service'
import { take } from 'rxjs/operators'
import { DeviceDetectionService } from '@app/core/_services/device-detection.service'
import { SafeResourceUrl } from '@angular/platform-browser'
import { UserService } from '@app/core/_services/user.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { PlayToneService } from '@app/core/_services/play-tone.service'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'

@Component({
    selector: 'app-video-icon',
    templateUrl: './video-icon.component.html',
    styleUrls: ['./video-icon.component.scss'],
})
export class VideoIconComponent implements OnInit {
    @Input() sessionUrl: string
    @Input() sessionTime: number
    @Input() videoIconEnabled: boolean
    @Input() source: SessionTileSource
    @Input() hideJoinButtonTextLabel: boolean = false
    @Input() rematchAtStart: boolean = false
    @Output() joiningSession: EventEmitter<boolean> = new EventEmitter<boolean>()

    faPlay = faArrowRight
    public sessionId: string = ''
    public updatedSessionUrl: SafeResourceUrl = ''
    public isUnsupportedBrowser: boolean = false
    public playButtonState: number = 0
    public routerLink: string = ''
    public routerState: any = {}
    public tileClass: string = ''
    public tooltipContent: string = ''

    constructor(
        private analyticsService: AnalyticsService,
        private userService: UserService,
        private utilsService: UtilsService,
        private dialogService: DialogService,
        private deviceDetectionService: DeviceDetectionService,
        private playToneService: PlayToneService,
    ) {}

    ngOnInit() {
        this.processSessionUrl()
    }

    ngOnChanges() {
        this.processSessionUrl()
    }

    processSessionUrl() {
        this.userService.currentUser.pipe(take(1)).subscribe((user) => {
            let meetingRoom = ''

            if (typeof this.sessionUrl !== 'undefined' && this.sessionUrl !== '') {
                this.tooltipContent = 'You can join your session 10 minutes before it starts'
                this.sessionId = this.sessionTime.toString()
                meetingRoom = this.utilsService.getRoomIdFromUrl(this.sessionUrl)
            } else {
                this.tooltipContent = 'You can join the session after you’re matched with a partner'
            }

            this.isUnsupportedBrowser = !this.deviceDetectionService.isSupportedBrowserDaily()

            this.updatedSessionUrl = this.utilsService.createSessionUrl(
                this.sessionId,
                this.sessionTime,
                user,
            )
            this.routerLink = '/session/' + (this.sessionTime / 1000).toString()

            this.routerState = {
                data: {
                    meetingRoom: meetingRoom,
                    rematchAtStart: this.rematchAtStart,
                },
            }

            if (this.source === SessionTileSource.CalendarTile) {
                this.playButtonState = 8
            }
            if (
                this.source === SessionTileSource.UpcomingSessions ||
                this.source === SessionTileSource.MySessionPage
            ) {
                this.playButtonState = 9
            }
            if (this.source === SessionTileSource.SessionInfoModal) {
                this.playButtonState = 7
                if (this.isUnsupportedBrowser) {
                    this.playButtonState = 5
                }
            }
        })
    }

    async joinSessionButtonClick() {
        this.playToneService.playEndSessionToneMuted()
        this.analyticsService.logClickedEvent(
            'Join session',
            VideoLinkToString[this.source]['Amplitude'],
        )

        if (this.isUnsupportedBrowser) {
            this.dialogService.openSafariWarning((this.sessionTime / 1000).toString())
        }

        if (this.playButtonState === 7) {
            this.joiningSession.emit(true)
        }
    }
}
