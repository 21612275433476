import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { PlayToneService } from '@app/core/_services/play-tone.service'

@Component({
    selector: 'app-new-partner-dialog',
    templateUrl: './new-partner-dialog.component.html',
    styleUrls: ['./new-partner-dialog.component.scss'],
})
export class NewPartnerDialogComponent implements OnInit {
    constructor(
        private analyticsService: AnalyticsService,
        public dialog: MatDialogRef<NewPartnerDialogComponent>,
        private playToneService: PlayToneService,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Partner Canceled: Rematched')
        this.playToneService.playRematchPopupTone()
    }
}
