import { HttpParams } from '@angular/common/http'
import { Injectable, Signal, WritableSignal, computed, signal } from '@angular/core'
import { ApiService } from './api.service'
import { environment } from '@env/environment'
import { Subject, takeUntil } from 'rxjs'
import {
    GroupFilter,
    SESSION_DURATION_25_MINS,
    SESSION_DURATION_50_MINS,
    SESSION_DURATION_75_MINS,
    SessionActivityType,
    TimeSlotAvailabilityResponse,
    TimeSlotAvailablePartner,
    TimeSlotAvailablePartnerGroup,
    TimeSlotSessionInfo,
    User,
} from '../_models'
import { map } from 'rxjs/operators'
import { GroupFilterService } from './group-filter.service'
import { UtilsService } from './utils.service'
import { UserService } from './user.service'
import { SessionSettingsService } from './session-settings.service'

export interface TimeSlotPartnerInfo {
    firstName: string
    displayName: string
    groups: TimeSlotAvailablePartnerGroup[]
    profilePhoto: string
    profileSlug: string
    saved: boolean
    sharedAvailability: boolean
    quietModeMatchAllowed: boolean
    preferences: PartnerSessionPreferences
    userId: string
    activityType: SessionActivityType
}
export interface TimeSlotData {
    hasDirectBookablePartner: boolean
    partner: TimeSlotPartnerInfo
}

export interface AvailableTimesAndPartnersLists {
    sessions25minutes: Map<number, TimeSlotData>
    sessions50minutes: Map<number, TimeSlotData>
    sessions75minutes: Map<number, TimeSlotData>
}

export interface PartnersAtTimeSlotAvailabilityResponse {
    availability: PartnersAtTimeSlotAvailability
}
export interface PartnersAtTimeSlotAvailability {
    partners: PartnerAtTimeSlot[]
}
export interface PartnerAtTimeSlot {
    userId: string
    firstName: string
    displayName: string
    profilePhoto: string
    profileSlug: string
    groups: PartnerAtTimeSlotGroupInfo[]
    saved: boolean
    sharedAvailability: boolean
    lastSessionTime: number
    sharedSessions: number
    sessionTime: number
    duration: number
    timezone: string
    totalSessions: number
    activityType: SessionActivityType
    preferences: PartnerSessionPreferences
    quietModeMatchAllowed: boolean
}
export interface PartnerAtTimeSlotGroupInfo {
    groupId: string
}

export interface PartnerSessionPreferences {
    quietMode: {
        value: boolean
    }
}

@Injectable({
    providedIn: 'root',
})
export class AvailableTimesService {
    receivedTimeSlotAvailabilityList: WritableSignal<TimeSlotAvailabilityResponse> = signal(
        new TimeSlotAvailabilityResponse(),
    )

    filteredTimeSlotAvailabilityList: Signal<AvailableTimesAndPartnersLists> = computed(() =>
        this.filterList(),
    )

    private ngUnsubscribe: Subject<any> = new Subject<any>()
    private currentUser: User = new User()

    constructor(
        private utilsService: UtilsService,
        private apiService: ApiService,
        private groupFilterService: GroupFilterService,
        private userService: UserService,
        private sessionSettingsService: SessionSettingsService,
    ) {
        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.currentUser = user
        })
    }

    clearAvailableTimesAndPartnersLists() {
        this.receivedTimeSlotAvailabilityList.set(new TimeSlotAvailabilityResponse())
    }

    queryAvailableTimes(retrieveFromTimestamp: number, retrieveToTimestamp: number) {
        let params = new HttpParams()

        params = params.append('retrieve-from', retrieveFromTimestamp.toString())
        params = params.append('retrieve-to', retrieveToTimestamp.toString())

        return this.apiService.get(environment.api_url + 'available-times/', params).pipe(
            map((response: TimeSlotAvailabilityResponse) => {
                this.receivedTimeSlotAvailabilityList.set(response)
            }),
        )
    }

    queryAvailablePartnersAtTimeslot(sessionTime: number, duration: number) {
        let params = new HttpParams()
        const sessionTimeString = sessionTime.toString()
        const sessionDurationString = (duration / 60000).toString()

        const url = `${environment.api_url}available-times/${sessionDurationString}/${sessionTimeString}`

        return this.apiService.get(url, params).pipe(
            map((response: PartnersAtTimeSlotAvailabilityResponse) => {
                return response
            }),
        )
    }

    private copyApiDataToTimeSlotInfo(
        partner: TimeSlotAvailablePartner,
        session: TimeSlotSessionInfo,
    ): TimeSlotPartnerInfo {
        const partnerInfo: TimeSlotPartnerInfo = {
            firstName: partner.firstName,
            displayName: partner.displayName,
            groups: partner.groups,
            profilePhoto: partner.profilePhoto,
            profileSlug: partner.profileSlug,
            saved: partner.saved,
            sharedAvailability: partner.sharedAvailability,
            quietModeMatchAllowed: partner.quietModeMatchAllowed,
            preferences: session.preferences,
            userId: session.userId,
            activityType: session.activityType,
        }
        return partnerInfo
    }

    private addSessionToList(
        partner: TimeSlotAvailablePartner,
        session: TimeSlotSessionInfo,
        availableTimesAndPartnersLists: AvailableTimesAndPartnersLists,
    ) {
        let updateList: Map<number, TimeSlotData>

        const sessionDuration = session.duration
        const availableLists = availableTimesAndPartnersLists

        if (sessionDuration === SESSION_DURATION_25_MINS) {
            updateList = availableLists.sessions25minutes
        } else if (sessionDuration === SESSION_DURATION_50_MINS) {
            updateList = availableLists.sessions50minutes
        } else if (sessionDuration === SESSION_DURATION_75_MINS) {
            updateList = availableLists.sessions75minutes
        } else {
            return
        }

        const sessionTime = session.sessionTime
        const currentTimeslotData = updateList.get(sessionTime)
        const isDirectlyBookable = partner.saved && partner.sharedAvailability

        if (currentTimeslotData) {
            if (!currentTimeslotData.partner && !session.directOnly) {
                currentTimeslotData.partner = this.copyApiDataToTimeSlotInfo(partner, session)
            } else if (
                !session.directOnly &&
                this.utilsService.usersShareGroup(this.currentUser.group, partner.groups) &&
                !this.utilsService.usersShareGroup(
                    this.currentUser.group,
                    currentTimeslotData.partner.groups,
                )
            ) {
                currentTimeslotData.partner = this.copyApiDataToTimeSlotInfo(partner, session)
            }
            currentTimeslotData.hasDirectBookablePartner =
                currentTimeslotData.hasDirectBookablePartner || isDirectlyBookable
            updateList.set(sessionTime, currentTimeslotData)
        } else {
            const data: TimeSlotData = session.directOnly
                ? { partner: null, hasDirectBookablePartner: true }
                : {
                      partner: this.copyApiDataToTimeSlotInfo(partner, session),
                      hasDirectBookablePartner: isDirectlyBookable,
                  }
            updateList.set(sessionTime, data)
        }
    }

    filterList(): AvailableTimesAndPartnersLists {
        let availableTimesAndPartnersLists: AvailableTimesAndPartnersLists = {
            sessions25minutes: new Map<number, TimeSlotData>(),
            sessions50minutes: new Map<number, TimeSlotData>(),
            sessions75minutes: new Map<number, TimeSlotData>(),
        }
        const groupFilters = this.groupFilterService.groupFiltersSelected()
        const receivedList = this.receivedTimeSlotAvailabilityList()
        const quietModeEnabled = this.sessionSettingsService.quietModeEnabled()

        if (receivedList) {
            const partners = receivedList.availability.partners || {}
            const sessions = receivedList.availability.sessions || []

            for (let session of sessions) {
                if (
                    this.filterPartner(
                        partners[session.userId],
                        session,
                        groupFilters,
                        quietModeEnabled,
                    )
                ) {
                    this.addSessionToList(
                        partners[session.userId],
                        session,
                        availableTimesAndPartnersLists,
                    )
                }
            }
        }
        return availableTimesAndPartnersLists
    }

    includeSessionBasedOnQuietModeSettings(
        partner: TimeSlotAvailablePartner,
        session: TimeSlotSessionInfo,
        quietModeEnabled: boolean,
    ): boolean {
        /* Do not include a potential match in the list if all the following conditions are met:
		1. The current user has setting quietModeMatchAllowed set to False
		2. The potential partner has their session set to quietMode
		3. The current user does not have quietMode set

		OR

		1. The potential partner has quietModeMatchAllowed set to False
		2. the current user has quietMode enabled
		3. The potential partner's session is not set for quietMode
		*/
        if (
            !this.currentUser.settings.quietModeMatchAllowed &&
            !quietModeEnabled &&
            session.preferences.quietMode.value
        ) {
            return false
        }
        if (
            !partner.quietModeMatchAllowed &&
            quietModeEnabled &&
            !session.preferences.quietMode.value
        ) {
            return false
        }
        return true
    }

    filterPartner(
        partner: TimeSlotAvailablePartner,
        session: TimeSlotSessionInfo,
        groupFilters: GroupFilter[],
        quietModeEnabled: boolean,
    ): boolean {
        let show_all_focusmate_members = true
        let group_selected_to_show = true

        if (this.includeSessionBasedOnQuietModeSettings(partner, session, quietModeEnabled)) {
            for (let filter of groupFilters) {
                if (filter.groupInfo.groupId === '_all_fm_members') {
                    show_all_focusmate_members = filter.show
                } else {
                    if (partner.groups.some((el) => el.groupId === filter.groupInfo.groupId)) {
                        group_selected_to_show = filter.show

                        if (group_selected_to_show) {
                            return true
                        }
                    }
                }
            }

            // If we reach here and group_selected_to_show is False
            // that means that the filters specifically filter out this user
            // based on their groups
            if (!group_selected_to_show) {
                return false
            } else {
                return show_all_focusmate_members
            }
        } else {
            return false
        }
    }
}
