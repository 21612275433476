import { Component, Input } from '@angular/core'
import { faMicrophoneSlash } from '@fortawesome/pro-solid-svg-icons'

@Component({
    selector: 'fm-quiet-mode-icon',
    templateUrl: './quiet-mode-icon.component.html',
    styleUrls: ['./quiet-mode-icon.component.scss'],
})
export class QuietModeIconComponent {
    @Input() backgroundClasses: string = 'relative h-3.5 w-3 bg-blurple-2 rounded-[100px]'
    @Input() iconClasses: string =
        'top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2 h-[9px] w-[9px] fill-blurple'
    @Input() tooltip: string | null = null

    faMicrophoneSlash = faMicrophoneSlash

    ngOnInit() {}

    ngOnChanges() {}
}
