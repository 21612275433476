import { Injectable, inject } from '@angular/core'

import { SelectedSessionService } from '@app/core/_services/selected-sessions.service'
import { UserService } from '@app/core/_services/user.service'
import { ConfirmedSessionsService } from '@app/core/_services/confirmed-sessions.service'

import { AnalyticsService } from './analytics.service'
import { Auth, User as FirebaseUser, user } from '@angular/fire/auth'
import { AvailableTimesService } from './available-times.service'
import { firstValueFrom } from 'rxjs'
import { environment } from '@env/environment'
import { Router } from '@angular/router'
import { UtilsService } from './utils.service'

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private afAuth = inject(Auth)
    redirectToPath: string = ''

    constructor(
        private userService: UserService,
        private ss: SelectedSessionService,
        private cs: ConfirmedSessionsService,
        private availableTimesService: AvailableTimesService,
        private analyticsService: AnalyticsService,
        private utilsService: UtilsService,
        private router: Router,
    ) {}

    signOut(): Promise<void> {
        this.ss.deleteAllSelectedSessions()
        this.cs.clearConfirmedSessions()
        this.availableTimesService.clearAvailableTimesAndPartnersLists()

        return this.afAuth.signOut().then(() => {
            this.analyticsService.setUserId(null)
            this.userService.purgeAuth(false)

            // Upon logging out, go to the marketing site homepage
            // unless running in standalone/minimal-ui mode
            if (this.utilsService.isRunningInPWAMode()) {
                this.router.navigate(['/login'])
            } else {
                window.location.href = environment.marketingHomeUrl
            }
        })
    }

    // To be used during email verification process if
    // verification code is for an email address that is currently
    // not logged in. Ensure we stay at the verification page.
    signOutVerify() {
        this.ss.deleteAllSelectedSessions()
        this.cs.clearConfirmedSessions()
        this.availableTimesService.clearAvailableTimesAndPartnersLists()

        this.afAuth.signOut().then(() => {
            this.userService.purgeAuth(false)
        })
    }

    getFirebaseUser(): Promise<FirebaseUser> {
        return firstValueFrom(user(this.afAuth))
    }
}
