import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { GENDER_MASK_CISGENDER, GENDER_MASK_PREFER_NOT_SAY, User } from '@app/core/_models'
import { UtilsService } from '@app/core/_services/utils.service'
import { AnalyticsService } from '@app/core/_services/analytics.service'
import { DialogService } from '@app/core/_services/dialog.service'
import { UserService } from '@app/core/_services/user.service'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
    selector: 'app-reset-gender-preference-modal',
    templateUrl: './reset-gender-preference-modal.component.html',
    styleUrls: ['./reset-gender-preference-modal.component.scss'],
})
export class ResetGenderPreferenceModalComponent implements OnInit {
    public faTimes = faTimes
    private ngUnsubscribe: Subject<any> = new Subject<any>()
    public canUpdatePreferences: boolean = false

    constructor(
        public dialog: MatDialogRef<ResetGenderPreferenceModalComponent>,
        private analyticsService: AnalyticsService,
        private userService: UserService,
        private utilsService: UtilsService,
    ) {}

    ngOnInit(): void {
        this.analyticsService.logViewedModalEvent('Gender Preference: Reset')

        this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.canUpdatePreferences = this.canUpdatePreferencesBasedOnCurrentGenderSettings(user)
        })
    }

    private canUpdatePreferencesBasedOnCurrentGenderSettings(user: User): boolean {
        if (
            this.utilsService.isStringEmpty(user.gendersAdditional) &&
            (user.genderSelection === GENDER_MASK_PREFER_NOT_SAY ||
                user.genderSelection === GENDER_MASK_CISGENDER ||
                user.genderSelection === 0)
        ) {
            return false
        } else {
            return true
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null)
        this.ngUnsubscribe.complete()
    }
}
